import React, { useEffect, useState } from "react";
import styled from "styled-components";
import spinner from "../../assets/images/gifs/spinner.gif";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import rootStore from "../../stores/RootStore";
import LeadAssignModal from "../lead_assign_modal/LeadAssignModal";
import { observer } from "mobx-react-lite";
import { pageSize } from "../../Config";
import {
  deleteMultipleLeadsApi,
  getFilterColsApi,
  markUnmarkMultipleLeadAsFreshLeadApi,
  searchLeadsByQueryApi,
} from "../../Api";
import { Box, makeStyles } from "@material-ui/core";
import PaginationComponent from "../pagination/PaginationComponent";
import { Backdrop, CircularProgress } from "@mui/material";
import SuccessMessageDialog from "../alert_dialogue/SuccessMessageDialog";
import ErrorMessageDialog from "../alert_dialogue/ErrorMessageDialog";
import { isLastContactedToday } from "../../Functions";
import CustomConfirmationDialog from "../alert_dialogue/CustomConfirmationDialog";
import { leadListingIconsColumn } from "../../ColumnUtils";
import MultiLeadUpdateStageModal from "../update_stage_modal/MultiLeadUpdateStageModal";
import DeleteLeadConfirmationDialog from "../alert_dialogue/DeleteLeadConfirmationDialog";
import CustomMessageDialog from "../alert_dialogue/CustomMessageDialog";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const Container = styled.div`
  height: fit-content;
  ${`min-height: calc(100vh - 148px);`};
  background-color: white;
  width: 100%;
  /* padding: 12px; */
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 400px;
  flex-direction: column;
  margin: auto;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;
const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "white",
      backgroundColor: "black",
      margin: "0px",
      borderRadius: "0px",
      height: "25px",
      minWidth: "20px",
    },
    "& .MuiPaginationItem-root": {
      margin: "0px",
      borderRadius: "0px",
      height: "25px",
      minWidth: "20px",
    },
  },
}));

const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const PaginationWrapper = styled.div`
  padding: 0px 20px;
`;

const FilteredTab = (props) => {
  let navigate = useNavigate();

  const [refresh, setRefresh] = useState(false);
  let uid = localStorage.getItem("uid");
  const [leads, setLeads] = useState([]);
  const { authStore, userStore } = rootStore;
  let pid = authStore.projectId;
  // let columns = userStore.FilterColumnHeaders;
  const [size, setSize] = useState(pageSize);
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  // delete leads related state hooks
  // const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const stagesList = [...rootStore.authStore.stageList];
  const statusList = [...rootStore.authStore.statusList];
  const [showDeleteProtectedAlert, setShowDeleteProtectedAlert] =
    useState(false);

  const init = async () => {
    window.scrollTo(0, 0);
    await getFilterColsApi();
    await getFilteredLeads({ page_no: 0, page_size: pageSize });
  };
  useEffect(() => {
    init();
  }, [authStore.searchQuery]);
  useEffect(() => {
    if (refresh === true) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  const getFilteredLeads = async ({ page_no, page_size }) => {
    setRow([]);
    setLoading(true);
    let response = await searchLeadsByQueryApi({ page_no, page_size });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    }
    setLoading(false);
  };
  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setPage(0);
    await getFilteredLeads({ page_no: 0, page_size: e.target.value });
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);
    await getFilteredLeads({ page_no: page - 1, page_size: size });
  };

  const handleMarkLeadAsFreshLead = async () => {
    props.setOpenConfirmMarkLeadDialog(false);
    setShowBackDrop(true);
    let payload = { lead_ids: leads[0], status: true };
    let response = await markUnmarkMultipleLeadAsFreshLeadApi({
      payload: payload,
    });
    if (response.hasError()) {
      setShowBackDrop(false);
      setOpenErrorDialog(true);
    } else {
      setShowBackDrop(false);
      setSuccessMessage("Leads successfully marked as fresh!");
      setOpenSuccessDialog(true);
      setRefresh(true);
    }
  };

  //*******************delete leads related functions************* */

  const isStageDeleteProtected = ({ leadData }) => {
    let index = stagesList.findIndex(
      (stage) => stage["stage"] === leadData["lead_stage"]
    );
    if (index !== -1) {
      return stagesList[index]["delete_protected"];
    }
    return false;
  };
  const isStatusDeleteProtected = ({ leadData }) => {
    let index = statusList.findIndex(
      (status) => status["status"] === leadData["lead_status"]
    );
    if (index !== -1) {
      return statusList[index]["delete_protected"];
    }
    return false;
  };

  const isDeleteProtected = ({ leadData }) => {
    return (
      isStageDeleteProtected({ leadData: leadData }) ||
      isStatusDeleteProtected({ leadData: leadData })
    );
  };

  const isAnyLeadDeleteProtected = () => {
    for (let i = 0; i < leads[0].length; i++) {
      let index = row.findIndex((lead) => lead["id"] === leads[0][i]);
      let leadData = row[index];
      if (isDeleteProtected({ leadData: leadData })) {
        return true;
      }
    }
    return false;
  };

  const handleDeleteLead = async () => {
    props.setOpenDeleteLead(false);
    setShowBackDrop(true);

    if (isAnyLeadDeleteProtected()) {
      setShowBackDrop(false);
      setShowDeleteProtectedAlert(true);
    } else {
      let response = await deleteMultipleLeadsApi({ idList: leads[0] });
      if (response.hasError()) {
        setShowBackDrop(false);
        setOpenErrorDialog(true);
      } else {
        setShowBackDrop(false);
        setSuccessMessage("Leads deleted successfully!");
        setOpenSuccessDialog(true);
        setRefresh(true);
      }
    }
  };

  //****************************END********************************** */

  return (
    <>
      <Container>
        <Wrapper>
          {userStore.FilterColumnHeaders.length > 0 ? (
            <>
              <TableWrapper>
                <Box
                  sx={{
                    "& .last-contacted-today": {
                      backgroundColor: "#E6EDF7",
                    },
                    "& .not-last-contacted-today": {
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                >
                  <DataGrid
                    getRowClassName={(params) =>
                      isLastContactedToday(params.row["last_contacted_on"])
                        ? "last-contacted-today"
                        : "not-last-contacted-today"
                    }
                    autoHeight={true}
                    rows={row}
                    columns={leadListingIconsColumn.concat(
                      userStore.FilterColumnHeaders
                    )}
                    pageSize={size}
                    style={{ cursor: "pointer" }}
                    hideFooter={true}
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Array(ids);
                      props.setAssignState(selectedIDs[0].length);
                      setLeads(selectedIDs);
                    }}
                    loading={loading}
                    disableColumnMenu={true}
                    onRowClick={(e) => {
                      navigate(`/users/${uid}/leads/${e.id}`);
                    }}
                    checkboxSelection={true}
                  />
                </Box>
              </TableWrapper>
              <PaginationWrapper>
                <PaginationComponent
                  page_no={page}
                  row={row}
                  page_size={size}
                  size={size}
                  count={count}
                  handlePageSize={handlePageSize}
                  handlePage={handlePagination}
                />
              </PaginationWrapper>
            </>
          ) : (
            <SpinnerWrapper>
              <Spinner src={spinner} alt="loading..." />
            </SpinnerWrapper>
          )}
          {props.open && (
            <LeadAssignModal
              open={props.open}
              setOpen={props.setOpen}
              row={props.member}
              leads={leads}
              pid={pid}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          )}
        </Wrapper>
      </Container>

      {openSuccessDialog && (
        <SuccessMessageDialog
          open={openSuccessDialog}
          setOpen={setOpenSuccessDialog}
          message={successMessage}
        />
      )}
      {openErrorDialog && (
        <ErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
        />
      )}
      {props.openConfirmMarkLeadDialog && (
        <CustomConfirmationDialog
          message={"Are you sure you want to mark the leads as fresh?"}
          open={props.openConfirmMarkLeadDialog}
          setOpen={props.setOpenConfirmMarkLeadDialog}
          handleConfirm={() => {
            handleMarkLeadAsFreshLead();
          }}
        />
      )}
      {props.openDeleteLead && (
        <DeleteLeadConfirmationDialog
          open={props.openDeleteLead}
          setOpen={props.setOpenDeleteLead}
          handleConfirm={() => {
            handleDeleteLead();
          }}
        />
      )}
      {showDeleteProtectedAlert && (
        <CustomMessageDialog
          open={showDeleteProtectedAlert}
          setOpen={setShowDeleteProtectedAlert}
          message={
            "Leads are delete-protected. Unprotect or remove them to proceed with deletion."
          }
        />
      )}

      {props.openUpdateStageModal && (
        <MultiLeadUpdateStageModal
          open={props.openUpdateStageModal}
          setOpen={props.setOpenUpdateStageModal}
          selectedLeadIdsData={leads}
          setRefresh={setRefresh}
          scope={"search_page"}
        />
      )}
      <Backdrop
        open={showBackDrop}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
    </>
  );
};

export default observer(FilteredTab);
