import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AddBoxOutlined } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import spinner from "../../assets/images/gifs/spinner.gif";
import { notesContactColumns } from "../../Db";
import { DataGrid } from "@mui/x-data-grid";
import { getLeadNotesApi } from "../../Api";
import { Box } from "@material-ui/core";
import CreateNoteModal from "../notes_tab_modal/CreateNoteModal";
import NoteDetailsModal from "../notes_tab_modal/NoteDetailsModal";
import rootStore from "../../stores/RootStore";
import analytics from "../../Analytics";

const Container = styled.div`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 234px);`};
`;
const Wrapper = styled.div`
  display: flex;
`;

const DetailsWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;

const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  width: 100%;
  ${`min-height: calc(100vh - 148px);`};
`;

const AddBtnWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const NotesTab = (props) => {
  const { authStore } = rootStore;
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [row, setRow] = useState();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setRow();
    setLoading(true);
    const getNotes = async () => {
      try {
        let response = await getLeadNotesApi({ id: props.lid });
        setRow(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getNotes();
  }, [refresh]);
  useEffect(() => {
    analytics.triggerEvent(
      4625000055,
      "notes_tab_load",
      "lead_details_page",
      "",
      {}
    );
  }, []);

  return (
    <>
      {row ? (
        <Container>
          <AddBtnWrapper>
            <AddBoxOutlined
              color={props.canEditLead() ? "primary" : "disabled"}
              style={{
                width: "40px",
                height: "40px",
              }}
              onClick={() => {
                authStore.updateLastActiveTime();
                if (props.canEditLead()) {
                  analytics.triggerEvent(
                    4625000070,
                    "add_note_button_tap",
                    "notes_tab",
                    "add_note_button",
                    {}
                  );
                  setOpen(true);
                }
              }}
            />
          </AddBtnWrapper>
          <Wrapper>
            <DetailsWrapper>
              <TableWrapper>
                <Box
                  sx={{
                    "& .decision-maker-true": {
                      backgroundColor: "#EDA93B",
                    },
                    "& .decision-maker-false": {
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                >
                  <DataGrid
                    autoHeight={true}
                    rows={row}
                    getRowClassName={(params) =>
                      params.row.decision_maker
                        ? "decision-maker-true"
                        : "decision-maker-false"
                    }
                    columns={notesContactColumns}
                    checkboxSelection={false}
                    style={{ cursor: "pointer" }}
                    hideFooter={true}
                    loading={loading}
                    disableColumnMenu={true}
                    disableSelectionOnClick={true}
                    onRowClick={(params) => {
                      analytics.triggerEvent(
                        4625000071,
                        "note_row_tap",
                        "notes_tab",
                        "",
                        {}
                      );
                      setSelectedRow(params.row);
                      setOpenView(true);
                    }}
                  />
                </Box>
              </TableWrapper>
            </DetailsWrapper>
          </Wrapper>
        </Container>
      ) : (
        <SpinnerWrapper>
          <Spinner src={spinner} alt="loading..." />
        </SpinnerWrapper>
      )}
      {open && (
        <CreateNoteModal
          open={open}
          setOpen={setOpen}
          lid={props.lid}
          uid={props.uid}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
      {openView && (
        <NoteDetailsModal
          setOpen={setOpenView}
          open={openView}
          data={selectedRow}
        />
      )}
    </>
  );
};

export default observer(NotesTab);
