import React from "react";
import styled from "styled-components";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const TableComponent = (props) => {
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  return (
    <TableWrapper style={{ width: "100%", height: "100%" }}>
      <div
        className="ag-theme-alpine"
        style={{ width: "100%", height: "100%", overflowY: "auto" }}
      >
        <AgGridReact
          rowData={props.row}
          defaultColDef={defaultColDef}
          columnDefs={props.columns}
          animateRows={true}
          pagination={true}
          paginationPageSize={10}
          className="table-widget-pagination"
        />
      </div>
    </TableWrapper>
  );
};

export default TableComponent;
