import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { IsoToLocalDate, dataRenderer } from "../../../Functions";
import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { Divider, Drawer, Grid } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { dealsTabProductsColumnHeaders } from "../../../Db";
import { useMemo } from "react";
import ViewDealProductSidepanel from "../../view_product_sidepanel/ViewDealProductSidepanel";
const Label = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  margin-bottom: 8px;
  color: #979799;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
`;

const InputWrapper = styled(Box)`
  background: #f9f9fc 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  height: 100%;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  color: #979797;
`;
const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const DealDeatilsWidget = ({ details }) => {
  const [openViewProductPanel, setOpenViewProductPanel] = useState(false);
  const [selectedRowID, setSelectedRowID] = useState("");

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };
  const nameColumn = [
    {
      field: "name",
      headerName: "NAME",
      width: 230,
      cellRenderer: (params) => {
        let value = "-";
        if (params.data.item !== null) {
          value = dataRenderer(params.data.item);
        } else {
          value = dataRenderer(params.data.product.name);
        }
        if (params.data.item !== null) {
          return (
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Tooltip title={value}>
                <Text>{value}</Text>
              </Tooltip>
            </Box>
          );
        } else {
          return (
            <Box
              onClick={() => {
                setSelectedRowID(params.data.product.id);
                setOpenViewProductPanel(true);
              }}
              sx={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <Tooltip title={value}>
                <TextHighlight>{value}</TextHighlight>
              </Tooltip>
            </Box>
          );
        }
      },
    },
  ];
  return (
    <>
      <Container>
        <SectionWrapper style={{ marginBottom: "16px" }}>
          <SectionHeader>Deal Information</SectionHeader>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={3} xl={2}>
              <InputWrapper>
                <Label>Deal Name</Label>
                <Detail>{dataRenderer(details.name)}</Detail>
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={2}>
              <InputWrapper>
                <Label>Deal Value</Label>
                {details.deal_value_currency !== undefined &&
                details.deal_value_currency !== null &&
                details.deal_value_currency !== "" ? (
                  <Detail>{`${dataRenderer(
                    details.deal_value_currency
                  )} ${dataRenderer(details.deal_value)}`}</Detail>
                ) : (
                  <Detail>{dataRenderer(details.deal_value)}</Detail>
                )}
              </InputWrapper>
            </Grid>

            <Grid item xs={12} md={4} lg={3} xl={2}>
              <InputWrapper>
                <Label>Expected close date</Label>
                <Detail>
                  {details.expected_close_date !== null &&
                  details.expected_close_date !== ""
                    ? IsoToLocalDate(details.expected_close_date)
                    : "-"}
                </Detail>
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={2}>
              <InputWrapper>
                <Label>Priority</Label>
                {(() => {
                  if (details.priority.toLowerCase() === "low") {
                    return (
                      <Chip
                        color="default"
                        label={details.priority}
                        style={{ width: "fit-content", fontWeight: "bold" }}
                      />
                    );
                  } else if (details.priority.toLowerCase() === "medium") {
                    return (
                      <Chip
                        color="warning"
                        label={details.priority}
                        style={{ width: "fit-content", fontWeight: "bold" }}
                      />
                    );
                  } else if (details.priority.toLowerCase() === "high") {
                    return (
                      <Chip
                        color="success"
                        label={details.priority}
                        style={{ width: "fit-content", fontWeight: "bold" }}
                      />
                    );
                  }
                })()}
              </InputWrapper>
            </Grid>
          </Grid>
        </SectionWrapper>
        <Divider style={{ marginBottom: "16px" }} />
        <SectionWrapper>
          <SectionHeader>Products</SectionHeader>
          <div className="ag-theme-alpine" style={{ width: "100%" }}>
            <AgGridReact
              domLayout="autoHeight"
              gridOptions={gridOptions}
              rowData={details.deal_items !== null ? details.deal_items : []}
              defaultColDef={defaultColDef}
              columnDefs={nameColumn.concat(dealsTabProductsColumnHeaders)}
              animateRows={true}
              suppressCellFocus
            />
          </div>
        </SectionWrapper>
      </Container>
      {openViewProductPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openViewProductPanel}
          onClose={() => {
            setSelectedRowID("");
            setOpenViewProductPanel(false);
          }}
        >
          <ViewDealProductSidepanel
            productID={selectedRowID}
            setOpen={setOpenViewProductPanel}
            open={openViewProductPanel}
          />
        </Drawer>
      )}
    </>
  );
};

export default DealDeatilsWidget;
