import { ChevronRight } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AdminUserCheckInCalendar from "../../components/checkin_page_components/admin_user_checkin_components/AdminUserCheckinCalendar";
import AdminUserCheckinTable from "../../components/checkin_page_components/admin_user_checkin_components/AdminUserCheckinTable";
import Footer from "../../components/landing_page_components/footer/Footer";
import rootStore from "../../stores/RootStore";

const Container = styled.div`
  margin-top: 64px;
  background-color: #f9f9fc;
  ${`min-height: calc(100vh - 142px);`};
`;
const Wrapper = styled.div`
  width: 95%;
  margin: auto;
  /* background-color: white; */
  display: flex;
  flex-direction: column;
  /* padding-top: 20px; */
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding-bottom: 20px;
`;
const CalendarWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  min-height: 70vh;
  background-color: white;
  padding: 30px;
  column-gap: 20px;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0px;
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;
const SectionHeader = styled.p`
  margin: 0px;
  font: normal normal 600 20px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
  margin-bottom: 20px;
`;
const AdminUserCheckinPage = () => {
  const [checkinDate, setCheckinDate] = useState(null);
  const { pid, id } = useParams();
  const navigate = useNavigate();
  const [date, setDate] = useState(null);
  const monthStartDateGetter = () => {
    return new Date("2023", 1, 1);
  };
  useEffect(() => {
    rootStore.authStore.updateLeadDetailStatus();
    let sDate = monthStartDateGetter();
    sDate = moment(sDate).format("YYYY-MM-DD");
    setCheckinDate(sDate);
    setDate(sDate);
  }, []);
  return (
    <>
      <Container>
        <Wrapper>
          <TopWrapper>
            <Text>Admin</Text>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Text
              style={{ color: "#185DD2", cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Users
            </Text>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Text>{id}</Text>
          </TopWrapper>
          <Row>
            <Header>User Check-Ins History</Header>
          </Row>
          <CalendarWrapper>
            <Column>
              <SectionHeader>Select Checkin Date</SectionHeader>
              {date !== null && (
                <AdminUserCheckInCalendar
                  id={id}
                  setDate={setDate}
                  date={date}
                  setCheckinDate={setCheckinDate}
                />
              )}
            </Column>
            <Column style={{ width: "100%" }}>
              <SectionHeader>Checkin History</SectionHeader>
              {checkinDate !== null && (
                <AdminUserCheckinTable id={id} checkinDate={checkinDate} />
              )}
            </Column>
          </CalendarWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default observer(AdminUserCheckinPage);
