import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import spinner from "../../assets/images/gifs/spinner.gif";
import { TargetPageColumns } from "../../Db";

const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const AchievedTab = () => {
  const [page, setPage] = useState(0);

  const [row, setRow] = useState();
  const [loading, setLoading] = useState(false);
  //   useEffect(() => {
  //     const getTargets = async () => {
  //       try {
  //         let response = await getTargetsApi({ page: page });
  //         setRow(response.data);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     };
  //     getTargets();
  //   }, []);
  return (
    <>
      {/* {row ? ( */}
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight={true}
          rows={[]}
          columns={TargetPageColumns}
          checkboxSelection={false}
          style={{ cursor: "pointer" }}
          hideFooter={true}
          loading={loading}
          disableColumnMenu={true}
          //   onRowClick={(e) => {
          //     navigate(`/target/${e.row.name}/${e.id}`);
          //   }}
        />
      </div>
      {/* ) : (
        <SpinnerWrapper>
          <Spinner src={spinner} alt="loading..." />
        </SpinnerWrapper>
      )} */}
    </>
  );
};

export default AchievedTab;
