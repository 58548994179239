import React, { useEffect, useState } from "react";
import styled from "styled-components";
import spinner from "../../assets/images/gifs/spinner.gif";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import rootStore from "../../stores/RootStore";
import LeadAssignModal from "../lead_assign_modal/LeadAssignModal";
import { observer } from "mobx-react-lite";
import ColumnSelectionModal from "../column_selection_modal/ColumnSelectionModal";
import {
  deleteMultipleLeadsApi,
  getAllColsApi,
  getColumnsForTabApi,
  getLeadsApi,
} from "../../Api";
import { toJS } from "mobx";
import { Box, Button, makeStyles } from "@material-ui/core";
import PaginationComponent from "../pagination/PaginationComponent";
import { FormattedColumns } from "../../ColumnUtils";
import { Backdrop, CircularProgress } from "@mui/material";
import SuccessMessageDialog from "../alert_dialogue/SuccessMessageDialog";
import ErrorMessageDialog from "../alert_dialogue/ErrorMessageDialog";
import DeleteLeadConfirmationDialog from "../alert_dialogue/DeleteLeadConfirmationDialog";
import analytics from "../../Analytics";
import { isLastContactedToday } from "../../Functions";
import { pageSize } from "../../Config";
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${`min-height: calc(100vh - 282px);`};
  align-items: center;
`;
const Container = styled.div`
  height: fit-content;
  ${`min-height: calc(100vh - 234px);`};
  background-color: white;
  /* padding: 12px; */
  width: 100%;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 400px;
  flex-direction: column;
  margin: auto;
`;
const PaginationWrapper = styled.div`
  padding: 0px 20px;
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "white",
      backgroundColor: "#4D4E4F",
      margin: "0px",
      borderRadius: "0px",
      height: "25px",
      minWidth: "20px",
    },
    "& .MuiPaginationItem-root": {
      margin: "0px",
      borderRadius: "0px",
      height: "25px",
      minWidth: "20px",
    },
  },
}));
const NewLeadTab = (props) => {
  const { authStore, userStore } = rootStore;
  let navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [refreshColumn, setRefreshColumn] = useState(false);
  const [columns, setColumns] = useState([]);
  const [deletingLead, setDeletingLead] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  let uid = localStorage.getItem("uid");
  let pid = authStore.projectId;
  let tid = authStore.teamId;
  let tabId = userStore.TabId;
  let page_size = userStore.tabInfo.hasOwnProperty(tabId)
    ? userStore.tabInfo[tabId]["page_size"]
    : pageSize;
  let page = userStore.tabInfo.hasOwnProperty(tabId)
    ? userStore.tabInfo[tabId]["page"]
    : 0;
  let Columns = userStore.ColumnHeaders;

  const filters = toJS(userStore.tabFilters).hasOwnProperty(tabId)
    ? toJS(userStore.tabFilters)[tabId]
    : null;
  const sorting = toJS(userStore.sortingTabs).hasOwnProperty(tabId)
    ? toJS(userStore.sortingTabs)[tabId]
    : null;
  const handlePageSize = async (e) => {
    userStore.updatePageSize({ id: tabId, size: e.target.value });
    userStore.updatePaging({ id: tabId, page: 0 });
    await getLeadsList();
  };

  const getColumnsForTab = async () => {
    try {
      let response = await getColumnsForTabApi(tabId);
      let tabColumnsFieldList = await response.data;
      let allColumnList = userStore.AllColumnsList;

      let newColumnsList = [];
      if (tabColumnsFieldList.length > 0 && allColumnList.length > 0) {
        tabColumnsFieldList.map((item) => {
          allColumnList.map((key) => {
            if (key["field"] === item) {
              // let newItem = key;
              // newItem["dataType"] = key["data_type"];
              newColumnsList.push(key);
            }
          });
        });
      }
      let columnsList = FormattedColumns(newColumnsList);

      setColumns(columnsList);
    } catch (error) {
      if (error.response.request.status === 404) {
        let newList = [];
        let allColumnsList = userStore.AllColumnsList;
        if (Columns.length > 0 && allColumnsList.length > 0) {
          Columns.map((item) => {
            allColumnsList.map((key) => {
              if (key["field"] === item["field"]) {
                newList.push(key);
              }
            });
          });
        }
        let columnsList = FormattedColumns(newList);
        setColumns(columnsList);
      }
      console.log(error);
    }
  };

  const getPageSize = () => {
    return userStore.tabInfo.hasOwnProperty(tabId)
      ? userStore.tabInfo[tabId]["page_size"]
      : pageSize;
  };
  const getpageNumber = () => {
    return userStore.tabInfo.hasOwnProperty(tabId)
      ? userStore.tabInfo[tabId]["page"]
      : 0;
  };
  const getLeadsList = async () => {
    setRow([]);
    props.setLoadingTabItemCount(true);
    setLoading(true);
    let response = await getLeadsApi({
      page_size: getPageSize(),
      page: getpageNumber(),
      filters: filters,
      sort_on: sorting,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    }
    userStore.updateTabCounterList({
      tabID: tabId,
      count: response.item_count,
    });
    setLoading(false);
    props.setLoadingTabItemCount(false);
  };

  const init = async () => {
    await getColumnsForTab();
    if (sorting !== null) {
      delete sorting["flag"];
    }

    await getLeadsList();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    init();
  }, [
    tid,
    refresh,
    userStore.tabFilters[tabId],
    tabId,
    userStore.sortingTabs[tabId],
    refreshColumn,
    props.refresh,
  ]);
  const handlePage = async (e, page) => {
    userStore.updatePaging({ id: tabId, page: page - 1 });
    await getLeadsList();
    console.log("tabinfo:::", JSON.stringify(userStore.tabInfo));
  };

  const handleDeleteLead = async () => {
    props.setOpenDeleteLead(false);
    let response_flag = await deleteMultipleLeadsApi({ idList: leads[0] });
    if (response_flag) {
      setDeletingLead(false);
      setRefresh(!refresh);
      setOpenSuccessDialog(true);
    } else {
      setDeletingLead(false);
      setOpenErrorDialog(true);
    }
  };

  return (
    <>
      <Container>
        <Wrapper>
          {!loading ? (
            <>
              <TableWrapper>
                <Box
                  sx={{
                    "& .last-contacted-today": {
                      backgroundColor: "#E6EDF7",
                    },
                    "& .not-last-contacted-today": {
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                >
                  <DataGrid
                    autoHeight={true}
                    rows={row}
                    getRowClassName={(params) =>
                      isLastContactedToday(params.row["last_contacted_on"])
                        ? "last-contacted-today"
                        : "not-last-contacted-today"
                    }
                    columns={columns}
                    pageSize={getPageSize()}
                    checkboxSelection
                    style={{ cursor: "pointer" }}
                    hideFooter={true}
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Array(ids);
                      props.setAssignState(selectedIDs[0].length);
                      setLeads(selectedIDs);
                    }}
                    loading={loading}
                    disableColumnMenu={true}
                    onRowClick={(e) => {
                      analytics.triggerEvent(
                        4625000035,
                        "lead_row_tap",
                        "leads_page",
                        "",
                        {}
                      );
                      authStore.updateLastActiveTime();
                      navigate(`/users/${uid}/leads/${e.id}`);
                    }}
                  />
                </Box>
              </TableWrapper>
              <PaginationWrapper>
                <PaginationComponent
                  page_no={getpageNumber()}
                  row={row}
                  size={getPageSize()}
                  page_size={getPageSize()}
                  count={count}
                  handlePageSize={handlePageSize}
                  handlePage={handlePage}
                />
              </PaginationWrapper>
            </>
          ) : (
            <SpinnerWrapper>
              <CircularProgress />
            </SpinnerWrapper>
          )}

          {props.open && (
            <LeadAssignModal
              open={props.open}
              setOpen={props.setOpen}
              leads={leads}
              pid={pid}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          )}
          {props.openSelectCol &&
            Columns.length > 0 &&
            props.allColumns.length > 0 && (
              <ColumnSelectionModal
                open={props.openSelectCol}
                setOpen={props.setOpenSelectCol}
                selectedColumns={Columns}
                allColumns={props.allColumns}
                tabId={tabId}
                refresh={refreshColumn}
                setRefresh={setRefreshColumn}
              />
            )}
        </Wrapper>
      </Container>

      {props.openDeleteLead && (
        <DeleteLeadConfirmationDialog
          open={props.openDeleteLead}
          setOpen={props.setOpenDeleteLead}
          leads={leads}
          setRefresh={setRefresh}
          refresh={refresh}
          handleConfirm={() => {
            setDeletingLead(true);
            handleDeleteLead();
          }}
        />
      )}

      <Backdrop
        open={deletingLead}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
      {openSuccessDialog && (
        <SuccessMessageDialog
          open={openSuccessDialog}
          setOpen={setOpenSuccessDialog}
          message={"Leads deleted successfully!"}
        />
      )}
      {openErrorDialog && (
        <ErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
        />
      )}
    </>
  );
};

export default observer(NewLeadTab);
