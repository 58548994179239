import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { CircularProgress, Grid } from "@material-ui/core";
import { getProductDetailsApi } from "../../Api";
import { IsoToLocalDate, IsoToLocalTime, dataRenderer } from "../../Functions";
import rootStore from "../../stores/RootStore";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ViewAdminUserSidepanel = ({ data }) => {
  return (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>User Details</ModelHeader>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>ID</Label>
              <Detail>{dataRenderer(data["flid"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Name</Label>
              <Detail>{dataRenderer(data["name"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Phone</Label>
              <Detail>{dataRenderer(data["phone"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Email</Label>
              <Detail>{dataRenderer(data["email"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Employee ID</Label>
              <Detail>{dataRenderer(data["employee_id"])}</Detail>
            </InputWrapper>
          </Grid>

          <Grid item xs={6}>
            <InputWrapper>
              <Label>Group</Label>
              <Detail>{dataRenderer(data["group"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Designation</Label>
              <Detail>{dataRenderer(data["designation"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Role</Label>
              <Detail>{dataRenderer(data["role"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Created At</Label>
              <Detail>
                {data["created_at"] !== null
                  ? IsoToLocalTime(data["created_at"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Last Action At</Label>
              <Detail>
                {data["last_action_at"] !== null
                  ? IsoToLocalTime(data["last_action_at"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Bank Name</Label>
              <Detail>{dataRenderer(data["bank_name"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Bank Account Number</Label>
              <Detail>{dataRenderer(data["bank_account_number"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Bank Account Name</Label>
              <Detail>{dataRenderer(data["bank_acccount_name"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>IFSC Code</Label>
              <Detail>{dataRenderer(data["ifsc_code"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>PAN Number</Label>
              <Detail>{dataRenderer(data["pan_number"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Reporting To</Label>
              <Detail>
                {data["reporting_to"] !== null
                  ? dataRenderer(data["reporting_to"]["name"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
        </Grid>
      </ModelWrapper>
    </Box>
  );
};

export default ViewAdminUserSidepanel;
