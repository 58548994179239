import { AddBoxOutlined, Edit, MoreHoriz } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  getAdminStatusesApi,
  getAdminStatusToStageApi,
  getTeamApi,
} from "../../Api";
import spinner from "../../assets/images/gifs/spinner.gif";
import {
  AdminStatusColumns,
  AdminStatusToStageColumns,
  TeamSettingColumns,
} from "../../Db";
import AddSatusToStageModal from "../admin_setting_modals/AddSatusToStageModal";
import AddStatusModal from "../admin_setting_modals/AddStatusModal";
import AddTeamModal from "../admin_setting_modals/AddTeamModal";
import analytics from "../../Analytics";
import { AgGridReact } from "ag-grid-react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { useMemo } from "react";
import { Chip } from "@mui/material";
import ViewUpdateFieldsModal from "../admin_setting_modals/ViewUpdateFieldsModal";
import { dataRenderer } from "../../Functions";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const Container = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 234px);`};

  position: relative;
  /* margin-top: 70px; */
`;
const Wrapper = styled.div`
  display: flex;
`;

const DetailsWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const AddBtnWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;

const StatusToStageTab = (props) => {
  const [row, setRow] = useState();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [viewUpdateFields, setViewUpdateFields] = useState(false);
  let navigate = useNavigate();

  const compareFunction = (a, b) => {
    const firstComparison = a.lead_stage.stage.localeCompare(
      b.lead_stage.stage
    );

    if (firstComparison === 0) {
      return a.lead_status.status.localeCompare(b.lead_status.status);
    }

    return firstComparison;
  };

  useEffect(() => {
    const getStatusList = async () => {
      try {
        let response = await getAdminStatusToStageApi(props.id);

        let sortedData = response.data.sort(compareFunction);
        setRow(sortedData);
      } catch (error) {
        console.log(error);
      }
    };
    getStatusList();
  }, [refresh]);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };
  const actionColumn = [
    {
      field: "update_fields",
      headerName: "FIELDS TO UPDATE",
      width: 150,
      cellRenderer: (params) => {
        if (params.data.update_fields.length > 0) {
          return (
            <IconButton
              onClick={() => {
                setEditableData(params.data);
                setViewUpdateFields(true);
              }}
            >
              <MoreHoriz color="primary" />
            </IconButton>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "lead_stage",
      headerName: "JUMP TO STAGE",
      sortable: false,

      width: 230,
      cellRenderer: (params) => {
        let value =
          params.data.jump_to !== null
            ? dataRenderer(params.data.jump_to.stage)
            : "-";

        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTIONS",
      sortable: false,
      minWidth: 70,
      flex: 1,
      resizable: false,
      cellRenderer: (params) => {
        if (params.data.default) {
          return (
            <>
              <Edit
                onClick={(e) => {}}
                style={{ width: "18px", height: "18px" }}
                color="disabled"
              />
            </>
          );
        } else {
          return (
            <>
              <Edit
                onClick={(e) => {
                  analytics.triggerEvent(
                    4625000221,
                    "edit_button_tap",
                    "project_statustostage_page",
                    "edit_button",
                    {}
                  );
                  setEditableData(params.data);
                  setIsEdit(true);
                  setOpen(true);
                }}
                style={{
                  width: "18px",
                  height: "18px",
                  cursor: "pointer",
                }}
                color="primary"
              />
            </>
          );
        }
      },
    },
  ];
  return (
    <>
      <Container>
        <AddBtnWrapper>
          <AddBoxOutlined
            onClick={() => {
              analytics.triggerEvent(
                4625000217,
                "add_button_tap",
                "project_statustostage_page",
                "add_button",
                {}
              );
              setOpen(true);
            }}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </AddBtnWrapper>
        <Wrapper>
          <DetailsWrapper>
            {row ? (
              <TableWrapper>
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={AdminStatusToStageColumns.concat(actionColumn)}
                    animateRows={true}
                    suppressCellFocus
                    pagination={false}
                    className="paginated-ag-grid"
                    onRowClicked={(row) => {
                      if (row.event.defaultPrevented) {
                        return null;
                      }
                    }}
                    overlayNoRowsTemplate={"No Contacts found."}
                  />
                </div>
              </TableWrapper>
            ) : (
              <TableWrapper
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </TableWrapper>
            )}
          </DetailsWrapper>
        </Wrapper>
      </Container>
      {open && (
        <AddSatusToStageModal
          id={props.id}
          setOpen={setOpen}
          open={open}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          editableData={editableData}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      {viewUpdateFields && (
        <ViewUpdateFieldsModal
          setOpen={setViewUpdateFields}
          open={viewUpdateFields}
          editableData={editableData}
        />
      )}
    </>
  );
};

export default StatusToStageTab;
