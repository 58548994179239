import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { editContactApi, editLeadApi, getPhoneApi } from "../../Api";
import {
  IsoToLocalDate,
  dateHandler,
  getIsoString,
  getLocalizedText,
} from "../../Functions";
import { myTheme } from "../../themeUtils";
import { useEffect } from "react";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import moment from "moment";
import MuiAlert from "@mui/material/Alert";
import {
  Box,
  Button,
  CircularProgress,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";
import GroupFieldWidget from "../create_lead_modal/group_field_widget/GroupFieldWidget";
import SingleSelect from "../select_dropdown/SingleSelect";
import SingleSelectNew from "../select_dropdown/SingleSelectNew";
import { Grid } from "@material-ui/core";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;
const ModelSubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  flex-direction: column;
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;
const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: #f9f9fc;
`;

const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 2px 6px;
  outline: none;
  border: 1px solid #e0deca;
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 14px Open Sans;
    color: #b5b5b5;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled("textarea")`
  width: 100%;
  height: 60px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #e0deca;
  color: #4d4e4f;

  resize: none;
  font: normal normal 600 14px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 14px Open Sans;
    color: #b5b5b5;
  }
`;

const InputContainer = styled(Box)`
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  white-space: pre-line;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const EditLeadComponent = (props) => {
  const { userStore } = rootStore;
  const [details, setDetails] = useState({});
  const [changedValues, setChangedValues] = useState({});
  const columnsList = userStore.AllColumnsList;
  const [sortedColumnsList, setSortedColumnsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [parentChildData, setParentChildData] = useState({});

  const handleChange = ({ e, dataType }) => {
    let prevDetailsState = { ...details };
    let prevChangedState = { ...changedValues };
    prevDetailsState[e.target.name] = {
      value: e.target.value,
      type: dataType,
    };
    prevChangedState[e.target.name] = {
      value: e.target.value,
      type: dataType,
    };
    let index = columnsList.findIndex(
      (column) => column["field"] === e.target.name
    );
    let columnMeta = columnsList[index];
    if (columnMeta["parent"]) {
      let childList = parentChildData[columnMeta["id"]];

      childList.forEach((child) => {
        prevDetailsState[child["field"]] = {
          value: "",
          type: child["data_type"],
        };

        prevChangedState[child["field"]] = {
          value: "",
          type: child["data_type"],
        };
      });
    }
    setDetails(prevDetailsState);
    setChangedValues(prevChangedState);
  };

  const identifyDateFormat = (dateString) => {
    if (!isNaN(dateString)) {
      const m = moment(parseInt(dateString));
      if (m.isValid()) {
        return "epoch";
      }
    } else {
      const m = moment(dateString);
      if (m.isValid()) {
        return "ISO";
      }
    }
    return "unknown";
  };

  const dateFieldHandler = ({ item }) => {
    let value = null;
    let dateStringType = identifyDateFormat(item);
    if (item !== null) {
      if (dateStringType !== "unknown") {
        if (dateStringType === "epoch") {
          value = dateHandler(item);
        } else {
          value = IsoToLocalDate(item);
        }
      }
    }
    return value;
  };

  const getLeadPhone = async () => {
    let response = await getPhoneApi(props.id);
    return response.data;
  };

  const dataGetter = async (data, key, type) => {
    if (data.hasOwnProperty(key)) {
      let value = data[key];
      if (key === "phone") {
        value = await getLeadPhone();
      }
      if (type === "date" || type === "datetime") {
        value = dateFieldHandler({ item: value });
      }
      return value !== null ? value : "";
    } else {
      return "";
    }
  };

  const generateContactPayloadData = (data) => {
    let contactFields = {};
    for (const key in data) {
      let value = data[key]["value"];
      if (data[key]["type"] === "date" || data[key]["type"] === "datetime") {
        value = getIsoString(value);
      } else if (data[key]["type"] === "integer") {
        value = parseInt(value);
      }

      contactFields[key] = value;
    }

    return contactFields;
  };

  const getContactData = () => {
    let tempList = {};
    for (const key in changedValues) {
      let index = columnsList.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let columnData = columnsList[index];
        if (columnData["contact_column"] !== null) {
          tempList[columnData["contact_column"]] =
            changedValues[columnData["field"]];
        }
      }
    }
    return tempList;
  };

  const submitEditLead = async () => {
    let newValue = {};
    let newCustomValue = {};
    if (Object.keys(changedValues).length !== 0) {
      Object.keys(changedValues).forEach((key) => {
        let index = columnsList.findIndex((column) => column["field"] === key);
        if (index !== -1) {
          if (
            changedValues[key]["type"] === "date" ||
            changedValues[key]["type"] === "datetime"
          ) {
            let newDate = getIsoString(changedValues[key]["value"]);

            if (columnsList[index]["is_custom_param"]) {
              let new_key_name = key.replace("custom_params_", "");
              newCustomValue[new_key_name] = newDate;
            } else {
              newValue[key] = newDate;
            }
          } else {
            if (columnsList[index]["is_custom_param"]) {
              let new_key_name = key.replace("custom_params_", "");
              newCustomValue[new_key_name] = changedValues[key]["value"];
            } else {
              newValue[key] = changedValues[key]["value"];
            }
          }
        }
      });
    }

    let payload = {
      ...newValue,
      custom_params: { ...newCustomValue },
    };
    try {
      let response = await editLeadApi({
        id: props.id,
        data: payload,
      });
      props.setRefreshLeadDetails(true);
      setIsSubmitSuccess(true);
    } catch (error) {
      setErrorMessage(getLocalizedText("some_error_occurred"));
      setIsSubmitFail(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let contactData = getContactData();
    if (Object.keys(contactData).length > 0) {
      if (
        props.leadDetails["contact_id"] !== null &&
        props.leadDetails["contact_id"] !== ""
      ) {
        let contactPayload = generateContactPayloadData(contactData);
        let updateContactResponse = await editContactApi({
          contactID: props.leadDetails["contact_id"],
          payload: contactPayload,
        });
        if (!updateContactResponse.hasError()) {
          await submitEditLead();
        } else {
          setErrorMessage(updateContactResponse.errorMessage);
          setIsSubmitFail(true);
        }
      }
    } else {
      await submitEditLead();
    }
  };

  const initEditableData = async (filteredListByColumn, parentChildData) => {
    let newFieldList = {};
    let custom_data = props.leadDetails.hasOwnProperty("custom_params")
      ? JSON.parse(props.leadDetails["custom_params"])
      : {};
    if (filteredListByColumn.length > 0) {
      for (let i = 0; i < filteredListByColumn.length; i++) {
        let item = filteredListByColumn[i];
        if (item["parent"] === true) {
          let children = parentChildData[item["id"]];
          for (let j = 0; j < children.length; j++) {
            let childItem = children[j];

            if (childItem["is_custom_param"]) {
              let new_field = childItem["field"].replace("custom_params_", "");
              let value = await dataGetter(
                custom_data,
                new_field,
                childItem["data_type"]
              );
              newFieldList[childItem["field"]] = {
                value: value,
                type: childItem["data_type"],
              };
            } else {
              let value = await dataGetter(
                props.leadDetails,
                childItem["field"],
                childItem["data_type"]
              );
              newFieldList[childItem["field"]] = {
                value: value,
                type: childItem["data_type"],
              };
            }
          }
        }
        if (item["is_custom_param"]) {
          let new_field = item["field"].replace("custom_params_", "");
          let value = await dataGetter(
            custom_data,
            new_field,
            item["data_type"]
          );
          newFieldList[item["field"]] = {
            value: value,
            type: item["data_type"],
          };
        } else {
          let value = await dataGetter(
            props.leadDetails,
            item["field"],
            item["data_type"]
          );
          newFieldList[item["field"]] = {
            value: value,
            type: item["data_type"],
          };
        }
      }
    }
    setDetails(newFieldList);
  };

  function setupColumns() {
    if (columnsList.length > 0) {
      let tempColumns = [];
      for (let id of props.columnIds) {
        let index = columnsList.findIndex((element) => element["id"] === id);
        if (index !== -1) {
          if (columnsList[index]["editable"]) {
            tempColumns.push(columnsList[index]);
          }
        }
      }
      let requiredFields = tempColumns
        .filter((item) => item["required"] && item["child_of"] === null)
        .slice();
      requiredFields.sort((a, b) =>
        a["headerName"]
          .toLowerCase()
          .localeCompare(b["headerName"].toLowerCase())
      );

      let parentFields = tempColumns.filter((item) => item["parent"]).slice();
      parentFields.sort((a, b) =>
        a["headerName"]
          .toLowerCase()
          .localeCompare(b["headerName"].toLowerCase())
      );

      let allFields = requiredFields.slice();

      parentFields.forEach((data) => {
        let index = allFields.findIndex(
          (element) => element["id"] === data["id"]
        );
        if (index === -1) {
          allFields.push(data);
        }
      });

      let parentChildMap = {};
      for (let item of tempColumns) {
        if (item["parent"]) {
          let parentId = item["id"];
          let children = tempColumns
            .filter(
              (child) =>
                child["child_of"] !== null &&
                child["child_of"]["id"] === parentId
            )
            .slice()
            .sort((a, b) =>
              a["headerName"]
                .toLowerCase()
                .localeCompare(b["headerName"].toLowerCase())
            );
          parentChildMap[parentId] = children;
        }
      }

      let remainingFields = [];
      tempColumns.forEach((item) => {
        let index = allFields.findIndex(
          (element) => element["id"] === item["id"]
        );
        if (index === -1 && item["child_of"] === null) {
          remainingFields.push(item);
        }
      });
      remainingFields.sort((a, b) =>
        a["headerName"]
          .toLowerCase()
          .localeCompare(b["headerName"].toLowerCase())
      );
      remainingFields.forEach((data) => {
        let index = allFields.findIndex(
          (element) => element["id"] === data["id"]
        );
        if (index === -1) {
          allFields.push(data);
        }
      });

      allFields.sort((a, b) => {
        if (a.is_multiline && !b.is_multiline) {
          return 1;
        } else if (!a.is_multiline && b.is_multiline) {
          return -1;
        } else {
          return 0;
        }
      });

      //*************width arrangement for columns************** */
      for (const item of allFields) {
        if (item.is_multiline || item.parent) {
          item.width = 2;
          item.modifyWidth = false;
        } else {
          item.width = 1;
          item.modifyWidth = true;
        }
      }
      for (let i = 0; i < allFields.length; i++) {
        let currentItem = allFields[i];

        if (currentItem.modifyWidth) {
          if (i === allFields.length - 1) {
            currentItem.width = 2;
            currentItem.modifyWidth = false;
          } else {
            let nextItem = allFields[i + 1];

            if (nextItem.width === 2) {
              currentItem.width = 2;
              currentItem.modifyWidth = false;
            } else if (nextItem.width === 1) {
              currentItem.width = 1;
              currentItem.modifyWidth = false;
              nextItem.modifyWidth = false;
            }
          }
        }
      }
      //***********************END*************************** */

      return { columnMetaList: allFields, parentChildList: parentChildMap };
    }
  }
  const init = async () => {
    let metaData = setupColumns();
    setSortedColumnsList(metaData.columnMetaList);
    await initEditableData(metaData.columnMetaList, metaData.parentChildList);
    setParentChildData(metaData.parentChildList);
    setIsLoading(false);
  };
  useEffect(() => {
    init();
  }, []);
  const getRegex = (item) => {
    if (item.regex === null || item.regex === "") {
      return null;
    } else {
      return item.regex;
    }
  };
  const getMinMaxValue = ({ value, dataType }) => {
    if (value !== null && value !== "") {
      if (dataType === "integer") {
        return parseInt(value);
      } else if (dataType === "date") {
        return IsoToLocalDate(value);
      }
    }
    return "";
  };

  const renderField = ({ item }) => {
    if (item.filter_input === "" || item.filter_input === null) {
      if (item.is_multiline === false) {
        return (
          <Grid item xs={item["width"] === 1 ? 6 : 12}>
            <InputWrapper>
              <Label>
                {item.headerName}
                {item.required && <span style={{ color: "red" }}>*</span>}
              </Label>
              <Input
                type={
                  item.data_type === "email"
                    ? "email"
                    : item.data_type === "datetime" || item.data_type === "date"
                    ? "date"
                    : item.data_type === "integer"
                    ? "number"
                    : "text"
                }
                disabled={!item["editable"] || item["derived"]}
                value={
                  details.hasOwnProperty(item.field)
                    ? details[item.field]["value"]
                    : ""
                }
                name={item.field}
                placeholder={
                  item.hint !== null && item.hint !== ""
                    ? item.hint
                    : `Enter ${item.headerName}`
                }
                pattern={getRegex(item)}
                required={item.required}
                min={getMinMaxValue({
                  value: item["min"],
                  dataType: item["data_type"],
                })}
                max={getMinMaxValue({
                  value: item["max"],
                  dataType: item["data_type"],
                })}
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    `Please enter a valid ${item.headerName}`
                  )
                }
                onChange={(e) => {
                  e.target.setCustomValidity("");
                  let dataType = item.data_type;
                  handleChange({ e, dataType });
                }}
              />
            </InputWrapper>
          </Grid>
        );
      } else {
        return (
          <Grid item xs={item["width"] === 1 ? 6 : 12}>
            <InputWrapper>
              <Label>
                {item.headerName}
                {item.required && <span style={{ color: "red" }}>*</span>}
              </Label>
              <TextArea
                value={
                  details.hasOwnProperty(item.field)
                    ? details[item.field]["value"]
                    : ""
                }
                name={item.field}
                placeholder={
                  item.hint !== null && item.hint !== ""
                    ? item.hint
                    : `Enter ${item.headerName}`
                }
                pattern={getRegex(item)}
                required={item.required}
                disabled={!item["editable"] || item["derived"]}
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    `Please enter a valid ${item.headerName}`
                  )
                }
                onChange={(e) => {
                  e.target.setCustomValidity("");
                  let dataType = item.data_type;
                  handleChange({ e, dataType });
                }}
              />
            </InputWrapper>
          </Grid>
        );
      }
    } else if (
      item.filter_input.charAt(0) === "/" ||
      item.filter_input.startsWith("ep:")
    ) {
      return (
        <Grid item xs={item["width"] === 1 ? 6 : 12}>
          <InputWrapper>
            <Label>
              {item.headerName}
              {item.required && <span style={{ color: "red" }}>*</span>}
            </Label>
            <SingleSelect
              item={item}
              headerName={item.headerName}
              setSelectedVal={setDetails}
              selectedVal={details}
              field={item.field}
              required={item.required}
              type={item.data_type}
              handleChange={(e) =>
                handleChange({ e, dataType: item["data_type"] })
              }
            />
          </InputWrapper>
        </Grid>
      );
    } else if (
      item.filter_input.charAt(0) !== "/" &&
      item.filter_input !== "" &&
      item.filter_input !== null
    ) {
      return (
        <Grid item xs={item["width"] === 1 ? 6 : 12}>
          <InputWrapper>
            <Label>
              {item.headerName}
              {item.required && <span style={{ color: "red" }}>*</span>}
            </Label>
            <SingleSelectNew
              item={item}
              list={item.filter_input}
              field={item.field}
              headerName={item.headerName}
              setSelectedVal={setDetails}
              selectedVal={details}
              required={item.required}
              type={item.data_type}
              handleChange={(e) =>
                handleChange({ e, dataType: item["data_type"] })
              }
            />
          </InputWrapper>
        </Grid>
      );
    }
  };

  return !isLoading ? (
    !isSubmitSuccess ? (
      <>
        {sortedColumnsList.length > 0 && Object.keys(details).length > 0 ? (
          <Box role="presentation">
            <AppBar
              component={"nav"}
              position="sticky"
              color="inherit"
              elevation={0}
              sx={{
                top: 0,
                bottom: "auto",
                width: 500,
                right: 0,
                bgcolor: "#f9f9fc",
              }}
            >
              <Toolbar sx={{ padding: "24px" }}>
                <Column>
                  <ModelHeader>
                    {getLocalizedText("edit_information")}
                  </ModelHeader>
                  <ModelSubHeader>
                    {getLocalizedText("please_fill_the_below_details")}
                  </ModelSubHeader>
                </Column>
              </Toolbar>
            </AppBar>

            <ModelWrapper
              component={"form"}
              onSubmit={handleSubmit}
              style={{ paddingBottom: "50px" }}
            >
              <>
                <InputContainer>
                  <Grid container spacing={3}>
                    {sortedColumnsList.map((item) => {
                      if (item["parent"] === true) {
                        return (
                          <Grid item xs={12}>
                            <GroupFieldWidget
                              isEdit={true}
                              item={item}
                              renderField={renderField}
                              details={details}
                              setDetals={setDetails}
                              allChildList={parentChildData[item["id"]]}
                              handleChange={(event) =>
                                handleChange({
                                  e: event,
                                  dataType: item["data_type"],
                                })
                              }
                              handleFilledParentChildMap={() => {}}
                            />
                          </Grid>
                        );
                      } else {
                        return renderField({ item: item });
                      }
                    })}
                  </Grid>
                </InputContainer>
              </>
              <AppBar
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{ top: "auto", bottom: 0, width: 500 }}
              >
                <Toolbar style={{ width: 500, position: "relative" }}>
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <ButtonWrapper>
                    <Button
                      onClick={() => {
                        props.setOpen(false);
                      }}
                      variant="outlined"
                      color="primary"
                      style={{ width: "100px", marginRight: "8px" }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        width: "100px",
                        ...myTheme.Button.btnBlue,
                      }}
                    >
                      {getLocalizedText("submit")}
                    </Button>
                  </ButtonWrapper>
                </Toolbar>
              </AppBar>
            </ModelWrapper>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              width: 500,
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper>
              <Message
                style={{
                  color: "gray",
                  fontSize: "20px",
                  marginBottom: "10px",
                }}
              >
                {getLocalizedText("no_fields_to_edit")}
              </Message>
              <Button
                onClick={() => {
                  props.setOpen(false);
                }}
                variant="outlined"
                style={{
                  ...myTheme.Button.btnLight,
                  width: "fit-content",
                  textTransform: "none",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </Box>
        )}
      </>
    ) : (
      <Box
        sx={{
          display: "flex",
          width: 500,
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MessageWrapper>
          <Message>{getLocalizedText("info_updated_successfully")}</Message>
          <Button
            onClick={() => {
              props.setOpen(false);
            }}
            variant="outlined"
            style={{
              ...myTheme.Button.btnLight,
              width: "fit-content",
              textTransform: "none",
            }}
          >
            {getLocalizedText("close")}
          </Button>
        </MessageWrapper>
      </Box>
    )
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(EditLeadComponent);
