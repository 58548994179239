import React from "react";
import { FormControl, Select, makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { Box, MenuItem, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { LeadFilterTypeList } from "../../../Config";
import { DatePicker } from "antd";
import moment from "moment";
import { getLocalizedText } from "../../../Functions";

const InputContainer = styled(Box)`
  display: flex;
  width: 520px;
  column-gap: 20px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 250px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const DateRangeFilterComponent = (props) => {
  const classes = useStyles();
  const { RangePicker } = DatePicker;

  return (
    <InputContainer>
      <InputWrapper>
        <Label>{getLocalizedText("select_date_filter_type")}</Label>
        <FormControl
          classes={{
            root: classes.quantityRoot,
          }}
        >
          <Select
            classes={{
              icon: classes.icon,
            }}
            value={props.dateFilterType}
            displayEmpty
            disableUnderline
            variant="outlined"
            onChange={(e) => {
              props.setDateFilterType(e.target.value);
              props.setDateRangeValue(null);
              props.setShowDateRangeAlert(false);
            }}
            required
            style={{
              width: "100%",
              height: "30px",
              fontSize: "12px",
              fontWeight: "bold",
              borderRadius: "0px",
            }}
          >
            {LeadFilterTypeList.map((e, i) => {
              return (
                <MenuItem id={i} value={e.label}>
                  {getLocalizedText(e.key, "project")}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </InputWrapper>

      <InputWrapper>
        <Label>{getLocalizedText("select_date_range")}</Label>
        <RangePicker
          disabled={
            props.dateFilterType === "All Leads" || props.dateFilterType === ""
          }
          placeholder={["From", "To"]}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode;
          }}
          value={
            props.dateRangeValue && [
              moment(props.dateRangeValue["start_date"], "YYYY-MM-DD"),
              moment(props.dateRangeValue["end_date"], "YYYY-MM-DD"),
            ]
          }
          defaultValue={
            props.dateRangeValue && [
              moment(props.dateRangeValue["start_date"], "YYYY-MM-DD"),
              moment(props.dateRangeValue["end_date"], "YYYY-MM-DD"),
            ]
          }
          style={{ height: "30px", width: "100%" }}
          onChange={(value) => {
            let d1 = moment(value[0]).format("YYYY-MM-DD");
            let d2 = moment(value[1]).format("YYYY-MM-DD");
            props.setDateRangeValue({
              ...props.dateRangeValue,
              start_date: d1,
              end_date: d2,
            });
          }}
        />
      </InputWrapper>
    </InputContainer>
  );
};

export default observer(DateRangeFilterComponent);
