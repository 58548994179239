import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
import rootStore from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { CircularProgress, Typography } from "@material-ui/core";
import { Box } from "@mui/material";
import { getStageColorCode, getStatusColorCode } from "../../../Functions";
Chart.register(ArcElement);

const Container = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: ${(e) => (e.type === "preview" ? "0px" : "20px")};
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
`;

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
`;
const Text = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const OneGroupBarGraphWidget = (props) => {
  const { userStore } = rootStore;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const generateRandomColor = (val) => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    userStore.updateColorsList({ key: val, color: color });
    return color;
  };

  const updateData = (response, v2Response) => {
    let aggregateBy = v2Response["xdata"].hasOwnProperty(
      "aggregate_by_col_names"
    )
      ? v2Response["xdata"]["aggregate_by_col_names"]
      : "count"; //here if xdata has the aggregate_by_col_names then it will use that else by default it will use "count"
    let newObj = {
      labels: [],
      datasets: [{ label: aggregateBy, data: [], backgroundColor: [] }],
    };
    if (response.length > 0) {
      response.map((item) => {
        let keyname = v2Response["xdata"]["group_by"][0];
        newObj.labels.push(item[keyname]);
        newObj.datasets[0].data.push(item[aggregateBy]);
        if (keyname === "lead_stage") {
          let color = getStageColorCode(item[keyname]);
          newObj.datasets[0].backgroundColor.push(color);
        } else if (keyname === "lead_status") {
          let color = getStatusColorCode(item[keyname]);
          newObj.datasets[0].backgroundColor.push(color);
        } else {
          if (userStore.ColorsList.hasOwnProperty(item[keyname])) {
            let colorVal = userStore.ColorsList[item[keyname]];
            newObj.datasets[0].backgroundColor.push(colorVal);
          } else {
            let colorVal = generateRandomColor(item[keyname]);
            newObj.datasets[0].backgroundColor.push(colorVal);
          }
        }
      });
    } else {
      newObj = {};
    }
    return newObj;
  };

  const ChartBottomSection = () => {
    if (Object.keys(data).length === 0) {
      return (
        <Wrapper>
          <Typography>No data found!</Typography>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <Bar
            data={data}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </Wrapper>
      );
    }
  };

  useEffect(() => {
    const getData = async () => {
      let modifiedData = updateData(props.data, props.widgetConfig);
      setData(modifiedData);
      setLoading(false);
    };
    if (props.type !== "preview") {
      getData();
    }
  }, [props.widgetConfig]);
  return (
    <>
      {!loading ? (
        <Container>
          <Box>
            <Text>{props.widgetConfig.title}</Text>
          </Box>
          <ChartBottomSection />
        </Container>
      ) : (
        <Container style={{ alignItems: "center" }}>
          <CircularProgress />
        </Container>
      )}
    </>
  );
};

export default observer(OneGroupBarGraphWidget);
