import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";
import React, { Component } from "react";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { useState } from "react";
import "./addchart.css";
const groupByList = [
  { label: "Created At", value: "created_at" },
  { label: "Updated At", value: "updated_at" },
  { label: "Assigned On", value: "lead_assigned_on" },
  { label: "Contacted On ", value: "last_contacted_on" },
  { label: "Next Followup On", value: "next_follow_up_on" },
  { label: "Status", value: "lead_status" },
  { label: "Source", value: "lead_source" },
  { label: "Stage", value: "lead_stage" },
  { label: "Owner", value: "lead_owner" },
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "30px",
    height: "30px",
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "30px",
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "30px",
  }),
};

const MySelect = (props) => {
  return (
    <ReactSelect
      maxMenuHeight={100}
      isSearchable={true}
      {...props}
      isOptionDisabled={() => props.value.length >= 2}
      options={[...props.options]}
      styles={customStyles}
      onChange={(selected) => {
        props.setShowAlert(false);
        return props.onChange(selected);
      }}
    />
  );
};

MySelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

MySelect.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span style={{ width: "fit-content" }}>{props.data.label}</span>
  </components.MultiValue>
);

const animatedComponents = makeAnimated();
const GroupByMultiSelect = (props) => {
  const handleChange = (selected) => {
    props.setState(selected);
  };

  {
    return (
      <MySelect
        setShowAlert={props.setShowAlert}
        options={groupByList}
        isMulti
        className="chart_multi_select"
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option, MultiValue, animatedComponents }}
        onChange={handleChange}
        allowSelectAll={false}
        value={props.state}
      />
    );
  }
};

export default GroupByMultiSelect;
