import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import {
  addAdminColumnApi,
  editAdminColumnApi,
  editAdminSystemColumnApi,
} from "../../Api";
import { useEffect } from "react";
import { myTheme } from "../../themeUtils";
import analytics from "../../Analytics";
import { getLocalizedText } from "../../Functions";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 30px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const Input = styled.input`
  width: 428px;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  margin-bottom: 20px;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const ButtonWrapper = styled.div`
  /* margin-top: 30px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const SubStatusContainer = styled.div`
  width: 428px;
  max-height: 80px;
  overflow-y: auto;
  border: none;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 4px;
  padding: 4px 0px;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const AddColumnModal = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({
    display_name: "",
    group: "",
    filterable: true,
    editable: false,
    is_visible: true,
    data_type: "",
    required: true,
    enable_for_create: true,
  });
  const dataTypeList = ["string", "integer", "date", "boolean"];
  const filterTypeList = {
    string: ["normal", "list"],
    integer: ["normal", "range", "list"],
    date: ["normal", "range"],
    boolean: ["normal"],
  };
  const [inputList, setInputList] = useState([]);
  const [filterType, setFilterType] = useState("");
  const [radioVal, setRadioVal] = useState("yes");
  const [editableVal, setEditableVal] = useState("no");
  const [requiredVal, setRequiredVal] = useState("yes");
  const [enableForCreate, setEnableForCreate] = useState("yes");
  const [isVisible, setIsVisible] = useState("yes");
  const [disabledFields, setDisabledFields] = useState([]);

  const handleClick = () => {
    setUser({
      display_name: "",
      group: "",
      filterable: true,
      is_visible: true,
      editable: false,
      required: true,
      data_type: "",
    });
    setInputList([]);
    props.setOpen(false);
    props.setIsEdit(false);
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const editColumn = async () => {
      let data = {
        column_id: props.editableData.id,
        field: props.editableData.field,
        display_name: user.display_name,
        is_visible: user.is_visible,
        group: user.group,
        filterable: user.filterable,
        editable: user.editable,
        required: user.required,
        enable_for_create: user.enable_for_create,
        data_type: user.data_type,
      };

      if (user.filterable) {
        data["filter_type"] = filterType;
        if (filterType === "list") {
          data["filter_input"] = inputList.toString();
        }
      }

      try {
        if (props.editableData.system) {
          let response = await editAdminSystemColumnApi({
            id: props.id,
            data: data,
          });
        } else {
          let response = await editAdminColumnApi({
            id: props.id,
            data: data,
          });
        }
        props.setRefresh(!props.refresh);
        props.setOpen(false);
        props.setIsEdit(false);
      } catch (error) {
        console.log(error);
      }
    };
    const addColumn = async () => {
      let field = "custom_params_" + user.display_name.replace(" ", "_");
      let data = {
        field: field,
        display_name: user.display_name,
        group: user.group,
        is_visible: user.is_visible,
        filterable: user.filterable,
        editable: user.editable,
        required: user.required,
        data_type: user.data_type,
        enable_for_create: user.enable_for_create,
      };
      if (user.filterable) {
        data["filter_type"] = filterType;
        if (filterType === "list") {
          data["filter_input"] = inputList.toString();
        }
      }
      try {
        let response = await addAdminColumnApi({
          id: props.id,
          data: data,
        });
        props.setRefresh(!props.refresh);
        props.setOpen(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (props.isEdit) {
      editColumn();
    } else {
      addColumn();
    }
  };
  const handleClose = () => {
    setUser({
      display_name: "",
      group: "",
      filterable: true,
      is_visible: true,
      editable: false,
      data_type: "",
      required: true,
    });
    setInputList([]);
    props.setOpen(false);
    props.setIsEdit(false);
  };
  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };
  const handleSubStatus = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setInputList([...inputList, value]);
    e.target.value = "";
  };
  const handleDelete = (index) => {
    setInputList(inputList.filter((e, i) => i !== index));
  };
  useEffect(() => {
    if (props.isEdit === true) {
      analytics.triggerEvent(
        4625000231,
        "edit_column_form_load",
        "project_columns_page",
        "",
        {}
      );
      setUser({
        display_name: props.editableData.name,
        group: props.editableData.group,
        filterable: props.editableData.filterable,
        is_visible: props.editableData.is_visible,
        editable: props.editableData.editable,
        data_type: props.editableData.data_type,
        required: props.editableData.required,
        enable_for_create: props.editableData.enable_for_create,
      });
      setRadioVal(props.editableData.filterable ? "yes" : "no");
      setEditableVal(props.editableData.editable ? "yes" : "no");
      setRequiredVal(props.editableData.required ? "yes" : "no");
      setEnableForCreate(props.editableData.enable_for_create ? "yes" : "no");
      setIsVisible(props.editableData.is_visible ? "yes" : "no");

      if (props.editableData.hasOwnProperty("filter_type")) {
        setFilterType(props.editableData.filter_type);
        if (
          props.editableData.filter_input !== "" &&
          props.editableData.filter_input !== null
        ) {
          let newList = props.editableData.filter_input.split(",");
          setInputList(newList);
        }
      }
    } else {
      analytics.triggerEvent(
        4625000227,
        "add_column_form_load",
        "project_columns_page",
        "",
        {}
      );
      setUser({
        display_name: "",
        group: "",
        filterable: true,
        is_visible: true,
        editable: false,
        data_type: "",
        required: true,
        enable_for_create: true,
      });
      setInputList([]);
    }

    setDisabledList();
  }, []);

  const setDisabledList = () => {
    if (props.isEdit) {
      if (props.editableData["derived"]) {
        setDisabledFields([
          "group",
          "column_name",
          "required",
          "enable_for_create",
          "is_visible",
          "data_type",
        ]);
      } else if (props.editableData["system"]) {
        setDisabledFields(["column_name", "group", "data_type"]);
      }
    }
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              {props.isEdit ? (
                <Header>{getLocalizedText("edit_details")}</Header>
              ) : (
                <Header>{getLocalizedText("add_a_new_column")}</Header>
              )}
              <SubHeader>
                {getLocalizedText("fill_in_the_below_details")}
              </SubHeader>
              <Form onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>
                <InputContainer>
                  <InputWrapper>
                    <Label>{getLocalizedText("column_name")}*</Label>
                    <Input
                      value={user.display_name}
                      disabled={disabledFields.includes("column_name")}
                      required
                      type="text"
                      placeholder="Enter name here..."
                      name="display_name"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Label>{getLocalizedText("group_name")}</Label>
                    <Input
                      value={user.group}
                      disabled={disabledFields.includes("group")}
                      type="text"
                      placeholder="Enter group name here..."
                      name="group"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Label style={{ marginBottom: "0px", marginRight: "20px" }}>
                      {getLocalizedText("is_visible")}*
                    </Label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            size="small"
                            disabled={disabledFields.includes("is_visible")}
                            checked={isVisible === "yes"}
                            onChange={(e) => {
                              setIsVisible(e.target.value);

                              setUser({ ...user, is_visible: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            size="small"
                            disabled={disabledFields.includes("is_visible")}
                            checked={isVisible === "no"}
                            onChange={(e) => {
                              setIsVisible(e.target.value);

                              setUser({ ...user, is_visible: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>

                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Label style={{ marginBottom: "0px", marginRight: "20px" }}>
                      {getLocalizedText("required")}*
                    </Label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        disabled={disabledFields.includes("required")}
                        control={
                          <Radio
                            size="small"
                            checked={requiredVal === "yes"}
                            onChange={(e) => {
                              setRequiredVal(e.target.value);

                              setUser({ ...user, required: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        disabled={disabledFields.includes("required")}
                        control={
                          <Radio
                            size="small"
                            checked={requiredVal === "no"}
                            onChange={(e) => {
                              setRequiredVal(e.target.value);
                              setUser({ ...user, required: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Label style={{ marginBottom: "0px", marginRight: "20px" }}>
                      {getLocalizedText("filterable")}*
                    </Label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            size="small"
                            checked={radioVal === "yes"}
                            onChange={(e) => {
                              setRadioVal(e.target.value);

                              setUser({ ...user, filterable: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            size="small"
                            checked={radioVal === "no"}
                            onChange={(e) => {
                              setRadioVal(e.target.value);
                              setUser({ ...user, filterable: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>

                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Label style={{ marginBottom: "0px", marginRight: "20px" }}>
                      {getLocalizedText("editable")}*
                    </Label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            size="small"
                            checked={editableVal === "yes"}
                            onChange={(e) => {
                              setEditableVal(e.target.value);

                              setUser({ ...user, editable: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            size="small"
                            checked={editableVal === "no"}
                            onChange={(e) => {
                              setEditableVal(e.target.value);
                              setUser({ ...user, editable: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>

                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Label style={{ marginBottom: "0px", marginRight: "20px" }}>
                      {getLocalizedText("enable_for_create")}*
                    </Label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            size="small"
                            disabled={disabledFields.includes(
                              "enable_for_create"
                            )}
                            checked={enableForCreate === "yes"}
                            onChange={(e) => {
                              setEnableForCreate(e.target.value);

                              setUser({ ...user, enable_for_create: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            size="small"
                            disabled={disabledFields.includes(
                              "enable_for_create"
                            )}
                            checked={enableForCreate === "no"}
                            onChange={(e) => {
                              setEnableForCreate(e.target.value);
                              setUser({ ...user, enable_for_create: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                  <Row>
                    {dataTypeList && (
                      <SelectColumn style={{ marginRight: "28px" }}>
                        <Label>{getLocalizedText("select_data_type")}*</Label>
                        <FormControl
                          sx={{ m: 1, minWidth: 200 }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                          disabled={disabledFields.includes("data_type")}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            value={user.data_type}
                            name="data_type"
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            required
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            style={{
                              marginBottom: "20px",
                              width: "200px",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {dataTypeList.map((e) => {
                              return (
                                <MenuItem id={e} value={e}>
                                  {e}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </SelectColumn>
                    )}

                    <SelectColumn>
                      <Label>{getLocalizedText("select_filter_type")}</Label>
                      <FormControl
                        sx={{ m: 1, minWidth: 200 }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          value={filterType}
                          disabled={!user.filterable}
                          classes={{
                            icon: classes.icon,
                          }}
                          name="filter_type"
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          onChange={(e) => {
                            setFilterType(e.target.value);
                          }}
                          style={{
                            marginBottom: "20px",
                            width: "200px",
                            height: "30px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "0px",
                          }}
                        >
                          {filterTypeList[user.data_type] &&
                            filterTypeList[user.data_type].map((e) => {
                              return (
                                <MenuItem id={e} value={e}>
                                  {e}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </SelectColumn>
                  </Row>
                </InputContainer>

                <InputContainer>
                  <InputWrapper>
                    <Label>{getLocalizedText("add_filter_input")}*</Label>
                    <Input
                      disabled={filterType !== "list"}
                      style={{ marginBottom: "8px" }}
                      type="text"
                      placeholder="Type something"
                      name="sub_status"
                      onKeyDown={handleSubStatus}
                    />
                    <SubStatusContainer>
                      {inputList.map((e, i) => {
                        return (
                          <Chip
                            key={e}
                            label={e}
                            onDelete={() => handleDelete(i)}
                          />
                        );
                      })}
                    </SubStatusContainer>
                  </InputWrapper>
                </InputContainer>

                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      if (props.isEdit) {
                        analytics.triggerEvent(
                          4625000232,
                          "cancel_button_tap",
                          "edit_column_form",
                          "cancel_button",
                          {}
                        );
                      } else {
                        analytics.triggerEvent(
                          4625000228,
                          "cancel_button_tap",
                          "add_column_form",
                          "cancel_button",
                          {}
                        );
                      }
                      handleClick();
                    }}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      marginRight: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      if (props.isEdit) {
                        analytics.triggerEvent(
                          4625000233,
                          "submit_button_tap",
                          "edit_column_form",
                          "submit_button",
                          {}
                        );
                      } else {
                        analytics.triggerEvent(
                          4625000229,
                          "submit_button_tap",
                          "add_column_form",
                          "submit_button",
                          {}
                        );
                      }
                    }}
                    type="submit"
                    variant="contained"
                    id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {getLocalizedText("submit_details")}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(AddColumnModal);
