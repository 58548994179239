import React from "react";
import flag from "../../assets/images/flags/india.png";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import { sendOtpEmailApi, sendOtpPhoneApi } from "../../Api";
import { Button } from "@material-ui/core";
import { myTheme } from "../../themeUtils";
import { getLocalizedText } from "../../Functions";
import analytics from "../../Analytics";
const Header = styled.span`
  font: normal normal 600 44px Poppins;
  color: ${myTheme.Button.colorBlue};
`;
const SubHeader = styled.span`
  font: normal normal normal 16px Poppins;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 10px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Label = styled.span`
  font: normal normal 600 12px Poppins;
  margin-bottom: 4px;
`;
const InputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  align-items: center;
  border: 2px solid rgb(218, 244, 245);
`;
const InputLeft = styled.div`
  display: flex;
  align-items: center;
  border-right: 2px solid rgb(218, 244, 245);
  padding: 4px;
`;
const Image = styled.img`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 4px;
`;
const Code = styled.span`
  font: normal normal 500 15px Poppins;
  margin-right: 10px;
`;
const Input = styled.input`
  width: 100%;
  height: 30px;
  padding: 8px 12px;
  outline: none;
  border: none;
  font: normal normal 500 16px/25px Poppins;
  letter-spacing: 2px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 14px/25px Poppins;
    color: #b5b5b5;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
`;

const DisclaimerWrapper = styled.div`
  width: 80%;
  text-align: center;
  margin: auto;
  margin-top: 10px;
`;
const Disclaimer = styled.span`
  font: normal normal normal 12px/18px Poppins;
  color: #b5b5b5;
`;
const Highlighter = styled.span`
  color: #185dd2;
`;
const Error = styled.div`
  display: none;
  color: red;
`;
const LoginRight = (props) => {
  const onChange = (e) => {
    props.setValue({ ...props.value, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (localStorage.getItem("device_id") === null) {
      let device_id = uuidv4();
      localStorage.setItem("device_id", device_id);
    }
  }, []);
  const handleClick = async (e) => {
    e.preventDefault();

    try {
      let payload = { type: "login", account: props.value.account };
      if (props.inputFieldType === "phone") {
        payload["phone"] = `+91-${props.value.phone}`;
        let response = await sendOtpPhoneApi(payload);
      } else {
        payload["email"] = props.value.email;
        let response = await sendOtpEmailApi(payload);
      }
      document.getElementById("error").style.display = "none";
      props.setStatus(true);
    } catch (error) {
      console.log("got error", error);
      document.getElementById("error").style.display = "block";
    }
  };
  return (
    <>
      <Header>Log In</Header>
      <SubHeader>
        Need a LeadPixie account?{" "}
        <span style={{ color: "#FFAE42" }}>Contact support@leadpixie.in</span>
      </SubHeader>
      <Form onSubmit={handleClick}>
        <Label>Account</Label>
        <InputContainer>
          <Input
            type="text"
            name="account"
            id="account"
            onChange={onChange}
            value={props.value.account}
            autoFocus={true}
            placeholder="Enter your account name"
            required
          />
        </InputContainer>
        {props.inputFieldType === "phone" ? (
          <>
            <Label>{props.data.label}</Label>
            <InputContainer>
              <InputLeft>
                <Image src={flag} alt="loading..." />
                <Code>{props.data.code}</Code>
              </InputLeft>
              <Input
                type="text"
                name="phone"
                id="phone"
                value={props.value.phone}
                onChange={onChange}
                placeholder="Enter your phone number"
                required
                minLength="10"
                pattern="\d*"
                maxLength="10"
                onKeyUp={(e) => (e.key === "Enter" ? handleClick(e) : null)}
              />
            </InputContainer>
          </>
        ) : (
          <>
            <Label>Email</Label>
            <InputContainer>
              <Input
                type="email"
                name="email"
                id="email"
                value={props.value.email}
                onChange={onChange}
                placeholder="Enter your email"
                required
                onKeyUp={(e) => (e.key === "Enter" ? handleClick(e) : null)}
              />
            </InputContainer>
          </>
        )}

        <Error id="error">
          {" "}
          some error occured, please contact your administrator!!!
        </Error>
        <ButtonWrapper>
          <Button
            onClick={() => {
              analytics.triggerEvent(
                4625000015,
                "continue_button_tap",
                "login_page",
                "login_continue_button",
                {}
              );
            }}
            variant="contained"
            style={{ width: "100%", height: "100%", ...myTheme.Button.btnBlue }}
            type="submit"
          >
            {getLocalizedText("continue")}
          </Button>
          <Button
            variant="text"
            color="primary"
            style={{
              color: myTheme.Button.colorBlue,
              textTransform: "none",
              textDecoration: "underline",
            }}
            onClick={() => {
              if (props.inputFieldType === "phone") {
                analytics.triggerEvent(
                  4625000016,
                  "switch_to_email_button_tap",
                  "login_page",
                  "switch_to_email_button",
                  {}
                );
                props.setInputFieldType("email");
              } else {
                analytics.triggerEvent(
                  4625000017,
                  "switch_to_phone_button_tap",
                  "login_page",
                  "switch_to_phone_button",
                  {}
                );
                props.setInputFieldType("phone");
              }
            }}
          >
            {props.inputFieldType === "phone"
              ? "Switch to Email"
              : "Switch to phone"}
          </Button>
        </ButtonWrapper>

        <DisclaimerWrapper>
          {props.data.disclaimer ? (
            <Disclaimer>
              {props.data.disclaimer}{" "}
              <a
                href="https://d2dsj7nm5j2dtv.cloudfront.net/tos.html"
                target="_blank"
                style={{ textDecoration: "none" }}
                rel="noreferrer"
              >
                <Highlighter
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000019,
                      "terms_of_use_tap",
                      "login_page",
                      "terms_of_use_link",
                      {}
                    );
                  }}
                >
                  {props.data.t1}{" "}
                </Highlighter>
              </a>
              and{" "}
              <a
                rel="noreferrer"
                href="https://d2dsj7nm5j2dtv.cloudfront.net/pp.html"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Highlighter
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000018,
                      "privacy_policy_tap",
                      "login_page",
                      "privacy_policy_link",
                      {}
                    );
                  }}
                >
                  {props.data.t2}{" "}
                </Highlighter>
              </a>
            </Disclaimer>
          ) : (
            ""
          )}
        </DisclaimerWrapper>
      </Form>
    </>
  );
};

export default observer(LoginRight);
