import { Link } from "@material-ui/icons";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { getRawLeadsApi, getRawLeadsColumnsApi } from "../../../Api";
import PaginationComponent from "../../../components/pagination/PaginationComponent";
import { pageSize } from "../../../Config";
import { Box, Typography } from "@mui/material";
import { RawLeadsColumnHeaders } from "../../../Db";
import { CircularProgress, Drawer, Tooltip } from "@material-ui/core";
import RawLeadDetailsPanel from "../../../components/raw_lead_page_components/RawLeadDetailsPanel";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import FilterSidePanel from "../filter_side_panel/FilterSidePanel";
import { FormattedRawLeadColumns } from "../../../ColumnUtils";
import rootStore from "../../../stores/RootStore";
import analytics from "../../../Analytics";
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;
/* height: 100vh; */
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 292px);`};
  background-color: white;
`;

const ProgressWrapper = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const RawLeadsTab = (props) => {
  const { userStore } = rootStore;
  const [row, setRow] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rawColumns, setRawColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [openDetailsPanel, setOpenDetailsPanel] = useState(false);
  const [leadDetails, setLeadDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [refreshOnFilter, setRefreshOnFilter] = useState(false);

  const generateFiltersPayload = () => {
    let allFiltersList = {};
    if (Object.keys(userStore.rawLeadFilters).length !== 0) {
      Object.keys(userStore.rawLeadFilters).map((key) => {
        if (userStore.rawLeadFilters[key]["type"] === "normal") {
          if (
            userStore.rawLeadFilters[key]["dataType"] === "date" ||
            userStore.rawLeadFilters[key]["dataType"] === "datetime"
          ) {
            let new_date = new Date(userStore.rawLeadFilters[key]["value"]);
            new_date = new_date.toISOString();
            allFiltersList[key] = new_date;
          } else {
            allFiltersList[key] = userStore.rawLeadFilters[key]["value"];
          }
        } else if (userStore.rawLeadFilters[key]["type"] === "range") {
          if (
            userStore.rawLeadFilters[key]["dataType"] === "date" ||
            userStore.rawLeadFilters[key]["dataType"] === "datetime"
          ) {
            let d1 = new Date(userStore.rawLeadFilters[key]["value"]["start"]);
            d1 = d1.toISOString();
            let d2 = new Date(userStore.rawLeadFilters[key]["value"]["end"]);
            d2 = d2.toISOString();
            allFiltersList[key] = {
              start: d1,
              end: d2,
            };
          } else if (userStore.rawLeadFilters[key]["dataType"] === "integer") {
            let num1 = parseInt(
              userStore.rawLeadFilters[key]["value"]["start"]
            );
            let num2 = parseInt(userStore.rawLeadFilters[key]["value"]["end"]);

            allFiltersList[key] = {
              start: num1,
              end: num2,
            };
          }
        } else if (userStore.rawLeadFilters[key]["type"] === "list") {
          allFiltersList[key] = userStore.rawLeadFilters[key]["value"];
        }
      });
    }
    return allFiltersList;
  };

  const updateRawColumns = (list) => {
    let temp_list = [];
    list.forEach((column) => {
      let new_column = {
        id: column["id"],
        field: column["column_name"],
        headerName: column["display_name"],
        filterable: column["filterable"],
        filter_type: column["filter_type"],
        data_type: column["data_type"],
        filter_input: column["filter_input"],
      };
      temp_list.push(new_column);
    });
    return temp_list;
  };
  const generateColumns = (list) => {
    let temp_list = FormattedRawLeadColumns(list);
    if (temp_list.length > 0) {
      temp_list[0]["headerCheckboxSelection"] = true;
      temp_list[0]["checkboxSelection"] = true;
    }
    return temp_list;
  };
  const getRawLeadsColumns = async () => {
    let response = await getRawLeadsColumnsApi();
    let updated_raw_columns = updateRawColumns([...response]);
    setRawColumns(updated_raw_columns);
    let generated_columns_list = generateColumns([...response]);
    setColumns(generated_columns_list);
  };
  const getRawLeads = async ({ page, page_size }) => {
    let payload = generateFiltersPayload();
    let response = await getRawLeadsApi({
      page: page,
      page_size: page_size,
      payload: payload,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
  };

  const setup = async () => {
    setLoading(true);
    await getRawLeadsColumns();
    await getRawLeads({ page: 0, page_size: pageSize });
    setLoading(false);
  };
  useEffect(() => {
    setup();
  }, [props.refresh, props.refreshAfterImport]);

  useEffect(() => {
    if (refreshOnFilter === true) {
      setup();
      setRefreshOnFilter(false);
    }
  }, [refreshOnFilter]);

  const handlePageSize = async (e) => {
    setPage(0);
    setSize(e.target.value);
    await getRawLeads({ page: 0, page_size: e.target.value });
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);
    setLoading(true);
    await getRawLeads({ page: page - 1, page_size: size });
    setLoading(false);
  };
  const actionColumn = [
    {
      field: "other_fields",
      headerName: "Other Fields",
      cellRenderer: (params) => {
        return (
          <Link
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              analytics.triggerEvent(
                4625000138,
                "other_fields_button_tap",
                "raw_leads_tab",
                "other_fields_button",
                {}
              );
              e.preventDefault();
              e.stopPropagation();
              setLeadDetails(params.data);
              setOpenDetailsPanel(true);
            }}
          />
        );
      },
    },
  ];

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  function onSelectionChanged(event) {
    let selectedRowData = event.api.getSelectedNodes();
    let leads = selectedRowData.map((item) => item.data);
    props.setAssignState(leads.length);
    props.setLeads(leads);
  }

  const gridOptions = {
    suppressRowClickSelection: true,
  };
  return (
    <>
      <>
        <TableWrapper>
          {!loading ? (
            <div className="ag-theme-alpine" style={{ width: "100%" }}>
              <AgGridReact
                domLayout="autoHeight"
                gridOptions={gridOptions}
                rowData={row}
                defaultColDef={defaultColDef}
                columnDefs={columns.concat(actionColumn)}
                animateRows={true}
                rowSelection="multiple"
                rowMultiSelectWithClick
                onSelectionChanged={onSelectionChanged}
              />
            </div>
          ) : (
            <ProgressWrapper>
              <CircularProgress />
            </ProgressWrapper>
          )}
        </TableWrapper>
        <PaginationWrapper>
          <PaginationComponent
            page_no={page}
            row={row}
            page_size={size}
            size={size}
            count={count}
            handlePageSize={handlePageSize}
            handlePage={handlePagination}
          />
        </PaginationWrapper>
      </>

      <Drawer
        anchor={"right"}
        open={openDetailsPanel}
        onClose={() => {
          setOpenDetailsPanel(false);
        }}
      >
        {
          <RawLeadDetailsPanel
            data={leadDetails}
            open={openDetailsPanel}
            setOpen={setOpenDetailsPanel}
            columns={rawColumns}
          />
        }
      </Drawer>
      <Drawer
        anchor={"right"}
        open={props.openFilterPanel}
        onClose={() => {
          props.setOpenFilterPanel(false);
        }}
      >
        {
          <FilterSidePanel
            open={props.openFilterPanel}
            setOpen={props.setOpenFilterPanel}
            columns={rawColumns}
            setRefresh={setRefreshOnFilter}
          />
        }
      </Drawer>
    </>
  );
};

export default observer(RawLeadsTab);
