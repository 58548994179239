import { Button, Dialog, DialogActions } from "@mui/material";
import { observer } from "mobx-react-lite";
import rootStore from "../../stores/RootStore";
import CallInfoHeader from "./call_info_components/CallInfoHeader";
import CallInfoBody from "./call_info_components/CallInfoBody";
import { consoleLogger, getLocalizedText } from "../../Functions";
import { styled } from "@mui/material/styles";
import { callInfoSheetScope } from "../../utils/enum";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: "bold",
  width: "100px",
}));
const CallInfoDialog = (props) => {
  const { phoneCallState, authStore } = rootStore;
  const uid = localStorage.getItem("uid");
  const navigate = useNavigate();
  const onClose = () => {
    props.setOpen(false);
    phoneCallState.resetProgressiveCallDialog();
  };

  const isSameLead = () => {
    let leadInfo = {};
    if (phoneCallState.callStatus.hasOwnProperty("lead")) {
      leadInfo = phoneCallState.callStatus["lead"];
    }
    if (
      leadInfo.hasOwnProperty("id") &&
      leadInfo["id"] !== null &&
      leadInfo["id"] !== ""
    ) {
      if (authStore.leadDetailStatus["lead_id"] === leadInfo["id"]) {
        return true;
      }
    }
    return false;
  };

  const RenderButtonBasedOnCallStatus = () => {
    if (phoneCallState.callStatus["status"] === "calling") {
      return (
        <StyledButton
          variant="contained"
          size="small"
          color="primary"
          onClick={() => {
            onClose();
            props.openCallLogCallback();
          }}
          autoFocus
        >
          {getLocalizedText("exit_call")}
        </StyledButton>
      );
    } else {
      return (
        <StyledButton
          variant="contained"
          size="small"
          color="primary"
          onClick={() => {
            onClose();
            props.openCallLogCallback();
          }}
          autoFocus
        >
          {getLocalizedText("submit_log")}
        </StyledButton>
      );
    }
  };

  return (
    <>
      <Dialog
        open={props.open}
        PaperProps={{ style: { width: "500px", height: "400px" } }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CallInfoHeader
          callStatus={phoneCallState.callStatus}
          scope={props.scope}
          onClick={onClose}
          isSameLead={isSameLead}
        />

        <CallInfoBody
          callStatus={phoneCallState.callStatus}
          scope={props.scope}
        />

        {phoneCallState.isUserLeadOwner() ? (
          <>
            <DialogActions sx={{ padding: "0px", margin: "16px" }}>
              <StyledButton
                variant="contained"
                size="small"
                color="inherit"
                sx={{ backgroundColor: "#EFEFF4" }}
                onClick={onClose}
              >
                {getLocalizedText("close")}
              </StyledButton>
              {(() => {
                if (
                  props.scope === callInfoSheetScope.lead_details_call_icon ||
                  props.scope === callInfoSheetScope.lead_details_call_log
                ) {
                  if (isSameLead()) {
                    return <RenderButtonBasedOnCallStatus />;
                  } else {
                    return (
                      <StyledButton
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                          onClose();
                          navigate(
                            `/users/${uid}/leads/${phoneCallState.callStatus["lead"]["id"]}`
                          );
                        }}
                        autoFocus
                      >
                        {getLocalizedText("go_to_lead", "project")}
                      </StyledButton>
                    );
                  }
                } else {
                  return <RenderButtonBasedOnCallStatus />;
                }
              })()}
            </DialogActions>
          </>
        ) : (
          <>
            <DialogActions sx={{ padding: "0px", margin: "16px" }}>
              <StyledButton
                variant="contained"
                size="small"
                color="inherit"
                sx={{ backgroundColor: "#EFEFF4" }}
                onClick={() => {
                  onClose();
                  consoleLogger(
                    "Phone state ::: post submit calllog ::: info dialog"
                  );
                  phoneCallState.postSubmitCallLog();
                }}
              >
                {getLocalizedText("close")}
              </StyledButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};
export default observer(CallInfoDialog);
