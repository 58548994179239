import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { ExpandMore, WhatsApp } from "@mui/icons-material";
import rootStore from "../../stores/RootStore";
import {
  IsoToLocalDate,
  IsoToLocalTime,
  dateHandler,
  dateTimeHandler,
} from "../../Functions";
import moment from "moment";
import { Snackbar } from "@material-ui/core";
import AdvanceCopyAlertDialog from "../alert_dialogue/AdvanceCopyAlertDialog";
import { getPhoneApi } from "../../Api";

const ModelWrapper = styled(Box)(({ theme }) => ({
  width: "500px",
  margin: "auto",
  display: "flex",
  position: "relative",
  /* padding: 30px, */
  flexDirection: "column",
  height: "100vh",
  paddingTop: "0px",
}));
const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "30px",
  backgroundColor: "#f9f9fc",
}));

const ModelHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  variant: "span",
  color: "#4d4e4f",
}));
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;
const ProgressWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const charLimit = 2083;
const AdvanceCopySidepanel = ({
  leadData,
  setOpen,
  leadId,
  columnsIdsByGroup,
}) => {
  const { userStore } = rootStore;
  const [dataList, setDataList] = useState({});
  const [loading, setLoading] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const columnMeta = [...userStore.AllColumnsList];

  const identifyDateFormat = (dateString) => {
    if (!isNaN(dateString)) {
      const m = moment(parseInt(dateString));
      if (m.isValid()) {
        return "epoch";
      }
    } else {
      const m = moment(dateString);
      if (m.isValid()) {
        return "ISO";
      }
    }
    return "unknown";
  };

  const dateFieldHandler = ({ item, type }) => {
    let value = null;
    let dateStringType = identifyDateFormat(item);
    if (item !== null) {
      if (dateStringType !== "unknown") {
        if (dateStringType === "epoch") {
          if (type === "datetime") {
            value = dateTimeHandler(item);
          } else {
            value = dateHandler(item);
          }
        } else {
          if (type === "datetime") {
            value = IsoToLocalTime(item);
          } else {
            value = IsoToLocalDate(item);
          }
        }
      }
    }
    return value;
  };

  const init = async () => {
    let data = {};

    for (const key in columnsIdsByGroup) {
      let columnsIds = columnsIdsByGroup[key];
      let tempData = {};
      for (let i = 0; i < columnsIds.length; i++) {
        const id = columnsIds[i];
        const index = columnMeta.findIndex((element) => element.id === id);
        if (index !== -1) {
          const headerName = columnMeta[index].headerName;
          const value = await getColumnValue(
            columnMeta[index].field,
            columnMeta[index].data_type
          );
          tempData[id] = `${headerName} : ${value}`;
        }
      }
      data[key] = tempData;
    }
    console.log("generated advance copy data::::", JSON.stringify(data));
    setDataList(data);
    setLoading(false);
  };
  const getColumnValue = async (field, data_type) => {
    let data = { ...leadData };
    let key = field;
    let index = columnMeta.findIndex((column) => column["field"] === field);
    if (index !== -1) {
      if (columnMeta[index]["is_custom_param"]) {
        key = field.replace("custom_params_", "");
        let customData = leadData.hasOwnProperty("custom_params")
          ? JSON.parse(leadData["custom_params"])
          : {};
        data = customData;
      }
      if (data.hasOwnProperty(key)) {
        let value = data[key];
        if (key === "phone") {
          value = await getLeadPhoneNumber();
        }
        if (data_type === "date" || data_type === "datetime") {
          value = dateFieldHandler({ item: value, type: data_type });
        }
        if (key === "lead_owner" || key === "team" || key === "lead_creator") {
          return value !== null ? value["name"] : "";
        }

        return value !== null ? value : "";
      } else {
        return "";
      }
    }
  };

  const getLeadPhoneNumber = async () => {
    let response = await getPhoneApi(leadId);
    return response.data;
  };

  useEffect(() => {
    init();
  }, []);
  const handleCheckbox = (selectedRowId) => {
    let ids = [...selectedIds];
    if (!ids.includes(selectedRowId)) {
      ids.push(selectedRowId);
    } else {
      ids = ids.filter((id) => id !== selectedRowId);
    }
    setSelectedIds(ids);
  };

  const generateString = () => {
    let list = [];

    const allValues = {};
    for (const group in dataList) {
      if (dataList.hasOwnProperty(group)) {
        const groupData = dataList[group];
        for (const key in groupData) {
          allValues[key] = groupData[key];
        }
      }
    }
    selectedIds.forEach((id) => {
      if (Object.keys(allValues).includes(id)) {
        list.push(allValues[id]);
      }
    });
    let formattedData = list.join("\n");
    return formattedData;
  };

  const handleCopy = () => {
    let data = generateString();
    navigator.clipboard.writeText(data);
    console.log(data);
    setOpenSnackBar(true);
  };

  const handleShare = () => {
    let data = generateString();
    navigator.clipboard.writeText(data);
    if (data.length > charLimit) {
      setShowAlertDialog(true);
    } else {
      const encodedTextData = encodeURIComponent(data);
      window.open(`https://wa.me/?text=${encodedTextData}`, "_blank");
    }
  };

  return (
    <>
      <Box role="presentation">
        <ModelWrapper>
          <AppBar
            component={"nav"}
            position="sticky"
            color="inherit"
            sx={{
              top: 0,
              bottom: "auto",
              width: 500,
              right: 0,
              bgcolor: "#f9f9fc",
            }}
          >
            <Toolbar>
              <ModelHeader>Advance Copy</ModelHeader>
            </Toolbar>
          </AppBar>
          {!loading ? (
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                paddingX: "24px",
                overflowY: "auto",
              }}
            >
              {Object.keys(dataList).map((group, i) => {
                return (
                  <Accordion
                    key={i}
                    style={{ marginBottom: "10px" }}
                    defaultExpanded={i === 0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{group}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {Object.keys(dataList[group]).map((key, i) => {
                        return (
                          <Card sx={{ marginBottom: "10px" }}>
                            <ListItem key={i} disablePadding>
                              <ListItemButton
                                dense
                                onClick={() => handleCheckbox(key)}
                              >
                                <Checkbox
                                  edge="start"
                                  checked={selectedIds.includes(key)}
                                  tabIndex={-1}
                                  disableRipple
                                />
                                <ListItemText primary={dataList[group][key]} />
                              </ListItemButton>
                            </ListItem>
                          </Card>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </List>
          ) : (
            <ProgressWrapper>
              <CircularProgress />
            </ProgressWrapper>
          )}

          <AppBar
            position="fixed"
            color="inherit"
            sx={{ top: "auto", bottom: 0, width: 500 }}
          >
            <Toolbar style={{ width: "500px" }}>
              <ButtonWrapper>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                  variant="contained"
                  color="inherit"
                  style={{ textTransform: "capitalize", width: "100%" }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={selectedIds.length === 0}
                  onClick={handleCopy}
                  variant="contained"
                  color="primary"
                  style={{ textTransform: "capitalize", width: "100%" }}
                >
                  Copy
                </Button>
                <Button
                  disabled={selectedIds.length === 0}
                  onClick={handleShare}
                  variant="contained"
                  color="success"
                  style={{ textTransform: "capitalize", width: "100%" }}
                >
                  <WhatsApp />
                  Share
                </Button>
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      </Box>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setOpenSnackBar(false);
        }}
        message="Copied to clipboard!"
      />
      {showAlertDialog && (
        <AdvanceCopyAlertDialog
          open={showAlertDialog}
          setOpen={setShowAlertDialog}
        />
      )}
    </>
  );
};

export default AdvanceCopySidepanel;
