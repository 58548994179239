import React from "react";
import * as d3 from "d3";
import { useRef } from "react";
import { getWidgetDataApiv2 } from "../../../Api";
import rootStore from "../../../stores/RootStore";
import { consoleLogger } from "../../../Functions";

const margin = {
  top: 80,
  right: 180,
  bottom: 80,
  left: 180,
};

function drawChart(svgRef, legendRef, data, keys, colors) {
  const svg = d3.select(svgRef.current);
  const legend = d3.select(legendRef.current);
  const dataByGroup1 = d3.group(data, (d) => d.group1);
  const dataByGroup2 = d3.group(data, (d) => d.group2);

  const stackGenerator = d3.stack().keys(keys);
  const layers = stackGenerator(data);

  const width = 2500;
  const height = 1000;

  svg
    .attr("width", "100%")
    .attr("height", "100%")
    .attr("viewBox", [0, 0, width, height])
    .style("background-color", "white");

  const outerGroupXScale = d3
    .scaleBand()
    .domain(dataByGroup1.keys())
    .range([margin.left, width - margin.right])
    .padding(0.05);
  const innerGroupXScale = d3
    .scaleBand()
    .domain(dataByGroup2.keys())
    .range([0, outerGroupXScale.bandwidth()])
    .padding(0.05);
  const innerGroupXAxis = (g) => {
    // bottom align it
    g.attr("transform", `translate(0, ${height - margin.bottom})`)
      .attr("class", "x-axis")
      .call(d3.axisBottom(innerGroupXScale))
      .attr("font-size", "28px");
  };
  const outerGroupXAxis = (g) => {
    g.attr("transform", `translate(0, ${height})`)
      .attr("class", "x-axis")
      .call(d3.axisBottom(outerGroupXScale))
      .call((g) => {
        g.selectAll(".tick>line").attr("y2", 0).attr("y1", 10);
      })
      .attr("font-size", "28px");
  };

  //******creating legend*********

  legend.attr("width", "20%").attr("height", "100%").append("g");
  const legendData = [];
  Object.keys(colors).map((item) => {
    let newObj = { color: colors[item], label: item };
    legendData.push(newObj);
  });

  legend
    .selectAll("rect")
    .data(legendData)
    .enter()
    .append("rect")
    .attr("x", 0)
    .attr("y", function (d, i) {
      return i * 20;
    })
    .attr("width", 10)
    .attr("height", 10)
    .style("fill", function (d) {
      return d.color;
    });

  legend
    .selectAll("text")
    .data(legendData)
    .enter()
    .append("text")
    .attr("x", 10 + 16)
    .attr("y", function (d, i) {
      return i * 20 + 3.5;
    })
    .attr("dy", ".35em")
    .attr("font-size", "10px")
    .text(function (d) {
      return d.label;
    });
  //*******end*********

  // looking at second value / y value
  const extent = [
    0.9 * d3.min(layers, (layer) => d3.min(layer, (d) => d[1])),
    1.1 * d3.max(layers, (layer) => d3.max(layer, (d) => d[1])),
  ];
  const [yMin, yMax] = extent;
  const yScale = d3
    .scaleLinear()
    .domain(extent)
    .range([height - margin.bottom, margin.top]); // range from bottom up

  const yAxis = (g) => {
    g.attr("transform", `translate(${margin.left - 20}, 0)`)
      .attr("class", "y-axis")
      .call(d3.axisLeft(yScale))
      .call((g) => g.selectAll(".domain").remove())
      .call((g) => {
        g.selectAll(".tick>line").attr("x2", 0).attr("x1", 34);
      })
      .attr("font-size", "28px");
  };

  const yAxisLabel = (g) => {
    g.append("text")
      .attr("text-anchor", "start")
      .attr("fill", "black")
      .attr("font-size", "18px")
      .attr("x", -40)
      .attr("y", height - 60)
      .text("UNITS");
  };

  // create the initially grouping by binding to the data grouped by state
  var group1G = svg
    .selectAll(".group1")
    .data(dataByGroup1)
    .join("g")
    .attr("class", "group1")
    .attr("fill", (d) => colors[d[0]])
    .attr("transform", (d) => `translate(${outerGroupXScale(d[0])}, 0)`);

  // draw the inner x axis on the state group because we will have one per state group
  group1G.append("g").attr("class", "group1Axis").call(innerGroupXAxis);

  // create the year groups inside the initial grouping of states and offset them
  // based on which state they belong to
  var group2G = group1G
    .selectAll(".group2G")
    .data((d) => {
      const filteredByState = data.filter((i) => i.group1 === d[0]);
      const groupedByYear = d3.group(filteredByState, (a) => a.group2);
      return groupedByYear;
    })
    .join("g")
    .attr("class", "group2G")
    .attr("transform", (d) => {
      return `translate(${innerGroupXScale(d[0])}, 0)`;
    });

  // for each year put down your layers
  group2G
    .selectAll(".layers")
    .data((d) => {
      return stackGenerator(d[1]);
    })
    .join("rect")
    .attr("class", "layers")
    .attr("y", (d) => yScale(d[0][1]))
    .attr("fill", (d) => colors[d.key])
    .attr("width", (d) => innerGroupXScale.bandwidth())
    .attr("height", (d) => {
      const lower = d[0][0];
      const upper = d[0][1];
      const firstBarAdjustment = lower === 0 ? yMin : 0;
      return yScale(lower + firstBarAdjustment) - yScale(upper);
    });

  svg.append("g").call(outerGroupXAxis);
  svg.append("g").call(yAxis).call(yAxisLabel);

  svg.node();
}

const ThreeGroupChartComponent = (props) => {
  const svg = useRef(null);
  const legendRef = useRef(null);
  const { userStore } = rootStore;

  const generateRandomColor = (val) => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    userStore.updateColorsList({ key: val, color: color });
    return color;
  };

  function getLabels(response, key) {
    let labels = [];
    response.map((item) => {
      let value = item[key];
      if (labels.includes(value) == false) {
        labels.push(value);
      }
    });
    return labels;
  }
  function getLabelsWithcolor(response, key) {
    let labels = {};
    response.map((item) => {
      let value = item[key];
      if (labels.hasOwnProperty(value) == false) {
        let color = userStore.ColorsList.hasOwnProperty(value)
          ? userStore.ColorsList[value]
          : generateRandomColor(value);
        labels[value] = color;
      }
    });
    return labels;
  }

  const updateData = (response, v2Response) => {
    const group1Key = v2Response["xdata"]["group_by"][0];
    const group2Key = v2Response["xdata"]["group_by"][1];
    const group3Key = v2Response["xdata"]["group_by"][2];
    const aggregateBy = v2Response["xdata"]["aggregate_by"];
    let d3Groups1 = getLabels(response, group1Key);
    let d3Groups2 = getLabels(response, group2Key);
    let keysWithcolor = getLabelsWithcolor(response, group3Key);
    consoleLogger(keysWithcolor);
    let keys = getLabels(response, group3Key);

    let data = [];
    d3Groups1.map((group1) => {
      d3Groups2.map((group2) => {
        let entries = response.filter(
          (item) => item[group1Key] === group1 && item[group2Key] === group2
        );

        if (entries.length > 0) {
          let obj = {
            group1: group1,
            group2: group2,
          };
          entries.map((entry) => {
            obj[entry[group3Key]] = entry[aggregateBy];
          });
          data.push(obj);
        }
      });
    });

    return { data: data, keys: keys, keysWithColor: keysWithcolor };
  };

  React.useEffect(() => {
    const getData = async () => {
      try {
        let response = await getWidgetDataApiv2({ id: props.data.id });
        // let data=resp;
        let groups = props.data["xdata"]["group_by"];
        if (response.data.length > 0) {
          response.data.map((item) => {
            groups.map((key) => {
              if (item[key] === null || item[key] === "") {
                item[key] = "NA";
              }
            });
          });
        }

        let data = updateData(response.data, props.data);
        if (data !== null) {
          consoleLogger("now drawing the chart");
          drawChart(
            svg,
            legendRef,
            data["data"],
            data["keys"],
            data["keysWithColor"]
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (props.data) {
      getData();
    }
  }, [svg]);

  return (
    <div
      id="parent-div"
      style={{ display: "flex", width: "100%", height: "100%" }}
    >
      <svg
        ref={svg}
        id="d3-graph-container"
        style={{ width: "100%", height: "100%" }}
      ></svg>
      <svg ref={legendRef} x="2000" y="0" id="legend"></svg>
    </div>
  );
};

export default ThreeGroupChartComponent;
