import React from "react";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import {
  getSummaryBarData,
  getWidgetDataApi,
  removeWidgetApi,
  removeWidgetApiv2,
} from "../../../Api";
import { useEffect } from "react";
import { useState } from "react";
import { StackedBarConfig } from "../../../Config";
import rootStore from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { IconButton, Menu } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CalendarToday, Fullscreen } from "@material-ui/icons";
import FullScreenModal from "../../full_screen_modal/FullScreenModal";
import { Chart, registerables } from "chart.js";
import { MenuItem } from "@mui/material";
Chart.register(...registerables);

const emptyData = {
  labels: ["color1", "color2", "color3"],
  // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
  datasets: [
    {
      label: "Popularity of colours",
      data: [95, 23, 96],
      // you can set indiviual colors for each bar
      backgroundColor: ["#ffbb11", "#ecf0f1", "#50AF95"],
      borderWidth: 1,
    },
  ],
};
const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: ${(e) => (e.type === "preview" ? "0px" : "20px")};
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
`;
const ChartTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.p`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const Text2 = styled.p`
  font: normal normal 500 12px Open Sans;
  margin: 0px;
  text-align: left;
  color: #bbbab6;
  margin-right: 8px;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ITEM_HEIGHT = 48;

const BarGraphWidget = (props) => {
  const { userStore } = rootStore;
  const [data, setData] = useState();
  const [title, setTitle] = useState();
  const [open, setOpen] = useState(false);
  const [showTop, setShowTop] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dateRange, setDateRange] = useState("");
  const Open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRemove = async () => {
    try {
      let response = await removeWidgetApiv2({ id: props.data.id });
      setAnchorEl(null);
      props.setRefresh(!props.refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const generateRandomColor = (val) => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    userStore.updateColorsList({ key: val, color: color });
    return color;
  };
  const generateRandomColorOther = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const updateData = (val) => {
    let newObj = {
      labels: [],
      datasets: [],
    };
    let checkData = val["data"];
    if (Object.keys(checkData).length > 0) {
      let group_name = Object.keys(props.data.xdata)[0];
      let search_value = props.data.xdata[group_name];

      if (search_value === "created_at::date") {
        if (Object.keys(checkData).length > 0) {
          let tempObj = {
            borderWidth: 1,
          };
          let dataList = [];
          let colorList = [];
          tempObj["label"] = search_value;
          Object.keys(checkData).map((item) => {
            newObj.labels.push(item);
            dataList.push(checkData[item]);
            colorList.push(generateRandomColorOther());
            tempObj["data"] = dataList;
            tempObj["backgroundColor"] = colorList;
          });
          newObj.datasets.push(tempObj);
        }
      } else {
        let meta = val["xdata"][search_value];

        if (meta.length > 0) {
          let tempObj = {
            borderWidth: 1,
          };
          let dataList = [];
          let colorList = [];
          tempObj["label"] = search_value;
          meta.map((item) => {
            newObj.labels.push(item["name"]);
            if (userStore.ColorsList.hasOwnProperty(item["name"])) {
              let colorVal = userStore.ColorsList[item["name"]];
              colorList.push(colorVal);
            } else {
              let colorVal = generateRandomColor(item["name"]);
              colorList.push(colorVal);
            }
            if (checkData.hasOwnProperty(item["id"])) {
              dataList.push(checkData[item["id"]]);
            } else {
              dataList.push(0);
            }
            tempObj["data"] = dataList;
            tempObj["backgroundColor"] = colorList;
          });
          newObj.datasets.push(tempObj);
        }
      }
    } else {
      newObj = {
        labels: ["no data"],
        datasets: [
          {
            label: ["no data"],
            data: [100],
            backgroundColor: [
              "rgba(54, 162, 235, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(54, 162, 235, 0.2)",
            ],
            hoverBackgroundColor: [
              "rgba(54, 162, 235, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(54, 162, 235, 1)",
            ],
          },
        ],
      };
    }

    // if (labelList.length > 0) {
    //   labelList.map((item, i) => {
    //     if (
    //       Object.keys(BgList).length > 0 &&
    //       Object.keys(dataList).length > 0
    //     ) {
    //       let subObj = {
    //         label: labelList[i],
    //         data: dataList[item],
    //         backgroundColor: BgList[item],
    //       };
    //       newObj.datasets.push(subObj);
    //     }
    //   });
    // } else {
    //   newObj = {};
    // }
    return newObj;
  };

  const ChartBottomSection = () => {
    if (props.type === "preview") {
      return (
        <Wrapper>
          <Bar
            data={emptyData}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </Wrapper>
      );
    } else if (props.type !== "preview" && data) {
      return (
        <Wrapper>
          <Bar
            data={data}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </Wrapper>
      );
    }
  };
  useEffect(() => {
    const getData = async () => {
      let response = await getWidgetDataApi({ id: props.data.id });
      let modifiedData = updateData(response.data);
      setData(modifiedData);
      if (props.data.rolling_date) {
        setDateRange(props.data.rolling_date_string);
      }
    };
    if (props.type !== "preview") {
      getData();
    }
  }, []);
  return (
    <>
      {data ? (
        <Container type={props.type}>
          {props.type === "preview" ? (
            <Text style={{ textAlign: "center" }}>Preview</Text>
          ) : (
            <ChartTop>
              <Text>{props.data.title}</Text>
              <IconWrapper>
                <CalendarToday
                  style={{
                    width: "18px",
                    height: "18px",
                    marginRight: "8px",
                    color: "#BBBAB6",
                  }}
                />
                <Text2>{props.data.rolling_date && dateRange}</Text2>
                <IconButton
                  style={{ padding: "0px" }}
                  onClick={() => {
                    setShowTop(false);
                    setOpen(true);
                  }}
                >
                  <Fullscreen />
                </IconButton>
                <IconButton
                  onClick={handleClick}
                  aria-label="more"
                  id="long-button"
                  aria-controls={Open ? "long-menu" : undefined}
                  aria-expanded={Open ? "true" : undefined}
                  aria-haspopup="true"
                  style={{ padding: "0px", marginRight: "-10px" }}
                >
                  <MoreVertIcon />
                </IconButton>
              </IconWrapper>
            </ChartTop>
          )}
          <ChartBottomSection />
        </Container>
      ) : (
        <Container type={props.type}>
          {props.type === "preview" ? (
            <Text style={{ textAlign: "center" }}>Preview</Text>
          ) : (
            <ChartTop>
              <Text>{props.data.title}</Text>
              <IconWrapper>
                <IconButton
                  style={{ padding: "0px" }}
                  onClick={() => {
                    setShowTop(false);
                    setOpen(true);
                  }}
                >
                  <Fullscreen />
                </IconButton>
                <IconButton
                  onClick={handleClick}
                  aria-label="more"
                  id="long-button"
                  aria-controls={Open ? "long-menu" : undefined}
                  aria-expanded={Open ? "true" : undefined}
                  aria-haspopup="true"
                  style={{ padding: "0px", marginRight: "-10px" }}
                >
                  <MoreVertIcon />
                </IconButton>
              </IconWrapper>
            </ChartTop>
          )}
          <ChartBottomSection />
        </Container>
      )}
      {open && (
        <FullScreenModal
          open={open}
          setOpen={setOpen}
          myComponent={ChartBottomSection}
          setShowTop={setShowTop}
        />
      )}
      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
          },
        }}
      >
        <MenuItem style={{ fontSize: "12px" }} onClick={handleRemove}>
          Remove
        </MenuItem>
      </Menu>
    </>
  );
};

export default observer(BarGraphWidget);
