import React, { useState } from "react";
import styled from "styled-components";
import rootStore from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { Box, Chip, FormControl, makeStyles, Select } from "@material-ui/core";
import { AddBoxOutlined } from "@material-ui/icons";
import { useEffect } from "react";
import { Alert, MenuItem } from "@mui/material";
import ChartSelectFilterSlug from "../chart_select_filters/ChartSelectFilterSlug";
import ChartSelectFilterNoraml from "../chart_select_filters/ChartSelectFilterNormal";
import { checkFeaturePermission, getLocalizedText } from "../../../Functions";
import SelectedFilterComponent from "./SelectedFilterComponent";
const Row = styled.div`
  display: flex;
  /* width: 100%;
  margin-bottom: 10px; */
  align-items: center;
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Input = styled.input`
  width: 220px;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Message = styled.span`
  font: normal normal 500 10px Open Sans;
  color: red;
  text-align: center;
`;
const FilterItem = styled.div`
  display: flex;
  background-color: #1976d2;
  color: white;
  width: fit-content;
  padding: 4px;
  border-radius: 8px;
  margin-bottom: 8px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const DateRangeWrapper = styled.div`
  width: 100%;
  display: flex;
  height: fit-content;
  align-items: center;
`;
const DateRange = styled.div`
  display: flex;
  flex-direction: column;
`;
const DynamicFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const DynamicFilter = styled.div`
  display: flex;
  align-items: flex-end;
`;
const FilterHeader = styled.p`
  margin: 0px;
  font: normal normal normal 22px Poppins;
`;
const FilterValue = styled.div`
  margin-bottom: -8px;
  margin-left: 20px;
`;
const FilterListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const CustomLabel = styled.div`
  display: flex;
  align-items: center;
`;
const ChipLable = styled.p`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
  margin-right: 4px;
`;
const ChipValue = styled.p`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;
const AlertWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
`;
const FilterRowWrapper = styled.div`
  display: flex;
  width: 520px;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  padding: 8px;
  max-height: 90px;
  overflow-y: auto;
  border: 1px solid #e1e1e1;
  margin-top: 10px;

  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const filtersApplicable = [
  {
    field: "created_at",
    headerName: "Created At",
    data_type: "date",
    filter_type: "date",
    filter_input: "",
  },
  {
    field: "updated_at",
    headerName: "Updated At",
    data_type: "date",
    filter_type: "date",
    filter_input: "",
  },
  {
    field: "lead_assigned_on",
    headerName: "Assigned On",
    data_type: "date",
    filter_type: "date",
    filter_input: "",
  },
  {
    field: "last_contacted_on",
    headerName: "Contacted On",
    data_type: "date",
    filter_type: "date",
    filter_input: "",
  },
  {
    field: "next_follow_up_on",
    headerName: "Next Followup On",
    data_type: "date",
    filter_type: "date",
    filter_input: "",
  },
  {
    field: "lead_source",
    headerName: "Source",
    data_type: "string",
    filter_type: "list",
    filter_input: "",
  },
  {
    field: "lead_status",
    headerName: "Status",
    data_type: "string",
    filter_type: "list",
    filter_input: "",
  },
  {
    field: "lead_stage",
    headerName: "Stage",
    data_type: "string",
    filter_type: "list",
    filter_input: "",
  },
];

const AddFilterToWidget = (props) => {
  const { authStore } = rootStore;
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const [selectFitlers, setSelectFilters] = useState(filtersApplicable);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedFilterMeta, setSelectedFilterMeta] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [selectedVal, setSelectedVal] = useState([]);
  const classes = useStyles();
  let pid = authStore.projectId;

  const handleChange = (e) => {
    let value = e.target.value;
    setSelectedFilter(value);
    let flag = false;
    let column_meta = {};
    if (columnsList.length > 0) {
      columnsList.map((item) => {
        if (item["field"] === value["field"]) {
          flag = true;
          column_meta["field"] = item["field"];
          column_meta["headerName"] = item["headerName"];
          column_meta["data_type"] = item["data_type"];
          column_meta["filter_type"] = item["filter_type"];
          column_meta["filter_input"] = item["filter_input"];
        }
      });
    }
    if (flag) {
      setSelectedFilterMeta(column_meta);
    } else {
      setSelectedFilterMeta(value);
    }
  };
  const handleValue = (e) => {
    setFilterValue(e.target.value);
  };

  const handleAdd = () => {
    if (filterValue !== "" && selectedFilter !== "") {
      props.setFilters({
        ...props.filters,
        [selectedFilter["field"]]: {
          value: filterValue,
          type: selectedFilter["filter_type"],
        },
      });
      props.setFilterList({
        ...props.filterList,
        [selectedFilter["field"]]: {
          value: filterValue,
          type: selectedFilter["filter_type"],
        },
      });
      const newState = selectFitlers.filter(
        (obj) => obj.field !== selectedFilter["field"]
      );
      setSelectFilters(newState);
      setFilterValue("");
      setSelectedFilter("");
      setSelectedFilterMeta("");
      props.setDate("");
    }
  };
  const handleDelete = (item) => {
    let reAddable = null;
    if (item === "lead_owner") {
      reAddable = {
        field: "lead_owner",
        headerName: "Owner",
        data_type: "string",
        filter_type: "list",
        filter_input: "",
      };
    } else {
      filtersApplicable.map((itr) => {
        if (itr["field"] === item) {
          reAddable = itr;
        }
      });
    }

    let newList = Object.keys(props.filterList)
      .filter((key) => !key.includes(item))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: props.filterList[key],
        });
      }, {});
    let filters = Object.keys(props.filters)
      .filter((key) => !key.includes(item))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: props.filters[key],
        });
      }, {});
    props.setFilters(filters);
    props.setFilterList(newList);
    if (reAddable !== null) {
      setSelectFilters(selectFitlers.concat(reAddable));
    }
  };
  useEffect(() => {
    let newList = [...filtersApplicable];
    if (checkFeaturePermission("dashboard_add_widget_filter")) {
      newList.push({
        field: "lead_owner",
        headerName: "Owner",
        data_type: "string",
        filter_type: "list",
        filter_input: "",
      });
    }
    setSelectFilters(newList);
  }, []);
  return (
    <>
      {selectedFilter !== "" &&
        (selectedFilter["data_type"] === "datetime" ||
          selectedFilter["data_type"] === "date") &&
        props.date.includes("Last") &&
        !props.date.includes("Last Month") && (
          <AlertWrapper>
            <Alert
              style={{
                padding: "0px 10px",
                fontSize: "12px",
                borderRadius: "0px 12px 12px 12px",
              }}
              severity="info"
            >
              {`${props.date} does not include today's data`}
            </Alert>
          </AlertWrapper>
        )}
      <Box>
        <Container>
          <DynamicFilterWrapper>
            <DynamicFilter>
              <SelectColumn>
                <Label>{getLocalizedText("select_filter")}</Label>
                <FormControl
                  sx={{ minWidth: 250 }}
                  classes={{
                    root: classes.quantityRoot,
                  }}
                >
                  <Select
                    classes={{
                      icon: classes.icon,
                    }}
                    name="filter_name"
                    value={selectedFilter}
                    displayEmpty
                    disableUnderline
                    variant="outlined"
                    onChange={handleChange}
                    style={{
                      marginRight: "20px",
                      width: "250px",
                      height: "30px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      borderRadius: "0px",
                    }}
                  >
                    {selectFitlers &&
                      selectFitlers.map((e, i) => {
                        return (
                          <MenuItem
                            id={i}
                            value={e}
                            style={{
                              fontSize: "12px",
                              height: "30px",
                            }}
                          >
                            {e.headerName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </SelectColumn>
              {selectedFilter === "" ? (
                <SelectColumn>
                  <Label>{getLocalizedText("value")}</Label>
                  <Input
                    id="standard-basic"
                    label="value"
                    variant="standard"
                    value={filterValue}
                    placeholder="enter value"
                    onChange={handleValue}
                  />
                </SelectColumn>
              ) : selectedFilter["data_type"] === "datetime" ||
                selectedFilter["data_type"] === "date" ? (
                <DateRangeWrapper>
                  <DateRange>
                    <SelectColumn>
                      <Label>{getLocalizedText("value")}</Label>
                      <FormControl
                        sx={{ m: 1, minWidth: 220 }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          name={selectedFilter["field"]}
                          value={props.date}
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          onChange={(e) => {
                            handleValue(e);
                            props.setFilters({
                              ...props.filters,
                              [e.target.name]: {
                                value: e.target.value,
                                type: selectedFilter["data_type"],
                              },
                            });
                            props.handleChange(e);
                          }}
                          style={{
                            width: "220px",
                            height: "30px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "0px",
                          }}
                        >
                          {props.DateList.map((e) => {
                            return (
                              <MenuItem
                                style={{
                                  fontSize: "12px",
                                  height: "30px",
                                }}
                                id={e}
                                value={e}
                              >
                                {e}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </SelectColumn>
                  </DateRange>
                </DateRangeWrapper>
              ) : (
                <SelectedFilterComponent
                  selectedFilter={selectedFilterMeta}
                  setSelectedVal={setSelectedVal}
                  selectedVal={selectedVal}
                  setFilterValue={setFilterValue}
                  filterValue={filterValue}
                  handleValue={handleValue}
                  filters={props.filters}
                  setFilters={props.setFilters}
                />
              )}
              <AddBoxOutlined
                onClick={handleAdd}
                color={
                  filterValue !== "" && selectedFilter !== ""
                    ? "primary"
                    : "disabled"
                }
                style={{
                  cursor:
                    filterValue !== "" && selectedFilter !== ""
                      ? "pointer"
                      : "unset",
                  height: "39px",
                  width: "39px",
                  marginBottom: "-4px",
                }}
              />
            </DynamicFilter>
            {Object.keys(props.filterList).length > 0 && (
              <FilterListWrapper>
                <FilterRowWrapper>
                  {Object.keys(props.filterList).map((e, i) => {
                    return (
                      <Chip
                        key={i}
                        style={{ borderRadius: "8px", width: "fit-content" }}
                        label={
                          <CustomLabel>
                            <ChipLable>{e}</ChipLable>
                            <ChipValue>
                              {props.filterList[e]["value"]}
                            </ChipValue>
                          </CustomLabel>
                        }
                        onDelete={() => handleDelete(e)}
                        // deleteIcon={<DoneIcon />}
                      />
                    );
                  })}
                </FilterRowWrapper>
              </FilterListWrapper>
            )}
          </DynamicFilterWrapper>
        </Container>
      </Box>
    </>
  );
};

export default observer(AddFilterToWidget);
