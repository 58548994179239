import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import styled from "styled-components";
import { getWidgetDataApiv2, removeWidgetApiv2 } from "../../../Api";
import { useEffect } from "react";
import { useState } from "react";
import { StackedBarConfig } from "../../../Config";
import rootStore from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { CircularProgress, IconButton, Typography } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CalendarToday, Fullscreen } from "@material-ui/icons";
import FullScreenModal from "../../full_screen_modal/FullScreenModal";
import { Menu, MenuItem } from "@mui/material";
import {
  consoleLogger,
  getStageColorCode,
  getStatusColorCode,
} from "../../../Functions";
Chart.register(ArcElement);

const response = [
  {
    created_at: "2023-02-08",
    lead_status: "Callback",
    count: 1,
  },
  {
    created_at: "2023-02-24",
    lead_status: "Not_Interested",
    count: 1,
  },
  {
    created_at: "2023-02-17",
    lead_status: "Hold",
    count: 2,
  },
  {
    created_at: "2023-03-01",
    lead_status: "Callback",
    count: 1,
  },
  {
    created_at: "2023-02-24",
    lead_status: "",
    count: 3,
  },
  {
    created_at: "2023-02-22",
    lead_status: "Interested2",
    count: 1,
  },
  {
    created_at: "2023-02-08",
    lead_status: "Interested2",
    count: 1,
  },
  {
    created_at: "2023-02-07",
    lead_status: "Not_Interested",
    count: 1,
  },
  {
    created_at: "2023-03-01",
    lead_status: "",
    count: 1,
  },
  {
    created_at: "2023-02-08",
    lead_status: "Not_Interested",
    count: 1,
  },
];

const emptyData = {
  labels: ["Label1", "Label2", "Label3"],
  // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
  datasets: [
    {
      label: "Dataset1",
      data: [95, 23, 96],
      // you can set indiviual colors for each bar
      backgroundColor: ["#ffbb11", "#ffbb11", "#ffbb11", "#f3ba2f", "#2a71d0"],
      borderWidth: 1,
    },
    {
      label: "Dataset2",
      data: [75, 63, 96],
      // you can set indiviual colors for each bar
      backgroundColor: ["#ecf0f1", "#ecf0f1", "#ecf0f1", "#f3ba2f", "#2a71d0"],
      borderWidth: 1,
    },
    {
      label: "Dataset3",
      data: [85, 23, 36],
      // you can set indiviual colors for each bar
      backgroundColor: ["#50AF95", "#50AF95", "#50AF95", "#f3ba2f", "#2a71d0"],
      borderWidth: 1,
    },
  ],
};
const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: ${(e) => (e.type === "preview" ? "0px" : "20px")};
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
`;
const ChartTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.p`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Text2 = styled.p`
  font: normal normal 500 12px Open Sans;
  margin: 0px;
  text-align: left;
  color: #bbbab6;
  margin-right: 8px;
`;
const ITEM_HEIGHT = 48;
const TwoGroupBarGraphWidget = (props) => {
  const { userStore } = rootStore;
  const [data, setData] = useState({});
  const [title, setTitle] = useState();
  const [open, setOpen] = useState(false);
  const [showTop, setShowTop] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dateRange, setDateRange] = useState("");
  const [loading, setLoading] = useState(true);

  const Open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRemove = async () => {
    try {
      let response = await removeWidgetApiv2({ id: props.data.id });
      setAnchorEl(null);
      props.setRefresh(!props.refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const generateRandomColor = (val) => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    userStore.updateColorsList({ key: val, color: color });
    return color;
  };

  function buildDataset(response, v2Response) {
    let labels = getLabels(response, v2Response["xdata"]["group_by"][0]);
    let dataset = [];
    let keyName = v2Response["xdata"]["group_by"][1];
    let columns = getLabels(response, keyName); // give me all the unique values for the dataset
    let aggregateBy = v2Response["xdata"].hasOwnProperty(
      "aggregate_by_col_names"
    )
      ? v2Response["xdata"]["aggregate_by_col_names"]
      : "count"; //here if xdata has the aggregate_by_col_names then it will use that else by default it will use "count"
    columns.map((column) => {
      let set = {
        label: column,
        data: [],
        backgroundColor: [],
      };
      let entires = response.filter((item) => item[keyName] === column);
      labels.map((label) => {
        let key = v2Response["xdata"]["group_by"][0];
        if (keyName === "lead_stage") {
          let color = getStageColorCode(column);
          if (
            set.backgroundColor.length === 0 ||
            set.backgroundColor.includes(color)
          ) {
            set.backgroundColor.push(color);
          }
        } else if (keyName === "lead_status") {
          let color = getStatusColorCode(column);
          if (
            set.backgroundColor.length === 0 ||
            set.backgroundColor.includes(color)
          ) {
            set.backgroundColor.push(color);
          }
        } else {
          let color = generateRandomColor(column);
          if (userStore.ColorsList.hasOwnProperty(column)) {
            color = userStore.ColorsList[column];
          }
          if (set.backgroundColor.length === 0) {
            color = generateRandomColor(column);
            set.backgroundColor.push(color);
          } else {
            if (set.backgroundColor.includes(color)) {
              set.backgroundColor.push(color);
            }
          }
        }

        let count = 0;
        entires.map((entry) => {
          if (entry[key] === label) {
            count += entry[aggregateBy];
          }
        });
        set["data"].push(count);
      });
      dataset.push(set);
    });
    return dataset;
  }

  function getLabels(response, key) {
    let labels = [];
    response.map((item) => {
      let value = item[key];
      if (labels.includes(value) == false) {
        labels.push(value);
      }
    });
    return labels;
  }

  const updateData = (response, v2Response) => {
    let newObj = {
      labels: [],
      datasets: [],
    };

    if (response.length > 0) {
      newObj.labels = getLabels(response, v2Response["xdata"]["group_by"][0]);
      newObj.datasets = buildDataset(response, v2Response);
    } else {
      newObj = {};
    }
    consoleLogger("result", newObj);
    return newObj;
  };
  const ChartBottomSection = () => {
    if (Object.keys(data).length === 0) {
      return (
        <Wrapper>
          <Typography>No data found!</Typography>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <Bar data={data} options={StackedBarConfig.options} />
        </Wrapper>
      );
    }
  };
  useEffect(() => {
    const getData = async (groups) => {
      try {
        let response = await getWidgetDataApiv2({ id: props.data.id });
        // let data=resp;
        let groups = props.data["xdata"]["group_by"];
        if (response.data.length > 0) {
          response.data.map((item) => {
            groups.map((key) => {
              if (item[key] === null) {
                item[key] = "NA";
              }
            });
          });
        }

        let modifiedData = updateData(response.data, props.data);
        consoleLogger("modified data", modifiedData);
        setTitle(response.data.title);
        setData(modifiedData);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (props.type !== "preview") {
      let groups = props.data["xdata"];
      getData(groups);
    }
    // console.log("bar data", data);
  }, [props.data]);
  return (
    <>
      {!loading ? (
        Object.keys(data).length > 0 ? (
          <Container type={props.type}>
            {props.type === "preview" ? (
              <Text style={{ textAlign: "center" }}>Preview</Text>
            ) : (
              <ChartTop>
                <Text>{props.data.title}</Text>
                <IconWrapper>
                  <Text2>{props.data.rolling_date && dateRange}</Text2>
                  <IconButton
                    style={{ padding: "0px" }}
                    onClick={() => {
                      setShowTop(false);
                      setOpen(true);
                    }}
                  >
                    <Fullscreen />
                  </IconButton>
                  <IconButton
                    onClick={handleClick}
                    aria-label="more"
                    id="long-button"
                    aria-controls={Open ? "long-menu" : undefined}
                    aria-expanded={Open ? "true" : undefined}
                    aria-haspopup="true"
                    style={{ padding: "0px", marginRight: "-10px" }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </IconWrapper>
              </ChartTop>
            )}
            <ChartBottomSection />
          </Container>
        ) : (
          <Container type={props.type}>
            {props.type === "preview" ? (
              <Text style={{ textAlign: "center" }}>Preview</Text>
            ) : (
              <ChartTop>
                <Text>{props.data.title}</Text>
                <IconWrapper>
                  <IconButton
                    style={{ padding: "0px" }}
                    onClick={() => {
                      setShowTop(false);
                      setOpen(true);
                    }}
                  >
                    <Fullscreen />
                  </IconButton>
                  <IconButton
                    onClick={handleClick}
                    aria-label="more"
                    id="long-button"
                    aria-controls={Open ? "long-menu" : undefined}
                    aria-expanded={Open ? "true" : undefined}
                    aria-haspopup="true"
                    style={{ padding: "0px", marginRight: "-10px" }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </IconWrapper>
              </ChartTop>
            )}
            <ChartBottomSection />
          </Container>
        )
      ) : (
        <Container style={{ alignItems: "center" }}>
          <CircularProgress />
        </Container>
      )}
      {open && !showTop && (
        <FullScreenModal
          open={open}
          setOpen={setOpen}
          myComponent={ChartBottomSection}
          setShowTop={setShowTop}
          title={props.data.title}
        />
      )}
      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
          },
        }}
      >
        <MenuItem
          style={{ fontSize: "12px" }}
          onClick={handleRemove}
          disabled={!props.isDashboardEditable()}
        >
          Remove
        </MenuItem>
      </Menu>
    </>
  );
};

export default observer(TwoGroupBarGraphWidget);
