import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { Add, ChevronRight, Edit, Send } from "@material-ui/icons";
import {
  getAdminDetailsApi,
  getProfileDataApi,
  getProjectApi,
} from "../../Api";
import profile_logo from "../../assets/profile_placeholder.png";
import rootStore from "../../stores/RootStore";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getLocalizedText } from "../../Functions";

const ParentContainer = styled.div`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled.div`
  ${`min-height: calc(100vh - 84px);`};

  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const Text1 = styled.p`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  color: #4d4e4f;
`;
const Text2 = styled.p`
  margin: 0px;
  font: normal normal 600 16px Open Sans;
  color: #4d4e4f;
  margin-bottom: 10px;
`;
const Text3 = styled.p`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #363636;
`;
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  row-gap: 20px;
`;
const Input = styled.input`
  width: 100%;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  font: normal normal 600 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const BottomWrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  column-gap: 20px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
`;
const FirstSection = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  background-color: white;
  flex-direction: column;
  align-items: center;
`;
const SecondSection = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  background-color: #f9f9fc;
  padding: 20px;
`;
const ThirdSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  background-color: #f9f9fc;
`;
const DetailsHeader = styled.span`
  font: normal normal 600 16px Open Sans;
  color: #979797;
  margin-bottom: 20px;
`;
const InfoWrapper = styled.div`
  width: 50%;
  padding: 12px 0px;
  height: fit-content;
`;
const Label = styled.div`
  font: normal normal 600 12px Open Sans;
  margin-bottom: 8px;
  color: #979799;
`;
const Detail = styled.div`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const Image = styled.img`
  width: 200px;
  height: 200px;
`;
const ImageWrapper = styled.div`
  display: flex;
  width: fit-content;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
`;
const AdminSettingPage = (props) => {
  const { authStore } = rootStore;
  const [accountData, setAccountData] = useState(null);
  const projectList = authStore.projectList;
  useEffect(() => {
    authStore.updateLeadDetailStatus();
    props.setShowBar(true);

    const getAccountData = async () => {
      try {
        let response = await getProfileDataApi();
        setAccountData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAccountData();
  }, []);

  const getProjectRole = (project) => {
    if (project.hasOwnProperty("role")) {
      let roleDetails = project["role"];
      if (roleDetails.hasOwnProperty("role")) {
        return roleDetails["role"];
      }
    }
    return "-";
  };

  return (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <Header>Settings</Header>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Header>Profile</Header>
          </TopWrapper>
          {accountData !== null && (
            <Wrapper>
              <Text1>Hello, {accountData.name}</Text1>
              <BottomWrapper>
                <FirstSection>
                  <ImageWrapper>
                    <Image src={profile_logo} alt="loading..." />
                  </ImageWrapper>
                  <Text2>{accountData.name}</Text2>
                  <Text3>{accountData.phone}</Text3>
                </FirstSection>
                <SecondSection>
                  <DetailsHeader>
                    {getLocalizedText("profile_details")}
                  </DetailsHeader>
                  <Row style={{ flexWrap: "wrap" }}>
                    <InfoWrapper>
                      <Label>{getLocalizedText("name")}</Label>
                      <Detail>{accountData.name}</Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>{getLocalizedText("role")}</Label>
                      <Detail>{accountData.role}</Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>{getLocalizedText("email")}</Label>
                      <Detail>{accountData.email}</Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>{getLocalizedText("phone")}</Label>
                      <Detail>{accountData.phone}</Detail>
                    </InfoWrapper>
                  </Row>
                </SecondSection>
                <Column>
                  <ThirdSection>
                    <DetailsHeader>
                      {getLocalizedText("account_details")}
                    </DetailsHeader>
                    <InfoWrapper>
                      <Label>{getLocalizedText("account_name")}</Label>
                      <Detail>{accountData.account.name}</Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>{getLocalizedText("account_owner")}</Label>
                      <Detail>
                        {accountData.account.owner !== null &&
                          accountData.account.owner.name}
                      </Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>{getLocalizedText("owner_email")}</Label>
                      <Detail>
                        {accountData.account.owner !== null &&
                          accountData.account.owner.email}
                      </Detail>
                    </InfoWrapper>
                  </ThirdSection>

                  <ThirdSection>
                    <DetailsHeader>
                      {getLocalizedText("projects")}
                    </DetailsHeader>
                    {projectList.length > 0 &&
                      projectList.map((project) => {
                        return (
                          <Row
                            style={{
                              margin: "0px",
                              padding: "0px",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Send
                              style={{
                                width: "12px",
                                height: "12px",
                                marginRight: "4px",
                              }}
                            />
                            <Detail>
                              {`${project["name"]} (${getProjectRole(
                                project
                              )})`}
                            </Detail>
                          </Row>
                        );
                      })}
                  </ThirdSection>
                </Column>
              </BottomWrapper>
            </Wrapper>
          )}
        </Container>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default observer(AdminSettingPage);
