import { Box, Divider, InputLabel, Modal, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  createContactApi,
  createLeadinCampaign,
  editContactApi,
  getAdminProjectApi,
  getMembersToAssignByProject,
  moveRawLeadsToProjectApi,
  searchContactByEmailApi,
  searchContactByPhoneApi,
} from "../../Api";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import { myTheme } from "../../themeUtils";
import { MoveRawLeadsResponseColumnHeaders } from "../../Db";
import { v4 as uuid } from "uuid";
import { DataGrid } from "@mui/x-data-grid";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import MapRawLeadColumnToProject from "./MapRawLeadColumnToProject";
import analytics from "../../Analytics";
import {
  consoleLogger,
  getLocalizedText,
  isNumber,
  isValidDateTime,
} from "../../Functions";
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
`;
const ModelWrapper = styled.form`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const Message = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  margin-bottom: 10px;
`;
const SubHeader = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #5a5a5a;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  width: 320px;
  column-gap: 20px;
  margin-bottom: 30px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  flex-direction: column;
`;
const AssignRawLeadsToProject = (props) => {
  const { authStore } = rootStore;
  const [project, setProject] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [member, setMember] = useState("");
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const projectList = authStore.projectList;
  const [counter, setCounter] = useState(0);
  const [rawToProjectList, setRawToProjectList] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [showRequiredColumnAlert, setShowRequiredColumnAlert] = useState(false); //this state variable is to track the required columns filled or not
  const [projectColumnList, setProjectColumnList] = useState([]);
  const [creatingLeads, setCreatingLeads] = useState(false);

  const moveRawLeadsToProject = async () => {
    setShowConfirmMessage(false);
    setCreatingLeads(true);
    let responseData = [];

    for (const lead of props.leads) {
      console.log(lead);
      let regularData = {
        lead_owner_id: member,
      };
      let customData = {};
      let contactsData = {};
      let hasParseError = false;
      for (const item in rawToProjectList) {
        if (rawToProjectList[item]["checked"]) {
          let raw_lead = rawToProjectList[item]["raw_lead"];
          let project_lead = rawToProjectList[item]["project_lead"];
          let value = "";
          if (lead.hasOwnProperty(raw_lead["field"])) {
            value = lead[raw_lead["field"]];
          }

          if (
            project_lead["data_type"] === "date" ||
            project_lead["data_type"] === "datetime"
          ) {
            if (isValidDateTime(value) === false) {
              hasParseError = true;
              consoleLogger(
                `data mismatch ::: date ::: ${value} :::  ${project_lead["field"]}`
              );
              break;
            }
          } else if (project_lead["data_type"] === "integer") {
            if (isNumber(value) === false) {
              hasParseError = true;
              consoleLogger(
                `data mismatch ::: integer ::: ${value} :::  ${project_lead["field"]}`
              );

              break;
            }
          }
          let index = projectColumnList.findIndex(
            (col) => col.id === project_lead.id
          );
          if (index !== -1) {
            let column = projectColumnList[index];
            if (column["contact_column"] !== null) {
              contactsData[column["contact_column"]] = value;
            }
          }
          if (project_lead["is_custom_param"]) {
            let field_name = project_lead["field"].replace(
              "custom_params_",
              ""
            );

            customData[field_name] = value;
          } else {
            regularData[project_lead["field"]] = value;
          }
        }
      }
      consoleLogger(`regular data ::: ${JSON.stringify(regularData)}`);
      consoleLogger(`custom data ::: ${JSON.stringify(customData)}`);
      consoleLogger(`contact data ::: ${JSON.stringify(contactsData)}`);
      if (hasParseError) {
        responseData.push({
          status: "failure",
          phone: regularData["phone"],
          message: "Error creating lead in the project",
        });
      } else {
        let contactDetails = {};
        let index = projectList.findIndex((proj) => project === proj["id"]);
        if (projectList[index]["phone_primary"]) {
          let response = await searchContactByPhoneApi({
            payload: { phone: regularData["phone"] },
          });
          if (response.length > 0) {
            contactDetails = response[0];
          }
        } else if (projectList[index]["email_primary"]) {
          let response = await searchContactByEmailApi({
            payload: { email: regularData["email"] },
          });
          if (response.length > 0) {
            contactDetails = response[0];
          }
        }

        if (Object.keys(contactDetails).length > 0) {
          let updateContactResponse = {};
          if (canUpdateContact(contactsData, contactDetails)) {
            updateContactResponse = await editContactApi({
              contactID: contactDetails["id"],
              payload: contactsData,
            });
            if (updateContactResponse.hasError()) {
              responseData.push({
                status: "failure",
                phone: regularData["phone"],
                message: "Error creating lead in the project",
              });
            } else {
              let createLeadPayload = {
                contact_id: contactDetails["id"],
                pid: project,
                lead_data: { ...regularData, custom_params: customData },
              };

              let response = await createLeadinCampaign({
                pid: project,
                data: createLeadPayload,
              });
              if (response.hasError()) {
                responseData.push({
                  status: "failure",
                  phone: regularData["phone"],
                  message: "Error creating lead in the project",
                });
              } else {
                responseData.push({
                  status: "success",
                  phone: regularData["phone"],
                  message: "Lead created succesfully in the project",
                });
              }
            }
          } else {
            let createLeadPayload = {
              contact_id: contactDetails["id"],
              lead_data: { ...regularData, custom_params: customData },
            };
            let response = await createLeadinCampaign({
              pid: project,
              data: createLeadPayload,
            });
            if (response.hasError()) {
              responseData.push({
                status: "failure",
                phone: regularData["phone"],
                message: "Error creating lead in the project",
              });
            } else {
              responseData.push({
                status: "success",
                phone: regularData["phone"],
                message: "Lead created succesfully in the project",
              });
            }
          }
        } else {
          let createContactResponse = await createContactApi({
            payload: contactsData,
          });
          if (createContactResponse.hasError()) {
            responseData.push({
              status: "failure",
              phone: regularData["phone"],
              message: "Error creating lead in the project",
            });
          } else {
            let createLeadPayload = {
              contact_id: createContactResponse.data["id"],
              lead_data: { ...regularData, custom_params: customData },
            };
            let response = await createLeadinCampaign({
              pid: project,
              data: createLeadPayload,
            });
            if (response.hasError()) {
              responseData.push({
                status: "failure",
                phone: regularData["phone"],
                message: "Error creating lead in the project",
              });
            } else {
              responseData.push({
                status: "success",
                phone: regularData["phone"],
                message: "Lead created succesfully in the project",
              });
            }
          }
        }
      }
    }
    setResponseData(responseData);
    setCreatingLeads(false);
    setIsSubmit(true);
  };
  const canUpdateContact = (generatedContactData, contactDetails) => {
    for (const key in generatedContactData) {
      if (generatedContactData[key] !== contactDetails[key]) {
        return true;
      }
    }
    return false;
  };
  const handleAssign = async (e) => {
    e.preventDefault();
    if (counter === 1) {
      if (isAtleastOneColumnMapped()) {
        setShowAlert(false);
        if (allProjectRequiredColumnsFilled()) {
          setShowRequiredColumnAlert(false);
          setShowConfirmMessage(true);
        } else {
          setShowRequiredColumnAlert(true);
        }
      } else {
        setShowAlert(true);
      }
    } else {
      setCounter(counter + 1);
      setShowAlert(false);
      setShowRequiredColumnAlert(false);
    }
  };
  const getProjectMemberList = async (id) => {
    try {
      let response = await getMembersToAssignByProject(id);
      setMemberList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleProject = (event) => {
    setMemberList([]);
    getProjectMemberList(event.target.value);
    setMember("");
    setProject(event.target.value);
  };

  const isAtleastOneColumnMapped = () => {
    let flag = false;
    Object.keys(rawToProjectList).forEach((item) => {
      if (rawToProjectList[item]["checked"] === true) {
        flag = true;
      }
    });
    return flag;
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  //below function is to check if all the required field of the selected project are mapped or not
  const allProjectRequiredColumnsFilled = () => {
    let required_columns_list = projectColumnList.filter(
      (column) => column.required
    );
    const checked_rawtoproject_list = Object.keys(rawToProjectList)
      .filter((key) => rawToProjectList[key].checked === true)
      .reduce((result, key) => {
        result[key] = rawToProjectList[key];
        return result;
      }, {});

    let flag = required_columns_list.every((item) => {
      return Object.values(checked_rawtoproject_list).some(
        (obj) => obj["project_lead"]["id"] === item.id
      );
    });

    return flag;
  };

  const getProjectName = () => {
    let index = projectList.findIndex((e) => e.id === project);
    if (index !== -1) {
      return projectList[index]["name"];
    } else {
      return "";
    }
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000133,
      "move_raw_leads_form_load",
      "raw_leads_tab",
      "",
      {}
    );
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          {creatingLeads || loading ? (
            <Box
              style={{
                height: "200px",
                width: "400px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {creatingLeads && (
                <Message
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  Moving Leads...
                </Message>
              )}
              <CircularProgress />
            </Box>
          ) : !isSubmit ? (
            <Box>
              <ModelWrapper onSubmit={handleAssign}>
                {counter === 0 ? (
                  <>
                    <Header>{getLocalizedText("move_to")}</Header>
                    <SubHeader>
                      {getLocalizedText("select_from_below_dropdown")}
                    </SubHeader>
                    <Divider style={{ margin: "10px 0px" }} />

                    <Row>
                      <Column>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{
                            font: "normal normal 600 10px Open Sans",
                            color: "#4d4e4f",
                          }}
                        >
                          {getLocalizedText("select_project")}*
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={project}
                          label="Select Member"
                          required
                          onChange={handleProject}
                          style={{
                            width: "150px",
                            font: "normal normal 500 12px Open Sans",
                          }}
                        >
                          {projectList.length > 0 &&
                            projectList.map((e, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  style={{ fontSize: "12px" }}
                                  id={e.id}
                                  value={e.id}
                                >
                                  {e.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </Column>
                      <Column>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{
                            font: "normal normal 600 10px Open Sans",
                            color: "#4d4e4f",
                          }}
                        >
                          {getLocalizedText("select_member")}*
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={member}
                          required
                          label="Select Member"
                          onChange={(e) => {
                            setMember(e.target.value);
                          }}
                          style={{
                            width: "150px",
                            font: "normal normal 500 12px Open Sans",
                          }}
                        >
                          {memberList.length > 0 &&
                            memberList.map((e, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  style={{ fontSize: "12px" }}
                                  id={e.id}
                                  value={e.id}
                                >
                                  {e.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </Column>
                    </Row>
                  </>
                ) : (
                  <MapRawLeadColumnToProject
                    projectId={project}
                    rawToProjectList={rawToProjectList}
                    setRawToProjectList={setRawToProjectList}
                    setShowAlert={setShowAlert}
                    setProjectColumnList={setProjectColumnList}
                    setShowRequiredColumnAlert={setShowRequiredColumnAlert}
                  />
                )}

                {showAlert && (
                  <Alert
                    style={{
                      width: "100%",
                      padding: "0px 10px",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                    severity="warning"
                  >
                    {getLocalizedText(
                      "please_map_atleast_one_column_to_proceed"
                    )}
                  </Alert>
                )}

                {showRequiredColumnAlert && (
                  <Alert
                    style={{
                      width: "100%",
                      padding: "0px 10px",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                    severity="warning"
                  >
                    {getLocalizedText(
                      "all_required_fields_for_lead_creation_are_not_mapped"
                    )}
                  </Alert>
                )}

                <Message>
                  <span style={{ color: "red" }}>*</span>
                  {getLocalizedText(
                    "please_note_that_the_raw_leads_will_be_deleted_at_the_end_of_this_step"
                  )}
                </Message>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      if (counter === 0) {
                        analytics.triggerEvent(
                          4625000134,
                          "cancel_button_tap",
                          "move_raw_leads_form",
                          "cancel_button",
                          {}
                        );
                        props.setOpen(false);
                      } else {
                        analytics.triggerEvent(
                          4625000136,
                          "previous_button_tap",
                          "move_raw_leads_form",
                          "previous_button",
                          {}
                        );
                        setCounter(counter - 1);
                        setShowAlert(false);
                        setShowRequiredColumnAlert(false);
                      }
                    }}
                    variant="contained"
                    color="inherit"
                    style={{
                      width: "100%",
                      font: "normal normal 600 10px Open Sans",
                      marginRight: "10px",
                      textTransform: "none",
                    }}
                  >
                    {counter === 0
                      ? getLocalizedText("cancel")
                      : getLocalizedText("prev")}
                  </Button>
                  <Button
                    onClick={() => {
                      if (counter === 0) {
                        analytics.triggerEvent(
                          4625000135,
                          "next_button_tap",
                          "move_raw_leads_form",
                          "next_button",
                          {}
                        );
                      } else {
                        analytics.triggerEvent(
                          4625000137,
                          "move_button_tap",
                          "move_raw_leads_form",
                          "move_button",
                          {}
                        );
                      }
                    }}
                    disabled={loading}
                    type="submit"
                    id="assign-btn"
                    variant="contained"
                    style={{
                      font: "normal normal 600 10px Open Sans",
                      width: "100%",
                      textTransform: "none",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {counter === 0 ? getLocalizedText("next") : "Move"}
                  </Button>
                </ButtonWrapper>
              </ModelWrapper>
            </Box>
          ) : responseData.length > 0 ? (
            <Box style={{ width: "600px" }}>
              <Column style={{ rowGap: "20px" }}>
                <Header>{getLocalizedText("result")}</Header>
                <div
                  style={{
                    width: "100%",
                    maxHeight: "40vh",
                    overflowY: "auto",
                  }}
                >
                  <DataGrid
                    getRowId={() => uuid() + Math.random()}
                    autoHeight={true}
                    rows={responseData}
                    columns={MoveRawLeadsResponseColumnHeaders}
                    checkboxSelection={false}
                    style={{ cursor: "pointer" }}
                    hideFooter={true}
                    disableColumnMenu={true}
                  />
                </div>
                <Button
                  variant="outlined"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                  style={{
                    width: "fit-content",
                    ...myTheme.Button.btnLight,

                    fontSize: "12px",
                    fontWeight: "bold",
                    margin: "auto",
                    textTransform: "none",
                  }}
                >
                  {getLocalizedText("close")}
                </Button>
              </Column>
            </Box>
          ) : (
            <Box>
              {" "}
              <MessageWrapper>
                <Message
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  {getLocalizedText("no_response_data_to_show")}
                </Message>
                <Button
                  variant="outlined"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                  color="inherit"
                  style={{
                    width: "fit-content",

                    fontSize: "12px",
                    fontWeight: "bold",
                    margin: "auto",
                    textTransform: "none",
                  }}
                >
                  {getLocalizedText("close")}
                </Button>
              </MessageWrapper>
            </Box>
          )}
        </ModelContainer>
      </Modal>

      <Dialog
        disableEnforceFocus
        open={showConfirmMessage}
        onClose={() => {
          setShowConfirmMessage(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold" }}>
          {getLocalizedText("alert")}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            style={{ fontWeight: "bold" }}
            id="alert-dialog-description"
          >
            {`Are you sure you want to move the raw leads to the project ${getProjectName()} ?`}
          </DialogContentText>
        </DialogContent>
        {
          <DialogActions>
            <Button
              color="primary"
              style={{ textTransform: "capitalize", fontWeight: "bold" }}
              onClick={() => {
                setShowConfirmMessage(false);
              }}
            >
              {getLocalizedText("cancel")}
            </Button>
            <Button
              color="primary"
              style={{ textTransform: "capitalize", fontWeight: "bold" }}
              onClick={moveRawLeadsToProject}
              autoFocus
            >
              {getLocalizedText("continue")}
            </Button>
          </DialogActions>
        }
      </Dialog>
    </>
  );
};

export default observer(AssignRawLeadsToProject);
