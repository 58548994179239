import { FormControl, Select, makeStyles } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled.span`
  font: normal normal normal 10px Open Sans;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const SingleSelectNew = (props) => {
  const classes = useStyles();
  const [list, setList] = useState([]);

  useEffect(() => {
    const getList = async () => {
      if (props.list.length > 0) {
        let val = props.list.split(",");
        setList(val);
      }
    };
    getList();
  }, [props.item.id]);
  return (
    <>
      {list.length > 0 && (
        <FormControl
          sx={{ width: "100%" }}
          classes={{
            root: classes.quantityRoot,
          }}
        >
          <Select
            classes={{
              icon: classes.icon,
            }}
            displayEmpty
            name={props.field}
            variant="outlined"
            onChange={(e) => props.handleChange(e)}
            style={{
              width: "100%",
              fontSize: "12px",
              height: "30px",
            }}
            value={
              props.selectedVal.hasOwnProperty(props.field)
                ? props.selectedVal[props.field]["value"]
                : ""
            }
            required={props.required}
          >
            {list.map((e) => {
              return (
                <MenuItem id={e} value={e}>
                  {e}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default SingleSelectNew;
