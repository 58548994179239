import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Modal,
  Typography,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import {
  dataRenderer,
  getLocalizedText,
  getLocalTimeFromIsoString,
  IsoToLocalTime,
} from "../../Functions";
import ReactAudioPlayer from "react-audio-player";
import rootStore from "../../stores/RootStore";
import CallSummaryPanel from "../call/CallSummaryPanel";
import analytics from "../../Analytics";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  width: 320px;
  column-gap: 0px;
  max-height: 60vh;
  overflow-y: auto;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
`;
const Label = styled.span`
  font: normal normal 500 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled.span`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const InputWrapperLg = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;

  align-items: center;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;

const ViewCallLogDetails = (props) => {
  const { phoneCallState } = rootStore;
  const [externalAudioUrl, setExternalAudioUrl] = useState(null);
  const [showSummaryButton, setShowSummaryButton] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const handleClose = () => {
    props.setData(null);
    props.setOpen(false);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000068,
      "call_log_information_form_load",
      "call_logs_tab",
      "",
      {}
    );

    if (props.data !== null) {
      let dialerLog = {};

      if (props.data["dialer_log"] !== null) {
        dialerLog = props.data["dialer_log"];
      }
      let externalUrl = "";

      if (Object.keys(dialerLog).length > 0) {
        let summary = {};
        if (dialerLog.hasOwnProperty("summary_response")) {
          if (dialerLog["summary_response"] !== null) {
            summary = dialerLog["summary_response"];
            setShowSummaryButton(true);
          }
        }

        externalUrl = phoneCallState.getExternalDialerAudioUrl(summary);
        if (externalUrl !== "") {
          setExternalAudioUrl(externalUrl);
        }
      }
    }
  }, []);

  const noteTextGetter = (raw_string) => {
    return raw_string.replace(/\r\n|\n/g, "\n");
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        disableBackdropClick
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            {props.data !== null ? (
              <Container>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    marginBottom: "16px",
                  }}
                >
                  <Row>
                    <Info color="primary" style={{ marginRight: "8px" }} />
                    <Header>{getLocalizedText("info")}</Header>
                  </Row>
                </Box>
                <Form>
                  <InputContainer>
                    <InputWrapper>
                      <Label>{getLocalizedText("caller_name")}</Label>
                      <Detail>{dataRenderer(props.data.caller.name)}</Detail>
                    </InputWrapper>
                    <InputWrapper>
                      <Label>{getLocalizedText("status")}</Label>{" "}
                      <Detail>{dataRenderer(props.data.lead_status)}</Detail>
                    </InputWrapper>
                    <InputWrapper>
                      <Label>{getLocalizedText("sub_status")}</Label>
                      <Detail>
                        {dataRenderer(props.data.lead_sub_status)}
                      </Detail>
                    </InputWrapper>
                    <InputWrapper>
                      <Label>{getLocalizedText("next_follow_up_on")}</Label>
                      <Detail>
                        {props.data.next_followup_on !== null
                          ? IsoToLocalTime(props.data.next_followup_on)
                          : "-"}
                      </Detail>
                    </InputWrapper>
                    <InputWrapperLg>
                      <Label>{getLocalizedText("call_notes")}</Label>
                      {props.data.notes !== "" && props.data.notes !== null ? (
                        <Typography
                          component="div"
                          style={{
                            whiteSpace: "pre-line",
                          }}
                        >
                          {noteTextGetter(props.data.notes)}
                        </Typography>
                      ) : (
                        <Detail>-</Detail>
                      )}
                    </InputWrapperLg>
                    <InputWrapperLg>
                      <Label>{getLocalizedText("customer_notes")}</Label>
                      {props.data.customer_notes !== "" &&
                      props.data.customer_notes !== null ? (
                        <Typography
                          component="div"
                          style={{
                            whiteSpace: "pre-line",
                          }}
                        >
                          {noteTextGetter(props.data.customer_notes)}
                        </Typography>
                      ) : (
                        <Detail>-</Detail>
                      )}
                    </InputWrapperLg>
                    <InputWrapper>
                      <Label>{getLocalizedText("call_start_time")}</Label>
                      <Detail>
                        {getLocalTimeFromIsoString(props.data.call_start_time)}
                      </Detail>
                    </InputWrapper>
                    <InputWrapper>
                      <Label>{getLocalizedText("call_end_time")}</Label>
                      <Detail>
                        {getLocalTimeFromIsoString(props.data.call_end_time)}
                      </Detail>
                    </InputWrapper>
                    <InputWrapper>
                      <Label>{getLocalizedText("call_durations")}</Label>
                      <Detail>
                        {props.data.call_duration !== null
                          ? `${props.data.call_duration}`
                          : "-"}
                      </Detail>
                    </InputWrapper>

                    {props.data.audio !== null && (
                      <InputWrapper style={{ width: "100%" }}>
                        <Label>{getLocalizedText("audio")}</Label>
                        <ReactAudioPlayer
                          src={props.data.audio}
                          autoPlay={false}
                          controls
                        />
                      </InputWrapper>
                    )}
                    {externalAudioUrl !== null && (
                      <InputWrapper style={{ width: "100%" }}>
                        <Label>{getLocalizedText("audio")}</Label>
                        <ReactAudioPlayer
                          src={externalAudioUrl}
                          autoPlay={false}
                          controls
                        />
                      </InputWrapper>
                    )}
                  </InputContainer>

                  <ButtonWrapper>
                    {showSummaryButton && (
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setOpenSummary(true);
                        }}
                        style={{
                          width: "120px",
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "bold",
                          marginRight: "8px",
                        }}
                      >
                        {getLocalizedText("call_summary")}
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        analytics.triggerEvent(
                          4625000069,
                          "close_button_tap",
                          "call_log_information_form",
                          "close_button",
                          {}
                        );
                        handleClose();
                      }}
                      type="button"
                      variant="outlined"
                      color="primary"
                      style={{
                        width: "120px",
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        marginLeft: showSummaryButton ? "8px" : "0px",
                      }}
                    >
                      {getLocalizedText("close")}
                    </Button>
                  </ButtonWrapper>
                </Form>
              </Container>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </ModelContainer>
      </Modal>
      {openSummary && (
        <Drawer
          anchor={"right"}
          open={openSummary}
          onClose={() => {
            setOpenSummary(false);
          }}
        >
          {
            <CallSummaryPanel
              setOpen={openSummary}
              open={setOpenSummary}
              data={props.data["dialer_log"]["summary_response"]}
            />
          }
        </Drawer>
      )}
    </>
  );
};

export default observer(ViewCallLogDetails);
