import React, { useEffect, useState } from "react";
import "./otp.css";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Auth } from "../../Db";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import OtpInput from "react-otp-input";
import { Button } from "@material-ui/core";
import {
  sendOtpEmailApi,
  sendOtpPhoneApi,
  userLoginEmailApi,
  userLoginPhoneApi,
} from "../../Api";
import { Edit } from "@material-ui/icons";
import { myTheme } from "../../themeUtils";
import { getLocalizedText, setCookie } from "../../Functions";
import analytics from "../../Analytics";
const Header = styled.span`
  font: normal normal 600 24px Poppins;
  @media (min-width: 1920px) {
    font: normal normal 600 28px Poppins;
  }
`;
const SubHeader = styled.span`
  font: normal normal normal 15px Poppins;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 10px;
  @media (min-width: 1920px) {
    font: normal normal 500 20px Poppins;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const ButtonWrapper = styled.div`
  margin-top: 30px;
  width: 350px;
  @media (min-width: 1920px) {
    width: 100%;
    height: 50px;
  }
`;
const ResendWrapper = styled.div`
  width: 70%;
  margin: auto;
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const Resend = styled.span`
  font: normal normal normal 12px/18px Poppins;
  color: #6f6f6f;
  margin: auto;
`;
const Error = styled.span`
  color: red;
  margin-top: 12px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const EditWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
`;
const Otp = (props) => {
  const [counter, setCounter] = React.useState(29);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleLoginSuccess = async () => {
    if (userStore.UserData !== null) {
      await authStore.postInit();
      if (authStore.canFetchStartDayApi()) {
        await authStore.fetchUserStartDayApiCall(); //this api fetch function is for startday api (get fetched once in a day), calling it here as we need to determine role which we will get after fetching userdata api
      }
      if (authStore.canRedirectToDahboard()) {
        navigate("/summary");
      } else {
        navigate("/");
      }
    }
  };
  let navigate = useNavigate();
  const [state, setState] = useState({ otp: "" });
  const { authStore, userStore } = rootStore;
  const handleClick = async (e) => {
    e.preventDefault();

    let otp = parseInt(state.otp);
    let payload = { otp: otp, account: props.value.account };
    if (props.inputFieldType === "phone") {
      payload["phone"] = `+91-${props.value.phone}`;
      let response = await userLoginPhoneApi(payload);
      if (response.hasError()) {
        setErrorMessage(response.errorMessage);
        setIsError(true);
      } else {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("uid", response.data.user.id);
        setCookie("token", response.data.token);
        setCookie("uid", response.data.uid);
        rootStore.authStore.setAccountId(response.data.user.account.id);
        rootStore.userStore.getData(response.data.user);
        await handleLoginSuccess();
      }
    } else {
      payload["email"] = props.value.email;
      let response = await userLoginEmailApi(payload);
      if (response.hasError()) {
        setErrorMessage(response.errorMessage);
        setIsError(true);
      } else {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("uid", response.data.user.id);
        setCookie("token", response.data.token);
        setCookie("uid", response.data.uid);
        rootStore.authStore.setAccountId(response.data.user.account.id);
        rootStore.userStore.getData(response.data.user);
        await handleLoginSuccess();
      }
    }
  };
  const handleResend = async () => {
    try {
      let payload = { type: "login", account: props.value.account };
      if (props.inputFieldType === "phone") {
        payload["phone"] = `+91-${props.value.phone}`;
        let response = await sendOtpPhoneApi(payload);
      } else {
        payload["email"] = props.value.email;
        let response = await sendOtpEmailApi(payload);
      }
      setCounter(29);
      setIsError(false);
      setState({ otp: "" });
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = () => {
    props.setStatus(false);
  };
  return (
    <>
      <Header>
        {props.inputFieldType === "phone"
          ? Auth[0].otp.phone_header
          : Auth[0].otp.email_header}
      </Header>
      <SubHeader>
        {Auth[0].otp.subheader}
        <Row>
          <span style={{ color: "#185dd2" }}>
            {props.inputFieldType === "phone"
              ? props.value.phone
              : props.value.email}
          </span>
          <EditWrapper
            onClick={() => {
              analytics.triggerEvent(
                4625000020,
                "edit_phone_tap",
                "login_page",
                "edit_phone_link",
                {}
              );
              handleEdit();
            }}
          >
            <Edit
              style={{
                color: "#185dd2",
                marginLeft: "6px",
                height: "16px",
                width: "16px",
              }}
            />
            <span style={{ color: "#185dd2", fontSize: "12px" }}>edit</span>
          </EditWrapper>
        </Row>
      </SubHeader>
      <Form onSubmit={handleClick}>
        <OtpInput
          hasErrored={isError}
          errorStyle={{ border: "1px solid red" }}
          value={state.otp}
          onChange={(otp) => setState({ otp })}
          numInputs={4}
          inputStyle="otp-input"
          shouldAutoFocus={true}
          isInputNum={true}
          onKeyUp={(e) => (e.key === "Enter" ? handleClick(e) : null)}
        />
        {isError && <Error>{errorMessage}</Error>}
        <ButtonWrapper>
          <Button
            onClick={() => {
              analytics.triggerEvent(
                4625000015,
                "continue_button_tap",
                "login_page",
                "otp_continue_button",
                {}
              );
            }}
            variant="contained"
            style={{ width: "100%", ...myTheme.Button.btnBlue }}
            type="submit"
          >
            {getLocalizedText("continue")}
          </Button>
        </ButtonWrapper>
        <ResendWrapper>
          <Resend>
            {counter !== 0 ? Auth[0].otp.disclaimer : null}{" "}
            {counter !== 0 ? (
              counter + "sec"
            ) : (
              <Button
                style={{
                  fontSize: "10px",
                  width: "fit-content",
                  padding: "0px",
                }}
                variant="outlined"
                onClick={() => {
                  analytics.triggerEvent(
                    4625000021,
                    "resend_button_tap",
                    "login_page",
                    "resend_button",
                    {}
                  );
                  handleResend();
                }}
              >
                {getLocalizedText("resend")}
              </Button>
            )}{" "}
          </Resend>
        </ResendWrapper>
      </Form>
    </>
  );
};

export default observer(Otp);
