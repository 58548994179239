import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Select,
  makeStyles,
} from "@material-ui/core";
import {
  AppBar,
  Box,
  MenuItem,
  OutlinedInput,
  Toolbar,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import MuiAlert from "@mui/material/Alert";
import analytics from "../../Analytics";
import { myTheme } from "../../themeUtils";
import {
  addAdminColumnApi,
  editAdminColumnApi,
  editAdminSystemColumnApi,
} from "../../Api";
import {
  IsoToLocalDate,
  getIsoString,
  getLocalizedText,
} from "../../Functions";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const AddColumnSidepanel = (props) => {
  const classes = useStyles();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({
    display_name: "",
    group: "",
    filterable: true,
    editable: false,
    is_visible: true,
    data_type: "",
    required: true,
    enable_for_create: true,
    hint: "",
    max: "",
    min: "",
  });
  const dataTypeList = ["string", "integer", "date", "boolean"];
  const filterTypeList = {
    string: ["normal", "list"],
    integer: ["normal", "range", "list"],
    date: ["normal", "range"],
    boolean: ["normal"],
  };
  const [inputList, setInputList] = useState([]);
  const [filterType, setFilterType] = useState("");
  const [radioVal, setRadioVal] = useState("yes");
  const [editableVal, setEditableVal] = useState("no");
  const [requiredVal, setRequiredVal] = useState("yes");
  const [enableForCreate, setEnableForCreate] = useState("yes");
  const [isVisible, setIsVisible] = useState("yes");
  const [disabledFields, setDisabledFields] = useState([]);

  const handleClose = () => {
    props.setOpen(false);
    props.setIsEdit(false);
  };

  const addColumn = async () => {
    let field = "custom_params_" + user.display_name.replace(" ", "_");
    let data = {
      field: field,
      display_name: user.display_name,
      group: user.group,
      is_visible: user.is_visible,
      filterable: user.filterable,
      editable: user.editable,
      required: user.required,
      data_type: user.data_type,
      enable_for_create: user.enable_for_create,
    };

    data["filter_type"] = filterType;
    if (filterType === "list") {
      data["filter_input"] = inputList.toString();
    }

    if (user.hint !== "") {
      data["hint"] = user.hint;
    }
    if (user.data_type === "integer") {
      if (user.min !== "") {
        data["min"] = parseInt(user.min);
      }
      if (user.max !== "") {
        data["max"] = parseInt(user.max);
      }
    } else if (user.data_type === "date") {
      if (user.min !== "") {
        data["min"] = getIsoString(user.min);
      }
      if (user.max !== "") {
        data["max"] = getIsoString(user.max);
      }
    }
    try {
      let response = await addAdminColumnApi({
        id: props.id,
        data: data,
      });
      props.setRefresh(!props.refresh);
      setIsSubmitSuccess(true);
    } catch (error) {
      setErrorMessage(getLocalizedText("something_went_wrong"));
      setIsSubmitFail(true);
      console.log(error);
    }
  };
  const editColumn = async () => {
    let data = {
      column_id: props.editableData.id,
      field: props.editableData.field,
      display_name: user.display_name,
      is_visible: user.is_visible,
      group: user.group,
      filterable: user.filterable,
      editable: user.editable,
      required: user.required,
      enable_for_create: user.enable_for_create,
      data_type: user.data_type,
    };

    data["filter_type"] = filterType;
    if (filterType === "list") {
      data["filter_input"] = inputList.toString();
    }

    if (user.hint !== "") {
      data["hint"] = user.hint;
    }
    if (user.data_type === "integer") {
      if (user.min !== "") {
        data["min"] = parseInt(user.min);
      }
      if (user.max !== "") {
        data["max"] = parseInt(user.max);
      }
    } else if (user.data_type === "date") {
      if (user.min !== "") {
        data["min"] = getIsoString(user.min);
      }
      if (user.max !== "") {
        data["max"] = getIsoString(user.max);
      }
    }
    try {
      if (props.editableData.system) {
        let response = await editAdminSystemColumnApi({
          id: props.id,
          data: data,
        });
      } else {
        let response = await editAdminColumnApi({
          id: props.id,
          data: data,
        });
      }
      props.setRefresh(!props.refresh);
      setIsSubmitSuccess(true);
    } catch (error) {
      setErrorMessage(getLocalizedText("something_went_wrong"));
      setIsSubmitFail(true);
      console.log(error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (props.isEdit) {
      editColumn();
    } else {
      addColumn();
    }
  };

  const init = async () => {
    if (props.isEdit === true) {
      analytics.triggerEvent(
        4625000231,
        "edit_column_form_load",
        "project_columns_page",
        "",
        {}
      );
      let tempObj = {
        display_name: props.editableData.name,
        group: props.editableData.group,
        filterable: props.editableData.filterable,
        is_visible: props.editableData.is_visible,
        editable: props.editableData.editable,
        data_type: props.editableData.data_type,
        required: props.editableData.required,
        enable_for_create: props.editableData.enable_for_create,
        hint: props.editableData.hint !== null ? props.editableData.hint : "",
      };
      if (props.editableData.data_type === "integer") {
        tempObj["min"] =
          props.editableData.min !== null ? props.editableData.min : "";
        tempObj["max"] =
          props.editableData.max !== null ? props.editableData.max : "";
      } else if (props.editableData.data_type === "date") {
        tempObj["min"] =
          props.editableData.min !== null
            ? IsoToLocalDate(props.editableData.min)
            : "";
        tempObj["max"] =
          props.editableData.max !== null
            ? IsoToLocalDate(props.editableData.max)
            : "";
      } else {
        tempObj["min"] = "";
        tempObj["max"] = "";
      }
      setUser(tempObj);
      setRadioVal(props.editableData.filterable ? "yes" : "no");
      setEditableVal(props.editableData.editable ? "yes" : "no");
      setRequiredVal(props.editableData.required ? "yes" : "no");
      setEnableForCreate(props.editableData.enable_for_create ? "yes" : "no");
      setIsVisible(props.editableData.is_visible ? "yes" : "no");

      if (props.editableData.hasOwnProperty("filter_type")) {
        setFilterType(props.editableData.filter_type);
        if (
          props.editableData.filter_input !== "" &&
          props.editableData.filter_input !== null
        ) {
          let newList = props.editableData.filter_input.split(",");
          setInputList(newList);
        }
      }
    } else {
      analytics.triggerEvent(
        4625000227,
        "add_column_form_load",
        "project_columns_page",
        "",
        {}
      );
    }

    setDisabledList();

    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };
  const handleSubStatus = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setInputList([...inputList, value]);
    e.target.value = "";
  };
  const handleDelete = (index) => {
    setInputList(inputList.filter((e, i) => i !== index));
  };
  const setDisabledList = () => {
    if (props.isEdit) {
      if (props.editableData["derived"]) {
        setDisabledFields([
          "group",
          "column_name",
          "required",
          "enable_for_create",
          "is_visible",
          "data_type",
        ]);
      } else if (props.editableData["system"]) {
        setDisabledFields(["column_name", "group", "data_type"]);
      }
    }
  };

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            {props.isEdit ? (
              <ModelHeader>{getLocalizedText("edit_details")}</ModelHeader>
            ) : (
              <ModelHeader>{getLocalizedText("add_a_new_column")}</ModelHeader>
            )}
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper
            component={"form"}
            onSubmit={handleSubmit}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <InputContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{getLocalizedText("column_name")}*</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={user.display_name}
                      disabled={disabledFields.includes("column_name")}
                      required
                      type="text"
                      placeholder="Enter name here..."
                      name="display_name"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{getLocalizedText("group_name")}</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={user.group}
                      disabled={disabledFields.includes("group")}
                      type="text"
                      placeholder="Enter group name here..."
                      name="group"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ marginBottom: "0px", minWidth: "120px" }}>
                      {getLocalizedText("is_visible")}*
                    </Label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            size="small"
                            disabled={disabledFields.includes("is_visible")}
                            checked={isVisible === "yes"}
                            onChange={(e) => {
                              setIsVisible(e.target.value);

                              setUser({ ...user, is_visible: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            size="small"
                            disabled={disabledFields.includes("is_visible")}
                            checked={isVisible === "no"}
                            onChange={(e) => {
                              setIsVisible(e.target.value);

                              setUser({ ...user, is_visible: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ marginBottom: "0px", minWidth: "120px" }}>
                      {getLocalizedText("required")}*
                    </Label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        disabled={disabledFields.includes("required")}
                        control={
                          <Radio
                            size="small"
                            checked={requiredVal === "yes"}
                            onChange={(e) => {
                              setRequiredVal(e.target.value);

                              setUser({ ...user, required: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        disabled={disabledFields.includes("required")}
                        control={
                          <Radio
                            size="small"
                            checked={requiredVal === "no"}
                            onChange={(e) => {
                              setRequiredVal(e.target.value);
                              setUser({ ...user, required: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ marginBottom: "0px", minWidth: "120px" }}>
                      {getLocalizedText("filterable")}*
                    </Label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            size="small"
                            checked={radioVal === "yes"}
                            onChange={(e) => {
                              setRadioVal(e.target.value);

                              setUser({ ...user, filterable: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            size="small"
                            checked={radioVal === "no"}
                            onChange={(e) => {
                              setRadioVal(e.target.value);
                              setUser({ ...user, filterable: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ marginBottom: "0px", minWidth: "120px" }}>
                      {getLocalizedText("editable")}*
                    </Label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            size="small"
                            checked={editableVal === "yes"}
                            onChange={(e) => {
                              setEditableVal(e.target.value);

                              setUser({ ...user, editable: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            size="small"
                            checked={editableVal === "no"}
                            onChange={(e) => {
                              setEditableVal(e.target.value);
                              setUser({ ...user, editable: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ marginBottom: "0px", minWidth: "120px" }}>
                      {getLocalizedText("enable_for_create")}*
                    </Label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            size="small"
                            disabled={disabledFields.includes(
                              "enable_for_create"
                            )}
                            checked={enableForCreate === "yes"}
                            onChange={(e) => {
                              setEnableForCreate(e.target.value);

                              setUser({ ...user, enable_for_create: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            size="small"
                            disabled={disabledFields.includes(
                              "enable_for_create"
                            )}
                            checked={enableForCreate === "no"}
                            onChange={(e) => {
                              setEnableForCreate(e.target.value);
                              setUser({ ...user, enable_for_create: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                </Grid>

                {dataTypeList && (
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>{getLocalizedText("select_data_type")}*</Label>
                      <FormControl
                        sx={{ width: "100%" }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                        disabled={disabledFields.includes("data_type")}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          value={user.data_type}
                          name="data_type"
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          required
                          onChange={(e) => {
                            setUser({
                              ...user,
                              data_type: e.target.value,
                              min: "",
                              max: "",
                            });
                          }}
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "0px",
                          }}
                        >
                          {dataTypeList.map((e) => {
                            return (
                              <MenuItem id={e} value={e}>
                                {e}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </InputWrapper>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("select_filter_type")}</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        value={filterType}
                        classes={{
                          icon: classes.icon,
                        }}
                        name="filter_type"
                        displayEmpty
                        disableUnderline
                        variant="outlined"
                        onChange={(e) => {
                          setFilterType(e.target.value);
                        }}
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "0px",
                        }}
                      >
                        {filterTypeList[user.data_type] &&
                          filterTypeList[user.data_type].map((e) => {
                            return (
                              <MenuItem id={e} value={e}>
                                {e}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                </Grid>

                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{getLocalizedText("add_filter_input")}*</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      type="text"
                      placeholder="Type something"
                      name="sub_status"
                      onKeyDown={handleSubStatus}
                    />
                    <Grid container spacing={2} style={{ padding: "12px 0px" }}>
                      {inputList.map((e, i) => {
                        return (
                          <Grid item>
                            <Chip
                              key={e}
                              label={e}
                              onDelete={() => handleDelete(i)}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Hint Text</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={user.hint}
                      type="text"
                      placeholder="Enter value"
                      name="hint"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Min</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                        max: user.max,
                      }}
                      value={user.min}
                      type={
                        user.data_type === "integer"
                          ? "number"
                          : user.data_type === "date"
                          ? "date"
                          : "text"
                      }
                      placeholder="Enter value"
                      name="min"
                      disabled={
                        user.data_type !== "integer" &&
                        user.data_type !== "date"
                      }
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Max</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: user.min,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={user.max}
                      disabled={
                        user.data_type !== "integer" &&
                        user.data_type !== "date"
                      }
                      type={
                        user.data_type === "integer"
                          ? "number"
                          : user.data_type === "date"
                          ? "date"
                          : "text"
                      }
                      placeholder="Enter value"
                      name="max"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
              </Grid>
            </InputContainer>
            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500 }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      if (props.isEdit) {
                        analytics.triggerEvent(
                          4625000232,
                          "cancel_button_tap",
                          "edit_column_form",
                          "cancel_button",
                          {}
                        );
                      } else {
                        analytics.triggerEvent(
                          4625000228,
                          "cancel_button_tap",
                          "add_column_form",
                          "cancel_button",
                          {}
                        );
                      }
                      handleClose();
                    }}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      if (props.isEdit) {
                        analytics.triggerEvent(
                          4625000233,
                          "submit_button_tap",
                          "edit_column_form",
                          "submit_button",
                          {}
                        );
                      } else {
                        analytics.triggerEvent(
                          4625000229,
                          "submit_button_tap",
                          "add_column_form",
                          "submit_button",
                          {}
                        );
                      }
                    }}
                    type="submit"
                    variant="contained"
                    id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {getLocalizedText("submit_details")}
                  </Button>
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            {props.isEdit ? (
              <Message>Column updated successfully!</Message>
            ) : (
              <Message>Column created successfully!</Message>
            )}

            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        )}
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(AddColumnSidepanel);
