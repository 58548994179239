import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const CallConfirmationDailog = (props) => {
  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold" }}>
          Alert
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            style={{ fontWeight: "bold" }}
            id="alert-dialog-description"
          >
            {"Are you sure you want to make a call?"}
          </DialogContentText>
        </DialogContent>
        {
          <DialogActions>
            <Button
              color="primary"
              style={{ textTransform: "capitalize", fontWeight: "bold" }}
              onClick={() => {
                props.setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              style={{ textTransform: "capitalize", fontWeight: "bold" }}
              onClick={props.handlePhoneCall}
              autoFocus
            >
              Continue
            </Button>
          </DialogActions>
        }
      </Dialog>
    </>
  );
};
export default CallConfirmationDailog;
