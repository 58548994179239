import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  Modal,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { changeLeadstatusApi, editContactApi } from "../../Api";
import { consoleLogger, getIsoString, getLocalizedText } from "../../Functions";
import { Alert, MenuItem, OutlinedInput } from "@mui/material";
import { myTheme } from "../../themeUtils";
import moment from "moment";
import analytics from "../../Analytics";
import UpdateFieldsWidget from "../status_param_column_rendere/UpdateFieldsWidget";
import MuiAlert from "@mui/material/Alert";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  width: 420px;
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  justify-content: space-between;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;
const MessageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled.p`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const UpdateStatusModal = (props) => {
  const [subStatusList, setSubStatusList] = useState([]);
  const classes = useStyles();
  const [user, setUser] = useState({
    status: "",
    sub_status: "",
    comments: "",
  });
  const [prevState, setPrevState] = useState({});
  const [btnText, setBtnText] = useState("Update");
  const columns = [...rootStore.userStore.AllColumnsList];

  const [showAlert, setShowAlert] = useState(false);
  const [updateFieldList, setUpdateFieldList] = useState([]);
  const [updateFieldInputData, setUpdateFieldInputData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const stageStatusList = [...rootStore.authStore.stageStatusList];
  const statusList = [...rootStore.authStore.statusList];
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const hasChange = () => {
    let flag = true;
    if (Object.keys(prevState).length > 0 && Object.keys(user).length > 0) {
      Object.keys(user).map((item) => {
        if (user[item] === prevState[item] && item !== "sub_status") {
          flag = false;
        }
      });
    }
    return flag;
  };

  const generateContactPayload = (data) => {
    let contactData = {};

    for (const key in data) {
      let index = columns.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let columnData = columns[index];
        if (columnData["contact_column"] !== null) {
          let fieldData = data[columnData["field"]];
          let value = fieldData["value"];
          if (value !== "") {
            if (fieldData["type"] === "date") {
              value = getIsoString(value);
            } else if (fieldData["type"] === "datetime") {
              value = getDateTimeString({
                dateString: value["date"],
                timeString: value["time"],
              });
            } else if (fieldData["type"] === "integer") {
              value = parseInt(value);
            }
            contactData[columnData["contact_column"]] = value;
          }
        }
      }
    }
    return contactData;
  };

  const updateLeadStatus = async () => {
    try {
      let data = {};
      let updateFieldsPayload =
        generateUpdateFieldsPayloadData(updateFieldInputData);
      data["status"] = user.status;
      data["sub_status"] = user.sub_status;
      if (user.comments !== "") {
        data["comments"] = user.comments;
      }
      data["update_fields"] = updateFieldsPayload;
      if (updateFieldsPayload.hasOwnProperty("next_follow_up_on")) {
        data["next_followup_on"] = updateFieldsPayload["next_follow_up_on"];
      }

      let response = await changeLeadstatusApi({
        id: props.id,
        data: data,
      });
      consoleLogger(response.data);
      props.setRefreshLeadDetails(true);
      setIsSubmitSuccess(true);
      // props.setOpen(false);
    } catch (error) {
      setErrorMessage(getLocalizedText("some_error_occurred"));
      setShowAlert(true);
      console.log(error);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setShowAlert(false);

    if (hasChange()) {
      let contactPayload = generateContactPayload(updateFieldInputData);
      if (Object.keys(contactPayload).length > 0) {
        if (
          props.data["contact_id"] !== null &&
          props.data["contact_id"] !== ""
        ) {
          let response = await editContactApi({
            contactID: props.data["contact_id"],
            payload: contactPayload,
          });
          if (!response.hasError()) {
            await updateLeadStatus();
          } else {
            setErrorMessage(response.errorMessage);
            setShowAlert(true);
          }
        }
      } else {
        await updateLeadStatus();
      }
    } else {
      setErrorMessage("Kindly make a modification before submitting the form.");
      setShowAlert(true);
    }
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000057,
      "update_status_form_load",
      "lead_details_page",
      "",
      {}
    );
    const getStatusList = async () => {
      try {
        if (Object.keys(props.data).length > 0) {
          setUser({
            ...user,
            status:
              props.data.lead_status !== null ? props.data.lead_status : "",
            sub_status:
              props.data.lead_sub_status !== null
                ? props.data.lead_sub_status
                : "",
          });
          setPrevState({
            ...prevState,
            status:
              props.data.lead_status !== null ? props.data.lead_status : "",
            sub_status:
              props.data.lead_sub_status !== null
                ? props.data.lead_sub_status
                : "",
          });
        }
        if (statusList.length > 0 && Object.keys(props.data).length > 0) {
          statusList.map((item) => {
            if (item.status === props.data.lead_status) {
              initUpdateFieldList(item.status);
              setSubStatusList(item.sub_status);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    getStatusList();
  }, []);
  const handleClose = () => {
    props.setOpen(false);
  };

  const getUpdateFieldsInStageStatus = (statusName) => {
    let tempList = [];
    let currentLeadStage =
      props.data.hasOwnProperty("lead_stage") &&
      props.data["lead_stage"] !== null
        ? props.data["lead_stage"]
        : "";
    let index = stageStatusList.findIndex(
      (item) =>
        item["lead_status"]["status"] === statusName &&
        item["lead_stage"]["stage"] === currentLeadStage
    );
    if (index !== -1) {
      if (
        stageStatusList[index].hasOwnProperty("update_fields") &&
        stageStatusList[index]["update_fields"] !== null
      ) {
        let updateFieldsData = stageStatusList[index]["update_fields"];
        updateFieldsData.forEach((data) => {
          let index = columns.findIndex(
            (column) => column["id"] === data["column_meta"]["id"]
          );
          if (index !== -1) {
            let columnData = { ...columns[index] };
            columnData["required"] = data["required"];
            tempList.push(columnData);
          }
        });
      }
    }
    return tempList;
  };

  const getUpdateFieldsInStatus = (statusName) => {
    let tempList = [];
    let index = statusList.findIndex(
      (status) => status["status"] === statusName
    );
    if (index !== -1) {
      if (
        statusList[index].hasOwnProperty("update_fields") &&
        statusList[index]["update_fields"] !== null
      ) {
        let updateFieldsData = statusList[index]["update_fields"];
        updateFieldsData.forEach((data) => {
          let index = columns.findIndex(
            (column) => column["id"] === data["column_meta"]["id"]
          );
          if (index !== -1) {
            let columnData = { ...columns[index] };
            columnData["required"] = data["required"];
            tempList.push(columnData);
          }
        });
      }
    }
    return tempList;
  };

  //**********************functions related to update_fields changes*************************** */
  const initUpdateFieldList = (statusName) => {
    let updateFieldsInStageSTatus = getUpdateFieldsInStageStatus(statusName);
    if (updateFieldsInStageSTatus.length > 0) {
      setUpdateFieldList(updateFieldsInStageSTatus);
    } else {
      let updateFieldsInStatus = getUpdateFieldsInStatus(statusName);
      setUpdateFieldList(updateFieldsInStatus);
    }
  };

  const getDateTimeString = ({ dateString, timeString }) => {
    let newDateTime = null;

    if (dateString !== "") {
      if (timeString !== null) {
        const newTime = timeString.format("HH:mm:ss");
        newDateTime = moment(
          `${dateString} ${newTime}`,
          "YYYY-MM-DD HH:mm:ss"
        ).toISOString();
      } else {
        newDateTime = moment(dateString, "YYYY-MM-DD").toISOString();
      }
    }

    return newDateTime;
  };

  const generateUpdateFieldsPayloadData = (data) => {
    let normalList = {};
    let customList = {};
    for (const key in data) {
      let value = data[key]["value"];
      let index = columns.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        if (value !== "") {
          if (data[key]["type"] === "date") {
            value = getIsoString(value);
          } else if (data[key]["type"] === "datetime") {
            value = getDateTimeString({
              dateString: value["date"],
              timeString: value["time"],
            });
          } else if (data[key]["type"] === "integer") {
            value = parseInt(value);
          }
          if (columns[index]["is_custom_param"]) {
            let newKey = key.replace("custom_params_", "");
            customList[newKey] = value;
          } else {
            normalList[key] = value;
          }
        }
      }
    }

    return { ...normalList, custom_params: customList };
  };
  //*******************************************END*********************************************** */
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box>
              <Container>
                <Header>
                  {getLocalizedText("update_lead_status", "project")}
                </Header>
                <SubHeader>
                  {getLocalizedText("select_from_below_dropdown")}
                </SubHeader>
                <Divider style={{ margin: "16px 0px" }} />
                <Form onSubmit={handleUpdate}>
                  <Box
                    style={{
                      height: "100%",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item container spacing={2}>
                        {statusList && (
                          <Grid item xs={6}>
                            <InputWrapper>
                              <Label>{getLocalizedText("status")}*</Label>
                              <FormControl
                                sx={{ width: "100%" }}
                                classes={{
                                  root: classes.quantityRoot,
                                }}
                              >
                                <Select
                                  classes={{
                                    icon: classes.icon,
                                  }}
                                  name="status"
                                  value={user.status}
                                  displayEmpty
                                  disableUnderline
                                  variant="outlined"
                                  required
                                  onChange={(e) => {
                                    setShowAlert(false);
                                    setUpdateFieldList([]);
                                    statusList.forEach((item) => {
                                      if (e.target.value === item.status) {
                                        let newList = item.sub_status;
                                        setSubStatusList(newList);
                                        setUpdateFieldInputData({});
                                        initUpdateFieldList(e.target.value);

                                        if (newList.length > 0) {
                                          if (newList.includes("None")) {
                                            setUser({
                                              ...user,
                                              status: e.target.value,
                                              sub_status: "None",
                                            });
                                          } else {
                                            setUser({
                                              ...user,
                                              status: e.target.value,
                                              sub_status: newList[0],
                                            });
                                          }
                                        } else {
                                          setUser({
                                            ...user,
                                            status: e.target.value,
                                            sub_status: "",
                                          });
                                        }
                                      }
                                    });
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "30px",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    borderRadius: "0px",
                                  }}
                                >
                                  {statusList.map((e) => {
                                    if (e["is_not_set"] === false) {
                                      return (
                                        <MenuItem id={e.id} value={e.status}>
                                          {e.status}
                                        </MenuItem>
                                      );
                                    }
                                  })}
                                </Select>
                              </FormControl>
                            </InputWrapper>
                          </Grid>
                        )}
                        {statusList && (
                          <Grid item xs={6}>
                            <InputWrapper>
                              <Label>{getLocalizedText("sub_status")}*</Label>
                              <FormControl
                                sx={{ width: "100%" }}
                                classes={{
                                  root: classes.quantityRoot,
                                }}
                              >
                                <Select
                                  classes={{
                                    icon: classes.icon,
                                  }}
                                  name="sub_status"
                                  displayEmpty
                                  variant="outlined"
                                  required
                                  value={user.sub_status}
                                  onChange={handleChange}
                                  style={{
                                    width: "100%",
                                    height: "30px",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    borderRadius: "0px",
                                  }}
                                >
                                  {subStatusList.length > 0 &&
                                    subStatusList.map((e) => {
                                      return (
                                        <MenuItem id={e} value={e}>
                                          {e}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </InputWrapper>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <InputWrapper>
                            <Label>Comments</Label>
                            <OutlinedInput
                              multiline={true}
                              rows={3}
                              inputProps={{
                                min: 0,
                                "aria-label": "weight",
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              value={user.comments}
                              name={"comments"}
                              type={"text"}
                              placeholder={"Enter Value"}
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                      </Grid>
                      {updateFieldList.length > 0 && (
                        <UpdateFieldsWidget
                          value={updateFieldInputData}
                          setValue={setUpdateFieldInputData}
                          fieldList={updateFieldList}
                        />
                      )}
                    </Grid>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                      <ButtonWrapper>
                        {showAlert && (
                          <ErrorAlert
                            onClose={(e) => {
                              e.stopPropagation();
                              setShowAlert(false);
                            }}
                            severity="error"
                            sx={{ width: "100%" }}
                          >
                            {errorMessage}
                          </ErrorAlert>
                        )}
                        <Button
                          onClick={() => {
                            analytics.triggerEvent(
                              4625000058,
                              "cancel_button_tap",
                              "update_status_form",
                              "cancel_button",
                              {}
                            );
                            props.setOpen(false);
                          }}
                          variant="contained"
                          color="default"
                          style={{
                            fontSize: "12px",
                            marginRight: "10px",
                            textTransform: "none",
                          }}
                        >
                          {getLocalizedText("cancel")}
                        </Button>
                        <Button
                          onClick={() => {
                            analytics.triggerEvent(
                              4625000059,
                              "update_button_tap",
                              "update_status_form",
                              "update_button",
                              {}
                            );
                          }}
                          type="submit"
                          variant="contained"
                          style={{
                            fontWeight: "bold",
                            width: "fit-content",
                            textTransform: "none",
                            ...myTheme.Button.btnBlue,
                          }}
                        >
                          {btnText}
                        </Button>
                      </ButtonWrapper>
                    </Grid>
                  </Grid>
                </Form>
              </Container>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px" }}>
              <Message>Status updated successfully!</Message>

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default UpdateStatusModal;
