import { Box, Modal } from "@material-ui/core";
import styled from "styled-components";
import { Close, FullscreenExit } from "@material-ui/icons";
import { useEffect } from "react";
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 20px;
`;
const ModelWrapper = styled.div`
  width: 90vw;
  margin: auto;
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.p`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const FullScreenModal = ({
  myComponent: MyComponent,
  open: open,
  setOpen: setOpen,
  setShowTop: setShowTop,
  title: title,
}) => {
  const handleClose = () => {
    setShowTop(true);
    setOpen(false);
  };

  return (
    <>
      {
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModelContainer>
            <Box>
              <Top>
                <Text>{title}</Text>
                <FullscreenExit
                  onClick={handleClose}
                  style={{
                    height: "28px",
                    width: "28px",
                    cursor: "pointer",
                  }}
                />
              </Top>
              <ModelWrapper>
                <MyComponent />
              </ModelWrapper>
            </Box>
          </ModelContainer>
        </Modal>
      }
    </>
  );
};

export default FullScreenModal;
