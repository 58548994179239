import React, { useMemo } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";

import { styled } from "@mui/material/styles";

import { observer } from "mobx-react-lite";

import { ChevronRight, Delete, Edit } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { useEffect } from "react";
import {
  Backdrop,
  Box,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CircularProgress,
  Drawer,
  FormControl,
  Select,
  makeStyles,
} from "@material-ui/core";
import { smartViewRoleList } from "../../Config";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  deleteProjectAdministrationViewApi,
  getProjectAdministrationReOrederedViewsApi,
  getProjectAdministrationViewsApi,
} from "../../Api";
import { ProjectAdminSmartViewColumnHeaders } from "../../Db";
import CustomErrorMessageDialog from "../../components/alert_dialogue/CustomErrorMessageDialog";
import EditTabSidepanel from "../../components/view_groups_sidepanels/EditTabSidepanel";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  height: 100%;
  margin: auto;
  margin-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0px;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;

const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const InputWrapper = styled(Box)`
  display: flex;
  width: fit-content;
  align-items: center;
  column-gap: 10px;
`;
const FilterLabel = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #979799;
  white-space: nowrap;
`;
const Bottom = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 226px);`};
  background-color: white;
  overflow-x: auto;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const AdminProjectSetting = () => {
  const { authStore } = rootStore;
  const project_id = authStore.projectId;
  const [selectedRole, setSelectedRole] = useState("manager");
  const classes = useStyles();
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [openEditTabSidepanel, setOpenEditTabSidepanel] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getViewList();
  }, [selectedRole]);

  useEffect(() => {
    if (refresh) {
      getViewList();
      setRefresh(false);
    }
  }, [refresh]);
  const getViewList = async () => {
    setRow([]);
    setLoading(true);
    let response = await getProjectAdministrationViewsApi({
      projectId: project_id,
      projectRole: selectedRole,
    });
    setRow(response);
    setLoading(false);
  };

  const getReOrderedViewList = async ({ role, tabSequences }) => {
    setRow([]);
    setLoading(true);
    let payload = { role: role, tab_sequences: tabSequences };
    let response = await getProjectAdministrationReOrederedViewsApi({
      projectId: project_id,
      payload: payload,
    });
    setRow(response);
    setLoading(false);
  };

  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  useEffect(() => {
    authStore.updateLeadDetailStatus();
  }, []);

  const onRowDragEnd = async (event) => {
    const gridApi = event.api;
    let draggedItem = event.node.data;
    let newOrderList = [];
    gridApi.forEachNode((node, i) => {
      let tempObj = { tab_id: node.data.tab.id, sequence: i };
      newOrderList.push(tempObj);
    });

    //*********below getting the prev index and new index to check if there is any order change happened or not */

    let prevIndex = row.findIndex(
      (data) => data["tab"]["id"] === draggedItem["tab"]["id"]
    );
    let newIndex = newOrderList.findIndex(
      (data) => data["tab_id"] === draggedItem["tab"]["id"]
    );

    if (prevIndex !== newIndex) {
      await getReOrderedViewList({
        role: selectedRole,
        tabSequences: newOrderList,
      });
    }
  };

  const handleDelete = async (id) => {
    setLoadingBackdrop(true);
    let payload = { role: selectedRole };
    let response = await deleteProjectAdministrationViewApi({
      projectId: project_id,
      itemId: id,
      payload,
    });
    if (response.hasError()) {
      setLoadingBackdrop(false);

      setErrorMessage(response.errorMessage);
      setOpenErrorDialog(true);
    } else {
      setLoadingBackdrop(false);
      await getViewList();
    }
  };

  const actionColumn = [
    {
      field: "actions",
      headerName: "Actions",
      resizable: false,
      minWidth: 130,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title={"Delete"}>
              <IconButton
                color="error"
                onClick={() => {
                  handleDelete(params.data.id);
                }}
              >
                <Delete
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Edit"}>
              <IconButton
                color="primary"
                onClick={() => {
                  setSelectedRowData(params.data);
                  setOpenEditTabSidepanel(true);
                }}
              >
                <Edit
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Text>Project Administration</Text>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Text>View Tabs</Text>
            </TopWrapper>
            <InputWrapper>
              <FilterLabel>Role :</FilterLabel>
              <FormControl
                sx={{ width: "100%" }}
                classes={{
                  root: classes.quantityRoot,
                }}
              >
                <Select
                  classes={{
                    icon: classes.icon,
                  }}
                  name="role"
                  displayEmpty
                  variant="outlined"
                  value={selectedRole}
                  onChange={(e) => {
                    setSelectedRole(e.target.value);
                  }}
                  style={{
                    width: "120px",
                    height: "30px",
                    fontSize: "14px",
                    borderRadius: "0px",
                  }}
                >
                  {smartViewRoleList.map((e, i) => {
                    return (
                      <MenuItem
                        style={{ fontSize: "14px" }}
                        key={i}
                        id={e}
                        value={e.value}
                      >
                        {e.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </InputWrapper>
          </Row>

          <Bottom>
            {!loading ? (
              <TableWrapper>
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    onRowDragEnd={onRowDragEnd}
                    rowDragManaged={true}
                    domLayout="autoHeight"
                    suppressCellFocus
                    gridOptions={gridOptions}
                    loadingOverlayComponent={<CircularProgress />}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={ProjectAdminSmartViewColumnHeaders.concat(
                      actionColumn
                    )}
                    animateRows={true}
                  />
                </div>
              </TableWrapper>
            ) : (
              <TableWrapper
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </TableWrapper>
            )}
          </Bottom>
        </Container>
      </ParentContainer>
      <Footer />
      <Backdrop
        open={loadingBackdrop}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
      {openErrorDialog && (
        <CustomErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
          message={errorMessage}
        />
      )}
      {openEditTabSidepanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openEditTabSidepanel}
          onClose={() => {
            setOpenEditTabSidepanel(false);
          }}
        >
          <EditTabSidepanel
            setOpen={setOpenEditTabSidepanel}
            open={openEditTabSidepanel}
            details={selectedRowData}
            setRefresh={setRefresh}
          />
        </Drawer>
      )}
    </>
  );
};

export default observer(AdminProjectSetting);
