import { Box, CircularProgress, Modal } from "@material-ui/core";
import styled from "styled-components";
import { FullscreenExit } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import TableComponent from "../chart_components/dashboard_chart_v2/tabular_widget/TableComponent";
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 20px;
`;
const ModelWrapper = styled.div`
  width: 90vw;
  margin: auto;
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.p`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const TableWidgetFullScreenModal = ({
  open: open,
  setOpen: setOpen,
  setShowTop: setShowTop,
  title: title,
  columns: columns,
  row: row,
}) => {
  const [loading, setLoading] = useState(true);
  const [tableColumns, setTableColumns] = useState([]);
  const handleClose = () => {
    setShowTop(true);
    setOpen(false);
  };

  //below function is to calculate the grid table width in full screen mode

  const calculateWidthInPixels = () => {
    const viewportWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    const widthInPixels = (90 * viewportWidth) / 100;
    return widthInPixels;
  };

  //in below function redefining the columns if there are empty space at last, else using default columns defination
  const generateColumns = (tableWidth) => {
    let total_columns_width = 0;
    columns.forEach((column) => {
      total_columns_width += column["width"];
    });
    if (tableWidth > total_columns_width) {
      let newList = [];
      columns.forEach((column) => {
        let newObj = {
          field: column["headerName"],
          headerName: column["headerName"],
          flex: 1,
          cellRenderer: (params) => {
            let data = null;
            if (
              params.data[column["field"]] !== null &&
              params.data[column["field"]] !== "" &&
              params.data[column["field"]] !== undefined
            ) {
              if (typeof params.data[column["field"]] === "boolean") {
                if (params.data[column["field"]] === true) {
                  data = "True";
                } else {
                  data = "False";
                }
              } else {
                data = params.data[column["field"]];
              }
            }
            return (
              <span className="csutable-cell-trucate">
                {data !== null ? data : "-"}
              </span>
            );
          },
        };
        newList.push(newObj);
      });
      return newList;
    }
    return columns;
  };

  useEffect(() => {
    let width = calculateWidthInPixels();
    let columns = generateColumns(width);
    setTableColumns(columns);
    setLoading(false);
  }, []);

  return (
    <>
      {
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModelContainer>
            <Box>
              <Top>
                <Text>{title}</Text>
                <FullscreenExit
                  onClick={handleClose}
                  style={{
                    height: "28px",
                    width: "28px",
                    cursor: "pointer",
                  }}
                />
              </Top>
              <ModelWrapper>
                {!loading ? (
                  tableColumns.length > 0 ? (
                    <Wrapper>
                      <TableComponent row={row} columns={tableColumns} />
                    </Wrapper>
                  ) : (
                    <Wrapper>
                      <Typography>No data found!</Typography>
                    </Wrapper>
                  )
                ) : (
                  <Wrapper>
                    <CircularProgress />
                  </Wrapper>
                )}
              </ModelWrapper>
            </Box>
          </ModelContainer>
        </Modal>
      }
    </>
  );
};

export default TableWidgetFullScreenModal;
