import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";

import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProfileTab from "../../components/user_tabs/ProfileTab";
import UserActivityTab from "../../components/user_tabs/UserActivityTab";
import UserCallDetails from "../../components/user_tabs/UserCallDetails";
import {
  Box,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";
import whatsapp_icon from "../../assets/campaign_icons/whatsapp.png";
import edit_icon from "../../assets/campaign_icons/edit.png";
import update_icon from "../../assets/campaign_icons/update.png";
import update_source_icon from "../../assets/campaign_icons/source.png";
import delete_icon from "../../assets/campaign_icons/delete.png";
import copy_icon from "../../assets/campaign_icons/copy.png";
import mark_icon from "../../assets/campaign_icons/mark.png";
import unmark_icon from "../../assets/campaign_icons/unmark.png";
import Update_stage_icon from "../../assets/campaign_icons/stage.png";
import submit_ops_icon from "../../assets/campaign_icons/submit_ops.png";
import unlocked_icon from "../../assets/campaign_icons/unlocked.png";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  ArrowLeft,
  AssignmentOutlined,
  Call,
  ChevronRight,
  Link,
  Refresh,
  WhatsApp,
} from "@material-ui/icons";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Backdrop, Menu, MenuItem } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab, { tabClasses } from "@mui/material/Tab";
import UserFileTab from "../../components/user_tabs/UserFileTab";
import {
  createContactApi,
  deleteLeadApi,
  getColumnGroupsApi,
  getLeadProfileApi,
  getPhoneApi,
  linkContactApi,
  markUnmarkLeadAsFreshLeadApi,
  searchContactByPhoneApi,
} from "../../Api";
import LeadAssignModal from "../../components/lead_assign_modal/LeadAssignModal";
import UpdateStatusModal from "../../components/update_status_modal/UpdateStatusModal";
import {
  checkFeaturePermission,
  consoleLogger,
  getLocalizedText,
  validateDisabledFeature,
  validateDisabledSubFeature,
} from "../../Functions";
import UserCheckInTab from "../../components/user_tabs/UserCheckInTab";
import UpdateSourceModal from "../../components/update_source_modal/UpdateSourceModal";
import NotesTab from "../../components/user_tabs/NotesTab";
import CallConfirmationDailog from "../../components/call/CallConfirmationDailog";
import CallInfoDialog from "../../components/call/CallInfoDialog";
import ErrorMessageDialog from "../../components/call/ErrorMessageDialog";
import { callInfoSheetScope } from "../../utils/enum";
import CallLogModal from "../../components/call_log_modal/CallLogModal";
import { PersonAddAlt } from "@mui/icons-material";
import MoneyViewCreateLead from "../../components/money_view_components/MoneyViewCreateLead";
import CustomDetailsTab from "../../components/user_tabs/CustomDetailsTab";
import RefreshOffersDialog from "../../components/money_view_components/RefreshOffersDialog";
import RefreshJourneyUrlDialog from "../../components/money_view_components/RefreshJourneyUrlDialog";
import StageStepperComponent from "../../components/stage_stepper_component/StageStepperComponent";
import analytics from "../../Analytics";
import AdvanceCopySidepanel from "../../components/advance_copy_sidepanel/AdvanceCopySidepanel";
import DealsTab from "../../components/user_tabs/DealsTab";
import CustomErrorMessageDialog from "../../components/alert_dialogue/CustomErrorMessageDialog";
import StageStepperComponentv2 from "../../components/stage_stepper_component/StageStepperComponentv2";
import UpdateStageModal from "../../components/update_stage_modal/UpdateStageModal";
import SubmitLeadModal from "../../components/submit_lead_modal/SubmitLeadModal";
import ConfirmLeadUnlockDialog from "../../components/alert_dialogue/ConfirmLeadUnlockDialog";
import DeleteIndividualLeadConfirmationDialog from "../../components/alert_dialogue/DeleteIndividualLeadConfirmationDialog";
import MessageWithLoadingStateCustomDialog from "../../components/alert_dialogue/MessageWithLoadingStateCustomDialog";

const ParentContainer = styled.div`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled.div`
  ${`min-height: calc(100vh - 253px);`};
  margin: auto;
  margin-top: 70px;
  width: 95%;
`;
const Wrapper = styled.div`
  display: flex;
  position: relative;
  background-color: white;
  padding: 20px 20px 0px 20px;
  border: 2px solid #f9f9fc;
`;
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0px;
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const Icon = styled.img`
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin-right: 8px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;
const NoAccessText = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const RightArrowedContainer = styled.div`
  width: fit-content;
  height: fit-content;
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
`;
const ITEM_HEIGHT = 48;

const UserDetail = (props) => {
  const { filterStore, phoneCallState, authStore, userStore } = rootStore;
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  let { lid, uid } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openSourceModal, setOpenSourceModal] = useState(false);
  const [openUpdateStageModal, setOpenUpdateStageModal] = useState(false);
  const [openSubmitLeadModal, setOpenSubmitLeadModal] = useState(false);
  const stageStatusList = [...rootStore.authStore.stageStatusList];
  const [leadData, setLeadData] = useState({});
  const [openCallInfo, setOpenCallInfo] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openErrorDialog, setOpenErrorDailog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [callInfoScope, setCallInfoScope] = useState(null);
  const [openCallLogModal, setOpenCallLogModal] = useState(false);
  const statusList = rootStore.authStore.statusList;
  const stagesList = rootStore.authStore.stageList;
  const [openCreateLeadModal, setOpenCreateLeadModal] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [tabsToGroups, setTabsToGroups] = useState({});
  const [groupsToColumns, setGroupsToColumns] = useState({});
  const [detailsTabs, setDetailsTabs] = useState([]);
  const [refreshLeadDetails, setRefreshLeadDetails] = useState(false);
  const [loading, setLoading] = useState(true);
  const columnsList = userStore.AllColumnsList;
  const columnGroups = userStore.columnGroups;
  const [openRefreshOfferDialog, setOpenRefreshOfferDailog] = useState(false);
  const [openRefreshJourneyDialog, setOpenRefreshJourneyDailog] =
    useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [markLeadErrorMessage, setMarkLeadErrorMessage] = useState("");
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [accessLevel, setAccessLevel] = useState(""); //access level for lead details access permission
  const [sortedStageList, setSortedStageList] = useState([]);
  const [openAdvancedCopyPanel, setOpenAdvancedCopyPanel] = useState(false);
  const [sortedColumnIdList, setSortedColumnIdList] = useState({}); //this state is maintained for advance copy side panel
  // const location = useLocation();
  // const receivedProps = location.state; // default is null else if state props is passed through useNavigate then it has the value
  const [_leadLockedOverriden, setLeadLockedOverriden] = useState(false);
  const [openUnlockLeadConfirmDialog, setOpenUnlockLeadConfirmDialog] =
    useState(false);

  /// state variables related to delete lead
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showResponseDialog, setShowResponseDialog] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  ///  END
  //********Functions to check lead details access permissions**************** */
  const canReadLead = () => {
    let account_role =
      userStore.UserData !== null ? userStore.UserData["role"] : "";
    if (account_role === "owner") {
      return true;
    }
    return leadData["access_read"].includes(accessLevel);
  };
  const canEditLead = () => {
    if (isLeadLocked()) {
      return false;
    } else {
      let account_role =
        userStore.UserData !== null ? userStore.UserData["role"] : "";
      if (account_role === "owner") {
        return true;
      }
      return leadData["access_edit"].includes(accessLevel);
    }
  };
  const canDestroyLead = () => {
    let account_role =
      userStore.UserData !== null ? userStore.UserData["role"] : "";
    if (account_role === "owner") {
      return true;
    }
    return leadData["access_destroy"].includes(accessLevel);
  };

  //*************************END*********************** */

  const isEmailVerified = () => {
    return leadData["email_verified"];
  };

  const handleMenuClick = (event, level) => {
    setAnchorEl({
      ...anchorEl,
      [level]: event.currentTarget,
    });
  };

  const handleClose = (level) => {
    setAnchorEl({
      ...anchorEl,
      [level]: null,
    });
  };

  const setupTabToGroups = (tabs) => {
    const result = {};

    // Create a dictionary of tabs with an empty list for each tab
    tabs.forEach((tab) => {
      result[tab] = [];
    });

    // Loop through the data and add each group name and group_order value to the appropriate tab's list
    columnGroups.forEach((item) => {
      if (result[item.tab_name]) {
        result[item.tab_name].push({
          name: item.group_name,
          order: item.group_order,
        });
      }
    });

    // Sort the groups within each tab's list by their group_order property
    Object.keys(result).forEach((tab) => {
      result[tab].sort((a, b) => a.order - b.order);
      result[tab] = result[tab].map((item) => item.name);
    });

    return result;
  };

  const setupGroupsToColumns = () => {
    // Get a list of all unique group names from the tabs
    const uniqueGroups = Array.from(
      new Set(columnGroups.map((item) => item.group_name))
    );

    const result = { "Other Information": [] };

    uniqueGroups.forEach((group) => {
      if (!result[group]) {
        result[group] = [];
      }
    });

    // Loop through the data and add each ID to the appropriate group's list
    columnsList.forEach((item) => {
      let group = item.group;
      if (item["is_visible"]) {
        if (group === null || group === "") {
          result["Other Information"].push(item["id"]);
        } else {
          var groups = group.split(",").map((group) => {
            return group.trim();
          });
          groups.forEach((group) => {
            if (!uniqueGroups.includes(group)) {
              result["Other Information"].push(item["id"]);
            } else {
              result[group].push(item["id"]);
            }
          });
        }
      }
    });

    return result;
  };

  const setupTabs = () => {
    let result = [];
    if (columnGroups.length > 0) {
      columnGroups.forEach((item) => {
        const { tab_name } = item;
        if (tab_name !== "Basic Info") {
          if (!result.includes(tab_name)) {
            result.push(tab_name);
          }
        }
      });

      result.sort();

      let index = columnGroups.findIndex(
        (item) => item["tab_name"] === "Basic Info"
      );
      if (index !== -1) {
        result.unshift("Basic Info");
      }
    }
    return result;
  };

  const setup = () => {
    let tempDetailsTabs = setupTabs();
    setDetailsTabs(tempDetailsTabs);
    let tempTabsToGroups = setupTabToGroups(tempDetailsTabs);
    setTabsToGroups(tempTabsToGroups);
    let tempGroupsToColumns = setupGroupsToColumns();
    setGroupsToColumns(tempGroupsToColumns);

    let allTabs = [...tempDetailsTabs];

    if (canShowActivitiesTab()) {
      allTabs.push("Activities");
    }
    allTabs.push("Deal");
    allTabs.push("Call Logs");
    allTabs.push("Files");
    if (validateDisabledFeature("checkins")) {
      allTabs.push("Check-Ins");
    }
    if (validateDisabledSubFeature("leaddetails_notes_tab")) {
      allTabs.push("Notes");
    }
    filterStore.updateDetailTabValue(allTabs[0]);
    setTabs(allTabs);
    consoleLogger(
      "setup done:::",
      allTabs,
      tempGroupsToColumns,
      tempTabsToGroups
    );
  };

  const getLeadDetailsApi = async () => {
    setLoading(true);
    try {
      let response = await getLeadProfileApi(lid);
      setAccessLevel(response.data.access_level);
      let updatedLeadData = await checkAndCreateContact(response.data.lead);
      setLeadData(updatedLeadData);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLeadDetailsApi();
  }, [refresh, lid]);

  useEffect(() => {
    analytics.triggerEvent(
      4625000036,
      "lead_details_page_load",
      "lead_details_page",
      "",
      {}
    );
    window.scrollTo(0, 0); //this will always load this page component scrolled to top
    setup();
    rootStore.authStore.resetLeadCallDetails();
  }, []);

  //-----------below useEffect is specific to update the lead detail status
  useEffect(() => {
    authStore.updateLeadDetailStatus(lid); //this function is to update the current status of lead detail in store
  }, [lid]);

  useEffect(() => {
    if (refreshLeadDetails === true) {
      fetchLeadDetails();
    }
  }, [refreshLeadDetails]);

  // useEffect(() => {
  //   console.log("received props:::::", receivedProps);
  //   if (receivedProps !== null) {
  //     if (receivedProps["scope"] === "call_info_dialog_submit_log") {
  //       setOpenCallLogModal(true);
  //     }
  //   }
  // }, []);

  const fetchLeadDetails = async () => {
    let response = await getLeadProfileApi(lid);
    setAccessLevel(response.data.access_level);
    setLeadData(response.data.lead);
    setRefreshLeadDetails(false);
  };

  const isStatusLocked = () => {
    let index = statusList.findIndex(
      (element) => element["status"] === leadData.lead_status
    );
    if (index !== -1) {
      return statusList[index]["lock"];
    } else {
      return false;
    }
  };

  const hasMoneyViewLeadID = () => {
    let customData = JSON.parse(leadData["custom_params"]);
    if (customData.hasOwnProperty("moneyview_lead_id")) {
      if (
        customData["moneyview_lead_id"] !== null &&
        customData["moneyview_lead_id"] !== ""
      ) {
        return true;
      }
    }
    return false;
  };

  const handleBreadCrump = () => {
    navigate(-1);
  };
  const getLeadPhone = async () => {
    let response = await getPhoneApi(lid);
    let phone = response.data;
    if (phone.length === 10) {
      phone = "91" + phone;
    } else if (phone.length > 10) {
      if (phone.includes("+91-")) {
        let temp = phone.replace("+91-", "");
        temp = "91" + temp;
        phone = temp;
      } else if (phone.includes("91-")) {
        let temp = phone.replace("91-", "");
        temp = "91" + temp;
        phone = temp;
      }
    }
    window.open(`https://wa.me/${phone}`, "_blank");
  };

  const handlePhoneCall = async () => {
    let dialer = authStore.currentProject["external_dialer"];

    switch (dialer) {
      case "ozonetel":
      case "mcube":
      case "teckinfo":
        let response = await phoneCallState.initiateCall(lid);
        setOpenConfirmation(false);
        if (response["status"] === "success") {
          setOpenCallInfo(true);
          setCallInfoScope(callInfoSheetScope.lead_details);
        } else {
          setErrorMessage(response.message);
          setOpenErrorDailog(true);
        }
        break;
      default:
        setErrorMessage(
          `${dialer} is not supported. Please contact the adminstrator.`
        );
        setOpenErrorDailog(true);
        break;
    }
  };

  const canShowCallIcon = () => {
    if (
      authStore.currentProject["external_dialer"] === "default" ||
      authStore.getCallingMode().toLowerCase() === "progressive"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isCallDisabled = () => {
    let userID = localStorage.getItem("uid");
    if (canEditLead()) {
      if (leadData["lead_owner"]["id"] === userID) {
        return false;
      }
    }
    return true;
  };

  //**************functions to check whether to disable or enable actions in 3 dot menu *******/
  const isDeleteLeadDisabled = () => {
    if (checkFeaturePermission("lead_details_delete")) {
      if (canDestroyLead()) {
        return false;
      }
    }
    return true;
  };

  const isUpdateStatusDisabled = () => {
    if (!isStatusLocked()) {
      if (canEditLead()) {
        return false;
      }
    }
    return true;
  };

  const isAssignLeadIconVisible = () => {
    if (checkFeaturePermission("assign_leads")) {
      if (canEditLead()) {
        return true;
      }
    }
    return false;
  };

  const isMoneyViewCreateLeadDisabled = () => {
    if (hasMoneyViewLeadID()) {
      return true;
    } else {
      if (canEditLead()) {
        return false;
      } else {
        return true;
      }
    }
  };

  const isMoneyViewRefreshOffersDisabled = () => {
    if (hasMoneyViewLeadID()) {
      if (canEditLead()) {
        return false;
      }
    }
    return true;
  };

  const isMoneyViewRefreshJourneyURLDisabled = () => {
    if (hasMoneyViewLeadID()) {
      if (canEditLead()) {
        return false;
      }
    }
    return true;
  };
  const getCallStatusLeadID = () => {
    /*
    this func is being used, because on submit call log, we are resetting call status
    So if we directly pass callstatus lead id, it will result in white screen
    */
    if (phoneCallState.callStatus.hasOwnProperty("lead")) {
      return phoneCallState.callStatus["lead"]["id"];
    } else {
      return "";
    }
  };

  /*********************END****************** */

  const handleAdvanceCopyButtonTap = () => {
    let columnsIDsByGroup = {};
    Object.keys(groupsToColumns).forEach((key) => {
      let columnsIds = [...groupsToColumns[key]];
      columnsIds.sort((id1, id2) => {
        const id1index = columnsList.findIndex((element) => element.id === id1);
        const id2index = columnsList.findIndex((element) => element.id === id2);
        const headerName1 = columnsList[id1index].headerName;
        const headerName2 = columnsList[id2index].headerName;
        return headerName1.localeCompare(headerName2);
      });
      columnsIDsByGroup[key] = columnsIds;
    });
    setSortedColumnIdList(columnsIDsByGroup);
    setOpenAdvancedCopyPanel(true);
  };

  //***********************************Mark unmark related functions */

  const handleMarkUnMarkLeadAsFreshLead = async (scope) => {
    setShowBackDrop(true);
    let payload = { status: scope === "mark" ? true : false };
    let response = await markUnmarkLeadAsFreshLeadApi({
      leadID: lid,
      payload: payload,
    });
    if (response.hasError()) {
      setShowBackDrop(false);
      setMarkLeadErrorMessage(response.errorMessage);
      setShowErrorDialog(true);
    } else {
      setShowBackDrop(false);
      setShowErrorDialog(false);
      setRefreshLeadDetails(true);
    }
  };

  const isMarkUnMarkFreshLeadMenuVisible = () => {
    if (checkFeaturePermission("fresh_lead")) {
      if (canEditLead()) {
        return true;
      }
    }
    return false;
  };

  //***************************END******************* */

  const RenderMarkUnMarkFreshLeadMenu = () => {
    if (leadData["fresh_lead"] === true) {
      return (
        <MenuItem
          onClick={() => {
            handleClose(0);
            handleMarkUnMarkLeadAsFreshLead("unmark");
          }}
        >
          <Icon src={unmark_icon} alt="loading.." />
          Unmark Fresh Lead
        </MenuItem>
      );
    } else {
      return (
        <MenuItem
          onClick={() => {
            handleClose(0);
            handleMarkUnMarkLeadAsFreshLead("mark");
          }}
        >
          <Icon src={mark_icon} alt="loading.." />
          Mark as Fresh Lead
        </MenuItem>
      );
    }
  };

  //***********************Create contact function if contact is not created ***************************** */
  const checkAndCreateContact = async (details) => {
    if (details["contact_id"] == null || details["contact_id"] == "") {
      let customData = details.hasOwnProperty("custom_params")
        ? JSON.parse(details["custom_params"])
        : {};
      let contactData = {};
      let contactColumns = [...rootStore.contactStore.columnsList];

      contactColumns = contactColumns.filter(
        (columnData) =>
          columnData["show_in_create_form"] &&
          columnData["is_visible"] &&
          columnData["required"]
      );

      let leadsColumns = [...columnsList];

      for (let i = 0; i < contactColumns.length; i++) {
        let column = contactColumns[i];
        let index = leadsColumns.findIndex(
          (element) => element["contact_column"] === column["column_name"]
        );

        if (index !== -1) {
          let leadField = leadsColumns[index]["field"];

          if (leadsColumns[index]["is_custom_param"]) {
            let newField = leadField.replace("custom_params_", "");

            if (customData.hasOwnProperty(newField)) {
              contactData[column["column_name"]] = customData[newField];
            }
          } else {
            if (details.hasOwnProperty(leadField)) {
              if (leadField === "phone") {
                let response = await getPhoneApi(lid);
                contactData[column["column_name"]] = response.data;
              } else {
                contactData[column["column_name"]] = details[leadField];
              }
            }
          }
        } else {
          contactData[column["column_name"]] = null;
        }
      }
      let response = await createContactApi({ payload: contactData });
      if (response.hasError()) {
        if (
          response.data.hasOwnProperty("code") &&
          response.data["code"] === "1029"
        ) {
          if (contactData.hasOwnProperty("phone")) {
            let search_response = await searchContactByPhoneApi({
              payload: {
                phone: contactData["phone"],
              },
            });
            if (search_response.length > 0) {
              let searchedContactData = search_response[0];
              let link_contact_response = await linkContactApi({
                projectID: rootStore.authStore.projectId,
                teamID:
                  rootStore.authStore.teamId !== null
                    ? rootStore.authStore.teamId !== "All"
                      ? rootStore.authStore.teamId
                      : ""
                    : null,
                leadID: lid,
                payload: {
                  contact_id: searchedContactData["id"],
                },
              });
              details["contact_id"] = searchedContactData["id"];
            }
          }
        }
      } else {
        if (response.hasOwnProperty("id")) {
          let link_contact_response = await linkContactApi({
            projectID: rootStore.authStore.projectId,
            teamID:
              rootStore.authStore.teamId !== null
                ? rootStore.authStore.teamId !== "All"
                  ? rootStore.authStore.teamId
                  : ""
                : null,
            leadID: lid,
            payload: {
              contact_id: response["id"],
            },
          });
          details["contact_id"] = response["id"];
        }
      }
    }

    return details;
  };
  //**************************************END************************************************************** */

  // sorting stage list getter function for stageStepper///////////////////
  const getSortedStageListByRank = () => {
    let stageList = [...rootStore.authStore.stageList];
    const currentLeadStage = leadData["lead_stage"];

    // Sort the list based on the "rank" property in ascending order
    stageList.sort((a, b) => a.rank - b.rank);

    // Find the index of the current lead_stage object
    const currentIndex = stageList.findIndex(
      (item) => item.stage === currentLeadStage
    );

    // If the current lead_stage object is found, remove it from the list
    if (currentIndex !== -1) {
      const currentLeadStageObject = stageList.splice(currentIndex, 1)[0];

      // Iterate through the sorted list and find the index where the current lead_stage object should be inserted
      const insertIndex = stageList.findIndex(
        (item) => item.rank > currentLeadStageObject.rank
      );

      // If no such index is found, add the current lead_stage object to the end of the list
      if (insertIndex === -1) {
        stageList.push(currentLeadStageObject);
      } else {
        // Otherwise, insert the current lead_stage object at the appropriate position
        stageList.splice(insertIndex, 0, currentLeadStageObject);
      }
    }
    return stageList;
  };
  //***************************END********************** */

  const canShowUpdateStatus = () => {
    if (validateDisabledSubFeature("update_status") === false) {
      return false;
    } else {
      let projectRole = authStore.getCurrentProjectRole();
      if (projectRole.toLowerCase() === "member") {
        if (validateDisabledSubFeature("update_status_members") === false) {
          return false;
        }
      }
      return true;
    }
  };

  const canShowActivitiesTab = () => {
    if (validateDisabledFeature("activities") === false) {
      return false;
    } else {
      let projectRole = authStore.getCurrentProjectRole();
      if (projectRole.toLowerCase() === "member") {
        if (validateDisabledFeature("activities_members") === false) {
          return false;
        }
      } else if (projectRole.toLowerCase() === "lead") {
        if (validateDisabledFeature("activities_leads") === false) {
          return false;
        }
      } else if (projectRole.toLowerCase() === "manager") {
        if (validateDisabledFeature("activities_managers") === false) {
          return false;
        }
      }
      return true;
    }
  };

  const canShowUpdateSource = () => {
    if (validateDisabledSubFeature("update_source") === false) {
      return false;
    } else {
      let projectRole = authStore.getCurrentProjectRole();
      if (projectRole.toLowerCase() === "member") {
        if (validateDisabledSubFeature("update_source_members") === false) {
          return false;
        }
      }
      return true;
    }
  };
  const canShowUpdateStage = () => {
    if (validateDisabledSubFeature("update_stage") === false) {
      return false;
    } else {
      let projectRole = authStore.getCurrentProjectRole();
      if (projectRole.toLowerCase() === "member") {
        if (validateDisabledSubFeature("update_stage_members") === false) {
          return false;
        }
      }
      return true;
    }
  };

  const canShowSubmitLead = () => {
    let index = stageStatusList.findIndex(
      (item) =>
        item["lead_stage"]["stage"] === leadData["lead_stage"] &&
        item["lead_status"]["status"] === leadData["lead_status"]
    );
    if (index !== -1) {
      let data = stageStatusList[index];
      return data["submit_enabled"];
    }
    return false;
  };
  function isLeadLocked() {
    if (_leadLockedOverriden) {
      return false;
    } else {
      let won = leadData["won"] || false;
      let lost = leadData["lost"] || false;
      let closed = leadData["closed"] || false;
      if (won || lost || closed) {
        return true;
      } else {
        return false;
      }
    }
  }

  const canShowLeadUnlockMenu = () => {
    if (isLeadLocked()) {
      let account_role =
        userStore.UserData !== null ? userStore.UserData["role"] : "";
      if (account_role === "owner") {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {}, [_leadLockedOverriden]); //this useeffect is called to rerender the page on leadlocked state change

  //*******************functions related delete lead*********************************** */
  const isStageDeleteProtected = () => {
    let index = stagesList.findIndex(
      (stage) => stage["stage"] === leadData["lead_stage"]
    );
    if (index !== -1) {
      return stagesList[index]["delete_protected"];
    }
    return false;
  };
  const isStatusDeleteProtected = () => {
    let index = statusList.findIndex(
      (status) => status["status"] === leadData["lead_status"]
    );
    if (index !== -1) {
      return statusList[index]["delete_protected"];
    }
    return false;
  };

  const isDeleteProtected = () => {
    return isStageDeleteProtected() || isStatusDeleteProtected();
  };

  const deleteLead = async () => {
    setShowConfirmDeleteDialog(false);
    setIsDeleting(true);
    setShowResponseDialog(true);
    let response = await deleteLeadApi({ leadID: lid });
    setIsDeleting(false);
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitFail(false);
      navigate(-1);
    }
  };
  const handleDelete = async () => {
    setShowConfirmDeleteDialog(true);
  };

  //*****************************END********************************************* */

  return (
    <>
      {!loading ? (
        canReadLead() ? (
          <ParentContainer>
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <TopWrapper>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Text
                            style={{ color: "#185DD2", cursor: "pointer" }}
                            onClick={handleBreadCrump}
                          >
                            {getLocalizedText("leads", "project")}
                          </Text>
                          <ChevronRight
                            style={{ width: "16px", height: "16px" }}
                          />
                          <Text>{lid}</Text>
                        </Box>
                      </Grid>
                      {authStore.currentProject["external_dialer"] !==
                        "default" && (
                        <Grid item xs={4}>
                          <Text
                            style={{
                              textAlign: "center",
                              color: "#21589F",
                            }}
                          >{`You are in "${authStore.getCallingMode()}" calling mode.`}</Text>
                        </Grid>
                      )}
                    </Grid>
                  </TopWrapper>
                </Grid>
                <Grid item xs={12}>
                  <StageStepperComponentv2
                    leadData={leadData}
                    stageList={getSortedStageListByRank()}
                  />
                </Grid>
                <Grid
                  item
                  container
                  spacing={2}
                  justifyContent="space-between"
                  wrap="nowrap"
                >
                  <Grid item>
                    <Tabs
                      sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                          "&.Mui-disabled": { opacity: 0.3 },
                        },
                      }}
                      variant="scrollable"
                      value={filterStore.detailTabValue}
                      textColor="primary"
                      indicatorColor="primary"
                      scrollButtons={"auto"}
                      onChange={(event, newValue) => {
                        analytics.triggerEvent(
                          4625000037,
                          "lead_details_tab_tap",
                          "lead_details_page",
                          "lead_details_tab",
                          { order: newValue }
                        );
                        authStore.updateLastActiveTime();
                        filterStore.updateDetailTabValue(newValue);
                      }}
                    >
                      {tabs.length > 0 &&
                        tabs.map((tab_name) => {
                          return (
                            <Tab
                              sx={{
                                "&.Mui-selected": {
                                  backgroundColor: "#E7EEFA",
                                  color: "#4079DA",
                                  font: "normal normal 600 14px Open Sans",
                                  textTransform: "capitalize",
                                },

                                backgroundColor: "white",
                                color: "#4D4E4F",
                                font: "normal normal 600 14px Open Sans",
                                textTransform: "capitalize",
                              }}
                              value={tab_name}
                              label={tab_name}
                            />
                          );
                        })}
                    </Tabs>
                  </Grid>
                  <Grid item>
                    <ButtonWrapper>
                      {canShowCallIcon() && (
                        <IconButton
                          disabled={isCallDisabled()}
                          color="primary"
                          style={{
                            width: "30px",
                            marginRight: "10px",
                            height: "30px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            analytics.triggerEvent(
                              4625000048,
                              "call_button_tap",
                              "lead_details_page",
                              "call_button",
                              {}
                            );
                            authStore.updateLastActiveTime();
                            if (phoneCallState.hasPendingCallLog()) {
                              setOpenCallInfo(true);
                              setCallInfoScope(
                                callInfoSheetScope.lead_details_call_icon
                              );
                            } else {
                              setOpenConfirmation(true);
                            }
                          }}
                        >
                          <Call />
                        </IconButton>
                      )}
                      {isAssignLeadIconVisible() && (
                        <AssignmentOutlined
                          onClick={() => {
                            analytics.triggerEvent(
                              4625000038,
                              "assign_lead_button_tap",
                              "lead_details_page",
                              "assign_lead_button",
                              {}
                            );
                            authStore.updateLastActiveTime();
                            setOpen(true);
                          }}
                          color="primary"
                          style={{
                            width: "32px",
                            height: "32px",
                            cursor: "pointer",
                            marginRight: "10px",
                          }}
                        />
                      )}
                      <IconButton
                        disabled={!canEditLead()}
                        style={{
                          width: "42px",
                          height: "42px",
                        }}
                        onClick={() => {
                          analytics.triggerEvent(
                            4625000039,
                            "whatsapp_button_tap",
                            "lead_details_page",
                            "whatsapp_button",
                            {}
                          );
                          authStore.updateLastActiveTime();
                          getLeadPhone();
                        }}
                      >
                        <Icon
                          style={{
                            cursor: "pointer",
                            marginRight: "0px",
                            width: "42px",
                            height: "42px",
                          }}
                          src={whatsapp_icon}
                          alt="loading.."
                        />
                      </IconButton>
                      <IconButton
                        onClick={(event) => handleMenuClick(event, 0)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        style={{ display: "flex", flexDirection: "column" }}
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl[0]}
                        open={Boolean(anchorEl[0])}
                        onClose={() => handleClose(0)}
                        PaperProps={{
                          style: {
                            // maxHeight: ITEM_HEIGHT * 4.5,
                            width: "22ch",
                          },
                        }}
                      >
                        {canShowLeadUnlockMenu() && (
                          <MenuItem
                            onClick={() => {
                              authStore.updateLastActiveTime();
                              setOpenUnlockLeadConfirmDialog(true);
                              handleClose(0);
                            }}
                          >
                            <Icon src={unlocked_icon} alt="loading.." />
                            Unlock Lead
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => {
                            analytics.triggerEvent(
                              4625000040,
                              "delete_lead_button_tap",
                              "lead_details_page",
                              "delete_lead_button",
                              {}
                            );
                            authStore.updateLastActiveTime();
                            handleDelete();
                            handleClose(0);
                          }}
                          disabled={isDeleteLeadDisabled()}
                        >
                          {" "}
                          <Icon src={delete_icon} alt="loading.." />
                          Delete
                        </MenuItem>
                        {canShowUpdateStatus() && (
                          <MenuItem
                            disabled={isUpdateStatusDisabled()}
                            onClick={() => {
                              analytics.triggerEvent(
                                4625000042,
                                "update_status_button_tap",
                                "lead_details_page",
                                "update_status_button",
                                {}
                              );
                              authStore.updateLastActiveTime();
                              setOpenStatusModal(true);
                              handleClose(0);
                            }}
                          >
                            <Icon src={update_icon} alt="loading.." />
                            Update Status
                          </MenuItem>
                        )}
                        {canShowUpdateStage() && (
                          <MenuItem
                            disabled={!canEditLead()}
                            onClick={() => {
                              authStore.updateLastActiveTime();
                              setOpenUpdateStageModal(true);
                              handleClose(0);
                            }}
                          >
                            <Icon src={Update_stage_icon} alt="loading.." />
                            Update Stage
                          </MenuItem>
                        )}
                        {canShowUpdateSource() && (
                          <MenuItem
                            onClick={() => {
                              analytics.triggerEvent(
                                4625000041,
                                "update_source_button_tap",
                                "lead_details_page",
                                "update_source_button",
                                {}
                              );
                              authStore.updateLastActiveTime();
                              setOpenSourceModal(true);
                              handleClose(0);
                            }}
                            disabled={!canEditLead()}
                          >
                            <Icon src={update_source_icon} alt="loading.." />
                            Update Source
                          </MenuItem>
                        )}
                        {canShowSubmitLead() && (
                          <MenuItem
                            disabled={!canEditLead()}
                            onClick={() => {
                              authStore.updateLastActiveTime();
                              setOpenSubmitLeadModal(true);
                              handleClose(0);
                            }}
                          >
                            <Icon src={submit_ops_icon} alt="loading.." />
                            Submit Lead
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => {
                            handleAdvanceCopyButtonTap();
                            handleClose(0);
                          }}
                        >
                          <Icon src={copy_icon} alt="loading.." />
                          Advance Copy
                        </MenuItem>
                        {isMarkUnMarkFreshLeadMenuVisible() && (
                          <RenderMarkUnMarkFreshLeadMenu />
                        )}

                        {authStore.hasDigitalLender("moneyview") && (
                          <>
                            <MenuItem
                              onClick={(event) => {
                                analytics.triggerEvent(
                                  4625000043,
                                  "moneyview_button_tap",
                                  "lead_details_page",
                                  "moneyview_button",
                                  {}
                                );
                                handleMenuClick(event, 1);
                              }}
                            >
                              <ArrowLeft />
                              Money View
                            </MenuItem>
                            <Menu
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl[1]}
                              open={Boolean(anchorEl[1])}
                              onClose={() => handleClose(1)}
                              PaperProps={{}}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "left",
                                vertical: "top",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  analytics.triggerEvent(
                                    4625000044,
                                    "create_lead_button_tap",
                                    "lead_details_page",
                                    "moneyview_create_lead_button",
                                    {}
                                  );
                                  authStore.updateLastActiveTime();
                                  setOpenCreateLeadModal(true);
                                  setAnchorEl({});
                                }}
                                disabled={isMoneyViewCreateLeadDisabled()}
                              >
                                <PersonAddAlt
                                  color="primary"
                                  style={{ marginRight: "6px" }}
                                />
                                Create Lead
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  analytics.triggerEvent(
                                    4625000045,
                                    "refresh_offers_button_tap",
                                    "lead_details_page",
                                    "moneyview_refresh_offers_button",
                                    {}
                                  );
                                  authStore.updateLastActiveTime();
                                  setOpenRefreshOfferDailog(true);
                                  handleClose(1);
                                }}
                                disabled={isMoneyViewRefreshOffersDisabled()}
                              >
                                <RefreshIcon
                                  color="primary"
                                  style={{ marginRight: "6px" }}
                                />
                                Refresh Offers
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  analytics.triggerEvent(
                                    4625000046,
                                    "refresh_journey_url_button_tap",
                                    "lead_details_page",
                                    "moneyview_refresh_journey_url_button",
                                    {}
                                  );
                                  authStore.updateLastActiveTime();
                                  setOpenRefreshJourneyDailog(true);
                                  handleClose(1);
                                }}
                                disabled={isMoneyViewRefreshJourneyURLDisabled()}
                              >
                                <RefreshIcon
                                  color="primary"
                                  style={{ marginRight: "6px" }}
                                />
                                Refresh Journey URL
                              </MenuItem>
                              <MenuItem
                                disabled={!canEditLead()}
                                onClick={() => {
                                  analytics.triggerEvent(
                                    4625000047,
                                    "send_journey_url_button_tap",
                                    "lead_details_page",
                                    "moneyview_send_journey_url_button",
                                    {}
                                  );
                                }}
                              >
                                <Link
                                  color="primary"
                                  style={{ marginRight: "6px" }}
                                />
                                Send Journey URL
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </Menu>
                    </ButtonWrapper>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Wrapper>
                    {(() => {
                      if (filterStore.detailTabValue === "Activities") {
                        return <UserActivityTab uid={uid} lid={lid} />;
                      } else if (filterStore.detailTabValue === "Call Logs") {
                        return (
                          <UserCallDetails
                            lid={lid}
                            uid={uid}
                            statusList={statusList}
                            setRefreshLeadDetails={setRefreshLeadDetails}
                            isUpdateStatusDisabled={isUpdateStatusDisabled}
                            leadData={leadData}
                          />
                        );
                      } else if (filterStore.detailTabValue === "Files") {
                        return (
                          <UserFileTab
                            lid={lid}
                            uid={uid}
                            canEditLead={canEditLead}
                            leadData={leadData}
                          />
                        );
                      } else if (filterStore.detailTabValue === "Check-Ins") {
                        return <UserCheckInTab lid={lid} uid={uid} />;
                      } else if (filterStore.detailTabValue === "Notes") {
                        return (
                          <NotesTab
                            lid={lid}
                            uid={uid}
                            canEditLead={canEditLead}
                          />
                        );
                      } else if (filterStore.detailTabValue === "Deal") {
                        return (
                          <DealsTab
                            lid={lid}
                            uid={uid}
                            canEditLead={canEditLead}
                          />
                        );
                      } else if (filterStore.detailTabValue === "Basic Info") {
                        consoleLogger(
                          "tabsToGroups in tab render::::",
                          tabsToGroups
                        );
                        return (
                          <ProfileTab
                            tab_type="basic_info_tab"
                            lid={lid}
                            uid={uid}
                            setRefreshLeadDetails={setRefreshLeadDetails}
                            setLeadData={setLeadData}
                            leadData={leadData}
                            groups={tabsToGroups[filterStore.detailTabValue]}
                            groupsToColumns={groupsToColumns}
                            canEditLead={canEditLead}
                            isEmailVerified={isEmailVerified}
                          />
                        );
                      } else {
                        return (
                          <CustomDetailsTab
                            key={filterStore.detailTabValue}
                            lid={lid}
                            uid={uid}
                            setRefreshLeadDetails={setRefreshLeadDetails}
                            setLeadData={setLeadData}
                            leadData={leadData}
                            groups={tabsToGroups[filterStore.detailTabValue]}
                            groupsToColumns={groupsToColumns}
                            canEditLead={canEditLead}
                          />
                        );
                      }
                    })()}
                  </Wrapper>
                </Grid>
              </Grid>
            </Container>
          </ParentContainer>
        ) : (
          <SpinnerWrapper>
            <NoAccessText>
              You do not have permission to view the lead.
            </NoAccessText>
          </SpinnerWrapper>
        )
      ) : (
        <SpinnerWrapper>
          <CircularProgress />
        </SpinnerWrapper>
      )}
      <Footer />
      {open && (
        <LeadAssignModal
          open={open}
          setOpen={setOpen}
          refresh={refresh}
          setRefresh={setRefresh}
          leads={[[lid]]}
        />
      )}

      {openStatusModal && (
        <UpdateStatusModal
          id={lid}
          open={openStatusModal}
          setOpen={setOpenStatusModal}
          data={leadData}
          setRefreshLeadDetails={setRefreshLeadDetails}
        />
      )}
      {openUpdateStageModal && (
        <UpdateStageModal
          id={lid}
          open={openUpdateStageModal}
          setOpen={setOpenUpdateStageModal}
          leadData={leadData}
          setRefreshLeadDetails={setRefreshLeadDetails}
        />
      )}
      {openSubmitLeadModal && (
        <SubmitLeadModal
          id={lid}
          open={openSubmitLeadModal}
          setOpen={setOpenSubmitLeadModal}
          leadData={leadData}
          setRefreshLeadDetails={setRefreshLeadDetails}
        />
      )}

      {openSourceModal && (
        <UpdateSourceModal
          id={lid}
          open={openSourceModal}
          setOpen={setOpenSourceModal}
          data={leadData}
          setRefreshLeadDetails={setRefreshLeadDetails}
        />
      )}
      {openConfirmation && (
        <CallConfirmationDailog
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          handlePhoneCall={handlePhoneCall}
        />
      )}
      {openCallInfo && (
        <CallInfoDialog
          open={openCallInfo}
          setOpen={setOpenCallInfo}
          scope={callInfoScope}
          openCallLogCallback={async () => {
            setOpenCallLogModal(true);
            setOpenCallInfo(false);
          }}
        />
      )}
      {openErrorDialog && (
        <ErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDailog}
          message={errorMessage}
        />
      )}
      {openCreateLeadModal && (
        <MoneyViewCreateLead
          data={leadData}
          open={openCreateLeadModal}
          setOpen={setOpenCreateLeadModal}
          setRefreshLeadDetails={setRefreshLeadDetails}
        />
      )}
      {openRefreshOfferDialog && (
        <RefreshOffersDialog
          data={leadData}
          open={openRefreshOfferDialog}
          setOpen={setOpenRefreshOfferDailog}
          setRefreshLeadDetails={setRefreshLeadDetails}
        />
      )}
      {openRefreshJourneyDialog && (
        <RefreshJourneyUrlDialog
          data={leadData}
          open={openRefreshJourneyDialog}
          setOpen={setOpenRefreshJourneyDailog}
          setRefreshLeadDetails={setRefreshLeadDetails}
        />
      )}
      {openCallLogModal && (
        <CallLogModal
          scope={"user_details"}
          open={openCallLogModal}
          setOpen={setOpenCallLogModal}
          lid={getCallStatusLeadID()}
          uid={uid}
          setRefreshLeadDetails={setRefreshLeadDetails}
          leadData={leadData}
        />
      )}
      {openAdvancedCopyPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openAdvancedCopyPanel}
          onClose={() => {
            setOpenAdvancedCopyPanel(false);
          }}
        >
          <AdvanceCopySidepanel
            leadData={leadData}
            setOpen={setOpenAdvancedCopyPanel}
            columnsIdsByGroup={sortedColumnIdList}
            leadId={lid}
          />
        </Drawer>
      )}
      <Backdrop
        open={showBackDrop}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
      {showErrorDialog && (
        <CustomErrorMessageDialog
          message={markLeadErrorMessage}
          open={showErrorDialog}
          setOpen={setShowErrorDialog}
        />
      )}

      {openUnlockLeadConfirmDialog && (
        <ConfirmLeadUnlockDialog
          handleConfirm={() => {
            setLeadLockedOverriden(true);
            setOpenUnlockLeadConfirmDialog(false);
          }}
          open={openUnlockLeadConfirmDialog}
          setOpen={setOpenUnlockLeadConfirmDialog}
          title={"Unlock Lead for Editing"}
          message={"Do you wish to unlock and edit this lead?"}
        />
      )}
      {showConfirmDeleteDialog && (
        <DeleteIndividualLeadConfirmationDialog
          isDeleteProtected={isDeleteProtected}
          open={showConfirmDeleteDialog}
          setOpen={setShowConfirmDeleteDialog}
          handleConfirm={async () => {
            await deleteLead();
          }}
        />
      )}
      {showResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={showResponseDialog}
          setOpen={setShowResponseDialog}
          successMessage={"Lead deleted successfully!"}
          errorMessage={errorMessage}
          loading={isDeleting}
          isError={isSubmitFail}
        />
      )}
    </>
  );
};

export default observer(UserDetail);
