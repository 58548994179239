import React, { useRef } from "react";
import styled from "styled-components";
import { getWidgetDataApiv2, removeWidgetApiv2 } from "../../../../Api";
import { useEffect } from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { CircularProgress, IconButton } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Fullscreen } from "@material-ui/icons";
import { Menu, MenuItem, Typography } from "@mui/material";
import TableComponent from "./TableComponent";
import TableWidgetFullScreenModal from "../../../full_screen_modal/TableWidgetFullScreenModal";
const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
`;
const ChartTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px;
  padding-bottom: 0px; */
`;
const ChartBottom = styled.div`
  width: 100%;
  height: 100%;
`;
const Text = styled.p`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* padding: 20px; */
  padding-top: 20px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const char_size = 10;

const ITEM_HEIGHT = 48;
const TabularWidget = (props) => {
  const [open, setOpen] = useState(false);
  const [showTop, setShowTop] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const componentRef = useRef(null);

  const Open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRemove = async () => {
    try {
      let response = await removeWidgetApiv2({ id: props.data.id });
      setAnchorEl(null);
      props.setRefresh(!props.refresh);
    } catch (error) {
      console.log(error);
    }
  };

  const ChartBottomSection = () => {
    if (!loading) {
      if (tableData.length > 0) {
        return (
          <Wrapper>
            <TableComponent row={tableData} columns={columns} />
          </Wrapper>
        );
      } else {
        return (
          <Wrapper>
            <Typography>No data found!</Typography>
          </Wrapper>
        );
      }
    } else {
      return (
        <Wrapper>
          <CircularProgress />
        </Wrapper>
      );
    }
  };

  //below function is returning the widget width by its reference

  const calculateWidthInPixels = () => {
    if (componentRef.current) {
      const width = componentRef.current.getBoundingClientRect().width;
      return width;
    }
    return 0;
  };

  const calculateWidthByStringLength = (str) => {
    let str_len = str.length + 3;
    if (str_len < 10) {
      return 10 * char_size;
    } else {
      return str_len * char_size;
    }
  };

  const canDivideColumnsEqualWidth = (data) => {
    let total_columns_width = 0;
    let widgetWidth = calculateWidthInPixels();
    Object.keys(data).forEach((key) => {
      total_columns_width += calculateWidthByStringLength(key);
    });

    return total_columns_width < widgetWidth;
  };

  const setupColumns = (data) => {
    const columns_list = [];
    let flag = canDivideColumnsEqualWidth(data);
    Object.keys(data).forEach((key, i) => {
      let new_obj = {
        field: key,
        headerName: key,
        cellRenderer: (params) => {
          let data = null;
          if (
            params.data[key] !== null &&
            params.data[key] !== "" &&
            params.data[key] !== undefined
          ) {
            if (typeof params.data[key] === "boolean") {
              if (params.data[key] === true) {
                data = "True";
              } else {
                data = "False";
              }
            } else {
              data = params.data[key];
            }
          }
          return (
            <span className="csutable-cell-trucate">
              {data !== null ? data : "-"}
            </span>
          );
        },
      };
      if (flag) {
        new_obj["flex"] = 1;
      } else {
        new_obj["width"] = calculateWidthByStringLength(key);
      }

      columns_list.push(new_obj);
    });
    return columns_list;
  };
  const initData = (data_list) => {
    if (data_list.length > 0) {
      let columns = setupColumns(data_list[0]);
      setColumns(columns);
      setTableData(data_list);
    }
    setLoading(false);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await getWidgetDataApiv2({ id: props.data.id });
        initData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (props.type !== "preview") {
      getData();
    }
  }, [props.data]);
  return (
    <>
      <Container>
        <ChartTop>
          <Text>{props.data.title}</Text>
          <IconWrapper>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => {
                setShowTop(false);
                setOpen(true);
              }}
            >
              <Fullscreen />
            </IconButton>
            <IconButton
              onClick={handleClick}
              aria-label="more"
              id="long-button"
              aria-controls={Open ? "long-menu" : undefined}
              aria-expanded={Open ? "true" : undefined}
              aria-haspopup="true"
              style={{ padding: "0px", marginRight: "-10px" }}
            >
              <MoreVertIcon />
            </IconButton>
          </IconWrapper>
        </ChartTop>
        <ChartBottom ref={componentRef}>
          <ChartBottomSection />
        </ChartBottom>
      </Container>

      {open && !showTop && (
        <TableWidgetFullScreenModal
          open={open}
          setOpen={setOpen}
          setShowTop={setShowTop}
          title={props.data.title}
          columns={columns}
          row={tableData}
        />
      )}
      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
          },
        }}
      >
        <MenuItem
          style={{ fontSize: "12px" }}
          onClick={handleRemove}
          disabled={!props.isDashboardEditable()}
        >
          Remove
        </MenuItem>
      </Menu>
    </>
  );
};

export default observer(TabularWidget);
