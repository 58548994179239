import React from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import DetailsWidget from "./addtional_components/DetailsWidget";

const Container = styled(Box)`
  width: 100%;
  padding: 20px;
  background-color: white;
  ${`min-height: calc(100vh - 246px);`};
  /* background: #f9f9fc 0% 0% no-repeat padding-box; */
`;

const BasicInfoTab = ({ loading, customerData, groups, groupsToColumns }) => {
  return (
    <>
      {!loading ? (
        <Container>
          <DetailsWidget
            details={customerData}
            groups={groups}
            groupsToColumns={groupsToColumns}
          />
        </Container>
      ) : (
        <Container
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      )}
    </>
  );
};

export default BasicInfoTab;
