import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { CircularProgress } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import TableComponent from "../../chart_components/dashboard_chart_v2/tabular_widget/TableComponent";
const Container = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
`;

const ChartBottom = styled(Box)`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* padding: 20px; */
  padding-top: 20px;
`;
const Text = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const char_size = 10;

const TabularWidget = (props) => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const componentRef = useRef(null);

  const ChartBottomSection = () => {
    if (!loading) {
      if (tableData.length > 0) {
        return (
          <Wrapper>
            <TableComponent row={tableData} columns={columns} />
          </Wrapper>
        );
      } else {
        return (
          <Wrapper>
            <Typography>No data found!</Typography>
          </Wrapper>
        );
      }
    } else {
      return (
        <Wrapper>
          <CircularProgress />
        </Wrapper>
      );
    }
  };

  //below function is returning the widget width by its reference

  const calculateWidthInPixels = () => {
    if (componentRef.current) {
      const width = componentRef.current.getBoundingClientRect().width;
      return width;
    }
    return 0;
  };

  const calculateWidthByStringLength = (str) => {
    let str_len = str.length + 3;
    if (str_len < 10) {
      return 10 * char_size;
    } else {
      return str_len * char_size;
    }
  };

  const canDivideColumnsEqualWidth = (data) => {
    let total_columns_width = 0;
    let widgetWidth = calculateWidthInPixels();
    Object.keys(data).forEach((key) => {
      total_columns_width += calculateWidthByStringLength(key);
    });

    return total_columns_width < widgetWidth;
  };

  const setupColumns = (data) => {
    const columns_list = [];
    let flag = canDivideColumnsEqualWidth(data);
    Object.keys(data).forEach((key, i) => {
      let new_obj = {
        field: key,
        headerName: key,
        cellRenderer: (params) => {
          let data = null;
          if (
            params.data[key] !== null &&
            params.data[key] !== "" &&
            params.data[key] !== undefined
          ) {
            if (typeof params.data[key] === "boolean") {
              if (params.data[key] === true) {
                data = "True";
              } else {
                data = "False";
              }
            } else {
              data = params.data[key];
            }
          }
          return (
            <span className="csutable-cell-trucate">
              {data !== null ? data : "-"}
            </span>
          );
        },
      };
      if (flag) {
        new_obj["flex"] = 1;
      } else {
        new_obj["width"] = calculateWidthByStringLength(key);
      }

      columns_list.push(new_obj);
    });
    return columns_list;
  };
  const initData = (data_list) => {
    if (data_list.length > 0) {
      let columns = setupColumns(data_list[0]);
      setColumns(columns);
      setTableData(data_list);
    }
    setLoading(false);
  };

  useEffect(() => {
    const getData = async () => {
      initData(props.data);
    };

    getData();
  }, [props.widgetConfig]);
  return (
    <>
      <Container>
        <Box>
          <Text>{props.widgetConfig.title}</Text>
        </Box>
        <ChartBottom ref={componentRef}>
          <ChartBottomSection />
        </ChartBottom>
      </Container>
    </>
  );
};

export default observer(TabularWidget);
