//here we will store all the required data rendering on our page and will use whenever wherever it is required
import "./styles/multiselect.css";
import { Tooltip } from "@material-ui/core";
import img1 from "./assets/1.png";
import img2 from "./assets/2.png";
import img3 from "./assets/3.png";
import img4 from "./assets/4.png";
import { FiberManualRecord, MoreHoriz } from "@material-ui/icons";
import styled from "styled-components";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  dataRenderer,
  dateHandler,
  dateTimeHandler,
  getLocalizedText,
  IsoToLocalDate,
  IsoToLocalTime,
  tabsDateHandler,
} from "./Functions";
import { Box, Chip, Typography } from "@mui/material";
import { Rectangle } from "@mui/icons-material";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  outline: none;
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #363636;
`;
const TextHighlight = styled.span`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;
const ColDirection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 4px;
`;

function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}
const ColumnHeader = (value) => {
  return (
    <Container>
      <Text>{value}</Text>
    </Container>
  );
};
const RenderList = (value) => {
  if (value.length > 0) {
    return (
      <ColDirection>
        {value.map((e) => {
          <Text>{e}</Text>;
        })}
      </ColDirection>
    );
  }
};
const content = [
  {
    title: "Interactive Learning",
    body: "Learn and engage with content immersively through guided social interaction.",
  },
  {
    title: "Self Learning",
    body: "Learn daily via content designed and personalized for every student’s learning journey using AI",
  },
  {
    title: "Exploratory Learning",
    body: "Learn incrementally in a real-world scenario with open-ended learning objectives like lab simulations",
  },
  {
    title: "Social collaborative learning",
    body: "Learn with your friends together, solve problems, and complement team strengths",
  },
];

const Auth = [
  {
    login: {
      header: "User Login!",
      subheader: "please enter the below details to login",
      label: "Phone Number",
      code: "+91",
      disclaimer: " By signing up for Leadpixie, you agree to our",
      t1: "Terms Of Use",
      t2: "Privacy Policy",
    },
    update: {
      header: "Update Number",
      subheader: "Please enter the mobile number to update.",
      label: "Phone Number",
      code: "+91",
    },
    otp: {
      phone_header: "Please verify your phone number",
      email_header: "Please verify your email address",
      subheader: getLocalizedText("enter_4_digit_code_sent_to"),
      disclaimer: getLocalizedText("resend_otp_in"),
    },
  },
];

const ruleList = [
  {
    name: "Assignment as in import file",
    data: {},
  },
  { name: "Selected Project Members - Equally", data: {} },
];
const createImportRuleList = [
  {
    name: "if contact does not exist, create contact and create lead",
    slug: "option_no_contact",
    desc: "",
  },
  {
    name: "if contact exists and no lead exists, then update contact and create lead",
    slug: "option_contact_exists_no_lead",
    desc: "",
  },
  {
    name: "if contact exists and lead(s) exists, add a new lead to contact",
    slug: "option_contact_exists_add_lead",
    desc: "",
  },
  {
    name: "if contact exists and lead(s) exists, add a new lead only if this lead column is not duplicate",
    slug: "existing_contact_existing_lead_duplicate_column",
    desc: "",
  },
];
const updateImportRuleList = [
  {
    name: "if contact exists and one lead exists, update lead",
    slug: "existing_contact_update_lead",
    desc: "",
  },
  {
    name: "if contact exists and more than one lead exists, update all leads",
    slug: "existing_contact_update_all_leads",
    desc: "",
  },
  {
    name: "if contact exists and more than one lead exists, update lead with a specific column value",
    slug: "existing_contact_update_lead_specific_column",
    desc: "",
  },
];

const callsColumns = [
  {
    field: "lead_status",
    headerName: ColumnHeader("Status"),
    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.lead_status}>
            <span
              className="csutable-cell-trucate"
              style={{
                color:
                  params.row.lead_status === "Not Interested" ? "red" : "green",
              }}
            >
              {params.row.lead_status}
            </span>
          </Tooltip>
        </>
      );
    },
    sortable: false,
  },
  {
    field: "lead_sub_status",
    headerName: ColumnHeader("Sub Status"),
    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.lead_sub_status}>
            <span
              className="csutable-cell-trucate"
              style={{
                color:
                  params.row.lead_sub_status === "Not Interested"
                    ? "red"
                    : "green",
              }}
            >
              {params.row.lead_sub_status}
            </span>
          </Tooltip>
        </>
      );
    },
    sortable: false,
  },

  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    width: 220,
    sortable: false,
    renderCell: (params) => {
      let date;
      if (params.row.created_at !== null) {
        date = IsoToLocalTime(params.row.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.created_at !== null ? date : params.row.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "next_follow_up_on",
    headerName: ColumnHeader("Follow Up On"),
    width: 220,
    sortable: false,
    renderCell: (params) => {
      let date;
      if (params.row.next_followup_on !== null) {
        date = IsoToLocalTime(params.row.next_followup_on);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.next_followup_on !== null ? date : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "notes",
    sortable: false,

    headerName: ColumnHeader("Call Notes"),
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.notes}>
            <span className="csutable-cell-trucate">
              {params.row.notes !== "" && params.row.notes !== null
                ? truncate(params.row.notes, 26)
                : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "customer_notes",
    sortable: false,

    headerName: ColumnHeader("Customer Notes"),
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.customer_notes}>
            <span className="csutable-cell-trucate">
              {params.row.customer_notes !== "" &&
              params.row.customer_notes !== null
                ? truncate(params.row.customer_notes, 26)
                : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "name",
    sortable: false,

    headerName: ColumnHeader("Caller Name"),
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.caller.name}>
            <span className="csutable-cell-trucate">
              {params.row.caller.name}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "call_duration",
    sortable: false,

    headerName: ColumnHeader("Call Duration(s)"),
    width: 200,
    renderCell: (params) => {
      let value =
        params.row.call_duration !== null && params.row.call_duration !== ""
          ? `${params.row.call_duration}`
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//****************************CheckIns Column******************************** */

const CheckInColumns = [
  {
    field: "place",
    sortable: false,

    headerName: ColumnHeader("Place"),
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.place}>
            <span className="csutable-cell-trucate">{params.row.place}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "checked_in",
    sortable: false,

    headerName: ColumnHeader("Checked In"),
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.checked_in}>
            <span className="csutable-cell-trucate">
              {IsoToLocalTime(params.row.checked_in)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "checked_out",
    sortable: false,

    headerName: ColumnHeader("Checked Out"),
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.checked_out}>
            <span className="csutable-cell-trucate">
              {IsoToLocalTime(params.row.checked_out)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

const leadActivityColumns = [
  {
    field: "activity_performed_at",
    headerName: ColumnHeader("Time Of Activity"),
    sortable: false,
    width: 200,
    renderCell: (params) => {
      let date =
        params.row.activity_performed_at !== null
          ? IsoToLocalTime(params.row.activity_performed_at)
          : "-";

      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">{date}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "activity_desc",
    headerName: ColumnHeader("Description"),
    sortable: false,
    width: 250,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.activity_desc}>
            <span className="csutable-cell-trucate">
              {params.row.activity_desc !== null
                ? truncate(params.row.activity_desc, 30)
                : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "activity_type",
    headerName: ColumnHeader("Activity Type"),
    sortable: false,
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.activity_type}>
            <span className="csutable-cell-trucate">
              {params.row.activity_type}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "user",
    headerName: ColumnHeader("Performed By"),
    sortable: false,
    width: 200,
    renderCell: (params) => {
      let value =
        params.row.user !== null ? dataRenderer(params.row.user["name"]) : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

const businessContactColumns = [
  {
    field: "name",
    headerName: ColumnHeader("Name"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let full_name = `${params.row.salutation} ${params.row.first_name} ${params.row.last_name}`;
      return (
        <>
          <Tooltip title={full_name}>
            <span
              style={{
                color: params.row.decision_maker ? "#ffffff" : "#353535",
              }}
              className="csutable-cell-trucate"
            >
              {full_name}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "title",
    headerName: ColumnHeader("Title"),
    sortable: false,

    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.title}>
            <span
              style={{
                color: params.row.decision_maker ? "#f5f5f5" : "#353535",
              }}
              className="csutable-cell-trucate"
            >
              {params.row.title}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

const notesContactColumns = [
  {
    field: "note",
    sortable: false,

    headerName: ColumnHeader("Note"),
    width: 400,
    renderCell: (params) => {
      return (
        <>
          <Tooltip
            title={<Typography fontSize={14}> {params.row.note}</Typography>}
          >
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {truncate(params.row.note, 70)}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "created_by",
    sortable: false,
    headerName: ColumnHeader("Created By"),
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.created_by.name}>
            <span className="csutable-cell-trucate">
              {params.row.created_by.name}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created At"),
    width: 220,
    sortable: false,
    renderCell: (params) => {
      let date;
      if (params.row.created_at !== null) {
        date = IsoToLocalTime(params.row.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.created_at !== null ? date : params.row.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//*************user file tab column headers*********************************** */

const leadFileColumns = [
  {
    field: "file_type",
    headerName: ColumnHeader("File Type"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let value = params.row.hasOwnProperty("file_type")
        ? dataRenderer(params.row.file_type)
        : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "uploaded_by",
    headerName: ColumnHeader("Uploaded By"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let value = params.row.hasOwnProperty("uploaded_by")
        ? dataRenderer(params.row.uploaded_by.name)
        : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Uploaded On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let value =
        params.row.hasOwnProperty("created_at") &&
        params.row.created_at !== null
          ? IsoToLocalTime(params.row.created_at)
          : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      if (
        params.row.hasOwnProperty("status") &&
        params.row["status"] !== null
      ) {
        return <Chip label={params.row["status"]} color="primary" />;
      } else {
        return "-";
      }
    },
  },
];

//********import history column headers******************************* */

const ImportHistoryColumns = [
  {
    field: "project",
    headerName: ColumnHeader("Project"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip project={params.row.project}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "import_type",
    headerName: ColumnHeader("Import Type"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.import_type}>
            <span className="csutable-cell-trucate">
              {params.row.import_type}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "imported_by",
    headerName: ColumnHeader("Imported By"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.imported_by}>
            <span className="csutable-cell-trucate">
              {params.row.imported_by}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "start_time",
    headerName: ColumnHeader("Start Time"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.start_time !== null) {
        date = dateTimeHandler(params.row.start_time);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.start_time !== null ? date : params.row.start_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_time",
    headerName: ColumnHeader("End Time"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.end_time !== null) {
        date = dateTimeHandler(params.row.end_time);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.end_time !== null ? date : params.row.end_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_processed",
    headerName: ColumnHeader("Rows Processed"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_processed}>
            <span className="csutable-cell-trucate">
              {params.row.rows_processed}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_created",
    headerName: ColumnHeader("Rows Created"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_created}>
            <span className="csutable-cell-trucate">
              {params.row.rows_created}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_updated",
    headerName: ColumnHeader("Rows Updated"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_updated}>
            <span className="csutable-cell-trucate">
              {params.row.rows_updated}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_errored",
    headerName: ColumnHeader("Rows Errored"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_errored}>
            <span className="csutable-cell-trucate">
              {params.row.rows_errored}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "error_log",
    headerName: ColumnHeader("Error Log"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.error_log}>
            <a
              className="csutable-cell-trucate"
              href={params.row.error_log}
              target="_blank"
              rel="noreferrer"
            >
              <MoreHoriz />
            </a>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "full_log",
    headerName: ColumnHeader("Full Log"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.full_log}>
            <a
              className="csutable-cell-trucate"
              href={params.row.full_log}
              target="_blank"
              rel="noreferrer"
            >
              <MoreHoriz />
            </a>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "original_file",
    headerName: ColumnHeader("Original File"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.original_file}>
            <a
              className="csutable-cell-trucate"
              href={params.row.original_file}
              target="_blank"
              rel="noreferrer"
            >
              <MoreHoriz />
            </a>
          </Tooltip>
        </>
      );
    },
  },
];

//********import history listing column headers******************************* */

const ImportHistoryListingColumns = [
  {
    field: "project",
    headerName: ColumnHeader("Project"),
    sortable: false,
  },
  {
    field: "import_type",
    headerName: ColumnHeader("Import Type"),
    sortable: false,
  },
  {
    field: "imported_by",
    headerName: ColumnHeader("Imported By"),
    sortable: false,
  },

  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,
  },

  {
    field: "start_time",
    headerName: ColumnHeader("Start Time"),
    sortable: false,
  },
  {
    field: "end_time",
    headerName: ColumnHeader("End Time"),
    sortable: false,
  },
];

//********Raw Leads import history table column headers******************************* */

const RawLeadsImportHistoryTableColumns = [
  {
    field: "import_type",
    headerName: ColumnHeader("Job Type"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.import_type}>
            <span className="csutable-cell-trucate">
              {params.row.import_type}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "imported_by",
    headerName: ColumnHeader("Creator"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.imported_by}>
            <span className="csutable-cell-trucate">
              {params.row.imported_by}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "start_time",
    headerName: ColumnHeader("Start Time"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.start_time !== null) {
        date = dateTimeHandler(params.row.start_time);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.start_time !== null ? date : params.row.start_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_time",
    headerName: ColumnHeader("End Time"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.end_time !== null) {
        date = dateTimeHandler(params.row.end_time);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.end_time !== null ? date : params.row.end_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//********Raw Leads import history column headers******************************* */

const RawLeadsImportHistoryColumns = [
  {
    field: "import_type",
    headerName: ColumnHeader("Import Type"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.import_type}>
            <span className="csutable-cell-trucate">
              {params.row.import_type}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "imported_by",
    headerName: ColumnHeader("Imported By"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.imported_by}>
            <span className="csutable-cell-trucate">
              {params.row.imported_by}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "start_time",
    headerName: ColumnHeader("Start Time"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.start_time !== null) {
        date = dateTimeHandler(params.row.start_time);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.start_time !== null ? date : params.row.start_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_time",
    headerName: ColumnHeader("End Time"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.end_time !== null) {
        date = dateTimeHandler(params.row.end_time);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.end_time !== null ? date : params.row.end_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_processed",
    headerName: ColumnHeader("Rows Processed"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_processed}>
            <span className="csutable-cell-trucate">
              {params.row.rows_processed}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_created",
    headerName: ColumnHeader("Rows Created"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_created}>
            <span className="csutable-cell-trucate">
              {params.row.rows_created}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_updated",
    headerName: ColumnHeader("Rows Updated"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_updated}>
            <span className="csutable-cell-trucate">
              {params.row.rows_updated}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_errored",
    headerName: ColumnHeader("Rows Errored"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_errored}>
            <span className="csutable-cell-trucate">
              {params.row.rows_errored}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "error_log",
    headerName: ColumnHeader("Error Log"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.error_log}>
            <a
              className="csutable-cell-trucate"
              href={params.row.error_log}
              target="_blank"
              rel="noreferrer"
            >
              <MoreHoriz />
            </a>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "full_log",
    headerName: ColumnHeader("Full Log"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.full_log}>
            <a
              className="csutable-cell-trucate"
              href={params.row.full_log}
              target="_blank"
              rel="noreferrer"
            >
              <MoreHoriz />
            </a>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "original_file",
    headerName: ColumnHeader("Original File"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.original_file}>
            <a
              className="csutable-cell-trucate"
              href={params.row.original_file}
              target="_blank"
              rel="noreferrer"
            >
              <MoreHoriz />
            </a>
          </Tooltip>
        </>
      );
    },
  },
];

//**********************************target page *******************************************/

const TargetPageColumns = [
  {
    field: "name",
    headerName: ColumnHeader("Name"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.name}>
            <span className="csutable-cell-trucate">{params.row.name}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "measure",
    headerName: ColumnHeader("Measure"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.measure}>
            <span className="csutable-cell-trucate">{params.row.measure}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "start_date",
    headerName: ColumnHeader("Start Date"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.start_date !== null) {
        date = dateTimeHandler(params.row.start_date);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.start_date !== null ? date : params.row.start_date}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_date",
    headerName: ColumnHeader("End Date"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.end_date !== null) {
        date = dateTimeHandler(params.row.end_date);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.end_date !== null ? date : params.row.end_date}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "actual",
    headerName: ColumnHeader("Actual"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.actual}>
            <span className="csutable-cell-trucate">{params.row.actual}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "target",
    headerName: ColumnHeader("Target"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.target}>
            <span className="csutable-cell-trucate">{params.row.target}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "percentage_achieved",
    headerName: ColumnHeader("% Target Achieved"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.percentage_achieved}>
            <span className="csutable-cell-trucate">
              {params.row.percentage_achieved}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "creator",
    headerName: ColumnHeader("Creator"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.creator}>
            <span className="csutable-cell-trucate">{params.row.creator}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.created_at !== null) {
        date = dateTimeHandler(params.row.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.created_at !== null ? date : params.row.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//**********************************target Details page *******************************************/

const TargetDetailsColumns = [
  {
    field: "name",
    headerName: ColumnHeader("Member Name"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.name}>
            <span className="csutable-cell-trucate">{params.row.name}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "team",
    headerName: ColumnHeader("Team"),
    sortable: false,

    width: 170,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.team}>
            <span className="csutable-cell-trucate">{params.row.team}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "target",
    headerName: ColumnHeader("Target"),
    sortable: false,

    width: 170,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.target}>
            <span className="csutable-cell-trucate">{params.row.target}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "actual",
    headerName: ColumnHeader("Actual"),
    sortable: false,

    width: 170,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.actual}>
            <span className="csutable-cell-trucate">{params.row.actual}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "percentage",
    headerName: ColumnHeader("% Target Achieved"),
    sortable: false,

    width: 400,
    renderCell: (params) => {
      return (
        <>
          <ProgressBar
            // completedClassName="barCompleted-progress-bar"
            labelClassName="label-progress-bar"
            width="400px"
            height="20px"
            bgColor="#59E3A3"
            borderRadius="0px"
            animateOnRender={true}
            completed={params.row.percentage}
          />
        </>
      );
    },
  },
];

//***************member setting columns*********************************************** */

const MemberSettingColumns = [
  {
    field: "name",
    headerName: ColumnHeader("Member Name"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.name}>
            <span className="csutable-cell-trucate">{params.row.name}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "email",
    headerName: ColumnHeader("Email"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.email}>
            <span className="csutable-cell-trucate">{params.row.email}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "phone",
    headerName: ColumnHeader("Phone"),
    sortable: false,

    width: 140,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.phone}>
            <span className="csutable-cell-trucate">{params.row.phone}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "role",
    headerName: ColumnHeader("Role"),
    sortable: false,

    width: 140,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.role}>
            <span className="csutable-cell-trucate">{params.row.role}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "team_name",
    headerName: ColumnHeader("Team"),
    sortable: false,

    width: 140,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.team["name"]}>
            <span className="csutable-cell-trucate">
              {params.row.team["name"]}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "calling_mode",
    headerName: ColumnHeader("Calling Mode"),
    sortable: false,

    width: 140,
    renderCell: (params) => {
      let callingMode = "-";
      if (params.row.hasOwnProperty("calling_mode")) {
        if (
          params.row.calling_mode !== null ||
          params.row.calling_mode !== ""
        ) {
          callingMode = params.row.calling_mode;
        }
      }
      return (
        <>
          <Tooltip title={callingMode}>
            <span className="csutable-cell-trucate">{callingMode}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "unmask_columns",
    headerName: ColumnHeader("Unmask Columns"),
    sortable: false,

    width: 125,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.unmask_columns}>
            <span className="csutable-cell-trucate">
              {params.row.unmask_columns ? "True" : "False"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************new user setting columns******************** */

const NewUSerSettingColumns = [
  // {
  //   field: "sl no",
  //   headerName: "Sl No.",

  //   width: 230,
  //   cellRenderer: (params) => {
  //     return params.node.rowIndex + 1;
  //   },
  // },
  {
    field: "flid",
    headerName: "ID",

    width: 100,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.flid}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.flid)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "name",
    headerName: "Name",

    width: 230,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">{params.data.name}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "phone",
    headerName: "Phone",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.phone}>
            <span className="csutable-cell-trucate">{params.data.phone}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",

    width: 230,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.email}>
            <span className="csutable-cell-trucate">{params.data.email}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "employee_id",
    headerName: "Employee ID",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.employee_id}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.employee_id)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "group",
    headerName: "Group",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.group}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.group)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "designation",
    headerName: "Designation",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.designation}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.designation)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "role",
    headerName: "Role",

    width: 120,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.role}>
            <span className="csutable-cell-trucate">{params.data.role}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "last_action_at",
    headerName: "Last Action At",

    width: 200,
    cellRenderer: (params) => {
      let date = "-";
      if (params.data.last_action_at !== null) {
        date = IsoToLocalTime(params.data.last_action_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">{date}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: "Created On",

    width: 150,
    cellRenderer: (params) => {
      let date;
      if (params.data.created_at !== null) {
        date = IsoToLocalDate(params.data.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.data.created_at !== null ? date : params.data.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "unmask_columns",
    headerName: "Unmask Columns",

    width: 125,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.unmask_columns}>
            <span className="csutable-cell-trucate">
              {params.data.unmask_columns ? "True" : "False"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//***************project setting columns******************* */

const ProjectSettingColumns = [
  {
    field: "name",
    headerName: ColumnHeader("Project Name"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.name}>
            <span className="csutable-cell-trucate">{params.row.name}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.created_at !== null) {
        date = IsoToLocalTime(params.row.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.created_at !== null ? date : params.row.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//***************team setting columns******************* */

const TeamSettingColumns = [
  {
    field: "name",
    headerName: ColumnHeader("Team Name"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.name}>
            <span className="csutable-cell-trucate">{params.row.name}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//**************************admin statuses column headers *********************************/
const AdminStatusColumns = [
  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "sub_status",
    headerName: ColumnHeader("Sub-Status"),
    sortable: false,

    width: 300,
    renderCell: (params) => {
      return params.row.sub_status.length > 0 ? (
        <ColDirection>
          {params.row.sub_status.map((e) => {
            return (
              <div
                style={{
                  display: "flex",
                  columnGap: "4px",
                  alignItems: "flex-start",
                  marginBottom: "4px",
                }}
              >
                <FiberManualRecord
                  style={{
                    width: "12px",
                    height: "12px",
                    marginTop: "3px",
                    color: "gray",
                  }}
                />
                <span>{e}</span>
              </div>
            );
          })}
        </ColDirection>
      ) : (
        ""
      );
    },
  },
  {
    field: "group",
    headerName: ColumnHeader("Group"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      let value = dataRenderer(params.row.group);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "is_visible_to_add",
    headerName: ColumnHeader("Visible"),
    sortable: false,

    width: 125,
    renderCell: (params) => {
      let value = params.row.is_visible_to_add ? "True" : "False";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "notes_mandatory",
    headerName: ColumnHeader("Call Notes"),
    sortable: false,

    width: 125,
    renderCell: (params) => {
      let value = params.row.notes_mandatory ? "True" : "False";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "delete_protected",
    headerName: ColumnHeader("Delete Protection"),
    sortable: false,

    width: 125,
    renderCell: (params) => {
      let value = params.row.delete_protected ? "True" : "False";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "color",
    headerName: ColumnHeader("Color"),
    sortable: true,

    width: 130,
    renderCell: (params) => {
      if (params.row.color !== null) {
        return (
          <Rectangle
            style={{
              color: params.row.color,
              width: "30px",
              height: "30px",
            }}
          />
        );
      } else {
        return "-";
      }
    },
  },
];

//***************admin status_stage column headers********************** */

const AdminStatusToStageColumns = [
  {
    field: "lead_stage",
    headerName: "STAGE",
    sortable: false,

    width: 230,
    cellRenderer: (params) => {
      let value =
        params.data.lead_stage !== null
          ? dataRenderer(params.data.lead_stage.stage)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_status",
    headerName: "ON STATUS",
    sortable: false,

    width: 230,
    cellRenderer: (params) => {
      let value =
        params.data.lead_status !== null
          ? dataRenderer(params.data.lead_status.status)
          : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//*******************admin source column headers********************** */
const AdminSourceColumns = [
  {
    field: "source",
    headerName: ColumnHeader("Source"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.source}>
            <span className="csutable-cell-trucate">{params.row.source}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//*******************admin stage column headers********************** */
const AdminStageColumns = [
  {
    field: "stage",
    headerName: "STAGE",
    sortable: false,
    rowDrag: true,
    width: 230,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.stage}>
            <span className="csutable-cell-trucate">{params.data.stage}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rank",
    headerName: "RANK",
    sortable: true,

    width: 130,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.rank}>
            <span className="csutable-cell-trucate">{params.data.rank}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "delete_protected",
    headerName: "DELETE PROTECTED",
    sortable: false,
    width: 125,
    cellRenderer: (params) => {
      let value = params.data.delete_protected ? "True" : "False";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "color",
    headerName: "COLOR",
    sortable: true,

    width: 130,
    cellRenderer: (params) => {
      if (params.data.color !== null) {
        return (
          <Rectangle
            style={{
              color: params.data.color,
              width: "30px",
              height: "30px",
            }}
          />
        );
      } else {
        return "-";
      }
    },
  },
];

//*****************************admin ColumnTab herader************************* */

const AdminViewColumns = [
  {
    field: "name",
    headerName: ColumnHeader("Name"),
    sortable: false,

    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.name}>
            <span className="csutable-cell-trucate">{params.row.name}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "group",
    headerName: ColumnHeader("Group"),
    sortable: false,

    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.group}>
            <span className="csutable-cell-trucate">
              {params.row.group !== null && params.row.group !== ""
                ? params.row.group
                : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "data_type",
    headerName: ColumnHeader("DataType"),
    sortable: false,

    width: 100,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.data_type}>
            <span className="csutable-cell-trucate">
              {params.row.data_type}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "filterable",
    headerName: ColumnHeader("Filterable"),
    sortable: false,

    width: 90,
    renderCell: (params) => {
      let value = params.row.filterable;
      if (value === true) {
        value = "true";
      } else {
        value = "false";
      }
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

const AdminColumnsGroup = [
  {
    field: "name",
    headerName: "NAME",

    width: 150,
    flex: 1,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.name)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "editable",
    headerName: "EDITABLE",
    sortable: false,

    width: 130,
    renderCell: (params) => {
      let value = params.row.editable;
      if (value === true) {
        value = "true";
      } else {
        value = "false";
      }
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************************Reports Listing Column Headers ******************************************* */

const ReportListColumns = [
  {
    field: "title",
    headerName: ColumnHeader("Title"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.title}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "desc",
    headerName: ColumnHeader("Description"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.desc}>
            <span className="csutable-cell-trucate">{params.row.desc}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "project",
    headerName: ColumnHeader("Project"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip project={params.row.project}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "start_date",
    headerName: ColumnHeader("Start Date"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.start_date}>
            <span className="csutable-cell-trucate">
              {params.row.start_date}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_date",
    headerName: ColumnHeader("End Date"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.end_date}>
            <span className="csutable-cell-trucate">
              {params.row.start_date}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.created_at !== null) {
        date = tabsDateHandler(params.row.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.created_at !== null ? date : params.row.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************************Segments Listing Column Headers ******************************************* */

const SegmentListColumns = [
  {
    field: "title",
    headerName: ColumnHeader("Title"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.title}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "desc",
    headerName: ColumnHeader("Description"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.desc}>
            <span className="csutable-cell-trucate">{params.row.desc}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "project",
    headerName: ColumnHeader("Project"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.project}>
            <span className="csutable-cell-trucate">{params.row.project}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "creator",
    headerName: ColumnHeader("Created By"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.creator.name}>
            <span className="csutable-cell-trucate">{params.creator.name}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.created_on}>
            <span className="csutable-cell-trucate">
              {params.row.created_on}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************************Campaign Listing Column Headers ******************************************* */

const CampaignListColumnsHeaders = [
  {
    field: "title",
    headerName: ColumnHeader("Title"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.title}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "segment",
    headerName: ColumnHeader("Segment"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.segment}>
            <span className="csutable-cell-trucate">{params.row.segment}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "campaign_type",
    headerName: ColumnHeader("Campaign Type"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.campaign_type}>
            <span className="csutable-cell-trucate">
              {params.row.campaign_type}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
  },

  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 150,
  },
];

//************************************Jobs Listing Column Headers ******************************************* */

const JobListColumns = [
  {
    field: "action",
    headerName: ColumnHeader("Action"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip action={params.row.action}>
            <span className="csutable-cell-trucate">{params.row.action}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip status={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "project",
    headerName: ColumnHeader("Project"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip project={params.row.project}>
            <span className="csutable-cell-trucate">{params.row.project}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "title",
    headerName: ColumnHeader("Title"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.title}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "desc",
    headerName: ColumnHeader("Description"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.desc}>
            <span className="csutable-cell-trucate">{params.row.desc}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "job_type",
    headerName: ColumnHeader("Job Type"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.job_type}>
            <span className="csutable-cell-trucate">{params.row.job_type}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_by",
    headerName: ColumnHeader("Created By"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.created_by}>
            <span className="csutable-cell-trucate">
              {params.row.created_by}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.created_at !== null) {
        date = tabsDateHandler(params.row.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.created_at !== null ? date : params.row.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "start_time",
    headerName: ColumnHeader("Start Time"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.start_time}>
            <span className="csutable-cell-trucate">
              {params.row.start_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_time",
    headerName: ColumnHeader("End Time"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.end_time}>
            <span className="csutable-cell-trucate">{params.row.end_time}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************************Raw Lead Export Job Listing Column Headers ******************************************* */

const RawLeadExportJobColumns = [
  {
    field: "action",
    headerName: ColumnHeader("Actions"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip action={params.row.action}>
            <span className="csutable-cell-trucate">{params.row.action}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "title",
    headerName: ColumnHeader("Title"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.title}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip status={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "created_by",
    headerName: ColumnHeader("Created By"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.created_by}>
            <span className="csutable-cell-trucate">
              {params.row.created_by}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.created_at !== null) {
        date = tabsDateHandler(params.row.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.created_at !== null ? date : params.row.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "start_time",
    headerName: ColumnHeader("Start Time"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.start_time}>
            <span className="csutable-cell-trucate">
              {params.row.start_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_time",
    headerName: ColumnHeader("End Time"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.end_time}>
            <span className="csutable-cell-trucate">{params.row.end_time}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//*******************support section column headers********************** */
const SupportSectionColumns = [
  {
    field: "title",
    headerName: ColumnHeader("Title"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.title}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "priority",
    headerName: ColumnHeader("Priority"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.priority}>
            <span className="csutable-cell-trucate">{params.row.priority}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_by",
    headerName: ColumnHeader("Created By"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.created_by}>
            <span className="csutable-cell-trucate">
              {params.row.created_by}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.created_at}>
            <span className="csutable-cell-trucate">
              {params.row.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//*******************billing section column headers********************** */
const billingSectionColumns = [
  {
    field: "month",
    headerName: ColumnHeader("Month"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip month={params.row.month}>
            <span className="csutable-cell-trucate">{params.row.month}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "amount",
    headerName: ColumnHeader("Amount"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.amount}>
            <span className="csutable-cell-trucate">{params.row.amount}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "invoice_sent_on",
    headerName: ColumnHeader("Invoice Sent On"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.invoice_sent_on}>
            <span className="csutable-cell-trucate">
              {params.row.invoice_sent_on}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "paid_on",
    headerName: ColumnHeader("Paid On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.paid_on}>
            <span className="csutable-cell-trucate">{params.row.paid_on}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "invoice",
    headerName: ColumnHeader("Invoice"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.invoice}>
            <span className="csutable-cell-trucate">{params.row.invoice}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************************Raw Leads Column Headers ******************************************* */

const RawLeadsColumnHeaders = [
  {
    field: "phone",
    headerName: "Phone",
    headerCheckboxSelection: true,
    checkboxSelection: true,

    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.phone}>
            <span className="csutable-cell-trucate">
              {params.data.phone !== null ? params.data.phone : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "name",
    headerName: "Name",
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">
              {params.data.name !== null ? params.data.name : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "city",
    headerName: "City",

    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.city}>
            <span className="csutable-cell-trucate">
              {params.data.city !== null ? params.data.city : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",

    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.email}>
            <span className="csutable-cell-trucate">
              {params.data.email !== null ? params.data.email : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "source",
    headerName: "Source",

    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.source}>
            <span className="csutable-cell-trucate">
              {params.data.source !== null && params.data.source !== ""
                ? params.data.source
                : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "created_by",
    headerName: "Created By",

    width: 130,
    cellRenderer: (params) => {
      let value =
        params.data.created_by !== null ? params.data.created_by.name : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "created_at",
    headerName: "Created On",
    cellRenderer: (params) => {
      let date;
      if (params.value.created_at !== null) {
        date = IsoToLocalTime(params.value.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {date !== null ? date : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************auto dialer campaign column headers****************** */
const AutoDialerCampaignColumnHeaders = [
  {
    field: "agent_name",
    headerName: ColumnHeader("Agent Name"),
    sortable: false,
    flex: 1,
  },
];
//***********************************END******************************* */

//************************Ozonetel dialer agent list specific column headers****************** */
const OzonetelDialerAgentListColumnHeaders = [
  {
    field: "agent_name",
    headerName: ColumnHeader("Agent Name"),
    sortable: false,
    flex: 1,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.agent_name}>
          <span className="csutable-cell-trucate">{params.row.agent_name}</span>
        </Tooltip>
      );
    },
  },
  {
    field: "name",
    headerName: ColumnHeader("User Name"),
    sortable: false,
    flex: 1,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.user.name}>
          <span className="csutable-cell-trucate">{params.row.user.name}</span>
        </Tooltip>
      );
    },
  },
];
//***********************************END******************************* */

//*********************Raw Lead Move to Project response data column headers*********************** */

const MoveRawLeadsResponseColumnHeaders = [
  {
    field: "phone",
    headerName: ColumnHeader("Phone"),
    sortable: false,

    width: 150,

    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.phone}>
            <span className="csutable-cell-trucate">
              {params.row.phone !== null ? params.row.phone : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 150,

    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.status}>
            <span className="csutable-cell-trucate">
              {params.row.status !== null ? params.row.status : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "message",
    headerName: ColumnHeader("Message"),
    sortable: false,

    flex: 1,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.message}>
            <span className="csutable-cell-trucate">
              {params.row.message !== null ? params.row.message : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//********************IP config column headers********************* */
const IpConfigColumnHeaders = [
  {
    field: "ip_address",
    headerName: ColumnHeader("IPV4 Address"),
    sortable: false,
    width: 200,
    renderCell: (params) => {
      return dataRenderer(params.row.ip_address);
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created At"),
    sortable: false,
    width: 200,
    renderCell: (params) => {
      let date = null;
      if (params.row.created_at !== null && params.row.created_at !== "") {
        date = IsoToLocalTime(params.row.created_at);
      }
      return date !== null ? date : "-";
    },
  },
  {
    field: "created_by",
    headerName: ColumnHeader("Created By"),
    sortable: false,
    width: 200,
    renderCell: (params) => {
      if (params.row.created_by !== null) {
        return dataRenderer(params.row.created_by.name);
      } else {
        return "-";
      }
    },
  },
  {
    field: "deactivated_on",
    headerName: ColumnHeader("Deactivated On"),
    sortable: false,
    width: 200,
    renderCell: (params) => {
      let date = null;
      if (
        params.row.deactivated_on !== null &&
        params.row.deactivated_on !== ""
      ) {
        date = IsoToLocalTime(params.row.deactivated_on);
      }
      return date !== null ? date : "-";
    },
  },
  {
    field: "deactivated_by",
    headerName: ColumnHeader("Deactivated By"),
    sortable: false,
    width: 200,
    renderCell: (params) => {
      if (params.row.deactivated_by !== null) {
        return dataRenderer(params.row.deactivated_by.name);
      } else {
        return "-";
      }
    },
  },
];
//*****************************END******************************** */

//********************Web Forms column headers********************* */
const WebFormsColumnHeaders = [
  {
    field: "name",
    headerName: "Name",
    sortable: false,
    width: 200,
    cellRenderer: (params) => {
      return dataRenderer(params.data.name);
    },
  },
  {
    field: "created_at",
    headerName: "Created At",
    sortable: false,
    width: 200,
    cellRenderer: (params) => {
      let date = null;
      if (params.data.created_at !== null && params.data.created_at !== "") {
        date = IsoToLocalTime(params.data.created_at);
      }
      return date !== null ? date : "-";
    },
  },
  {
    field: "created_by",
    headerName: "Created By",
    sortable: false,
    width: 200,
    cellRenderer: (params) => {
      if (params.data.created_by !== null) {
        return dataRenderer(params.data.created_by.name);
      } else {
        return "-";
      }
    },
  },
];
//*****************************END******************************** */

//********************Project Administration Smart Views column headers********************* */
const ProjectAdminSmartViewColumnHeaders = [
  {
    field: "sequence",
    headerName: "Sequence",
    rowDrag: true,
    width: 130,
    cellRenderer: (params) => {
      return dataRenderer(params.data.sequence);
    },
  },

  {
    field: "name",
    headerName: "Tab Name",
    cellRenderer: (params) => {
      if (params.data.tab !== null) {
        return dataRenderer(params.data.tab.name);
      } else {
        return "-";
      }
    },
  },
  {
    field: "view_groups",
    headerName: "View Groups",
    autoHeight: true,
    cellRenderer: (params) => {
      let list = params.data.tab.viewgroups;
      if (list.length > 0) {
        return (
          <Box
            sx={{ display: "flex", flexDirection: "column", paddingY: "8px" }}
          >
            {list.map((e) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "4px",
                    alignItems: "flex-start",
                  }}
                >
                  <FiberManualRecord
                    style={{
                      width: "12px",
                      height: "12px",
                      color: "gray",
                      marginTop: "4px",
                    }}
                  />

                  <Typography style={{ wordBreak: "break-word" }}>
                    {e}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        );
      } else {
        return "-";
      }
    },
  },
];
//*****************************END******************************** */

//********************Project Administration View Groups column headers********************* */
const ProjectAdminViewGroupsColumnHeaders = [
  {
    field: "name",
    headerName: "GROUP NAME",
    cellRenderer: (params) => {
      if (params.data.tab !== null) {
        return dataRenderer(params.data.name);
      } else {
        return "-";
      }
    },
  },
];
//*****************************END******************************** */

//************************deals tab product column headers******************** */

const dealsTabProductsColumnHeaders = [
  {
    field: "rate",
    headerName: "RATE",

    width: 130,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.rate);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "quantity",
    headerName: "QUANTITY",

    width: 130,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.quantity);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "amount",
    headerName: "AMOUNT",

    minWidth: 130,
    resizable: false,
    flex: 1,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.amount);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************Contacts page column headers******************** */

const ContactLeadsPageColumnHeaders = [
  {
    field: "flid",
    headerName: "ID",

    width: 100,

    cellRenderer: (params) => {
      let value = dataRenderer(params.data.flid);
      return (
        <>
          <Tooltip title={value}>
            <TextHighlight>{value}</TextHighlight>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "deal",
    headerName: "DEAL NAME",

    width: 150,

    cellRenderer: (params) => {
      let value = params.data.deal !== null ? params.data.deal["name"] : "";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">
              {value !== "" ? value : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_stage",
    headerName: "LEAD STAGE",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.lead_stage);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_status",
    headerName: "LEAD STATUS",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.lead_status);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_owner",
    headerName: "LEAD OWNER",

    width: 150,

    cellRenderer: (params) => {
      let value =
        params.data.lead_owner !== null ? params.data.lead_owner["name"] : "";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">
              {value !== "" ? value : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "last_contacted_on",
    headerName: "LAST CONTACTED ON",

    width: 150,

    cellRenderer: (params) => {
      let value =
        params.data.last_contacted_on !== null &&
        params.data.last_contacted_on !== ""
          ? IsoToLocalTime(params.data.last_contacted_on)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

const CustomerLeadsColumnHeaders = [
  {
    field: "flid",
    headerName: "ID",
    resizable: false,
    width: 100,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.flid);
      return (
        <>
          <Tooltip title={value}>
            <TextHighlight>{value}</TextHighlight>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "deal",
    headerName: "DEAL NAME",

    width: 150,

    cellRenderer: (params) => {
      let value = params.data.deal !== null ? params.data.deal["name"] : "";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">
              {value !== "" ? value : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_stage",
    headerName: "LEAD STAGE",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.lead_stage);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_status",
    headerName: "LEAD STATUS",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.lead_status);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_owner",
    headerName: "LEAD OWNER",

    width: 150,

    cellRenderer: (params) => {
      let value =
        params.data.lead_owner !== null ? params.data.lead_owner["name"] : "";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">
              {value !== "" ? value : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "last_contacted_on",
    headerName: "LAST CONTACTED ON",

    width: 150,

    cellRenderer: (params) => {
      let value =
        params.data.last_contacted_on !== null &&
        params.data.last_contacted_on !== ""
          ? IsoToLocalTime(params.data.last_contacted_on)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************Leads Deals Tab column headers******************** */

const dealsColumnHeaders = [
  {
    field: "name",
    headerName: "Deal Name",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.name)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "deal_value",
    headerName: "Deal Value",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.deal_value}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.deal_value)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "deal_currency",
    headerName: "Deal Currency",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.deal_currency}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.deal_currency)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "expected_close_date",
    headerName: "Expected Close Date",

    width: 150,
    cellRenderer: (params) => {
      let date =
        params.data.expected_close_date !== null &&
        params.data.expected_close_date !== ""
          ? IsoToLocalDate(params.data.expected_close_date)
          : "-";
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">{date}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "priority",
    headerName: "Deal Priority",

    flex: 1,
    minWidth: 150,
    resizable: false,
    cellRenderer: (params) => {
      if (params.data.priority.toLowerCase() === "low") {
        return <Chip color="default" label={params.data.priority} />;
      } else if (params.data.priority.toLowerCase() === "medium") {
        return <Chip color="warning" label={params.data.priority} />;
      } else if (params.data.priority.toLowerCase() === "high") {
        return <Chip color="success" label={params.data.priority} />;
      }
    },
  },
];

//**************************************Deals page column headers******************* */
const dealsPageColumnHeaders = [
  {
    field: "name",
    headerName: "DEAL NAME",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.name)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "deal_value",
    headerName: "DEAL VALUE",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.deal_value}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.deal_value)}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "expected_close_date",
    headerName: "EXPECTED CLOSE DATE",

    width: 150,
    cellRenderer: (params) => {
      let date =
        params.data.expected_close_date !== null &&
        params.data.expected_close_date !== ""
          ? IsoToLocalDate(params.data.expected_close_date)
          : "-";
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">{date}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "deal_owner",
    headerName: "DEAL OWNER",

    width: 150,
    cellRenderer: (params) => {
      let value =
        params.data.deal_owner !== null
          ? dataRenderer(params.data.deal_owner.name)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "priority",
    headerName: "DEAL PRIORITY",

    flex: 1,
    minWidth: 150,
    resizable: false,
    cellRenderer: (params) => {
      if (params.data.priority.toLowerCase() === "low") {
        return (
          <Chip
            style={{ fontWeight: "bold" }}
            color="default"
            label={params.data.priority}
          />
        );
      } else if (params.data.priority.toLowerCase() === "medium") {
        return (
          <Chip
            style={{ fontWeight: "bold" }}
            color="warning"
            label={params.data.priority}
          />
        );
      } else if (params.data.priority.toLowerCase() === "high") {
        return (
          <Chip
            style={{ fontWeight: "bold" }}
            color="success"
            label={params.data.priority}
          />
        );
      }
    },
  },
];
//***************************************END************************************** */

//**************************************Files page column headers******************* */
const filesPageColumnHeaders = [
  {
    field: "sl_no",
    headerName: "S.NO",
    width: 70,

    valueGetter: "node.rowIndex + 1",
  },
  {
    field: "name",
    headerName: "NAME",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span
              className="csutable-cell-trucate"
              style={{ cursor: "pointer" }}
            >
              {dataRenderer(params.data.name)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

const FolderFilesColumnHeaders = [
  {
    field: "title",
    headerName: "TITLE",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.title);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "desc",
    headerName: "DESCRIPTION",

    width: 300,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.desc);

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_by",
    headerName: "CREATED BY",

    width: 200,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.created_by.name);

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: "CREATED ON",

    width: 150,
    cellRenderer: (params) => {
      let value =
        params.data.created_at !== null
          ? IsoToLocalTime(params.data.created_at)
          : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "document_type",
    headerName: "DOCUMENT TYPE",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.document_type);

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//***************************************END************************************** */

//**************************************Predefined Deals page column headers******************* */
const predefinedDealsPageColumnHeaders = [
  {
    field: "name",
    headerName: "DEAL NAME",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.name)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "deal_value",
    headerName: "DEAL VALUE",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.deal_value}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.deal_value)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "deal_value_currency",
    headerName: "DEAL CURRENCY",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.deal_value_currency}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.deal_value_currency)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "creator",
    headerName: "CREATOR",

    width: 150,
    cellRenderer: (params) => {
      let value =
        params.data.creator !== null
          ? dataRenderer(params.data.creator.name)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "priority",
    headerName: "DEAL PRIORITY",

    flex: 1,
    minWidth: 150,
    resizable: false,
    cellRenderer: (params) => {
      if (params.data.priority.toLowerCase() === "low") {
        return (
          <Chip
            style={{ fontWeight: "bold" }}
            color="default"
            label={params.data.priority}
          />
        );
      } else if (params.data.priority.toLowerCase() === "medium") {
        return (
          <Chip
            style={{ fontWeight: "bold" }}
            color="warning"
            label={params.data.priority}
          />
        );
      } else if (params.data.priority.toLowerCase() === "high") {
        return (
          <Chip
            style={{ fontWeight: "bold" }}
            color="success"
            label={params.data.priority}
          />
        );
      }
    },
  },
];
//***************************************END************************************** */

//*******************************Files Tab Column headers used in contacts,customer details page */
const filesTabColumnHeaders = [
  {
    field: "title",
    headerName: "TITLE",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.title);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "desc",
    headerName: "DESCRIPTION",

    width: 300,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.desc);

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "uploaded_by",
    headerName: "UPLOADED BY",

    width: 200,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.uploaded_by.name);

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: "UPLOADED ON",

    width: 150,
    cellRenderer: (params) => {
      let value =
        params.data.created_at !== null
          ? IsoToLocalTime(params.data.created_at)
          : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "file_type",
    headerName: "TYPE",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.file_type);

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];
//*********************************END******************************************* */

//********************Table widget test column headers******************* */
const TableWidgetTestColumnHeader = [
  {
    field: "column1",
    headerName: "Column1",
    sortable: false,
  },
  {
    field: "column2",
    headerName: "Column2",
    sortable: false,
  },
  {
    field: "column3",
    headerName: "Column3",
    sortable: false,
  },
  {
    field: "column4",
    headerName: "Column4",
    sortable: false,
  },
  {
    field: "column5",
    headerName: "Column5",
    sortable: false,
  },
  {
    field: "column6",
    headerName: "Column6",
    sortable: false,
  },
  {
    field: "column7",
    headerName: "Column7",
    sortable: false,
  },
];
//*****************************END************************************ */

//********************************landing page text contents***********************************

const LandingPageCardData = [
  {
    color: "#E5F8F4",
    img: img1,
    title: "Marketing",
    desc: "Effortlessly generate leads from all channels with LeadPixie",
  },
  {
    color: "#FFFAE5",
    img: img2,
    title: "Sales",
    desc: "Efficiently prioritize, distribute and manage leads for maximum results",
  },
  {
    color: "#FFFAE5",
    img: img3,
    title: "Startups",
    desc: "Easy to use, affordable and flexible, LeadPixie grows with your startup",
  },
  {
    color: "#E5F8F4",
    img: img4,
    title: "Revenue",
    desc: "Streamline your lead management process and watch your revenue soar",
  },
];

const SubscriptionCardData = [
  {
    tag: "Lite",
    desc: "Automate and optimize your sales cycle",
    price: "499",
    annual_tag: "/user/month, billed annually",
    features: {
      title: "Everything comes in Lite",
      keys: [
        "Lead Tracking",
        "Lead Scoring",
        "Custom Fields",
        "Predefine Reports",
        "Advanced Filters",
        "Lead Activities",
      ],
    },
  },
  {
    tag: "Pro",
    desc: "Manage multiple sales teams, avoid repetitive work and spend more time selling",
    price: "799",
    annual_tag: "/user/month, billed annually",
    tag_line: "All prices are exclusive of applicable taxes",
    features: {
      title: "Everything in Lite, plus",
      keys: [
        "Role Based Access",
        "Custom Tabs",
        "Campaigns",
        "Lead Segmentation",
        "Lead Distribution",
        "Custom Reports",
        "Mobile APP",
      ],
    },
  },
  {
    tag: "Ultimate",
    desc: "Advanced customization and in-depth Analytics to accelerate growth",
    // price: "4,999",
    annual_tag: "/user/month, billed annually",
    month_tag: "Contact us for a quote",
    features: {
      title: "Everything in Lite, plus",
      keys: [
        "Workflows",
        "Dynamic Forms",
        "AI Powered Insights",
        "Account Management",
        "Digital Marketing",
      ],
    },
  },
];

const LandingPageCarouselData = [
  {
    desc: "“The best part of LeadPixie is the ease and speed of customization for any business process. It is one of the lightest and easiest lead management software out there, and I would highly recommend it for teams of any size!”",
    name: "Vikram H",
    post: "Business Head, FB",
  },
  {
    desc: "“Our team was struggling with manual lead management processes and losing valuable leads in the process. With LeadPixie, we now have all of our leads in one centralized location and can prioritize them based on their potential value to our business. We've already seen an increase in closed deals and revenue thanks to this platform.”",
    name: "Harish Rao",
    post: "SFS",
  },
  {
    desc: "“We were hesitant to switch to a new lead management platform, but LeadPixie has exceeded our expectations. The integration with our existing tools has made the transition seamless and the custom workflows have allowed us to streamline our processes like never before. Our sales team is now able to close more deals and focus on what they do best: selling.”",
    name: "Praveen",
    post: "-",
  },
];
const FooterLinks = [
  {
    label: getLocalizedText("privacy_policy"),
    link: "https://d2dsj7nm5j2dtv.cloudfront.net/pp.html",
  },
  {
    label: getLocalizedText("terms_conditions"),
    link: "https://d2dsj7nm5j2dtv.cloudfront.net/tos.html",
  },
];

const daysList = [
  { name: "Monday", value: "monday" },
  { name: "Tuesday", value: "tuesday" },
  { name: "Wednesday", value: "wednesday" },
  { name: "Thursday", value: "thursday" },
  { name: "Friday", value: "friday" },
  { name: "Saturday", value: "saturday" },
  { name: "Sunday", value: "sunday" },
];

const productTypeList = ["Inventory", "Non-Inventory", "Service"];
const dealPriorityList = ["High", "Medium", "Low"];
const currencylist = [
  { name: "Afghan Afghani", code: "AFA" },
  { name: "Albanian Lek", code: "ALL" },
  { name: "Algerian Dinar", code: "DZD" },
  { name: "Angolan Kwanza", code: "AOA" },
  { name: "Argentine Peso", code: "ARS" },
  { name: "Armenian Dram", code: "AMD" },
  { name: "Aruban Florin", code: "AWG" },
  { name: "Australian Dollar", code: "AUD" },
  { name: "Azerbaijani Manat", code: "AZN" },
  { name: "Bahamian Dollar", code: "BSD" },
  { name: "Bahraini Dinar", code: "BHD" },
  { name: "Bangladeshi Taka", code: "BDT" },
  { name: "Barbadian Dollar", code: "BBD" },
  { name: "Belarusian Ruble", code: "BYR" },
  { name: "Belgian Franc", code: "BEF" },
  { name: "Belize Dollar", code: "BZD" },
  { name: "Bermudan Dollar", code: "BMD" },
  { name: "Bhutanese Ngultrum", code: "BTN" },
  { name: "Bitcoin", code: "BTC" },
  { name: "Bolivian Boliviano", code: "BOB" },
  { name: "Bosnia-Herzegovina Convertible Mark", code: "BAM" },
  { name: "Botswanan Pula", code: "BWP" },
  { name: "Brazilian Real", code: "BRL" },
  { name: "British Pound Sterling", code: "GBP" },
  { name: "Brunei Dollar", code: "BND" },
  { name: "Bulgarian Lev", code: "BGN" },
  { name: "Burundian Franc", code: "BIF" },
  { name: "Cambodian Riel", code: "KHR" },
  { name: "Canadian Dollar", code: "CAD" },
  { name: "Cape Verdean Escudo", code: "CVE" },
  { name: "Cayman Islands Dollar", code: "KYD" },
  { name: "CFA Franc BCEAO", code: "XOF" },
  { name: "CFA Franc BEAC", code: "XAF" },
  { name: "CFP Franc", code: "XPF" },
  { name: "Chilean Peso", code: "CLP" },
  { name: "Chilean Unit of Account", code: "CLF" },
  { name: "Chinese Yuan", code: "CNY" },
  { name: "Colombian Peso", code: "COP" },
  { name: "Comorian Franc", code: "KMF" },
  { name: "Congolese Franc", code: "CDF" },
  { name: "Costa Rican Colón", code: "CRC" },
  { name: "Croatian Kuna", code: "HRK" },
  { name: "Cuban Convertible Peso", code: "CUC" },
  { name: "Czech Republic Koruna", code: "CZK" },
  { name: "Danish Krone", code: "DKK" },
  { name: "Djiboutian Franc", code: "DJF" },
  { name: "Dominican Peso", code: "DOP" },
  { name: "East Caribbean Dollar", code: "XCD" },
  { name: "Egyptian Pound", code: "EGP" },
  { name: "Eritrean Nakfa", code: "ERN" },
  { name: "Estonian Kroon", code: "EEK" },
  { name: "Ethiopian Birr", code: "ETB" },
  { name: "Euro", code: "EUR" },
  { name: "Falkland Islands Pound", code: "FKP" },
  { name: "Fijian Dollar", code: "FJD" },
  { name: "Gambian Dalasi", code: "GMD" },
  { name: "Georgian Lari", code: "GEL" },
  { name: "German Mark", code: "DEM" },
  { name: "Ghanaian Cedi", code: "GHS" },
  { name: "Gibraltar Pound", code: "GIP" },
  { name: "Greek Drachma", code: "GRD" },
  { name: "Guatemalan Quetzal", code: "GTQ" },
  { name: "Guinean Franc", code: "GNF" },
  { name: "Guyanaese Dollar", code: "GYD" },
  { name: "Haitian Gourde", code: "HTG" },
  { name: "Honduran Lempira", code: "HNL" },
  { name: "Hong Kong Dollar", code: "HKD" },
  { name: "Hungarian Forint", code: "HUF" },
  { name: "Icelandic Króna", code: "ISK" },
  { name: "Indian Rupee", code: "INR" },
  { name: "Indonesian Rupiah", code: "IDR" },
  { name: "Iranian Rial", code: "IRR" },
  { name: "Iraqi Dinar", code: "IQD" },
  { name: "Israeli New Sheqel", code: "ILS" },
  { name: "Italian Lira", code: "ITL" },
  { name: "Jamaican Dollar", code: "JMD" },
  { name: "Japanese Yen", code: "JPY" },
  { name: "Jordanian Dinar", code: "JOD" },
  { name: "Kazakhstani Tenge", code: "KZT" },
  { name: "Kenyan Shilling", code: "KES" },
  { name: "Kuwaiti Dinar", code: "KWD" },
  { name: "Kyrgystani Som", code: "KGS" },
  { name: "Laotian Kip", code: "LAK" },
  { name: "Latvian Lats", code: "LVL" },
  { name: "Lebanese Pound", code: "LBP" },
  { name: "Lesotho Loti", code: "LSL" },
  { name: "Liberian Dollar", code: "LRD" },
  { name: "Libyan Dinar", code: "LYD" },
  { name: "Litecoin", code: "LTC" },
  { name: "Lithuanian Litas", code: "LTL" },
  { name: "Macanese Pataca", code: "MOP" },
  { name: "Macedonian Denar", code: "MKD" },
  { name: "Malagasy Ariary", code: "MGA" },
  { name: "Malawian Kwacha", code: "MWK" },
  { name: "Malaysian Ringgit", code: "MYR" },
  { name: "Maldivian Rufiyaa", code: "MVR" },
  { name: "Mauritanian Ouguiya", code: "MRO" },
  { name: "Mauritian Rupee", code: "MUR" },
  { name: "Mexican Peso", code: "MXN" },
  { name: "Moldovan Leu", code: "MDL" },
  { name: "Mongolian Tugrik", code: "MNT" },
  { name: "Moroccan Dirham", code: "MAD" },
  { name: "Mozambican Metical", code: "MZM" },
  { name: "Myanmar Kyat", code: "MMK" },
  { name: "Namibian Dollar", code: "NAD" },
  { name: "Nepalese Rupee", code: "NPR" },
  { name: "Netherlands Antillean Guilder", code: "ANG" },
  { name: "New Taiwan Dollar", code: "TWD" },
  { name: "New Zealand Dollar", code: "NZD" },
  { name: "Nicaraguan Córdoba", code: "NIO" },
  { name: "Nigerian Naira", code: "NGN" },
  { name: "North Korean Won", code: "KPW" },
  { name: "Norwegian Krone", code: "NOK" },
  { name: "Omani Rial", code: "OMR" },
  { name: "Pakistani Rupee", code: "PKR" },
  { name: "Panamanian Balboa", code: "PAB" },
  { name: "Papua New Guinean Kina", code: "PGK" },
  { name: "Paraguayan Guarani", code: "PYG" },
  { name: "Peruvian Nuevo Sol", code: "PEN" },
  { name: "Philippine Peso", code: "PHP" },
  { name: "Polish Zloty", code: "PLN" },
  { name: "Qatari Rial", code: "QAR" },
  { name: "Romanian Leu", code: "RON" },
  { name: "Russian Ruble", code: "RUB" },
  { name: "Rwandan Franc", code: "RWF" },
  { name: "Salvadoran Colón", code: "SVC" },
  { name: "Samoan Tala", code: "WST" },
  { name: "São Tomé and Príncipe Dobra", code: "STD" },
  { name: "Saudi Riyal", code: "SAR" },
  { name: "Serbian Dinar", code: "RSD" },
  { name: "Seychellois Rupee", code: "SCR" },
  { name: "Sierra Leonean Leone", code: "SLL" },
  { name: "Singapore Dollar", code: "SGD" },
  { name: "Slovak Koruna", code: "SKK" },
  { name: "Solomon Islands Dollar", code: "SBD" },
  { name: "Somali Shilling", code: "SOS" },
  { name: "South African Rand", code: "ZAR" },
  { name: "South Korean Won", code: "KRW" },
  { name: "South Sudanese Pound", code: "SSP" },
  { name: "Special Drawing Rights", code: "XDR" },
  { name: "Sri Lankan Rupee", code: "LKR" },
  { name: "St. Helena Pound", code: "SHP" },
  { name: "Sudanese Pound", code: "SDG" },
  { name: "Surinamese Dollar", code: "SRD" },
  { name: "Swazi Lilangeni", code: "SZL" },
  { name: "Swedish Krona", code: "SEK" },
  { name: "Swiss Franc", code: "CHF" },
  { name: "Syrian Pound", code: "SYP" },
  { name: "Tajikistani Somoni", code: "TJS" },
  { name: "Tanzanian Shilling", code: "TZS" },
  { name: "Thai Baht", code: "THB" },
  { name: "Tongan Pa'anga", code: "TOP" },
  { name: "Trinidad & Tobago Dollar", code: "TTD" },
  { name: "Tunisian Dinar", code: "TND" },
  { name: "Turkish Lira", code: "TRY" },
  { name: "Turkmenistani Manat", code: "TMT" },
  { name: "Ugandan Shilling", code: "UGX" },
  { name: "Ukrainian Hryvnia", code: "UAH" },
  { name: "United Arab Emirates Dirham", code: "AED" },
  { name: "Uruguayan Peso", code: "UYU" },
  { name: "US Dollar", code: "USD" },
  { name: "Uzbekistan Som", code: "UZS" },
  { name: "Vanuatu Vatu", code: "VUV" },
  { name: "Venezuelan BolÃvar", code: "VEF" },
  { name: "Vietnamese Dong", code: "VND" },
  { name: "Yemeni Rial", code: "YER" },
  { name: "Zambian Kwacha", code: "ZMK" },
  { name: "Zimbabwean dollar", code: "ZWL" },
];

const fieldBlockList = [
  {
    label: "Short Text",
    structure: {
      id: "id1",
      name: "short-text",
      attributes: {
        minCharacters: "",
        maxCharacters: "",
        required: false,
        nextBtnLabel: false,
        classnames: "",
        // placeholder: "",

        defaultValue: "",

        label: "",
        customHTML: "",
        layout: "stack",
        attachmentFocalPoint: {
          x: 0.5,
          y: 0.5,
        },
        attachmentFancyBorderRadius: false,
        attachmentBorderRadius: "0px",
        attachmentMaxWidth: "none",
      },
    },
  },
  {
    label: "Long Text",
    structure: {
      id: "id2",
      name: "long-text",
      attributes: {
        minCharacters: "",
        maxCharacters: "",
        required: false,
        nextBtnLabel: false,
        classnames: "",
        // placeholder: "",

        defaultValue: "",

        label: "",
        customHTML: "",
        layout: "stack",
        attachmentFocalPoint: {
          x: 0.5,
          y: 0.5,
        },
        attachmentFancyBorderRadius: false,
        attachmentBorderRadius: "0px",
        attachmentMaxWidth: "none",
      },
    },
  },
  {
    label: "Number",
    structure: {
      id: "id3",
      name: "number",
      attributes: {
        setMax: false,
        max: 0,
        setMin: false,
        min: 0,
        required: false,
        nextBtnLabel: false,
        classnames: "",
        // placeholder: "",
        defaultValue: "",

        label: "",
        customHTML: "",
        layout: "stack",
        attachmentFocalPoint: {
          x: 0.5,
          y: 0.5,
        },
        attachmentFancyBorderRadius: false,
        attachmentBorderRadius: "0px",
        attachmentMaxWidth: "none",
      },
    },
  },
  {
    label: "Email",
    structure: {
      id: "id4",
      name: "email",
      attributes: {
        required: false,
        nextBtnLabel: false,
        classnames: "",
        // placeholder: "",

        defaultValue: "",

        label: "",
        customHTML: "",
        layout: "stack",
        attachmentFocalPoint: {
          x: 0.5,
          y: 0.5,
        },
        attachmentFancyBorderRadius: false,
        attachmentBorderRadius: "0px",
        attachmentMaxWidth: "none",
      },
    },
  },
];

export {
  content,
  Auth,
  leadActivityColumns,
  callsColumns,
  businessContactColumns,
  notesContactColumns,
  LandingPageCardData,
  SubscriptionCardData,
  LandingPageCarouselData,
  FooterLinks,
  ImportHistoryColumns,
  TargetPageColumns,
  TargetDetailsColumns,
  leadFileColumns,
  MemberSettingColumns,
  ProjectSettingColumns,
  TeamSettingColumns,
  NewUSerSettingColumns,
  AdminStatusColumns,
  AdminSourceColumns,
  AdminStageColumns,
  AdminStatusToStageColumns,
  AdminViewColumns,
  AdminColumnsGroup,
  ReportListColumns,
  JobListColumns,
  SegmentListColumns,
  SupportSectionColumns,
  billingSectionColumns,
  CheckInColumns,
  daysList,
  RawLeadsColumnHeaders,
  RawLeadsImportHistoryColumns,
  MoveRawLeadsResponseColumnHeaders,
  ruleList,
  createImportRuleList,
  updateImportRuleList,
  AutoDialerCampaignColumnHeaders,
  CampaignListColumnsHeaders,
  OzonetelDialerAgentListColumnHeaders,
  IpConfigColumnHeaders,
  RawLeadsImportHistoryTableColumns,
  RawLeadExportJobColumns,
  TableWidgetTestColumnHeader,
  ImportHistoryListingColumns,
  fieldBlockList,
  WebFormsColumnHeaders,
  ProjectAdminSmartViewColumnHeaders,
  productTypeList,
  dealsColumnHeaders,
  dealsPageColumnHeaders,
  dealPriorityList,
  currencylist,
  ContactLeadsPageColumnHeaders,
  dealsTabProductsColumnHeaders,
  ProjectAdminViewGroupsColumnHeaders,
  CustomerLeadsColumnHeaders,
  filesTabColumnHeaders,
  predefinedDealsPageColumnHeaders,
  filesPageColumnHeaders,
  FolderFilesColumnHeaders,
};
