import React, { useEffect, useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { leadActivityColumns } from "../../Db";
import spinner from "../../assets/images/gifs/spinner.gif";
import { DataGrid } from "@mui/x-data-grid";
import { pageSize } from "../../Config";
import Pagination from "../pagination/Pagination";
import { getLeadActivityApi } from "../../Api";
import PageSizeDropdown from "../page_size_dropdown/PageSizeDropdown";
import PaginationComponent from "../pagination/PaginationComponent";
import { AddBoxOutlined } from "@material-ui/icons";
import ActivityLogModal from "../activity_log_modal/ActivityLogModal";
import analytics from "../../Analytics";
import { Drawer } from "@material-ui/core";
import ViewActivityInfoPanel from "../view_activity_info_panel/ViewActivityInfoPanel";

const Container = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 234px);`};

  position: relative;
  /* margin-top: 70px; */
`;
const Wrapper = styled.div`
  display: flex;
`;

const DetailsWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;

const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  width: 100%;
  ${`min-height: calc(100vh - 148px);`};
`;

const AddBtnWrapper = styled.div`
  position: absolute;
  right: 30px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;

const UserActivityTab = ({ uid, lid }) => {
  const [count, setCount] = useState(0);
  const [row, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState(pageSize);
  const [open, setOpen] = useState(false);
  const [openActivityInfoPanel, setOpenACtivityInfoPanel] = useState(false);
  const [selectedActivityData, setSelectedActivityData] = useState({});

  const getActivityList = async ({ page, page_size }) => {
    setRow([]);
    let response = await getLeadActivityApi({
      leadID: lid,
      page: page,
      page_size: page_size,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
  };
  const init = async () => {
    await getActivityList({ page: 0, page_size: pageSize });
    setLoading(false);
  };
  useEffect(() => {
    analytics.triggerEvent(
      4625000052,
      "activities_tab_load",
      "lead_details_page",
      "",
      {}
    );
    init();
  }, []);
  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setPage(0);
    setLoading(true);
    await getActivityList({ page: 0, page_size: e.target.value });
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);
    setLoading(true);
    await getActivityList({ page: page - 1, page_size: size });
    setLoading(false);
  };
  return (
    <>
      <Container>
        <Wrapper>
          <DetailsWrapper>
            <TableWrapper>
              <DataGrid
                autoHeight={true}
                rows={row}
                columns={leadActivityColumns}
                pageSize={size}
                checkboxSelection={false}
                style={{ cursor: "pointer" }}
                hideFooter={true}
                loading={loading}
                disableColumnMenu={true}
                onRowClick={(row) => {
                  setSelectedActivityData(row.row);
                  setOpenACtivityInfoPanel(true);
                }}
              />
            </TableWrapper>
            <PaginationComponent
              page_no={page}
              row={row}
              page_size={size}
              size={size}
              count={count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </DetailsWrapper>
        </Wrapper>
      </Container>

      {open && <ActivityLogModal open={open} setOpen={setOpen} />}
      {openActivityInfoPanel && (
        <Drawer
          anchor={"right"}
          open={openActivityInfoPanel}
          onClose={() => {
            setOpenACtivityInfoPanel(false);
          }}
        >
          {<ViewActivityInfoPanel data={selectedActivityData} />}
        </Drawer>
      )}
    </>
  );
};

export default observer(UserActivityTab);
