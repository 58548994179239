import axios from "axios";
import moment from "moment";
import { app_id, app_version, device_os, features, pageSize } from "./Config";
import { isUnderWorkSchedule, updateViewList } from "./Functions";
import ApiResult from "./services/apiResult";

import rootStore from "./stores/RootStore";
const api_key = process.env.REACT_APP_API_KEY;
const lead_source = "Website";
const lpp_id = "eef36517-2b4d-4078-9c00-4ad567409aaa";
// const { rootStore.userStore, rootStore.authStore } = rootStore;
let url = process.env.REACT_APP_API_BASE_URL;

//**********landing page form related payload data generator */
const normalFieldList = ["name", "phone", "email", "city"];
const modifyPayloadData = (data) => {
  let normalList = {};
  let customList = {};
  Object.keys(data).map((item) => {
    if (normalFieldList.includes(item)) {
      normalList[item] = data[item];
    } else {
      customList[item] = data[item];
    }
  });
  return { normalList, customList };
};

//********end********************** */

//**********updated columns for filteredcolumns in search page************* */
const getUpdatedColumns = (response_columns) => {
  let allColumnList = rootStore.userStore.AllColumnsList;

  let newColumnsList = [];
  if (response_columns.length > 0 && allColumnList.length > 0) {
    response_columns.map((item) => {
      allColumnList.map((key) => {
        if (key["field"] === item["field"]) {
          newColumnsList.push(key);
        }
      });
    });
  }
  return newColumnsList;
};
//*************************END***************************************** */
const getCommonHeaders = () => {
  let device_id = localStorage.getItem("device_id");
  let headers = {};
  headers["X_TIMESTAMP"] = Math.round(new Date() / 1000);
  headers["X_FEATURES"] = features;
  headers["X_APPID"] = app_id;
  headers["X_APPVERSION"] = app_version;
  headers["X_DEVICEID"] = device_id;
  headers["X_DEVICEOS"] = device_os;
  return headers;
};
const getUserHeaders = () => {
  let headers = getCommonHeaders();
  headers["X_USERID"] = localStorage.getItem("uid");
  headers["X_TOKEN"] = localStorage.getItem("token");
  return headers;
};

const sendOtpPhoneApi = (payload) => {
  return axios({
    method: "post",
    url: `${url}users/sendotp`,
    headers: getCommonHeaders(),
    data: payload,
  });
};
const sendOtpEmailApi = (payload) => {
  return axios({
    method: "post",
    url: `${url}users/sendotpemail`,
    headers: getCommonHeaders(),
    data: payload,
  });
};

const testAPI = () => {
  return axios({
    method: "get",
    url: `http://3.111.11.76:3003/api/test`,
    headers: {
      ...getUserHeaders(),
    },
  });
};

const bookDemoApi = async (data) => {
  let lead_created_at = null;
  lead_created_at = moment().format("YYYY-MM-DD");
  let modifiedData = modifyPayloadData(data);
  try {
    let response = await axios({
      method: "post",
      url: `${url}external/projects/${lpp_id}/leads`,
      headers: {
        ...getCommonHeaders(),
        X_APIKEY: api_key,
      },
      data: {
        ...modifiedData.normalList,
        custom_params: modifiedData.customList,
        lead_source: lead_source,
        lead_created_at: lead_created_at,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};
const getQuoteApi = async (data) => {
  let lead_created_at = null;
  lead_created_at = moment().format("YYYY-MM-DD");
  let modifiedData = modifyPayloadData(data);
  try {
    let response = await axios({
      method: "post",
      url: `${url}external/projects/${lpp_id}/leads`,
      headers: {
        ...getCommonHeaders(),
        X_APIKEY: api_key,
      },
      data: {
        ...modifiedData.normalList,
        custom_params: modifiedData.customList,
        lead_source: lead_source,
        lead_created_at: lead_created_at,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

const submitExternalCampaignForm = async ({ campaign_id, data }) => {
  let lead_created_at = null;
  lead_created_at = moment().format("YYYY-MM-DD");
  let payload_data = {
    payload: {
      ...data,
      lead_source: lead_source,
      lead_created_at: lead_created_at,
    },
  };
  if (data.hasOwnProperty("email")) {
    payload_data["target"] = data["email"];
  } else if (data.hasOwnProperty("phone")) {
    payload_data["target"] = data["phone"];
  }
  try {
    let response = await axios({
      method: "post",
      url: `${url}external/campaigns/${campaign_id}/submit`,
      headers: {
        ...getCommonHeaders(),
        X_APIKEY: api_key,
      },
      data: payload_data,
    });
    return true;
  } catch (error) {
    return false;
  }
};
const userLoginPhoneApi = async (payload) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/login`,
      headers: getCommonHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const userLoginEmailApi = async (payload) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/login/email`,
      headers: getCommonHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getProjectApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/my_projects`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const getAdminProjectApi = async () => {
  return await axios({
    method: "get",
    url: `${url}admin/projects/`,
    headers: getUserHeaders(),
  });
};
const getAdminUsersApi = async () => {
  return await axios({
    method: "get",
    url: `${url}admin/users/`,
    headers: getUserHeaders(),
  });
};

const enableAdminUserApi = async (id) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/users/${id}/enable`,
      headers: getUserHeaders(),
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const disableAdminUserApi = async ({ userID, queryParams }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/users/${userID}/disable`,
      headers: getUserHeaders(),
      params: queryParams,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getProjectListApi = async () => {
  return await axios({
    method: "get",
    url: `${url}projects/my_projects`,
    headers: getUserHeaders(),
  });
};

const getUserStatus = async (formData) => {
  let uid = localStorage.getItem("uid");

  return await axios({
    method: "post",
    url: `${url}users/${uid}/status/current`,
    headers: getUserHeaders(),
    data: formData,
  });
};

const getTabsApi = (pid) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/tabs`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const getViewGroupsApi = async ({ projectID }) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${projectID}/viewgroups`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getTeamApi = async (pid) => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/my_teams`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const getRoleApi = async (pid) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/me/v1`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getRoleApiAdmin = (pid) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/me/v1`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
    },
  });
};

const getDataApi = async () => {
  let uid = localStorage.getItem("uid");
  let response = await axios({
    method: "get",
    url: `${url}users/${uid}/`,
    headers: getUserHeaders(),
  });
  rootStore.userStore.createUser({
    name: response.data.name,
    email: response.data.email,
    phone: response.data.phone,
    role: response.data.role,
  });
};
const getProfileDataApi = () => {
  let uid = localStorage.getItem("uid");
  return axios({
    method: "get",
    url: `${url}users/${uid}/`,
    headers: getUserHeaders(),
  });
};
const getStatusApi = async () => {
  let pid = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/lead_statuses/`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getStagesApi = async () => {
  let pid = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/lead_stages/details`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getStageStatusesApi = async () => {
  let pid = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/stage_statuses`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const getListApi = async ({ slug }) => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}${slug}`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

const getAllColsApi = (pid) => {
  return axios({
    method: "get",
    url: `${url}projects/${pid}/columns`,
    headers: getUserHeaders(),
  });
};

const getFilterColsApi = async () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/columns_for_filters`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    let updated_columns = getUpdatedColumns(response.data);
    rootStore.userStore.formatFilterColumnHeaders(updated_columns);
  } catch (error) {
    console.log(error);
  }
};
const getLeadsApi = async ({ page_size, page, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/tab?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
const getFilteredLeadsApi = ({ page_size, page_no }) => {
  let filters = rootStore.userStore.globalFilters;
  let accId = rootStore.authStore.accountId;

  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/filter?offset=${
      page_no * page_size
    }&limit=${page_size}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: filters,
  });
};

const uploadCsvApi = (formData) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/import_v1`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: formData,
  });
};
const submitLeadApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const editLeadApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/${id}/update`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const changeLeadstatusApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/${id}/status/change`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const changeLeadSourceApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/${id}/source/change`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const getSourcesListApi = async () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/lead_sources`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getSummaryApi = ({ start_date, end_date }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/reports/summary`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      start_date: start_date !== null ? start_date : null,
      end_date: end_date !== null ? end_date : null,
    },
  });
};

const getPhoneApi = (lid) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/${lid}/getph`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const getLeadProfileApi = (lid) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/${lid}/show_v1`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const getLeadActivityApi = async ({ leadID, page, page_size }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/activities?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const getLeadCallsApi = async ({ lid, page, page_size }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${lid}/calldetails?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};
const getLeadFilesApi = async ({ id, page, page_size }) => {
  let pid = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${id}/files?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getLeadPredefinedFilesApi = async () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/files/predefined`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const submitCallApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/${id}/submit_call_log`,
    headers: {
      "Content-Type": "multipart/form-data",
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};
const submitFileApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/upload_file`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getLeadNotesApi = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/${id}/notes`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const createLeadNoteApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/${id}/notes`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const userLogoutApi = () => {
  let uid = localStorage.getItem("uid");
  return axios({
    method: "post",
    url: `${url}users/${uid}/logout`,
    headers: getUserHeaders(),
  });
};

const leadAssignApi = ({ ids, member, erase_history }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/assign`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      lead_ids: ids,
      assign_to: member,
      erase_history: erase_history,
    },
  });
};

const deleteMultipleLeadsApi = async ({ idList }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/delete`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: {
        lead_ids: idList,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const deleteLeadApi = async ({ leadID }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/delete`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getMembersApi = () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "get",
    url: `${url}projects/${pid}/userdetails_to_assign`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const getTargetMembersApi = (pid) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/userdetails_to_assign`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getAdminMembersApi = (pid) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}admin/projects/${pid}/members`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getAdminSourcesApi = (pid) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}admin/projects/${pid}/sources`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getAdminStagesApi = (pid) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}admin/projects/${pid}/stages`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const getProjectAdministrationReOrederedStagesApi = async ({
  projectId,
  payload,
}) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/stages/reorder`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getAdminStatusesApi = (pid) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}admin/projects/${pid}/statuses`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getAdminStatusToStageApi = (pid) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}admin/projects/${pid}/status_stages`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getAdminColumnsApi = ({ pid, page, page_size }) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}admin/projects/${pid}/columns?offset=${
      page * page_size
    }&limit=${page_size}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getSummaryChartData = ({ start_date, end_date }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/reports/status/summary`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      start_date: start_date !== null ? start_date : null,
      end_date: end_date !== null ? end_date : null,
    },
  });
};
const getSummaryBarData = ({ start_date, end_date }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/reports/owner_status/summary`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      start_date: start_date !== null ? start_date : null,
      end_date: end_date !== null ? end_date : null,
    },
  });
};
const getImportHistoryApi = ({ page, page_size }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/import/history?offset=${
      page * page_size
    }&limit=${page_size}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const refreshImportRowApi = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/imports/${id}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const getTargetsApi = ({ page }) => {
  let pid = rootStore.authStore.projectId;
  let page_size = pageSize;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/targets?offset=${
      page * page_size
    }&limit=${page_size}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const sendTargetsApi = (data) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/targets`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};
const getNotificationsApi = () => {
  let uid = localStorage.getItem("uid");
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}users/${uid}/notifications`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
    },
  });
};
const getNotificationsDetailApi = ({ page_size, page_no, data }) => {
  let uid = localStorage.getItem("uid");
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}users/${uid}/notifications/details?offset=${
      page_no * page_size
    }&limit=${page_size}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
    },
    data: data,
  });
};
const editUsersApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/users/${id}/edit`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const addUsersApi = ({ data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/users`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const addNewProjectUserApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/users`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const editProjectApi = ({ id, data }) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/edit`,
    headers: {
      ...getUserHeaders(),
      X_ACCOUNTID: accId,
    },
    data: data,
  });
};
const editProjectWorkScheduleApi = ({ id, data }) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/edit/schedule`,
    headers: {
      ...getUserHeaders(),
      X_ACCOUNTID: accId,
    },
    data: data,
  });
};

const getAdminProjectDataApi = (id) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}admin/projects/${id}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getAdminTeamsApi = async ({ projectID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${projectID}/teams`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const editTeamApi = async ({ projectID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/teams/edit`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const addTeamApi = async ({ projectID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/teams/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const editSourceApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/sources/edit`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const addSourceApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/sources/add`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const editStageApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/stages/edit`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const addStageApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/stages/add`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const editStatusApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/statuses/edit`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};
const addStatusApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/statuses/add`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const addStatusToStageApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/status_stages/add`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const editStatusToStageApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/status_stages/edit`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const getAdminDetailsApi = () => {
  return axios({
    method: "get",
    url: `${url}admin/accounts/show`,
    headers: {
      ...getUserHeaders(),
    },
  });
};
const addAdminMemberApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/members/add`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};
const removeAdminMemberApi = async ({ id, data }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${id}/members/remove`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: data,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const editProjectMemberApi = ({ id, data }) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/members/edit`,
    headers: {
      ...getUserHeaders(),
      X_ACCOUNTID: accId,
    },
    data: data,
  });
};
const getPredefinedWidgetsApi = () => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/widgets/predefined`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const addWidgetApi = ({ data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/widgets`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const getPredefinedWidgetsApiv2 = () => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/widgets/v2/predefined`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const getPredefinedWidgetsForPrebuiltDashboradsApi = async () => {
  let pid = rootStore.authStore.projectId;
  try {
    let repponse = await axios({
      method: "post",
      url: `${url}dashboards/list/predefined`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
      },
    });
    return repponse;
  } catch (error) {
    return [];
  }
};

const addWidgetApiv2 = async ({ dashboard_id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}dashboards/${dashboard_id}/widgets/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: { ...data, project_id: pid },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const addPredefinedWidgetApi = async ({ dashboard_id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}dashboards/${dashboard_id}/widgets/predefined/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: { ...data, project_id: pid },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getWidgetDataApiv2 = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}widgets/${id}/v2/data`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      project_id: pid,
    },
  });
};

const getWidgetListApiv2 = async () => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/widgets/v2`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getWidgetDataApi = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/widgets/${id}/data`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getWidgetListApi = () => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/widgets`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const removeWidgetApiv2 = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/widgets/${id}/v2/remove`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const removeWidgetApi = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/widgets/${id}/remove`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const addAdminColumnApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/columns/add`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};
const editAdminColumnApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/columns/edit`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};
const editAdminSystemColumnApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/columns/edit_system`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const submitReportApi = ({ data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/reports`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const editReportApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/reports/${id}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};
const getReportsApi = ({ page_size, page }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/reports?offset=${
      page * page_size
    }&limit=${page_size}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getReportDetailsApi = ({ id, page, page_size }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/reports/${id}/data?offset=${
      page * page_size
    }&limit=${page_size}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getReportFileApi = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/reports/${id}/export`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const downloadReportFileApi = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/reports/${id}/download`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const deleteReportApi = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/reports/${id}/delete`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const refreshReportRowApi = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/reports/${id}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const getJobsApi = ({ id, page, page_size }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${id}/jobs?offset=${
      page * page_size
    }&limit=${page_size}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const createJobApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${id}/jobs`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const getJobStatusApi = ({ id, jid }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${id}/jobs/${jid}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getJobSpecificCountApi = ({ id, jid }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${id}/jobs/${jid}/itemcount`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const startSpecificJobApi = ({ id, jid }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${id}/jobs/${jid}/start`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

/////////*******************checkins related apis********************* */

const getMonthCheckInsApi = (date) => {
  let pid = rootStore.authStore.projectId;
  let uid = localStorage.getItem("uid");
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}users/${uid}/checkins/monthdata`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      date: date,
    },
  });
};

const getDayCheckInsApi = (date) => {
  let pid = rootStore.authStore.projectId;
  let uid = localStorage.getItem("uid");
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}users/${uid}/checkins/daydata`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      date: date,
    },
  });
};

const getUserMonthCheckInsApi = (date, pid, id) => {
  let uid = localStorage.getItem("uid");
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${pid}/checkins/monthdata`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      date: date,
      user_id: id,
    },
  });
};

const getUserDayCheckInsApi = (date, pid, id) => {
  let uid = localStorage.getItem("uid");
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${pid}/checkins/daydata`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      date: date,
      user_id: id,
    },
  });
};

const getAdminUserMonthCheckInsApi = (date, id) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/users/${id}/checkins/monthdata`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      date: date,
    },
  });
};
const getAdminUserDayCheckInsApi = (date, id) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/users/${id}/checkins/daydata`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      date: date,
    },
  });
};

const getLeadCheckInsApi = ({ id }) => {
  let uid = localStorage.getItem("uid");
  let pid = rootStore.authStore.projectId;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/checkins/lead/get`,
    headers: {
      ...getUserHeaders(),
    },
    data: {
      lead_id: id,
    },
  });
};

/////////***************************END***************************************************//////// */

//////////*************************Marketing Section Api*********************************** */

const getSegmentsApi = async ({ page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}segments?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
const getSegmentDataApi = (id) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}segments/${id}`,
    headers: {
      ...getUserHeaders(),

      X_ACCOUNTID: accId,
    },
  });
};

const removeSegmentApi = async (id) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}segments/${id}/delete`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const submitSegmentApi = async ({ data }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}segments`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: data,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getCampaignTypeListApi = async () => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}campaigns/types`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getCampaignProviderListApi = async (payload) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}campaigns/providers`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//api to get template list
const getTemplateListApi = async (payload) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}campaigns/templates`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getAgentListApi = async (payload) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}campaigns/dialers/agents`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getDialerExportJobAgentListApi = async (payload) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/dialer_campaigns/dialers/agents`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const createCampaignApi = (payload) => {
  let accId = rootStore.authStore.accountId;
  return axios({
    method: "post",
    url: `${url}campaigns/create`,
    headers: {
      ...getUserHeaders(),
      X_ACCOUNTID: accId,
    },
    data: payload,
  });
};

const getCampaignsListApi = async ({ page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}campaigns/list?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getCampaignDetailsApi = async (id) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}campaigns/${id}/show`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const startCampaignApi = async (id) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}campaigns/${id}/start`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getCampaignItemCountApi = async (id) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}campaigns/${id}/count`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getCampaignStatsApi = async (id) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}campaigns/${id}/stats`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getCampaignLeadsListApi = async ({
  campaign_id,
  page_size,
  page_no,
  scope,
}) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}campaigns/${campaign_id}/leads?scope=${scope}&offset=${
        page_no * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const createLeadinCampaign = async ({ pid, data }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: data,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const testCampaignApi = async (id) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}campaigns/${id}/test`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//**********************add project member new flow apis************** */

const getMemberDetailByPhoneApi = (pid, phone) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${pid}/userdetails`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      phone: phone,
    },
  });
};

//***************** project_type->B2B related apis ********************** */

const getBusinessContactListApi = (lid) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/${lid}/contacts`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const addBusinessContactApi = ({ lid, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/${lid}/contacts`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const editBusinessContactApi = ({ lid, cid, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/${lid}/contacts/${cid}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const getBusinessContactDetailsApi = ({ lid, cid }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/${lid}/contacts/${cid}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const testWidgetApi = (data) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/widgets/53b2a33c-b3fb-41f2-805b-f06f213a4a88/data/v1`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

//********************************End************************************ */

//********************export_leads job related apis */
const getExportJobDownloadFileApi = ({ id, jid }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${id}/jobs/${jid}/artifact`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

//**********************************End********************************** */

//******************money view related apis*****************************************

const moneyViewCreateLeadApi = async (lid) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${lid}/lenders/moneyview/lead/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

const moneyViewCollectOffersApi = async (lid) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${lid}/lenders/moneyview/offers`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

const moneyViewFetchJourneyURLApi = async (lid) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${lid}/lenders/moneyview/journey_url`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

/*******************END*********************************** */

//************select columns for tab related apis************** */

const getColumnsForTabApi = (tabId) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/tabs/${tabId}/columns`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const setColumnsForTabApi = ({ tabId, data }) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/tabs/${tabId}/columns`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      columns: data,
    },
  });
};
//**************************Call API************************************** */
const ozonetelCall = (leadID) => {
  let pid = rootStore.authStore.projectId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/${leadID}/dialers/ozonetel/dial/manual`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
    },
  });
};
const getOzonetelCallStatus = (formData) => {
  return axios({
    method: "post",
    url: `${url}dialers/ozonetel/call_status`,
    headers: {
      ...getUserHeaders(),
    },
    data: formData,
  });
};
const ozonetelSetCallDisposition = async (leadID, formData) => {
  let pid = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/dialers/ozonetel/disposition/set`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
      },
      data: formData,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    console.log(error);
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

/******************************* M-Cube  ************************************* */
const mcubeCall = (leadID) => {
  let pid = rootStore.authStore.projectId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/${leadID}/dialers/mcube/dial/manual`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
    },
  });
};
const getMcubeCallStatus = (formData) => {
  return axios({
    method: "post",
    url: `${url}dialers/mcube/call_status`,
    headers: {
      ...getUserHeaders(),
    },
    data: formData,
  });
};

/********************************Techinfo************************************* */

const teckinfoCall = (leadID) => {
  let pid = rootStore.authStore.projectId;

  // return axios({
  //   method: "get",
  //   url: `${url}projects/${pid}/leads/${leadID}/dialers/mcube/dial/manual`,
  //   headers: {
  //     ...getUserHeaders(),
  //     X_PROJECTID: pid,
  //   },
  // });
};

const teckinfoLeadpixieCall = (leadID) => {
  let pid = rootStore.authStore.projectId;

  // return axios({
  //   method: "get",
  //   url: `${url}projects/${pid}/leads/${leadID}/dialers/mcube/dial/manual`,
  //   headers: {
  //     ...getUserHeaders(),
  //     X_PROJECTID: pid,
  //   },
  // });
};

const getTeckinfoCallStatus = (formData) => {
  // return axios({
  //   method: "post",
  //   url: `${url}dialers/mcube/call_status`,
  //   headers: {
  //     ...getUserHeaders(),
  //   },
  //   data: formData,
  // });
};

//************************Filter v2 related apis*********************** */
const getFilteredLeadsApiV2 = ({ page_size, page_no, payload }) => {
  let accId = rootStore.authStore.accountId;

  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/filter?offset=${
      page_no * page_size
    }&limit=${page_size}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: payload,
  });
};

const getFilterColsApiV2 = () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    return axios({
      method: "get",
      url: `${url}projects/${pid}/columns_for_filters`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

//**************************End************************************** */
//*********************details section grouping related apis*************** */

const getColumnGroupsApi = async (pid) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/column_groups`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//***********************************END********************************** */

//**********************Imports status get logs file related apis********************** */

const getImportLogUrlApi = async ({ id, scope }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/imports/${id}/${scope}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

//**************************************END******************************************** */

//**********************raw leads page related apis********************** */

const getRawLeadsApi = async ({ page, page_size, payload }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}raw_leads?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: { filters: payload },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getRawLeadsColumnsApi = async () => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/columns`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getRawLeadsImportsApi = async ({ page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/imports?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const importRawLeadsApi = (data) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}raw_leads/imports`,
    headers: {
      ...getUserHeaders(),
      X_ACCOUNTID: accId,
    },
    data: data,
  });
};

const refetchRawLeadImportRowDataApi = ({ id }) => {
  let accId = rootStore.authStore.accountId;
  return axios({
    method: "get",
    url: `${url}raw_leads/imports/${id}`,
    headers: {
      ...getUserHeaders(),

      X_ACCOUNTID: accId,
    },
  });
};

const getRawLeadImportLogUrlApi = async ({ id, scope }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/imports/${id}/${scope}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getMembersToAssignByProject = (pid) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/userdetails_to_assign`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
    },
  });
};

const moveRawLeadsToProjectApi = (data) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}raw_leads/move`,
    headers: {
      ...getUserHeaders(),
      X_ACCOUNTID: accId,
    },
    data: data,
  });
};

//**************************************END******************************************** */

//************api to get import leads list**************** */
const getImportLeadListApi = async ({ id, page_no, page_size }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/imports/${id}/leads?offset=${
        page_no * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
//***************end*********************** */

//******************get project columns for mapping api******************** */
const getColumnsForMapping = async (pid) => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/columns/formapping`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
//***************************END*************************************** */

//******************admin whitelist page apis ******************/

const getUserNetWorkInfoApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: "https://api.ipify.org/?format=json",
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getWhiteListedIpsApi = async () => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "get",
      url: `${url}users/${uid}/whitelisted_ips`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const addNewIpAddressApi = async (payload) => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/whitelisted_ips/add`,
      headers: getUserHeaders(),
      data: payload,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const removeIpAddressApi = async (id) => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/whitelisted_ips/${id}/remove`,
      headers: getUserHeaders(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const disableIpAddressApi = async (id) => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/whitelisted_ips/${id}/disable`,
      headers: getUserHeaders(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const enableIpAddressApi = async (id) => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/whitelisted_ips/${id}/enable`,
      headers: getUserHeaders(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

//*************************END*********************** */

//************Quick start api ***************** */

const startDayApi = async () => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/udl/startday`,
      headers: getUserHeaders(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

//***************END************************ */

//************Send user active status api ***************** */

const sendUserActiveStatus = async (payload) => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/udl/markstatus`,
      headers: getUserHeaders(),
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

//***************END************************ */

//***************Raw lead export tab related apis*************** */
const getRawLeadJobsApi = async ({ page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/jobs/get_jobs?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
const createRawLeadJobApi = async ({ data }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}raw_leads/jobs/create_job`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: data,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getRawLeadJobStatusApi = async ({ jid }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/jobs/${jid}/get_job`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
const getRawLeadJobCountApi = async ({ jid }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/jobs/${jid}/itemcount`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
const startRawLeadJobApi = async ({ jid }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/jobs/${jid}/start_job`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getRawLeadExportJobDownloadFileApi = async ({ jid }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/jobs/${jid}/artifact`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

//**************************END********************************** */

//**************************Search api to get leads in search page after doing query in searchbar *************/
const searchLeadsByQueryApi = async ({ page_size, page_no }) => {
  let search_query = rootStore.authStore.searchQuery;
  let accId = rootStore.authStore.accountId;
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/search?offset=${
        page_no * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: {
        search_query: search_query,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
//************************************END************************************************* */

//************************multi dashboard related apis**************************************
const getDashboardListApi = async () => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}dashboards/list`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: {
        project_id: pid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getWidgetListByDashboardApi = async (dashboard_id) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}dashboards/${dashboard_id}/widgets`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const createPrebuildDashboardApi = async (payload) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}dashboards/predefined/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    console.log(error);
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const createDashboardApi = async (payload) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}dashboards/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: { ...payload, project_id: pid },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    console.log(error);
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const editDashboardApi = async ({ dashboard_id, payload }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}dashboards/${dashboard_id}/edit`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: { ...payload, project_id: pid },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    console.log(error);
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//*******************************END*********************************** */

//********************create web forms related apis***********************************
const getWebFormsListApi = async ({ page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}webforms?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const createWebFormApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}webforms`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getWebFormDataApi = async (id) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}webforms/${id}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

//*****************************END************************************************** */

//***********************Smart Views page related apis************************** */
const getProjectAdministrationViewsApi = async ({ projectId, projectRole }) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${projectId}/views?role=${projectRole}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getProjectAdministrationReOrederedViewsApi = async ({
  projectId,
  payload,
}) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/views/reorder`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const deleteProjectAdministrationViewApi = async ({
  projectId,
  itemId,
  payload,
}) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/views/${itemId}/delete`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const editProjectAdministrationViewApi = async ({
  projectId,
  itemId,
  payload,
}) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/views/${itemId}/edit`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//********************************END********************************** */

//***********************View Groups page related apis************************** */
const getProjectAdministrationViewGroupsApi = async ({ projectId }) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${projectId}/viewgroups`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const deleteProjectAdministrationViewGroupApi = async ({
  projectId,
  viewGroupID,
}) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/viewgroups/${viewGroupID}/remove`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const createProjectAdministrationViewGroupApi = async ({
  projectId,
  payload,
}) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/viewgroups/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//********************************END********************************** */

//********************Deals tab apis************ */
const getLeadActiveDealApi = async ({ leadID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/deals/active`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
const createLeadDealApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/deals/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//**********************END****************** */

//********************contacts page apis********************** */

const getContactsListApi = async ({ page, page_size, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}contacts?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
const createContactApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}contacts/create`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getContactDetailsApi = async ({ contactID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}contacts/${contactID}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const editContactApi = async ({ contactID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}contacts/${contactID}/update`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getContactLeadsListApi = async ({ contactID, page, page_size }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}contacts/${contactID}/leads?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getContactFilesListApi = async ({ contactID, page, page_size }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}contacts/${contactID}/files?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const uploadContactFileApi = async ({ contactID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}contacts/${contactID}/upload_file`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//************************END********************** */

//********************Customers page apis************** */

const getCustomersListApi = async ({ page, page_size, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}customers?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getCustomerDetailsApi = async ({ customerID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}customers/${customerID}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const getCustomerLeadsListApi = async ({ customerID, page, page_size }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}customers/${customerID}/leads?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getCustomerFilesListApi = async ({ customerID, page, page_size }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}customers/${customerID}/files?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const uploadCustomerFileApi = async ({ customerID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}customers/${customerID}/upload_file`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//************************END************************ */

//*******************Organizations page apis******************** */
const getOrganizationsListApi = async ({ page, page_size, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}organizations?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const createOrganizationApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}organizations/create`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getOrganizationDetailsApi = async ({ organizationID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}organizations/${organizationID}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const editOrganizationApi = async ({ organizationID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}organizations/${organizationID}/update`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getOrganizationContactsListApi = async ({
  organizationID,
  page,
  page_size,
}) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}organizations/${organizationID}/contacts?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

//**********************END********************************** */

//********************Products page apis********************** */

const getProductsListApi = async ({ page, page_size }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}products?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const createProductApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}products/create`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getProductDetailsApi = async ({ productID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}products/${productID}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const editProductApi = async ({ productID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}products/${productID}/update`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//************************END********************** */

//*********************Deals page apis****************** */
const getDealsListApi = async ({ page, page_size }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/deals/list?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
//*********************END**************************** */

//*************Search contact by phone and email apis******************** */
const searchContactByPhoneApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/contacts/search/phone`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    return [];
  }
};
const searchContactByEmailApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/contacts/search/email`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    return {};
  }
};
//******************END******************************* */

// mark or unmark lead as fresh lead api***********************
const markUnmarkLeadAsFreshLeadApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/fresh/mark`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const markUnmarkMultipleLeadAsFreshLeadApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/fresh/mark`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//****************END******************************* */

//*************************columns apis************ */

const getProductColumnsListApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}products/columns`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const getContactColumnsListApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}contacts/columns`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getContactColumnGroupsApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}contacts/column_groups`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getContactDetailsTabsApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}contacts/detail_tabs`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getCustomerColumnsListApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}customers/columns`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getCustomerColumnGroupsApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}customers/column_groups`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getCustomerDetailsTabsApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}customers/detail_tabs`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getOrganizationColumnsListApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}organizations/columns`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
//**************************END*************** */

//**********************************Admin predefined deals page apis***************************** */

const getAdminPredefinedDealsListApi = async ({ projectID, teamID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${projectID}/predefined_deals`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
        X_TEAMID: teamID,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const createAdminPredefinedDealApi = async ({ projectID, teamID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/predefined_deals/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
        X_TEAMID: teamID,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//*****************************************END********************************************** */

//******************************predefined deals list api **********************/

const getPredefinedDealsApi = async () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/predefined_deals`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//**********************************END******************** */

//*********************Link contact api********************** */
const linkContactApi = async ({ projectID, teamID, leadID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${projectID}/leads/${leadID}/link_contact/`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
        X_TEAMID: teamID,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//***************************END***************************** */

//*****************************Update stage api******************** */
const updateLeadStageApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/stage/change`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateMultipleLeadStageApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/stage/update`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//**********************************END*********************** */

//******************************Files page related apis********** */

//******************************predefined deals list api **********************/

const getFoldersListApi = async ({ page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}folders/list?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const createFolderApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}folders/create`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getFolderFileListApi = async ({ folderID, page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}folders/${folderID}/documents/list?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getFolderPermissionListApi = async ({ folderID, page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}folders/${folderID}/permissions?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const createFileApi = async ({ folderID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}folders/${folderID}/documents/add`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateDocumentApi = async ({ folderID, documentID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}folders/${folderID}/documents/${documentID}/update`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const addFolderPermissionApi = async ({ folderID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}folders/${folderID}/permissions/add`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const removeFolderPermissionApi = async ({ folderID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}folders/${folderID}/permissions/remove`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//**********************************END******************** */
//***********************************END********************* */

//*****************************lead submit api******************** */
const leadSubmitApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/submit`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//**********************************END*********************** */

export {
  getQuoteApi,
  bookDemoApi,
  submitExternalCampaignForm,
  testAPI,
  sendOtpEmailApi,
  sendOtpPhoneApi,
  userLoginPhoneApi,
  userLoginEmailApi,
  getProjectApi,
  getProjectListApi,
  getUserStatus,
  getDataApi,
  getProfileDataApi,
  getRoleApi,
  getTabsApi,
  getTeamApi,
  getStagesApi,
  getStageStatusesApi,
  getStatusApi,
  getAllColsApi,
  getLeadsApi,
  getFilteredLeadsApi,
  getListApi,
  submitLeadApi,
  editLeadApi,
  changeLeadstatusApi,
  changeLeadSourceApi,
  getSourcesListApi,
  getSummaryApi,
  getPhoneApi,
  getLeadProfileApi,
  getLeadActivityApi,
  getLeadCallsApi,
  submitCallApi,
  userLogoutApi,
  leadAssignApi,
  deleteMultipleLeadsApi,
  deleteLeadApi,
  getMembersApi,
  getFilterColsApi,
  getSummaryChartData,
  getSummaryBarData,
  getImportHistoryApi,
  getTargetsApi,
  sendTargetsApi,
  uploadCsvApi,
  getNotificationsApi,
  getTargetMembersApi,
  getLeadFilesApi,
  getLeadPredefinedFilesApi,
  submitFileApi,
  getLeadNotesApi,
  createLeadNoteApi,
  getNotificationsDetailApi,
  getAdminProjectApi,
  getAdminUsersApi,
  getAdminMembersApi,
  getAdminSourcesApi,
  getAdminStagesApi,
  getProjectAdministrationReOrederedStagesApi,
  getAdminStatusesApi,
  editUsersApi,
  editProjectApi,
  editProjectWorkScheduleApi,
  getAdminProjectDataApi,
  editTeamApi,
  addTeamApi,
  getAdminTeamsApi,
  editSourceApi,
  editStageApi,
  editStatusApi,
  getAdminDetailsApi,
  addSourceApi,
  addStageApi,
  addStatusApi,
  refreshImportRowApi,
  addAdminMemberApi,
  getAdminStatusToStageApi,
  addStatusToStageApi,
  editStatusToStageApi,
  addUsersApi,
  removeAdminMemberApi,
  editProjectMemberApi,
  getPredefinedWidgetsApi,
  getPredefinedWidgetsApiv2,
  getPredefinedWidgetsForPrebuiltDashboradsApi,
  addWidgetApiv2,
  getWidgetDataApiv2,
  getWidgetListApiv2,
  addWidgetApi,
  getWidgetDataApi,
  getWidgetListApi,
  removeWidgetApi,
  removeWidgetApiv2,
  getAdminColumnsApi,
  addAdminColumnApi,
  editAdminColumnApi,
  editAdminSystemColumnApi,
  submitReportApi,
  getReportsApi,
  editReportApi,
  getReportDetailsApi,
  getReportFileApi,
  downloadReportFileApi,
  deleteReportApi,
  refreshReportRowApi,
  getJobsApi,
  createJobApi,
  getJobStatusApi,
  getJobSpecificCountApi,
  getDialerExportJobAgentListApi,
  startSpecificJobApi,
  getRoleApiAdmin,
  getMonthCheckInsApi,
  getSegmentsApi,
  getCampaignTypeListApi,
  getCampaignProviderListApi,
  getTemplateListApi,
  getAgentListApi,
  submitSegmentApi,
  createCampaignApi,
  getCampaignDetailsApi,
  getCampaignStatsApi,
  testCampaignApi,
  getCampaignsListApi,
  getSegmentDataApi,
  removeSegmentApi,
  getLeadCheckInsApi,
  getDayCheckInsApi,
  getUserDayCheckInsApi,
  getUserMonthCheckInsApi,
  getMemberDetailByPhoneApi,
  addNewProjectUserApi,
  enableAdminUserApi,
  disableAdminUserApi,
  getAdminUserDayCheckInsApi,
  getAdminUserMonthCheckInsApi,
  getBusinessContactListApi,
  getBusinessContactDetailsApi,
  addBusinessContactApi,
  editBusinessContactApi,
  testWidgetApi,
  getExportJobDownloadFileApi,

  //tabs select columns apis exports
  getColumnsForTabApi,
  setColumnsForTabApi,

  //filter v2 related api exports
  getFilteredLeadsApiV2,
  getFilterColsApiV2,
  // call api
  ozonetelCall,
  getOzonetelCallStatus,
  ozonetelSetCallDisposition,
  mcubeCall,
  getMcubeCallStatus,
  teckinfoCall,
  teckinfoLeadpixieCall,
  getTeckinfoCallStatus,

  //moneyview related api exports
  moneyViewCreateLeadApi,
  moneyViewCollectOffersApi,
  moneyViewFetchJourneyURLApi,

  //group order related api exports
  getColumnGroupsApi,

  //import log urls related api
  getImportLogUrlApi,

  //raw leads page related api exports
  getRawLeadsApi,
  getRawLeadsColumnsApi,
  getRawLeadsImportsApi,
  importRawLeadsApi,
  refetchRawLeadImportRowDataApi,
  getRawLeadImportLogUrlApi,
  getMembersToAssignByProject,
  moveRawLeadsToProjectApi,
  startCampaignApi,
  getCampaignItemCountApi,
  getImportLeadListApi,

  //get columns for mapping api
  getColumnsForMapping,

  //add predefinedWidget api
  addPredefinedWidgetApi,

  //get network info api
  getUserNetWorkInfoApi,
  getWhiteListedIpsApi,
  addNewIpAddressApi,
  removeIpAddressApi,
  disableIpAddressApi,
  enableIpAddressApi,

  //get started dialog api
  startDayApi,

  //send user active status api
  sendUserActiveStatus,

  //raw leads export tab apis
  getRawLeadJobsApi,
  getRawLeadJobStatusApi,
  getRawLeadExportJobDownloadFileApi,
  getRawLeadJobCountApi,
  startRawLeadJobApi,
  createRawLeadJobApi,

  //query seach api for search page
  searchLeadsByQueryApi,

  //multi dashboard apis
  getDashboardListApi,
  getWidgetListByDashboardApi,
  createPrebuildDashboardApi,
  createDashboardApi,
  editDashboardApi,

  //campaign stats page apis
  getCampaignLeadsListApi,
  createLeadinCampaign,

  //webforms apis
  getWebFormsListApi,
  createWebFormApi,
  getWebFormDataApi,

  //smartviews page apis
  getProjectAdministrationViewsApi,
  getProjectAdministrationReOrederedViewsApi,
  deleteProjectAdministrationViewApi,
  editProjectAdministrationViewApi,

  // deals tab apis
  getLeadActiveDealApi,
  createLeadDealApi,

  //contacts page apis
  getContactsListApi,
  createContactApi,
  getContactDetailsApi,
  editContactApi,
  getContactLeadsListApi,
  getContactFilesListApi,
  uploadContactFileApi,

  //products page apis
  getProductsListApi,
  createProductApi,
  getProductDetailsApi,
  editProductApi,

  //search contact apis
  searchContactByEmailApi,
  searchContactByPhoneApi,

  //deals page apis
  getDealsListApi,

  //organizations page apis
  getOrganizationsListApi,
  createOrganizationApi,
  getOrganizationDetailsApi,
  editOrganizationApi,
  getOrganizationContactsListApi,

  //mark unmark lead apis
  markUnmarkLeadAsFreshLeadApi,
  markUnmarkMultipleLeadAsFreshLeadApi,

  //view groups page apis
  getProjectAdministrationViewGroupsApi,
  deleteProjectAdministrationViewGroupApi,
  createProjectAdministrationViewGroupApi,

  //customers page apis
  getCustomersListApi,
  getCustomerDetailsApi,
  getCustomerLeadsListApi,
  getCustomerFilesListApi,
  uploadCustomerFileApi,

  //viewgroups api
  getViewGroupsApi,

  //columns apis
  getProductColumnsListApi,
  getContactColumnsListApi,
  getContactColumnGroupsApi,
  getContactDetailsTabsApi,
  getOrganizationColumnsListApi,
  getCustomerColumnsListApi,
  getCustomerColumnGroupsApi,
  getCustomerDetailsTabsApi,

  //Admin predefined deals apis
  getAdminPredefinedDealsListApi,
  createAdminPredefinedDealApi,

  ///// predeined deals api
  getPredefinedDealsApi,

  //link contact api
  linkContactApi,

  //update lead stage api
  updateLeadStageApi,
  updateMultipleLeadStageApi,

  //files page apis
  createFolderApi,
  getFoldersListApi,
  getFolderFileListApi,
  createFileApi,
  updateDocumentApi,
  leadSubmitApi,
  getFolderPermissionListApi,
  addFolderPermissionApi,
  removeFolderPermissionApi,
};
