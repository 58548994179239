import { Button, Modal } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ChromePicker } from "react-color";
import styled from "styled-components";
import { myTheme } from "../../themeUtils";
import { getLocalizedText } from "../../Functions";
const ModalContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const ColorPickerModal = (props) => {
  const [color, setColor] = useState("#000000");

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };

  const handleApply = () => {
    props.setColor(color);
    props.setOpen(false);
  };
  useEffect(() => {
    if (props.color !== "") {
      setColor(props.color);
    }
  }, []);
  return (
    <Modal open={props.open} onClose={() => props.setOpen(false)}>
      <ModalContainer>
        <ChromePicker
          color={color}
          onChange={handleColorChange}
          disableAlpha={false}
        />
        <ButtonWrapper>
          <Button
            onClick={handleClose}
            type="button"
            variant="contained"
            color="default"
            style={{
              backgroundColor: "#EFEFF4",
              textTransform: "none",
              marginRight: "20px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {getLocalizedText("cancel")}
          </Button>
          <Button
            onClick={handleApply}
            type="button"
            variant="contained"
            id="call-log-btn"
            style={{
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
              ...myTheme.Button.btnBlue,
            }}
          >
            {getLocalizedText("apply")}
          </Button>
        </ButtonWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default ColorPickerModal;
