import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, CircularProgress, Drawer, Tooltip } from "@material-ui/core";
import { ChevronRight, Edit } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import { getProductsListApi } from "../../Api";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import AddProductSidePanel from "../../components/add_product_sidepanel/AddProductSidePanel";
import ViewProductSidepanel from "../../components/view_product_sidepanel/ViewProductSidepanel";
import { FormattedAgGridColumns } from "../../ColumnUtils";
import EditProductSidePanel from "../../components/add_product_sidepanel/EditProductSidePanel";
import { dataRenderer } from "../../Functions";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 236px);`};
  background-color: white;
  padding: 20px;
  padding-bottom: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;
const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;

const nonRequiredColumns = ["name", "desc"];

const ProductsPage = () => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openAddProductPanel, setOpenAddProductPanel] = useState(false);
  const [openEditProductPanel, setOpenEditProductPanel] = useState(false);
  const [openViewProductPanel, setOpenViewProductPanel] = useState(false);
  const [selectedRowID, setSelectedRowID] = useState("");
  const [columns, setColumns] = useState([]);

  const getProductsList = async () => {
    setLoadingData(true);
    let response = await getProductsListApi({
      page: rootStore.productStore.listingInfo.page_number,
      page_size: rootStore.productStore.listingInfo.page_size,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      rootStore.productStore.updateCount(response.item_count);
    } else {
      setRow([]);
      rootStore.productStore.updateCount(0);
    }
    setLoadingData(false);
  };

  const getProductColumns = async () => {
    await rootStore.productStore.fetchColumns();
    let filteredColumns = [...rootStore.productStore.columnsList].filter(
      (column) => !nonRequiredColumns.includes(column["column_name"])
    );
    let formattedColumns = FormattedAgGridColumns(filteredColumns);
    setColumns(formattedColumns);
  };

  const init = async () => {
    setLoading(true);
    await getProductsList();
    await getProductColumns();
    setLoading(false);
  };

  useEffect(() => {
    if (refresh === true) {
      getProductsList();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handlePageSize = async (e) => {
    rootStore.productStore.udpatePageNumber(0);
    rootStore.productStore.updatePageSize(e.target.value);
    await getProductsList();
  };
  const handlePagination = async (e, page) => {
    rootStore.productStore.udpatePageNumber(page - 1);

    await getProductsList();
  };

  const nameColumn = [
    {
      field: "name",
      headerName: "NAME",
      width: 230,
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.name);
        return (
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <Tooltip title={value}>
              <TextHighlight>{value}</TextHighlight>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const actionColumn = [
    {
      field: "actions",
      headerName: "ACTIONS",
      flex: 1,
      minWidth: 100,
      resizable: false,
      cellRenderer: (params) => {
        return (
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedRowID(params.data.id);

              setOpenEditProductPanel(true);
            }}
          >
            <Tooltip title={"Edit"}>
              <Edit
                style={{ width: "18px", height: "18px", cursor: "pointer" }}
                color="primary"
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return !loading ? (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Header>Products</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
            </TopWrapper>
            <ButtonWrapper>
              <Button
                onClick={() => {
                  setOpenAddProductPanel(true);
                }}
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "fit-content",
                  whiteSpace: "nowrap",
                }}
              >
                Add Product
              </Button>
            </ButtonWrapper>
          </Row>
          {!loadingData ? (
            <TableWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={nameColumn.concat(columns).concat(actionColumn)}
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  paginationPageSize={
                    rootStore.productStore.listingInfo.page_size
                  }
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    setSelectedRowID(row.data.id);
                    setOpenViewProductPanel(true);
                  }}
                />
              </div>
            </TableWrapper>
          ) : (
            <TableWrapper
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </TableWrapper>
          )}
          <PaginationWrapper>
            <PaginationComponent
              page_no={rootStore.productStore.listingInfo.page_number}
              row={row}
              page_size={rootStore.productStore.listingInfo.page_size}
              size={rootStore.productStore.listingInfo.page_size}
              count={rootStore.productStore.listingInfo.count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </PaginationWrapper>
        </Container>
      </ParentContainer>
      <Footer />
      {openAddProductPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openAddProductPanel}
          onClose={() => {
            setSelectedRowID("");

            setOpenAddProductPanel(false);
          }}
        >
          <AddProductSidePanel
            setRefresh={setRefresh}
            setOpen={setOpenAddProductPanel}
            open={openAddProductPanel}
          />
        </Drawer>
      )}
      {openEditProductPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openEditProductPanel}
          onClose={() => {
            setSelectedRowID("");

            setOpenEditProductPanel(false);
          }}
        >
          <EditProductSidePanel
            setRefresh={setRefresh}
            setOpen={setOpenEditProductPanel}
            open={openEditProductPanel}
            productID={selectedRowID}
          />
        </Drawer>
      )}

      {openViewProductPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openViewProductPanel}
          onClose={() => {
            setSelectedRowID("");
            setOpenViewProductPanel(false);
          }}
        >
          <ViewProductSidepanel
            productID={selectedRowID}
            setOpen={setOpenViewProductPanel}
            open={openViewProductPanel}
          />
        </Drawer>
      )}
    </>
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(ProductsPage);
