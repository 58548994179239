import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { Box, Button, Modal } from "@material-ui/core";
import { createLeadNoteApi } from "../../Api";
import { myTheme } from "../../themeUtils";
import { useEffect } from "react";
import analytics from "../../Analytics";
import { getLocalizedText } from "../../Functions";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 30px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const TextArea = styled.textarea`
  width: 428px;
  height: 90px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;

  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 600 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 2px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CreateNoteModal = (props) => {
  const [user, setUser] = useState({
    note: "",
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const sendCallLog = async () => {
      let formData = user;
      try {
        await createLeadNoteApi({ id: props.lid, data: formData });
        document.getElementById("call-log-btn").innerText = "Submitted";
        document.getElementById("call-log-btn").style.backgroundColor = "green";
        document.getElementById("call-log-btn").style.color = "white";
        setTimeout(() => {
          props.setRefresh(!props.refresh);
          props.setOpen(false);
        }, 800);
      } catch (error) {
        console.log(error);
      }
    };
    sendCallLog();
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000074,
      "add_note_form_load",
      "notes_tab",
      "",
      {}
    );
  }, []);
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Header>{getLocalizedText("add_note")}</Header>
              <SubHeader>
                {getLocalizedText("fill_in_the_below_details")}
              </SubHeader>
              <Form onSubmit={handleSubmit}>
                <InputContainer>
                  <InputWrapper>
                    <Label>{getLocalizedText("note")}*</Label>
                    <TextArea
                      required
                      type="text"
                      placeholder="Enter note here..."
                      name="note"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </InputContainer>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000076,
                        "cancel_button_tab",
                        "add_note_form",
                        "cancel_button",
                        {}
                      );
                      handleClose();
                    }}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      marginRight: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000075,
                        "submit_button_tab",
                        "add_note_form",
                        "submit_button",
                        {}
                      );
                    }}
                    type="submit"
                    variant="contained"
                    id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {getLocalizedText("submit")}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(CreateNoteModal);
