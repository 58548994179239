import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { addStageApi, editStageApi } from "../../Api";
import { useEffect } from "react";
import { myTheme } from "../../themeUtils";
import ColorPickerModal from "../color_picker_modal/ColorPickerModal";
import { Rectangle } from "@mui/icons-material";
import analytics from "../../Analytics";
import { getLocalizedText } from "../../Functions";
import { Slider, Typography } from "@mui/material";
import { colorsList } from "../../Config";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin-bottom: 20px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 420px;
`;

const Row = styled(Box)`
  display: flex;
  width: 420px;
  column-gap: 20px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
  white-space: nowrap;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const ButtonWrapper = styled(Box)`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const FlagText = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;

const probabilityList = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 20,
    label: "20%",
  },
  {
    value: 40,
    label: "40%",
  },
  {
    value: 60,
    label: "60%",
  },
  {
    value: 80,
    label: "80%",
  },
  {
    value: 100,
    label: "100%",
  },
];
const defaultColor = "#4d4e4f";
const AddStageModal = (props) => {
  const [color, setColor] = useState("#4d4e4f");
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [user, setUser] = useState({
    stage: "",
    mark_as_won: "No",
    mark_as_lost: "No",
    mark_as_closed: "No",
    probability: 100,
  });
  const [isDeleteProtected, setIsDeleteProtect] = useState(false);

  const handleClick = () => {
    setUser({ stage: "" });
    props.setOpen(false);
    props.setIsEdit(false);
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const editStage = async () => {
      let data = { stage: user.stage, stage_id: props.editableData.id };
      data["mark_as_won"] = user.mark_as_won === "Yes" ? true : false;
      data["mark_as_lost"] = user.mark_as_lost === "Yes" ? true : false;
      data["mark_as_closed"] = user.mark_as_closed === "Yes" ? true : false;
      data["probability"] = user.probability;
      data["delete_protected"] = isDeleteProtected;
      if (color !== "") {
        data["color"] = color;
      }
      try {
        let response = await editStageApi({
          id: props.id,
          data: data,
        });
        setUser({
          name: "",
        });
        props.setIsEdit(false);
        props.setRefresh(!props.refresh);
        props.setOpen(false);
      } catch (error) {
        console.log(error);
      }
    };
    const addStage = async () => {
      let data = { stage: user.stage };
      data["mark_as_won"] = user.mark_as_won === "Yes" ? true : false;
      data["mark_as_lost"] = user.mark_as_lost === "Yes" ? true : false;
      data["mark_as_closed"] = user.mark_as_closed === "Yes" ? true : false;
      data["probability"] = user.probability;
      data["delete_protected"] = isDeleteProtected;
      if (color !== "") {
        data["color"] = color;
      }
      try {
        let response = await addStageApi({
          id: props.id,
          data: data,
        });
        setUser({
          name: "",
        });
        props.setRefresh(!props.refresh);
        props.setOpen(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (props.isEdit) {
      editStage();
    } else {
      addStage();
    }
  };
  const handleClose = () => {
    setUser({ stage: "" });
    props.setOpen(false);
    props.setIsEdit(false);
  };

  const getUniqueColorCodeListLength = () => {
    const uniqueColors = new Set(
      props.stageList
        .map((stage) => stage.color)
        .filter((color) => color !== null)
    );
    return Array.from(uniqueColors).length;
  };

  const setupDefaultColor = () => {
    const index = getUniqueColorCodeListLength();
    const resulatantColor =
      index > colorsList.length ? defaultColor : colorsList[index];
    setColor(resulatantColor);
  };

  useEffect(() => {
    if (props.isEdit) {
      analytics.triggerEvent(
        4625000204,
        "edit_stage_form_load",
        "project_stages_page",
        "",
        {}
      );
      setUser({
        stage: props.editableData.stage,
        mark_as_won: props.editableData.mark_as_won === true ? "Yes" : "No",
        mark_as_lost: props.editableData.mark_as_lost === true ? "Yes" : "No",
        mark_as_closed:
          props.editableData.mark_as_closed === true ? "Yes" : "No",
        probability:
          props.editableData.probability !== null &&
          props.editableData.probability !== ""
            ? props.editableData.probability
            : 100,
      });
      setIsDeleteProtect(props.editableData.delete_protected);
      if (props.editableData.color !== null) {
        setColor(props.editableData.color);
      }
    } else {
      analytics.triggerEvent(
        4625000200,
        "add_stage_form_load",
        "project_stages_page",
        "",
        {}
      );
      setupDefaultColor();
    }
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            {/* <Close
              onClick={handleClick}
              style={{
                height: "18px",
                width: "18px",
                position: "absolute",
                top: "20px",
                right: "30px",
                cursor: "pointer",
              }}
            /> */}
            <Container>
              {props.isEdit ? (
                <Header>{getLocalizedText("edit_details")}</Header>
              ) : (
                <Header>{getLocalizedText("add_a_new_stage")}</Header>
              )}
              <Form component={"form"} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Row>
                      <InputWrapper>
                        <Label>{getLocalizedText("stage_name")}*</Label>
                        <Input
                          value={user.stage}
                          required
                          type="text"
                          placeholder="Enter name here..."
                          name="stage"
                          onChange={handleChange}
                        />
                      </InputWrapper>
                      <InputWrapper
                        style={{
                          width: "fit-content",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* <Label>Select Color</Label> */}
                        <Rectangle
                          style={{
                            color: color,
                            cursor: "pointer",
                            width: "45px",
                            height: "auto",
                            marginBottom: "-7px",
                          }}
                          onClick={() => {
                            setOpenColorPicker(true);
                          }}
                        />
                      </InputWrapper>
                    </Row>
                  </Grid>
                  <Grid item xs={4}>
                    <InputWrapper>
                      <Label>Mark As Won</Label>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={user.mark_as_won}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            mark_as_won: e.target.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          value={"Yes"}
                          control={
                            <Radio
                              disabled={
                                user.mark_as_closed === "Yes" ||
                                user.mark_as_lost === "Yes"
                              }
                              size="small"
                            />
                          }
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("yes")}
                            </Label>
                          }
                        />
                        <FormControlLabel
                          value={"No"}
                          control={
                            <Radio
                              disabled={
                                user.mark_as_closed === "Yes" ||
                                user.mark_as_lost === "Yes"
                              }
                              size="small"
                            />
                          }
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("no")}
                            </Label>
                          }
                        />
                      </RadioGroup>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={4}>
                    <InputWrapper>
                      <Label>Mark As Lost</Label>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={user.mark_as_lost}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            mark_as_lost: e.target.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          value={"Yes"}
                          control={
                            <Radio
                              disabled={
                                user.mark_as_won === "Yes" ||
                                user.mark_as_closed === "Yes"
                              }
                              size="small"
                            />
                          }
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("yes")}
                            </Label>
                          }
                        />
                        <FormControlLabel
                          value={"No"}
                          control={
                            <Radio
                              disabled={
                                user.mark_as_won === "Yes" ||
                                user.mark_as_closed === "Yes"
                              }
                              size="small"
                            />
                          }
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("no")}
                            </Label>
                          }
                        />
                      </RadioGroup>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={4}>
                    <InputWrapper>
                      <Label>Mark As Closed</Label>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={user.mark_as_closed}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            mark_as_closed: e.target.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          value={"Yes"}
                          control={
                            <Radio
                              disabled={
                                user.mark_as_won === "Yes" ||
                                user.mark_as_lost === "Yes"
                              }
                              size="small"
                            />
                          }
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("yes")}
                            </Label>
                          }
                        />
                        <FormControlLabel
                          value={"No"}
                          control={
                            <Radio
                              disabled={
                                user.mark_as_won === "Yes" ||
                                user.mark_as_lost === "Yes"
                              }
                              size="small"
                            />
                          }
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("no")}
                            </Label>
                          }
                        />
                      </RadioGroup>
                    </InputWrapper>
                  </Grid>
                  <Grid item>
                    <InputWrapper>
                      <Label>Probability</Label>
                      <Slider
                        style={{ width: "410px" }}
                        aria-label="Custom marks"
                        step={10}
                        value={user.probability}
                        valueLabelDisplay="auto"
                        marks={probabilityList}
                        onChange={(e, value) => {
                          setUser({ ...user, probability: value });
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={isDeleteProtected} color="primary" />
                      }
                      label={<FlagText>Delete Protection</FlagText>}
                      onChange={(e) => {
                        setIsDeleteProtect(e.target.checked);
                      }}
                    />
                  </Grid>
                </Grid>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      if (props.isEdit) {
                        analytics.triggerEvent(
                          4625000205,
                          "cancel_button_tap",
                          "edit_stage_form",
                          "cancel_button",
                          {}
                        );
                      } else {
                        analytics.triggerEvent(
                          4625000201,
                          "cancel_button_tap",
                          "add_stage_form",
                          "cancel_button",
                          {}
                        );
                      }
                      handleClick();
                    }}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      marginRight: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      if (props.isEdit) {
                        analytics.triggerEvent(
                          4625000206,
                          "submit_button_tap",
                          "edit_stage_form",
                          "submit_button",
                          {}
                        );
                      } else {
                        analytics.triggerEvent(
                          4625000202,
                          "submit_button_tap",
                          "add_stage_form",
                          "submit_button",
                          {}
                        );
                      }
                    }}
                    type="submit"
                    variant="contained"
                    id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {getLocalizedText("submit")}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
      {openColorPicker && (
        <ColorPickerModal
          color={color}
          setColor={setColor}
          open={openColorPicker}
          setOpen={setOpenColorPicker}
        />
      )}
    </>
  );
};

export default observer(AddStageModal);
