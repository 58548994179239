import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import rootStore from "../../stores/RootStore";
import spinner from "../../assets/images/gifs/spinner.gif";
import SingleSelect from "../select_dropdown/SingleSelect";
import SingleSelectNew from "../select_dropdown/SingleSelectNew";
import { observer } from "mobx-react-lite";
import { Box, Grid } from "@material-ui/core";
import { Typography } from "@mui/material";
import { IsoToLocalDate } from "../../Functions";
import GroupFieldWidget from "../create_lead_modal/group_field_widget/GroupFieldWidget";
const Spinner = styled("img")`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 2px 6px;
  outline: none;
  border: 1px solid #e0deca;
  font: normal normal 600 10px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 12px Open Sans;
    color: #b5b5b5;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const InputContainer = styled(Box)`
  display: flex;
  overflow-y: auto;
  width: 100%;
  padding: 16px;
  padding-bottom: 74px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const FirstForm = (props) => {
  const { authStore, userStore } = rootStore;
  const [allCols, setAllCols] = useState([]);
  const [loading, setLoading] = useState(true);
  const [parentChildData, setParentChildData] = useState({});
  let pid = authStore.projectId;
  let columnsList = [...userStore.AllColumnsList];
  const handleChange = ({ e, dataType }) => {
    let prevState = { ...props.value };
    prevState[e.target.name] = { value: e.target.value, type: dataType };
    let index = columnsList.findIndex(
      (column) => column["field"] === e.target.name
    );
    let columnMeta = columnsList[index];
    if (columnMeta["parent"]) {
      let childList = parentChildData[columnMeta["id"]];
      childList.forEach((child) => {
        prevState[child["field"]] = { value: "", type: child["data_type"] };
      });
    }
    props.setValue(prevState);
  };

  function setupColumns() {
    let tempColumns = [...columnsList];

    tempColumns = tempColumns.filter(
      (columnData) =>
        columnData["field"] !== "lead_owner" &&
        columnData["field"] !== "lead_creator" &&
        !columnData["derived"] &&
        columnData["enable_for_create"] &&
        columnData["is_visible"] &&
        !columnData["is_custom_param"]
    );

    let requiredFields = tempColumns
      .filter((item) => item["required"] && item["child_of"] === null)
      .map((item) => item)
      .sort((a, b) =>
        a["headerName"]
          .toLowerCase()
          .localeCompare(b["headerName"].toLowerCase())
      );

    let parentFields = tempColumns
      .filter((item) => item["parent"])
      .map((item) => item)
      .sort((a, b) =>
        a["headerName"]
          .toLowerCase()
          .localeCompare(b["headerName"].toLowerCase())
      );

    let allFields = [...requiredFields];

    parentFields.forEach((data) => {
      let index = allFields.findIndex(
        (element) => element["id"] === data["id"]
      );
      if (index === -1) {
        allFields.push(data);
      }
    });

    let parentChildMap = {};
    for (let item of tempColumns) {
      if (item["parent"]) {
        let parentId = item["id"];
        let children = tempColumns
          .filter(
            (child) =>
              child["child_of"] !== null && child["child_of"]["id"] === parentId
          )
          .map((child) => child)
          .sort((a, b) =>
            a["headerName"]
              .toLowerCase()
              .localeCompare(b["headerName"].toLowerCase())
          );
        parentChildMap[parentId] = children;
      }
    }
    setParentChildData(parentChildMap);
    let remainingFields = [];
    tempColumns.forEach((item) => {
      let index = allFields.findIndex(
        (element) => element["id"] === item["id"]
      );
      if (index === -1 && item["child_of"] === null) {
        remainingFields.push(item);
      }
    });
    remainingFields.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    remainingFields.forEach((data) => {
      let index = allFields.findIndex(
        (element) => element["id"] === data["id"]
      );
      if (index === -1) {
        allFields.push(data);
      }
    });

    for (const item of allFields) {
      if (item.is_multiline || item.parent) {
        item.width = 2;
        item.modifyWidth = false;
      } else {
        item.width = 1;
        item.modifyWidth = true;
      }
    }
    for (let i = 0; i < allFields.length; i++) {
      let currentItem = allFields[i];

      if (currentItem.modifyWidth) {
        if (i === allFields.length - 1) {
          currentItem.width = 2;
          currentItem.modifyWidth = false;
        } else {
          let nextItem = allFields[i + 1];

          if (nextItem.width === 2) {
            currentItem.width = 2;
            currentItem.modifyWidth = false;
          } else if (nextItem.width === 1) {
            currentItem.width = 1;
            currentItem.modifyWidth = false;
            nextItem.modifyWidth = false;
          }
        }
      }
    }

    setAllCols(allFields);
    setLoading(false);
  }

  useEffect(() => {
    setupColumns();
  }, []);

  const getRegex = (item) => {
    if (item.regex === null || item.regex === "") {
      return null;
    } else {
      return item.regex;
    }
  };

  const getMinMaxValue = ({ value, dataType }) => {
    if (value !== null && value !== "") {
      if (dataType === "integer") {
        return parseInt(value);
      } else if (dataType === "date") {
        return IsoToLocalDate(value);
      }
    }
    return "";
  };

  const renderField = ({ item }) => {
    if (item.filter_input === "" || item.filter_input === null) {
      return (
        <Grid item xs={item["width"] === 1 ? 6 : 12}>
          <InputWrapper>
            <Label>
              {item.headerName}
              {item.required && <span style={{ color: "red" }}>*</span>}
            </Label>
            <Input
              type={
                item.data_type === "email"
                  ? "email"
                  : item.data_type === "datetime" || item.data_type === "date"
                  ? "date"
                  : item.data_type === "integer"
                  ? "number"
                  : "text"
              }
              disabled={props.isPhoneAndEmailFieldDisabled(item.field)}
              onBlur={(e) => {
                if (
                  item.field.toLowerCase() === "phone" ||
                  item.field.toLowerCase() === "email"
                ) {
                  if (e.target.value.length > 3) {
                    props.handleContactSearch(item.field, e.target.value);
                  }
                }
              }}
              value={
                props.value.hasOwnProperty(item.field)
                  ? props.value[item.field]["value"]
                  : ""
              }
              min={getMinMaxValue({
                value: item["min"],
                dataType: item["data_type"],
              })}
              max={getMinMaxValue({
                value: item["max"],
                dataType: item["data_type"],
              })}
              pattern={getRegex(item)}
              required={item.required}
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  `Please enter a valid ${item.headerName}`
                )
              }
              name={item.field}
              placeholder={
                item.hint !== null && item.hint !== ""
                  ? item.hint
                  : `Enter ${item.headerName}`
              }
              onChange={(e) => {
                e.target.setCustomValidity("");
                let dataType = item.data_type;
                handleChange({ e, dataType });
              }}
            />
          </InputWrapper>
        </Grid>
      );
    } else if (
      item.filter_input.charAt(0) === "/" ||
      item.filter_input.startsWith("ep:")
    ) {
      return (
        <Grid item xs={item["width"] === 1 ? 6 : 12}>
          <InputWrapper>
            <Label>
              {item.headerName}
              {item.required && <span style={{ color: "red" }}>*</span>}
            </Label>
            <SingleSelect
              item={item}
              headerName={item.headerName}
              setSelectedVal={props.setValue}
              selectedVal={props.value}
              field={item.field}
              required={item.required}
              type={item.data_type}
              handleChange={(e) =>
                handleChange({ e, dataType: item["data_type"] })
              }
            />
          </InputWrapper>
        </Grid>
      );
    } else if (
      item.filter_input.charAt(0) !== "/" &&
      item.filter_input !== "" &&
      item.filter_input !== null
    ) {
      return (
        <Grid item xs={item["width"] === 1 ? 6 : 12}>
          <InputWrapper>
            <Label>
              {item.headerName}
              {item.required && <span style={{ color: "red" }}>*</span>}
            </Label>
            <SingleSelectNew
              item={item}
              list={item.filter_input}
              field={item.field}
              headerName={item.headerName}
              setSelectedVal={props.setValue}
              selectedVal={props.value}
              required={item.required}
              type={item.data_type}
              handleChange={(e) =>
                handleChange({ e, dataType: item["data_type"] })
              }
            />
          </InputWrapper>
        </Grid>
      );
    }
  };

  return (
    <InputContainer>
      <Grid container spacing={2}>
        {!loading ? (
          allCols.map((item) => {
            if (item["parent"] === true) {
              return (
                <Grid item xs={12}>
                  <GroupFieldWidget
                    isEdit={false}
                    item={item}
                    renderField={renderField}
                    details={props.value}
                    setDetals={props.setValue}
                    allChildList={parentChildData[item["id"]]}
                    handleFilledParentChildMap={(parentID, childIDList) => {
                      props.setFilledParentChildMap({
                        ...props.filledParentChildMap,
                        [parentID]: childIDList,
                      });
                    }}
                    handleChange={(event) =>
                      handleChange({ e: event, dataType: item["data_type"] })
                    }
                  />
                </Grid>
              );
            } else {
              return renderField({ item: item });
            }
          })
        ) : (
          <SpinnerWrapper>
            <Spinner src={spinner} alt="loading..." />
          </SpinnerWrapper>
        )}
      </Grid>
    </InputContainer>
  );
};

export default observer(FirstForm);
