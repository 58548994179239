import {
  Add,
  ArrowRightAltSharp,
  ChevronRight,
  History,
} from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import spinner from "../../assets/images/gifs/spinner.gif";
import { v4 as uuid } from "uuid";

import styled from "styled-components";
import Footer from "../../components/landing_page_components/footer/Footer";

import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { ReportListColumns } from "../../Db";
import {
  getAllColsApi,
  getImportHistoryApi,
  getReportDetailsApi,
  refreshImportRowApi,
} from "../../Api";
import { Button, Tooltip } from "@material-ui/core";
import Pagination from "../../components/pagination/Pagination";
import UploadModal from "../../components/upload_csv_modal/UploadModal";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { pageSize } from "../../Config";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ImportRowComponent from "../../components/import_row_component/ImportRowComponent";
import CreateReportModal from "../../components/create_report_modal/CreateReportModal";
import ReportRowComponent from "../../components/import_row_component/ReportRowComponent";
import { useNavigate, useParams } from "react-router-dom";
import analytics from "../../Analytics";
const Container = styled.div`
  width: 100%;
  background-color: #f9f9fc;
`;
const Wrapper = styled.div`
  ${`min-height: calc(100vh - 75px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 40px;
  padding: 20px 0px;
`;

const get_leads_url = process.env.REACT_APP_API_BASE_URL;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
`;
const PaginationWrapper = styled.div`
  padding: 0px 20px;
  background-color: white;
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
  background-color: white;
`;
const EmptyTableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const EmptyText = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
  margin-top: 50px;
`;
const SerialRow = [
  {
    field: "id",
    headerName: <Text>ID</Text>,
    sortable: false,

    width: 70,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.id}>
            <span className="csutable-cell-trucate">{params.row.id}</span>
          </Tooltip>
        </>
      );
    },
  },
];
const ReportDetailsPage = (props) => {
  const { authStore, userStore } = rootStore;
  const { id } = useParams();
  const [row, setRow] = useState();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [openImport, setOpenImport] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const columns = userStore.reportColumns;
  let pid = authStore.projectId;
  let url = `${get_leads_url}projects/${pid}/leads/import/history/`;
  let navigate = useNavigate();

  useEffect(() => {
    setRow();
    setLoading(true);

    const getSummary = async () => {
      let response = await getReportDetailsApi({
        id: id,
        page: page,
        page_size: size,
      });
      setRow(response.data.items);
      setCount(response.data.item_count);
      setLoading(false);
    };
    getSummary();
  }, [refresh]);
  // const MINUTE_MS = 60000;

  // const getRefreshedData = async () => {
  //   try {
  //     let response = await refreshImportRowApi();
  //     userStore.resetBlackList();
  //     setNotifyData(response.data.items);
  //     setNotifyCount(response.data.items_count);
  //     if (response.data.items_count !== 0) {
  //       setNotify(false);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getNotications();
  //   }, MINUTE_MS * 30);

  //   return () => clearInterval(interval);
  // }, []);
  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setRow();
    setPage(0);
    setLoading(true);
    let response = await getReportDetailsApi({
      id: id,
      page: 0,
      page_size: e.target.value,
    });
    setRow(response.data.items);
    setCount(response.data.item_count);
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setRow();
    setPage(page - 1);
    setLoading(true);
    let response = await getReportDetailsApi({
      id: id,
      page: page - 1,
      page_size: size,
    });
    setRow(response.data.items);

    setCount(response.data.item_count);
    setLoading(false);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000095,
      "report_details_page_load",
      "report_details_page",
      "",
      {}
    );
    authStore.updateLeadDetailStatus();
  }, []);
  return (
    <>
      <Container>
        <Wrapper>
          <TopWrapper>
            <Header
              style={{ color: "#185DD2", cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Reports
            </Header>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Header>{id}</Header>
          </TopWrapper>
          {row && columns.length > 0 ? (
            <>
              <TableWrapper>
                <DataGrid
                  getRowId={() => uuid() + Math.random()}
                  autoHeight={true}
                  rows={row}
                  columns={columns}
                  pageSize={pageSize}
                  checkboxSelection={false}
                  style={{ cursor: "pointer" }}
                  hideFooter={true}
                  loading={loading}
                  disableColumnMenu={true}
                />
              </TableWrapper>
              <PaginationWrapper>
                <PaginationComponent
                  page_no={page}
                  row={row}
                  page_size={size}
                  size={size}
                  count={count}
                  handlePageSize={handlePageSize}
                  handlePage={handlePagination}
                />
              </PaginationWrapper>
            </>
          ) : (
            <SpinnerWrapper>
              <Spinner src={spinner} alt="loading..." />
            </SpinnerWrapper>
          )}
        </Wrapper>
      </Container>
      <Footer />
      {openImport && (
        <CreateReportModal
          open={openImport}
          setOpen={setOpenImport}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default observer(ReportDetailsPage);
