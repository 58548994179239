import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import styled from "styled-components";
import "./style.css";
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
`;
const Label = styled.span`
  font: normal normal normal 12px Open Sans;
  color: #4d4e4f;
`;
const NormalMultiSelectFilter = (props) => {
  const [list, setList] = useState([]);
  const updateList = (val) => {
    let newList = [];
    val.map((e) => {
      let newObj = { label: e, value: e };
      newList.push(newObj);
    });
    return newList;
  };
  const generateColumns = (val, list) => {
    let newList = [];

    if (val.length > 0 && list.length > 0) {
      list.map((e) => {
        if (val.includes(e["value"])) {
          newList.push(e);
        }
      });
    }
    return newList;
  };
  useEffect(() => {
    const getList = async () => {
      if (props.list.length > 0) {
        let val = props.list.split(",");
        let newList = updateList(val);
        if (
          props.selectedVal.hasOwnProperty(props.field) &&
          props.type === "report"
        ) {
          let tempList = props.selectedVal[props.field];
          let prevColumns = generateColumns(tempList, newList);
          props.setSelected(prevColumns);
        }
        setList(newList);
      }
    };
    getList();
  }, [props.field]);
  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }
  return (
    <>
      {list.length > 0 && (
        <InputWrapper type={props.type}>
          <MultiSelect
            className={"new_filter_multi_select"}
            options={list}
            value={props.selected}
            onChange={(e) => {
              props.setSelected(e);
              let newList = [];
              e.map((val) => {
                newList.push(val.value);
              });
              props.setSelectedVal({
                ...props.selectedVal,
                [props.field]: newList,
              });
              props.setFilterList({
                ...props.filterList,
                [props.field]: {
                  value: newList,
                  type: props.filterType,
                  dataType: props.dataType,
                },
              });
            }}
            labelledBy="Select Status"
          />
        </InputWrapper>
      )}
    </>
  );
};

export default NormalMultiSelectFilter;
