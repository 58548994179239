import { Call, Mail } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import { FooterLinks } from "../../../Db";
import { app_version } from "../../../Config";

const Container = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  @media (max-width: 700px) {
    height: fit-content;
  }
`;
const Wrapper = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: space-between;
  @media (max-width: 700px) {
    width: 100%;
    flex-direction: column;
    row-gap: 10px;
    padding: 20px;
  }
`;
const LinkItem = styled.a`
  text-decoration: none;
  margin: 0px;
  margin-right: 20px;
  font: normal normal normal 12px poppins;
  letter-spacing: 0px;
  color: #838383;
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal normal 12px poppins;
  letter-spacing: 0px;
  color: #838383;
`;
const IconText = styled.a`
  margin: 0px;
  margin-left: 8px;
  text-decoration: none;
  list-style: none;
  font: normal normal normal 12px poppins;
  letter-spacing: 0px;
  color: #838383;
`;
const Left = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
`;
const Center = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Footer = () => {
  return (
    <Container>
      <Wrapper>
        <Left>
          {FooterLinks.map((item, i) => {
            return <Text key={i}>{item.label}</Text>;
          })}
        </Left>
        <Center>
          <IconWrapper>
            <Mail />
            <IconText href="mailto:hello@leadpixie.in" target="_blank">
              hello@leadpixie.in
            </IconText>
          </IconWrapper>
          <IconWrapper>
            <Call />
            <IconText href="tel:+91-9591040631">+91-9591040631</IconText>
          </IconWrapper>
        </Center>
        <Right>
          <Text>©2023 FLZ Private Limited(LeadPixie) All rights reserved.</Text>
        </Right>
        <Right>
          <Text>{app_version}</Text>
        </Right>
      </Wrapper>
    </Container>
  );
};
// <LinkItem href={item.link} target="_blank" key={i}>
//   {item.label}
// </LinkItem>

export default Footer;
