import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { CircularProgress, Drawer } from "@material-ui/core";
import DetailsScreen from "../create_contact_modal/view_screens/DetailsScreen";
import EditContactSidepanel from "../contact_forms_sidepanl/EditContactSidepanel";

const Container = styled(Box)`
  width: 100%;
  padding: 20px;
  background-color: white;
  ${`min-height: calc(100vh - 246px);`};
  /* background: #f9f9fc 0% 0% no-repeat padding-box; */
`;

const BasicInfoTab = ({
  id,
  loading,
  contactData,
  setRefresh,
  groups,
  groupsToColumns,
}) => {
  const [openEditPanel, setOpenEditPanel] = useState(false);
  const [columnIds, setColumnIds] = useState([]);
  const [showOrganizationField, setShowOrganizationField] = useState(false);

  return (
    <>
      {!loading ? (
        <Container>
          <DetailsScreen
            setColumnIds={setColumnIds}
            details={contactData}
            groups={groups}
            groupsToColumns={groupsToColumns}
            setOpenEditPanel={setOpenEditPanel}
            setShowOrganizationField={setShowOrganizationField}
          />
        </Container>
      ) : (
        <Container
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      )}
      {openEditPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openEditPanel}
          onClose={() => {
            setShowOrganizationField(false);
            setOpenEditPanel(false);
          }}
        >
          <EditContactSidepanel
            setRefresh={setRefresh}
            setOpen={setOpenEditPanel}
            open={openEditPanel}
            contactID={id}
            editableData={contactData}
            columnIds={columnIds}
            showOrganizationField={showOrganizationField}
          />
        </Drawer>
      )}
    </>
  );
};

export default BasicInfoTab;
