import React, { useEffect, useState } from "react";
import LoginLeft from "../../components/loginLeft/LoginLeft";
import LoginRight from "../../components/loginRight/LoginRight";
import Otp from "../../components/Otp/Otp";
import styled from "styled-components";
import bg from "../../assets/background/bg_gradient.png";
import e1 from "../../assets/background/e1.png";
import e2 from "../../assets/background/e2.png";
import arrow from "../../assets/content_asset/icons/arrow_down.png";
import AppBar from "../../components/landing_page_components/appbar/AppBar";
import { Auth } from "../../Db";
import { useNavigate } from "react-router-dom";
import analytics from "../../Analytics";
const Container = styled.div`
  display: flex;
  height: 100vh;
`;
const Left = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  background-image: url(${bg});
  background-size: 100% 100%;
  background-color: #f5f9ff;
  overflow: hidden;
  position: relative;
`;
const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  height: fit-content;
`;
const Right = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
`;

const LogoTitle = styled.p`
  font: normal normal 600 22px Poppins;
  color: #185dd2;
`;
const EllipseRed = styled.img`
  position: absolute;
  top: -100px;
  left: -100px;
  height: 180px;
  width: 180px;
  @media (max-width: 1420px) {
    height: 140px;
    width: 140px;
    top: -80px;
    left: -80px;
  }
`;
const EllipseYellow = styled.img`
  position: absolute;
  bottom: -30px;
  right: -50px;
  height: 180px;
  width: 180px;
  @media (max-width: 1420px) {
    height: 140px;
    width: 140px;
    bottom: -30px;
    right: -50px;
  }
`;
const LanguageWrapper = styled.div`
  width: 64px;
  height: 29px;
  background-color: #f4f4f4;
  border-radius: 4px;
  display: none;
  padding: 1px;
  position: absolute;
  justify-content: space-around;
  align-items: center;
  top: 20px;
  right: 140px;
`;
const LanguageText = styled.span`
  font: normal normal 500 12px/50px Poppins;
`;
const LanguageImg = styled.img`
  width: 10px;
  height: 10px;
  cursor: pointer;
`;
// here it is our main landing page consists of two section --> left section for quick intro and right section for login
const Main = (props) => {
  const [value, setValue] = useState({ phone: "", account: "", email: "" });
  const [inputFieldType, setInputFieldType] = useState("phone");
  let navigate = useNavigate();
  useEffect(() => {
    analytics.triggerEvent(4625000014, "login_page_load", "login_page", "", {});
    if (
      localStorage.getItem("token") !== null &&
      localStorage.getItem("uid") !== null
    ) {
      navigate("/");
    } else {
      let device_id = localStorage.getItem("device_id");
      localStorage.clear();
      localStorage.setItem("device_id", device_id);
      props.setShowBar(false);
    }
  }, []);
  const [status, setStatus] = useState(false);

  return (
    <>
      <Container>
        <AppBar type={"login"} />
        <Left>
          <LoginLeft />
        </Left>
        <Right>
          <LanguageWrapper>
            <LanguageText>Eng</LanguageText>
            <LanguageImg src={arrow} alt="loading..." />
          </LanguageWrapper>
          <LoginWrapper>
            {!status ? (
              <LoginRight
                setStatus={setStatus}
                data={Auth[0].login}
                value={value}
                setValue={setValue}
                inputFieldType={inputFieldType}
                setInputFieldType={setInputFieldType}
              />
            ) : (
              <Otp
                // setIsCollapse={props.setIsCollapse}
                setData={props.setData}
                setStatus={setStatus}
                inputFieldType={inputFieldType}
                value={value}
              />
            )}
          </LoginWrapper>
        </Right>
      </Container>
    </>
  );
};

export default Main;
