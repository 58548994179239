import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import styled from "styled-components";
import "./style.css";
import { consoleLogger } from "../../../Functions";
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const ChartSelectFilterNoraml = (props) => {
  const [selected, setSelected] = useState([]);
  const [list, setList] = useState([]);
  const updateList = (val) => {
    let newList = [];
    val.map((e) => {
      let newObj = { label: e, value: e };
      newList.push(newObj);
    });
    consoleLogger(newList);
    return newList;
  };
  const generateColumns = (val, list) => {
    let newList = [];

    if (val.length > 0 && list.length > 0) {
      list.map((e) => {
        if (val.includes(e["value"])) {
          newList.push(e);
        }
      });
    }
    return newList;
  };
  useEffect(() => {
    setSelected([]);
    const getList = async () => {
      if (props.list.length > 0) {
        let val = props.list.split(",");
        let newList = updateList(val);
        if (
          props.selectedVal.hasOwnProperty(props.field) &&
          props.type === "report"
        ) {
          let tempList = props.selectedVal[props.field];
          let prevColumns = generateColumns(tempList, newList);
          setSelected(prevColumns);
        }
        setList(newList);
      }
    };
    getList();
  }, [props.field]);
  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }
  return (
    <>
      {list.length > 0 && (
        <MultiSelect
          className="global_chart_filter_multi_select"
          options={list}
          value={selected}
          hasSelectAll={false}
          onChange={(e) => {
            setSelected(e);
            let newList = [];
            e.map((val) => {
              newList.push(val.value);
            });
            props.setSelectedVal({
              ...props.selectedVal,
              [props.field]: newList,
            });
            newList = newList.toString();

            props.setFilterValue(newList);
          }}
          labelledBy="Select Status"
        />
      )}
    </>
  );
};

export default ChartSelectFilterNoraml;
