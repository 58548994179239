import moment from "moment";
import { Tooltip } from "@material-ui/core";
import { columnWidth } from "./Config";
import ColumnHeader from "./components/column_header/ColumnHeader";
import rootStore from "./stores/RootStore";
import styled from "styled-components";
import {
  tabsDateHandler,
  dateTimeHandler,
  getLocalDateTime,
  IsoToLocalDate,
  IsoToLocalTime,
} from "./Functions";
import { myTheme } from "./themeUtils";
import { Chip } from "@mui/material";
import { AutoAwesome } from "@mui/icons-material";
import { PhoneCallback } from "@material-ui/icons";
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 12px poppins;
  color: #4d4e4f;
`;
const TextHyperLink = styled.a`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: ${myTheme.Button.colorBlue};
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
`;
function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}
const FormattedColumns = (val) => {
  const { userStore, authStore } = rootStore;
  let tabId = rootStore.leadStore.tabID;
  let newColumnHeaders = [];
  val.map((e) => {
    if (e["is_visible"] === true) {
      if (e["is_custom_param"]) {
        let newColumn = {
          field: e.field,
          sortable: false,
          headerName:
            rootStore.leadStore.tabColumnSortingList.hasOwnProperty(tabId) &&
            rootStore.leadStore.tabColumnSortingList[tabId]["field_name"] ===
              e.field ? (
              <ColumnHeader
                headerName={e.headerName}
                field={e.field}
                flag={true}
              />
            ) : (
              <ColumnHeader
                headerName={e.headerName}
                field={e.field}
                flag={false}
              />
            ),
          width: e.columnSize
            ? columnWidth[e.columnSize]
            : columnWidth["medium"],
          renderCell: (params) => {
            let parsedData = params.row.custom_params;
            if (typeof parsedData !== "object") {
              parsedData = JSON.parse(parsedData);
            }
            let component;
            if (Object.keys(parsedData).length !== 0) {
              component = Object.keys(parsedData).map((key) => {
                let compare_field = e.field.replace("custom_params_", "");
                if (key === compare_field) {
                  if (parsedData[key] !== null) {
                    if (e.is_link === true) {
                      let link_str = parsedData[key];
                      return (
                        <TextHyperLink
                          href={link_str}
                          target="_blank"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {link_str.slice(0, 16) + "..."}
                        </TextHyperLink>
                      );
                    } else {
                      if (e.data_type === "datetime") {
                        let value =
                          parsedData[key] !== null &&
                          parsedData[key] !== undefined
                            ? IsoToLocalTime(parsedData[key])
                            : "";
                        return (
                          <Tooltip title={value}>
                            <span className="csutable-cell-trucate">
                              {value !== "" ? value : "-"}
                            </span>
                          </Tooltip>
                        );
                      } else if (e.data_type === "date") {
                        let value =
                          parsedData[key] !== null &&
                          parsedData[key] !== undefined
                            ? IsoToLocalDate(parsedData[key])
                            : "";
                        return (
                          <Tooltip title={value}>
                            <span className="csutable-cell-trucate">
                              {value !== "" ? value : "-"}
                            </span>
                          </Tooltip>
                        );
                      } else {
                        return (
                          <Tooltip title={parsedData[key]}>
                            <span className="csutable-cell-trucate">
                              {parsedData[key]}
                            </span>
                          </Tooltip>
                        );
                      }
                    }
                  } else {
                    if (e.data_type === "string") {
                      return (
                        <Tooltip title={"-"}>
                          <span className="csutable-cell-trucate">-</span>
                        </Tooltip>
                      );
                    } else if (e.data_type === "integer") {
                      return (
                        <Tooltip title={0}>
                          <span className="csutable-cell-trucate">{0}</span>
                        </Tooltip>
                      );
                    } else if (
                      e.data_type === "date" ||
                      e.data_type === "datetime"
                    ) {
                      return (
                        <Tooltip title={"-"}>
                          <span className="csutable-cell-trucate">-</span>
                        </Tooltip>
                      );
                    }
                  }
                }
              });
            } else {
              if (e.data_type === "string") {
                return (
                  <Tooltip title={"-"}>
                    <span className="csutable-cell-trucate">-</span>
                  </Tooltip>
                );
              } else if (e.data_type === "integer") {
                return (
                  <Tooltip title={0}>
                    <span className="csutable-cell-trucate">{0}</span>
                  </Tooltip>
                );
              } else if (e.data_type === "date" || e.data_type === "datetime") {
                return (
                  <Tooltip title={"-"}>
                    <span className="csutable-cell-trucate">-</span>
                  </Tooltip>
                );
              }
            }
            if (component !== undefined) {
              return component;
            }
          },
          sequence: e.sequence,
        };
        newColumnHeaders.push(newColumn);
      } else {
        if (e.is_link === true) {
          let field = e.field;
          let newColumn = {
            field: e.field,
            sortable: false,
            headerName:
              rootStore.leadStore.tabColumnSortingList.hasOwnProperty(tabId) &&
              rootStore.leadStore.tabColumnSortingList[tabId]["field_name"] ===
                e.field ? (
                <ColumnHeader
                  headerName={e.headerName}
                  field={e.field}
                  flag={true}
                />
              ) : (
                <ColumnHeader
                  headerName={e.headerName}
                  field={e.field}
                  flag={false}
                />
              ),
            width: e.columnSize
              ? columnWidth[e.columnSize]
              : columnWidth["medium"],

            renderCell: (params) => {
              let link_str =
                params.row[field] !== null ? params.row[field] : "";

              return (
                <TextHyperLink
                  href={link_str}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  {link_str.slice(0, 16) + "..."}
                </TextHyperLink>
              );
            },
            sequence: e.sequence,
          };
          newColumnHeaders.push(newColumn);
        } else {
          if (e.data_type === "datetime") {
            let field = e.field;
            let newColumn = {
              field: e.field,
              sortable: false,
              headerName:
                rootStore.leadStore.tabColumnSortingList.hasOwnProperty(
                  tabId
                ) &&
                rootStore.leadStore.tabColumnSortingList[tabId][
                  "field_name"
                ] === e.field ? (
                  <ColumnHeader
                    headerName={e.headerName}
                    field={e.field}
                    flag={true}
                  />
                ) : (
                  <ColumnHeader
                    headerName={e.headerName}
                    field={e.field}
                    flag={false}
                  />
                ),
              width: e.columnSize
                ? columnWidth[e.columnSize]
                : columnWidth["medium"],

              renderCell: (params) => {
                let value =
                  params.row[field] !== null && params.row[field] !== undefined
                    ? IsoToLocalTime(params.row[field])
                    : "";

                return (
                  <Tooltip title={value}>
                    <span className="csutable-cell-trucate">
                      {value !== "" ? value : "-"}
                    </span>
                  </Tooltip>
                );
              },
              sequence: e.sequence,
            };
            newColumnHeaders.push(newColumn);
          } else if (e.data_type === "date") {
            let field = e.field;
            let newColumn = {
              field: e.field,
              sortable: false,
              headerName:
                rootStore.leadStore.tabColumnSortingList.hasOwnProperty(
                  tabId
                ) &&
                rootStore.leadStore.tabColumnSortingList[tabId][
                  "field_name"
                ] === e.field ? (
                  <ColumnHeader
                    headerName={e.headerName}
                    field={e.field}
                    flag={true}
                  />
                ) : (
                  <ColumnHeader
                    headerName={e.headerName}
                    field={e.field}
                    flag={false}
                  />
                ),
              width: e.columnSize
                ? columnWidth[e.columnSize]
                : columnWidth["medium"],

              renderCell: (params) => {
                let value =
                  params.row[field] !== null && params.row[field] !== undefined
                    ? IsoToLocalDate(params.row[field])
                    : "";

                return (
                  <Tooltip title={value}>
                    <span className="csutable-cell-trucate">
                      {value !== "" ? value : "-"}
                    </span>
                  </Tooltip>
                );
              },
              sequence: e.sequence,
            };
            newColumnHeaders.push(newColumn);
          } else {
            let newColumn = {
              field: e.field,
              headerName:
                rootStore.leadStore.tabColumnSortingList.hasOwnProperty(
                  tabId
                ) &&
                rootStore.leadStore.tabColumnSortingList[tabId][
                  "field_name"
                ] === e.field ? (
                  <ColumnHeader
                    headerName={e.headerName}
                    field={e.field}
                    flag={true}
                  />
                ) : (
                  <ColumnHeader
                    headerName={e.headerName}
                    field={e.field}
                    flag={false}
                  />
                ),
              sortable: false,
              width: e.columnSize
                ? columnWidth[e.columnSize]
                : columnWidth["medium"],

              renderCell: (params) => {
                if (e.field === "lead_stage") {
                  let color_value = "";
                  let index = authStore.stageList.findIndex(
                    (stageObj) => stageObj.stage === params.row[e.field]
                  );
                  if (index !== -1) {
                    color_value = authStore.stageList[index]["color"];
                  }
                  return (
                    <Tooltip title={params.row[e.field]}>
                      <span
                        className="csutable-cell-trucate"
                        style={{
                          color: color_value !== null ? color_value : "",
                        }}
                      >
                        {params.row[e.field]}
                      </span>
                    </Tooltip>
                  );
                } else if (e.field === "lead_status") {
                  let color_value = "";
                  let index = authStore.statusList.findIndex(
                    (status) => status.status === params.row[e.field]
                  );
                  if (index !== -1) {
                    color_value = authStore.statusList[index]["color"];
                  }
                  return (
                    <Tooltip title={params.row[e.field]}>
                      <span
                        className="csutable-cell-trucate"
                        style={{
                          color: color_value !== null ? color_value : "",
                        }}
                      >
                        {params.row[e.field]}
                      </span>
                    </Tooltip>
                  );
                } else if (
                  e.field === "lead_owner" ||
                  e.field === "lead_creator" ||
                  e.field === "team"
                ) {
                  let value =
                    params.row[e.field] !== null &&
                    params.row[e.field] !== undefined
                      ? params.row[e.field]["name"]
                      : "";
                  return (
                    <Tooltip title={value} style={{ textOverflow: "ellipsis" }}>
                      <span className="csutable-cell-trucate">
                        {value !== "" ? truncate(value, 16) : "-"}
                      </span>
                    </Tooltip>
                  );
                } else {
                  let value =
                    params.row[e.field] !== null &&
                    params.row[e.field] !== undefined
                      ? params.row[e.field]
                      : "";
                  return (
                    <Tooltip title={value} style={{ textOverflow: "ellipsis" }}>
                      <span className="csutable-cell-trucate">
                        {value !== "" ? truncate(value, 16) : "-"}
                      </span>
                    </Tooltip>
                  );
                }
              },
            };
            newColumnHeaders.push(newColumn);
          }
        }
      }
    }
  });
  return newColumnHeaders;
};

const FormattedColumnsFilters = (val) => {
  const { authStore } = rootStore;
  let newColumnHeaders = [];
  val.map((e) => {
    if (e["is_visible"] === true) {
      if (e["is_custom_param"]) {
        let newColumn = {
          field: e.field,
          sortable: false,
          headerName: <Text> {e.headerName}</Text>,
          width: e.columnSize
            ? columnWidth[e.columnSize]
            : columnWidth["medium"],
          renderCell: (params) => {
            let parsedData = params.row.custom_params;
            if (typeof parsedData !== "object") {
              parsedData = JSON.parse(parsedData);
            }
            let component;
            if (Object.keys(parsedData).length !== 0) {
              component = Object.keys(parsedData).map((key) => {
                let compare_field = e.field.replace("custom_params_", "");
                if (key === compare_field) {
                  if (parsedData[key] !== null) {
                    if (e.is_link === true) {
                      let link_str = parsedData[key];
                      return (
                        <TextHyperLink
                          href={link_str}
                          target="_blank"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {link_str.slice(0, 16) + "..."}
                        </TextHyperLink>
                      );
                    } else {
                      if (e.data_type === "datetime") {
                        let value =
                          parsedData[key] !== null &&
                          parsedData[key] !== undefined
                            ? IsoToLocalTime(parsedData[key])
                            : "";
                        return (
                          <Tooltip title={value}>
                            <span className="csutable-cell-trucate">
                              {value !== "" ? value : "-"}
                            </span>
                          </Tooltip>
                        );
                      } else if (e.data_type === "date") {
                        let value =
                          parsedData[key] !== null &&
                          parsedData[key] !== undefined
                            ? IsoToLocalDate(parsedData[key])
                            : "";
                        return (
                          <Tooltip title={value}>
                            <span className="csutable-cell-trucate">
                              {value !== "" ? value : "-"}
                            </span>
                          </Tooltip>
                        );
                      } else {
                        return (
                          <Tooltip title={parsedData[key]}>
                            <span className="csutable-cell-trucate">
                              {parsedData[key]}
                            </span>
                          </Tooltip>
                        );
                      }
                    }
                  } else {
                    if (e.data_type === "string") {
                      return (
                        <Tooltip title={"-"}>
                          <span className="csutable-cell-trucate">-</span>
                        </Tooltip>
                      );
                    } else if (e.data_type === "integer") {
                      return (
                        <Tooltip title={0}>
                          <span className="csutable-cell-trucate">{0}</span>
                        </Tooltip>
                      );
                    } else if (
                      e.data_type === "date" ||
                      e.data_type === "datetime"
                    ) {
                      return (
                        <Tooltip title={"-"}>
                          <span className="csutable-cell-trucate">-</span>
                        </Tooltip>
                      );
                    }
                  }
                }
              });
            } else {
              if (e.data_type === "string") {
                return (
                  <Tooltip title={"-"}>
                    <span className="csutable-cell-trucate">-</span>
                  </Tooltip>
                );
              } else if (e.data_type === "integer") {
                return (
                  <Tooltip title={0}>
                    <span className="csutable-cell-trucate">{0}</span>
                  </Tooltip>
                );
              } else if (e.data_type === "date" || e.data_type === "datetime") {
                return (
                  <Tooltip title={"-"}>
                    <span className="csutable-cell-trucate">-</span>
                  </Tooltip>
                );
              }
            }
            if (component !== undefined) {
              return component;
            }
          },
          sequence: e.sequence,
        };
        newColumnHeaders.push(newColumn);
      } else {
        if (e.is_link === true) {
          let field = e.field;
          let newColumn = {
            field: e.field,
            sortable: false,
            headerName: <Text> {e.headerName}</Text>,
            width: e.columnSize
              ? columnWidth[e.columnSize]
              : columnWidth["medium"],

            renderCell: (params) => {
              let link_str =
                params.row[field] !== null ? params.row[field] : "";

              return (
                <TextHyperLink
                  href={link_str}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  {link_str.slice(0, 16) + "..."}
                </TextHyperLink>
              );
            },
            sequence: e.sequence,
          };
          newColumnHeaders.push(newColumn);
        } else {
          if (e.data_type === "datetime") {
            let field = e.field;
            let newColumn = {
              field: e.field,
              sortable: false,
              headerName: <Text> {e.headerName}</Text>,
              width: e.columnSize
                ? columnWidth[e.columnSize]
                : columnWidth["medium"],

              renderCell: (params) => {
                let value =
                  params.row[field] !== null && params.row[field] !== undefined
                    ? IsoToLocalTime(params.row[field])
                    : "";

                return (
                  <Tooltip title={value}>
                    <span className="csutable-cell-trucate">
                      {value !== "" ? value : "-"}
                    </span>
                  </Tooltip>
                );
              },
              sequence: e.sequence,
            };
            newColumnHeaders.push(newColumn);
          } else if (e.data_type === "date") {
            let field = e.field;
            let newColumn = {
              field: e.field,
              sortable: false,
              headerName: <Text> {e.headerName}</Text>,
              width: e.columnSize
                ? columnWidth[e.columnSize]
                : columnWidth["medium"],

              renderCell: (params) => {
                let value =
                  params.row[field] !== null && params.row[field] !== undefined
                    ? IsoToLocalDate(params.row[field])
                    : "";

                return (
                  <Tooltip title={value}>
                    <span className="csutable-cell-trucate">
                      {value !== "" ? value : "-"}
                    </span>
                  </Tooltip>
                );
              },
              sequence: e.sequence,
            };
            newColumnHeaders.push(newColumn);
          } else {
            let newColumn = {
              field: e.field,
              headerName: <Text> {e.headerName}</Text>,
              sortable: false,
              width: e.columnSize
                ? columnWidth[e.columnSize]
                : columnWidth["medium"],

              renderCell: (params) => {
                if (e.field === "lead_stage") {
                  let color_value = "";
                  let index = authStore.stageList.findIndex(
                    (stageObj) => stageObj.stage === params.row[e.field]
                  );
                  if (index !== -1) {
                    color_value = authStore.stageList[index]["color"];
                  }
                  return (
                    <Tooltip title={params.row[e.field]}>
                      <span
                        className="csutable-cell-trucate"
                        style={{
                          color: color_value !== null ? color_value : "",
                        }}
                      >
                        {params.row[e.field]}
                      </span>
                    </Tooltip>
                  );
                } else if (e.field === "lead_status") {
                  let color_value = "";
                  let index = authStore.statusList.findIndex(
                    (status) => status.status === params.row[e.field]
                  );
                  if (index !== -1) {
                    color_value = authStore.statusList[index]["color"];
                  }
                  return (
                    <Tooltip title={params.row[e.field]}>
                      <span
                        className="csutable-cell-trucate"
                        style={{
                          color: color_value !== null ? color_value : "",
                        }}
                      >
                        {params.row[e.field]}
                      </span>
                    </Tooltip>
                  );
                } else if (
                  e.field === "lead_owner" ||
                  e.field === "lead_creator" ||
                  e.field === "team"
                ) {
                  let value =
                    params.row[e.field] !== null &&
                    params.row[e.field] !== undefined
                      ? params.row[e.field]["name"]
                      : "";
                  return (
                    <Tooltip title={value} style={{ textOverflow: "ellipsis" }}>
                      <span className="csutable-cell-trucate">
                        {value !== "" ? truncate(value, 16) : "-"}
                      </span>
                    </Tooltip>
                  );
                } else {
                  let value =
                    params.row[e.field] !== null &&
                    params.row[e.field] !== undefined
                      ? params.row[e.field]
                      : "";
                  return (
                    <Tooltip title={value} style={{ textOverflow: "ellipsis" }}>
                      <span className="csutable-cell-trucate">
                        {value !== "" ? truncate(value, 16) : "-"}
                      </span>
                    </Tooltip>
                  );
                }
              },
            };
            newColumnHeaders.push(newColumn);
          }
        }
      }
    }
  });
  return newColumnHeaders;
};

const FormattedReportColumns = (val) => {
  const { authStore } = rootStore;
  let newColumnHeaders = [];
  val.map((e) => {
    if (e["is_custom_param"]) {
      let newColumn = {
        field: e.field,
        sortable: false,
        headerName: <Text> {e.headerName}</Text>,
        width: e.columnSize ? columnWidth[e.columnSize] : columnWidth["medium"],
        renderCell: (params) => {
          let parsedData = params.row.custom_params;
          if (typeof parsedData !== "object") {
            parsedData = JSON.parse(parsedData);
          }
          let component;
          if (Object.keys(parsedData).length !== 0) {
            component = Object.keys(parsedData).map((key) => {
              let compare_field = e.field.replace("custom_params_", "");
              if (key === compare_field) {
                if (parsedData[key] !== null) {
                  if (e.is_link === true) {
                    let link_str = parsedData[key];
                    return (
                      <TextHyperLink
                        href={link_str}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {link_str.slice(0, 16) + "..."}
                      </TextHyperLink>
                    );
                  } else {
                    if (e.data_type === "datetime") {
                      let date = null;
                      if (parsedData[key] !== null && parsedData[key] !== "") {
                        date = getLocalDateTime(parsedData[key]);
                      }
                      return (
                        <Tooltip title={date}>
                          <span className="csutable-cell-trucate">
                            {date !== null ? date : "-"}
                          </span>
                        </Tooltip>
                      );
                    } else if (e.data_type === "date") {
                      let date;
                      if (parsedData[key] !== null && parsedData[key] !== "") {
                        date = getLocalDateTime(parsedData[key]);
                      }
                      return (
                        <Tooltip title={date}>
                          <span className="csutable-cell-trucate">
                            {date !== null ? date : "-"}
                          </span>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip title={parsedData[key]}>
                          <span className="csutable-cell-trucate">
                            {parsedData[key]}
                          </span>
                        </Tooltip>
                      );
                    }
                  }
                } else {
                  if (e.data_type === "string") {
                    return (
                      <Tooltip title={"-"}>
                        <span className="csutable-cell-trucate">-</span>
                      </Tooltip>
                    );
                  } else if (e.data_type === "integer") {
                    return (
                      <Tooltip title={0}>
                        <span className="csutable-cell-trucate">{0}</span>
                      </Tooltip>
                    );
                  } else if (
                    e.data_type === "date" ||
                    e.data_type === "datetime"
                  ) {
                    return (
                      <Tooltip title={"-"}>
                        <span className="csutable-cell-trucate">-</span>
                      </Tooltip>
                    );
                  }
                }
              }
            });
          } else {
            if (e.data_type === "string") {
              return (
                <Tooltip title={"-"}>
                  <span className="csutable-cell-trucate">-</span>
                </Tooltip>
              );
            } else if (e.data_type === "integer") {
              return (
                <Tooltip title={0}>
                  <span className="csutable-cell-trucate">{0}</span>
                </Tooltip>
              );
            } else if (e.data_type === "date" || e.data_type === "datetime") {
              return (
                <Tooltip title={"-"}>
                  <span className="csutable-cell-trucate">-</span>
                </Tooltip>
              );
            }
          }
          if (component !== undefined) {
            return component;
          }
        },
        sequence: e.sequence,
      };
      newColumnHeaders.push(newColumn);
    } else {
      if (e.is_link === true) {
        let field = e.field;
        let newColumn = {
          field: e.field,
          sortable: false,
          headerName: <Text> {e.headerName}</Text>,
          width: e.columnSize
            ? columnWidth[e.columnSize]
            : columnWidth["medium"],

          renderCell: (params) => {
            let link_str = params.row[field] !== null ? params.row[field] : "";

            return (
              <TextHyperLink
                href={link_str}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                {link_str.slice(0, 16) + "..."}
              </TextHyperLink>
            );
          },
          sequence: e.sequence,
        };
        newColumnHeaders.push(newColumn);
      } else {
        if (e.data_type === "datetime") {
          let field = e.field;
          let newColumn = {
            field: e.field,
            sortable: false,
            headerName: <Text> {e.headerName}</Text>,
            width: e.columnSize
              ? columnWidth[e.columnSize]
              : columnWidth["medium"],

            renderCell: (params) => {
              let date = null;
              if (params.row[field] !== null && params.row[field] !== "") {
                date = getLocalDateTime(params.row[field]);
              }
              return (
                <Tooltip title={date}>
                  <span className="csutable-cell-trucate">
                    {date !== null ? date : "-"}
                  </span>
                </Tooltip>
              );
            },
            sequence: e.sequence,
          };
          newColumnHeaders.push(newColumn);
        } else if (e.data_type === "date") {
          let field = e.field;
          let newColumn = {
            field: e.field,
            sortable: false,
            headerName: <Text> {e.headerName}</Text>,
            width: e.columnSize
              ? columnWidth[e.columnSize]
              : columnWidth["medium"],

            renderCell: (params) => {
              let date;
              if (params.row[field] !== null && params.row[field] !== "") {
                date = getLocalDateTime(params.row[field]);
              }
              return (
                <Tooltip title={date}>
                  <span className="csutable-cell-trucate">
                    {date !== null ? date : "-"}
                  </span>
                </Tooltip>
              );
            },
            sequence: e.sequence,
          };
          newColumnHeaders.push(newColumn);
        } else {
          let newColumn = {
            field: e.field,
            headerName: <Text> {e.headerName}</Text>,
            sortable: false,
            width: e.columnSize
              ? columnWidth[e.columnSize]
              : columnWidth["medium"],

            renderCell: (params) => {
              if (e.field === "lead_stage") {
                let color_value = "";
                let index = authStore.stageList.findIndex(
                  (stageObj) => stageObj.stage === params.row[e.field]
                );
                if (index !== -1) {
                  color_value = authStore.stageList[index]["color"];
                }
                return (
                  <Tooltip title={params.row[e.field]}>
                    <span
                      className="csutable-cell-trucate"
                      style={{
                        color: color_value !== null ? color_value : "",
                      }}
                    >
                      {params.row[e.field]}
                    </span>
                  </Tooltip>
                );
              } else if (e.field === "lead_status") {
                let color_value = "";
                let index = authStore.statusList.findIndex(
                  (status) => status.status === params.row[e.field]
                );
                if (index !== -1) {
                  color_value = authStore.statusList[index]["color"];
                }
                return (
                  <Tooltip title={params.row[e.field]}>
                    <span
                      className="csutable-cell-trucate"
                      style={{
                        color: color_value !== null ? color_value : "",
                      }}
                    >
                      {params.row[e.field]}
                    </span>
                  </Tooltip>
                );
              } else {
                return (
                  <Tooltip title={params.row[e.field]}>
                    <span className="csutable-cell-trucate">
                      {params.row[e.field]}
                    </span>
                  </Tooltip>
                );
              }
            },
          };
          newColumnHeaders.push(newColumn);
        }
      }
    }
  });
  return newColumnHeaders;
};

const FormattedRawLeadColumns = (val) => {
  let newColumnHeaders = [];
  val.map((e) => {
    if (!e.column_name.includes("custom_params_")) {
      if (e.data_type === "datetime") {
        let field = e.column_name;
        let newColumn = {
          field: e.column_name,
          sortable: false,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,
          cellRenderer: (params) => {
            let date = null;
            if (
              params.data[field] !== null &&
              params.data[field] !== "" &&
              params.data[field] !== undefined
            ) {
              date = IsoToLocalTime(params.data[field]);
            }
            return (
              <Tooltip title={date} placement="bottom-start">
                <span className="csutable-cell-trucate">
                  {date !== null ? date : "-"}
                </span>
              </Tooltip>
            );
          },
          sequence: e.sequence,
        };
        newColumnHeaders.push(newColumn);
      } else if (e.data_type === "date") {
        let field = e.column_name;
        let newColumn = {
          field: e.column_name,
          sortable: false,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,

          cellRenderer: (params) => {
            let date = null;
            if (
              params.data[field] !== null &&
              params.data[field] !== "" &&
              params.data[field] !== undefined
            ) {
              date = IsoToLocalDate(params.data[field]);
            }
            return (
              <Tooltip title={date} placement="bottom-start">
                <span className="csutable-cell-trucate">
                  {date !== null ? date : "-"}
                </span>
              </Tooltip>
            );
          },
          sequence: e.sequence,
        };
        newColumnHeaders.push(newColumn);
      } else if (e.data_type === "boolean") {
        let newColumn = {
          field: e.column_name,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,
          cellRenderer: (params) => {
            let value =
              params.data[e.column_name] !== null &&
              params.data[e.column_name] !== "" &&
              params.data[e.column_name] !== undefined
                ? params.data[e.column_name] === true
                  ? "True"
                  : "False"
                : "-";
            return (
              <Tooltip title={value} placement="bottom-start">
                <span className="csutable-cell-trucate">
                  {value !== "-" ? (
                    <Chip
                      label={value}
                      color={value === "True" ? "success" : "warning"}
                    />
                  ) : (
                    "-"
                  )}
                </span>
              </Tooltip>
            );
          },
        };
        newColumnHeaders.push(newColumn);
      } else {
        let newColumn = {
          field: e.column_name,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,
          cellRenderer: (params) => {
            return (
              <Tooltip
                title={params.data[e.column_name]}
                placement="bottom-start"
              >
                <span className="csutable-cell-trucate">
                  {params.data[e.column_name] !== null &&
                  params.data[e.column_name] !== "" &&
                  params.data[e.column_name] !== undefined
                    ? params.data[e.column_name]
                    : "-"}
                </span>
              </Tooltip>
            );
          },
        };
        newColumnHeaders.push(newColumn);
      }
    }
  });
  return newColumnHeaders;
};

const FormattedAgGridColumns = (val) => {
  let newColumnHeaders = [];
  val.map((e) => {
    if (e.is_visible) {
      if (e.data_type === "datetime") {
        let field = e.column_name;
        let newColumn = {
          field: e.column_name,
          sortable: false,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,
          cellRenderer: (params) => {
            let date = null;
            if (
              params.data[field] !== null &&
              params.data[field] !== "" &&
              params.data[field] !== undefined
            ) {
              date = IsoToLocalTime(params.data[field]);
            }
            return (
              <Tooltip title={date} placement="bottom-start">
                <span className="csutable-cell-trucate">
                  {date !== null ? date : "-"}
                </span>
              </Tooltip>
            );
          },
          sequence: e.sequence,
        };
        newColumnHeaders.push(newColumn);
      } else if (e.data_type === "date") {
        let field = e.column_name;
        let newColumn = {
          field: e.column_name,
          sortable: false,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,

          cellRenderer: (params) => {
            let date = null;
            if (
              params.data[field] !== null &&
              params.data[field] !== "" &&
              params.data[field] !== undefined
            ) {
              date = IsoToLocalDate(params.data[field]);
            }
            return (
              <Tooltip title={date} placement="bottom-start">
                <span className="csutable-cell-trucate">
                  {date !== null ? date : "-"}
                </span>
              </Tooltip>
            );
          },
          sequence: e.sequence,
        };
        newColumnHeaders.push(newColumn);
      } else if (e.data_type === "boolean") {
        let newColumn = {
          field: e.column_name,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,
          cellRenderer: (params) => {
            let value =
              params.data[e.column_name] !== null &&
              params.data[e.column_name] !== "" &&
              params.data[e.column_name] !== undefined
                ? params.data[e.column_name] === true
                  ? "True"
                  : "False"
                : "-";
            return (
              <Tooltip title={value} placement="bottom-start">
                <span className="csutable-cell-trucate">
                  {value !== "-" ? (
                    <Chip
                      label={value}
                      color={value === "True" ? "success" : "warning"}
                    />
                  ) : (
                    "-"
                  )}
                </span>
              </Tooltip>
            );
          },
        };
        newColumnHeaders.push(newColumn);
      } else {
        let newColumn = {
          field: e.column_name,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,
          cellRenderer: (params) => {
            return (
              <Tooltip
                title={params.data[e.column_name]}
                placement="bottom-start"
              >
                <span className="csutable-cell-trucate">
                  {params.data[e.column_name] !== null &&
                  params.data[e.column_name] !== "" &&
                  params.data[e.column_name] !== undefined
                    ? params.data[e.column_name]
                    : "-"}
                </span>
              </Tooltip>
            );
          },
        };
        newColumnHeaders.push(newColumn);
      }
    }
  });
  return newColumnHeaders;
};

// ----------------------functions and column defination for fresh_lead and followup icons in leads listing pages---------------

const isThisHour = (dateTimeString) => {
  const dateTime = moment(dateTimeString);
  const startOfCurrentHour = moment().startOf("hour");
  const endOfCurrentHour = moment().startOf("hour").add(1, "hour");

  return dateTime.isBetween(startOfCurrentHour, endOfCurrentHour, null, "[]");
};

const isToday = (dateTimeString) => {
  const dateTime = moment(dateTimeString);
  return dateTime.isSame(moment(), "day");
};

const isMissedToday = (dateTimeString) => {
  const dateTime = moment(dateTimeString);
  return (
    dateTime.isSame(moment(), "day") && dateTime.isBefore(moment(), "hour")
  );
};

const renderFollowupIcon = (data) => {
  if (
    data.hasOwnProperty("next_follow_up_on") &&
    data["next_follow_up_on"] !== null &&
    data["next_follow_up_on"] !== ""
  ) {
    if (isToday(data["next_follow_up_on"])) {
      if (isThisHour(data["next_follow_up_on"])) {
        return (
          <Tooltip title={"Followup this hour"}>
            <span>
              <PhoneCallback style={{ color: "#4caf50" }} />
            </span>
          </Tooltip>
        );
      } else if (isMissedToday(data["next_follow_up_on"])) {
        return (
          <Tooltip title={"Followup missed"}>
            <span>
              <PhoneCallback color="error" />
            </span>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={"Followup today"}>
            <span>
              <PhoneCallback color="primary" />
            </span>
          </Tooltip>
        );
      }
    }
  }
  return <PhoneCallback color="disabled" />;
};

const renderFreshLeadIcon = (data) => {
  if (data.hasOwnProperty("fresh_lead")) {
    if (data["fresh_lead"] === true) {
      return (
        <Tooltip title={"Fresh Lead"}>
          <span>
            <AutoAwesome color="secondary" />
          </span>
        </Tooltip>
      );
    }
  }
  return <AutoAwesome color="disabled" />;
};

const leadListingIconsColumn = [
  {
    field: "icons",
    headerName: "",
    sortable: false,
    width: 70,
    renderCell: (params) => {
      return (
        <IconWrapper>
          {renderFreshLeadIcon(params.row)}
          {renderFollowupIcon(params.row)}
        </IconWrapper>
      );
    },
  },
];
// -------------------------------------END-------------------------------------------------
export {
  FormattedColumns,
  FormattedReportColumns,
  FormattedColumnsFilters,
  FormattedRawLeadColumns,
  leadListingIconsColumn,
  FormattedAgGridColumns,
};
