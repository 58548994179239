import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import {
  AddBoxOutlined,
  CheckBox,
  CheckBoxOutlineBlank,
  ChevronRight,
  Delete,
} from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import { IpConfigColumnHeaders } from "../../Db";
import {
  Backdrop,
  Box,
  CircularProgress,
  Switch,
  Typography,
} from "@mui/material";
import {
  disableIpAddressApi,
  enableIpAddressApi,
  getUserNetWorkInfoApi,
  getWhiteListedIpsApi,
  removeIpAddressApi,
} from "../../Api";
import AddWhiteListedIp from "../../components/admin_setting_modals/AddWhiteListedIp";
import rootStore from "../../stores/RootStore";
import { Button } from "@material-ui/core";
import analytics from "../../Analytics";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};

  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: fit-content;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 188px);`};
  background-color: white;
  position: relative;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const SwitchText = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #979799;
  white-space: nowrap;
`;
const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 0px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
`;
const IpConfigPage = () => {
  const { userStore } = rootStore;
  const [row, setRow] = useState([]);
  const [userNetworkInfo, setUserNetworkInfo] = useState({});
  const [fetchingNetworkInfo, setFetchingNetworkInfo] = useState(true);
  const [fetchingIpList, setFetchingIpList] = useState(true);
  const [refreshIpList, setRefreshIpList] = useState(false);
  const [openAddIpModal, setOpenAddIpModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); //-> this is for backdrop

  const actionColumn = [
    {
      field: "active",
      headerName: <Text>Active</Text>,
      sortable: false,

      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Switch
              onClick={() => {
                analytics.triggerEvent(
                  4625000166,
                  "switch_button_tap",
                  "ip_whitelist_page",
                  "switch_button",
                  {}
                );
                if (params.row.active) {
                  disableIpAddress(params.row.id);
                } else {
                  enableIpAddress(params.row.id);
                }
              }}
              checked={params.row.active}
            />
          </>
        );
      },
    },
    {
      field: "action",
      headerName: <Text>Actions</Text>,
      sortable: false,
      width: 70,
      renderCell: (params) => {
        return (
          <>
            <Delete
              onClick={(e) => {
                e.stopPropagation();
                analytics.triggerEvent(
                  4625000165,
                  "delete_button_tap",
                  "ip_whitelist_page",
                  "delete_button",
                  {}
                );
                removeIpAddress(params.row.id);
              }}
              color="error"
            />
          </>
        );
      },
    },
  ];

  const fetchIpList = async () => {
    let response = await getWhiteListedIpsApi();
    setRow(response);
    setFetchingIpList(false);
  };
  const fetchUserNetworkInfo = async () => {
    let response = await getUserNetWorkInfoApi();
    setUserNetworkInfo(response);
    setFetchingNetworkInfo(false);
  };

  const disableIpAddress = async (id) => {
    setIsLoading(true);
    let response_flag = await disableIpAddressApi(id);
    if (response_flag === true) {
      setRefreshIpList(true);
    }
    setIsLoading(false);
  };

  const enableIpAddress = async (id) => {
    setIsLoading(true);
    let response_flag = await enableIpAddressApi(id);
    if (response_flag === true) {
      setRefreshIpList(true);
    }
    setIsLoading(false);
  };

  const removeIpAddress = async (id) => {
    setIsLoading(true);
    let response_flag = await removeIpAddressApi(id);
    if (response_flag === true) {
      setRefreshIpList(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000160,
      "ip_whitelist_page_load",
      "ip_whitelist_page",
      "",
      {}
    );
    rootStore.authStore.updateLeadDetailStatus();
    fetchUserNetworkInfo();
    fetchIpList();
  }, []);
  useEffect(() => {
    if (refreshIpList === true) {
      fetchIpList();
      setRefreshIpList(false);
    }
  }, [refreshIpList]);

  const isEnableWhiteListing = () => {
    let account_info =
      userStore.UserData !== null ? userStore.UserData.account : {};
    if (account_info.hasOwnProperty("enable_ip_whitelisting_web")) {
      return account_info["enable_ip_whitelisting_web"];
    }
    return false;
  };
  return (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Header>Admin</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Header>IP Whitelisting</Header>
            </TopWrapper>
            {fetchingNetworkInfo ? (
              <Text>Fetch IP details...</Text>
            ) : userNetworkInfo.hasOwnProperty("ip") ? (
              <Text>Your public IP is {userNetworkInfo["ip"]}</Text>
            ) : (
              <Text>Failed to fetch IP details</Text>
            )}
            <Row style={{ width: "fit-content", columnGap: "8px" }}>
              <SwitchText>IP Whitelisting Status :</SwitchText>

              {isEnableWhiteListing() ? (
                <Button
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  color="primary"
                  style={{ height: "30px", pointerEvents: "none" }}
                  variant="outlined"
                  endIcon={<CheckBox />}
                >
                  Web
                </Button>
              ) : (
                <Button
                  disableFocusRipple
                  disableRipple
                  color="default"
                  disableTouchRipple
                  style={{ height: "30px", pointerEvents: "none" }}
                  variant="outlined"
                  endIcon={<CheckBoxOutlineBlank />}
                >
                  Web
                </Button>
              )}
            </Row>
          </Row>

          <TableWrapper>
            <AddBtnWrapper>
              <AddBoxOutlined
                onClick={() => {
                  analytics.triggerEvent(
                    4625000161,
                    "add_button_tap",
                    "ip_whitelist_page",
                    "add_button",
                    {}
                  );
                  setOpenAddIpModal(true);
                }}
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </AddBtnWrapper>
            <DataGrid
              autoHeight={true}
              rows={row}
              columns={IpConfigColumnHeaders.concat(actionColumn)}
              checkboxSelection={false}
              style={{ cursor: "pointer" }}
              hideFooter={true}
              loading={fetchingIpList}
              disableColumnMenu={true}
            />
          </TableWrapper>
        </Container>
      </ParentContainer>
      <Footer />

      {openAddIpModal && (
        <AddWhiteListedIp
          open={openAddIpModal}
          setOpen={setOpenAddIpModal}
          setRefresh={setRefreshIpList}
        />
      )}

      <Backdrop
        open={isLoading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
    </>
  );
};

export default observer(IpConfigPage);
