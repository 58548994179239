import { Button, Tooltip } from "@material-ui/core";
import { Delete, Edit, MoreHoriz, Visibility } from "@material-ui/icons";
import { FileDownload, FileUpload, IosShare } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  deleteReportApi,
  downloadReportFileApi,
  getAllColsApi,
  getFilterColsApi,
  getReportFileApi,
  refreshImportRowApi,
  refreshReportRowApi,
} from "../../Api";
import { FormattedColumns, FormattedReportColumns } from "../../ColumnUtils";
import { ReportListColumns } from "../../Db";
import {
  consoleLogger,
  dateTimeHandler,
  tabsDateHandler,
} from "../../Functions";
import rootStore from "../../stores/RootStore";
import CreateReportModal from "../create_report_modal/CreateReportModal";
import "../../pages/import_history/import.css";
import { refetch_time } from "../../Config";
import analytics from "../../Analytics";

const SpinnerWrapper = styled.div`
  display: flex;
  min-width: 48px;
  height: 30px;
  justify-content: center;
  align-items: center;
`;
const ReportRowComponent = ({ item, idx, refresh, setRefresh }) => {
  const { userStore, authStore } = rootStore;
  const { id } = item;
  const [data, setData] = useState();
  const [flag, setFlag] = useState(false);
  const [exportStatus, setExportStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedColumnsList, setSelectedColumnsList] = useState([]);
  let navigate = useNavigate();
  const columnsList = userStore.AllColumnsList;

  const MINUTE_MS = 30000;

  const getFile = async () => {
    try {
      let response = await getReportFileApi({ id: data.id });
      setExportStatus(response.data.export_status);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const rowRefresh = async () => {
    try {
      let response = await refreshReportRowApi({ id: id });
      setData(response.data);
      setExportStatus(response.data["export_status"]);
    } catch (error) {
      console.log(error);
    }
  };
  const handleExport = (e) => {
    e.stopPropagation();
    setLoading(true);
    getFile();
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (exportStatus === "queued" || exportStatus === "processing") {
        rowRefresh();
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [exportStatus]);

  const updateSelectedColumnsList = () => {
    let newList = [];
    item["columns"].forEach((column) => {
      if (column.includes("::datetime")) {
        let new_column = column.replace("::datetime", "");
        newList.push(new_column);
      }
      newList.push(column);
    });
    setSelectedColumnsList(newList);
  };

  useEffect(() => {
    setData(item);
    setExportStatus(item["export_status"]);
    updateSelectedColumnsList();
  }, []);

  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }
  const handleDownload = (e) => {
    e.stopPropagation();
    const downloadFile = async () => {
      try {
        let response = await downloadReportFileApi({ id: data.id });
        window.open(response.data.artifact);
        consoleLogger(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    downloadFile();
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    const deleteReport = async () => {
      try {
        await deleteReportApi({ id: data.id });
        setRefresh(!refresh);
      } catch (error) {
        console.log(error);
      }
    };
    deleteReport();
  };
  const updateColumns = () => {
    let newList = [];
    if (columnsList.length > 0) {
      columnsList.map((item) => {
        if (selectedColumnsList.includes(item["field"])) {
          newList.push(item);
        }
      });
    }
    return newList;
  };
  return (
    <>
      <TableRow
        onClick={() => {
          analytics.triggerEvent(
            4625000091,
            "report_row_tap",
            "reports_page",
            "",
            {}
          );
          let updated_column_list = updateColumns();
          updated_column_list = FormattedReportColumns(updated_column_list);
          userStore.setReportColumns(updated_column_list);

          navigate(`/reports/details/${data.id}`);
        }}
        key={idx}
        style={{
          width: "100%",
          cursor: "pointer",
          backgroundColor: idx % 2 === 0 ? "white" : "#F9F9FC",
        }}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        {data &&
          ReportListColumns.concat([{ field: "action" }]).map((key) => {
            if (key["field"] === "action") {
              return (
                <TableCell
                  className="textContainer"
                  style={{
                    display: "flex",
                    justiyContent: "center",
                    alignItems: "center",
                    height: "33px",
                  }}
                >
                  <Tooltip title="Edit">
                    <Edit
                      onClick={(e) => {
                        analytics.triggerEvent(
                          4625000092,
                          "edit_report_button_tap",
                          "reports_page",
                          "edit_report_button",
                          {}
                        );
                        e.stopPropagation();
                        setOpen(true);
                      }}
                      style={{
                        width: "18px",
                        height: "18px",
                      }}
                      color="primary"
                    />
                  </Tooltip>
                  {/* <Delete
                    style={{
                      width: "18px",
                      height: "18px",
                      margin: "0px 12px",
                    }}
                    color="error"
          
                  /> */}
                  {(() => {
                    if (
                      exportStatus === "queued" ||
                      exportStatus === "processing"
                    ) {
                      return (
                        <SpinnerWrapper onClick={(e) => e.stopPropagation()}>
                          <CircularProgress
                            style={{ width: "18px", height: "18px" }}
                          />
                        </SpinnerWrapper>
                      );
                    } else if (exportStatus === "completed") {
                      return (
                        <Tooltip title="Download">
                          <SpinnerWrapper
                            onClick={(e) => {
                              analytics.triggerEvent(
                                4625000096,
                                "download_report_button_tap",
                                "reports_page",
                                "download_report_button",
                                {}
                              );
                              handleDownload(e);
                            }}
                          >
                            <FileDownload color="primary" />
                          </SpinnerWrapper>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip title="Export">
                          <SpinnerWrapper
                            onClick={(e) => {
                              analytics.triggerEvent(
                                4625000093,
                                "export_report_button_tap",
                                "reports_page",
                                "export_report_button",
                                {}
                              );
                              handleExport(e);
                            }}
                          >
                            <IosShare color="primary" />
                          </SpinnerWrapper>
                        </Tooltip>
                      );
                    }
                  })()}
                  <Tooltip title="Delete">
                    <DeleteIcon
                      onClick={(e) => {
                        analytics.triggerEvent(
                          4625000094,
                          "delete_report_button_tap",
                          "reports_page",
                          "delete_report_button",
                          {}
                        );
                        handleDelete(e);
                      }}
                      style={{
                        width: "18px",
                        height: "18px",
                      }}
                      color="primary"
                    />
                  </Tooltip>
                  {/* {loading ? (
                    <SpinnerWrapper>
                      <CircularProgress
                        style={{ width: "18px", height: "18px" }}
                      />
                    </SpinnerWrapper>
                  ) : exportStatus==="" ? (
                    <Button
                      variant="text"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        height: "30px",
                      }}
                    >
                      File
                    </Button>
                  ) : (
                    <Button
                      onClick={(e) => handleExport(e)}
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        height: "30px",
                      }}
                      variant="text"
                    >
                      Export
                    </Button>
                  )} */}
                </TableCell>
              );
            } else if (key["field"] === "created_at") {
              let new_end_time;
              if (data[key["field"]] !== null) {
                let newDate = new Date(data[key["field"]]);
                new_end_time = newDate.toLocaleDateString();
              }
              return (
                <Tooltip title={new_end_time}>
                  <TableCell
                    className="textContainer"
                    style={{
                      height: "33px",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {data[key["field"]] !== null ? new_end_time : "-"}
                  </TableCell>
                </Tooltip>
              );
            } else if (key["field"] === "id") {
              return (
                <Tooltip title={data[key["field"]]}>
                  <TableCell
                    className="textContainer"
                    style={{
                      height: "33px",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {truncate(data[key["field"]], 10)}
                  </TableCell>
                </Tooltip>
              );
            } else if (key["field"] === "last_refreshed") {
              let new_last_refreshed;
              if (data[key["field"]] !== null) {
                new_last_refreshed = dateTimeHandler(data[key["field"]]);
              }
              return (
                <TableCell
                  className="textContainer"
                  style={{
                    width: "150px",
                    height: "33px",
                    fontSize: "14px",
                    fontFamily: "Open Sans",
                    color: "#414141",
                  }}
                >
                  {data[key["field"]] !== null ? new_last_refreshed : "-"}
                </TableCell>
              );
            } else {
              return (
                <Tooltip title={data[key["field"]]}>
                  <TableCell
                    className="textContainer"
                    style={{
                      width: "150px",
                      height: "33px",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {data[key["field"]] !== null && data[key["field"]] !== ""
                      ? data[key["field"]]
                      : "-"}
                  </TableCell>
                </Tooltip>
              );
            }
          })}
      </TableRow>
      {open && (
        <CreateReportModal
          type={"edit"}
          open={open}
          setOpen={setOpen}
          refresh={refresh}
          setRefresh={setRefresh}
          data={data}
        />
      )}
    </>
  );
};

export default observer(ReportRowComponent);
