import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Add } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import { Box, Button, Drawer } from "@material-ui/core";
import { CircularProgress, Typography } from "@mui/material";
import AddDealSidePanel from "../add_deal_sidepanel/AddDealSidePanel";
import { getLeadActiveDealApi } from "../../Api";
import DealDeatilsWidget from "./details_widgets/DealDetailsWidget";
import AddPredefinedDealSidePanel from "../add_deal_sidepanel/AddPredefinedDealSidepanel";
import { validateDisabledSubFeature } from "../../Functions";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 258px);`};
  margin-bottom: 20px;
`;
const Wrapper = styled(Box)`
  display: flex;
`;

const DetailsWrapper = styled(Box)`
  ${`min-height: calc(100vh - 282px);`};
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
`;
const TextHeading = styled(Typography)`
  margin: 0px;
  font: normal normal 600 16px Open Sans;
  color: #757575;
`;
const TextLgHighlight = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
`;

const NoDealWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${`height: calc(100vh - 258px);`};
  justify-content: center;
  align-items: center;
`;

const DealsTab = (props) => {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openAddDealPanel, setOpenAddDealPanel] = useState(false);
  const [openAddPredefinedDealPanel, setOpenAddPredefinedDealPanel] =
    useState(false);

  const getDealsList = async () => {
    setLoading(true);
    let response = await getLeadActiveDealApi({ leadID: props.lid });
    if (Object.keys(response).length > 0) {
      setDetails(response);
    }
    setLoading(false);
  };

  const init = async () => {
    await getDealsList();
  };
  useEffect(() => {
    if (refresh) {
      init();
      setRefresh(false);
    }
  }, [refresh]);
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Container>
        <Wrapper>
          {!loading ? (
            Object.keys(details).length > 0 ? (
              <DetailsWrapper>
                <DealDeatilsWidget details={details} />
              </DetailsWrapper>
            ) : (
              <NoDealWrapper>
                <TextHeading>No Associated Deal.</TextHeading>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                >
                  {validateDisabledSubFeature("custom_deals") && (
                    <Button
                      disabled={props.canEditLead() === false}
                      variant="text"
                      style={{ textTransform: "none", fontSize: "16px" }}
                      color="primary"
                      onClick={() => {
                        setOpenAddDealPanel(true);
                      }}
                    >
                      <Add style={{ marginRight: "6px" }} />
                      <TextLgHighlight>Add a deal</TextLgHighlight>
                    </Button>
                  )}
                  <Button
                    disabled={props.canEditLead() === false}
                    variant="text"
                    style={{ textTransform: "none", fontSize: "16px" }}
                    color="primary"
                    onClick={() => {
                      setOpenAddPredefinedDealPanel(true);
                    }}
                  >
                    <Add style={{ marginRight: "6px" }} />
                    <TextLgHighlight>Add predefined deal</TextLgHighlight>
                  </Button>
                </Box>
              </NoDealWrapper>
            )
          ) : (
            <DetailsWrapper
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <CircularProgress />
            </DetailsWrapper>
          )}
        </Wrapper>
      </Container>

      {openAddDealPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openAddDealPanel}
          onClose={() => {
            setOpenAddDealPanel(false);
          }}
        >
          <AddDealSidePanel
            leadID={props.lid}
            setRefresh={setRefresh}
            setOpen={setOpenAddDealPanel}
            open={openAddDealPanel}
          />
        </Drawer>
      )}

      {openAddPredefinedDealPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openAddPredefinedDealPanel}
          onClose={() => {
            setOpenAddPredefinedDealPanel(false);
          }}
        >
          <AddPredefinedDealSidePanel
            leadID={props.lid}
            setRefresh={setRefresh}
            setOpen={setOpenAddPredefinedDealPanel}
            open={openAddPredefinedDealPanel}
          />
        </Drawer>
      )}
    </>
  );
};

export default observer(DealsTab);
