import {
  Box,
  Button,
  Divider,
  FormControl,
  makeStyles,
  Modal,
  Select as LinearSelect,
} from "@material-ui/core";
import styled from "styled-components";
import { ArrowRightAltSharp, Close } from "@material-ui/icons";
import {
  Alert,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { forwardRef, useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { useEffect } from "react";
import {
  addPredefinedWidgetApi,
  addWidgetApi,
  addWidgetApiv2,
  getPredefinedWidgetsApi,
  getPredefinedWidgetsApiv2,
  getWidgetDataApi,
} from "../../../Api";
import "./addchart.css";
import rootStore from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { consoleLogger, getLocalizedText } from "../../../Functions";
import "../../../pages/summary/summary.css";
import { myTheme } from "../../../themeUtils";
import AddFilterToWidget from "../add_filter_to_widget/AddFilterToWidget";
import { MultiSelect } from "react-multi-select-component";
import Select, { components } from "react-select";
import GroupByMultiSelect from "./GroupByMultiSelect";
import DateRangeFilterComponent from "../daterange_filter_component/DateRangeFilterComponent";
import MuiAlert from "@mui/material/Alert";
import analytics from "../../../Analytics";

const ErrorAlert = forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
      color="error"
      style={{
        backgroundColor: "rgba(249,86,104,1)",
        color: "#F9E1E1",
        width: "520px",
        position: "absolute",
        bottom: "50px",
      }}
    />
  );
});

const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 20px;
  padding-right: 0px;
`;
const Header = styled.p`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
  margin-bottom: 20px;
  /* text-align: center; */
`;
const Input = styled.input`
  width: 520px;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  margin-bottom: 20px;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ModelWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
`;
const Left = styled.form`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
  min-height: 80vh;
`;
const Right = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;
const Text1 = styled.p`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
  margin-bottom: 20px;
  text-align: center;
`;
const Text2 = styled.p`
  font: normal normal 600 14px Open Sans;
  margin: 0px;
  margin-bottom: 8px;
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Row = styled.div`
  display: flex;
`;
const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 540px;
  column-gap: 20px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  right: 20px;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const SelectNormal = styled.select``;
const OptionNormal = styled.option``;
const DropDownWrapper = styled.div`
  height: 100%;
  display: flex;
  margin-right: 12px;
  align-items: center;
`;
const DateRangeWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 80px;
  align-items: center;
`;
const DateRange = styled.div`
  display: flex;
  flex-direction: column;
`;
const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: -14px;
  margin-left: 8px;
`;
const StartDate = styled.p`
  width: fit-content;
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  /* border: 1px solid gray; */
`;
const EndDate = styled.p`
  font: normal normal 500 12px Open Sans;
  margin: 0px;

  /* border: 1px solid gray; */

  width: fit-content;
`;
const InputWrapperLg = styled.div`
  display: flex;
  width: 520px;
  border: 1px solid #bfbfbf;
  padding: 8px;
  height: 30px;
`;

const DateList = [
  "Today",
  "Yesterday",
  "Current Month",
  "Last Month",
  "Last 3 days",
  "Last 7 days",
  "Last 30 days",
  "Last 3 Months",
];

const defaultWidgetList = [
  {
    name: "Custom",
    id: "custom",
  },
];

const aggregateList = ["Count"];
const visualizationList = ["Bar Graph"];

const AddChartModalv2 = (props) => {
  const { userStore, authStore } = rootStore;
  const [predefinedList, setPredefinedList] = useState([
    { name: "Custom", id: "custom" },
  ]);
  const [visualList, setVisualList] = useState();
  const [fillType, setFillType] = useState("predefined");
  const [title, setTitle] = useState("");
  const [selectedWidgetID, setSelectedWidgetID] = useState("custom");
  const [visual, setVisual] = useState("");
  const [open, setOpen] = useState(false);
  const [colGroup, setColGroup] = useState();
  const [rowGroup, setRowGroup] = useState();
  const [funnelGroup, setFunnelGroup] = useState();
  const [date, setDate] = useState("");
  const [maxDays, setMaxDays] = useState(null);
  const [widgetType, setWidgetType] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filters, setFilters] = useState({});
  const [filterList, setFilterList] = useState({});
  const [aggregateBy, setAggregateBy] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [size, setSize] = useState(null);
  const [testData, setTestData] = useState(null);
  const [dateFilterType, setDateFilterType] = useState("");
  const [dateRangeValue, setDateRangeValue] = useState(null);
  const [showDateRangeAlert, setShowDateRangeAlert] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [teamId, setTeamId] = useState(""); //this state variable is only for predefined widget creation (if the widget has scope - team)
  const classes = useStyles();
  const handleChange = (e) => {
    e.preventDefault();
    setDate(e.target.value);
  };
  const handleClose = () => {
    props.setOpen(false);
  };
  const handleClick = () => {
    props.setOpen(false);
  };
  const canShowPredefinedWidgetDateFilter = () => {
    //selectedWidgetID variable is the state variable for widget selection

    if (selectedWidgetID !== "custom") {
      let index = predefinedList.findIndex(
        (widget) => widget["id"] === selectedWidgetID
      );
      if (index !== -1) {
        return predefinedList[index]["show_date_filter"];
      }
    }
    return false;
  };
  const canShowPredefinedWidgetTeamSelection = () => {
    if (selectedWidgetID !== "custom") {
      let index = predefinedList.findIndex(
        (widget) => widget["id"] === selectedWidgetID
      );
      if (index !== -1) {
        return predefinedList[index]["scope"] === "team";
      }
    }
    return false;
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000081,
      "create_widget_form_load",
      "dashboard_page",
      "",
      {}
    );
    const getWidgets = async () => {
      let response = await getPredefinedWidgetsApiv2();
      setPredefinedList(response.data.concat(defaultWidgetList));
      if (response.data.length > 0) {
        setMaxDays(response.data[0].max_days);
        setWidgetType(response.data[0].widget_type);
        setVisualList(response.data[0].supported_visualizations);

        // setXdata(response.data[0].xdata);
        if (response.data[0].xdata.hasOwnProperty("group_by_column")) {
          setColGroup(response.data[0].xdata["group_by_column"]);
        }
        if (response.data[0].xdata.hasOwnProperty("group_by_row")) {
          setRowGroup(response.data[0].xdata["group_by_row"]);
        }
        if (response.data[0].xdata.hasOwnProperty("funnel_field")) {
          setFunnelGroup(response.data[0].xdata["funnel_field"]);
        }
      }
    };
    // const getWidgetData = async () => {
    //   try {
    //     let response = await getWidgetDataApi();
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
    getWidgets();
    // getWidgetData();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    let newList = {};
    if (Object.keys(filterList).length > 0) {
      Object.keys(filterList).map((item) => {
        if (filterList[item]["type"] === "date") {
          let tempObj = { rolling: filterList[item]["value"] };
          newList[item] = tempObj;
        } else if (filterList[item]["type"] === "list") {
          let value = filterList[item]["value"].split(",");
          newList[item] = value;
        } else {
          newList[item] = filterList[item]["value"];
        }
      });
    }
    let groupList = [];
    if (selectedOptions.length > 0) {
      selectedOptions.map((item) => {
        groupList.push(item["value"]);
      });
    }
    let payload_data = {
      widget_type: visual,
      payload: {
        filters: newList,
        group_by: groupList,
        aggregate_by: "count",
      },
    };
    setSize(groupList.length);
    setTestData(payload_data);

    const addCustomWidget = async () => {
      let data = {
        title: title,
        predefined_widget_id: selectedWidgetID,
        visualization: visual,
        xdata: payload_data.payload,
        widget_type: widgetType,
        version: 2,
      };
      consoleLogger(data);

      let response = await addWidgetApiv2({
        dashboard_id: props.dashboard_id,
        data: data,
      });
      if (response.hasError()) {
        setErrorMessage(response.errorMessage);
        setIsError(true);
      } else {
        setIsError(false);
        props.setOpen(false);
        props.setRefresh(!props.refresh);
      }
    };

    const addPredefinedWidget = async () => {
      let data = {
        title: title,
        predefined_widget_id: selectedWidgetID,
        version: 2,
        input: {},
      };

      if (canShowPredefinedWidgetTeamSelection()) {
        data["team_id"] = teamId;
      }
      if (canShowPredefinedWidgetDateFilter()) {
        data["input"]["leads_date_filter_type"] = dateFilterType;
        if (dateFilterType !== "All Leads") {
          let start_date = new Date(dateRangeValue["start_date"]);
          start_date = start_date.toISOString();
          let end_date = new Date(dateRangeValue["end_date"]);
          end_date = end_date.toISOString();
          data["input"]["start_date"] = start_date;
          data["input"]["end_date"] = end_date;
        }
      }

      let response = await addPredefinedWidgetApi({
        dashboard_id: props.dashboard_id,
        data: data,
      });
      if (response.hasError()) {
        setErrorMessage(response.errorMessage);
        setIsError(true);
      } else {
        setIsError(false);
        props.setRefresh(!props.refresh);
        props.setOpen(false);
      }
    };

    if (selectedWidgetID === "custom") {
      if (selectedOptions.length > 0) {
        setShowAlert(false);
        addCustomWidget();
      } else {
        setShowAlert(true);
      }
    } else {
      if (canShowPredefinedWidgetDateFilter()) {
        if (dateFilterType !== "All Leads") {
          if (dateRangeValue === null) {
            setShowDateRangeAlert(true);
          } else {
            addPredefinedWidget();
          }
        } else {
          addPredefinedWidget();
        }
      } else {
        addPredefinedWidget();
      }
    }
  };
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  return (
    <>
      {
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModelContainer>
            <Box>
              <ModelWrapper>
                <Text1>{getLocalizedText("create_a_widget")}</Text1>
                <Row>
                  <Left onSubmit={handleSubmit}>
                    <Header>{getLocalizedText("details")}</Header>

                    <InputContainer>
                      <SelectColumn style={{ width: "520px" }}>
                        <Label>{getLocalizedText("select_widget")}</Label>
                        <FormControl
                          sx={{ m: 1, minWidth: 250 }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <LinearSelect
                            classes={{
                              icon: classes.icon,
                            }}
                            name="predefined_widget"
                            value={selectedWidgetID}
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            required
                            onChange={(e) => {
                              setSelectedWidgetID(e.target.value);
                              setTeamId("");
                              setShowDateRangeAlert(false);
                              if (e.target.value !== "custom") {
                                setDateRangeValue(null);
                                setDateFilterType("All Leads");
                                let index = predefinedList.findIndex(
                                  (widget) => widget["id"] === e.target.value
                                );
                                if (index !== -1) {
                                  setTitle(predefinedList[index]["name"]);
                                }
                              } else {
                                setTitle("");
                              }
                            }}
                            style={{
                              marginBottom: "20px",
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {predefinedList.length > 0 &&
                              predefinedList.map((e) => {
                                return (
                                  <MenuItem
                                    style={{
                                      fontSize: "12px",
                                      height: "30px",
                                    }}
                                    id={e.id}
                                    value={e.id}
                                  >
                                    {e.name}
                                  </MenuItem>
                                );
                              })}
                          </LinearSelect>
                        </FormControl>
                      </SelectColumn>

                      <InputWrapper>
                        <Label>{getLocalizedText("title")}*</Label>
                        <Input
                          required
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                          placeholder="enter title"
                          name="title"
                        />
                      </InputWrapper>
                      {canShowPredefinedWidgetDateFilter() && (
                        <DateRangeFilterComponent
                          dateFilterType={dateFilterType}
                          setDateFilterType={setDateFilterType}
                          dateRangeValue={dateRangeValue}
                          setDateRangeValue={setDateRangeValue}
                          setShowDateRangeAlert={setShowDateRangeAlert}
                        />
                      )}

                      {canShowPredefinedWidgetTeamSelection() && (
                        <SelectColumn style={{ width: "520px" }}>
                          <Label>{getLocalizedText("select_team")}</Label>
                          <FormControl
                            sx={{ m: 1, minWidth: 250 }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <LinearSelect
                              classes={{
                                icon: classes.icon,
                              }}
                              name="team"
                              value={teamId}
                              displayEmpty
                              disableUnderline
                              variant="outlined"
                              required
                              onChange={(e) => {
                                setTeamId(e.target.value);
                              }}
                              style={{
                                marginBottom: "20px",
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {authStore.currentProject.hasOwnProperty(
                                "teams"
                              ) &&
                                authStore.currentProject["teams"].map((e) => {
                                  if (e["name"] !== "All Teams") {
                                    return (
                                      <MenuItem
                                        style={{
                                          fontSize: "12px",
                                          height: "30px",
                                        }}
                                        id={e.id}
                                        value={e.id}
                                      >
                                        {e.name}
                                      </MenuItem>
                                    );
                                  }
                                })}
                            </LinearSelect>
                          </FormControl>
                        </SelectColumn>
                      )}

                      {selectedWidgetID === "custom" && (
                        <SelectColumn style={{ width: "520px" }}>
                          <Label>
                            {getLocalizedText("select_visualization")}
                          </Label>
                          <FormControl
                            sx={{ m: 1, minWidth: 250 }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <LinearSelect
                              classes={{
                                icon: classes.icon,
                              }}
                              name="widget_type"
                              value={visual}
                              displayEmpty
                              disableUnderline
                              variant="outlined"
                              required={selectedWidgetID === "custom"}
                              disabled={selectedWidgetID !== "custom"}
                              onChange={(e) => {
                                setVisual(e.target.value);
                              }}
                              style={{
                                marginBottom: "20px",
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {visualizationList.map((item, i) => {
                                return (
                                  <MenuItem
                                    style={{
                                      fontSize: "12px",
                                      height: "30px",
                                    }}
                                    key={i}
                                    id={i}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </LinearSelect>
                          </FormControl>
                        </SelectColumn>
                      )}

                      {funnelGroup && (
                        <InputWrapper>
                          <Label>{getLocalizedText("funnel_field")}</Label>
                          <Input
                            value={funnelGroup}
                            readOnly
                            name="funnel_field"
                          />
                        </InputWrapper>
                      )}

                      {selectedWidgetID === "custom" && (
                        <AddFilterToWidget
                          DateList={DateList}
                          maxDays={maxDays}
                          handleChange={handleChange}
                          date={date}
                          filters={filters}
                          setFilters={setFilters}
                          filterList={filterList}
                          setFilterList={setFilterList}
                          setDate={setDate}
                        />
                      )}
                      {selectedWidgetID === "custom" && (
                        <SelectColumn
                          style={{ width: "520px", marginTop: "20px" }}
                        >
                          <Label>{getLocalizedText("aggregate_by")}</Label>
                          <FormControl
                            sx={{ m: 1, minWidth: 520 }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <LinearSelect
                              classes={{
                                icon: classes.icon,
                              }}
                              name="predefined_widget"
                              value={aggregateBy}
                              displayEmpty
                              disableUnderline
                              variant="outlined"
                              disabled={selectedWidgetID !== "custom"}
                              required={selectedWidgetID === "custom"}
                              onChange={(e) => {
                                setAggregateBy(e.target.value);
                              }}
                              style={{
                                width: "520px",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {aggregateList.map((item, i) => {
                                return (
                                  <MenuItem
                                    style={{
                                      fontSize: "12px",
                                      height: "30px",
                                    }}
                                    key={i}
                                    id={i}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </LinearSelect>
                          </FormControl>
                        </SelectColumn>
                      )}
                      {selectedWidgetID === "custom" && (
                        <InputWrapper
                          style={{ width: "520px", marginTop: "20px" }}
                        >
                          <Label>{getLocalizedText("group_by")}</Label>
                          <GroupByMultiSelect
                            state={selectedOptions}
                            setState={setSelectedOptions}
                            setShowAlert={setShowAlert}
                          />
                        </InputWrapper>
                      )}
                    </InputContainer>
                    {showAlert && (
                      <Alert
                        style={{
                          width: "520px",
                          padding: "0px 20px",
                          marginTop: "20px",
                        }}
                        severity="warning"
                      >
                        {getLocalizedText("please_select_atleast_one_group_by")}
                      </Alert>
                    )}

                    {showDateRangeAlert && (
                      <Alert
                        style={{
                          width: "520px",
                          padding: "0px 20px",
                          marginTop: "20px",
                        }}
                        severity="warning"
                      >
                        {getLocalizedText("please_select_a_date_range")}
                      </Alert>
                    )}
                    {isError && (
                      <ErrorAlert
                        onClose={() => {
                          setIsError(false);
                          setErrorMessage("");
                        }}
                        severity="error"
                        sx={{ width: "100%" }}
                      >
                        {errorMessage}
                      </ErrorAlert>
                    )}

                    <ButtonWrapper>
                      <Button
                        onClick={() => {
                          analytics.triggerEvent(
                            4625000082,
                            "cancel_button_tap",
                            "create_widget_form",
                            "cancel_button",
                            {}
                          );
                          handleClick();
                        }}
                        type="button"
                        variant="contained"
                        color="default"
                        style={{
                          backgroundColor: "#EFEFF4",
                          textTransform: "none",
                          marginRight: "20px",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                      <Button
                        onClick={() => {
                          analytics.triggerEvent(
                            4625000083,
                            "add_widget_button_tap",
                            "create_widget_form",
                            "add_widget_button",
                            {}
                          );
                        }}
                        type="submit"
                        variant="contained"
                        id="call-log-btn"
                        style={{
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "bold",
                          ...myTheme.Button.btnBlue,
                        }}
                      >
                        {getLocalizedText("add_widget")}
                      </Button>
                    </ButtonWrapper>
                  </Left>
                </Row>
              </ModelWrapper>
            </Box>
          </ModelContainer>
        </Modal>
      }
    </>
  );
};

export default observer(AddChartModalv2);
