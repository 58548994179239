import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { getListApi } from "../../../Api";
import styled from "styled-components";
import "./style.css";
import { consoleLogger, getSlug } from "../../../Functions";
import rootStore from "../../../stores/RootStore";
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const ChartSelectFilterSlug = (props) => {
  const [selected, setSelected] = useState([]);
  const [list, setList] = useState([]);

  const updateList = (val, type) => {
    let newList = [];
    val.map((e) => {
      if (type === "list") {
        let newObj = { label: e, value: e };
        newList.push(newObj);
      } else if (type === "list_objects") {
        let newObj = { label: e.name, value: e.value };
        newList.push(newObj);
      }
    });
    consoleLogger(newList);
    return newList;
  };

  const generateColumns = (val, list) => {
    let newList = [];

    if (val.length > 0 && list.length > 0) {
      list.map((e) => {
        if (val.includes(e["value"])) {
          newList.push(e);
        }
      });
    }
    return newList;
  };
  const getList = async ({ slug }) => {
    let response = await getListApi({ slug });
    if (response !== null) {
      let newList = [];
      if (Array.isArray(response)) {
        newList = updateList(response, "list");
      } else if (typeof response === "object") {
        if (Object.keys(response).length > 0) {
          newList = updateList(response["values"], response["type"]);
        }
      }
      if (
        props.selectedVal.hasOwnProperty(props.field) &&
        props.type === "report"
      ) {
        let tempList = props.selectedVal[props.field];
        let prevColumns = generateColumns(tempList, newList);
        setSelected(prevColumns);
      }
      setList(newList);
    } else {
      setSelected([]);
      setList([]);
    }
  };

  useEffect(() => {
    let slug = getSlug(props.item.filter_input);
    getList({ slug: slug });
  }, [props.field]);
  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }
  return (
    <>
      <MultiSelect
        hasSelectAll={false}
        options={list}
        value={selected}
        className="global_chart_filter_multi_select"
        onChange={(e) => {
          setSelected(e);
          let newList = [];
          e.map((val) => {
            newList.push(val.value);
          });
          props.setSelectedVal({
            ...props.selectedVal,
            [props.field]: newList,
          });
          newList = newList.toString();

          props.setFilterValue(newList);
        }}
        labelledBy="Select Status"
      />
    </>
  );
};

export default ChartSelectFilterSlug;
