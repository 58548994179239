import { Add, Edit, Lock } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useState } from "react";

import styled from "styled-components";
import Footer from "../../components/landing_page_components/footer/Footer";
import "../summary/summary.css";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { getDashboardListApi, getWidgetListByDashboardApi } from "../../Api";
import { Chart, ArcElement } from "chart.js";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import {
  Button,
  FormControl,
  IconButton,
  Menu,
  Select,
  makeStyles,
} from "@material-ui/core";
import AddChartModalV2 from "../../components/chart_components/add_chart_modal/AddChartModalv2";
import { checkFeaturePermission, isUnderWorkSchedule } from "../../Functions";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";
import NoAccessPage from "../no_access_page/NoAccessPage";
import analytics from "../../Analytics";
import PieChartWidget from "../../components/chart_components/dashboard_charts/PieChartWidget";
import BarGraphWidget from "../../components/chart_components/dashboard_charts/BarGraphWidget";
import StackedBarGraphWidget from "../../components/chart_components/dashboard_charts/StackedBarGraphWidget";
import FunnelChartWidget from "../../components/chart_components/dashboard_charts/FunnelChartWidget";
import OneGroupBarGraphWidget from "../../components/chart_components/dashboard_chart_v2/OneGroupBarGraphWidget";
import TwoGroupBarGraphWidget from "../../components/chart_components/dashboard_chart_v2/TwoGroupBarGraphWidget";
import ThreeGroupBarGraphWidget from "../../components/chart_components/dashboard_chart_v2/ThreeGroupBarGraphWidget";
import TabularWidget from "../../components/chart_components/dashboard_chart_v2/tabular_widget/TabularWidget";
import AddDashboardModal from "../../components/chart_components/add_dashboard_modal/AddDashboardModal";
import EditDashboardModal from "../../components/chart_components/edit_dashboard_modal/EditDashboardModal";
import AddOwnerPrebuildDashboardModal from "../../components/chart_components/add_dashboard_modal/AddOwnerPrebuildDashboardModal";
import DashboardWidgetSlideshow from "../../components/dashboard_widget_slideshow/DashboardWidgetSlideshow";
import MoreVertIcon from "@mui/icons-material/MoreVert";

Chart.register(ArcElement);

const Container = styled.div`
  margin-top: 64px;
  background-color: #f9f9fc;
  width: 100%;
  ${`min-height: calc(100vh - 139px);`};
`;
const NoWidgetTextWrapper = styled.div`
  width: 100%;
  display: flex;
  ${`min-height: calc(100vh - 220px);`};
  justify-content: center;
  align-items: center;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: white;
  justify-content: space-between;
`;
const TitleText = styled.p`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  color: #4d4e4f;
`;
const TopLeftWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;
const Bottom = styled.div`
  padding: 20px;
  width: 100%;
`;
const Item = styled.div`
  height: 48vh;
  width: 100%;
  border: ${(e) => (e.props === "realtime" ? "1px solid #00A67E" : "none")};
  background-color: white;
`;
const Item2 = styled.div`
  width: 100%;
  background-color: white;
`;
const Text = styled.p`
  font: normal normal 600 1vw Open Sans;
  margin: 0px;
  color: #4d4e4f;
`;
const TextLgHighlight = styled.a`
  font: normal normal 600 18px Open Sans;
  margin: 0px;
  color: #3f51b5;
  text-decoration: underline;
`;
const TextLg = styled.p`
  font: normal normal 600 18px Open Sans;
  margin: 0px;
  color: #4d4e4f;
`;
const AddWidgetWrapper = styled.div`
  width: 100%;
  height: 48vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: 2px dashed #aeaeae;
`;

const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const ITEM_HEIGHT = 48;
const Dashboard = () => {
  const classes = useStyles();
  const { authStore, userStore } = rootStore;
  const [row, setRow] = useState([]);
  const [openAddWidget, setOpenAddWidget] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refreshWidgets, setRefreshWidgets] = useState(false);
  const [currentDashboardData, setCurrentDashboardData] = useState({});
  const [dashboardList, setDashboardList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAddDashboard, setOpenAddDashboard] = useState(false);
  const [loadingWidgets, setLoadingWidgets] = useState(false);
  const [openEditDashboard, setOpenEditDashboard] = useState(false);
  const [currentDashboardId, setCurrentDashboardId] = useState(""); //this extra id state for dashboard is created to render the current dashboard in dashboard dropdown
  const [openSlideShow, setOpenSlideShow] = useState(false);
  const [widgetListToSlideshow, setWidgetListToSlideshow] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const Open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const AddDashboard = () => {
    let account_role =
      userStore.UserData !== null ? userStore.UserData["role"] : "";
    if (account_role === "owner" && authStore.projectList.length === 1) {
      let dashBoardNames = dashboardList.map((item) => item.name.toLowerCase());

      if (
        authStore.currentProject.hasOwnProperty("teams") &&
        authStore.currentProject["teams"].length === 1
      ) {
        return (
          <AddOwnerPrebuildDashboardModal
            open={openAddDashboard}
            setOpen={setOpenAddDashboard}
            dashBoardNames={dashBoardNames}
            refresh={refresh}
            setRefresh={setRefresh}
            setCurrentDashboardData={setCurrentDashboardData}
            setCurrentDashboardId={setCurrentDashboardId}
          />
        );
      }
    }
    return (
      <AddDashboardModal
        open={openAddDashboard}
        setOpen={setOpenAddDashboard}
        refresh={refresh}
        setRefresh={setRefresh}
        setCurrentDashboardData={setCurrentDashboardData}
        setCurrentDashboardId={setCurrentDashboardId}
      />
    );
  };

  const updateList = (list, dashboard_data) => {
    let tempItemList = [];
    let tempItem = null;
    if (list.length > 0) {
      list.map((item) => {
        if (item.visualization === "Funnel Chart") {
          tempItemList.push(item);
        }
      });
      if (tempItemList.length > 0) {
        tempItem = tempItemList[0];
      }
    }
    let filteredList = list.filter((e) => !tempItemList.includes(e));
    let newItem = { visualization: "add_item" };
    if (tempItem !== null) {
      if (tempItem.visualization === "Funnel Chart" && tempItem.idx % 2 === 0) {
        if (
          filteredList.length % 2 !== 0 &&
          dashboard_data["editable"] === true
        ) {
          filteredList.push(newItem);
        }
        tempItemList.map((item) => {
          filteredList.push(item);
        });
      } else if (
        tempItem.visualization === "Funnel Chart" &&
        tempItem.idx % 2 !== 0
      ) {
        if (
          filteredList.length % 2 !== 0 &&
          dashboard_data["editable"] === true
        ) {
          filteredList.push(newItem);
        }
        tempItemList.map((item) => {
          filteredList.push(item);
        });
      }
    } else {
      if (dashboard_data["editable"] === true) {
        filteredList.push(newItem);
      }
    }
    if (filteredList.length === 0 && dashboard_data["editable"] === true) {
      filteredList.push(newItem);
    }
    return filteredList;
  };

  const getWidgetsByDashboard = async (dashboard_data) => {
    let response = await getWidgetListByDashboardApi(dashboard_data["id"]);
    setWidgetListToSlideshow(response);
    let updated_list = updateList([...response], dashboard_data);
    console.log(updated_list);
    setRow(updated_list);
  };
  const getDashboardList = async () => {
    let response = await getDashboardListApi();
    return response;
  };
  const init = async () => {
    setLoading(true);
    let dashboard_list = await getDashboardList();
    setDashboardList(dashboard_list);
    if (dashboard_list.length > 0) {
      setCurrentDashboardData(dashboard_list[0]);
      setCurrentDashboardId(dashboard_list[0]["id"]);
      await fetchWidgets(dashboard_list[0]);
    }
    setLoading(false);
  };

  const fetchWidgets = async (dashboard_data) => {
    setLoadingWidgets(true);
    await getWidgetsByDashboard(dashboard_data);
    setLoadingWidgets(false);
  };
  useEffect(() => {
    if (refreshWidgets) {
      fetchWidgets(currentDashboardData);
      setRefreshWidgets(false);
    }
  }, [refreshWidgets]);

  //below function is written to refetch the dashboard list and widgets on edit or add dashboard , but we need to show the newly created or edited dashboard in the view

  const refetchDataOnDashboardUpdate = async () => {
    setLoading(true);
    let dashboard_list = await getDashboardList();
    setDashboardList(dashboard_list);
    if (dashboard_list.length > 0) {
      let index = dashboard_list.findIndex(
        (dashboard) => dashboard["id"] === currentDashboardData["id"]
      );
      if (index !== -1) {
        setCurrentDashboardData(dashboard_list[index]);
        await fetchWidgets(dashboard_list[index]);
      } else {
        setCurrentDashboardId(dashboard_list[0]["id"]);
        setCurrentDashboardData(dashboard_list[0]);
        await fetchWidgets(dashboard_list[0]);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (refresh) {
      refetchDataOnDashboardUpdate();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    analytics.triggerEvent(
      4625000079,
      "dashboard_page_load",
      "dashboard_page",
      "",
      {}
    );
    authStore.updateLeadDetailStatus();
    init();
  }, []);

  const handleDashboardChange = async (e) => {
    setCurrentDashboardId(e.target.value);
    let index = dashboardList.findIndex(
      (dashboard) => dashboard["id"] === e.target.value
    );
    if (index !== -1) {
      setCurrentDashboardData(dashboardList[index]);
      await fetchWidgets(dashboardList[index]);
    }
  };
  const isDashboardEditable = () => {
    if (currentDashboardData.hasOwnProperty("editable")) {
      return currentDashboardData["editable"];
    }
    return false;
  };

  const isSlideShowEnabled = () => {
    if (currentDashboardData.hasOwnProperty("slideshow_enabled")) {
      return currentDashboardData["slideshow_enabled"];
    }
    return false;
  };

  const canAddDashboard = () => {
    let counter = 0;
    dashboardList.forEach((dashboard) => {
      if (dashboard["dashboard_type"] === "custom") {
        counter += 1;
      }
    });
    return counter < 3;
  };
  const getDashboardName = () => {
    if (currentDashboardData.hasOwnProperty("name")) {
      return currentDashboardData["name"];
    }
    return "Dashboards";
  };
  return (
    <>
      {checkFeaturePermission("dashboard") ? (
        isUnderWorkSchedule() ? (
          <>
            {!loading ? (
              <Container>
                <Top>
                  <TopLeftWrapper>
                    {dashboardList.length > 1 ? (
                      <IconTextWrapper>
                        {isDashboardEditable() ? (
                          <Edit
                            onClick={() => {
                              setOpenEditDashboard(true);
                            }}
                            style={{
                              width: "18px",
                              cursor: "pointer",
                              height: "18px",
                            }}
                            color="primary"
                          />
                        ) : (
                          <Lock style={{ width: "18px", height: "18px" }} />
                        )}
                        <FormControl
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            name="dashboard_type"
                            displayEmpty
                            disableUnderline
                            value={currentDashboardId}
                            onChange={handleDashboardChange}
                            variant="outlined"
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "28px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                              color: "#4D4E4F",
                            }}
                          >
                            {dashboardList.map((item, i) => {
                              return (
                                <MenuItem
                                  style={{
                                    fontSize: "12px",
                                    height: "30px",
                                  }}
                                  key={i}
                                  id={i}
                                  value={item.id}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </IconTextWrapper>
                    ) : currentDashboardData.hasOwnProperty("name") ? (
                      <IconTextWrapper style={{ columnGap: "10px" }}>
                        {isDashboardEditable() ? (
                          <Edit
                            onClick={() => {
                              setOpenEditDashboard(true);
                            }}
                            style={{
                              width: "18px",
                              cursor: "pointer",
                              height: "18px",
                            }}
                            color="primary"
                          />
                        ) : (
                          <Lock style={{ width: "18px", height: "18px" }} />
                        )}
                        <TitleText>{getDashboardName()}</TitleText>
                      </IconTextWrapper>
                    ) : (
                      <TitleText>Dashboards</TitleText>
                    )}
                  </TopLeftWrapper>
                  <ButtonWrapper>
                    <Button
                      disabled={!canAddDashboard()}
                      variant="contained"
                      color="primary"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        marginRight: "20px",
                      }}
                      onClick={() => {
                        setOpenAddDashboard(true);
                      }}
                    >
                      Add Dashboard
                    </Button>

                    <Button
                      onClick={() => {
                        analytics.triggerEvent(
                          4625000080,
                          "add_widget_button_tap",
                          "dashboard_page",
                          "add_widget_button",
                          {}
                        );
                        setOpenAddWidget(true);
                      }}
                      disabled={!isDashboardEditable()}
                      variant="contained"
                      color="primary"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Add Widget
                    </Button>

                    {isSlideShowEnabled() && (
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={Open ? "long-menu" : undefined}
                        aria-expanded={Open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleMenuClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    )}
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={Open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      getContentAnchorEl={null}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                        },
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          setOpenSlideShow(true);
                          handleClose();
                        }}
                      >
                        Start Slideshow
                      </MenuItem>
                    </Menu>
                  </ButtonWrapper>
                </Top>
                {!loadingWidgets ? (
                  dashboardList.length > 0 ? (
                    row.length > 0 ? (
                      <Bottom>
                        <Grid container rowSpacing={3} columnSpacing={3}>
                          {row.map((e, i) => {
                            if (e.version === 1) {
                              if (e.visualization === "Pie Chart") {
                                return (
                                  <Grid item xs={6} key={i}>
                                    <Item>
                                      <PieChartWidget
                                        data={e}
                                        refresh={refreshWidgets}
                                        setRefresh={setRefreshWidgets}
                                      />
                                    </Item>
                                  </Grid>
                                );
                              } else if (e.visualization === "Bar Chart") {
                                return (
                                  <Grid item xs={6} key={i}>
                                    <Item>
                                      <BarGraphWidget
                                        data={e}
                                        refresh={refreshWidgets}
                                        setRefresh={setRefreshWidgets}
                                      />
                                    </Item>
                                  </Grid>
                                );
                              } else if (
                                e.visualization === "Stacked Bar Chart"
                              ) {
                                return (
                                  <Grid item xs={6} key={i}>
                                    <Item>
                                      <StackedBarGraphWidget
                                        data={e}
                                        refresh={refreshWidgets}
                                        setRefresh={setRefreshWidgets}
                                      />
                                    </Item>
                                  </Grid>
                                );
                              } else if (e.visualization === "Funnel Chart") {
                                return (
                                  <Grid item xs={6} key={i}>
                                    <Item2>
                                      <FunnelChartWidget
                                        data={e}
                                        refresh={refreshWidgets}
                                        setRefresh={setRefreshWidgets}
                                      />
                                    </Item2>
                                  </Grid>
                                );
                              }
                            } else if (e.version === 2) {
                              if (e["widget_type"] === "tabular") {
                                return (
                                  <Grid item xs={6} key={i}>
                                    <Item>
                                      <TabularWidget
                                        data={e}
                                        refresh={refreshWidgets}
                                        setRefresh={setRefreshWidgets}
                                        isDashboardEditable={
                                          isDashboardEditable
                                        }
                                      />
                                    </Item>
                                  </Grid>
                                );
                              } else {
                                let groups = e["xdata"]["group_by"];
                                let size = groups.length;
                                if (size === 1) {
                                  return (
                                    <Grid item xs={6} key={i}>
                                      <Item props={e.data_source}>
                                        <OneGroupBarGraphWidget
                                          data={e}
                                          refresh={refreshWidgets}
                                          setRefresh={setRefreshWidgets}
                                          isDashboardEditable={
                                            isDashboardEditable
                                          }
                                        />
                                      </Item>
                                    </Grid>
                                  );
                                } else if (size === 2) {
                                  return (
                                    <Grid item xs={6} key={i}>
                                      <Item props={e.data_source}>
                                        <TwoGroupBarGraphWidget
                                          data={e}
                                          refresh={refreshWidgets}
                                          setRefresh={setRefreshWidgets}
                                          isDashboardEditable={
                                            isDashboardEditable
                                          }
                                        />
                                      </Item>
                                    </Grid>
                                  );
                                } else if (size === 3) {
                                  return (
                                    <Grid item xs={6} key={i}>
                                      <Item>
                                        <ThreeGroupBarGraphWidget
                                          data={e}
                                          refresh={refreshWidgets}
                                          setRefresh={setRefreshWidgets}
                                          isDashboardEditable={
                                            isDashboardEditable
                                          }
                                        />
                                      </Item>
                                    </Grid>
                                  );
                                }
                              }
                            } else if (e.visualization === "add_item") {
                              if (isDashboardEditable()) {
                                return (
                                  <Grid item xs={6} key={i}>
                                    <AddWidgetWrapper>
                                      <IconButton
                                        color="primary"
                                        onClick={() => {
                                          analytics.triggerEvent(
                                            4625000080,
                                            "add_widget_button_tap",
                                            "dashboard_page",
                                            "add_widget_button",
                                            {}
                                          );
                                          setOpenAddWidget(true);
                                        }}
                                      >
                                        <Add
                                          style={{
                                            width: "8vh",
                                            height: "8vh",
                                          }}
                                        />
                                      </IconButton>
                                      <Text>Add Widget</Text>
                                    </AddWidgetWrapper>
                                  </Grid>
                                );
                              }
                            }
                          })}
                        </Grid>
                      </Bottom>
                    ) : (
                      <NoWidgetTextWrapper>
                        <TextLg>
                          No widgets found! Please contact your administrator
                          for further assistance.
                        </TextLg>
                      </NoWidgetTextWrapper>
                    )
                  ) : (
                    <NoWidgetTextWrapper>
                      <TextLgHighlight
                        onClick={() => {
                          setOpenAddDashboard(true);
                        }}
                      >
                        Add a Dashboard to get started.
                      </TextLgHighlight>
                    </NoWidgetTextWrapper>
                  )
                ) : (
                  <NoWidgetTextWrapper>
                    <CircularProgress />
                  </NoWidgetTextWrapper>
                )}
              </Container>
            ) : (
              <Container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Container>
            )}
            <Footer />
          </>
        ) : (
          <NoAccessPage />
        )
      ) : (
        <NoAccessPermissionPage />
      )}
      {openAddWidget && (
        <AddChartModalV2
          open={openAddWidget}
          setOpen={setOpenAddWidget}
          refresh={refreshWidgets}
          setRefresh={setRefreshWidgets}
          dashboard_id={currentDashboardData["id"]}
        />
      )}
      {openAddDashboard && <AddDashboard />}
      {openEditDashboard && (
        <EditDashboardModal
          open={openEditDashboard}
          setOpen={setOpenEditDashboard}
          refresh={refresh}
          setRefresh={setRefresh}
          data={currentDashboardData}
        />
      )}
      {openSlideShow && (
        <DashboardWidgetSlideshow
          open={openSlideShow}
          setOpen={setOpenSlideShow}
          widgetList={widgetListToSlideshow}
        />
      )}
    </>
  );
};
export default observer(Dashboard);
