import { Grid } from "@material-ui/core";
import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SingleSelectNew from "../../select_dropdown/SingleSelectNew";
import SingleSelect from "../../select_dropdown/SingleSelect";
import { styled } from "@mui/material/styles";

const GroupLabel = styled("legend")`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 0px;
  width: auto;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;
const GroupFieldWidget = ({
  renderField,
  item,
  handleChange,
  details,
  setDetals,
  allChildList,
  isEdit,
  handleFilledParentChildMap,
}) => {
  const [loadingGroupField, setLoadingGroupField] = useState(false);
  const [childList, setChildList] = useState([]);

  const reArrangeChildFields = (list) => {
    let result = [...list];
    for (const item of result) {
      if (item.is_multiline) {
        item.width = 2;
        item.modifyWidth = false;
      } else {
        item.width = 1;
        item.modifyWidth = true;
      }
    }
    for (let i = 0; i < result.length; i++) {
      let currentItem = result[i];

      if (currentItem.modifyWidth) {
        if (i === result.length - 1) {
          currentItem.width = 2;
          currentItem.modifyWidth = false;
        } else {
          let nextItem = result[i + 1];

          if (nextItem.width === 2) {
            currentItem.width = 2;
            currentItem.modifyWidth = false;
          } else if (nextItem.width === 1) {
            currentItem.width = 1;
            currentItem.modifyWidth = false;
            nextItem.modifyWidth = false;
          }
        }
      }
    }
    return result;
  };

  const handleChildrenToRender = ({ parentValue }) => {
    setLoadingGroupField(true);
    let childFieldList = [];
    let childrenConfig = item.hasOwnProperty("children_config")
      ? item["children_config"]
      : null;
    if (childrenConfig !== null && childrenConfig.hasOwnProperty(parentValue)) {
      let childIdList = childrenConfig[parentValue];
      handleFilledParentChildMap(item["id"], childIdList);
      childIdList.forEach((id) => {
        let index = allChildList.findIndex((child) => child["id"] === id);
        if (index !== -1) {
          childFieldList.push(allChildList[index]);
        }
      });
    } else {
      handleFilledParentChildMap(item["id"], []);
    }
    let orderedList = reArrangeChildFields(childFieldList);
    setChildList(orderedList);
    setLoadingGroupField(false);
  };

  useEffect(() => {
    if (details.hasOwnProperty(item["field"])) {
      handleChildrenToRender({ parentValue: details[item["field"]]["value"] });
    }
  }, []);

  const renderParentField = ({ item }) => {
    if (
      item.filter_input.charAt(0) === "/" ||
      item.filter_input.startsWith("ep:")
    ) {
      return (
        <Grid item xs={12}>
          <InputWrapper>
            <Label style={{ fontSize: isEdit ? "12px" : "10px" }}>
              {item.headerName}
              {item.required && <span style={{ color: "red" }}>*</span>}
            </Label>
            <SingleSelect
              item={item}
              headerName={item.headerName}
              setSelectedVal={setDetals}
              selectedVal={details}
              field={item.field}
              required={item.required}
              type={item.data_type}
              handleChange={(event) => {
                handleChange(event);
                handleChildrenToRender({
                  parentValue: event.target.value,
                });
              }}
            />
          </InputWrapper>
        </Grid>
      );
    } else if (
      item.filter_input.charAt(0) !== "/" &&
      item.filter_input !== "" &&
      item.filter_input !== null
    ) {
      return (
        <Grid item xs={12}>
          <InputWrapper>
            <Label style={{ fontSize: isEdit ? "12px" : "10px" }}>
              {item.headerName}
              {item.required && <span style={{ color: "red" }}>*</span>}
            </Label>
            <SingleSelectNew
              item={item}
              list={item.filter_input}
              field={item.field}
              headerName={item.headerName}
              setSelectedVal={setDetals}
              selectedVal={details}
              required={item.required}
              type={item.data_type}
              handleChange={(event) => {
                handleChange(event);
                handleChildrenToRender({
                  parentValue: event.target.value,
                });
              }}
            />
          </InputWrapper>
        </Grid>
      );
    }
  };
  return (
    <Box
      component={"fieldset"}
      sx={{ border: "1px solid #cccccc", padding: "12px", width: "100%" }}
    >
      <GroupLabel style={{ fontSize: isEdit ? "12px" : "10px" }}>
        Group
      </GroupLabel>
      {!loadingGroupField ? (
        <Grid container spacing={2}>
          {renderParentField({ item: item })}
          {childList.length > 0 &&
            childList.map((data) => {
              return renderField({ item: data });
            })}
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress style={{ width: "18px", height: "18px" }} />
        </Box>
      )}
    </Box>
  );
};

export default GroupFieldWidget;
