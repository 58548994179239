const myTheme = {
  Button: {
    btnBlue: {
      backgroundColor: "#21589F",
      color: "white",
    },
    btnLight: {
      backgroundColor: "white",
      color: "#21589F",
    },
    Background: "#21589F",
    colorBlue: "#21589F",
  },
};

export { myTheme };
