import React from "react";
import { styled } from "@mui/material/styles";
import SingleSelect from "../select_dropdown/SingleSelect";
import SingleSelectNew from "../select_dropdown/SingleSelectNew";
import { observer } from "mobx-react-lite";
import { Box, Grid } from "@material-ui/core";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { getLocalizedText } from "../../Functions";

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 2px 6px;
  outline: none;
  border: 1px solid #e0deca;
  font: normal normal 600 10px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 12px Open Sans;
    color: #b5b5b5;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const UpdateFieldsWidget = (props) => {
  const [fieldList, setFieldList] = useState([]);
  const handleChange = ({ e, dataType }) => {
    let value =
      dataType === "integer" ? parseInt(e.target.value) : e.target.value;
    props.setValue({
      ...props.value,
      [e.target.name]: { value: value, type: dataType },
    });
  };

  const getRegex = (item) => {
    if (item.regex === null || item.regex === "") {
      return null;
    } else {
      return item.regex;
    }
  };

  function sortColumnFields() {
    let tempColumns = [...props.fieldList];

    let requiredFields = tempColumns
      .filter((item) => item["required"])
      .map((item) => item)
      .sort((a, b) =>
        a["headerName"]
          .toLowerCase()
          .localeCompare(b["headerName"].toLowerCase())
      );
    let allFields = [...requiredFields];

    let remainingFields = [];
    tempColumns.forEach((item) => {
      let index = allFields.findIndex(
        (element) => element["id"] === item["id"]
      );
      if (index === -1) {
        remainingFields.push(item);
      }
    });
    remainingFields.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    remainingFields.forEach((data) => {
      let index = allFields.findIndex(
        (element) => element["id"] === data["id"]
      );
      if (index === -1) {
        allFields.push(data);
      }
    });

    for (const item of allFields) {
      if (item.data_type === "datetime") {
        item.width = 2;
        item.modifyWidth = false;
      } else {
        item.width = 1;
        item.modifyWidth = true;
      }
    }

    for (let i = 0; i < allFields.length; i++) {
      let currentItem = allFields[i];

      if (currentItem.modifyWidth) {
        if (i === allFields.length - 1) {
          currentItem.width = 2;
          currentItem.modifyWidth = false;
        } else {
          let nextItem = allFields[i + 1];

          if (nextItem.width === 2) {
            currentItem.width = 2;
            currentItem.modifyWidth = false;
          } else if (nextItem.width === 1) {
            currentItem.width = 1;
            currentItem.modifyWidth = false;
            nextItem.modifyWidth = false;
          }
        }
      }
    }
    setFieldList(allFields);
  }

  useEffect(() => {
    sortColumnFields();
  }, [props.fieldList]);

  return (
    <Grid container spacing={2} item>
      {fieldList.map((item) => {
        if (item.filter_input === "" || item.filter_input === null) {
          if (item.data_type === "datetime") {
            return (
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>
                      {item.headerName}
                      {item.required && <span>*</span>}
                    </Label>

                    <Input
                      value={
                        props.value.hasOwnProperty(item.field)
                          ? props.value[item.field]["value"]["date"]
                          : ""
                      }
                      required={item.required}
                      type="date"
                      name="date"
                      onChange={(e) => {
                        let time = props.value.hasOwnProperty(item.field)
                          ? props.value[item.field]["value"]["time"]
                          : null;
                        props.setValue({
                          ...props.value,
                          [item.field]: {
                            value: { date: e.target.value, time: time },
                            type: item.data_type,
                          },
                        });
                      }}
                      style={{ marginBottom: "0px" }}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>
                      {item.required
                        ? `${getLocalizedText("time")}*`
                        : getLocalizedText("time")}
                    </Label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        onChange={(time) => {
                          let date = props.value.hasOwnProperty(item.field)
                            ? props.value[item.field]["value"]["date"]
                            : "";
                          props.setValue({
                            ...props.value,
                            [item.field]: {
                              value: { time: time, date: date },
                              type: item.data_type,
                            },
                          });
                        }}
                        value={
                          props.value.hasOwnProperty(item.field)
                            ? props.value[item.field]["value"]["time"]
                            : null
                        }
                        name="time"
                        InputProps={{
                          style: {
                            height: "30px",
                            width: "100%",
                            fontSize: "12px",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField {...params} required={item.required} />
                        )}
                      />
                    </LocalizationProvider>
                  </InputWrapper>
                </Grid>
              </Grid>
            );
          } else {
            return (
              <Grid item xs={item["width"] === 1 ? 6 : 12}>
                <InputWrapper>
                  <Label>
                    {item.headerName}
                    {item.required && <span>*</span>}
                  </Label>
                  <Input
                    type={
                      item.data_type === "email"
                        ? "email"
                        : item.data_type === "datetime" ||
                          item.data_type === "date"
                        ? "date"
                        : item.data_type === "integer"
                        ? "number"
                        : "text"
                    }
                    value={
                      props.value.hasOwnProperty(item.field)
                        ? props.value[item.field]["value"]
                        : ""
                    }
                    pattern={getRegex(item)}
                    required={item.required}
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        `Please enter a valid ${item.headerName}`
                      )
                    }
                    name={item.field}
                    placeholder={`Enter ${item.headerName}`}
                    onChange={(e) => {
                      e.target.setCustomValidity("");
                      let dataType = item.data_type;
                      handleChange({ e, dataType });
                    }}
                  />
                </InputWrapper>
              </Grid>
            );
          }
        } else if (
          item.filter_input.charAt(0) === "/" ||
          item.filter_input.startsWith("ep:")
        ) {
          return (
            <Grid item xs={item["width"] === 1 ? 6 : 12}>
              <InputWrapper>
                <Label>
                  {item.headerName}
                  {item.required && <span>*</span>}
                </Label>
                <SingleSelect
                  item={item}
                  headerName={item.headerName}
                  setSelectedVal={props.setValue}
                  selectedVal={props.value}
                  field={item.field}
                  required={item.required}
                  type={item.data_type}
                  handleChange={(e) =>
                    handleChange({ e, dataType: item["data_type"] })
                  }
                />
              </InputWrapper>
            </Grid>
          );
        } else if (
          item.filter_input.charAt(0) !== "/" &&
          item.filter_input !== "" &&
          item.filter_input !== null
        ) {
          return (
            <Grid item xs={item["width"] === 1 ? 6 : 12}>
              <InputWrapper>
                <Label>
                  {item.headerName}
                  {item.required && <span>*</span>}
                </Label>
                <SingleSelectNew
                  item={item}
                  list={item.filter_input}
                  field={item.field}
                  headerName={item.headerName}
                  setSelectedVal={props.setValue}
                  selectedVal={props.value}
                  required={item.required}
                  type={item.data_type}
                  handleChange={(e) =>
                    handleChange({ e, dataType: item["data_type"] })
                  }
                />
              </InputWrapper>
            </Grid>
          );
        }
      })}
    </Grid>
  );
};

export default observer(UpdateFieldsWidget);
