import { AddBoxOutlined, Edit, MoreHoriz } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getAdminStatusesApi, getTeamApi } from "../../Api";
import spinner from "../../assets/images/gifs/spinner.gif";
import { AdminStatusColumns, TeamSettingColumns } from "../../Db";
import AddStatusModal from "../admin_setting_modals/AddStatusModal";
import AddTeamModal from "../admin_setting_modals/AddTeamModal";
import analytics from "../../Analytics";
import ViewUpdateFieldsModal from "../admin_setting_modals/ViewUpdateFieldsModal";
import { IconButton } from "@material-ui/core";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const Container = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 234px);`};

  position: relative;
  /* margin-top: 70px; */
`;
const Wrapper = styled.div`
  display: flex;
`;

const DetailsWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const AddBtnWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;

const StatusTab = (props) => {
  const [row, setRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [viewUpdateFields, setViewUpdateFields] = useState(false);
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();
  const getStatusList = async () => {
    setRow([]);
    try {
      let response = await getAdminStatusesApi(props.id);
      let newData = [];
      if (response.data.length > 0) {
        newData = response.data.sort(function (a, b) {
          if (a.default > b.default) {
            return -1;
          }
          if (a.default < b.default) {
            return 1;
          }
          return 0;
        });
      }
      setRow(newData);
    } catch (error) {
      console.log(error);
    }
  };
  const init = async () => {
    await getStatusList();
    setLoading(false);
  };
  const refetch = async () => {
    await getStatusList();
    setRefresh(false);
  };
  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);
  useEffect(() => {
    init();
  }, []);
  const actionColumn = [
    {
      field: "update_fields",
      headerName: <Text>Fields to Update</Text>,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        if (params.row.update_fields.length > 0) {
          return (
            <IconButton
              onClick={() => {
                setEditableData(params.row);
                setViewUpdateFields(true);
              }}
            >
              <MoreHoriz color="primary" />
            </IconButton>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "action",
      headerName: <Text>Actions</Text>,
      sortable: false,
      width: 70,
      renderCell: (params) => {
        if (params.row.default) {
          return (
            <>
              <Edit
                onClick={(e) => {}}
                style={{ marginRight: "20px", width: "18px", height: "18px" }}
                color="disabled"
              />
            </>
          );
        } else {
          return (
            <>
              <Edit
                onClick={(e) => {
                  analytics.triggerEvent(
                    4625000212,
                    "edit_button_tap",
                    "project_statuses_page",
                    "edit_button",
                    {}
                  );
                  setEditableData(params.row);
                  setIsEdit(true);
                  setOpen(true);
                }}
                style={{ marginRight: "20px", width: "18px", height: "18px" }}
                color="primary"
              />
            </>
          );
        }
      },
    },
  ];
  return (
    <>
      <Container>
        <AddBtnWrapper>
          <AddBoxOutlined
            onClick={() => {
              analytics.triggerEvent(
                4625000208,
                "add_button_tap",
                "project_statuses_page",
                "add_button",
                {}
              );
              setOpen(true);
            }}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </AddBtnWrapper>
        <Wrapper>
          <DetailsWrapper>
            <TableWrapper>
              <DataGrid
                getRowHeight={() => "auto"}
                autoHeight={true}
                rows={row}
                columns={AdminStatusColumns.concat(actionColumn)}
                checkboxSelection={false}
                style={{ cursor: "pointer" }}
                hideFooter={true}
                disableColumnMenu={true}
                loading={loading}
              />
            </TableWrapper>
          </DetailsWrapper>
        </Wrapper>
      </Container>
      {open && (
        <AddStatusModal
          id={props.id}
          setOpen={setOpen}
          open={open}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          editableData={editableData}
          refresh={refresh}
          setRefresh={setRefresh}
          statusList={row}
        />
      )}
      {viewUpdateFields && (
        <ViewUpdateFieldsModal
          setOpen={setViewUpdateFields}
          open={viewUpdateFields}
          editableData={editableData}
        />
      )}
    </>
  );
};

export default StatusTab;
