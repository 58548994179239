import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import Footer from "../../components/landing_page_components/footer/Footer";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { Box, Grid, Typography } from "@mui/material";
import { getCampaignLeadsListApi, getCampaignStatsApi } from "../../Api";
import { useNavigate, useParams } from "react-router-dom";
import { pageSize } from "../../Config";
import {
  IsoToLocalTime,
  dataRenderer,
  getLocalizedText,
} from "../../Functions";
import { ChevronRight } from "@material-ui/icons";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const Container = styled(Box)`
  margin-top: 70px;
  background-color: #f9f9fc;
  ${`min-height: calc(100vh - 148px);`};
`;
const Wrapper = styled(Box)`
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const Bottom = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 396px);`};
  background-color: white;
  overflow-x: auto;
`;
const Top = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
  margin-bottom: 30px;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;

const Row = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
const Column = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Item = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 10px;
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); */
`;

const CardTitle = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  color: #a1b3d3;
  margin-bottom: 12px;
`;

const TextLg = styled(Typography)`
  font: normal normal 600 20px Open Sans;
  color: #4d4e4f;
`;
const LabelTextLg = styled(Typography)`
  font: normal normal 500 20px Open Sans;
  color: #4d4e4f;
`;
const TextSm = styled(Typography)`
  font: normal normal 500 10px Open Sans;
  color: #4d4e4f;
`;
const ProgressWrapper = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const WebformStatsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [tabValue, setTabValue] = useState("opened");
  const [data, setData] = useState({});
  const [row, setRow] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingListingData, setLoadingListingData] = useState(false);
  const [size, setSize] = useState(pageSize);

  //   const getCampaignStatsData = async () => {
  //     setLoading(true);
  //     let response = await getCampaignStatsApi(id);
  //     setData(response);
  //     setLoading(false);
  //   };
  //   useEffect(() => {
  //     getCampaignStatsData();
  //   }, []);

  //   useEffect(() => {
  //     getCampaignLeadsList({
  //       page_size: pageSize,
  //       page_no: 0,
  //     });
  //   }, [tabValue]);

  //   const getCampaignLeadsList = async ({ page_size, page_no }) => {
  //     setRow([]);
  //     setLoadingListingData(true);
  //     let response = await getCampaignLeadsListApi({
  //       campaign_id: id,
  //       page_size: page_size,
  //       page_no: page_no,
  //       scope: tabValue,
  //     });
  //     if (Object.keys(response).length > 0) {
  //       setRow(response.items);
  //       setCount(response.item_count);
  //     } else {
  //       setCount(0);
  //     }
  //     setLoadingListingData(false);
  //   };

  //   const handlePageSize = async (e) => {
  //     setSize(e.target.value);
  //     setPage(0);
  //     await getCampaignLeadsList({
  //       page_size: e.target.value,
  //       page_no: 0,
  //     });
  //   };
  //   const handlePagination = async (e, page) => {
  //     setPage(page - 1);
  //     await getCampaignLeadsList({
  //       page_size: size,
  //       page_no: page - 1,
  //     });
  //   };

  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));

  return (
    <>
      <Container>
        <Wrapper>
          <HeaderWrapper>
            <Text
              style={{ color: "#185DD2", cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Webforms
            </Text>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Text>{id}</Text>
          </HeaderWrapper>
          {!loading ? (
            <Top>
              <Grid container rowSpacing={3} columnSpacing={3}>
                <Grid item xs={3}>
                  <Item>
                    <CardTitle>Delivered</CardTitle>
                    <Column style={{ rowGap: "4px" }}>
                      <Row>
                        <LabelTextLg>Total :</LabelTextLg>
                        <TextLg>
                          {data.hasOwnProperty("total_delivered")
                            ? dataRenderer(data["total_delivered"])
                            : "-"}
                        </TextLg>
                      </Row>
                      <Column>
                        <Row>
                          <TextSm>First Delivered :</TextSm>
                          <TextSm>
                            {data.hasOwnProperty("first_delivered_at") &&
                            data["first_delivered_at"] !== null
                              ? IsoToLocalTime(data["first_delivered_at"])
                              : "-"}
                          </TextSm>
                        </Row>
                        <Row>
                          <TextSm>Last Delivered :</TextSm>
                          <TextSm>
                            {data.hasOwnProperty("last_delivered_at") &&
                            data["last_delivered_at"] !== null
                              ? IsoToLocalTime(data["last_delivered_at"])
                              : "-"}
                          </TextSm>
                        </Row>
                      </Column>
                    </Column>
                  </Item>
                </Grid>
                <Grid item xs={3}>
                  <Item>
                    <CardTitle>Opens</CardTitle>
                    <Column style={{ rowGap: "4px" }}>
                      <Row>
                        <LabelTextLg>Total :</LabelTextLg>
                        <TextLg>
                          {data.hasOwnProperty("total_opens")
                            ? dataRenderer(data["total_opens"])
                            : "-"}
                        </TextLg>
                      </Row>
                      <Column>
                        <Row>
                          <TextSm>First Opened :</TextSm>
                          <TextSm>
                            {data.hasOwnProperty("first_opened_at") &&
                            data["first_opened_at"] !== null
                              ? IsoToLocalTime(data["first_opened_at"])
                              : "-"}
                          </TextSm>
                        </Row>
                        <Row>
                          <TextSm>Last Opened :</TextSm>
                          <TextSm>
                            {data.hasOwnProperty("last_opened_at") &&
                            data["last_opened_at"] !== null
                              ? IsoToLocalTime(data["last_opened_at"])
                              : "-"}
                          </TextSm>
                        </Row>
                      </Column>
                    </Column>
                  </Item>
                </Grid>
              </Grid>
            </Top>
          ) : (
            <Top style={{ justifyContent: "center" }}>
              <CircularProgress />
            </Top>
          )}

          <Bottom>
            <TableWrapper>
              {!loadingListingData ? (
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    loadingOverlayComponent={<CircularProgress />}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={[]}
                    animateRows={true}
                  />
                </div>
              ) : (
                <ProgressWrapper>
                  <CircularProgress />
                </ProgressWrapper>
              )}
            </TableWrapper>
            {/* <PaginationComponent
              page_no={page}
              row={row}
              page_size={size}
              size={size}
              count={count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            /> */}
          </Bottom>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default WebformStatsPage;
