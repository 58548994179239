import gifImg1 from "./assets/images/online-learning.gif";
import gifImg2 from "./assets/images/gifs/gifImg2.gif";
import gifImg3 from "./assets/images/gifs/gifImg3.gif";
import gifImg4 from "./assets/images/gifs/gifImg4.gif";
import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import rootStore from "./stores/RootStore";
import moment from "moment";
import { toJS } from "mobx";
import enUsData from "./text_utilities/en-US.json";
import Cookies from "js-cookie";
import { domainName } from "./Config";

const { authStore, userStore } = rootStore;
//created separate file to store all the fuctions used in our different pages and sections
const Gif = styled.img`
  height: 100%;
  width: 100%;
  display: unset;
`;

const Days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const CustomDotLogin = ({ index, onClick, active }) => {
  return (
    <button
      className={active ? "activelogin" : "inactivelogin"}
      onClick={() => {
        onClick();
        ChangeIndex(index);
      }}
    ></button>
  );
};

let currIndex = -1;

function ChangeIndex(i) {
  currIndex = i;
  let element = <Gif src={gifImg1} />;
  if (currIndex == 2) {
    element = <Gif src={gifImg1} />;
  } else if (currIndex == 3) {
    element = <Gif src={gifImg2} />;
  } else if (currIndex == 4) {
    element = <Gif src={gifImg3} />;
  } else {
    element = <Gif src={gifImg4} />;
  }
  document.getElementById("default_gif").style.display = "none";
  ReactDOM.render(element, document.getElementById("gif_image"));
}

const validateUnmaskColumns = () => {
  let flag = false;
  let role = authStore.getCurrentProjectRoleDetails();
  if (role.hasOwnProperty("unmask_columns")) {
    if (role.unmask_columns === false) {
      if (userStore.UserData !== null) {
        if (userStore.UserData.unmask_columns === true) {
          flag = true;
        }
      }
    } else {
      flag = true;
    }
  }
  return flag;
};

const validateUnmaskColumnsAtProjectLevel = (role) => {
  if (role !== null) {
    return role.unmask_columns;
  } else {
    return userStore.UserData.unmask_columns;
  }
};

// below function will return date time string if it contains time also otherwise it will return only date (this is specifically used for view reports page only)
function getLocalDateTime(dateString) {
  const momentObj = moment(dateString);

  if (momentObj.isValid()) {
    if (
      momentObj.get("hour") === 0 &&
      momentObj.get("minute") === 0 &&
      momentObj.get("second") === 0
    ) {
      return momentObj.format("YYYY-MM-DD"); // Date only
    } else {
      return momentObj.format("YYYY-MM-DD HH:mm A"); // Date and time
    }
  }

  return "Invalid date";
}

const dateTimeHandler = (value) => {
  let date = new Date(0);
  date.setUTCSeconds(value);
  let newDate = date.toString();
  let dateString = moment(newDate).format("YYYY-MM-DD HH:mm A");
  if (dateString.includes("05:30 AM")) {
    dateString = moment(newDate).format("YYYY-MM-DD");
  }
  return dateString;
};

function IsoToLocalTime(isoString) {
  if (isoString !== null) {
    const localDateTime = moment.utc(isoString).local();
    return localDateTime.format("YYYY-MM-DD hh:mm A");
  } else {
    return "-";
  }
}

function IsoToLocalDate(isoString) {
  if (isoString !== null) {
    const localDateTime = moment.utc(isoString).local();
    return localDateTime.format("YYYY-MM-DD");
  } else {
    return "-";
  }
}
// Function to check if a date is more than X days ago
function isMoreThanXDaysAgo(days, dateString) {
  const xDaysAgo = moment().subtract(days, "days").startOf("day");
  // const xDaysAgo = moment().subtract(days, "minutes");
  const updatedAt = moment.utc(dateString).local();
  let flag = updatedAt.isBefore(xDaysAgo);
  return flag;
}

const dateHandler = (value) => {
  let d = new Date(0);
  d.setUTCSeconds(value);
  let newDate = d.toString();
  newDate = moment(newDate).format("YYYY-MM-DD HH:mm A");
  return newDate;
};
const tabsDateHandler = (value) => {
  let d = new Date(0);
  d.setUTCSeconds(value);
  let newDate = d.toString();
  newDate = moment(newDate).format("YYYY-MM-DD");
  return newDate;
};
const convertToUTC = (value) => {
  let newDate = new Date(value);
  newDate = newDate.toUTCString();
  return newDate;
};
function ampm(time) {
  if (time.value !== "") {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var suffix = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var displayTime = hours + ":" + minutes + " " + suffix;
    return displayTime;
  }
  return;
}

const validateDisabledFeature = (str) => {
  let flag = true;
  if (
    authStore.disabled_features !== null &&
    authStore.disabled_features !== undefined
  ) {
    const list = toJS(authStore.disabled_features);
    // console.log(list.includes(str), str, list);
    if (list.includes(str)) {
      flag = false;
    }
  }
  return flag;
};
const validateDisabledSubFeature = (str) => {
  let flag = true;
  if (authStore.disabled_sub_features !== null) {
    if (authStore.disabled_sub_features.includes(str)) {
      flag = false;
    }
  }
  return flag;
};

const IsSameUser = (id) => {
  const uid = localStorage.getItem("uid");
  return uid === id;
};

const currentDayNameGetter = () => {
  let d = new Date();
  return Days[d.getDay()];
};
const getLocalTimeFromIso = (isoString) => {
  if (isoString !== null && isoString !== "") {
    let localTime = moment.utc(isoString).local();
    localTime = localTime.format("HH:mm:ss");
    localTime = moment(localTime, "HH:mm:ss");
    return localTime;
  } else {
    return null;
  }
};
const isUnderWorkSchedule = () => {
  // let flag = false;
  // if (userStore.UserData !== null) {
  //   if (
  //     userStore.UserData.role === "admin" ||
  //     userStore.UserData.role === "owner"
  //   ) {
  //     flag = true;
  //   } else {
  //     if (authStore.currentProject !== null) {
  //       let list = authStore.currentProject;
  //       let today_day_name = "workday_" + currentDayNameGetter();
  //       if (list.hasOwnProperty(today_day_name)) {
  //         if (list[today_day_name]) {
  //           let start_time = getLocalTimeFromIso(list["work_start_time"]);
  //           let end_time = getLocalTimeFromIso(list["work_end_time"]);
  //           if (start_time !== null && end_time !== null) {
  //             let currentTime = moment().local();
  //             if (currentTime.isBetween(start_time, end_time)) {
  //               flag = true;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // return flag;
  return true;
};

const getLocalizedText = (key, scope = "global") => {
  let newKey = key;
  if (scope === "project") {
    if (authStore.currentProject !== null) {
      let project_type = authStore.currentProject["project_type"];
      newKey = project_type + "_" + key;
    }
  }
  if (enUsData.hasOwnProperty(newKey)) {
    return enUsData[newKey];
  } else {
    return key;
  }
};
const getLocalizedErrorText = (key, scope = "global") => {
  let newKey = key;
  if (scope === "project") {
    if (authStore.currentProject !== null) {
      let project_type = authStore.currentProject["project_type"];
      newKey = project_type + "_" + key;
    }
  }
  if (enUsData.hasOwnProperty(newKey)) {
    return enUsData[newKey];
  } else {
    return null;
  }
};

const updateViewList = () => {
  let role = authStore.getCurrentProjectRole();
  if (userStore.UserData !== null) {
    if (
      userStore.UserData.role === "admin" ||
      userStore.UserData.role === "owner" ||
      role === "manager"
    ) {
      let list = [getLocalizedText("all_leads", "project"), "Current View"];
      userStore.updateViewList({ view: list[0], viewList: list });
    } else {
      let list = [getLocalizedText("my_leads", "project"), "Current View"];
      userStore.updateViewList({ view: list[0], viewList: list });
    }
  } else {
    let list = [getLocalizedText("my_leads", "project"), "Current View"];
    userStore.updateViewList({ view: list[0], viewList: list });
  }
};

//feature access validation related function

function checkFeaturePermission(featureName) {
  if (userStore.UserData !== null) {
    let accountRole = userStore.UserData.role;
    let projectRole = authStore.getCurrentProjectRole();
    consoleLogger("project role::::", projectRole);
    switch (featureName) {
      case "dashboard":
      case "leads":
        if (accountRole === "owner") {
          return true;
        } else {
          if (projectRole === "") {
            return false;
          } else {
            return true;
          }
        }
      case "assign_leads":
      case "fresh_lead":
      case "update_stage":
        if (accountRole === "admin" || accountRole === "owner") {
          return true;
        } else {
          if (projectRole !== "member") {
            return true;
          }
          return false;
        }
      case "files":
      case "contacts":
      case "entities":
        if (accountRole === "admin" || accountRole === "owner") {
          return true;
        }
        return false;
      case "reports":
      case "imports":
        if (accountRole === "admin" || accountRole === "owner") {
          return true;
        } else {
          if (projectRole === "manager" || projectRole === "lead") {
            return true;
          }
          return false;
        }
      case "administrator_users":
      case "target":
      case "marketing":
        if (accountRole === "admin" || accountRole === "owner") {
          return true;
        } else {
          return false;
        }
      case "lead_listing_delete":
        if (accountRole === "owner") {
          return true;
        } else {
          if (projectRole !== "member") {
            return true;
          }
          return false;
        }
      case "lead_details_delete":
      case "lead_details_update_status":
        if (projectRole !== "member") {
          return true;
        }
        return false;
      case "lead_details_update_source":
      case "notification_assign_lead":
        if (accountRole === "admin" || accountRole === "owner") {
          return true;
        } else {
          if (projectRole === "manager") {
            return true;
          }
          return false;
        }
      case "dashboard_add_widget_filter":
        if (accountRole === "admin" || accountRole === "owner") {
          return true;
        } else {
          if (projectRole === "manager" || projectRole === "lead") {
            return true;
          }
          return false;
        }
      default:
      // code block
    }
  }
}

const dataRenderer = (val) => {
  if (val !== null && val !== "" && val !== undefined) {
    return val;
  } else {
    return "-";
  }
};
//**********End***************************** */

const getLocalTimeFromIsoString = (isoDateString) => {
  if (isoDateString !== null && isoDateString !== undefined) {
    return moment.utc(isoDateString).local().format("YYYY-MM-DD HH:mm:ss");
  } else {
    return "-";
  }
};

const consoleLogger = (indication_text = "", value) => {
  if (process.env.NODE_ENV !== "production") {
    console.log(indication_text, value);
  }
};

//below function is specifically used for leads listing pages
const isLastContactedToday = (isoStringTimestamp) => {
  if (isoStringTimestamp !== null) {
    const today = moment().startOf("day"); // Get today's date, time set to midnight
    const epochDate = moment(isoStringTimestamp).startOf("day"); // Convert ISO string to moment and set time to midnight

    return epochDate.isSame(today, "day");
  }
  return false;
};

function sortAndAssignWidthColumnSetup(columnMeta) {
  let tempColumns = [...columnMeta];

  tempColumns = tempColumns.filter((item) => item["filterable"]).slice();
  let allFields = tempColumns
    .filter((item) => item["child_of"] === null)
    .slice();
  allFields.sort((a, b) =>
    a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
  );

  let parentFields = tempColumns.filter((item) => item["parent"]).slice();
  let parentChildMap = {};
  for (let item of parentFields) {
    let parentId = item["id"];
    let children = tempColumns
      .filter(
        (child) =>
          child["child_of"] !== null && child["child_of"]["id"] === parentId
      )
      .slice()
      .sort((a, b) =>
        a["headerName"]
          .toLowerCase()
          .localeCompare(b["headerName"].toLowerCase())
      );
    parentChildMap[parentId] = children;
  }

  for (let parentId in parentChildMap) {
    let parentIndex = allFields.findIndex(
      (element) => element["id"] === parentId
    );
    if (parentIndex !== -1) {
      let children = parentChildMap[parentId];
      allFields.splice(parentIndex + 1, 0, ...children);
    }
  }
  for (const item of allFields) {
    if (item.filter_type === "normal" || item.filter_type === "") {
      item.width = 1;
      item.modifyWidth = true;
    } else if (item.filter_type === "range" || item.filter_type === "list") {
      item.width = 2;
      item.modifyWidth = false;
    }
  }

  for (let i = 0; i < allFields.length; i++) {
    let currentItem = allFields[i];

    if (currentItem.modifyWidth) {
      if (i === allFields.length - 1) {
        currentItem.width = 2;
        currentItem.modifyWidth = false;
      } else {
        let nextItem = allFields[i + 1];

        if (nextItem.width === 2) {
          currentItem.width = 2;
          currentItem.modifyWidth = false;
        } else if (nextItem.width === 1) {
          currentItem.width = 1;
          currentItem.modifyWidth = false;
          nextItem.modifyWidth = false;
        }
      }
    }
  }
  return allFields;
}
function getCurrentEpochTime() {
  // Get the current time in seconds since epoch using Moment.js
  const epochSeconds = moment().unix();
  return epochSeconds;
}

function capitalizeWord(input) {
  const words = input.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalizedWords.join(" ");
}
function isValidDateTime(dateTimeString) {
  const date = new Date(dateTimeString);

  // Check if the date object is valid and not equal to the default invalid date
  return !isNaN(date) && date.toString() !== "Invalid Date";
}
function isNumber(value) {
  return typeof value === "number" && !Number.isNaN(value);
}

function getIsoString(date) {
  const momentObj = moment(date);
  const isoString = momentObj.toISOString();
  return isoString;
}

// function to get ep to fetch data for dropdowns
function replacePlaceholdersWithProjectID(inputString) {
  let result = `projects/${rootStore.authStore.projectId}` + inputString;

  return result;
}

function replacePlaceholder(inputString, key, replacement) {
  const regex = new RegExp(`{${key}}`, "g");
  return inputString.replace(regex, replacement);
}

function replacePlaceholders(inputString) {
  const replacements = {
    project_id: rootStore.authStore.projectId,
    account_id: rootStore.userStore.UserData.account.id,
    team_id:
      rootStore.authStore.teamId !== null
        ? rootStore.authStore.teamId !== "All"
          ? rootStore.authStore.teamId
          : ""
        : null,
    user_id: rootStore.userStore.UserData.id,
  };
  let result = inputString;
  for (const key in replacements) {
    result = replacePlaceholder(result, key, replacements[key]);
  }
  return result;
}

const getSlug = (filterInput) => {
  if (filterInput.charAt(0) === "/") {
    return replacePlaceholdersWithProjectID(filterInput);
  } else if (filterInput.startsWith("ep:")) {
    let inputString = filterInput.replace("ep:", "");
    if (inputString.charAt(0) === "/") {
      inputString = inputString.slice(1);
    }
    return replacePlaceholders(inputString);
  }
  return filterInput;
};

//***********END*********************** */

const getJobTypeDisplayName = (key) => {
  switch (key) {
    case "reassign_leads":
      return "Reassign Leads";
    case "export_leads":
      return "Export Leads";
    case "export_leads_dialer_mcube":
      return "Export Leads For Mcube";
    case "export_leads_dialer_ozonetel":
      return "Export Leads For OzoneTel";
    case "delete_leads":
      return "Delete Leads";
    default:
      return "-";
  }
};

//*********************functions to get stage or status color code for widgets*************** */
const getUniqueStatusColorCodeList = () => {
  const uniqueColors = new Set(
    rootStore.authStore.statusList
      .map((status) => status.color)
      .filter((color) => color !== null)
  );
  return Array.from(uniqueColors);
};
const getUniqueStagesColorCodeList = () => {
  const uniqueColors = new Set(
    rootStore.authStore.stageList
      .map((stage) => stage.color)
      .filter((color) => color !== null)
  );
  return Array.from(uniqueColors);
};

const getRandomColor = (uniqueColors) => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  if (!uniqueColors.includes(color)) {
    return color;
  } else {
    getRandomColor(uniqueColors);
  }
};
const getStatusColorCode = (key) => {
  let uniqueColors = getUniqueStatusColorCodeList();
  if (uniqueColors.length === 0) {
    return getRandomColor(uniqueColors);
  } else {
    let index = rootStore.authStore.statusList.findIndex(
      (status) => status["status"] === key
    );
    if (index !== -1) {
      let color = rootStore.authStore.statusList[index]["color"];
      if (color !== null && color !== "") {
        return color;
      } else {
        return getRandomColor(uniqueColors);
      }
    } else {
      return getRandomColor(uniqueColors);
    }
  }
};

const getStageColorCode = (key) => {
  let uniqueColors = getUniqueStagesColorCodeList();
  if (uniqueColors.length === 0) {
    return getRandomColor(uniqueColors);
  } else {
    let index = rootStore.authStore.stageList.findIndex(
      (stage) => stage["stage"] === key
    );
    if (index !== -1) {
      let color = rootStore.authStore.stageList[index]["color"];
      if (color !== null && color !== "") {
        return color;
      } else {
        return getRandomColor(uniqueColors);
      }
    } else {
      return getRandomColor(uniqueColors);
    }
  }
};
//*********************************END*************************************************** */

//**********************Function to set cookie*************** */
const setCookie = (cookieName, cookieValue, options) => {
  Cookies.set(cookieName, cookieValue, {
    domain: domainName,
    path: "/",
    expires: 7,
    ...options,
  });
};
//**********************END******************************** */
export {
  responsive,
  CustomDotLogin,
  ChangeIndex,
  dateTimeHandler,
  dateHandler,
  tabsDateHandler,
  convertToUTC,
  ampm,
  validateUnmaskColumns,
  validateDisabledFeature,
  validateDisabledSubFeature,
  IsoToLocalTime,
  IsoToLocalDate,
  isMoreThanXDaysAgo,
  IsSameUser,
  isUnderWorkSchedule,
  getLocalizedText,
  getLocalizedErrorText,
  updateViewList,
  validateUnmaskColumnsAtProjectLevel,
  checkFeaturePermission,
  dataRenderer,
  getLocalDateTime,
  getLocalTimeFromIsoString,
  consoleLogger,
  isLastContactedToday,
  sortAndAssignWidthColumnSetup,
  getCurrentEpochTime,
  capitalizeWord,
  isValidDateTime,
  isNumber,
  getIsoString,
  replacePlaceholdersWithProjectID,
  replacePlaceholders,
  getSlug,
  getJobTypeDisplayName,
  getStatusColorCode,
  getStageColorCode,
  setCookie,
};
