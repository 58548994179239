import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
import { StackedBarConfig } from "../../../Config";
import rootStore from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { CircularProgress, Typography } from "@material-ui/core";
import {
  consoleLogger,
  getStageColorCode,
  getStatusColorCode,
} from "../../../Functions";
import { Box } from "@mui/material";
Chart.register(ArcElement);

const Container = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: ${(e) => (e.type === "preview" ? "0px" : "20px")};
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
`;

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
`;
const Text = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const TwoGroupBarGraphWidget = (props) => {
  const { userStore } = rootStore;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const generateRandomColor = (val) => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    userStore.updateColorsList({ key: val, color: color });
    return color;
  };

  function buildDataset(response, v2Response) {
    let labels = getLabels(response, v2Response["xdata"]["group_by"][0]);
    let dataset = [];
    let keyName = v2Response["xdata"]["group_by"][1];
    let columns = getLabels(response, keyName); // give me all the unique values for the dataset
    let aggregateBy = v2Response["xdata"].hasOwnProperty(
      "aggregate_by_col_names"
    )
      ? v2Response["xdata"]["aggregate_by_col_names"]
      : "count"; //here if xdata has the aggregate_by_col_names then it will use that else by default it will use "count"
    columns.map((column) => {
      let set = {
        label: column,
        data: [],
        backgroundColor: [],
      };
      let entires = response.filter((item) => item[keyName] === column);
      labels.map((label) => {
        let key = v2Response["xdata"]["group_by"][0];
        if (keyName === "lead_stage") {
          let color = getStageColorCode(column);
          if (
            set.backgroundColor.length === 0 ||
            set.backgroundColor.includes(color)
          ) {
            set.backgroundColor.push(color);
          }
        } else if (keyName === "lead_status") {
          let color = getStatusColorCode(column);
          if (
            set.backgroundColor.length === 0 ||
            set.backgroundColor.includes(color)
          ) {
            set.backgroundColor.push(color);
          }
        } else {
          let color = generateRandomColor(column);
          if (userStore.ColorsList.hasOwnProperty(column)) {
            color = userStore.ColorsList[column];
          }
          if (set.backgroundColor.length === 0) {
            color = generateRandomColor(column);
            set.backgroundColor.push(color);
          } else {
            if (set.backgroundColor.includes(color)) {
              set.backgroundColor.push(color);
            }
          }
        }

        let count = 0;
        entires.map((entry) => {
          if (entry[key] === label) {
            count += entry[aggregateBy];
          }
        });
        set["data"].push(count);
      });
      dataset.push(set);
    });
    return dataset;
  }

  function getLabels(response, key) {
    let labels = [];
    response.map((item) => {
      let value = item[key];
      if (labels.includes(value) == false) {
        labels.push(value);
      }
    });
    return labels;
  }

  const updateData = (response, v2Response) => {
    let newObj = {
      labels: [],
      datasets: [],
    };

    if (response.length > 0) {
      newObj.labels = getLabels(response, v2Response["xdata"]["group_by"][0]);
      newObj.datasets = buildDataset(response, v2Response);
    } else {
      newObj = {};
    }
    consoleLogger("result", newObj);
    return newObj;
  };
  const ChartBottomSection = () => {
    if (Object.keys(data).length === 0) {
      return (
        <Wrapper>
          <Typography>No data found!</Typography>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <Bar data={data} options={StackedBarConfig.options} />
        </Wrapper>
      );
    }
  };
  useEffect(() => {
    const getData = async () => {
      let groups = props.widgetConfig["xdata"]["group_by"];
      if (props.data.length > 0) {
        props.data.map((item) => {
          groups.map((key) => {
            if (item[key] === null) {
              item[key] = "NA";
            }
          });
        });
      }

      let modifiedData = updateData(props.data, props.widgetConfig);

      setData(modifiedData);
      setLoading(false);
    };

    getData();
  }, [props.widgetConfig]);
  return (
    <>
      {!loading ? (
        <Container type={props.type}>
          <Box>
            <Text>{props.widgetConfig.title}</Text>
          </Box>
          <ChartBottomSection />
        </Container>
      ) : (
        <Container style={{ alignItems: "center" }}>
          <CircularProgress />
        </Container>
      )}
    </>
  );
};

export default observer(TwoGroupBarGraphWidget);
