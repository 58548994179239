import { makeObservable, observable, action, toJS } from "mobx";
import { makePersistable } from "mobx-persist-store";
import {
  FormattedColumns,
  FormattedColumnsFilters,
  FormattedReportColumns,
} from "../ColumnUtils";
import { pageSize } from "../Config";
import {
  checkFeaturePermission,
  consoleLogger,
  getIsoString,
  isUnderWorkSchedule,
  updateViewList,
} from "../Functions";
import PhoneCallState from "../state/phoneCallState";
import {
  getAllColsApi,
  getColumnGroupsApi,
  getContactColumnGroupsApi,
  getContactColumnsListApi,
  getContactDetailsTabsApi,
  getCustomerColumnGroupsApi,
  getCustomerColumnsListApi,
  getCustomerDetailsTabsApi,
  getLeadCallsApi,
  getOrganizationColumnsListApi,
  getProductColumnsListApi,
  getProfileDataApi,
  getProjectApi,
  getRoleApi,
  getSourcesListApi,
  getStageStatusesApi,
  getStagesApi,
  getStatusApi,
  getTabsApi,
  getTeamApi,
  getViewGroupsApi,
  startDayApi,
} from "../Api";
import externalDialerNotificationService from "../services/externalDialerNotificationService";
import { digitalLenderState } from "../utils/enum";
import moment from "moment";
class RootStore {
  constructor() {
    this.userStore = new UserStore(this);
    this.filterStore = new FilterStore(this);
    this.authStore = new AuthStore(this);
    this.phoneCallState = new PhoneCallState(this);
    this.leadStore = new LeadStore(this);
    this.productStore = new ProductStore(this);
    this.contactStore = new ContactStore(this);
    this.customerStore = new CustomerStore(this);
    this.organizationStore = new OrganizationStore(this);
  }
}

class AuthStore {
  Exist = null;
  projectId = null;
  accountId = null;
  projectList = [];
  currentProject = null;
  disabled_features = null;
  disabled_sub_features = null;
  isInWorkTime = true;
  teamId = null;
  stageList = [];
  statusList = [];
  stageStatusList = [];
  sourcesList = [];
  Ul = [
    {
      phone: "",
      otp: null,
      account: "",
    },
  ];
  projectDetails = [];
  getStartedDate = null;
  lastActiveTime = "";
  leadDetailStatus = {
    in_lead_details_page: false,
    lead_id: "",
  };

  currentLeadInfo = {
    call_logs: {
      item_count: null,
      items: null,
      start_index: 0,
    },
  };

  searchQuery = null; //to store the search query entered in the searchbar, to use in search api call
  constructor() {
    makeObservable(this, {
      Ul: observable,
      teamId: observable,
      projectId: observable,
      accountId: observable,
      projectList: observable,
      currentProject: observable,
      isInWorkTime: observable,
      projectDetails: observable,
      Exist: observable,
      disabled_features: observable,
      disabled_sub_features: observable,
      stageList: observable,
      statusList: observable,
      getStartedDate: observable,
      lastActiveTime: observable,
      leadDetailStatus: observable,
      searchQuery: observable,
      currentLeadInfo: observable,
      stageStatusList: observable,
      sourcesList: observable,
      userLogin: action,
      getOtp: action,
      setExist: action,
      setProjectId: action,
      setAccountId: action,
      setTeamId: action,
      resetUserAuth: action,
      updateDisabledFeature: action,
      updateCurrentProject: action,
      updateIsInWorkTime: action,
      postInit: action,
      fetchProjects: action,
      fetchTeams: action,
      fetchColumnsMeta: action,
      fetchColumnGroups: action,
      fetchProjectRole: action,
      fetchStages: action,
      fetchStatuses: action,
      fetchSources: action,
      getCurrentProjectRoleDetails: action,
      getCurrentProjectRole: action,
      getCallingMode: action,
      getDigitalLenders: action,
      hasDigitalLender: action,
      getDigitalLenderMandatoryFields: action,
      getDigitalLenderOptionalFields: action,
      updateGetStartedDate: action,
      canShowStartDayDialog: action,
      updateLastActiveTime: action,
      fetchUserStartDayApiCall: action,
      updateLeadDetailStatus: action,
      updateSearchQuery: action,
      canRedirectToDahboard: action,
      fetchLeadCallLogs: action,
      getLeadCallDetails: action,
      addCallLog: action,
      resetLeadCallDetails: action,
    });
    makePersistable(this, {
      name: AuthStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [
        "Ul",
        "projectId",
        "currentProject",
        "isInWorkTime",
        "getStartedDate",
        "lastActiveTime",
      ],
    });
  }

  resetLeadCallDetails = () => {
    this.currentLeadInfo = {
      call_logs: {
        item_count: null,
        items: null,
        start_index: 0,
      },
    };
  };

  fetchLeadCallLogs = async ({ leadID, offset }) => {
    let response = await getLeadCallsApi({
      lid: leadID,
      page: offset,
      page_size: 25,
    });
    if (Object.keys(response).length > 0) {
      this.currentLeadInfo["call_logs"]["items"] = response.items;
      this.currentLeadInfo["call_logs"]["start_index"] = offset;
      this.currentLeadInfo["call_logs"]["item_count"] = response.item_count;
    }
  };

  addCallLog = (details) => {
    let itemCount = this.currentLeadInfo.call_logs.item_count;
    itemCount += 1;
    this.currentLeadInfo.call_logs.item_count = itemCount;
    if (this.currentLeadInfo.call_logs.start_index === 0) {
      let items = [...this.currentLeadInfo.call_logs.items];
      items.unshift(details);
      this.currentLeadInfo.call_logs.items = items;
    }
  };

  getLeadCallDetails = async ({ leadID, offset }) => {
    let tempObj = { items: [], item_count: 0 };
    if (
      this.currentLeadInfo.call_logs.items !== null &&
      this.currentLeadInfo.call_logs.item_count !== null
    ) {
      if (offset !== this.currentLeadInfo.call_logs.start_index) {
        await this.fetchLeadCallLogs({ leadID: leadID, offset: offset });
        tempObj["items"] = this.currentLeadInfo.call_logs.items;
        tempObj["item_count"] = this.currentLeadInfo.call_logs.item_count;
      } else {
        tempObj["items"] = this.currentLeadInfo.call_logs.items;
        tempObj["item_count"] = this.currentLeadInfo.call_logs.item_count;
      }
    } else {
      await this.fetchLeadCallLogs({ leadID: leadID, offset: offset });
      tempObj["items"] = this.currentLeadInfo.call_logs.items;
      tempObj["item_count"] = this.currentLeadInfo.call_logs.item_count;
    }
    return tempObj;
  };

  canRedirectToDahboard = () => {
    let account_role =
      rootStore.userStore.UserData !== null
        ? rootStore.userStore.UserData["role"]
        : "";
    let project_role = this.getCurrentProjectRole();
    return (
      account_role === "owner" ||
      account_role === "admin" ||
      project_role === "manager"
    );
  };

  updateSearchQuery = (value) => {
    this.searchQuery = value;
  };

  updateLeadDetailStatus = (lead_id = "") => {
    this.leadDetailStatus["lead_id"] = lead_id;
    this.leadDetailStatus["in_lead_details_page"] =
      lead_id !== "" ? true : false;
  };

  updateGetStartedDate = () => {
    let current_date = moment().toISOString();
    this.getStartedDate = current_date;
  };

  updateLastActiveTime = () => {
    const currentTime = moment().toISOString();
    this.lastActiveTime = currentTime;
  };

  canShowStartDayDialog = () => {
    if (
      localStorage.getItem("token") !== null &&
      localStorage.getItem("uid") !== null
    ) {
      if (this.getCurrentProjectRole().toLowerCase() === "member") {
        if (this.getStartedDate !== null) {
          return !moment(this.getStartedDate).isSame(moment(), "day");
        }
        return true;
      }
    }
    return false;
  };

  canFetchStartDayApi = () => {
    if (this.getCurrentProjectRole().toLowerCase() !== "member") {
      if (this.getStartedDate !== null) {
        return !moment(this.getStartedDate).isSame(moment(), "day");
      }
      return true;
    }
    return false;
  };

  fetchUserStartDayApiCall = async () => {
    let response_flag = await startDayApi();
    if (response_flag) {
      this.updateGetStartedDate();
      this.updateLastActiveTime();
    }
  };

  updateIsInWorkTime = (value) => {
    this.isInWorkTime = value;
  };
  setAccountId = (id) => {
    this.accountId = id;
  };
  updateCurrentProject = (value) => {
    this.currentProject = value;
  };

  getFeatureAndSubFeature = () => {
    let disabledFeatures = null;
    let disabledSubFeature = null;
    if (this.currentProject !== null) {
      disabledFeatures = this.currentProject.hasOwnProperty("disabled_features")
        ? this.currentProject["disabled_features"]
        : null;
      disabledSubFeature = this.currentProject.hasOwnProperty(
        "disabled_sub_features"
      )
        ? this.currentProject["disabled_sub_features"]
        : null;
    }
    return {
      disabled_features: disabledFeatures,
      disabled_sub_features: disabledSubFeature,
    };
  };

  updateDisabledFeature = () => {
    let feature_list = this.getFeatureAndSubFeature();
    if (
      typeof feature_list.disabled_features === "string" &&
      typeof feature_list.disabled_sub_features === "string"
    ) {
      feature_list.disabled_features = JSON.parse(
        feature_list.disabled_features
      );
      feature_list.disabled_sub_features = JSON.parse(
        feature_list.disabled_sub_features
      );
    }

    this.disabled_features = feature_list.disabled_features;
    this.disabled_sub_features = feature_list.disabled_sub_features;
  };
  userLogin = ({ phone, account }) => {
    this.Ul[0].phone = phone;
    this.Ul[0].account = account;
  };
  resetUserAuth = () => {
    this.projectId = null;
    this.projectList = [];
    this.teamId = null;
    this.accountId = null;
    this.isInWorkTime = true;
    this.currentProject = null;
    this.columnGroups = [];
    this.AllColumnsList = [];
    this.stageList = [];
    this.statusList = [];
    this.getStartedDate = null;
    this.lastActiveTime = "";
    this.leadDetailStatus["lead_id"] = "";
    this.leadDetailStatus["in_lead_details_page"] = false;
    this.searchQuery = null;
    rootStore.userStore.resetStore();
    rootStore.contactStore.resetStore();
    rootStore.productStore.resetStore();
    rootStore.customerStore.resetStore();
    rootStore.organizationStore.resetStore();
    rootStore.phoneCallState.reset();
    rootStore.leadStore.resetTabInfo();
  };
  getOtp = (value) => {
    this.Ul[0].otp = value;
  };
  setExist = (value) => {
    this.Exist = value;
  };
  setProjectId = (value) => {
    this.projectId = value;
  };
  setProjectList = (value) => {
    this.projectList = value;
  };

  setTeamId = (val) => {
    this.teamId = val;
  };

  //*******************data initialization after login success********************* */

  canSetAllTeamsByDefault = () => {
    let account_role =
      rootStore.userStore.UserData !== null
        ? rootStore.userStore.UserData["role"]
        : "";
    let project_role = this.getCurrentProjectRole();
    if (
      account_role === "owner" ||
      account_role === "admin" ||
      project_role === "manager"
    ) {
      if (
        this.currentProject !== null &&
        this.currentProject.hasOwnProperty("teams")
      ) {
        return this.currentProject["teams"].length > 1;
      }
    }
    return false;
  };

  postInit = async () => {
    if (
      localStorage.getItem("token") !== null &&
      localStorage.getItem("uid") !== null
    ) {
      await this.fetchProjects();
      consoleLogger(`Project list :::${JSON.stringify(this.projectList)}`);
      if (this.projectList.length > 0) {
        for (const project of this.projectList) {
          let role_response = await this.fetchProjectRole(project["id"]);
          let teams_response = await this.fetchTeams(project["id"]);
          project["role"] = role_response;
          project["teams"] = teams_response;
        }
        if (this.projectId === null) {
          this.projectId = this.projectList[0].id;
          this.currentProject = this.projectList[0];
          if (this.canSetAllTeamsByDefault()) {
            this.teamId = "All";
          } else {
            this.teamId = this.projectList[0]["teams"][0]["id"];
          }
        } else {
          const index = this.projectList.findIndex(
            (obj) => obj.id === this.projectId
          );
          if (index === -1) {
            this.projectId = this.projectList[0].id;
            this.currentProject = this.projectList[0];
            if (this.canSetAllTeamsByDefault()) {
              this.teamId = "All";
            } else {
              this.teamId = this.projectList[0]["teams"][0]["id"];
            }
          } else {
            this.currentProject = this.projectList[index];
            if (this.canSetAllTeamsByDefault()) {
              this.teamId = "All";
            } else {
              this.teamId = this.projectList[index]["teams"][0]["id"];
            }
          }
        }
        this.updateDisabledFeature();
        await this.fetchColumnsMeta(this.projectId);
        await this.fetchColumnGroups(this.projectId);
        this.fetchStages();
        this.fetchStageStatuses();
        this.fetchStatuses();
        this.fetchSources();
        await rootStore.leadStore.setupTabs();
      }
      await rootStore.phoneCallState.init();
      rootStore.contactStore.init();
      rootStore.customerStore.init();
      externalDialerNotificationService.init();
    }
  };

  resetOnProjectChange = () => {
    rootStore.userStore.resetCurrentFilter();
    rootStore.userStore.resetRawLeadFilters();
    rootStore.userStore.updateFilterDrawerState(true);
    rootStore.userStore.updateCurrentLoadedFilter({});
    rootStore.leadStore.resetTabInfo();
  };

  updateOnProjectChange = async (id) => {
    this.resetOnProjectChange();
    let index = this.projectList.findIndex((obj) => obj.id === id);
    if (index !== -1) {
      this.projectId = id;
      this.currentProject = this.projectList[index];
      if (this.canSetAllTeamsByDefault()) {
        this.teamId = "All";
      } else {
        this.teamId = this.projectList[index]["teams"][0]["id"];
      }
      this.updateDisabledFeature();
      rootStore.phoneCallState.registerCallService();
      externalDialerNotificationService.close();
      externalDialerNotificationService.init();
    }

    await this.fetchColumnsMeta(id);
    await this.fetchColumnGroups(id);
    this.fetchStages();
    this.fetchStageStatuses();
    this.fetchStatuses();
    this.fetchSources();
    await rootStore.leadStore.setupTabs();
  };

  fetchProjects = async () => {
    let response = await getProjectApi();
    this.projectList = response;
  };

  fetchStages = async () => {
    let response = await getStagesApi();
    this.stageList = response;
  };
  fetchSources = async () => {
    let response = await getSourcesListApi();
    this.sourcesList = response;
  };

  fetchStageStatuses = async () => {
    let response = await getStageStatusesApi();
    this.stageStatusList = response;
  };

  fetchStatuses = async () => {
    let response = await getStatusApi();
    this.statusList = response;
  };

  fetchTeams = async (project_id) => {
    try {
      let response = await getTeamApi(project_id);
      if (response.length > 1) {
        response.push({ id: "All", name: "All Teams" });
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  fetchColumnsMeta = async (project_id) => {
    try {
      let response = await getAllColsApi(project_id);
      rootStore.userStore.updateAllColumnsList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  fetchColumnGroups = async (project_id) => {
    try {
      let response = await getColumnGroupsApi(project_id);
      rootStore.userStore.updateColumnGroups(response);
    } catch (error) {
      console.log(error);
    }
  };

  fetchProjectRole = async (project_id) => {
    return await getRoleApi(project_id);
  };

  getCurrentProjectRoleDetails = () => {
    if (this.currentProject !== null) {
      if (this.currentProject.hasOwnProperty("role")) {
        let role = this.currentProject["role"];
        if (Object.keys(role).length > 0) {
          return role;
        }
      }
    }
    return {};
  };

  getCurrentProjectRole = () => {
    let roleDetails = this.getCurrentProjectRoleDetails();
    if (roleDetails.hasOwnProperty("role")) {
      return roleDetails["role"];
    } else {
      return "";
    }
  };

  getCallingMode = () => {
    let roleDetails = this.getCurrentProjectRoleDetails();
    let callingMode = roleDetails.hasOwnProperty("calling_mode")
      ? roleDetails["calling_mode"]
      : null;
    if (callingMode !== null) {
      return callingMode;
    } else {
      callingMode = this.currentProject.hasOwnProperty("calling_mode")
        ? this.currentProject["calling_mode"]
        : null;

      if (callingMode !== null) {
        return callingMode;
      }
    }
    return "Manual";
  };

  getDigitalLenders = () => {
    let lenders = this.currentProject.hasOwnProperty("digital_lenders")
      ? this.currentProject["digital_lenders"]
      : [];
    return lenders;
  };

  hasDigitalLender = (value) => {
    let lenders = this.getDigitalLenders();
    let index = lenders.findIndex((element) => element["lender"] === value);
    return index === -1 ? false : true;
  };
  getDigitalLenderMandatoryFields = (value) => {
    let lenders = this.getDigitalLenders();
    let index = lenders.findIndex((element) => element["lender"] === value);
    if (index === -1) {
      return [];
    } else {
      return lenders[index]["mandatory_fields"];
    }
  };
  getDigitalLenderOptionalFields = (value) => {
    let lenders = this.getDigitalLenders();
    let index = lenders.findIndex((element) => element["lender"] === value);
    if (index === -1) {
      return [];
    } else {
      return lenders[index]["optional_fields"];
    }
  };

  //**********************************END******************************************** */
}
class UserStore {
  Basic = [{ name: "", email: "", phone: "", role: "" }];
  Info = [{ state: "", district: "", school: "", branch: "" }];
  Exp = "";
  TabId = "";
  Paging = {};
  PageSize = {};
  filterStagesList = [];
  filterStatusList = [];
  RawColumnHeaders = [];
  ColumnHeaders = [];
  FilterColumnHeaders = [];
  ReportColumnHeaders = [];
  searchColumns = [];
  start_date = null;
  end_date = null;
  globalFilters = {};
  reportFilters = {};
  tabFilters = {};
  sortingTabs = {};
  segmentsList = [];
  ColorsList = {};
  TargetColorList = {};
  teamsTarget = {};
  blackList = {};
  activeList = {};
  reportColumns = [];
  allColumns = [];
  filters_tab = {
    phone: "",
    lead_owner: "",
    city: "",
    state: "",
    custom_param_active_score_min: "",
    custom_param_active_score_max: "",
    lead_created_at_from: "",
    lead_created_at_end: "",
    last_contacted_on: "",
    next_followup_on: "",
  };
  viewList = null;
  view = "";
  Subject = [];
  Grade = [];
  UserData = null;
  AllColumnsList = [];
  columnGroups = [];
  currentFilterViewId = null;
  currentFilterPayload = {};
  currentFilterPage = 0;
  currentFilterPageSize = pageSize;
  currentFilterRenderList = {};
  inUseFiltersList = [];

  isFilterDrawerOpen = true;
  //***************raw lead filter varaible */
  rawLeadFilters = {};

  //***************Create Lead in Money View related variables****************** */

  projectMemberMapping = null; //***this variable is used for admin projects members mapping ,getting used in add project member */

  moneyViewStepperState = {
    currentStep: -1,
    step: {
      0: {
        status: digitalLenderState.idle,
      },
      1: {
        status: digitalLenderState.idle,
      },
      2: {
        status: digitalLenderState.idle,
      },
    },
  };

  savedFilterTemplateList = {};
  currentLoadedFilter = {};

  tabCounterList = {};

  tabInfo = {};
  constructor() {
    makeObservable(this, {
      Basic: observable,
      sortingTabs: observable,
      activeList: observable,
      reportColumns: observable,
      ColumnHeaders: observable,
      reportFilters: observable,
      RawColumnHeaders: observable,
      FilterColumnHeaders: observable,

      viewList: observable,
      view: observable,
      allColumns: observable,
      teamsTarget: observable,
      ReportColumnHeaders: observable,
      segmentsList: observable,
      filters_tab: observable,
      ColorsList: observable,
      TargetColorList: observable,
      filterStagesList: observable,
      filterStatusList: observable,
      searchColumns: observable,
      globalFilters: observable,
      tabFilters: observable,
      start_date: observable,
      end_date: observable,
      Paging: observable,
      PageSize: observable,
      TabId: observable,
      Info: observable,
      Exp: observable,
      UserData: observable,
      Subject: observable,
      Grade: observable,
      blackList: observable,
      AllColumnsList: observable,
      columnGroups: observable,
      //money view create lead related variables
      moneyViewStepperState: observable,

      //filterv2 related variables
      currentFilterViewId: observable,
      currentFilterPayload: observable,
      currentFilterPage: observable,
      currentFilterPageSize: observable,
      currentFilterRenderList: observable,
      inUseFiltersList: observable,
      rawLeadFilters: observable,
      isFilterDrawerOpen: observable,
      ///end////////////////
      savedFilterTemplateList: observable,
      currentLoadedFilter: observable,
      tabCounterList: observable,
      projectMemberMapping: observable,
      createUser: action,
      userInfo: action,
      userProfession: action,
      getData: action,
      setTabFilters: action,
      setGlobalFilters: action,
      setTabId: action,
      updateDefaultSelectColumns: action,
      formatRawColumnHeaders: action,
      formatFilterColumnHeaders: action,
      formatReportColumnHeaders: action,
      updateFilterStatusList: action,
      updateFilterStagesList: action,
      updatePaging: action,
      updatePageSize: action,
      setStartDate: action,
      setEndDate: action,
      setDateRange: action,
      setSearchColumns: action,
      setSegmentsList: action,
      updateColorsList: action,
      updateTargetColorList: action,
      updateSortingTabs: action,
      updateTeamsTarget: action,
      resetTeamsTarget: action,
      queryGlobalFilters: action,
      queryTabFilters: action,
      updateBlackList: action,
      resetBlackList: action,
      setReportFilters: action,
      resetReportFilters: action,
      setReportColumns: action,
      setAllColumns: action,
      setActiveList: action,
      resetActiveList: action,
      updateViewList: action,
      updateView: action,
      updateAllColumnsList: action,
      updateColumnGroups: action,
      //filterv2 related actions

      updateCurrentFilterPayload: action,
      updateCurrentFilterPageSize: action,
      updateCurrentFilterViewId: action,
      updateCurrentFilterPage: action,
      updateCurrentFilterRenderList: action,
      resetCurrentFilter: action,
      updateInUseFilterList: action,

      updateMoneyViewCurrentStep: action,
      updateMoneyViewStepsStatus: action,
      resetCreateLeadStepper: action,
      isGroupEditable: action,
      updateRawLeadFilters: action,
      resetRawLeadFilters: action,
      fetchUserData: action,
      updateFilterDrawerState: action,
      updateSavedFilterTemplateList: action,
      resetSavedFilterTemplateList: action,
      updateCurrentLoadedFilter: action,
      updateTabCounterList: action,
      resetTabCounterList: action,
      resetTabInfo: action,
      initProjectMemberMapping: action,
      updateProjectMemberMapping: action,
      resetProjectMemberMapping: action,
      resetStore: action,
    });
    makePersistable(this, {
      name: UserStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [
        "Basic",
        "Info",
        "Exp",
        "Subject",
        "start_date",
        "end_date",
        "Grade",
        "TabId",
        "ColumnHeaders",
        "searchColumns",
        "ReportColumnHeaders",
        "blackList",
        "savedFilterTemplateList",
      ],
    });
  }

  resetStore = () => {
    this.resetCurrentFilter();
    this.resetRawLeadFilters();
    this.updateFilterDrawerState(true);
    this.getData(null);
    this.resetSavedFilterTemplateList();
    this.resetTabCounterList();
    this.resetProjectMemberMapping();
  };

  initProjectMemberMapping = (list) => {
    this.projectMemberMapping = list;
  };
  updateProjectMemberMapping = ({ projectID, list }) => {
    this.projectMemberMapping[projectID] = list;
  };

  resetProjectMemberMapping = () => {
    this.projectMemberMapping = null;
  };

  resetTabInfo = () => {
    this.tabInfo = {};
  };

  updateTabCounterList = ({ tabID, count }) => {
    this.tabCounterList[tabID] = count;
    if (this.tabInfo.hasOwnProperty(tabID)) {
      this.tabInfo[tabID]["count"] = count;
    } else {
      this.tabInfo[tabID] = this.getDefaulttabInfo("count", count);
    }
  };
  resetTabCounterList = () => {
    this.tabCounterList = {};
  };
  updateSavedFilterTemplateList = ({ fitlerName, viewId, filters }) => {
    let projectId = rootStore.authStore.projectId;
    if (this.savedFilterTemplateList.hasOwnProperty(projectId)) {
      this.savedFilterTemplateList[projectId][fitlerName] = {
        name: fitlerName,
        viewId: viewId,
        filters: filters,
      };
    } else {
      this.savedFilterTemplateList[projectId] = {
        [fitlerName]: { name: fitlerName, viewId: viewId, filters: filters },
      };
    }
  };
  updateCurrentLoadedFilter = (value) => {
    this.currentLoadedFilter = value;
  };

  resetSavedFilterTemplateList = () => {
    this.savedFilterTemplateList = {};
    this.currentLoadedFilter = {};
  };
  updateFilterDrawerState = (flag) => {
    this.isFilterDrawerOpen = flag;
  };

  fetchUserData = async () => {
    try {
      let response = await getProfileDataApi();
      this.UserData = response.data;
      rootStore.authStore.setAccountId(response.data.account.id);
    } catch (error) {
      this.UserData = null;
      console.log(error);
    }
  };

  updateRawLeadFilters = (filters) => {
    this.rawLeadFilters = filters;
  };

  resetRawLeadFilters = () => {
    this.rawLeadFilters = {};
  };

  isGroupEditable = (groupName) => {
    let index = this.columnGroups.findIndex(
      (element) => element["group_name"] === groupName
    );
    if (index !== -1) {
      return this.columnGroups[index]["editable"];
    }
    return false;
  };

  updateMoneyViewCurrentStep = (step) => {
    this.moneyViewStepperState["currentStep"] = step;
  };
  updateMoneyViewStepsStatus = (step, state) => {
    consoleLogger("rootstore stepper state:::", state, "step::::", step);
    this.moneyViewStepperState["step"][step]["status"] = state;
  };
  resetCreateLeadStepper = () => {
    this.moneyViewStepperState = {
      currentStep: -1,
      step: {
        0: {
          status: digitalLenderState.idle,
        },
        1: {
          status: digitalLenderState.idle,
        },
        2: {
          status: digitalLenderState.idle,
        },
      },
    };
  };
  //filterv2 related fucntions////////
  resetCurrentFilter = () => {
    this.inUseFiltersList = [];
    this.currentFilterPage = 0;
    this.currentFilterPageSize = pageSize;
    this.currentFilterPayload = {};
    this.currentFilterRenderList = {};
    this.currentFilterViewId = null;
  };
  updateInUseFilterList = (list) => {
    this.inUseFiltersList = list;
  };
  updateCurrentFilterViewId = (id) => {
    consoleLogger("current view id is", id);
    this.currentFilterViewId = id;
  };
  updateCurrentFilterRenderList = (value) => {
    this.currentFilterRenderList = value;
  };
  updateCurrentFilterPayload = (value) => {
    this.currentFilterPayload = value;
  };
  updateCurrentFilterPage = (page) => {
    this.currentFilterPage = page;
  };
  updateCurrentFilterPageSize = (size) => {
    this.currentFilterPageSize = size;
  };
  //////////end/////////////////
  updateAllColumnsList = (list) => {
    this.AllColumnsList = list;
  };
  updateColumnGroups = (list) => {
    this.columnGroups = list;
  };
  updateViewList = ({ view, viewList }) => {
    this.viewList = viewList;
    this.view = view;
  };
  updateView = (view) => {
    this.view = view;
    consoleLogger("changing view in store", this.view);
  };
  updateBlackList = (val) => {
    this.blackList[val] = true;
  };
  resetBlackList = () => {
    this.blackList = {};
  };
  setActiveList = (id) => {
    this.activeList[id] = true;
  };
  resetActiveList = () => {
    this.activeList = {};
  };

  updateTeamsTarget = (val) => {
    this.teamsTarget = val;
  };
  resetTeamsTarget = () => {
    this.teamsTarget = { 1: 0, 2: 0 };
  };
  updateSortingTabs = ({ id, val, direction, flag }) => {
    this.sortingTabs[id] = {
      field_name: val,
      direction: direction,
      flag: flag,
    };
  };
  setReportColumns = (val) => {
    this.reportColumns = val;
  };
  updateColorsList = ({ key, color }) => {
    this.ColorsList[key] = color;
  };
  updateTargetColorList = ({ key, color }) => {
    this.TargetColorList[key] = color;
  };
  setSearchColumns = (val) => {
    this.searchColumns = val;
  };
  queryGlobalFilters = (val) => {
    this.globalFilters = val;
  };
  setAllColumns = (val) => {
    this.allColumns = val;
  };
  queryTabFilters = ({ id, val }) => {
    this.tabFilters[id] = val;
  };
  setTabFilters = ({ id, filters, dateRange, numRange, selectedVal }) => {
    let newFilters = {};
    let newDateRange = {};
    if (Object.keys(filters).length !== 0) {
      Object.keys(filters).map((key) => {
        if (
          filters[key]["type"] === "date" ||
          filters[key]["type"] === "datetime"
        ) {
          let newDate = getIsoString(filters[key]["value"]);
          newFilters[key] = newDate;
        } else {
          newFilters[key] = filters[key]["value"];
        }
      });
    }
    if (Object.keys(dateRange).length !== 0) {
      Object.keys(dateRange).map((key) => {
        let d1 = getIsoString(dateRange[key]["start"]);
        let d2 = getIsoString(dateRange[key]["end"]);
        newDateRange[key] = {
          start: d1,
          end: d2,
        };
      });
    }

    let filtersData = {
      ...newFilters,
      ...newDateRange,
      ...numRange,
      ...selectedVal,
    };
    let custom_params = {};
    Object.keys(filtersData).map((key) => {
      let index = this.AllColumnsList.findIndex(
        (column) => column["field"] === key
      );
      if (index !== -1) {
        if (this.AllColumnsList[index]["is_custom_param"]) {
          let keyName = key.replace("custom_params_", "");
          let newValue = filtersData[key];
          delete filtersData[key];
          custom_params[keyName] = newValue;
        }
      }
    });
    filtersData["custom_params"] = custom_params;
    this.tabFilters[id] = filtersData;
  };
  resetTabFilters = (val) => {
    if (this.tabFilters.hasOwnProperty(val)) {
      this.tabFilters[val] = null;
    }
  };
  setGlobalFilters = ({ filters, dateRange, numRange, selectedVal }) => {
    let newFilters = {};
    let newDateRange = {};
    if (Object.keys(filters).length !== 0) {
      Object.keys(filters).map((key) => {
        if (
          filters[key]["type"] === "date" ||
          filters[key]["type"] === "datetime"
        ) {
          let newDate = getIsoString(filters[key]["value"]);
          newFilters[key] = newDate;
        } else {
          newFilters[key] = filters[key]["value"];
        }
      });
    }
    if (Object.keys(dateRange).length !== 0) {
      Object.keys(dateRange).map((key) => {
        let d1 = getIsoString(dateRange[key]["start"]);
        let d2 = getIsoString(dateRange[key]["end"]);
        newDateRange[key] = {
          start: d1,
          end: d2,
        };
      });
    }
    let filtersData = {
      ...newFilters,
      ...newDateRange,
      ...numRange,
      ...selectedVal,
    };
    let custom_params = {};
    Object.keys(filtersData).map((key) => {
      let index = this.AllColumnsList.findIndex(
        (column) => column["field"] === key
      );
      if (index !== -1) {
        if (this.AllColumnsList[index]["is_custom_param"]) {
          let keyName = key.replace("custom_params_", "");
          let newValue = filtersData[key];
          delete filtersData[key];
          custom_params[keyName] = newValue;
        }
      }
    });
    filtersData["custom_params"] = custom_params;
    this.globalFilters = filtersData;
  };
  setReportFilters = ({ filters, dateRange, numRange, selectedVal }) => {
    let newFilters = {};
    let newDateRange = {};
    if (Object.keys(filters).length !== 0) {
      Object.keys(filters).map((key) => {
        if (
          filters[key]["type"] === "date" ||
          filters[key]["type"] === "datetime"
        ) {
          let newDate = getIsoString(filters[key]["value"]);
          newFilters[key] = newDate;
        } else {
          newFilters[key] = filters[key]["value"];
        }
      });
    }
    if (Object.keys(dateRange).length !== 0) {
      Object.keys(dateRange).map((key) => {
        let d1 = getIsoString(dateRange[key]["start"]);
        let d2 = getIsoString(dateRange[key]["end"]);
        newDateRange[key] = {
          start: d1,
          end: d2,
        };
      });
    }
    let filtersData = {
      ...newFilters,
      ...newDateRange,
      ...numRange,
      ...selectedVal,
    };
    let custom_params = {};
    Object.keys(filtersData).map((key) => {
      let index = this.AllColumnsList.findIndex(
        (column) => column["field"] === key
      );
      if (index !== -1) {
        if (this.AllColumnsList[index]["is_custom_param"]) {
          let keyName = key.replace("custom_params_", "");
          let newValue = filtersData[key];
          delete filtersData[key];
          custom_params[keyName] = newValue;
        }
      }
    });
    if (Object.keys(custom_params).length > 0) {
      filtersData["custom_params"] = custom_params;
    }
    this.reportFilters = filtersData;
  };
  resetReportFilters = () => {
    this.reportFilters = {};
  };
  setSegmentsList = ({
    segmentName,
    filters,
    dateRange,
    numRange,
    selectedVal,
  }) => {
    let filtersData = { ...filters, ...dateRange, ...numRange, ...selectedVal };
    let custom_params = {};
    Object.keys(filtersData).map((key) => {
      let index = this.AllColumnsList.findIndex(
        (column) => column["field"] === key
      );
      if (index !== -1) {
        if (this.AllColumnsList[index]["is_custom_param"]) {
          let keyName = key.replace("custom_params_", "");
          let newValue = filtersData[key];
          delete filtersData[key];
          custom_params[keyName] = newValue;
        }
      }
    });
    filtersData["custom_params"] = custom_params;
    let finalObj = { name: segmentName, value: filtersData };
    this.segmentsList.push(finalObj);
  };
  setDateRange = (val) => {
    consoleLogger("in root store date range:", val);
    this.filters_tab.lead_created_at_from = val.lead_created_at_from;
    this.filters_tab.lead_created_at_end = val.lead_created_at_end;
  };

  getDefaulttabInfo = (key, value) => {
    let defaultStruture = {
      page: 0,
      page_size: pageSize,
      columns: [],
      count: 0,
    };
    defaultStruture[key] = value;
    return defaultStruture;
  };
  updatePaging = ({ id, page }) => {
    if (this.tabInfo.hasOwnProperty(id)) {
      this.tabInfo[id]["page"] = page;
    } else {
      this.tabInfo[id] = this.getDefaulttabInfo("page", page);
    }
  };
  updatePageSize = ({ id, size }) => {
    if (this.tabInfo.hasOwnProperty(id)) {
      this.tabInfo[id]["page_size"] = size;
    } else {
      this.tabInfo[id] = this.getDefaulttabInfo("page_size", size);
    }
  };
  updateFilterStagesList = (val) => {
    let list = [];
    if (val.length) {
      val.map((e) => {
        list.push(e.value);
      });
      this.filterStagesList = list;
    } else {
      this.filterStagesList = list;
    }
  };
  updateFilterStatusList = (val) => {
    let list = [];
    if (val.length) {
      val.map((e) => {
        list.push(e.value);
      });
      this.filterStatusList = list;
    } else {
      this.filterStatusList = list;
    }
  };
  setStartDate = (val) => {
    this.start_date = val;
  };
  setEndDate = (val) => {
    this.end_date = val;
  };
  formatFilterColumnHeaders = (val) => {
    this.FilterColumnHeaders = FormattedColumnsFilters(val);
  };
  updateDefaultSelectColumns = (val) => {
    this.ColumnHeaders = val;
  };
  formatRawColumnHeaders = (val) => {
    this.ColumnHeaders = FormattedColumns(val);
  };
  formatReportColumnHeaders = (val) => {
    this.ReportColumnHeaders = FormattedReportColumns(val);
  };
  setTabId = (val) => {
    this.TabId = val;
  };
  getData = (response) => {
    this.UserData = response;
  };
  createUser = ({ name, email, phone, role }) => {
    this.Basic[0].name = name;
    this.Basic[0].email = email;
    this.Basic[0].phone = phone;
    this.Basic[0].role = role;
  };

  userInfo = ({ state, district, school, branch }) => {
    this.Info[0].state = state;
    this.Info[0].district = district;
    this.Info[0].school = school;
    this.Info[0].branch = branch;
  };

  userProfession = ({ subject, grade, experience }) => {
    this.Subject = Array.from(subject);
    this.Grade = Array.from(grade);
    this.Exp = experience;
  };
}
class FilterStore {
  Filters = [];
  SheetFilter = [];
  SheetData = [];
  ContentData = [];
  TabValue = 0;
  CallTabValue = 0;
  detailTabValue = "Basic Info";
  page = 0;
  constructor() {
    makeObservable(this, {
      Filters: observable,
      TabValue: observable,
      CallTabValue: observable,
      SheetFilter: observable,
      SheetData: observable,
      ContentData: observable,
      detailTabValue: observable,
      page: observable,
      removeContent: action,
      removeAllContent: action,
      addFilter: action,
      removeFilter: action,
      removeAllFilter: action,
      addSheetFilter: action,
      updateTabValue: action,
      updateCallTabValue: action,
      setPage: action,
      updateDetailTabValue: action,
    });
    makePersistable(this, {
      name: FilterStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: ["Filters", "SheetFilter", "SheetData", "ContentData"],
    });
  }
  setPage = (val) => {
    this.page = val;
  };
  updateTabValue = (val) => {
    this.TabValue = val;
  };
  updateCallTabValue = (val) => {
    this.CallTabValue = val;
  };
  updateDetailTabValue = (val) => {
    this.detailTabValue = val;
  };

  addFilter = (arr) => {
    let temp = [];
    arr.map((e) => {
      if (this.Filters.length > 0) {
        if (
          this.Filters.every((item) => item.type !== e.type) &&
          e.isChecked === true
        ) {
          temp.push(e);
        }
      } else {
        if (e.isChecked === true) {
          temp.push(e);
        }
      }
      return temp;
    });
    this.Filters.push(...temp);
    temp = [];
  };
  removeFilter = (str) => {
    this.Filters = this.Filters.filter((e) => {
      return e.type !== str;
    });
  };
  removeAllFilter = (str) => {
    this.Filters = this.Filters.filter((item) => item.name !== str);
    consoleLogger("removed successful");
  };
  addSheetFilter = (item) => {
    if (!this.SheetFilter.includes(item)) {
      this.SheetFilter.push(item);
    }
  };
  removeSheetFilter = (item) => {
    this.SheetFilter = this.SheetFilter.filter((e) => e !== item);
  };

  removeContent = (str) => {
    if (this.Filters.length === 0) {
      this.ContentData = [];
      return this.ContentData;
    }
    this.ContentData.map((e) => {
      this.Filters.map((itr) => {
        if (
          (e.category === str ||
            e.subject === str ||
            e.grade === str ||
            e.level === str) &&
          e.category !== itr.type &&
          e.subject !== itr.type &&
          e.grade !== itr.type &&
          e.level !== itr.type
        ) {
          this.ContentData = this.ContentData.filter((item) => item !== e);
        }
        return this.ContentData;
      });
      return this.ContentData;
    });
  };
  removeAllContent = (e) => {
    this.ContentData.map((e1) => {
      e.subheaders.map((e2) => {
        this.Filters.map((itr) => {
          if (
            e1.dataType.includes(e2.type) &&
            !e1.dataType.includes(itr.type)
          ) {
            this.ContentData = this.ContentData.filter((e3) => e3 !== e1);
          }
          return this.ContentData;
        });
        return this.ContentData;
      });
      return this.ContentData;
    });
  };
}

//**********************Lead Store**************** */
class LeadStore {
  tabID = "";
  tabInfo = {};
  tabList = [];
  tabColumnSortingList = {};

  //variables to manage tabs grouping
  _tabGroups = [];
  _tabsDetails = [];
  _selectedGroupName = null;
  groupsToTabs = {};
  ///

  constructor() {
    makeObservable(this, {
      tabInfo: observable,
      tabID: observable,
      tabList: observable,
      tabColumnSortingList: observable,
      _tabGroups: observable,
      _tabsDetails: observable,
      _selectedGroupName: observable,
      groupsToTabs: observable,
      updateTabID: action,
      resetTabInfo: action,
      updateCurrentTabPage: action,
      updateCurrentTabPageSize: action,
      updateCurrentTabItemCount: action,
      setupTabs: action,
      updateTabList: action,
      updateCurrentTabColumns: action,
      updateTabColumnsWithDefaultColumns: action,
      updateTabColumnSortingList: action,
      getTabsForSelectedGroup: action,
      updateSelectedGroupName: action,
    });

    makePersistable(this, {
      name: FilterStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [],
    });
  }

  //****************tab grouping related functions***************************//
  setupTabs = async () => {
    if (checkFeaturePermission("leads")) {
      await this.fetchTabGroups();
      await this.fetchTabs();
      this.setupGroupsToTabs();

      let _tempTabGroups = [];

      this._tabGroups.forEach((group) => {
        let tabsForGroup = this.groupsToTabs[group["name"]] ?? [];
        if (tabsForGroup.length > 0) {
          _tempTabGroups.push(group);
        }
      });
      this._tabGroups = _tempTabGroups;
      let index = this._tabGroups.findIndex(
        (element) => element["default_group"] === true
      );
      if (index === -1) {
        let group = this._tabGroups[0];
        this._selectedGroupName = group["name"];
      } else {
        let group = this._tabGroups[index];
        this._selectedGroupName = group["name"];
      }
      for (let i = 0; i < this._tabsDetails.length; i++) {
        this.tabInfo[this._tabsDetails[i]["id"]] = {
          page: 0,
          page_size: pageSize,
          count: null,
          columns: [],
        };
      }
      this.updateTabID(this.groupsToTabs[this._selectedGroupName][0]);
    }
  };
  fetchTabGroups = async () => {
    let response = await getViewGroupsApi({
      projectID: rootStore.authStore.projectId,
    });
    if (response.length > 0) {
      this._tabGroups = response;
      this._tabGroups.push({
        id: "others",
        default_group: false,
        name: "Others",
      });
    } else {
      this._tabGroups.push({
        id: "others",
        default_group: true,
        name: "Others",
      });
      this._selectedGroupName = "Others";
    }
  };
  fetchTabs = async () => {
    try {
      let response = await getTabsApi(rootStore.authStore.projectId);
      if (response.data.length > 0) {
        this._tabsDetails = response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };
  setupGroupsToTabs = async () => {
    let uniqueGroups = [
      ...new Set(this._tabGroups.map((item) => item["name"])),
    ];
    let result = {};
    uniqueGroups.forEach((group) => {
      if (!result.hasOwnProperty(group)) {
        result[group] = [];
      }
    });
    this._tabsDetails.forEach((tab) => {
      let groups = tab["viewgroups"] ?? [];
      if (groups.length === 0) {
        result["Others"].push(tab["id"]);
      } else {
        groups.forEach((group) => {
          if (!uniqueGroups.includes(group)) {
            result["Others"].push(tab["id"]);
          } else {
            result[group].push(tab["id"]);
          }
        });
      }
    });
    this.groupsToTabs = result;
  };
  getTabsForSelectedGroup = () => {
    let tabIDs = this.groupsToTabs[this._selectedGroupName] ?? [];
    let tabs = [];
    tabIDs.forEach((id) => {
      tabs.push(this._tabsDetails.find((element) => element["id"] === id));
    });
    return tabs;
  };

  updateSelectedGroupName = (groupName) => {
    this._selectedGroupName = groupName;
  };

  //**************************END********************* */

  updateTabColumnSortingList = ({ tabID, field, direction, flag }) => {
    this.tabColumnSortingList[tabID] = {
      field_name: field,
      direction: direction,
      flag: flag,
    };
  };

  updateTabID = (id) => {
    this.tabID = id;
  };
  updateTabList = (list) => {
    this.tabList = list;
  };
  resetTabInfo = () => {
    this.tabID = "";
    this.tabList = [];
    this.tabColumnSortingList = {};
    this._tabGroups = [];
    this._tabsDetails = [];
    this._selectedGroupName = null;
    this.tabInfo = {};
  };

  updateCurrentTabPage = ({ tabID, page }) => {
    this.tabInfo[tabID]["page"] = page;
  };

  updateCurrentTabPageSize = ({ tabID, size }) => {
    this.tabInfo[tabID]["page_size"] = size;
  };

  updateCurrentTabItemCount = ({ tabID, count }) => {
    this.tabInfo[tabID]["count"] = count;
  };
  updateCurrentTabColumns = ({ tabID, columnsList }) => {
    this.tabInfo[tabID]["columns"] = columnsList;
  };

  updateTabColumnsWithDefaultColumns = () => {
    let index = this._tabsDetails.findIndex((tab) => tab["id"] === this.tabID);
    if (index !== -1) {
      let fieldList = [];
      this._tabsDetails[index]["selected_columns"].forEach((column) => {
        let index = rootStore.userStore.AllColumnsList.findIndex(
          (columnData) => columnData["field"] === column["field"]
        );
        if (index !== -1) {
          fieldList.push(rootStore.userStore.AllColumnsList[index]);
        }
      });
      this.updateCurrentTabColumns({
        tabID: this.tabID,
        columnsList: fieldList,
      });
    }
  };
}
//***************************END****************** */

//**********************Product Store**************** */
class ProductStore {
  columnsList = [];
  listingInfo = {
    page_number: 0,
    page_size: pageSize,
    count: 0,
  };

  constructor() {
    makeObservable(this, {
      columnsList: observable,
      listingInfo: observable,
      fetchColumns: action,
      udpatePageNumber: action,
      updatePageSize: action,
      updateCount: action,
    });

    makePersistable(this, {
      name: ProductStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [],
    });
  }

  resetStore = () => {
    this.columnsList = [];
    this.listingInfo = {
      page_number: 0,
      page_size: pageSize,
      count: 0,
    };
  };

  fetchColumns = async () => {
    if (this.columnsList.length === 0) {
      let response = await getProductColumnsListApi();
      this.columnsList = response;
    }
  };
  udpatePageNumber = (pageNumber) => {
    this.listingInfo["page_number"] = pageNumber;
  };
  updatePageSize = (pageSize) => {
    this.listingInfo["page_size"] = pageSize;
  };
  updateCount = (count) => {
    this.listingInfo["count"] = count;
  };
}
//***************************END****************** */

//**********************Contact Store**************** */
class ContactStore {
  columnsList = [];
  listingInfo = {
    page_number: 0,
    page_size: pageSize,
    count: 0,
  };
  columnGroups = [];
  detailTabs = [];
  detailsCurrentTabID = null;
  constructor() {
    makeObservable(this, {
      columnsList: observable,
      listingInfo: observable,
      columnGroups: observable,
      detailsCurrentTabID: observable,
      detailTabs: observable,
      fetchColumns: action,
      udpatePageNumber: action,
      updatePageSize: action,
      updateCount: action,
      fetchColumnGroups: action,
      updateDetailsCurrentTabID: action,
      fetchDetailTabs: action,
      init: action,
    });

    makePersistable(this, {
      name: ContactStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [],
    });
  }

  init = async () => {
    await this.fetchColumns();
    await this.fetchColumnGroups();
    await this.fetchDetailTabs();
  };

  updateDetailsCurrentTabID = (tabID) => {
    this.detailsCurrentTabID = tabID;
  };

  fetchColumnGroups = async () => {
    if (this.columnGroups.length === 0) {
      let response = await getContactColumnGroupsApi();
      this.columnGroups = response;
    }
  };

  fetchDetailTabs = async () => {
    let response = await getContactDetailsTabsApi();
    this.detailTabs = response;
  };

  resetStore = () => {
    this.columnsList = [];
    this.listingInfo = {
      page_number: 0,
      page_size: pageSize,
      count: 0,
    };
    this.columnGroups = [];
    this.detailTabs = [];
    this.detailsCurrentTabID = null;
  };

  fetchColumns = async () => {
    let response = await getContactColumnsListApi();
    this.columnsList = response;
  };
  udpatePageNumber = (pageNumber) => {
    this.listingInfo["page_number"] = pageNumber;
  };
  updatePageSize = (pageSize) => {
    this.listingInfo["page_size"] = pageSize;
  };
  updateCount = (count) => {
    this.listingInfo["count"] = count;
  };
}
//***************************END****************** */
//**********************Customer Store**************** */
class CustomerStore {
  columnsList = [];
  listingInfo = {
    page_number: 0,
    page_size: pageSize,
    count: 0,
  };
  columnGroups = [];
  detailTabs = [];
  detailsCurrentTabID = null;
  constructor() {
    makeObservable(this, {
      columnsList: observable,
      listingInfo: observable,
      columnGroups: observable,
      detailsCurrentTabID: observable,
      detailTabs: observable,
      fetchColumns: action,
      udpatePageNumber: action,
      updatePageSize: action,
      updateCount: action,
      fetchColumnGroups: action,
      updateDetailsCurrentTabID: action,
      fetchDetailTabs: action,
      init: action,
    });

    makePersistable(this, {
      name: CustomerStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [],
    });
  }

  init = async () => {
    await this.fetchColumns();
    await this.fetchColumnGroups();
    await this.fetchDetailTabs();
  };

  updateDetailsCurrentTabID = (tabID) => {
    this.detailsCurrentTabID = tabID;
  };

  fetchColumnGroups = async () => {
    if (this.columnGroups.length === 0) {
      let response = await getCustomerColumnGroupsApi();
      this.columnGroups = response;
    }
  };

  fetchDetailTabs = async () => {
    let response = await getCustomerDetailsTabsApi();
    this.detailTabs = response;
  };

  resetStore = () => {
    this.columnsList = [];
    this.listingInfo = {
      page_number: 0,
      page_size: pageSize,
      count: 0,
    };
    this.columnGroups = [];
    this.detailTabs = [];
    this.detailsCurrentTabID = null;
  };

  fetchColumns = async () => {
    let response = await getCustomerColumnsListApi();
    this.columnsList = response;
  };
  udpatePageNumber = (pageNumber) => {
    this.listingInfo["page_number"] = pageNumber;
  };
  updatePageSize = (pageSize) => {
    this.listingInfo["page_size"] = pageSize;
  };
  updateCount = (count) => {
    this.listingInfo["count"] = count;
  };
}
//***************************END****************** */

//**********************Organization Store**************** */
class OrganizationStore {
  columnsList = [];
  listingInfo = {
    page_number: 0,
    page_size: pageSize,
    count: 0,
  };

  constructor() {
    makeObservable(this, {
      columnsList: observable,
      listingInfo: observable,
      fetchColumns: action,
      udpatePageNumber: action,
      updatePageSize: action,
      updateCount: action,
    });

    makePersistable(this, {
      name: OrganizationStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [],
    });
  }

  resetStore = () => {
    this.columnsList = [];
    this.listingInfo = {
      page_number: 0,
      page_size: pageSize,
      count: 0,
    };
  };

  fetchColumns = async () => {
    if (this.columnsList.length === 0) {
      let response = await getOrganizationColumnsListApi();
      this.columnsList = response;
    }
  };
  udpatePageNumber = (pageNumber) => {
    this.listingInfo["page_number"] = pageNumber;
  };
  updatePageSize = (pageSize) => {
    this.listingInfo["page_size"] = pageSize;
  };
  updateCount = (count) => {
    this.listingInfo["count"] = count;
  };
}
//***************************END****************** */

const rootStore = new RootStore();
export default rootStore;
