import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Button, CircularProgress, Drawer } from "@material-ui/core";
import { ChevronRight, Share } from "@material-ui/icons";
import { filesPageColumnHeaders } from "../../Db";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import AddFolderModal from "../../components/files_page_components/AddFolderModal";
import { useNavigate } from "react-router-dom";
import { getFoldersListApi } from "../../Api";
import ManageFolderSidepanel from "../../components/manage_folder_sidepanel/ManageFolderSidepanel";
import { checkFeaturePermission } from "../../Functions";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const ProgressWrapper = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const SpinnerWrapper = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: "auto",
  alignItems: "center",
}));
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 236px);`};
  background-color: white;
  padding: 20px;
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;

const TopSectionWrapper = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 12px 0px;
`;

const FilesPage = () => {
  const navigate = useNavigate();
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [openAddFolderModal, setOpenAddFolderModal] = useState(false);
  const [openFolderPermissionPanel, setOpenFolderPermissionPanel] =
    useState(false);
  const [selectedFolderID, setSelectedFolderID] = useState("");
  const getFilesList = async ({ page, page_size }) => {
    setRow([]);
    let response = await getFoldersListApi({
      page: page,
      page_size: page_size,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
  };

  const init = async () => {
    setLoading(true);
    await getFilesList({ page: 0, page_size: pageSize });
    setLoading(false);
  };

  const handlePageSize = async (e) => {
    setLoading(true);
    setPage(0);
    setSize(e.target.value);
    await getFilesList({ page: 0, page_size: e.target.value });
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setLoading(true);
    setPage(page - 1);
    setLoading(true);
    await getFilesList({ page: page - 1, page_size: size });
    setLoading(false);
  };

  useEffect(() => {
    if (refresh === true) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "ACTIONS",
      flex: 1,
      width: 100,
      cellRenderer: (params) => {
        return (
          <Box>
            <Share
              style={{
                cursor: "pointer",
                marginRight: "10px",
                width: "20px",
                height: "20px",
              }}
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedFolderID(params.data.id);
                setOpenFolderPermissionPanel(true);
              }}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <ParentContainer>
        <Container>
          <TopSectionWrapper>
            <TopWrapper>
              <Header>Folders</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
            </TopWrapper>
            {checkFeaturePermission("files") && (
              <Button
                onClick={() => {
                  setOpenAddFolderModal(true);
                }}
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "160px",
                  height: "40px",
                }}
              >
                Add a Folder
              </Button>
            )}
          </TopSectionWrapper>
          <TableWrapper>
            {!loading ? (
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={
                    checkFeaturePermission("files")
                      ? filesPageColumnHeaders.concat(actionColumn)
                      : filesPageColumnHeaders
                  }
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  paginationPageSize={pageSize}
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    navigate(`/files/${row.data.id}`);
                  }}
                />
              </div>
            ) : (
              <ProgressWrapper>
                <CircularProgress />
              </ProgressWrapper>
            )}
          </TableWrapper>
          <PaginationWrapper>
            <PaginationComponent
              page_no={page}
              row={row}
              page_size={size}
              size={size}
              count={count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </PaginationWrapper>
        </Container>
      </ParentContainer>
      <Footer />
      {openAddFolderModal && (
        <AddFolderModal
          setOpen={setOpenAddFolderModal}
          open={openAddFolderModal}
          setRefresh={setRefresh}
        />
      )}
      {openFolderPermissionPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openFolderPermissionPanel}
          onClose={() => {
            setOpenFolderPermissionPanel(false);
            setSelectedFolderID("");
          }}
        >
          <ManageFolderSidepanel
            setOpen={setOpenFolderPermissionPanel}
            open={openFolderPermissionPanel}
            folderID={selectedFolderID}
          />
        </Drawer>
      )}
    </>
  );
};

export default observer(FilesPage);
