import React, { useEffect, useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  Modal,
  Select,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { editContactApi, submitCallApi } from "../../Api";
import { myTheme } from "../../themeUtils";
import {
  consoleLogger,
  getIsoString,
  getLocalizedText,
  validateDisabledSubFeature,
} from "../../Functions";
import analytics from "../../Analytics";
import UpdateFieldsWidget from "../status_param_column_rendere/UpdateFieldsWidget";
import MuiAlert from "@mui/material/Alert";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  justify-content: space-between;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Input = styled.input`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 600 16px Open Sans;
  :disabled {
    color: #d3d3d3;
    font-weight: 300;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Message = styled.p`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const SubmitMessage = styled.p`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const MessageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const TextArea = styled.textarea`
  width: 100%;
  height: 60px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;

  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 600 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: relative;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const CallLogModal = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({
    notes: "",
    status: "",
    sub_status: "",
  });
  const [subStatusList, setSubStatusList] = useState([]);
  const [audioFile, setAudioFile] = useState();
  const columns = [...rootStore.userStore.AllColumnsList];
  const [errorMessage, setErrorMessage] = useState("");
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const [updateFieldList, setUpdateFieldList] = useState([]);
  const [updateFieldInputData, setUpdateFieldInputData] = useState({});
  const stageStatusList = [...rootStore.authStore.stageStatusList];
  const statusList = [...rootStore.authStore.statusList];
  const [notesRequired, setNotesRequired] = useState(false);
  const [submitLogErrorMessage, setSubmitLogErrorMessage] = useState("");

  const handleClick = () => {
    props.setOpen(false);
  };
  const handleChange = (e) => {
    setErrorMessage("");
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const generateContactPayload = (data) => {
    let contactData = {};

    for (const key in data) {
      let index = columns.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let columnData = columns[index];
        if (columnData["contact_column"] !== null) {
          let fieldData = data[columnData["field"]];
          let value = fieldData["value"];
          if (value !== "") {
            if (fieldData["type"] === "date") {
              value = getIsoString(value);
            } else if (fieldData["type"] === "datetime") {
              value = getDateTimeString({
                dateString: value["date"],
                timeString: value["time"],
              });
            } else if (fieldData["type"] === "integer") {
              value = parseInt(value);
            }
            contactData[columnData["contact_column"]] = value;
          }
        }
      }
    }
    return contactData;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    let formData = new FormData();

    /****************************Update Fields************************* */
    let updateFieldsPayload =
      generateUpdateFieldsPayloadData(updateFieldInputData);
    formData.append("update_fields", JSON.stringify(updateFieldsPayload));
    if (updateFieldsPayload.hasOwnProperty("next_follow_up_on")) {
      formData.append(
        "next_followup_on",
        updateFieldsPayload["next_follow_up_on"]
      );
    }
    //***************************END*********************** */
    formData.append("notes", user.notes);
    user.hasOwnProperty("customer_notes") &&
      formData.append("customer_notes", user["customer_notes"]);

    formData.append("status", user.status);
    formData.append("sub_status", user.sub_status);

    let dialerLogID = rootStore.phoneCallState.getDialerLogIdForLead(props.lid);
    if (dialerLogID !== "") {
      formData.append("dialer_log_id", dialerLogID);
    }

    audioFile !== undefined && formData.append("audio", audioFile);

    const sendCallLog = async () => {
      try {
        let response = await submitCallApi({ id: props.lid, data: formData });
        setIsError(false);
        setIsFormSubmit(true);
        if (props.scope === "call_log_tab") {
          rootStore.authStore.addCallLog(response.data);
          props.setRefreshCallLogAfterAdd(true);
        }
        if (props.scope !== "app_bar") {
          props.setRefreshLeadDetails(true);
        }
      } catch (error) {
        setSubmitLogErrorMessage(getLocalizedText("some_error_occured"));
        setIsError(true);
        console.log(error);
      }
    };
    const setDisposition = async () => {
      if (dialerLogID !== "") {
        let dispositionData = {
          dialer_log_id: dialerLogID,
          lead_status: user.status,
        };
        let response = await rootStore.phoneCallState.setCallDisposition(
          props.lid,
          dispositionData
        );
        console.log(
          `call log disposition ::: ${response.status} ::: ${JSON.stringify(
            response.data
          )}`
        );
        if (response.hasError()) {
          document.getElementById("call-log-btn").innerText = "Submit Details";
          if (
            response.data !== null &&
            response.data.hasOwnProperty("details")
          ) {
            let message =
              "Error submitting call log; " + response.data["details"];
            setErrorMessage(message);
          } else {
            let message =
              "Error submitting call log; Please contact the administrator.";
            setErrorMessage(message);
          }
        } else {
          console.log(
            "Phone state ::: post submit calllog ::: submit call log dialog"
          );
          rootStore.phoneCallState.postSubmitCallLog();
          await sendCallLog();
        }
      } else {
        await sendCallLog();
      }
    };
    let contactPayload = generateContactPayload(updateFieldInputData);
    if (Object.keys(contactPayload).length > 0) {
      if (
        props.leadData["contact_id"] !== null &&
        props.leadData["contact_id"] !== ""
      ) {
        let response = await editContactApi({
          contactID: props.leadData["contact_id"],
          payload: contactPayload,
        });
        if (!response.hasError()) {
          await setDisposition();
        } else {
          setSubmitLogErrorMessage(response.errorMessage);
          setIsError(true);
        }
      }
    } else {
      await setDisposition();
    }
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleAudio = (e) => {
    setAudioFile(e.target.files[0]);
    consoleLogger(e.target.files[0]);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000064,
      "submit_call_log_form_load",
      "call_logs_tab",
      "",
      {}
    );
    if (
      props.leadData.hasOwnProperty("customer_notes") &&
      props.leadData["customer_notes"] !== null
    ) {
      setUser({ ...user, customer_notes: props.leadData["customer_notes"] });
    }
  }, []);

  const getUpdateFieldsInStageStatus = (statusName) => {
    let tempList = [];
    let currentLeadStage =
      props.leadData.hasOwnProperty("lead_stage") &&
      props.leadData["lead_stage"] !== null
        ? props.leadData["lead_stage"]
        : "";
    let index = stageStatusList.findIndex(
      (item) =>
        item["lead_status"]["status"] === statusName &&
        item["lead_stage"]["stage"] === currentLeadStage
    );
    if (index !== -1) {
      if (
        stageStatusList[index].hasOwnProperty("update_fields") &&
        stageStatusList[index]["update_fields"] !== null
      ) {
        let updateFieldsData = stageStatusList[index]["update_fields"];
        updateFieldsData.forEach((data) => {
          let index = columns.findIndex(
            (column) => column["id"] === data["column_meta"]["id"]
          );
          if (index !== -1) {
            let columnData = { ...columns[index] };
            columnData["required"] = data["required"];
            tempList.push(columnData);
          }
        });
      }
    }
    return tempList;
  };

  const getUpdateFieldsInStatus = (statusName) => {
    let tempList = [];
    let index = statusList.findIndex(
      (status) => status["status"] === statusName
    );
    if (index !== -1) {
      if (
        statusList[index].hasOwnProperty("update_fields") &&
        statusList[index]["update_fields"] !== null
      ) {
        let updateFieldsData = statusList[index]["update_fields"];
        updateFieldsData.forEach((data) => {
          let index = columns.findIndex(
            (column) => column["id"] === data["column_meta"]["id"]
          );
          if (index !== -1) {
            let columnData = { ...columns[index] };
            columnData["required"] = data["required"];
            tempList.push(columnData);
          }
        });
      }
    }
    return tempList;
  };

  //**********************functions related to update_fields changes*************************** */
  const initUpdateFieldList = (statusName) => {
    let updateFieldsInStageSTatus = getUpdateFieldsInStageStatus(statusName);
    if (updateFieldsInStageSTatus.length > 0) {
      setUpdateFieldList(updateFieldsInStageSTatus);
    } else {
      let updateFieldsInStatus = getUpdateFieldsInStatus(statusName);
      setUpdateFieldList(updateFieldsInStatus);
    }
  };

  const getDateTimeString = ({ dateString, timeString }) => {
    let newDateTime = null;

    if (dateString !== "") {
      if (timeString !== null) {
        const newTime = timeString.format("HH:mm:ss");
        newDateTime = moment(
          `${dateString} ${newTime}`,
          "YYYY-MM-DD HH:mm:ss"
        ).toISOString();
      } else {
        newDateTime = moment(dateString, "YYYY-MM-DD").toISOString();
      }
    }

    return newDateTime;
  };

  const generateUpdateFieldsPayloadData = (data) => {
    let normalList = {};
    let customList = {};
    for (const key in data) {
      let value = data[key]["value"];
      let index = columns.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        if (value !== "") {
          if (data[key]["type"] === "date") {
            value = getIsoString(value);
          } else if (data[key]["type"] === "datetime") {
            value = getDateTimeString({
              dateString: value["date"],
              timeString: value["time"],
            });
          } else if (data[key]["type"] === "integer") {
            value = parseInt(value);
          }
          if (columns[index]["is_custom_param"]) {
            let newKey = key.replace("custom_params_", "");
            customList[newKey] = value;
          } else {
            normalList[key] = value;
          }
        }
      }
    }

    return { ...normalList, custom_params: customList };
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isFormSubmit ? (
          <ModelContainer>
            <Box>
              <Container>
                <Header>{getLocalizedText("submit_call_log")}</Header>
                <SubHeader>
                  {getLocalizedText("fill_in_the_below_details")}
                </SubHeader>
                <Divider style={{ margin: "16px 0px" }} />
                <Form onSubmit={handleSubmit}>
                  <Box
                    style={{
                      height: "100%",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item container spacing={2}>
                        {statusList && (
                          <Grid item xs={6}>
                            <InputWrapper>
                              <Label>{getLocalizedText("status")}*</Label>
                              <FormControl
                                sx={{ width: "100%" }}
                                classes={{
                                  root: classes.quantityRoot,
                                }}
                              >
                                <Select
                                  classes={{
                                    icon: classes.icon,
                                  }}
                                  name="status"
                                  displayEmpty
                                  disableUnderline
                                  variant="outlined"
                                  required
                                  onChange={(e) => {
                                    setUpdateFieldList([]);
                                    statusList.map((item) => {
                                      if (e.target.value === item.status) {
                                        let newList = item.sub_status;
                                        setSubStatusList(newList);
                                        setNotesRequired(item.notes_mandatory);
                                        initUpdateFieldList(e.target.value);

                                        setErrorMessage("");
                                        if (newList.length > 0) {
                                          if (newList.includes("None")) {
                                            setUser({
                                              ...user,
                                              status: e.target.value,
                                              sub_status: "None",
                                            });
                                          } else {
                                            setUser({
                                              ...user,
                                              status: e.target.value,
                                              sub_status: newList[0],
                                            });
                                          }
                                        }
                                      }
                                    });
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "30px",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    borderRadius: "0px",
                                  }}
                                >
                                  {statusList.map((e) => {
                                    if (e["is_not_set"] === false) {
                                      return (
                                        <MenuItem id={e.id} value={e.status}>
                                          {e.status}
                                        </MenuItem>
                                      );
                                    }
                                  })}
                                </Select>
                              </FormControl>
                            </InputWrapper>
                          </Grid>
                        )}
                        {statusList && (
                          <Grid item xs={6}>
                            <InputWrapper>
                              <Label>{getLocalizedText("sub_status")}*</Label>
                              <FormControl
                                sx={{ width: "100%" }}
                                classes={{
                                  root: classes.quantityRoot,
                                }}
                              >
                                <Select
                                  classes={{
                                    icon: classes.icon,
                                  }}
                                  name="sub_status"
                                  displayEmpty
                                  variant="outlined"
                                  required
                                  value={user.sub_status}
                                  onChange={handleChange}
                                  style={{
                                    width: "100%",
                                    height: "30px",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    borderRadius: "0px",
                                  }}
                                >
                                  {subStatusList.length > 0 &&
                                    subStatusList.map((e) => {
                                      return (
                                        <MenuItem id={e} value={e}>
                                          {e}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </InputWrapper>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <InputWrapper>
                            <Label>
                              {notesRequired
                                ? `${getLocalizedText("call_notes")}*`
                                : getLocalizedText("call_notes")}
                            </Label>
                            <TextArea
                              required={notesRequired}
                              type="text"
                              placeholder="Enter note here..."
                              name="notes"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>

                        {validateDisabledSubFeature("customer_notes") && (
                          <Grid item xs={12}>
                            <InputWrapper>
                              <Label>
                                {`${getLocalizedText("customer_notes")}*`}
                              </Label>
                              <TextArea
                                required
                                value={
                                  user.hasOwnProperty("customer_notes")
                                    ? user["customer_notes"]
                                    : ""
                                }
                                type="text"
                                placeholder="Enter note here..."
                                name="customer_notes"
                                onChange={handleChange}
                              />
                            </InputWrapper>
                          </Grid>
                        )}

                        {validateDisabledSubFeature("call_audio_upload") && (
                          <Grid item xs={6}>
                            <InputWrapper>
                              <Label>
                                {getLocalizedText("add_audio_file")}
                              </Label>
                              <Input
                                accept=".mp3,audio/*"
                                type="file"
                                onChange={handleAudio}
                                style={{
                                  width: "428px",
                                  fontSize: "12px",
                                  padding: "2px",
                                  backgroundColor: "#EFEFF4",
                                  border: "none",
                                  borderRadius: "6px",
                                }}
                              />
                            </InputWrapper>
                          </Grid>
                        )}
                      </Grid>

                      {updateFieldList.length > 0 && (
                        <UpdateFieldsWidget
                          value={updateFieldInputData}
                          setValue={setUpdateFieldInputData}
                          fieldList={updateFieldList}
                        />
                      )}
                    </Grid>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <MessageWrapper>
                        <Message style={{ color: "red" }}>
                          {errorMessage}
                        </Message>
                      </MessageWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonWrapper>
                        {isError && (
                          <ErrorAlert
                            onClose={(e) => {
                              e.stopPropagation();
                              setIsError(false);
                            }}
                            severity="error"
                            sx={{ width: "100%" }}
                          >
                            {submitLogErrorMessage}
                          </ErrorAlert>
                        )}
                        <Button
                          onClick={() => {
                            analytics.triggerEvent(
                              4625000065,
                              "cancel_button_tap",
                              "submit_call_log_form",
                              "cancel_button",
                              {}
                            );
                            handleClick();
                          }}
                          type="button"
                          variant="contained"
                          color="default"
                          style={{
                            backgroundColor: "#EFEFF4",
                            textTransform: "none",
                            marginRight: "20px",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          {getLocalizedText("cancel")}
                        </Button>
                        <Button
                          onClick={() => {
                            analytics.triggerEvent(
                              4625000066,
                              "submit_button_tap",
                              "submit_call_log_form",
                              "submit_button",
                              {}
                            );
                          }}
                          type="submit"
                          variant="contained"
                          id="call-log-btn"
                          style={{
                            textTransform: "none",
                            fontSize: "12px",
                            fontWeight: "bold",
                            ...myTheme.Button.btnBlue,
                          }}
                        >
                          {getLocalizedText("submit_details")}
                        </Button>
                      </ButtonWrapper>
                    </Grid>
                  </Grid>
                </Form>
              </Container>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px" }}>
              <SubmitMessage>Call log added successfully!</SubmitMessage>
              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default observer(CallLogModal);
