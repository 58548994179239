import { Drawer, Tooltip } from "@material-ui/core";
import { CircularProgress, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { refreshImportRowApi } from "../../Api";
import { ImportHistoryListingColumns } from "../../Db";
import { dateTimeHandler } from "../../Functions";
import "../../pages/import_history/import.css";
import ImportDetailsSidePanel from "../import_details_sidepanel/ImportDetailsSidePanel";
import analytics from "../../Analytics";
const ImportRowComponent = ({ item, idx }) => {
  const [data, setData] = useState();
  const [status, setStatus] = useState("");
  const [openImportDetailPanel, setOpenImportDetailPanel] = useState(false);
  const { id } = item;
  const Thirty_MS = 30000;
  const Ten_MS = 10000;

  const getUpdatedData = async () => {
    try {
      let response = await refreshImportRowApi({ id: id });
      setData(response.data);
      setStatus(response.data["status"]);
    } catch (error) {
      console.log(error);
    }
  };

  const getFetchTimeByStatus = () => {
    if (status.toLowerCase() === "processing") {
      return Ten_MS;
    }
    return Thirty_MS;
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        status.toLowerCase() === "queued" ||
        status.toLowerCase() === "processing"
      ) {
        getUpdatedData();
      }
    }, getFetchTimeByStatus());

    return () => clearInterval(interval);
  }, [status]);

  useEffect(() => {
    setData(item);
    setStatus(item["status"]);
  }, []);
  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }

  const getImportTypeNameString = (importType) => {
    if (
      importType.toLowerCase() === "create" ||
      importType.toLowerCase() === "create_leads_v1"
    ) {
      return "Create";
    } else if (importType.toLowerCase() === "update" || "update_leads_v1") {
      return "Update";
    }
    return "-";
  };

  return (
    <>
      <TableRow
        key={idx}
        style={{
          width: "100%",
          cursor: "pointer",
          backgroundColor: idx % 2 === 0 ? "white" : "#F9F9FC",
        }}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
        onClick={() => {
          analytics.triggerEvent(
            4625000113,
            "import_row_tap",
            "imports_page",
            "",
            {}
          );
          setOpenImportDetailPanel(true);
        }}
      >
        {data &&
          ImportHistoryListingColumns.map((key) => {
            if (key["field"] === "start_time") {
              let new_start_time;
              if (data[key["field"]] !== null) {
                new_start_time = dateTimeHandler(data[key["field"]]);
              }
              return (
                <Tooltip title={new_start_time}>
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {data[key["field"]] !== null ? new_start_time : "-"}
                  </TableCell>
                </Tooltip>
              );
            } else if (key["field"] === "end_time") {
              let new_end_time;
              if (data[key["field"]] !== null) {
                new_end_time = dateTimeHandler(data[key["field"]]);
              }
              return (
                <Tooltip title={new_end_time}>
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {data[key["field"]] !== null ? new_end_time : "-"}
                  </TableCell>
                </Tooltip>
              );
            } else if (key["field"] === "status") {
              if (data[key["field"]] === "processing") {
                return (
                  <TableCell
                    className="textContainer"
                    style={{
                      display: "flex",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                  >
                    <span>
                      {data[key["field"]]} ({data["rows_processed"]})
                    </span>
                    <CircularProgress
                      style={{ width: "18px", height: "18px" }}
                    />
                  </TableCell>
                );
              } else {
                return (
                  <Tooltip title={data[key["field"]]}>
                    <TableCell
                      className="textContainer"
                      style={{
                        fontSize: "14px",
                        fontFamily: "Open Sans",
                        color: "#414141",
                      }}
                    >
                      {truncate(data[key["field"]], 10)}
                    </TableCell>
                  </Tooltip>
                );
              }
            } else if (key["field"] === "id") {
              return (
                <Tooltip title={data[key["field"]]}>
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {truncate(data[key["field"]], 10)}
                  </TableCell>
                </Tooltip>
              );
            } else if (key["field"] === "import_type") {
              let value =
                data[key["field"]] !== null
                  ? getImportTypeNameString(data[key["field"]])
                  : "-";
              return (
                <Tooltip title={value}>
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {value}
                  </TableCell>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title={data[key["field"]]}>
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {data[key["field"]]}
                  </TableCell>
                </Tooltip>
              );
            }
          })}
      </TableRow>
      {openImportDetailPanel && (
        <Drawer
          anchor={"right"}
          open={openImportDetailPanel}
          onClose={() => {
            setOpenImportDetailPanel(false);
          }}
        >
          {<ImportDetailsSidePanel id={id} />}
        </Drawer>
      )}
    </>
  );
};

export default ImportRowComponent;
