import { Typography, DialogContent, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import {
  IsoToLocalTime,
  dataRenderer,
  getLocalizedText,
} from "../../../Functions";
import { styled } from "@mui/material/styles";

const Title = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 10px Open Sans",
  color: "#4d4e4f",
  marginBottom: "6px",
}));
const Desc = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 14px Open Sans",
  color: "#4d4e4f",
  wordWrap: "break-word",
}));
const Item = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: "16px",
}));
const CallInfoBody = (props) => {
  return (
    <DialogContent
      id="alert-dialog-title"
      style={{
        fontWeight: "bold",
        padding: "0px",
        marginLeft: "16px",
        marginRight: "16px",
      }}
    >
      <Box>
        <Item>
          <Title>{getLocalizedText("customer_name")}</Title>
          <Desc>{dataRenderer(props.callStatus["lead"]["name"])}</Desc>
        </Item>
        <Item>
          <Title>{getLocalizedText("customer_number")}</Title>
          <Desc>{dataRenderer(props.callStatus["customer_number"])}</Desc>
        </Item>
        <Item>
          <Title>{getLocalizedText("dialed_on")}</Title>
          <Desc>{IsoToLocalTime(props.callStatus["dialed_on"])}</Desc>
        </Item>
      </Box>
    </DialogContent>
  );
};

export default observer(CallInfoBody);
