import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { RawLeadsImportHistoryTableColumns } from "../../../Db";
import { getRawLeadsImportsApi } from "../../../Api";
import PaginationComponent from "../../../components/pagination/PaginationComponent";
import { pageSize } from "../../../Config";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import RawLeadImportRowComponent from "../../import_row_component/RawLeadImportRowComponent";

const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
  background-color: white;
  overflow-x: auto;
`;
const PaginationWrapper = styled.div`
  padding: 0px 20px;
  background-color: white;
`;
const EmptyTableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const EmptyText = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
  margin-top: 50px;
`;

const RawLeadsImportsTab = (props) => {
  const [row, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState();
  const [size, setSize] = useState(pageSize);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getSummary = async () => {
      setRow([]);
      setLoading(true);
      let response = await getRawLeadsImportsApi({
        page: page,
        page_size: size,
      });
      setRow(response.items);
      setCount(response.item_count);
      setLoading(false);
    };
    getSummary();
  }, [props.refresh]);

  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setRow([]);
    setPage(0);
    setLoading(true);
    let response = await getRawLeadsImportsApi({
      page: 0,
      page_size: e.target.value,
    });
    setRow(response.items);
    setCount(response.item_count);
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setRow([]);
    setPage(page - 1);
    setLoading(true);
    let response = await getRawLeadsImportsApi({
      page: page - 1,
      page_size: size,
    });
    setRow(response.items);
    setCount(response.item_count);
    setLoading(false);
  };
  return (
    <>
      <TableWrapper>
        {!loading ? (
          row.length > 0 ? (
            <TableContainer
              component={Paper}
              sx={{
                width: "100%",
                display: "table",
                tableLayout: "fixed",
              }}
            >
              <Table size="small" aria-label="a dense table">
                <TableHead
                  style={{ backgroundColor: "#EFEFF4", height: "60px" }}
                >
                  <TableRow>
                    {RawLeadsImportHistoryTableColumns.map((e) => {
                      return (
                        <TableCell className="textContainer">
                          {e.headerName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.map((e, i) => {
                    return (
                      <RawLeadImportRowComponent
                        item={e}
                        columns={RawLeadsImportHistoryTableColumns}
                        idx={i}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <TableContainer
                component={Paper}
                style={{ width: "100%", overflowX: "auto" }}
              >
                <Table size="small" aria-label="a dense table">
                  <TableHead
                    style={{ backgroundColor: "#EFEFF4", height: "60px" }}
                  >
                    <TableRow>
                      {RawLeadsImportHistoryTableColumns.map((e) => {
                        return (
                          <TableCell className="textContainer">
                            {e.headerName}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody></TableBody>
                </Table>
              </TableContainer>
              <EmptyTableWrapper>
                <EmptyText>No rows</EmptyText>
              </EmptyTableWrapper>
            </>
          )
        ) : (
          <EmptyTableWrapper style={{ paddingTop: "50px" }}>
            <CircularProgress />
          </EmptyTableWrapper>
        )}
      </TableWrapper>
      <PaginationWrapper>
        <PaginationComponent
          page_no={page}
          row={row}
          page_size={size}
          size={size}
          count={count}
          handlePageSize={handlePageSize}
          handlePage={handlePagination}
        />
      </PaginationWrapper>
    </>
  );
};

export default observer(RawLeadsImportsTab);
