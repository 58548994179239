import React, { useEffect } from "react";
import { useState } from "react";
import spinner from "../../assets/images/gifs/spinner.gif";
import { v4 as uuid } from "uuid";

import styled from "styled-components";
import Footer from "../landing_page_components/footer/Footer";
import "./tabs.css";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { ImportHistoryColumns, JobListColumns } from "../../Db";
import {
  getImportHistoryApi,
  getJobsApi,
  refreshImportRowApi,
} from "../../Api";
import { Button, Tooltip } from "@material-ui/core";
import PaginationComponent from "../pagination/PaginationComponent";
import { pageSize } from "../../Config";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import JobRowComponent from "../import_row_component/JobRowComponent";
import CreateJobModal from "../create_job_modal/CreateJobModal";
import { AddBoxOutlined } from "@material-ui/icons";
import CustomMessageDialog from "../alert_dialogue/CustomMessageDialog";
const Container = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 234px);`};

  position: relative;
  /* margin-top: 70px; */
`;
const Wrapper = styled.div`
  display: flex;
`;

const DetailsWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
  overflow-x: auto;
  border: 2px solid #e0e0e0;
`;
const AddBtnWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 40px;
  padding: 20px 0px;
`;

const get_leads_url = process.env.REACT_APP_API_BASE_URL;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const TopWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
`;

const PaginationWrapper = styled.div`
  padding: 0px 20px;
`;
const EmptyTableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const EmptyText = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
  margin-top: 50px;
`;
const SerialRow = [
  {
    field: "id",
    headerName: <Text>ID</Text>,
    sortable: false,

    width: 70,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.id}>
            <span className="csutable-cell-trucate">{params.row.id}</span>
          </Tooltip>
        </>
      );
    },
  },
];
const JobsTab = (props) => {
  const { authStore, userStore } = rootStore;
  const [row, setRow] = useState();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState();
  const [size, setSize] = useState(pageSize);
  const [openImport, setOpenImport] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDeleteProtectedAlert, setShowDeleteProtectedAlert] =
    useState(false);
  useEffect(() => {
    setRow();
    setLoading(true);
    const getSummary = async () => {
      let response = await getJobsApi({
        id: props.id,
        page: page,
        page_size: size,
      });
      setRow(response.data.items);
      setCount(response.data.item_count);
      setLoading(false);
    };
    getSummary();
  }, [refresh]);

  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setRow();
    setPage(0);
    setLoading(true);
    let response = await getJobsApi({
      id: props.id,
      page: 0,
      page_size: e.target.value,
    });
    setRow(response.data.items);
    setCount(response.data.item_count);
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setRow();
    setPage(page - 1);
    setLoading(true);
    let response = await getJobsApi({
      id: props.id,
      page: page - 1,
      page_size: size,
    });
    setRow(response.data.items);
    setCount(response.data.item_count);
    setLoading(false);
  };
  return (
    <>
      <Container>
        <AddBtnWrapper>
          <AddBoxOutlined
            onClick={() => {
              setOpenImport(true);
            }}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </AddBtnWrapper>
        <Wrapper>
          {row ? (
            <DetailsWrapper>
              <TableWrapper>
                {row.length > 0 ? (
                  <TableContainer
                    component={Paper}
                    sx={{
                      width: "100%",
                      display: "table",
                      tableLayout: "fixed",
                    }}
                  >
                    <Table size="small" aria-label="a dense table">
                      <TableHead
                        style={{ backgroundColor: "#EFEFF4", height: "40px" }}
                      >
                        <TableRow>
                          {JobListColumns.map((e) => {
                            return (
                              <TableCell className="textContainer">
                                {e.headerName}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.map((e, i) => {
                          return (
                            <JobRowComponent
                              statusList={props.statusList}
                              pid={props.id}
                              item={e}
                              columns={ImportHistoryColumns}
                              idx={i}
                            />
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <>
                    <TableContainer
                      component={Paper}
                      style={{ width: "100%", overflowX: "auto" }}
                    >
                      <Table size="small" aria-label="a dense table">
                        <TableHead
                          style={{ backgroundColor: "#EFEFF4", height: "40px" }}
                        >
                          <TableRow>
                            {JobListColumns.map((e) => {
                              return (
                                <TableCell className="textContainer">
                                  {e.headerName}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody></TableBody>
                      </Table>
                    </TableContainer>
                    <EmptyTableWrapper>
                      <EmptyText>No rows</EmptyText>
                    </EmptyTableWrapper>
                  </>
                )}
              </TableWrapper>
              <PaginationWrapper>
                <PaginationComponent
                  page_no={page}
                  row={row}
                  page_size={size}
                  size={size}
                  count={count}
                  handlePageSize={handlePageSize}
                  handlePage={handlePagination}
                />
              </PaginationWrapper>
            </DetailsWrapper>
          ) : (
            <SpinnerWrapper>
              <Spinner src={spinner} alt="loading..." />
            </SpinnerWrapper>
          )}
        </Wrapper>
      </Container>
      {openImport && (
        <CreateJobModal
          id={props.id}
          open={openImport}
          setOpen={setOpenImport}
          refresh={refresh}
          setRefresh={setRefresh}
          statusList={props.statusList}
          setShowDeleteProtectedAlert={setShowDeleteProtectedAlert}
        />
      )}
      {showDeleteProtectedAlert && (
        <CustomMessageDialog
          open={showDeleteProtectedAlert}
          setOpen={setShowDeleteProtectedAlert}
          message={
            "Leads are delete-protected. Unprotect or remove them to proceed with deletion."
          }
        />
      )}
    </>
  );
};

export default observer(JobsTab);
