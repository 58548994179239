import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import styled from "styled-components";
import {
  getSummaryBarData,
  getWidgetDataApi,
  removeWidgetApi,
  removeWidgetApiv2,
} from "../../../Api";
import { useEffect } from "react";
import { useState } from "react";
import { StackedBarConfig } from "../../../Config";
import rootStore from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { IconButton, Tooltip, Menu } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CalendarToday, Fullscreen } from "@material-ui/icons";
import FullScreenModal from "../../full_screen_modal/FullScreenModal";
import createPlotlyComponent from "react-plotly.js/factory";
import { MenuItem } from "@mui/material";
const Plotly = window.Plotly;
const Plot = createPlotlyComponent(Plotly);
Chart.register(ArcElement);
const Response = {
  data: {
    "0cf105fd-b064-41c8-9941-98e495f43880": 1,
    "477e5ab0-5612-44aa-a2d7-742b37d083ef": 1,
    "50309544-dea6-4a4d-a90f-efd64081a748": 6,
    "6c00dabf-14ee-4887-87ee-abcc6a2cffb3": 1,
    "9acf39db-a741-403c-b804-723a8ca57283": 1,
    "d64341b5-e645-4ee9-9e9f-38c5a9faf176": 1,
    "": 5,
  },
  xdata: {
    lead_stage: [
      {
        id: "50309544-dea6-4a4d-a90f-efd64081a748",
        name: "Lead",
      },
      {
        id: "4b7026b8-8946-4a11-be24-071133c1572f",
        name: "Not Interested",
      },
      {
        id: "844b0128-d6c1-4b20-9b94-c7903d5b5e7c",
        name: "Prospect",
      },
      {
        id: "0cf105fd-b064-41c8-9941-98e495f43880",
        name: "Hot Prospect",
      },
      {
        id: "d64341b5-e645-4ee9-9e9f-38c5a9faf176",
        name: "Subscriber1",
      },
      {
        id: "0256f069-3be7-4bac-b367-5cb0e729ad0e",
        name: "Customer",
      },
      {
        id: "9acf39db-a741-403c-b804-723a8ca57283",
        name: "Subscriber_yt",
      },
    ],
    lead_stage_rank: [
      {
        id: "50309544-dea6-4a4d-a90f-efd64081a748",
        rank: 1,
      },
      {
        id: "4b7026b8-8946-4a11-be24-071133c1572f",
        rank: 2,
      },
      {
        id: "844b0128-d6c1-4b20-9b94-c7903d5b5e7c",
        rank: 2,
      },
      {
        id: "0cf105fd-b064-41c8-9941-98e495f43880",
        rank: 3,
      },
      {
        id: "d64341b5-e645-4ee9-9e9f-38c5a9faf176",
        rank: 4,
      },
      {
        id: "0256f069-3be7-4bac-b367-5cb0e729ad0e",
        rank: 4,
      },
      {
        id: "9acf39db-a741-403c-b804-723a8ca57283",
        rank: 4,
      },
    ],
  },
};
const emptyData = {
  labels: ["Red", "Orange", "Blue"],
  // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
  datasets: [
    {
      label: "Popularity of colours",
      data: [95, 23, 96],
      // you can set indiviual colors for each bar
      backgroundColor: ["#ffbb11", "#ffbb11", "#ffbb11", "#f3ba2f", "#2a71d0"],
      borderWidth: 1,
    },
    {
      label: "Popularity of colours",
      data: [75, 63, 96],
      // you can set indiviual colors for each bar
      backgroundColor: ["#ecf0f1", "#ecf0f1", "#ecf0f1", "#f3ba2f", "#2a71d0"],
      borderWidth: 1,
    },
    {
      label: "Popularity of colours",
      data: [85, 23, 36],
      // you can set indiviual colors for each bar
      backgroundColor: ["#50AF95", "#50AF95", "#50AF95", "#f3ba2f", "#2a71d0"],
      borderWidth: 1,
    },
  ],
};
const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: ${(e) => (e.type === "preview" ? "0px" : "20px")};
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
`;
const ChartTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.p`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Text2 = styled.p`
  font: normal normal 500 12px Open Sans;
  margin: 0px;
  text-align: left;
  color: #bbbab6;
  margin-right: 8px;
`;
const ITEM_HEIGHT = 48;
const FunnelChartWidget = (props) => {
  const { userStore } = rootStore;
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState("");
  const [showTop, setShowTop] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const Open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRemove = async () => {
    try {
      let response = await removeWidgetApiv2({ id: props.data.id });
      setAnchorEl(null);
      props.setRefresh(!props.refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const updateData = (val) => {
    let list = [];
    let checkData = val["data"];
    // console.log("funnel check data is :", checkData);
    let listY = [];
    let nameList = [];
    if (Object.keys(checkData).length > 0) {
      let group_first = Object.keys(props.data.xdata)[0];
      let search_value_first = props.data.xdata[group_first];
      let search_value_second = search_value_first + "_rank";
      let meta_first = val["xdata"][search_value_first];
      let meta_second = val["xdata"][search_value_second];

      if (meta_second.length > 0) {
        meta_second.map((item) => {
          let d = "Level " + item["rank"];
          if (!listY.includes(d)) {
            listY.push(d);
          }
        });
      }

      if (meta_first.length > 0) {
        meta_first.map((item) => {
          if (!listY.includes(item["name"])) {
            nameList.push(item["name"]);
          }
        });
      }
      let newMetaList = [];
      if (meta_first.length > 0) {
        meta_first.map((item) => {
          if (meta_second.length > 0) {
            meta_second.map((e) => {
              if (e["id"] === item["id"]) {
                item["rank"] = e["rank"];
              }
            });
          }
          newMetaList.push(item);
        });
      }
      if (newMetaList.length > 0) {
        newMetaList.map((item) => {
          let newObj = {
            type: "funnel",
            x: [],
            y: listY,
          };
          if (listY.length > 0) {
            listY.map((inner) => {
              if (
                checkData.hasOwnProperty(item["id"]) &&
                inner === "Level " + item["rank"]
              ) {
                newObj.x.push(checkData[item["id"]]);
              } else {
                newObj.x.push(0);
              }
            });
          }
          list.push(newObj);
        });
      }
      if (nameList.length > 0 && list.length > 0) {
        let newList = [];
        list.map((item, i) => {
          item["name"] = nameList[i];
          newList.push(item);
        });
        list = newList;
      }
    } else {
      list = [
        {
          type: "funnel",
          name: "no data",

          x: [0],
          textinfo: "value+percent initial",
        },
      ];
    }

    return list;
  };

  useEffect(() => {
    const getData = async () => {
      let response = await getWidgetDataApi({ id: props.data.id });
      let modifiedData = updateData(response.data);
      setData(modifiedData);
      if (props.data.rolling_date) {
        setDateRange(props.data.rolling_date_string);
      }
    };
    if (props.type !== "preview") {
      getData();
    }
  }, []);

  const ChartBottomSection = () => {
    if (props.type === "preview") {
      return (
        <Wrapper>
          <Plot
            data={[
              {
                type: "funnel",
                name: "Montreal",
                y: ["rank1", "rank2", "rank3", "rank4", "rank5"],
                x: [120, 60, 30, 20],
                textinfo: "value+percent initial",
              },
              {
                type: "funnel",
                name: "Toronto",
                y: ["rank1", "rank2", "rank3", "rank4", "rank5"],

                x: [100, 60, 40, 30, 20],
                textposition: "inside",
                textinfo: "value+percent previous",
              },
              {
                type: "funnel",
                name: "Vancouver",
                y: ["rank1", "rank2", "rank3", "rank4", "rank5"],

                x: [50, 70, 50, 30, 10, 5],
                textposition: "outside",
                textinfo: "value+percent total",
              },
            ]}
            layout={{
              funnelmode: "stack",
              // showlegend: "false",
              hovermode: "closest",
            }}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
        </Wrapper>
      );
    } else if (props.type !== "preview" && data) {
      return (
        <Wrapper>
          <Plot
            data={data}
            layout={{
              // margin: { l: 130, r: 0 },
              // width: 600,
              funnelmode: "stack",
              // showlegend: "false",
              hovermode: "closest",
            }}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
        </Wrapper>
      );
    }
  };

  return (
    <>
      {data ? (
        <Container type={props.type}>
          {props.type === "preview" ? (
            <Text style={{ textAlign: "center" }}>Preview</Text>
          ) : (
            <ChartTop>
              <Text>{props.data.title}</Text>
              <IconWrapper>
                <CalendarToday
                  style={{
                    width: "18px",
                    height: "18px",
                    marginRight: "8px",
                    color: "#BBBAB6",
                  }}
                />
                <Text2>{props.data.rolling_date && dateRange}</Text2>
                <IconButton
                  style={{ padding: "0px" }}
                  onClick={() => {
                    setShowTop(false);
                    setOpen(true);
                  }}
                >
                  <Fullscreen />
                </IconButton>
                <IconButton
                  onClick={handleClick}
                  aria-label="more"
                  id="long-button"
                  aria-controls={Open ? "long-menu" : undefined}
                  aria-expanded={Open ? "true" : undefined}
                  aria-haspopup="true"
                  style={{ padding: "0px", marginRight: "-10px" }}
                >
                  <MoreVertIcon />
                </IconButton>
              </IconWrapper>
            </ChartTop>
          )}
          <ChartBottomSection />
        </Container>
      ) : (
        <Container type={props.type}>
          {props.type === "preview" ? (
            <Text style={{ textAlign: "center" }}>Preview</Text>
          ) : (
            <ChartTop>
              <Text></Text>
              <IconWrapper>
                <IconButton
                  style={{ padding: "0px" }}
                  onClick={() => {
                    setShowTop(false);
                    setOpen(true);
                  }}
                >
                  <Fullscreen />
                </IconButton>
                <IconButton
                  onClick={handleClick}
                  aria-label="more"
                  id="long-button"
                  aria-controls={Open ? "long-menu" : undefined}
                  aria-expanded={Open ? "true" : undefined}
                  aria-haspopup="true"
                  style={{ padding: "0px", marginRight: "-10px" }}
                >
                  <MoreVertIcon />
                </IconButton>
              </IconWrapper>
            </ChartTop>
          )}
          <ChartBottomSection />
        </Container>
      )}
      {open && (
        <FullScreenModal
          open={open}
          setOpen={setOpen}
          myComponent={ChartBottomSection}
          setShowTop={setShowTop}
        />
      )}
      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
          },
        }}
      >
        <MenuItem style={{ fontSize: "12px" }} onClick={handleRemove}>
          Remove
        </MenuItem>
      </Menu>
    </>
  );
};

export default observer(FunnelChartWidget);
