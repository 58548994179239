import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { AppBar, Box, OutlinedInput, Toolbar, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import MuiAlert from "@mui/material/Alert";
import {
  createContactApi,
  editContactApi,
  editOrganizationApi,
  editProductApi,
  getContactDetailsApi,
  getProductDetailsApi,
} from "../../Api";
import SingleSelectNew from "../select_dropdown/SingleSelectNew";
import SingleSelect from "../select_dropdown/SingleSelect";
import { IsoToLocalDate, getIsoString } from "../../Functions";
import SelectFieldSlug from "../custom_field_components/SelectFieldSlug";
import SelectField from "../custom_field_components/SelectField";
import rootStore from "../../stores/RootStore";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const EditProductSidePanel = (props) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [fieldsToRender, setFieldsToRender] = useState([]);
  const [details, setDetails] = useState({});
  const [changedValues, setChangedValues] = useState({});
  const columnsList = [...rootStore.productStore.columnsList];

  const handleClose = () => {
    props.setOpen(false);
  };

  const generatePayloadData = (data) => {
    let fieldList = {};
    for (const key in data) {
      let value = data[key]["value"];
      if (value !== "") {
        if (data[key]["type"] === "date" || data[key]["type"] === "datetime") {
          value = getIsoString(value);
        } else if (data[key]["type"] === "integer") {
          value = parseInt(value);
        }

        fieldList[key] = value;
      }
    }

    return fieldList;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = generatePayloadData({ ...changedValues });
    if (Object.keys(payload).length > 0) {
      let response = await editProductApi({
        productID: props.productID,
        payload: payload,
      });
      if (response.hasError()) {
        setIsSubmitSuccess(false);
        setErrorMessage(response.errorMessage);
        setIsSubmitFail(true);
      } else {
        props.setRefresh(true);
        setIsSubmitSuccess(true);
      }
    } else {
      setErrorMessage("Kindly make a modification before submitting the form.");
      setIsSubmitFail(true);
    }
  };

  const getValue = (data, key, type) => {
    if (data.hasOwnProperty(key)) {
      let value = data[key];

      if (type === "date" || type === "datetime") {
        value = IsoToLocalDate(value);
      }
      return value !== null ? value : "";
    } else {
      return "";
    }
  };

  const initEditableData = (sortedColumnList, editableData) => {
    let tempList = {};

    if (sortedColumnList.length > 0) {
      for (let i = 0; i < sortedColumnList.length; i++) {
        let item = sortedColumnList[i];

        let value = getValue(
          editableData,
          item["column_name"],
          item["data_type"]
        );
        tempList[item["column_name"]] = {
          value: value,
          type: item["data_type"],
        };
      }
    }
    return tempList;
  };

  function sortColumnFields() {
    let tempColumns = columnsList.filter(
      (columnData) =>
        columnData["is_visible"] && columnData["show_in_edit_form"]
    );
    let requiredFields = tempColumns
      .filter((item) => item["required"])
      .map((item) => item)
      .sort((a, b) =>
        a["display_name"]
          .toLowerCase()
          .localeCompare(b["display_name"].toLowerCase())
      );
    let allFields = [...requiredFields];

    let remainingFields = [];
    tempColumns.forEach((item) => {
      let index = allFields.findIndex(
        (element) => element["id"] === item["id"]
      );
      if (index === -1) {
        remainingFields.push(item);
      }
    });
    remainingFields.sort((a, b) =>
      a["display_name"]
        .toLowerCase()
        .localeCompare(b["display_name"].toLowerCase())
    );
    remainingFields.forEach((data) => {
      let index = allFields.findIndex(
        (element) => element["id"] === data["id"]
      );
      if (index === -1) {
        allFields.push(data);
      }
    });
    allFields.sort((a, b) => {
      if (a.is_multiline && !b.is_multiline) {
        return 1;
      } else if (!a.is_multiline && b.is_multiline) {
        return -1;
      } else {
        return 0;
      }
    });

    for (const item of allFields) {
      if (item.is_multiline) {
        item.width = 2;
        item.modifyWidth = false;
      } else {
        item.width = 1;
        item.modifyWidth = true;
      }
    }
    for (let i = 0; i < allFields.length; i++) {
      let currentItem = allFields[i];

      if (currentItem.modifyWidth) {
        if (i === allFields.length - 1) {
          currentItem.width = 2;
          currentItem.modifyWidth = false;
        } else {
          let nextItem = allFields[i + 1];

          if (nextItem.width === 2) {
            currentItem.width = 2;
            currentItem.modifyWidth = false;
          } else if (nextItem.width === 1) {
            currentItem.width = 1;
            currentItem.modifyWidth = false;
            nextItem.modifyWidth = false;
          }
        }
      }
    }
    return allFields;
  }
  const init = async () => {
    let sortedFields = sortColumnFields();
    setFieldsToRender(sortedFields);
    let productDetails = await getProductDetails();
    let preFilledData = initEditableData(sortedFields, productDetails);
    setDetails(preFilledData);
    setLoading(false);
  };

  const getProductDetails = async () => {
    let response = await getProductDetailsApi({ productID: props.productID });
    return response;
  };

  useEffect(() => {
    init();
  }, []);

  const handleChange = ({ e, dataType }) => {
    let value =
      dataType === "integer" ? parseInt(e.target.value) : e.target.value;
    setDetails({
      ...details,
      [e.target.name]: { value: value, type: dataType },
    });
    setChangedValues({
      ...changedValues,
      [e.target.name]: { value: value, type: dataType },
    });
  };

  return !loading ? (
    <>
      {fieldsToRender.length > 0 ? (
        <Box role="presentation">
          <AppBar
            component={"nav"}
            position="sticky"
            color="inherit"
            elevation={0}
            sx={{
              top: 0,
              bottom: "auto",
              width: 500,
              right: 0,
              bgcolor: "#f9f9fc",
            }}
          >
            <Toolbar>
              <ModelHeader>Edit Details</ModelHeader>
            </Toolbar>
          </AppBar>
          {!isSubmitSuccess ? (
            <ModelWrapper component={"form"} onSubmit={handleSubmit}>
              <InputContainer>
                <Grid container spacing={2}>
                  {fieldsToRender.map((item) => {
                    if (item.input === "" || item.input === null) {
                      if (item.is_multiline) {
                        return (
                          <Grid item xs={12}>
                            <InputWrapper>
                              {item.required ? (
                                <Label>{item.display_name}*</Label>
                              ) : (
                                <Label>{item.display_name}</Label>
                              )}
                              <OutlinedInput
                                multiline={true}
                                rows={3}
                                inputProps={{
                                  min: 0,
                                  "aria-label": "weight",
                                  style: {
                                    fontSize: "12px",
                                  },
                                }}
                                required={item.required}
                                value={
                                  details.hasOwnProperty(item.column_name)
                                    ? details[item.column_name]["value"]
                                    : ""
                                }
                                disabled={!item.editable}
                                name={item.column_name}
                                type={"text"}
                                placeholder={`Enter ${item.display_name}`}
                                onChange={(e) => {
                                  handleChange({ e, dataType: item.data_type });
                                }}
                              />
                            </InputWrapper>
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid item xs={item["width"] === 1 ? 6 : 12}>
                            <InputWrapper>
                              {item.required ? (
                                <Label>{item.display_name}*</Label>
                              ) : (
                                <Label>{item.display_name}</Label>
                              )}
                              <OutlinedInput
                                style={{ height: "30px" }}
                                inputProps={{
                                  min: 0,
                                  "aria-label": "weight",
                                  style: {
                                    fontSize: "12px",
                                  },
                                }}
                                disabled={!item.editable}
                                required={item.required}
                                value={
                                  details.hasOwnProperty(item.column_name)
                                    ? details[item.column_name]["value"]
                                    : ""
                                }
                                name={item.column_name}
                                type={
                                  item.data_type === "email"
                                    ? "email"
                                    : item.data_type === "datetime" ||
                                      item.data_type === "date"
                                    ? "date"
                                    : item.data_type === "integer"
                                    ? "number"
                                    : "text"
                                }
                                placeholder={`Enter ${item.display_name}`}
                                onChange={(e) => {
                                  handleChange({ e, dataType: item.data_type });
                                }}
                              />
                            </InputWrapper>
                          </Grid>
                        );
                      }
                    } else if (item.input.charAt(0) === "/") {
                      let ep = item.input.slice(1);
                      return (
                        <Grid item xs={item["width"] === 1 ? 6 : 12}>
                          <SelectFieldSlug
                            ep={ep}
                            label={item.display_name}
                            value={details}
                            field={item.column_name}
                            required={item.required}
                            readOnly={!item.editable}
                            handleChange={(event) => {
                              handleChange({
                                e: event,
                                dataType: item.data_type,
                              });
                            }}
                          />
                        </Grid>
                      );
                    } else if (
                      item.input.charAt(0) !== "/" &&
                      item.input !== "" &&
                      item.input !== null
                    ) {
                      return (
                        <Grid item xs={item["width"] === 1 ? 6 : 12}>
                          <SelectField
                            list={item.input}
                            field={item.column_name}
                            label={item.display_name}
                            value={details}
                            required={item.required}
                            readOnly={!item.editable}
                            handleChange={(event) => {
                              handleChange({
                                e: event,
                                dataType: item.data_type,
                              });
                            }}
                          />
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </InputContainer>

              <AppBar
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{ top: "auto", bottom: 0, width: 500 }}
              >
                <Toolbar style={{ width: 500, position: "relative" }}>
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <ButtonWrapper>
                    <Button
                      onClick={handleClose}
                      type="button"
                      variant="contained"
                      color="default"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        backgroundColor: "#185DD2",
                        color: "white",
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Submit
                    </Button>
                  </ButtonWrapper>
                </Toolbar>
              </AppBar>
            </ModelWrapper>
          ) : (
            <MessageWrapper>
              <Message>Product updated successfully!</Message>

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  marginTop: "20px",
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                Close
              </Button>
            </MessageWrapper>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: 500,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MessageWrapper>
            <Message
              style={{
                color: "#4d4e4f",
              }}
            >
              No fields to show.
            </Message>
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        </Box>
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(EditProductSidePanel);
