import React, { useEffect, useState } from "react";
import styled from "styled-components";
import spinner from "../../../assets/images/gifs/spinner.gif";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import rootStore from "../../../stores/RootStore";
import LeadAssignModal from "../../lead_assign_modal/LeadAssignModal";
import { observer } from "mobx-react-lite";
import {
  deleteMultipleLeadsApi,
  getFilterColsApiV2,
  getFilteredLeadsApiV2,
  getLeadsApi,
  markUnmarkMultipleLeadAsFreshLeadApi,
} from "../../../Api";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import PaginationComponent from "../../pagination/PaginationComponent";
import {
  FormattedColumnsFilters,
  leadListingIconsColumn,
} from "../../../ColumnUtils";
import { Assignment } from "@material-ui/icons";
import {
  checkFeaturePermission,
  isLastContactedToday,
} from "../../../Functions";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SuccessMessageDialog from "../../alert_dialogue/SuccessMessageDialog";
import ErrorMessageDialog from "../../alert_dialogue/ErrorMessageDialog";
import { Backdrop, CircularProgress } from "@mui/material";
import CustomConfirmationDialog from "../../alert_dialogue/CustomConfirmationDialog";
import MultiLeadExportModal from "../../multi_lead_export_modal/MultiLeadExportModal";
import MultiLeadUpdateStageModal from "../../update_stage_modal/MultiLeadUpdateStageModal";
import analytics from "../../../Analytics";
import DeleteLeadConfirmationDialog from "../../alert_dialogue/DeleteLeadConfirmationDialog";
import CustomMessageDialog from "../../alert_dialogue/CustomMessageDialog";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const Header = styled.span`
  font: normal normal 600 28px Open Sans;
  color: #4d4e4f;
  margin-bottom: 20px;
`;
const Container = styled.div`
  min-height: 100vh;
  background-color: white;
  padding: 20px;
  width: 100%;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 400px;
  flex-direction: column;
  margin: auto;
`;
const PaginationWrapper = styled.div`
  padding: 0px 20px;
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 130px);`};
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "white",
      backgroundColor: "#4D4E4F",
      margin: "0px",
      borderRadius: "0px",
      height: "25px",
      minWidth: "20px",
    },
    "& .MuiPaginationItem-root": {
      margin: "0px",
      borderRadius: "0px",
      height: "25px",
      minWidth: "20px",
    },
  },
}));
const ITEM_HEIGHT = 48;
const get_leads_url = process.env.REACT_APP_API_BASE_URL;
const FilterTable = (props) => {
  const { authStore, userStore } = rootStore;
  let navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [openAssignLead, setOpenAssignLead] = useState(false);
  const [columns, setColumns] = useState([]);
  const [assignState, setAssignState] = useState(0);
  let pid = authStore.projectId;
  let uid = localStorage.getItem("uid");
  let columnsList = [...userStore.AllColumnsList];
  let url = `${get_leads_url}projects/${pid}/leads/tab`;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const Open = Boolean(anchorEl);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openConfirmMarkLeadDialog, setOpenConfirmMarkLeadDialog] =
    useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [openUpdateStageModal, setOpenUpdateStageModal] = useState(false);
  const [openDeleteLead, setOpenDeleteLead] = useState(false);
  const stagesList = [...rootStore.authStore.stageList];
  const statusList = [...rootStore.authStore.statusList];
  const [showDeleteProtectedAlert, setShowDeleteProtectedAlert] =
    useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageSize = (e) => {
    props.setFlag(true);
    userStore.updateCurrentFilterPageSize(e.target.value);
    userStore.updateCurrentFilterPage(0);
  };

  const getColumnsForTab = async () => {
    let index = props.tabsList.findIndex((tab) => tab["id"] === props.tabId);
    if (index !== -1) {
      let fieldList = [];
      props.tabsList[index]["selected_columns"].forEach((column) => {
        let index = columnsList.findIndex(
          (columnData) => columnData["field"] === column["field"]
        );
        if (index !== -1) {
          fieldList.push(columnsList[index]);
        }
      });
      let modifiedList = FormattedColumnsFilters(fieldList);
      setColumns(modifiedList);
    }
  };
  const getTabLeads = async () => {
    setRow([]);
    setLoading(true);
    let payload = {
      tab_id: props.tabId,
      query: props.filterPayload,
    };
    let response = await getLeadsApi({
      page: props.page,
      page_size: props.size,
      payload: payload,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    }
    setLoading(false);
    props.setFlag(false);
  };
  const getAllLeads = async () => {
    setRow([]);
    setLoading(true);
    try {
      let response = await getFilteredLeadsApiV2({
        page_size: props.size,
        page_no: props.page,
        payload: { query: props.filterPayload },
      });
      setRow(response.data.items);
      setCount(response.data.item_count);
      setLoading(false);
      props.setFlag(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getFilterColumns = async () => {
    try {
      let response = await getFilterColsApiV2();
      let filterColumnsList = await response.data;
      let allColumnList = userStore.AllColumnsList;

      let newColumnsList = [];
      if (filterColumnsList.length > 0 && allColumnList.length > 0) {
        filterColumnsList.map((item) => {
          allColumnList.map((key) => {
            if (key["field"] === item["field"]) {
              // let newItem = key;
              // newItem["dataType"] = key["data_type"];
              newColumnsList.push(key);
            }
          });
        });
      }
      let columnsList = FormattedColumnsFilters(newColumnsList);
      setColumns(columnsList);
    } catch (error) {}
  };

  useEffect(() => {
    getFilterColumns();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.isViewReset === false) {
      if (props.flag === true) {
        if (props.tabId === null) {
          getFilterColumns();
        } else if (props.tabId !== "all") {
          getColumnsForTab();
        } else {
          getFilterColumns();
        }

        if (props.tabId !== null) {
          if (props.tabId !== "all") {
            getTabLeads();
          } else {
            getAllLeads();
          }
        } else {
          setRow([]);
          setCount(0);
        }
      }
    } else {
      setRow([]);
      setCount(0);
    }
  }, [props.flag, props.tabId, props.size, props.page, props.refresh]);

  useEffect(() => {
    if (refresh === true) {
      if (props.tabId !== "all") {
        getTabLeads();
      } else {
        getAllLeads();
      }
      setRefresh(false);
    }
  }, [refresh]);
  const handlePage = (e, page) => {
    props.setFlag(true);
    userStore.updateCurrentFilterPage(page - 1);
  };

  const handleMarkLeadAsFreshLead = async () => {
    setOpenConfirmMarkLeadDialog(false);
    setShowBackDrop(true);
    let payload = { lead_ids: leads[0], status: true };
    let response = await markUnmarkMultipleLeadAsFreshLeadApi({
      payload: payload,
    });
    if (response.hasError()) {
      setShowBackDrop(false);
      setOpenErrorDialog(true);
    } else {
      setShowBackDrop(false);
      setSuccessMessage("Leads successfully marked as fresh!");
      setOpenSuccessDialog(true);
      setRefresh(true);
    }
  };

  //*******************delete leads related functions************* */

  const isStageDeleteProtected = ({ leadData }) => {
    let index = stagesList.findIndex(
      (stage) => stage["stage"] === leadData["lead_stage"]
    );
    if (index !== -1) {
      return stagesList[index]["delete_protected"];
    }
    return false;
  };
  const isStatusDeleteProtected = ({ leadData }) => {
    let index = statusList.findIndex(
      (status) => status["status"] === leadData["lead_status"]
    );
    if (index !== -1) {
      return statusList[index]["delete_protected"];
    }
    return false;
  };

  const isDeleteProtected = ({ leadData }) => {
    return (
      isStageDeleteProtected({ leadData: leadData }) ||
      isStatusDeleteProtected({ leadData: leadData })
    );
  };

  const isAnyLeadDeleteProtected = () => {
    for (let i = 0; i < leads[0].length; i++) {
      let index = row.findIndex((lead) => lead["id"] === leads[0][i]);
      let leadData = row[index];
      if (isDeleteProtected({ leadData: leadData })) {
        return true;
      }
    }
    return false;
  };

  const handleDeleteLead = async () => {
    setOpenDeleteLead(false);
    setShowBackDrop(true);

    if (isAnyLeadDeleteProtected()) {
      setShowBackDrop(false);
      setShowDeleteProtectedAlert(true);
    } else {
      let response = await deleteMultipleLeadsApi({ idList: leads[0] });
      if (response.hasError()) {
        setShowBackDrop(false);
        setOpenErrorDialog(true);
      } else {
        setShowBackDrop(false);
        setSuccessMessage("Leads deleted successfully!");
        setOpenSuccessDialog(true);
        setRefresh(true);
      }
    }
  };

  //****************************END********************************** */

  return (
    <>
      <Container>
        <Row>
          <Header>Search Results</Header>
          <Row style={{ width: "fit-content" }}>
            {checkFeaturePermission("assign_leads") && (
              <IconButton
                disabled={assignState < 1}
                onClick={() => {
                  analytics.triggerEvent(
                    4625000260,
                    "assign_lead_button_tap",
                    "filter_page",
                    "assign_lead_button",
                    {}
                  );
                  setOpenAssignLead(true);
                }}
              >
                <Assignment style={{ cursor: "pointer" }} />
              </IconButton>
            )}

            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={Open ? "long-menu" : undefined}
              aria-expanded={Open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              style={{ display: "flex", flexDirection: "column" }}
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={Open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {/* <MenuItem
                disabled={assignState < 1}
                onClick={() => {
                  setOpenExportDialog(true);
                  setAnchorEl(null);
                }}
              >
                Export
              </MenuItem> */}
              {checkFeaturePermission("lead_listing_delete") && (
                <MenuItem
                  disabled={assignState < 1}
                  onClick={() => {
                    authStore.updateLastActiveTime();
                    setOpenDeleteLead(true);
                    handleClose();
                  }}
                >
                  Delete Leads
                </MenuItem>
              )}

              {checkFeaturePermission("update_stage") && (
                <MenuItem
                  disabled={assignState < 1}
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000261,
                      "update_stage_button_tap",
                      "filter_page",
                      "update_stage_button",
                      {}
                    );
                    authStore.updateLastActiveTime();
                    setOpenUpdateStageModal(true);
                    handleClose();
                  }}
                >
                  Update Stage
                </MenuItem>
              )}

              {checkFeaturePermission("fresh_lead") && (
                <MenuItem
                  disabled={assignState < 1}
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000262,
                      "mark_fresh_lead_button_tap",
                      "filter_page",
                      "mark_fresh_lead_button",
                      {}
                    );
                    setOpenConfirmMarkLeadDialog(true);
                    setAnchorEl(null);
                  }}
                >
                  Mark as Fresh Lead
                </MenuItem>
              )}
            </Menu>
          </Row>
        </Row>
        <Wrapper>
          {row ? (
            <>
              <TableWrapper>
                <Box
                  sx={{
                    "& .last-contacted-today": {
                      backgroundColor: "#E6EDF7",
                    },
                    "& .not-last-contacted-today": {
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                >
                  <DataGrid
                    getRowClassName={(params) =>
                      isLastContactedToday(params.row["last_contacted_on"])
                        ? "last-contacted-today"
                        : "not-last-contacted-today"
                    }
                    autoHeight={true}
                    rows={row}
                    columns={leadListingIconsColumn.concat(columns)}
                    pageSize={props.size}
                    checkboxSelection
                    style={{ cursor: "pointer" }}
                    hideFooter={true}
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Array(ids);
                      setAssignState(selectedIDs[0].length);
                      setLeads(selectedIDs);
                    }}
                    loading={loading}
                    disableColumnMenu={true}
                    onRowClick={(e) => {
                      navigate(`/users/${uid}/leads/${e.id}`);
                    }}
                  />
                </Box>
              </TableWrapper>
              <PaginationWrapper>
                <PaginationComponent
                  page_no={props.page}
                  row={row}
                  page_size={props.size}
                  size={props.size}
                  count={count}
                  handlePageSize={handlePageSize}
                  handlePage={handlePage}
                />
              </PaginationWrapper>
            </>
          ) : (
            <SpinnerWrapper>
              <Spinner src={spinner} alt="loading..." />
            </SpinnerWrapper>
          )}

          {openAssignLead && (
            <LeadAssignModal
              open={openAssignLead}
              setOpen={setOpenAssignLead}
              leads={leads}
              pid={pid}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          )}
        </Wrapper>
      </Container>

      {openSuccessDialog && (
        <SuccessMessageDialog
          open={openSuccessDialog}
          setOpen={setOpenSuccessDialog}
          message={successMessage}
        />
      )}
      {openErrorDialog && (
        <ErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
        />
      )}

      {openDeleteLead && (
        <DeleteLeadConfirmationDialog
          open={openDeleteLead}
          setOpen={setOpenDeleteLead}
          handleConfirm={() => {
            handleDeleteLead();
          }}
        />
      )}

      {openConfirmMarkLeadDialog && (
        <CustomConfirmationDialog
          message={"Are you sure you want to mark the leads as fresh?"}
          open={openConfirmMarkLeadDialog}
          setOpen={setOpenConfirmMarkLeadDialog}
          handleConfirm={() => {
            handleMarkLeadAsFreshLead();
          }}
        />
      )}
      {openUpdateStageModal && (
        <MultiLeadUpdateStageModal
          open={openUpdateStageModal}
          setOpen={setOpenUpdateStageModal}
          selectedLeadIdsData={leads}
          setRefresh={setRefresh}
          scope={"filter_page"}
        />
      )}
      <Backdrop
        open={showBackDrop}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
      {showDeleteProtectedAlert && (
        <CustomMessageDialog
          open={showDeleteProtectedAlert}
          setOpen={setShowDeleteProtectedAlert}
          message={
            "Leads are delete-protected. Unprotect or remove them to proceed with deletion."
          }
        />
      )}
      {/* {openExportDialog && (
        <MultiLeadExportModal
          open={openExportDialog}
          setOpen={setOpenExportDialog}
          leads={leads}
          setRefresh={setRefresh}
        />
      )} */}
    </>
  );
};

export default observer(FilterTable);
