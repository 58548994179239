import React, { useState } from "react";
import { useRef } from "react";
import { removeWidgetApiv2 } from "../../../Api";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CalendarToday, Fullscreen } from "@material-ui/icons";
import FullScreenModal from "../../full_screen_modal/FullScreenModal";
import { Menu, MenuItem } from "@mui/material";
import ThreeGroupChartComponent from "./ThreeGroupChartComponent";
const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: ${(e) => (e.type === "preview" ? "0px" : "20px")};
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
`;
const ChartTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.p`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Text2 = styled.p`
  font: normal normal 500 12px Open Sans;
  margin: 0px;
  text-align: left;
  color: #bbbab6;
  margin-right: 8px;
`;

const margin = {
  top: 80,
  right: 180,
  bottom: 80,
  left: 180,
};

const ITEM_HEIGHT = 48;

const ThreeGroupBarGraphWidget = (props) => {
  const svg = useRef(null);
  const [open, setOpen] = useState(false);
  const [showTop, setShowTop] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateRange, setDateRange] = useState("");
  const Open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRemove = async () => {
    try {
      let response = await removeWidgetApiv2({ id: props.data.id });
      setAnchorEl(null);
      props.setRefresh(!props.refresh);
    } catch (error) {
      console.log(error);
    }
  };

  const ChartBottomSection = () => {
    return (
      <Wrapper>
        <ThreeGroupChartComponent data={props.data} />
      </Wrapper>
    );
  };

  return (
    <>
      <Container type={props.type}>
        <ChartTop>
          <Text>{props.data.title}</Text>
          <IconWrapper>
            <Text2>{props.data.rolling_date && dateRange}</Text2>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => {
                setShowTop(false);
                setOpen(true);
              }}
            >
              <Fullscreen />
            </IconButton>
            <IconButton
              onClick={handleClick}
              aria-label="more"
              id="long-button"
              aria-controls={Open ? "long-menu" : undefined}
              aria-expanded={Open ? "true" : undefined}
              aria-haspopup="true"
              style={{ padding: "0px", marginRight: "-10px" }}
            >
              <MoreVertIcon />
            </IconButton>
          </IconWrapper>
        </ChartTop>
        <ChartBottomSection />
      </Container>

      {open && !showTop && (
        <FullScreenModal
          open={open}
          setOpen={setOpen}
          myComponent={ChartBottomSection}
          setShowTop={setShowTop}
          title={props.data.title}
        />
      )}
      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
          },
        }}
      >
        <MenuItem
          style={{ fontSize: "12px" }}
          onClick={handleRemove}
          disabled={!props.isDashboardEditable()}
        >
          Remove
        </MenuItem>
      </Menu>
    </>
  );
};

export default ThreeGroupBarGraphWidget;
