import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChartSelectFilterNoraml from "../chart_select_filters/ChartSelectFilterNormal";
import ChartSelectFilterSlug from "../chart_select_filters/ChartSelectFilterSlug";
import { consoleLogger } from "../../../Functions";

const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Input = styled.input`
  width: 220px;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const SelectedFilterComponent = (props) => {
  const [selectedFilter, setSelectedFilter] = useState("");
  useEffect(() => {
    consoleLogger("selected filter is :", props.selectedFilter);
    setSelectedFilter(props.selectedFilter);
  }, [props.selectedFilter]);
  return (
    <div>
      <SelectColumn>
        <Label>Value</Label>
        {(() => {
          if (selectedFilter.filter_type === "list") {
            if (
              selectedFilter.filter_input.charAt(0) === "/" ||
              selectedFilter.filter_input.startsWith("ep:")
            ) {
              return (
                <ChartSelectFilterSlug
                  item={selectedFilter}
                  headerName={selectedFilter.headerName}
                  setSelectedVal={props.setSelectedVal}
                  selectedVal={props.selectedVal}
                  field={selectedFilter["field"]}
                  setFilterValue={props.setFilterValue}
                  filters={props.filters}
                  setFilters={props.setFilters}
                  type={selectedFilter["data_type"]}
                />
              );
            } else if (
              selectedFilter.filter_input !== "" &&
              selectedFilter.filter_input.charAt(0) !== "/"
            ) {
              return (
                <ChartSelectFilterNoraml
                  list={selectedFilter.filter_input}
                  field={selectedFilter["field"]}
                  headerName={selectedFilter.headerName}
                  setSelectedVal={props.setSelectedVal}
                  selectedVal={props.selectedVal}
                  setFilterValue={props.setFilterValue}
                  filters={props.filters}
                  setFilters={props.setFilters}
                  type={selectedFilter["data_type"]}
                />
              );
            } else if (
              selectedFilter.filter_input === null ||
              selectedFilter.filter_input === ""
            ) {
              alert("hello");
              return (
                <Input
                  id="standard-basic"
                  label="value"
                  variant="standard"
                  value={props.filterValue}
                  placeholder="enter value"
                  onChange={props.handleValue}
                />
              );
            }
          } else if (
            selectedFilter.filter_input === null ||
            selectedFilter.filter_input === ""
          ) {
            return (
              <Input
                id="standard-basic"
                label="value"
                variant="standard"
                value={props.filterValue}
                placeholder="enter value"
                onChange={props.handleValue}
              />
            );
          }
        })()}
      </SelectColumn>
    </div>
  );
};

export default SelectedFilterComponent;
