import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FilterTable from "../../components/filter_page_components/filter_table/FilterTable";
import { useEffect } from "react";
import styled from "styled-components";
import {
  Alert,
  Button,
  IconButton,
  MenuItem,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import {
  FormControl,
  makeStyles,
  Select as MuiSelect,
} from "@material-ui/core";
import { Add, Cancel, ChevronLeft, Menu, Save } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import rootStore from "../../stores/RootStore";
import { getTabsApi } from "../../Api";
import { useState } from "react";
import FilterValueComponent from "../../components/filter_page_components/filter_table/FilterValueComponent";
import { getIsoString } from "../../Functions";
import { Circle, SimCardDownload } from "@mui/icons-material";
import LoadFilterModal from "../../components/filter_page_components/modals/LoadFilterModal";
import SaveFilterModal from "../../components/filter_page_components/modals/SaveFilterModal";
import SaveFilterConfirmationDialog from "../../components/alert_dialogue/SaveFilterConfirmationDialog";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const FilterTitle = styled.span`
  font: normal normal 600 15px Open Sans;
  color: #4d4e4f;
`;
const VerticalSpaceBetween = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const FilterListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${`max-height: calc(100vh - 540px);`};
  overflow-y: auto;
  column-gap: 10px;
  row-gap: 10px;
  padding: 0px 20px;
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #efeff4;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #666;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  chipLabel: {
    whiteSpace: "normal",
  },
  icon: {
    color: "#4D4E4F",
  },
});
const CustomLabel = styled.div`
  display: flex;
`;
const ChipLable = styled.span`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
  margin-right: 4px;
`;
const ChipValue = styled.span`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  word-wrap: break-word;
  width: 100%;
`;
const CustomChip = styled.div`
  display: flex;
  position: relative;
  padding: 20px;
  background-color: #e0e0e0;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  border-radius: 12px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;
const drawerWidth = 300;

const FilterPage = (props) => {
  const { authStore, userStore } = rootStore;
  let pid = authStore.projectId;
  const [selected, setSelected] = useState([]);
  const [dateRange, setDateRange] = useState({});
  const [numRange, setNumRange] = useState({});
  const [selectedVal, setSelectedVal] = useState({});
  const [filters, setFilters] = useState({});
  const [tabsList, setTabsList] = React.useState([]);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [flag, setFlag] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedFilterID, setSelectedFilterID] = useState("");
  const [filterList, setFilterList] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const viewId = userStore.currentLoadedFilter.hasOwnProperty("viewId")
    ? userStore.currentLoadedFilter["viewId"]
    : userStore.currentFilterViewId;
  const payload = userStore.currentFilterPayload;
  const page_size = userStore.currentFilterPageSize;
  const page_number = userStore.currentFilterPage;
  const renderList = userStore.currentFilterRenderList;
  const inUseFiltersList = userStore.inUseFiltersList;
  const [isViewReset, setIsViewReset] = useState(false);
  const [openSaveFilterDialog, setOpenSaveFilterDialog] = useState(false);
  const [openLoadFilterDialog, setOpenLoadFilterDialog] = useState(false);
  const [openSaveConfirmationDialog, setOpenSaveConfirmationDialog] =
    useState(false);
  const columnsList = [...userStore.AllColumnsList];
  const [clearableSelectedFilter, setClearableSelectedFilter] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const classes = useStyles();
  const navigate = useNavigate();

  const getHeaderName = (field) => {
    let key = field;
    let headerName = "";

    if (columnsList.length > 0) {
      columnsList.map((item) => {
        if (item.field === key) {
          headerName = item.headerName;
        }
      });
    }
    return headerName;
  };
  const getAllFilterColumns = async () => {
    try {
      let tempFilterList = [];
      if (columnsList.length > 0) {
        columnsList.map((item) => {
          if (item.filterable === true && item.is_multiline === false) {
            tempFilterList.push(item);
          }
        });
      }
      setFilterColumns(tempFilterList);
    } catch (error) {
      console.log(error);
    }
  };

  const updateTabSearchColumns = (selectedTabId) => {
    let searchCols = [];
    tabsList.map((item) => {
      if (item["id"] === selectedTabId) {
        searchCols = item["search_columns"];
      }
    });
    if (columnsList.length > 0) {
      let newCols = [];
      columnsList.map((item) => {
        if (searchCols.includes(item.field)) {
          newCols.push(item);
        }
      });
      setFilterColumns(newCols);
    }
  };

  useEffect(() => {
    const getTabs = async () => {
      try {
        let response = await getTabsApi(pid);
        let defaultView = [{ name: "All", id: "all" }];
        setTabsList(defaultView.concat(response.data));
        if (viewId !== null) {
          if (viewId !== "all") {
            updateTabSearchColumns(viewId);
          } else {
            getAllFilterColumns();
          }
          if (Object.keys(renderList).length > 0) {
            setFlag(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getTabs();
    // getFilterColumns();
  }, [pid, refresh]);

  useEffect(() => {
    authStore.updateLeadDetailStatus();
  }, []);

  const resetFilters = () => {
    setDateRange({});
    setNumRange({});
    setSelectedVal({});
    setFilters({});
    setSelected([]);
    setFilterList({});
  };
  const isAddFilterButtonEnabled = () => {
    if (Object.keys(filterList).length > 0) {
      let data = filterList[selectedFilter["field"]];
      if (data["type"] === "list") {
        return data["value"].length > 0;
      } else if (data["type"] === "range") {
        return (
          data["value"].hasOwnProperty("start") &&
          data["value"].hasOwnProperty("end")
        );
      } else {
        return data["value"] !== null && data["value"] !== "";
      }
    }
    return false;
  };
  const handleAdd = () => {
    setShowAlert(false);
    let newList = {};
    if (Object.keys(filterList).length > 0) {
      Object.keys(filterList).map((item) => {
        newList[item] = filterList[item];
        if (!inUseFiltersList.includes(item)) {
          userStore.updateInUseFilterList([...inUseFiltersList, item]);
        }
      });
    }
    userStore.updateCurrentFilterRenderList({ ...renderList, ...newList });

    resetFilters();
    setSelectedFilter(null);
    setSelectedFilterID("");
    setClearableSelectedFilter(null);
  };
  const handleReset = () => {
    setShowAlert(false);
    resetFilters();
    setSelectedFilter(null);
    setFilterList({});
    setFilterColumns([]);
    userStore.resetCurrentFilter();
    userStore.updateCurrentLoadedFilter({});
    setFlag(true);
    setRefresh(!refresh);
  };
  const handleCancel = () => {
    resetFilters();
    setFilterList({});
    setFilterColumns([]);
    userStore.resetCurrentFilter();
    userStore.updateCurrentLoadedFilter({});
    setFlag(true);
    setRefresh(!refresh);
    navigate("/");
  };
  const handleDelete = (item) => {
    let newList = Object.keys(renderList)
      .filter((key) => !key.includes(item))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: renderList[key],
        });
      }, {});
    let newFilterList = Object.keys(filterList)
      .filter((key) => !key.includes(item))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: filterList[key],
        });
      }, {});

    let custom_param_data = payload.hasOwnProperty("custom_params")
      ? payload["custom_params"]
      : {};
    let general_data = Object.fromEntries(
      Object.entries(payload).filter(([key, value]) => key !== "custom_params")
    );
    let index = columnsList.findIndex((column) => column["field"] === item);
    if (index !== -1) {
      if (columnsList[index]["is_custom_param"]) {
        let target_key = item.replace("custom_params_", "");
        custom_param_data = Object.keys(custom_param_data)
          .filter((key) => !key.includes(target_key))
          .reduce((obj, key) => {
            return Object.assign(obj, {
              [key]: custom_param_data[key],
            });
          }, {});
      } else {
        general_data = Object.keys(general_data)
          .filter((key) => !key.includes(item))
          .reduce((obj, key) => {
            return Object.assign(obj, {
              [key]: general_data[key],
            });
          }, {});
      }
    }
    let combined_data = { ...general_data };
    if (Object.keys(custom_param_data).length > 0) {
      combined_data["custom_params"] = custom_param_data;
    }
    userStore.updateCurrentFilterPayload(combined_data);
    // if (Object.keys(combined_data).length === 0) {
    //   handleReset();
    // } else {
    //   setFlag(true);
    // }

    userStore.updateCurrentFilterRenderList(newList);
    setFilterList(newFilterList);
    if (inUseFiltersList.includes(item)) {
      let newList = inUseFiltersList.filter((e) => e !== item);
      userStore.updateInUseFilterList(newList);
    }
  };

  const generateFiltersPayload = (e) => {
    e.preventDefault();
    let allFiltersList = {};
    if (Object.keys(renderList).length !== 0) {
      Object.keys(renderList).map((key) => {
        if (renderList[key]["type"] === "normal") {
          if (
            renderList[key]["dataType"] === "date" ||
            renderList[key]["dataType"] === "datetime"
          ) {
            let newDate = getIsoString(renderList[key]["value"]);
            allFiltersList[key] = newDate;
          } else {
            allFiltersList[key] = renderList[key]["value"];
          }
        } else if (renderList[key]["type"] === "range") {
          if (
            renderList[key]["dataType"] === "date" ||
            renderList[key]["dataType"] === "datetime"
          ) {
            let d1 = getIsoString(renderList[key]["value"]["start"]);
            let d2 = getIsoString(renderList[key]["value"]["end"]);
            allFiltersList[key] = {
              start: d1,
              end: d2,
            };
          } else if (renderList[key]["dataType"] === "integer") {
            let num1 = renderList[key]["start"];
            let num2 = renderList[key]["end"];
            allFiltersList[key] = {
              start: num1,
              end: num2,
            };
          }
        } else if (renderList[key]["type"] === "list") {
          allFiltersList[key] = renderList[key]["value"];
        }
      });
    }
    if (Object.keys(allFiltersList).length > 0) {
      let custom_params = {};
      Object.keys(allFiltersList).map((key) => {
        let index = columnsList.findIndex((column) => column["field"] === key);
        if (index !== -1) {
          if (columnsList[index]["is_custom_param"]) {
            let keyName = key.replace("custom_params_", "");
            let newValue = allFiltersList[key];
            delete allFiltersList[key];
            custom_params[keyName] = newValue;
          }
        }
      });
      allFiltersList["custom_params"] = custom_params;
    }
    userStore.updateCurrentFilterPayload({ ...allFiltersList });
    if (Object.keys(renderList).length > 0) {
      setFlag(true);
    } else {
      setAlertMessage(
        "Kindly apply at least one filter before proceeding with your search."
      );
      setShowAlert(true);
    }
  };

  const handleSave = () => {
    if (userStore.currentLoadedFilter.hasOwnProperty("name")) {
      setOpenSaveConfirmationDialog(true);
    } else {
      setOpenSaveFilterDialog(true);
    }
  };

  const updateFilterSelectionList = (viewId) => {
    if (viewId !== "all") {
      updateTabSearchColumns(viewId);
    } else {
      getAllFilterColumns();
    }
  };

  const isLoadFilterButtonDisabled = () => {
    if (Object.keys(userStore.savedFilterTemplateList).length > 0) {
      if (
        userStore.savedFilterTemplateList.hasOwnProperty(authStore.projectId)
      ) {
        return (
          Object.keys(userStore.savedFilterTemplateList[authStore.projectId])
            .length === 0
        );
      }
    }
    return true;
  };

  //***********below are the helper functions to edit filter chip */

  const handleListFilter = (list) => {
    let newList = [];
    list.map((e) => {
      let newObj = { label: e, value: e };
      newList.push(newObj);
    });
    setSelected(newList);
  };

  const handleRangeFilter = (data, dataType, field) => {
    let newObj = { start: data["start"], end: data["end"] };
    if (dataType === "date" || dataType === "datetime") {
      setDateRange({ [field]: newObj });
    } else if (dataType === "integer") {
      setNumRange({ [field]: newObj });
    }
  };
  const handleFilterChipEdit = (filterData, field) => {
    let index = filterColumns.findIndex((column) => column["field"] === field);
    let filteredList = inUseFiltersList.filter((item) => item !== field);
    userStore.updateInUseFilterList([...filteredList]);
    setSelectedFilterID(filterColumns[index]["id"]);
    setSelectedFilter(filterColumns[index]);
    setFilterList({ [field]: filterData });
    setClearableSelectedFilter({
      label: filterColumns[index]["headerName"],
      value: filterColumns[index]["id"],
    });
    if (filterData["type"] === "list") {
      handleListFilter(filterData["value"]);
    } else if (filterData["type"] === "range") {
      handleRangeFilter(filterData["value"], filterData["dataType"], field);
    } else {
      setFilters({ [field]: filterData });
    }
  };

  //**********************END*************************** */

  const getFilterListToRender = () => {
    let newList = [];
    filterColumns.forEach((column) => {
      if (!inUseFiltersList.includes(column["field"])) {
        let tempObj = { label: column["headerName"], value: column["id"] };
        newList.push(tempObj);
      }
    });
    return newList;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CssBaseline />
        <Drawer
          sx={{
            width: userStore.isFilterDrawerOpen ? drawerWidth : 70,
            flexShrink: 0,
            zIndex: 10,
            "& .MuiDrawer-paper": {
              width: userStore.isFilterDrawerOpen ? drawerWidth : 70,
              boxSizing: "border-box",
              overflow: userStore.isFilterDrawerOpen ? "visible" : "hidden",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <VerticalSpaceBetween
            onSubmit={(e) => {
              e.preventDefault();
              if (
                isAddFilterButtonEnabled() &&
                Object.keys(renderList).length > 0
              ) {
                setAlertMessage(
                  "Kindly apply the selected filter before proceeding with your search."
                );
                setShowAlert(true);
              } else {
                generateFiltersPayload(e);
              }
            }}
          >
            <List>
              <ListItem
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: userStore.isFilterDrawerOpen ? "flex" : "none",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <ListItemText
                    style={{
                      visibility: userStore.isFilterDrawerOpen
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    <Header>Filters</Header>
                  </ListItemText>
                  <FilterTitle>
                    {userStore.currentLoadedFilter.hasOwnProperty("name")
                      ? userStore.currentLoadedFilter["name"]
                      : "-"}
                  </FilterTitle>
                </div>
                <Tooltip title="Load">
                  <IconButton
                    style={{
                      width: "40px",
                      height: "40px",
                      display: userStore.isFilterDrawerOpen ? "unset" : "none",
                    }}
                    onClick={() => {
                      setOpenLoadFilterDialog(true);
                    }}
                    color="primary"
                    disabled={isLoadFilterButtonDisabled()}
                  >
                    <SimCardDownload />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Save">
                  <IconButton
                    style={{
                      width: "40px",
                      height: "40px",
                      display: userStore.isFilterDrawerOpen ? "unset" : "none",
                    }}
                    onClick={() => {
                      handleSave();
                    }}
                    disabled={Object.keys(renderList).length === 0}
                    color="primary"
                  >
                    <Save />
                  </IconButton>
                </Tooltip>

                {userStore.isFilterDrawerOpen ? (
                  <IconButton
                    onClick={() => {
                      userStore.updateFilterDrawerState(false);
                    }}
                  >
                    {" "}
                    <ChevronLeft />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      userStore.updateFilterDrawerState(true);
                    }}
                  >
                    {" "}
                    <Menu />
                  </IconButton>
                )}
              </ListItem>
              <ListItem
                style={{
                  visibility: userStore.isFilterDrawerOpen
                    ? "visible"
                    : "hidden",
                }}
              >
                <InputWrapper>
                  <Label>Select View</Label>
                  <FormControl
                    classes={{
                      root: classes.quantityRoot,
                    }}
                  >
                    <MuiSelect
                      classes={{
                        icon: classes.icon,
                      }}
                      name="segment"
                      displayEmpty
                      disableUnderline
                      variant="outlined"
                      value={viewId}
                      onChange={(e) => {
                        setShowAlert(false);
                        handleReset();
                        setFlag(false);
                        setIsViewReset(true);
                        setSelectedFilter(null);
                        userStore.updateCurrentFilterViewId(e.target.value);
                        updateFilterSelectionList(e.target.value);
                        // setTabId(e.target.value["id"]);
                      }}
                      required
                      style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderRadius: "0px",
                      }}
                    >
                      {tabsList.length > 0 &&
                        tabsList.map((item, i) => {
                          return (
                            <MenuItem
                              key={i}
                              style={{ fontSize: "12px" }}
                              value={item.id}
                              id={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </MuiSelect>
                  </FormControl>
                </InputWrapper>
              </ListItem>
              <ListItem
                style={{
                  visibility: userStore.isFilterDrawerOpen
                    ? "visible"
                    : "hidden",
                }}
              >
                <InputWrapper>
                  <Label>Select Filter</Label>
                  <Select
                    options={getFilterListToRender()}
                    value={clearableSelectedFilter}
                    onChange={(selectedOption) => {
                      setClearableSelectedFilter(selectedOption);
                      if (selectedOption !== null) {
                        setSelectedFilterID(selectedOption.value);
                        resetFilters();
                        setIsViewReset(false);
                        setShowAlert(false);
                        setSelectedFilter(null);
                        let index = filterColumns.findIndex(
                          (column) => column["id"] === selectedOption.value
                        );
                        setSelectedFilter(filterColumns[index]);
                      } else {
                        setSelectedFilterID("");
                        resetFilters();
                        setIsViewReset(false);
                        setShowAlert(false);
                        setSelectedFilter(null);
                      }
                    }}
                    closeMenuOnSelect={true}
                    styles={customStyles}
                    isClearable
                    required
                  />
                </InputWrapper>
              </ListItem>
              <ListItem
                style={{
                  visibility: userStore.isFilterDrawerOpen
                    ? "visible"
                    : "hidden",
                }}
              >
                <InputWrapper>
                  <Label>Filter Value</Label>
                  {selectedFilter === null ? (
                    <OutlinedInput
                      disabled
                      placeholder="Enter Value"
                      style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                      }}
                    />
                  ) : (
                    <FilterValueComponent
                      item={selectedFilter}
                      selectedVal={selectedVal}
                      setSelectedVal={setSelectedVal}
                      filters={filters}
                      setFilters={setFilters}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      numRange={numRange}
                      setNumRange={setNumRange}
                      filterList={filterList}
                      setFilterList={setFilterList}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                </InputWrapper>
              </ListItem>
              <ListItem
                style={{
                  visibility: userStore.isFilterDrawerOpen
                    ? "visible"
                    : "hidden",
                }}
              >
                <ButtonWrapper
                  style={{ justifyContent: "flex-end", marginBottom: "20px" }}
                >
                  <Button
                    disabled={!isAddFilterButtonEnabled()}
                    onClick={handleAdd}
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "capitalize", width: "100%" }}
                  >
                    <Add /> Add Filter
                  </Button>
                </ButtonWrapper>
              </ListItem>
              <Divider style={{ marginBottom: "20px" }} />
              <ListItem
                style={{
                  visibility: userStore.isFilterDrawerOpen
                    ? "visible"
                    : "hidden",
                }}
              >
                {Object.keys(renderList).length > 0 && (
                  <FilterListContainer>
                    {Object.keys(renderList).map((item, i) => {
                      let headerName = getHeaderName(item);
                      if (renderList[item]["type"] === "list") {
                        let value = renderList[item]["value"];
                        return (
                          <CustomChip
                            key={i}
                            style={{ flexDirection: "column" }}
                            onClick={() => {
                              handleFilterChipEdit(renderList[item], item);
                            }}
                          >
                            <Cancel
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(item);
                              }}
                              style={{
                                position: "absolute",
                                top: "2px",
                                right: "2px",
                                cursor: "pointer",
                              }}
                            />
                            <ChipLable>{headerName}</ChipLable>
                            <Column>
                              {value.length > 0 &&
                                value.map((list_item, i) => {
                                  return (
                                    <Row key={i}>
                                      <Circle
                                        style={{
                                          width: "8px",
                                          height: "8px",
                                          color: "gray",
                                          marginTop: "2px",
                                        }}
                                      />
                                      <ChipValue>{list_item}</ChipValue>
                                    </Row>
                                  );
                                })}
                            </Column>
                          </CustomChip>
                        );
                      } else if (renderList[item]["type"] === "range") {
                        return (
                          <>
                            <CustomChip
                              onClick={() => {
                                handleFilterChipEdit(renderList[item], item);
                              }}
                            >
                              <Cancel
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDelete(item);
                                }}
                                style={{
                                  position: "absolute",
                                  top: "2px",
                                  right: "2px",
                                  cursor: "pointer",
                                }}
                              />
                              <ChipLable>{headerName}</ChipLable>
                              <ChipValue>{`${renderList[item]["value"]["start"]} to ${renderList[item]["value"]["end"]}`}</ChipValue>
                            </CustomChip>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <CustomChip
                              onClick={() => {
                                handleFilterChipEdit(renderList[item], item);
                              }}
                            >
                              <Cancel
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDelete(item);
                                }}
                                style={{
                                  position: "absolute",
                                  top: "2px",
                                  right: "2px",
                                  cursor: "pointer",
                                }}
                              />
                              <ChipLable>{headerName}</ChipLable>
                              <ChipValue>{renderList[item]["value"]}</ChipValue>
                            </CustomChip>
                          </>
                        );
                      }
                    })}
                  </FilterListContainer>
                )}
              </ListItem>
            </List>
            <List
              style={{
                visibility: userStore.isFilterDrawerOpen ? "visible" : "hidden",
              }}
            >
              <Divider style={{ marginBottom: "20px" }} />
              {showAlert && (
                <ListItem>
                  <Alert
                    style={{
                      width: "100%",
                      padding: "0px 20px",
                      fontSize: "12px",
                    }}
                    severity="warning"
                  >
                    {alertMessage}
                  </Alert>
                </ListItem>
              )}
              <ListItem>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      handleCancel();
                    }}
                    variant="contained"
                    color="inherit"
                    style={{ textTransform: "capitalize", width: "100%" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      handleReset();
                    }}
                    variant="contained"
                    color="error"
                    style={{ textTransform: "capitalize", width: "100%" }}
                  >
                    Reset
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "capitalize", width: "100%" }}
                  >
                    Search
                  </Button>
                </ButtonWrapper>
              </ListItem>
            </List>
          </VerticalSpaceBetween>
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: "80%", height: "100%" }}
        >
          <FilterTable
            tabId={viewId}
            flag={flag}
            setFlag={setFlag}
            filterPayload={payload}
            page={page_number}
            size={page_size}
            refresh={refresh}
            isViewReset={isViewReset}
            tabsList={tabsList}
          />
        </Box>
      </Box>

      {openLoadFilterDialog && (
        <LoadFilterModal
          open={openLoadFilterDialog}
          setOpen={setOpenLoadFilterDialog}
          updateFilterSelectionList={updateFilterSelectionList}
        />
      )}
      {openSaveFilterDialog && (
        <SaveFilterModal
          open={openSaveFilterDialog}
          setOpen={setOpenSaveFilterDialog}
          filters={renderList}
          viewId={viewId}
        />
      )}
      {openSaveConfirmationDialog && (
        <SaveFilterConfirmationDialog
          open={openSaveConfirmationDialog}
          setOpen={setOpenSaveConfirmationDialog}
          handleUpdate={() => {
            userStore.updateSavedFilterTemplateList({
              fitlerName: userStore.currentLoadedFilter["name"],
              viewId: viewId,
              filters: renderList,
            });
            userStore.updateCurrentLoadedFilter({
              name: userStore.currentLoadedFilter["name"],
              viewId: viewId,
              filters: renderList,
            });
            setOpenSaveConfirmationDialog(false);
          }}
          handleNew={() => {
            setOpenSaveFilterDialog(true);
            setOpenSaveConfirmationDialog(false);
          }}
        />
      )}
    </>
  );
};

export default observer(FilterPage);
