import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import rootStore from "../../stores/RootStore";
import LeadAssignModal from "../lead_assign_modal/LeadAssignModal";
import { observer } from "mobx-react-lite";
import ColumnSelectionModal from "../column_selection_modal/ColumnSelectionModal";
import {
  deleteMultipleLeadsApi,
  getColumnsForTabApi,
  getLeadsApi,
  markUnmarkMultipleLeadAsFreshLeadApi,
} from "../../Api";
import { toJS } from "mobx";
import { Box } from "@material-ui/core";
import PaginationComponent from "../pagination/PaginationComponent";
import { FormattedColumns, leadListingIconsColumn } from "../../ColumnUtils";
import { Backdrop, CircularProgress } from "@mui/material";
import SuccessMessageDialog from "../alert_dialogue/SuccessMessageDialog";
import ErrorMessageDialog from "../alert_dialogue/ErrorMessageDialog";
import analytics from "../../Analytics";
import { isLastContactedToday } from "../../Functions";
import CustomConfirmationDialog from "../alert_dialogue/CustomConfirmationDialog";
import MultiLeadUpdateStageModal from "../update_stage_modal/MultiLeadUpdateStageModal";
import CustomMessageDialog from "../alert_dialogue/CustomMessageDialog";
import DeleteLeadConfirmationDialog from "../alert_dialogue/DeleteLeadConfirmationDialog";

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${`min-height: calc(100vh - 282px);`};
  align-items: center;
`;
const Container = styled.div`
  height: fit-content;
  ${`min-height: calc(100vh - 234px);`};
  background-color: white;
  /* padding: 12px; */
  width: 100%;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 400px;
  flex-direction: column;
  margin: auto;
`;
const PaginationWrapper = styled.div`
  padding: 0px 20px;
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const LeadsTab = (props) => {
  const { authStore, userStore } = rootStore;
  let navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [refreshColumn, setRefreshColumn] = useState(false);
  const [columns, setColumns] = useState([]);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [refreshLeads, setRefreshLeads] = useState(false);
  const columnsList = [...userStore.AllColumnsList];
  const stagesList = [...rootStore.authStore.stageList];
  const statusList = [...rootStore.authStore.statusList];
  const [showDeleteProtectedAlert, setShowDeleteProtectedAlert] =
    useState(false);

  let uid = localStorage.getItem("uid");
  let pid = authStore.projectId;
  let tid = authStore.teamId;

  const sorting = toJS(rootStore.leadStore.tabColumnSortingList).hasOwnProperty(
    rootStore.leadStore.tabID
  )
    ? toJS(rootStore.leadStore.tabColumnSortingList)[rootStore.leadStore.tabID]
    : null;
  const handlePageSize = async (e) => {
    rootStore.leadStore.updateCurrentTabPageSize({
      tabID: rootStore.leadStore.tabID,
      size: e.target.value,
    });
    rootStore.leadStore.updateCurrentTabPage({
      tabID: rootStore.leadStore.tabID,
      page: 0,
    });
    await getLeadsList();
  };

  const getColumnsForTab = async () => {
    let storedTabColumns =
      rootStore.leadStore.tabInfo[rootStore.leadStore.tabID]["columns"];
    if (storedTabColumns.length > 0) {
      let updatedList = FormattedColumns(storedTabColumns);
      setColumns(updatedList);
    } else {
      try {
        let response = await getColumnsForTabApi(rootStore.leadStore.tabID);

        let newColumnsList = [];
        response.data.forEach((columnField) => {
          let index = columnsList.findIndex(
            (column) => column["field"] === columnField
          );
          if (index !== -1) {
            newColumnsList.push(columnsList[index]);
          }
        });
        rootStore.leadStore.updateCurrentTabColumns({
          tabID: rootStore.leadStore.tabID,
          columnsList: newColumnsList,
        });

        let modifiedList = FormattedColumns([
          ...rootStore.leadStore.tabInfo[rootStore.leadStore.tabID]["columns"],
        ]);

        setColumns(modifiedList);
      } catch (error) {
        if (error.response.request.status === 404) {
          rootStore.leadStore.updateTabColumnsWithDefaultColumns();

          let modifiedList = FormattedColumns([
            ...rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
              "columns"
            ],
          ]);
          setColumns(modifiedList);
        }
      }
    }
  };

  const getLeadsList = async () => {
    props.setAssignState(0);
    setLeads([]);
    setRow([]);
    setLoading(true);
    let payload = {
      tab_id: rootStore.leadStore.tabID,
      query: null,
      sort_on: sorting,
    };
    let response = await getLeadsApi({
      page_size:
        rootStore.leadStore.tabInfo[rootStore.leadStore.tabID]["page_size"],
      page: rootStore.leadStore.tabInfo[rootStore.leadStore.tabID]["page"],
      payload: payload,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);

      rootStore.leadStore.updateCurrentTabItemCount({
        tabID: rootStore.leadStore.tabID,
        count: response.item_count,
      });
    } else {
      setRow([]);
      rootStore.leadStore.updateCurrentTabItemCount({
        tabID: rootStore.leadStore.tabID,
        count: 0,
      });
    }
    setLoading(false);
  };

  const init = async () => {
    await getColumnsForTab();
    if (sorting !== null) {
      delete sorting["flag"];
    }

    await getLeadsList();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    init();
  }, [
    tid,
    refresh,
    rootStore.leadStore.tabID,
    props.refresh,
    rootStore.leadStore.tabColumnSortingList[rootStore.leadStore.tabID],
  ]);

  const refetchTabColumns = async () => {
    setLoading(true);
    try {
      let response = await getColumnsForTabApi(rootStore.leadStore.tabID);

      let newColumnsList = [];
      response.data.forEach((columnField) => {
        let index = columnsList.findIndex(
          (column) => column["field"] === columnField
        );
        if (index !== -1) {
          newColumnsList.push(columnsList[index]);
        }
      });
      rootStore.leadStore.updateCurrentTabColumns({
        tabID: rootStore.leadStore.tabID,
        columnsList: newColumnsList,
      });

      let modifiedList = FormattedColumns([
        ...rootStore.leadStore.tabInfo[rootStore.leadStore.tabID]["columns"],
      ]);

      setColumns(modifiedList);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (refreshColumn) {
      refetchTabColumns();
      setRefreshColumn(false);
    }
  }, [refreshColumn]);

  const handlePage = async (e, page) => {
    rootStore.leadStore.updateCurrentTabPage({
      tabID: rootStore.leadStore.tabID,
      page: page - 1,
    });
    await getLeadsList();
  };

  const handleMarkLeadAsFreshLead = async () => {
    props.setOpenConfirmMarkLeadDialog(false);
    setShowBackDrop(true);
    let payload = { lead_ids: leads[0], status: true };
    let response = await markUnmarkMultipleLeadAsFreshLeadApi({
      payload: payload,
    });
    if (response.hasError()) {
      setShowBackDrop(false);
      setOpenErrorDialog(true);
    } else {
      setShowBackDrop(false);
      setSuccessMessage("Leads successfully marked as fresh!");
      setOpenSuccessDialog(true);
      await getLeadsList();
    }
  };

  const refetchLeads = async () => {
    await getLeadsList();
    setRefreshLeads(false);
  };
  useEffect(() => {
    if (refreshLeads) {
      refetchLeads();
    }
  }, [refreshLeads]);

  //*******************delete leads related functions************* */

  const isStageDeleteProtected = ({ leadData }) => {
    let index = stagesList.findIndex(
      (stage) => stage["stage"] === leadData["lead_stage"]
    );
    if (index !== -1) {
      return stagesList[index]["delete_protected"];
    }
    return false;
  };
  const isStatusDeleteProtected = ({ leadData }) => {
    let index = statusList.findIndex(
      (status) => status["status"] === leadData["lead_status"]
    );
    if (index !== -1) {
      return statusList[index]["delete_protected"];
    }
    return false;
  };

  const isDeleteProtected = ({ leadData }) => {
    return (
      isStageDeleteProtected({ leadData: leadData }) ||
      isStatusDeleteProtected({ leadData: leadData })
    );
  };

  const isAnyLeadDeleteProtected = () => {
    for (let i = 0; i < leads[0].length; i++) {
      let index = row.findIndex((lead) => lead["id"] === leads[0][i]);
      let leadData = row[index];
      if (isDeleteProtected({ leadData: leadData })) {
        return true;
      }
    }
    return false;
  };

  const handleDeleteLead = async () => {
    props.setOpenDeleteLead(false);
    setShowBackDrop(true);

    if (isAnyLeadDeleteProtected()) {
      setShowBackDrop(false);
      setShowDeleteProtectedAlert(true);
    } else {
      let response = await deleteMultipleLeadsApi({ idList: leads[0] });
      if (response.hasError()) {
        setShowBackDrop(false);
        setOpenErrorDialog(true);
      } else {
        setShowBackDrop(false);
        setSuccessMessage("Leads deleted successfully!");
        setOpenSuccessDialog(true);
        await getLeadsList();
      }
    }
  };

  //****************************END********************************** */

  return (
    <>
      <Container>
        <Wrapper>
          {!loading ? (
            <>
              <TableWrapper>
                <Box
                  sx={{
                    "& .last-contacted-today": {
                      backgroundColor: "#E6EDF7",
                    },
                    "& .not-last-contacted-today": {
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                >
                  <DataGrid
                    autoHeight={true}
                    rows={row}
                    getRowClassName={(params) =>
                      isLastContactedToday(params.row["last_contacted_on"])
                        ? "last-contacted-today"
                        : "not-last-contacted-today"
                    }
                    columns={leadListingIconsColumn.concat(columns)}
                    pageSize={
                      rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
                        "page_size"
                      ]
                    }
                    checkboxSelection
                    style={{ cursor: "pointer" }}
                    hideFooter={true}
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Array(ids);
                      props.setAssignState(selectedIDs[0].length);
                      setLeads(selectedIDs);
                    }}
                    loading={loading}
                    disableColumnMenu={true}
                    onRowClick={(e) => {
                      analytics.triggerEvent(
                        4625000035,
                        "lead_row_tap",
                        "leads_page",
                        "",
                        {}
                      );
                      authStore.updateLastActiveTime();
                      navigate(`/users/${uid}/leads/${e.id}`);
                    }}
                  />
                </Box>
              </TableWrapper>
              <PaginationWrapper>
                <PaginationComponent
                  page_no={
                    rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
                      "page"
                    ]
                  }
                  row={row}
                  size={
                    rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
                      "page_size"
                    ]
                  }
                  page_size={
                    rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
                      "page_size"
                    ]
                  }
                  count={
                    rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
                      "count"
                    ]
                  }
                  handlePageSize={handlePageSize}
                  handlePage={handlePage}
                />
              </PaginationWrapper>
            </>
          ) : (
            <SpinnerWrapper>
              <CircularProgress />
            </SpinnerWrapper>
          )}

          {props.open && (
            <LeadAssignModal
              open={props.open}
              setOpen={props.setOpen}
              leads={leads}
              pid={pid}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          )}
          {props.openSelectCol && (
            <ColumnSelectionModal
              open={props.openSelectCol}
              setOpen={props.setOpenSelectCol}
              setRefresh={setRefreshColumn}
            />
          )}
        </Wrapper>
      </Container>

      {props.openUpdateStageModal && (
        <MultiLeadUpdateStageModal
          open={props.openUpdateStageModal}
          setOpen={props.setOpenUpdateStageModal}
          selectedLeadIdsData={leads}
          setRefresh={setRefreshLeads}
          scope={"leads_page"}
        />
      )}
      {props.openDeleteLead && (
        <DeleteLeadConfirmationDialog
          open={props.openDeleteLead}
          setOpen={props.setOpenDeleteLead}
          handleConfirm={() => {
            handleDeleteLead();
          }}
        />
      )}
      {showDeleteProtectedAlert && (
        <CustomMessageDialog
          open={showDeleteProtectedAlert}
          setOpen={setShowDeleteProtectedAlert}
          message={
            "Leads are delete-protected. Unprotect or remove them to proceed with deletion."
          }
        />
      )}
      {openSuccessDialog && (
        <SuccessMessageDialog
          open={openSuccessDialog}
          setOpen={setOpenSuccessDialog}
          message={successMessage}
        />
      )}
      {openErrorDialog && (
        <ErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
        />
      )}

      {props.openConfirmMarkLeadDialog && (
        <CustomConfirmationDialog
          message={"Are you sure you want to mark the leads as fresh?"}
          open={props.openConfirmMarkLeadDialog}
          setOpen={props.setOpenConfirmMarkLeadDialog}
          handleConfirm={() => {
            handleMarkLeadAsFreshLead();
          }}
        />
      )}
      <Backdrop
        open={showBackDrop}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
    </>
  );
};

export default observer(LeadsTab);
