import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Modal,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { getMembersApi, leadAssignApi } from "../../Api";
import { MenuItem } from "@mui/material";
import { myTheme } from "../../themeUtils";
import { getLocalizedText } from "../../Functions";
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
`;
const ModelWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const SubHeader = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #5a5a5a;
  margin-bottom: 30px;
`;
// const Button = styled.button`
//   width: 218px;
//   height: 50px;
//   border: none;
//   border-radius: 90px;
//   padding: 12px;
//   background-color: #185dd2;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: #ffffff;
//   cursor: pointer;
//   text-transform: uppercase;
//   font: normal normal 600 16px/25px Open Sans;
//   margin: auto;
//   margin-top: 30px;
// `;
const FlagText = styled.p`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;
const LeadAssignModal = (props) => {
  const [member, setMember] = useState();
  const [memberName, setMemberName] = useState("");
  const [isAssign, setIsAssign] = useState(false);
  const { authStore, userStore } = rootStore;
  const [flag, setFlag] = useState(false);
  let pid = authStore.projectId;
  const handleChange = (event) => {
    setMemberName(event.target.value);
  };
  const [status, setStatus] = useState(false);

  let uid = localStorage.getItem("uid");
  const handleAssign = async () => {
    document.getElementById("assign-btn").innerText = "Loading...";
    try {
      let response = await leadAssignApi({
        ids: props.leads[0],
        member: memberName,
        erase_history: flag,
      });
      document.getElementById("assign-btn").style.backgroundColor = "green";
      document.getElementById("assign-btn").innerText = "Assigned";
      setIsAssign(true);
      setStatus(false);
      setTimeout(() => {
        props.setRefresh(!props.refresh);
        props.setOpen(false);
      }, [800]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getTeamMembers = async () => {
      try {
        let response = await getMembersApi();
        setMember(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getTeamMembers();
  }, []);
  const handleClose = () => {
    props.setOpen(false);
  };
  const handleFlag = (e) => {
    setFlag(e.target.checked);
  };

  const canHaveDetailedMemberList = () => {
    let role = authStore.getCurrentProjectRole();
    if (userStore.UserData !== null && role !== "") {
      if (
        userStore.UserData.role === "admin" ||
        userStore.UserData.role === "owner" ||
        role === "manager"
      ) {
        return true;
      }
    }
    return false;
  };
  return (
    <>
      {member && (
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModelContainer>
            <Box>
              <ModelWrapper>
                <Header>{getLocalizedText("assign_to")}</Header>
                <SubHeader>
                  {getLocalizedText("select_from_below_dropdown")}
                </SubHeader>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{
                    font: "normal normal 600 10px Open Sans",
                    color: "#4d4e4f",
                  }}
                >
                  {getLocalizedText("select_member")}
                </InputLabel>
                {canHaveDetailedMemberList() ? (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={memberName}
                    label="Select Member"
                    onChange={handleChange}
                    style={{
                      minWidth: "200px",
                      marginBottom: "20px",
                      font: "normal normal 500 12px Open Sans",
                    }}
                  >
                    {member.map((e) => {
                      return (
                        <MenuItem
                          style={{ fontSize: "12px" }}
                          id={e.id}
                          value={e.id}
                        >
                          {e.name}
                          <span
                            style={{ fontWeight: "bolder", margin: "0px 4px" }}
                          >
                            ||
                          </span>
                          {e.role}
                          <span
                            style={{ fontWeight: "bolder", margin: "0px 4px" }}
                          >
                            ||
                          </span>
                          {e.team.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={memberName}
                    label="Select Member"
                    onChange={handleChange}
                    style={{
                      minWidth: "200px",
                      marginBottom: "20px",
                      font: "normal normal 500 12px Open Sans",
                    }}
                  >
                    {member.map((e) => {
                      return (
                        <MenuItem
                          id={e.id}
                          value={e.id}
                          style={{ fontSize: "12px" }}
                        >
                          {e.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label={
                    <FlagText>{getLocalizedText("erase_history")}</FlagText>
                  }
                  onChange={handleFlag}
                />
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      props.setOpen(false);
                    }}
                    variant="contained"
                    color="default"
                    style={{
                      font: "normal normal 600 10px Open Sans",
                      marginRight: "10px",
                      textTransform: "none",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    id="assign-btn"
                    onClick={handleAssign}
                    variant="contained"
                    style={{
                      font: "normal normal 600 10px Open Sans",
                      width: "100%",
                      textTransform: "none",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {getLocalizedText("assign")}
                  </Button>
                </ButtonWrapper>
              </ModelWrapper>
            </Box>
          </ModelContainer>
        </Modal>
      )}
    </>
  );
};

export default LeadAssignModal;
