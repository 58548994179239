import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Box, Tooltip } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { ContactLeadsPageColumnHeaders } from "../../Db";
import { CircularProgress } from "@mui/material";
import { getContactLeadsListApi } from "../../Api";
import PaginationComponent from "../pagination/PaginationComponent";
import { pageSize } from "../../Config";
import rootStore from "../../stores/RootStore";
import { useNavigate } from "react-router-dom";
import SwitchProjectToRedirectDialog from "../alert_dialogue/SwitchProjectToRedirectDialog";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 246px);`};
`;
const Wrapper = styled(Box)`
  display: flex;
`;

const DetailsWrapper = styled(Box)`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;

const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;

const LeadsTab = (props) => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [selectedLeadData, setSelectedLeadData] = useState({});
  const [switchingProject, setSwitchingProject] = useState(false);

  let userID = localStorage.getItem("uid");

  const navigate = useNavigate();
  const getContactLeadList = async ({ page, page_size }) => {
    let response = await getContactLeadsListApi({
      contactID: props.id,
      page: page,
      page_size: page_size,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
  };

  const init = async () => {
    setLoading(true);
    await getContactLeadList({ page: 0, page_size: pageSize });
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handlePageSize = async (e) => {
    setLoading(true);
    setPage(0);
    setSize(e.target.value);
    await getContactLeadList({ page: 0, page_size: e.target.value });
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setLoading(true);
    setPage(page - 1);
    setLoading(true);
    await getContactLeadList({ page: page - 1, page_size: size });
    setLoading(false);
  };
  const handleRowTap = (leadData) => {
    let currentProjectID = rootStore.authStore.projectId;
    if (leadData["project_id"] === currentProjectID) {
      navigate(`/users/${userID}/leads/${leadData["id"]}`);
    } else {
      setSelectedLeadData(leadData);
      setOpenAlertDialog(true);
    }
  };
  const handleSwitchToNewProject = async () => {
    setSwitchingProject(true);
    await rootStore.authStore.updateOnProjectChange(
      selectedLeadData["project_id"]
    );
    setSwitchingProject(false);
    navigate(`/users/${userID}/leads/${selectedLeadData["id"]}`);
    setOpenAlertDialog(false);
  };
  const getProjectNameByID = (id) => {
    let index = rootStore.authStore.projectList.findIndex(
      (project) => project["id"] === id
    );
    if (index !== -1) {
      return rootStore.authStore.projectList[index]["name"];
    }
    return "";
  };
  const additionalColumns = [
    {
      field: "project",
      headerName: "PROJECT NAME",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => {
        let value = getProjectNameByID(params.data.project_id);
        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">
                {value !== "" ? value : "-"}
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Container>
        <Wrapper>
          <DetailsWrapper>
            {!loading ? (
              <TableWrapper>
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={ContactLeadsPageColumnHeaders.concat(
                      additionalColumns
                    )}
                    animateRows={true}
                    suppressCellFocus
                    className="paginated-ag-grid"
                    onRowClicked={(row) => {
                      handleRowTap(row.data);
                    }}
                  />
                </div>
                <PaginationWrapper>
                  <PaginationComponent
                    page_no={page}
                    row={row}
                    page_size={size}
                    size={size}
                    count={count}
                    handlePageSize={handlePageSize}
                    handlePage={handlePagination}
                  />
                </PaginationWrapper>
              </TableWrapper>
            ) : (
              <TableWrapper
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </TableWrapper>
            )}
          </DetailsWrapper>
        </Wrapper>
      </Container>
      {openAlertDialog && (
        <SwitchProjectToRedirectDialog
          isLoading={switchingProject}
          open={openAlertDialog}
          setOpen={setOpenAlertDialog}
          handleCancel={() => {
            setSelectedLeadData({});
            setOpenAlertDialog(false);
          }}
          handleContinue={() => {
            handleSwitchToNewProject();
          }}
        />
      )}
    </>
  );
};

export default observer(LeadsTab);
