import { Drawer, IconButton } from "@material-ui/core";
import { AddBoxOutlined, Edit } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getAdminColumnsApi, getTeamApi } from "../../Api";
import spinner from "../../assets/images/gifs/spinner.gif";
import { AdminViewColumns, TeamSettingColumns } from "../../Db";
import AddColumnModal from "../admin_setting_modals/AddColumnModal";
import AddTeamModal from "../admin_setting_modals/AddTeamModal";
import { pageSize } from "../../Config";
import PaginationComponent from "../pagination/PaginationComponent";
import analytics from "../../Analytics";
import AddColumnSidepanel from "../admin_setting_modals/AddColumnSidepanel";
import ViewColumnSidepanel from "../view_column_sidepanel/ViewColumnSidepanel";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const Container = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 234px);`};

  position: relative;
  /* margin-top: 70px; */
`;
const Wrapper = styled.div`
  display: flex;
`;

const DetailsWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const AddBtnWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const ColumnsTab = (props) => {
  const [row, setRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [loading, setLoading] = useState(true);
  const [openInfoSidepanel, setOpenInfoSidepanel] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  let navigate = useNavigate();
  const getColumns = async ({ page_no, page_size }) => {
    setRow([]);
    setLoading(true);
    try {
      let response = await getAdminColumnsApi({
        pid: props.id,
        page: page_no,
        page_size: page_size,
      });

      setRow(response.data.items);
      setCount(response.data.item_count);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getColumns({ page_no: page, page_size: size });
  }, [refresh]);
  const actionColumn = [
    {
      field: "action",
      headerName: <Text>Actions</Text>,
      sortable: false,
      width: 70,
      renderCell: (params) => {
        return (
          <>
            <Edit
              onClick={(e) => {
                e.stopPropagation();
                analytics.triggerEvent(
                  4625000230,
                  "edit_button_tap",
                  "project_columns_page",
                  "edit_button",
                  {}
                );
                setEditableData(params.row);
                setIsEdit(true);
                setOpen(true);
              }}
              style={{ marginRight: "20px", width: "18px", height: "18px" }}
              color="primary"
            />
          </>
        );
      },
    },
  ];

  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setPage(0);
    getColumns({ page_no: 0, page_size: e.target.value });
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);
    getColumns({ page_no: page - 1, page_size: size });
  };
  return (
    <>
      <Container>
        <AddBtnWrapper>
          <AddBoxOutlined
            onClick={() => {
              analytics.triggerEvent(
                4625000226,
                "add_button_tap",
                "project_columns_page",
                "add_button",
                {}
              );
              setOpen(true);
            }}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </AddBtnWrapper>
        <Wrapper>
          <DetailsWrapper>
            <TableWrapper>
              <DataGrid
                autoHeight={true}
                rows={row}
                columns={AdminViewColumns.concat(actionColumn)}
                checkboxSelection={false}
                style={{ cursor: "pointer" }}
                hideFooter={true}
                disableColumnMenu={true}
                loading={loading}
                onRowClick={(e) => {
                  setSelectedRowData(e.row);
                  setOpenInfoSidepanel(true);
                }}
              />
            </TableWrapper>
            <PaginationComponent
              page_no={page}
              row={row}
              page_size={size}
              size={size}
              count={count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </DetailsWrapper>
        </Wrapper>
      </Container>

      {open && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={open}
          onClose={() => {
            setOpen(false);
            setIsEdit(false);
          }}
        >
          <AddColumnSidepanel
            id={props.id}
            setOpen={setOpen}
            open={open}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editableData={editableData}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </Drawer>
      )}
      {openInfoSidepanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openInfoSidepanel}
          onClose={() => {
            setOpenInfoSidepanel(false);
          }}
        >
          <ViewColumnSidepanel data={selectedRowData} />
        </Drawer>
      )}
    </>
  );
};

export default ColumnsTab;
