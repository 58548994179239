import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { FormControl, Select } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { Box, Typography } from "@mui/material";
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
`;
const SelectField = (props) => {
  const [list, setList] = useState([]);
  const getList = () => {
    if (props.list.length > 0) {
      let val = props.list.split(",");
      setList(val);
    }
  };
  useEffect(() => {
    getList();
  }, []);
  return (
    <>
      <InputWrapper>
        <Label>
          Select {props.label}
          {props.required && <span>*</span>}
        </Label>
        <FormControl>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name={props.field}
            label="Select Member"
            onChange={(e) => props.handleChange(e)}
            style={{
              width: "100%",
              fontSize: "12px",
            }}
            readOnly={props.readOnly}
            value={
              props.value.hasOwnProperty(props.field)
                ? props.value[props.field]["value"]
                : ""
            }
            required={props.required}
          >
            {list.map((e) => {
              return (
                <MenuItem id={e.id} value={e}>
                  {e}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </InputWrapper>
    </>
  );
};

export default SelectField;
