import * as React from "react";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import { useLocation, useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import appLogo from "../../assets/logo.png";
import { toJS } from "mobx";
import Menu from "@mui/material/Menu";
import { Link } from "react-router-dom";
import {
  AccountCircle,
  Assessment,
  Business,
  BusinessCenter,
  Call,
  Contacts,
  Dashboard,
  ExitToApp,
  Folder,
  Group,
  ListAlt,
  LocalOffer,
  Menu as MenuIcon,
  Notifications,
  Place,
  Search,
  Settings,
  TrackChanges,
  Tune,
} from "@material-ui/icons";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import { ChevronLeftOutlined } from "@material-ui/icons";
import { ChevronRightOutlined } from "@material-ui/icons";
import ListItem from "@mui/material/ListItem";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  AdminPanelSettings,
  Campaign,
  CellTower,
  ConnectWithoutContact,
  CorporateFare,
  Diversity3,
  DriveFolderUpload,
  DynamicForm,
  GroupWork,
  ManageAccountsOutlined,
  ProductionQuantityLimits,
  Segment,
  Upload,
} from "@mui/icons-material";
import UploadModal from "../upload_csv_modal/UploadModal";
import {
  getAdminProjectApi,
  getLeadProfileApi,
  getNotificationsApi,
  sendUserActiveStatus,
  userLogoutApi,
} from "../../Api";
import { Badge, Select, makeStyles } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import MainFilterComponent from "../filter_modal/MainFilterComponent";
import { useEffect } from "react";
import FilterComponent from "../filter_modal/FilterComponent";
import {
  checkFeaturePermission,
  consoleLogger,
  getLocalizedText,
  isUnderWorkSchedule,
  updateViewList,
  validateDisabledFeature,
  validateDisabledSubFeature,
} from "../../Functions";
import {
  AdminProjectMenuList,
  TeamLeadAccessNameList,
  domainName,
} from "../../Config";
import NotificationComponent from "../notification_component/NotificationComponent";
import AlertDialogue from "../alert_dialogue/AlertDialogue";
import WorkScheduleAlert from "../alert_dialogue/WorkScheduleAlert";
import CallInfoDialog from "../call/CallInfoDialog";
import { callInfoSheetScope } from "../../utils/enum";
import CallLogModal from "../call_log_modal/CallLogModal";
import moment from "moment";
import {
  Sidebar,
  Menu as Menuv2,
  MenuItem as MenuItemv2,
  SubMenu as SubMenuV2,
  sidebarClasses,
} from "react-pro-sidebar";
import Cookies from "js-cookie";

const drawerWidth = 275;

const Logo = styled("img")(({ theme }) => ({
  // maxWidth: "120px",
  maxHeight: "16px",
  // height: "auto",
  width: "auto",
  cursor: "pointer",
}));
const SearchBarWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "40px",
  width: "100%",
  padding: "0px 20px",
}));

const ProfileWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));
const SearchInput = styled("input")(({ theme }) => ({
  height: "40px",
  border: "none",
  outline: "none",
  width: "100%",
  color: "#4D4E4F",
  font: "normal normal 500 14px Poppins",
  padding: "2px",
  backgroundColor: "#EFEFF4",
  "&::placeholder": {
    fontSize: "14px",
    color: "#2B2B2B",
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 3,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
  }),
}));

const useStyles = makeStyles({
  blink: {
    animation: "$blink 1s linear infinite",
  },
  "@keyframes blink": {
    "0%": {
      opacity: 0,
      transform: "scale(1)",
    },
    "50%": {
      opacity: 1,
      transform: "scale(1.5)",
    },
    "100%": {
      opacity: 0,
      transform: "scale(1)",
    },
  },
});

const AppbarV2 = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { userStore, authStore, filterStore, phoneCallState } = rootStore;
  let pid = authStore.projectId;
  let tid = authStore.teamId;
  const [openCsv, setOpenCsv] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notify, setNotify] = useState(true);
  const [notifyCount, setNotifyCount] = useState(0);
  const openMenu = Boolean(anchorEl);
  const [notifyData, setNotifyData] = useState([]);
  const location = useLocation();
  const [moduleList, setModuleList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isInWorkHours, setIsInWorkHours] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [showAdminProject, setShowAdminProject] = useState(false);
  const [openCallInfo, setOpenCallInfo] = useState(false);
  const [openCallLogModal, setOpenCallLogModal] = useState(false);
  const [leadData, setLeadData] = useState({});

  const [callInfoScope, setCallInfoScope] = useState(
    callInfoSheetScope.home_call_icon
  );
  const uid = localStorage.getItem("uid");

  const view = userStore.view;
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  let navigate = useNavigate();
  const [query, setQuery] = useState("");
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleChange = async (e) => {
    if (phoneCallState.hasPendingCallLog()) {
      setOpenCallInfo(true);
      setCallInfoScope(callInfoSheetScope.home_project);
    } else {
      navigate("/");
      props.setIsFiltered(false);
      authStore.updateOnProjectChange(e.target.value);
    }
  };

  const clearLocalStorageWithoutDeviceID = () => {
    let device_id = localStorage.getItem("device_id");
    localStorage.clear();
    localStorage.setItem("device_id", device_id);
  };

  const handleLogout = async () => {
    try {
      let response = await userLogoutApi();
      Cookies.remove("token", { path: "/", domain: domainName });
      Cookies.remove("uid", { path: "/", domain: domainName });
      navigate("/login");
      clearLocalStorageWithoutDeviceID();
      props.setIsFiltered(false);
      authStore.resetUserAuth();
    } catch (error) {
      authStore.resetUserAuth();
      clearLocalStorageWithoutDeviceID();
      props.setIsFiltered(false);
      Cookies.remove("token", { path: "/", domain: domainName });
      Cookies.remove("uid", { path: "/", domain: domainName });
      navigate("/login");
      console.log(error);
    }
  };

  const handleTeam = (e) => {
    navigate("/");
    authStore.setTeamId(e.target.value);
  };

  const MINUTE_MS = 60000;

  const shouldAdminAndProjectVisible = async () => {
    try {
      let response = await getAdminProjectApi();
      if (response.data.length > 0) {
        setShowAdmin(true);
        setShowAdminProject(true);
      } else {
        if (userStore.UserData !== null) {
          if (
            userStore.UserData.role === "admin" ||
            userStore.UserData.role === "owner"
          ) {
            setShowAdmin(true);
          }
        }
      }
    } catch (error) {
      if (error.response.request.status !== 401) {
        setIsError(true);
      }
    }
  };

  const getNotications = async () => {
    try {
      let response = await getNotificationsApi();
      userStore.resetBlackList();
      setNotifyData(response.data.items);
      setNotifyCount(response.data.items_count);
      if (response.data.items_count !== 0) {
        setNotify(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleNotification = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const isQuarterHour = () => {
    const currentMinute = moment().minute();
    return currentMinute % 15 === 0;
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (isQuarterHour()) {
        getNotications();
        updateUserActiveStatus();
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  const getUserActiveStatus = () => {
    if (authStore.lastActiveTime !== "") {
      const currentTime = moment();
      const lastActiveMoment = moment(authStore.lastActiveTime);
      const diffInMinutes = currentTime.diff(lastActiveMoment, "minutes");
      if (diffInMinutes >= 15) {
        return "Inactive";
      } else {
        return "Active";
      }
    }
    return "Inactive";
  };

  const updateUserActiveStatus = async () => {
    let payload = { status: getUserActiveStatus() };
    let response = await sendUserActiveStatus(payload);
    consoleLogger(response);
  };

  useEffect(() => {
    const getData = async () => {
      if (userStore.UserData !== null) {
        let userAcc = userStore.UserData.account;
        if (userAcc.modules !== null) {
          let str = userAcc.modules;
          let newList = str.split(";");
          setModuleList(newList);
        }
      }
    };

    if (location.pathname !== "/login") {
      getData();
      shouldAdminAndProjectVisible();
    }
    if (pid !== null) {
      updateViewList();
      getNotications();
    }
  }, [pid]);

  const handleQuery = () => {
    if (query !== "") {
      authStore.updateSearchQuery(query);
      setQuery("");
      navigate("/");
      props.setIsFiltered(true);
    }
  };
  useEffect(() => {
    if (phoneCallState.showProgressiveCallDialog && !openCallLogModal) {
      setOpenCallInfo(true);
      setCallInfoScope(callInfoSheetScope.home_call_icon);
    }
  }, [phoneCallState.callStatus, phoneCallState.showProgressiveCallDialog]);

  const canShowCallBadge = () => {
    consoleLogger(
      `can show call badge ::: ${JSON.stringify(phoneCallState.callStatus)}`
    );
    if (phoneCallState.callStatus.hasOwnProperty("status")) {
      if (phoneCallState.callStatus["status"] === "completed") {
        return true;
      }
    }
    return false;
  };

  const getCallStatusLeadID = () => {
    /*
    this func is being used, because on submit call log, we are resetting call status
    So if we directly pass callstatus lead id, it will result in white screen
    */
    if (phoneCallState.callStatus.hasOwnProperty("lead")) {
      return phoneCallState.callStatus["lead"]["id"];
    } else {
      return "";
    }
  };

  const userAppLogoGetter = () => {
    if (userStore.UserData !== null) {
      if (userStore.UserData.hasOwnProperty("account")) {
        let account_info = userStore.UserData["account"];
        let logo_url = account_info.hasOwnProperty("logo_url")
          ? account_info["logo_url"]
          : "";
        return logo_url !== null && logo_url !== "" ? logo_url : appLogo;
      }
    }
    return appLogo;
  };

  const canShowProjectAdministration = () => {
    let accountRole =
      userStore.UserData !== null ? userStore.UserData.role : "";
    let projectRole = authStore.getCurrentProjectRole();
    if (accountRole === "admin" || accountRole === "owner") {
      return true;
    } else {
      if (projectRole !== "member") {
        return true;
      }
      return false;
    }
  };

  const canShowProjectAdministrationSubmenu = (menuName) => {
    let accountRole =
      userStore.UserData !== null ? userStore.UserData.role : "";
    let projectRole = authStore.getCurrentProjectRole();
    if (accountRole === "admin" || accountRole === "owner") {
      return true;
    } else {
      if (projectRole !== "member") {
        if (projectRole === "manager") {
          return true;
        }
        return TeamLeadAccessNameList.includes(menuName);
      }
      return false;
    }
  };

  const canShowWebFormsTile = () => {
    let accountName =
      userStore.UserData !== null ? userStore.UserData.account.name : "";
    return accountName.toLowerCase() === "demo";
  };
  const canShowReportMenuTile = () => {
    let accountName =
      userStore.UserData !== null ? userStore.UserData.account.name : "";
    if (
      process.env.REACT_APP_ENVIRONMENT !== "testproduction" ||
      accountName.toLowerCase() === "demo"
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          style={{
            backgroundColor: "white",
            height: "64px",
          }}
        >
          <Toolbar>
            <ListItem
              style={{
                justifyContent: "space-between",
                width: "100%",
                paddingRight: "20px",
                paddingLeft: open ? drawerWidth : "64px",
              }}
              // ref={myRef}
            >
              <Logo src={userAppLogoGetter()} />
              <ListItem
                style={{
                  boxShadow: "0px 4px 10px #B8B8CE26",
                  border: "1px solid #E7E7F0",
                  borderRadius: "8px",
                  height: "40px",
                  backgroundColor: "#EFEFF4",
                  padding: "0px",
                  position: "relative",
                  width: "400px",
                  marginLeft: "75px",
                }}
              >
                <SearchBarWrapper style={{ backgroundColor: "#EFEFF4" }}>
                  <Search
                    style={{
                      width: "22px",
                      height: "22px",
                      color: "#4D4E4F",
                      marginRight: "4px",
                    }}
                  />
                  <SearchInput
                    placeholder="Search"
                    value={query}
                    onChange={(e) => {
                      isUnderWorkSchedule()
                        ? setQuery(e.target.value)
                        : setIsInWorkHours(true);
                    }}
                    onKeyUp={(e) => (e.key === "Enter" ? handleQuery() : null)}
                  />
                  <Tune
                    style={{
                      width: "22px",
                      height: "22px",
                      color: "#4D4E4F",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      userStore.updateFilterDrawerState(true);
                      if (isUnderWorkSchedule()) {
                        navigate("/filter-page");
                      } else {
                        setIsInWorkHours(true);
                      }
                    }}
                  />
                </SearchBarWrapper>
                {props.open &&
                  (view === "All Leads" ||
                  view === "My Leads" ||
                  view === "All Businesses" ||
                  view === "My Businesses" ? (
                    <MainFilterComponent
                      isFiltered={props.isFiltered}
                      open={props.open}
                      setOpen={props.setOpen}
                      setRow={props.setRow}
                      setCount={props.setCount}
                      setLoading={props.setLoading}
                      setIsFiltered={props.setIsFiltered}
                      setPage={props.setPage}
                      setType={props.setType}
                      row={props.row}
                      type={props.type}
                    />
                  ) : (
                    <FilterComponent
                      open={props.open}
                      setOpen={props.setOpen}
                    />
                  ))}
              </ListItem>
              {/* <LogoTitle>Lead Pixie</LogoTitle> */}
            </ListItem>
            <ListItem
              style={{
                justifyContent: "flex-end",
                width: "fit-content",
                paddingLeft: "0px",
              }}
              sx={{
                pointerEvents: props.open ? "none" : "unset",
              }}
            >
              {phoneCallState.hasPendingCallLog() && (
                <IconButton
                  onClick={() => {
                    consoleLogger(
                      "Phone state ::: post submit calllog ::: call end button"
                    );

                    phoneCallState.postSubmitCallLog();
                  }}
                  disabled={!phoneCallState.dispositionFailed}
                  // style={{ marginRight: "16px" }}
                >
                  <PhoneDisabledIcon
                    color={
                      phoneCallState.dispositionFailed ? "error" : "disabled"
                    }
                  />
                </IconButton>
              )}
              {phoneCallState.hasPendingCallLog() && (
                <IconButton
                  onClick={() => {
                    phoneCallState.fetchUserStatus();
                    setOpenCallInfo(true);
                    setCallInfoScope(callInfoSheetScope.home_call_icon);
                  }}
                  style={{ marginRight: "16px" }}
                >
                  {canShowCallBadge() ? (
                    <Badge
                      variant="dot"
                      badgeContent=" "
                      color="error"
                      invisible={!canShowCallBadge()}
                    >
                      <Call color="primary" />
                    </Badge>
                  ) : (
                    <Call color="primary" className={classes.blink} />
                  )}
                </IconButton>
              )}
              {toJS(authStore.projectList).length > 0 && (
                <Select
                  disableUnderline
                  value={pid}
                  variant="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  label="Select Member"
                  onChange={handleChange}
                  style={{
                    minWidth: "125px",
                    backgroundColor: "#EFEFF4",
                    padding: "0px 8px",
                    marginRight: "20px",
                    fontSize: "14px",
                    borderRadius: "4px",
                    height: "40px",
                  }}
                >
                  {toJS(authStore.projectList).map((e) => {
                    return (
                      <MenuItem
                        style={{ fontSize: "14px" }}
                        id={e.id}
                        value={e.id}
                      >
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              {authStore.currentProject !== null && tid !== null && (
                <Select
                  disableUnderline
                  variant="standard"
                  value={tid}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  label="Select Member"
                  onChange={(e) =>
                    isUnderWorkSchedule()
                      ? handleTeam(e)
                      : setIsInWorkHours(true)
                  }
                  style={{
                    minWidth: "125px",
                    backgroundColor: "#EFEFF4",
                    padding: "0px 8px",
                    fontSize: "14px",
                    borderRadius: "4px",
                    height: "40px",
                  }}
                >
                  {authStore.currentProject.hasOwnProperty("teams") &&
                    authStore.currentProject["teams"].length > 0 &&
                    authStore.currentProject["teams"].map((e) => {
                      return (
                        <MenuItem
                          style={{ fontSize: "14px" }}
                          id={e.id}
                          value={e.id}
                        >
                          {e.name}
                        </MenuItem>
                      );
                    })}
                  ;
                </Select>
              )}
              <IconButton
                onClick={(e) =>
                  isUnderWorkSchedule()
                    ? handleNotification(e)
                    : setIsInWorkHours(true)
                }
                aria-controls={openMenu ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
              >
                <Badge
                  badgeContent={notifyCount}
                  color="error"
                  invisible={notify && notifyCount === 0}
                >
                  <Notifications color="action" />
                </Badge>
              </IconButton>
            </ListItem>
          </Toolbar>
        </AppBar>

        <Sidebar
          width={drawerWidth}
          rootStyles={{
            [`.${sidebarClasses.container}`]: {
              backgroundColor: "white",
              position: "sticky",
              left: 0,
              top: 0,
              height: "100vh",
              width: open ? drawerWidth : "unset",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              transition: "all 0.5s ease",
            },
          }}
          collapsed={!open}
        >
          <div>
            <div
              style={{
                position: "sticky",
                top: 0,
                left: 0,
                display: "flex",
                alignItems: "center",
                height: "64px",
                padding: "8px",
                justifyContent: "space-between",
                backgroundColor: "white",
                zIndex: 2,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {open ? (
                  <AccountCircle
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#757575",
                      marginRight: "8px",
                    }}
                  />
                ) : (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    sx={{ marginLeft: "10px" }}
                  >
                    <MenuIcon style={{ color: "#4D4E4F" }} />
                  </IconButton>
                )}
                <ProfileWrapper>
                  <p
                    style={{
                      margin: "0px",
                      fontWeight: "bold",
                      opacity: open ? "1" : "0",
                    }}
                  >
                    {userStore.UserData !== null ? userStore.UserData.name : ""}
                  </p>
                  <p
                    style={{
                      color: "#949494",
                      fontSize: "14px",
                      margin: "0px",
                      fontWeight: "bold",
                      opacity: open ? "1" : "0",
                    }}
                  >
                    {userStore.UserData !== null
                      ? userStore.UserData.account.name
                      : ""}
                  </p>
                </ProfileWrapper>
              </div>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightOutlined />
                ) : (
                  <ChevronLeftOutlined />
                )}
              </IconButton>
            </div>

            <Divider />
            <Menuv2
              closeOnClick={true}
              menuItemStyles={{
                subMenuContent: {
                  maxHeight: `calc(100vh - 148px)`,
                  overflowY: "auto",
                  width: "unset",
                  minWidth: open ? "unset" : "200px",
                },
                root: {
                  "&.ps-active": {
                    backgroundColor: "#EDF4FB",
                  },
                },
                label: {
                  color: "#3E2121",
                  "&.ps-active": {
                    color: "#185DD2",
                  },
                },
                icon: {
                  color: "#757575",
                  "&.ps-active": {
                    color: "#185DD2",
                  },
                },
              }}
            >
              <MenuItemv2
                active={location.pathname === "/summary"}
                component={<Link to="/summary" />}
                icon={<Dashboard />}
              >
                Dashboard
              </MenuItemv2>
              <SubMenuV2 label="Sales" icon={<Business />}>
                {rootStore.leadStore._tabGroups.length > 1 ? (
                  <SubMenuV2
                    label={getLocalizedText("leads", "project")}
                    icon={<ListAlt />}
                  >
                    {rootStore.leadStore._tabGroups.map((group) => {
                      return (
                        <MenuItemv2
                          active={
                            group["name"] ===
                            rootStore.leadStore._selectedGroupName
                          }
                          onClick={() => {
                            rootStore.leadStore.updateSelectedGroupName(
                              group["name"]
                            );
                            rootStore.leadStore.updateTabID(
                              rootStore.leadStore.groupsToTabs[group["name"]][0]
                            );
                            if (props.isFiltered) {
                              props.setIsFiltered(false);
                              navigate("/");
                            } else {
                              navigate("/");
                            }
                          }}
                        >
                          {group["name"]}
                        </MenuItemv2>
                      );
                    })}
                  </SubMenuV2>
                ) : (
                  <MenuItemv2
                    icon={<ListAlt />}
                    active={location.pathname === "/"}
                    onClick={() => {
                      if (props.isFiltered) {
                        props.setIsFiltered(false);
                        navigate("/");
                      } else {
                        navigate("/");
                      }
                    }}
                  >
                    {getLocalizedText("leads", "project")}
                  </MenuItemv2>
                )}
                {validateDisabledSubFeature("deals_navigation") && (
                  <MenuItemv2
                    active={location.pathname === "/deals"}
                    icon={<LocalOffer />}
                    component={<Link to="/deals" />}
                  >
                    Deals
                  </MenuItemv2>
                )}
              </SubMenuV2>
              {moduleList.includes("marketing") &&
                checkFeaturePermission("marketing") && (
                  <SubMenuV2 label="Marketing" icon={<ConnectWithoutContact />}>
                    <MenuItemv2
                      active={location.pathname === "/segments"}
                      icon={<Segment />}
                      component={<Link to="/segments" />}
                    >
                      Segments
                    </MenuItemv2>
                    <MenuItemv2
                      active={location.pathname === "/campaigns"}
                      icon={<Campaign />}
                      component={<Link to="/campaigns" />}
                    >
                      Campaigns
                    </MenuItemv2>
                    {canShowWebFormsTile() && (
                      <MenuItemv2
                        active={location.pathname === "/web-forms"}
                        icon={<DynamicForm />}
                        component={<Link to="/web-forms" />}
                      >
                        Web Forms
                      </MenuItemv2>
                    )}
                  </SubMenuV2>
                )}
              {checkFeaturePermission("entities") && (
                <SubMenuV2 label="Entities" icon={<GroupWork />}>
                  <MenuItemv2
                    active={location.pathname === "/products"}
                    component={<Link to="/products" />}
                    icon={<ProductionQuantityLimits />}
                  >
                    Products
                  </MenuItemv2>
                  <MenuItemv2
                    active={location.pathname === "/contacts"}
                    component={<Link to="/contacts" />}
                    icon={<Contacts />}
                  >
                    Contacts
                  </MenuItemv2>
                  <MenuItemv2
                    active={location.pathname === "/customers"}
                    component={<Link to="/customers" />}
                    icon={<Diversity3 />}
                  >
                    Customers
                  </MenuItemv2>
                  <MenuItemv2
                    active={location.pathname === "/organizations"}
                    component={<Link to="/organizations" />}
                    icon={<CorporateFare />}
                  >
                    Organizations
                  </MenuItemv2>
                </SubMenuV2>
              )}
              {canShowReportMenuTile() && (
                <MenuItemv2
                  active={location.pathname === "/reports"}
                  component={<Link to="/reports" />}
                  icon={<Assessment />}
                >
                  Reports
                </MenuItemv2>
              )}
              {checkFeaturePermission("imports") && (
                <MenuItemv2
                  active={location.pathname === "/imports"}
                  component={<Link to="/imports" />}
                  icon={<Upload />}
                >
                  Imports
                </MenuItemv2>
              )}
              {moduleList.includes("tva") &&
                checkFeaturePermission("target") && (
                  <MenuItemv2
                    active={location.pathname === "/target"}
                    component={<Link to="/target" />}
                    icon={<TrackChanges />}
                  >
                    Target
                  </MenuItemv2>
                )}
              {showAdmin && (
                <SubMenuV2 label="Administration" icon={<AdminPanelSettings />}>
                  {checkFeaturePermission("administrator_users") && (
                    <MenuItemv2
                      active={location.pathname === "/admin/users"}
                      icon={<Group />}
                      component={<Link to="/admin/users" />}
                    >
                      Users
                    </MenuItemv2>
                  )}
                  {moduleList.includes("raw_leads") && (
                    <MenuItemv2
                      active={location.pathname === "/admin/raw-leads"}
                      icon={<DriveFolderUpload />}
                      component={<Link to="/admin/raw-leads" />}
                    >
                      Raw Leads
                    </MenuItemv2>
                  )}
                  <MenuItemv2
                    active={location.pathname === "/admin/ip-config"}
                    icon={<CellTower />}
                    component={<Link to="/admin/ip-config" />}
                  >
                    IP Whitelisting
                  </MenuItemv2>
                </SubMenuV2>
              )}

              {canShowProjectAdministration() && (
                <SubMenuV2
                  label="Project Administration"
                  icon={<BusinessCenter />}
                >
                  {AdminProjectMenuList.map((menuData) => {
                    if (canShowProjectAdministrationSubmenu(menuData.name)) {
                      if (menuData.hasOwnProperty("submenu_list")) {
                        return (
                          <SubMenuV2 label={menuData.name} icon={menuData.icon}>
                            {menuData.submenu_list.map((subMenuData) => {
                              return (
                                <MenuItemv2
                                  active={
                                    location.pathname === subMenuData.path
                                  }
                                  icon={subMenuData.icon}
                                  component={<Link to={subMenuData.path} />}
                                >
                                  {subMenuData.name}
                                </MenuItemv2>
                              );
                            })}
                          </SubMenuV2>
                        );
                      } else {
                        return (
                          <MenuItemv2
                            active={location.pathname === menuData.path}
                            icon={menuData.icon}
                            component={<Link to={menuData.path} />}
                          >
                            {menuData.name}
                          </MenuItemv2>
                        );
                      }
                    }
                  })}
                </SubMenuV2>
              )}

              <SubMenuV2 label="Settings" icon={<Settings />}>
                <MenuItemv2
                  active={location.pathname === "/account-setting"}
                  icon={<ManageAccountsOutlined />}
                  component={<Link to="/account-setting" />}
                >
                  User Settings
                </MenuItemv2>
                {validateDisabledFeature("checkins") && (
                  <MenuItemv2
                    active={location.pathname === "/checkin"}
                    icon={<Place />}
                    component={<Link to="/checkin" />}
                  >
                    CheckIn
                  </MenuItemv2>
                )}
              </SubMenuV2>
              <MenuItemv2
                active={location.pathname === "/files"}
                component={<Link to="/files" />}
                icon={<Folder />}
              >
                Files
              </MenuItemv2>
            </Menuv2>
          </div>
          <div>
            <Menuv2
              closeOnClick={true}
              menuItemStyles={{
                label: {
                  color: "#3E2121",
                },
                icon: {
                  color: "#757575",
                },
              }}
            >
              <MenuItemv2 onClick={handleLogout} icon={<ExitToApp />}>
                Logout
              </MenuItemv2>
            </Menuv2>
          </div>
        </Sidebar>
      </Box>
      {openCsv && <UploadModal open={openCsv} setOpen={setOpenCsv} />}

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleMenuClose}
        // onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            bgcolor: "#f9f9fc",
            borderRadius: "0px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <NotificationComponent
          setNotify={setNotify}
          data={notifyData}
          isFiltered={props.isFiltered}
          open={props.open}
          setOpen={props.setOpen}
          setRow={props.setRow}
          setCount={props.setCount}
          setLoading={props.setLoading}
          setIsFiltered={props.setIsFiltered}
          setPage={props.setPage}
          setType={props.setType}
          row={props.row}
          type={props.type}
          setAnchorEl={setAnchorEl}
          setNotifyCount={setNotifyCount}
          notifyCount={notifyCount}
        />
      </Menu>
      {isError && <AlertDialogue open={isError} setOpen={setIsError} />}
      {isInWorkHours && (
        <WorkScheduleAlert open={isInWorkHours} setOpen={setIsInWorkHours} />
      )}
      {openCallInfo && (
        <CallInfoDialog
          open={openCallInfo}
          setOpen={setOpenCallInfo}
          scope={callInfoScope}
          // openCallLogCallback={async () => {
          //   let lead_id = getCallStatusLeadID();
          //   navigate(`/users/${uid}/leads/${lead_id}`, {
          //     state: { scope: "call_info_dialog_submit_log" },
          //   });
          //   setOpenCallInfo(false);
          // }}
          openCallLogCallback={async () => {
            const getLeadDetails = async () => {
              let leadID = getCallStatusLeadID();
              let response = await getLeadProfileApi(leadID);
              setLeadData(response.data.lead);
            };

            await getLeadDetails();
            setOpenCallLogModal(true);
            setOpenCallInfo(false);
          }}
        />
      )}
      {openCallLogModal && (
        <CallLogModal
          scope={"app_bar"}
          open={openCallLogModal}
          setOpen={setOpenCallLogModal}
          lid={getCallStatusLeadID()}
          uid={uid}
          leadData={leadData}
        />
      )}
    </>
  );
};
export default observer(AppbarV2);
