import React from "react";
import Footer from "../../components/landing_page_components/footer/Footer";

import styled from "styled-components";
import { observer } from "mobx-react-lite";

import { ChevronRight } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import JobsTab from "../../components/project_tabs/JobsTab";
import { getAdminStatusesApi } from "../../Api";
import { useState } from "react";
import { useEffect } from "react";
const ParentContainer = styled.div`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled.div`
  height: 100%;
  margin: auto;
  margin-top: 70px;
  width: 95%;
`;
const Wrapper = styled.div`
  display: flex;
  position: relative;
  ${`min-height: calc(100vh - 188px);`};
  background-color: white;
  padding: 20px 20px 0px 20px;
  border: 2px solid #f9f9fc;
`;
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0px;
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;

const AdminProjectJobs = () => {
  const { authStore } = rootStore;
  const [statusList, setStatusList] = useState([]);
  const project_id = authStore.projectId;

  const getStatusList = async () => {
    try {
      let response = await getAdminStatusesApi(project_id);
      setStatusList(response.data);
    } catch (error) {
      console.log(error);
      setStatusList([]);
    }
  };
  useEffect(() => {
    authStore.updateLeadDetailStatus();
    getStatusList();
  }, []);
  return (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <Text>Project Administration</Text>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Text>Jobs</Text>
          </TopWrapper>

          <Wrapper>
            <JobsTab id={project_id} statusList={statusList} />
          </Wrapper>
        </Container>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default observer(AdminProjectJobs);
