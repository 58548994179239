import React, { useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import Carousel from "react-multi-carousel";
import { useState } from "react";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

const activeStageColor = "#26599E";
const oldStageColor = "#EDA93B";
const untouchedStageColor = "#E6E6E6";

const Container = styled(Box)`
  width: 100%;
  margin-bottom: 16px;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
`;

const IconWrapperLeft = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background-color: #ddddff;
  display: flex;
  border-radius: 50%;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
`;
const IconWrapperRight = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #ddddff;
  display: flex;
  border-radius: 50%;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
`;
const StepBox = styled(Box)`
  background: ${(e) => e.colorData.bgColor};
  color: ${(e) => e.colorData.color};
  min-width: 100px;
  flex: 100%;
  height: 50px;
  padding: 20px;
  padding-left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 -12px;
  clip-path: ${(props) => props.clipPath};
`;
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 12,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 8,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 6,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 4,
  },
};

const CustomLeft = ({ onClick }) => (
  <IconWrapperLeft onClick={onClick}>
    <IconButton size="small">
      <ArrowBack style={{ color: "#26599E" }} />
    </IconButton>
  </IconWrapperLeft>
);
const CustomRight = ({ onClick }) => (
  <IconWrapperRight onClick={onClick}>
    <IconButton size="small">
      <ArrowForward style={{ color: "#26599E" }} />
    </IconButton>
  </IconWrapperRight>
);

const StageStepperComponentv2 = (props) => {
  const carouselRef = useRef();
  const [showButton, setShowButton] = useState(false);
  const [currentStage, setCurrentStage] = useState("");
  const init = () => {
    if (carouselRef && carouselRef.current) {
      let index = props.stageList.findIndex(
        (stage) =>
          stage["stage"].toLowerCase() ===
          props.leadData["lead_stage"].toLowerCase()
      );
      if (index !== -1) {
        let max_index = carouselRef.current.state.totalItems;
        let number_of_slides = carouselRef.current.state.slidesToShow;
        let lower_bound = index - number_of_slides / 2;
        let upper_bound = index + number_of_slides / 2;
        if (max_index >= number_of_slides) {
          if (lower_bound < 0) {
            carouselRef.current.goToSlide(0);
          } else if (upper_bound > max_index) {
            carouselRef.current.goToSlide(max_index - number_of_slides);
          } else if (lower_bound > 0 && upper_bound < max_index) {
            carouselRef.current.goToSlide(lower_bound);
          }
        }
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (
        currentStage.toLowerCase() !==
        props.leadData["lead_stage"].toLowerCase()
      ) {
        setCurrentStage(props.leadData["lead_stage"]);
        init();
      }
    }, 100);
  }, [props.leadData]);

  const getStageColorProps = (stageIndex) => {
    let currentStageIndex = props.stageList.findIndex(
      (stage) =>
        stage["stage"].toLowerCase() ===
        props.leadData["lead_stage"].toLowerCase()
    );
    if (currentStageIndex !== -1) {
      if (stageIndex === currentStageIndex) {
        return {
          bgColor: activeStageColor,
          color: "white",
        };
      } else if (stageIndex > currentStageIndex) {
        return {
          bgColor: untouchedStageColor,
          color: activeStageColor,
        };
      } else if (stageIndex < currentStageIndex) {
        return {
          bgColor: oldStageColor,
          color: activeStageColor,
        };
      }
    }
  };

  const handleTextLength = (str) => {
    return str.length > 20 ? str.slice(0, 20 - 1) + "..." : str;
  };

  return (
    <Container
      onMouseEnter={() => {
        setShowButton(true);
      }}
      onMouseLeave={() => {
        setShowButton(false);
      }}
    >
      <Carousel
        ref={carouselRef}
        responsive={responsive}
        swipeable={false}
        draggable={false}
        arrows={showButton}
        containerClass="stage-carousel-container"
        customLeftArrow={<CustomLeft />}
        customRightArrow={<CustomRight />}
      >
        {props.stageList.map((item, index) => {
          const isFirst = index === 0;
          const isLast = index === props.stageList.length - 1;

          let clipPath =
            "polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)";
          let firstClipPath =
            "polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)";
          let lastClipPath =
            "polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%)";

          if (isFirst) {
            clipPath = firstClipPath;
          } else if (isLast) {
            clipPath = lastClipPath;
          }

          return (
            <StepBox colorData={getStageColorProps(index)} clipPath={clipPath}>
              <Tooltip title={item["stage"]}>
                <Text>{handleTextLength(item["stage"])}</Text>
              </Tooltip>
            </StepBox>
          );
        })}
      </Carousel>
    </Container>
  );
};

export default StageStepperComponentv2;
