import React, { useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import "./createreport.css";
import { Close } from "@material-ui/icons";
import moment from "moment";
import {
  editReportApi,
  getStatusApi,
  submitCallApi,
  submitReportApi,
} from "../../Api";
import { useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import { DatePicker } from "antd";
import MainFilterComponent from "../filter_modal/MainFilterComponent";
import ReportFilterComponent from "../filter_modal/ReportFilterComponent";
import { toJS } from "mobx";
import { getLocalizedText, validateUnmaskColumns } from "../../Functions";
import { myTheme } from "../../themeUtils";
import analytics from "../../Analytics";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;

const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 30px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 320px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Input = styled.input`
  width: 320px;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled.textarea`
  width: 320px;
  height: 60px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;
  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 180px;
  @media (max-width: 700px) {
    width: 140px;
  }
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;

function ampm(time) {
  if (time.value !== "") {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var suffix = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var displayTime = hours + ":" + minutes + " " + suffix;
    return displayTime;
  }
  return;
}
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const CustomTab = withStyles({
  root: {
    backgroundColor: "white",
    color: "#4D4E4F",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
  selected: {
    backgroundColor: "#E7EEFA",
    color: "#4079DA",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
})(Tab);
const CreateReportModal = (props) => {
  const { authStore, userStore } = rootStore;
  const classes = useStyles();
  const [user, setUser] = useState({
    title: "",
    desc: "",
    unmask: false,
  });

  const { RangePicker } = DatePicker;
  const [dateRangeReport, setDateRangeReport] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState([]);
  const [list, setList] = useState([]);
  const [tabVal, selectTabVal] = useState(0);
  const [filterList, setFilterList] = useState([]);
  const [dateRange, setDateRange] = useState({});
  const [dateRangeVal, setDateRangeVal] = useState(null);
  const [numRange, setNumRange] = useState({});
  const [selectedVal, setSelectedVal] = useState({});
  const [filters, setFilters] = useState({});
  let pid = authStore.projectId;
  const OrgColumnsList = [...userStore.AllColumnsList];
  const handleClick = () => {
    props.setOpen(false);
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  const generateColumns = (val, list) => {
    let newList = [];

    if (val.length > 0 && list.length > 0) {
      list.map((e) => {
        if (val.includes(e["value"])) {
          newList.push(e);
        }
      });
    }
    return newList;
  };
  const updateList = (val) => {
    let newList = [];
    let filterList = [];
    val.map((e) => {
      if (e.field !== "lead_created_at" && e.field !== "lead_creator") {
        if (e.data_type === "datetime") {
          if (e.system === true) {
            let obj1 = {
              label: e.headerName,
              value: e.field,
            };
            let obj2 = {
              label: e.headerName + "::DateTime",
              value: e.field + "::datetime",
            };
            newList.push(obj1);
            newList.push(obj2);
          } else {
            let newObj = {
              label: e.headerName,
              value: e.field,
            };
            newList.push(newObj);
          }
        } else {
          let newObj = {
            label: e.headerName,
            value: e.field,
          };
          newList.push(newObj);
          filterList.push(newObj);
        }
      }
      if (e.filterable) {
        let newObj = {
          label: e.headerName,
          value: e.field,
        };
        filterList.push(newObj);
      }
    });
    return { newList, filterList };
  };

  const handleSubmit = () => {
    // props.setOpen(false);
    userStore.setReportFilters({
      filters,
      dateRange,
      numRange,
      selectedVal,
    });

    const submitReport = async () => {
      let column_list = [];
      selected.forEach((option) => {
        column_list.push(option.value);
      });
      let data = {
        title: user.title,
        desc: user.desc,
        unmask_columns: user.unmask,
        columns: column_list,
        filter: userStore.reportFilters,
        ...dateRangeReport,
      };
      try {
        let response = await submitReportApi({ data: data });
        userStore.resetReportFilters();
        props.setOpen(false);
        props.setRefresh(!props.refresh);
      } catch (error) {
        console.log(error);
      }
    };
    const editReport = async () => {
      let column_list = [];
      selected.forEach((option) => {
        column_list.push(option.value);
      });
      let data = {
        title: user.title,
        desc: user.desc,
        unmask_columns: user.unmask,
        columns: column_list,
        filter: userStore.reportFilters,
        ...dateRangeReport,
      };
      try {
        let response = await editReportApi({ id: props.data.id, data: data });
        userStore.resetReportFilters();
        props.setOpen(false);
        props.setRefresh(!props.refresh);
      } catch (error) {
        console.log(error);
      }
    };
    if (props.type === "edit") {
      editReport();
    } else {
      submitReport();
    }
  };
  const searchFieldType = (val) => {
    let datatype = null;
    if (OrgColumnsList.length > 0) {
      OrgColumnsList.map((item) => {
        if (item["field"] === val) {
          datatype = item["data_type"];
        }
      });
    }
    return datatype;
  };
  const searchFilterType = (val) => {
    let filterType = null;
    if (OrgColumnsList.length > 0) {
      OrgColumnsList.map((item) => {
        if (item["field"] === val) {
          filterType = item["filter_type"];
        }
      });
    }
    return filterType;
  };
  useEffect(() => {
    analytics.triggerEvent(
      4625000086,
      "create_report_form_load",
      "reports_page",
      "",
      {}
    );
    const getColumns = async () => {
      let modifiedList = updateList([...rootStore.userStore.AllColumnsList]);
      setList(modifiedList.newList);
      if (props.type === "edit") {
        if (Object.keys(props.data).length > 0) {
          let prevColumns = generateColumns(
            props.data["columns"],
            modifiedList.newList
          );
          setSelected(prevColumns);
          setSelectedColumn(props.data["columns"]);
        }
      }
      setFilterList(modifiedList.filterList);
    };
    getColumns();
    if (props.type === "edit") {
      if (Object.keys(props.data).length > 0) {
        setUser({
          title: props.data["title"],
          desc: props.data["desc"],
          unmask: props.data["unmask_columns"],
        });
        if (Object.keys(props.data["filter"]).length > 0) {
          let filterData = props.data["filter"];
          let newObj = {};
          let tempObj = {};
          let listObj = {};
          Object.keys(filterData).map((e) => {
            if (typeof filterData[e] !== "object") {
              let field_type = searchFieldType(e);
              newObj[e] = { value: filterData[e], type: field_type };
            } else {
              let index = OrgColumnsList.findIndex(
                (column) => column["field"] === e
              );
              if (index !== -1) {
                if (OrgColumnsList[index]["is_custom_param"]) {
                  let newCustomData = filterData[e];
                  if (Object.keys(newCustomData).length > 0) {
                    Object.keys(newCustomData).map((item) => {
                      let new_key = "custom_params_" + item;
                      let field_type = searchFieldType(new_key);
                      let filter_type = searchFilterType(new_key);
                      if (field_type === "date" || field_type === "datetime") {
                        let d = new Date(newCustomData[item]);
                        d = moment(d.toString()).format("YYYY-MM-DD");
                        newObj[new_key] = {
                          value: d,
                          type: field_type,
                        };
                      } else if (filter_type === "list") {
                        listObj[new_key] = newCustomData[item];
                      } else {
                        newObj[new_key] = {
                          value: newCustomData[item],
                          type: field_type,
                        };
                      }
                    });
                  }
                } else {
                  let field_type = searchFieldType(e);
                  let newCustomData = filterData[e];
                  if (field_type === "date" || field_type === "datetime") {
                    let obj = {};
                    let d1 = new Date(newCustomData["start"]);
                    let d2 = new Date(newCustomData["end"]);
                    d1 = moment(d1.toString()).format("YYYY-MM-DD");
                    d2 = moment(d2.toString()).format("YYYY-MM-DD");
                    obj["start"] = d1;
                    obj["end"] = d2;
                    tempObj[e] = obj;
                  } else {
                    listObj[e] = newCustomData;
                  }
                }
              }
            }
          });
          setSelectedVal(listObj);
          setDateRange(tempObj);
          setFilters(newObj);
        }

        setDateRangeVal({
          start_date: props.data["start_date"],
          end_date: props.data["end_date"],
        });
        setDateRangeReport({
          start_date: props.data["start_date"],
          end_date: props.data["end_date"],
        });
        // let prevColumns = generateColumns(props.data["columns"]);
        // console.log("previous columns", props.data["columns"]);
        // setSelected(prevColumns);
      }
    }
  }, []);
  // console.log(user);
  function disabledDate(current) {
    // Disable all dates that are outside of the current and previous month
    return (
      current &&
      (current > moment().endOf("month") ||
        current < moment().subtract(1, "month").startOf("month"))
    );
  }

  const handleSelect = (selectedOptions) => {
    // Group the options by their base name (before "::")
    const groupedOptions = selectedOptions.reduce((acc, option) => {
      const baseName = option.value.split("::")[0];
      if (!acc[baseName]) {
        acc[baseName] = [];
      }
      acc[baseName].push(option);
      return acc;
    }, {});

    // Filter out the options with duplicate base names and keep only the last selected option
    const filteredOptions = Object.values(groupedOptions).map(
      (group) => group[group.length - 1]
    );

    //
    setSelected(filteredOptions);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            {/* <Close
              onClick={handleClick}
              style={{
                height: "18px",
                width: "18px",
                position: "absolute",
                top: "20px",
                right: "30px",
                cursor: "pointer",
              }}
            /> */}
            <Container>
              {props.type === "edit" ? (
                <Header>{getLocalizedText("edit_report")}</Header>
              ) : (
                <Header>{getLocalizedText("create_a_new_report")}</Header>
              )}
              <SubHeader>
                {getLocalizedText("fill_in_the_below_details")}
              </SubHeader>
              <Tabs
                value={tabVal}
                textColor="primary"
                indicatorColor="primary"
                onChange={(event, newValue) => {
                  if (newValue === 0) {
                    analytics.triggerEvent(
                      4625000087,
                      "basic_info_tab_tap",
                      "create_report_form",
                      "basic_info_tab",
                      {}
                    );
                  } else {
                    analytics.triggerEvent(
                      4625000088,
                      "select_filters_tab_tap",
                      "create_report_form",
                      "select_filters_tab",
                      {}
                    );
                  }
                  selectTabVal(newValue);
                }}
              >
                <CustomTab label={getLocalizedText("basic_info")} />
                <CustomTab label={getLocalizedText("select_filters")} />
              </Tabs>

              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <InputContainer>
                  {(() => {
                    if (tabVal === 0) {
                      return (
                        <div style={{ margin: "6px" }}>
                          <InputWrapper style={{ marginTop: "12px" }}>
                            <Label>{getLocalizedText("title")}*</Label>
                            <Input
                              required
                              type="text"
                              placeholder="Enter title"
                              name="title"
                              value={user.title}
                              onChange={handleChange}
                            />
                          </InputWrapper>
                          <InputWrapper>
                            <Label>{getLocalizedText("description")}</Label>
                            <TextArea
                              value={user.desc}
                              type="text"
                              placeholder="Enter description"
                              name="desc"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                          <Row
                            style={{
                              columnGap: "20px",
                              marginBottom: "20px",
                            }}
                          >
                            <InputWrapper>
                              <Label>
                                {getLocalizedText("select_columns")}
                              </Label>
                              <MultiSelect
                                hasSelectAll={false}
                                options={list}
                                value={selected}
                                className="report_multi_select"
                                onChange={handleSelect}
                                labelledBy="Select Columns"
                              />
                            </InputWrapper>
                            <InputWrapper style={{ width: "150px" }}>
                              <Label
                                style={{
                                  marginBottom: "0px",
                                }}
                              >
                                {getLocalizedText("unmask_columns")}
                              </Label>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                              >
                                <FormControlLabel
                                  disabled={!validateUnmaskColumns()}
                                  value={true}
                                  control={<Radio size="small" />}
                                  label={
                                    <Label style={{ fontSize: "12px" }}>
                                      {getLocalizedText("yes")}
                                    </Label>
                                  }
                                  checked={user.unmask}
                                  onChange={() => {
                                    setUser({ ...user, unmask: true });
                                  }}
                                />
                                <FormControlLabel
                                  disabled={!validateUnmaskColumns()}
                                  value={false}
                                  checked={!user.unmask}
                                  onChange={() => {
                                    setUser({ ...user, unmask: false });
                                  }}
                                  control={<Radio size="small" />}
                                  label={
                                    <Label style={{ fontSize: "12px" }}>
                                      {getLocalizedText("no")}
                                    </Label>
                                  }
                                />
                              </RadioGroup>
                            </InputWrapper>
                          </Row>
                          <Row>
                            {props.type === "edit" ? (
                              dateRangeVal !== null && (
                                <InputWrapper style={{ marginBottom: "20px" }}>
                                  <Label>
                                    {getLocalizedText("select_date_range")}
                                  </Label>
                                  <RangePicker
                                    disabledDate={disabledDate}
                                    placeholder={["From", "To"]}
                                    getPopupContainer={(triggerNode) => {
                                      return triggerNode.parentNode;
                                    }}
                                    style={{ height: "30px", width: "320px" }}
                                    // value={dateRangeVal}
                                    defaultValue={[
                                      moment(
                                        dateRangeVal["start_date"],
                                        "YYYY-MM-DD"
                                      ),
                                      moment(
                                        dateRangeVal["end_date"],
                                        "YYYY-MM-DD"
                                      ),
                                    ]}
                                    onChange={(value) => {
                                      let d1 = moment(value[0]).format(
                                        "YYYY-MM-DD"
                                      );
                                      let d2 = moment(value[1]).format(
                                        "YYYY-MM-DD"
                                      );
                                      setDateRangeReport({
                                        ...dateRangeReport,
                                        start_date: d1,
                                        end_date: d2,
                                      });
                                    }}
                                  />
                                </InputWrapper>
                              )
                            ) : (
                              <InputWrapper style={{ marginBottom: "20px" }}>
                                <Label>
                                  {getLocalizedText("select_date_range")}
                                </Label>
                                <RangePicker
                                  disabledDate={disabledDate}
                                  placeholder={["From", "To"]}
                                  getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                  }}
                                  defaultValue={
                                    dateRangeVal && [
                                      moment(
                                        dateRangeVal["start_date"],
                                        "YYYY-MM-DD"
                                      ),
                                      moment(
                                        dateRangeVal["end_date"],
                                        "YYYY-MM-DD"
                                      ),
                                    ]
                                  }
                                  style={{ height: "30px", width: "320px" }}
                                  // value={dateRangeVal}
                                  onChange={(value) => {
                                    let d1 = moment(value[0]).format(
                                      "YYYY-MM-DD"
                                    );
                                    let d2 = moment(value[1]).format(
                                      "YYYY-MM-DD"
                                    );
                                    setDateRangeVal({
                                      ...dateRangeVal,
                                      start_date: d1,
                                      end_date: d2,
                                    });
                                    setDateRangeReport({
                                      ...dateRangeReport,
                                      start_date: d1,
                                      end_date: d2,
                                    });
                                  }}
                                />
                              </InputWrapper>
                            )}
                            {/* <InputWrapper>
                      <Label>Select Frequency*</Label>
                      <Input
                        style={{
                          width: "100px",
                          height: "30px",
                        }}
                        step={1}
                        value={user.frequency}
                        required
                        type="time"
                        placeholder="Enter title"
                        name="frequency"
                        onChange={handleChange}
                      />
                    </InputWrapper> */}
                          </Row>
                          {/* <SelectColumn>
                    <Label>Select Report Type</Label>
                    <FormControl
                      sx={{ m: 1, minWidth: 200 }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        name="report_type"
                        displayEmpty
                        disableUnderline
                        variant="outlined"
                        required
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        style={{
                          marginBottom: "20px",
                          width: "200px",
                          height: "30px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "0px",
                        }}
                      >
                        {reportTypeList.map((e) => {
                          return (
                            <MenuItem id={e} value={e}>
                              {e}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </SelectColumn> */}
                        </div>
                      );
                    } else if (tabVal === 1) {
                      return (
                        <ReportFilterComponent
                          dateRange={dateRange}
                          setDateRange={setDateRange}
                          numRange={numRange}
                          selectedVal={selectedVal}
                          setSelectedVal={setSelectedVal}
                          filters={filters}
                          setFilters={setFilters}
                          type={"edit"}
                        />
                      );
                    }
                  })()}
                </InputContainer>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      handleClick();
                      analytics.triggerEvent(
                        4625000089,
                        "cancel_button_tap",
                        "create_report_form",
                        "cancel_button",
                        {}
                      );
                    }}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      marginRight: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      handleSubmit();
                      analytics.triggerEvent(
                        4625000090,
                        "submit_button_tap",
                        "create_report_form",
                        "submit_button",
                        {}
                      );
                    }}
                    type="submit"
                    variant="contained"
                    // id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {getLocalizedText("submit_details")}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(CreateReportModal);
