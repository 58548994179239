import { Button } from "@material-ui/core";
import { EventNote } from "@material-ui/icons";
import { Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getFilterColsApi, getFilteredLeadsApi } from "../../Api";
import { pageSize } from "../../Config";
import rootStore from "../../stores/RootStore";
import spinner from "../../assets/images/gifs/spinner.gif";
import { getLocalizedText } from "../../Functions";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const Container = styled.div`
  width: 286px;
  height: 40vh;
  overflow-y: auto;
  display: flex;
  background-color: white;
  flex-direction: column;
  margin-top: -8px;
  ::-webkit-scrollbar {
    width: 4px;
  }
  position: relative;
`;
const NotificationBar = styled.div`
  height: 40px;
  padding: 20px;
  display: flex;
  box-shadow: 0px 3px 6px #0000000d;
  align-items: center;
  background-color: white;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
`;
const Text1 = styled.div`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 20px; */
  padding-top: 0px;
  /* margin-top: 50px; */
`;
const ItemWrapper = styled.div`
  display: flex;
  padding: 20px;
`;
const ItemLeft = styled.div`
  margin-right: 20px;
`;
const ItemRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Text2 = styled.div`
  margin: 0px;
  font: normal normal 600 12px Poppins;
  color: #2b2b2b;
  margin-bottom: 2px;
`;
const Text3 = styled.div`
  margin: 0px;
  font: normal normal 500 10px Poppins;
  color: #a3a3a3;
`;
const Text4 = styled.span`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #185dd2;
  cursor: pointer;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  /* justify-content: flex-end; */
`;
const NotificationComponent = (props) => {
  const { userStore } = rootStore;
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    props.setNotify(true);
    setLoading(true);
    let newList = [];
    let count = 0;
    if (props.data.length > 0) {
      props.data.map((e) => {
        if (!userStore.blackList.hasOwnProperty(e.notification_type)) {
          newList.push(e);
          if (!userStore.activeList.hasOwnProperty(e.notification_type)) {
            count += 1;
          }
        }
      });
    }
    setList(newList);
    props.setNotifyCount(count);
    setLoading(false);
  }, [refresh]);
  const handleView = (e) => {
    userStore.setActiveList(e.notification_type);
    props.setNotifyCount(props.notifyCount - 1);
    getFilterColsApi();
    navigate(`/notification/${e.notification_type}/${e.title}`);
    props.setAnchorEl(null);
  };
  const handleDismiss = (e) => {
    userStore.updateBlackList(e.notification_type);
    setRefresh(!refresh);
  };
  return (
    <Container>
      {/* <NotificationBar>
        <Text1>Notifications</Text1>
      </NotificationBar> */}
      {!loading ? (
        list.length > 0 ? (
          <Wrapper>
            {list.map((e, i) => {
              return (
                <>
                  <ItemWrapper
                    style={{
                      backgroundColor: userStore.activeList.hasOwnProperty(
                        e.notification_type
                      )
                        ? "#F6F6F6"
                        : (i + 1) % 2 !== 0
                        ? "#f9f9fc"
                        : "white",
                    }}
                  >
                    <ItemLeft>
                      <EventNote
                        style={{
                          color: "#757575",
                        }}
                      />
                    </ItemLeft>
                    <ItemRight>
                      <Text2
                        style={{
                          color: userStore.activeList.hasOwnProperty(
                            e.notification_type
                          )
                            ? "#a3a3a3"
                            : "#2b2b2b",
                        }}
                      >
                        {e.title}
                      </Text2>
                      <Text3>{e.subtitle}</Text3>
                      <Row style={{ marginTop: "8px" }}>
                        <Text4
                          onClick={() => handleView(e)}
                          style={{
                            color: userStore.activeList.hasOwnProperty(
                              e.notification_type
                            )
                              ? "#a3a3a3"
                              : "##185dd2",
                            marginRight: "10px",
                          }}
                        >
                          {getLocalizedText("view")}
                        </Text4>
                        <Text4
                          style={{
                            color: userStore.activeList.hasOwnProperty(
                              e.notification_type
                            )
                              ? "#a3a3a3"
                              : "##185dd2",
                          }}
                          onClick={() => handleDismiss(e)}
                        >
                          {getLocalizedText("dismiss")}
                        </Text4>
                      </Row>
                    </ItemRight>
                  </ItemWrapper>
                  <Divider />
                </>
              );
            })}
          </Wrapper>
        ) : (
          <Wrapper
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ItemWrapper>
              <Text3>There's nothing here, yet</Text3>
            </ItemWrapper>
          </Wrapper>
        )
      ) : (
        <SpinnerWrapper>
          <Spinner src={spinner} alt="loading..." />
        </SpinnerWrapper>
      )}
    </Container>
  );
};

export default observer(NotificationComponent);
