import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Box } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { filesTabColumnHeaders } from "../../Db";
import { CircularProgress } from "@mui/material";
import PaginationComponent from "../pagination/PaginationComponent";
import { pageSize } from "../../Config";
import { AddBoxOutlined, Visibility } from "@material-ui/icons";
import AddFileModal from "../contact_forms_sidepanl/AddFileModal";
import { getContactFilesListApi, getContactLeadsListApi } from "../../Api";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 246px);`};
`;
const Wrapper = styled(Box)`
  display: flex;
`;

const DetailsWrapper = styled(Box)`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;

const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;
const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 6px;
  top: 6px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
`;

const FilesTab = (props) => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openAddFile, setOpenAddFile] = useState(false);

  const getContactFiles = async () => {
    let response = await getContactFilesListApi({
      contactID: props.id,
      page: 0,
      page_size: 50,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
    } else {
      setRow([]);
    }
  };

  const init = async () => {
    setLoading(true);
    await getContactFiles();
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    if (refresh) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "ACTIONS",
      flex: 1,
      width: 100,
      cellRenderer: (params) => {
        return (
          <Visibility
            style={{
              cursor: "pointer",
              marginRight: "10px",
              width: "20px",
              height: "20px",
            }}
            color="primary"
            onClick={(e) => {
              window.open(params.data.file, "_blank");
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Container>
        <Wrapper>
          <DetailsWrapper>
            <AddBtnWrapper>
              <AddBoxOutlined
                onClick={() => {
                  setOpenAddFile(true);
                }}
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </AddBtnWrapper>
            {!loading ? (
              <TableWrapper>
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={filesTabColumnHeaders.concat(actionColumn)}
                    animateRows={true}
                    suppressCellFocus
                    className="paginated-ag-grid"
                    onRowClicked={(row) => {
                      if (row.event.defaultPrevented) {
                        return null;
                      }
                    }}
                  />
                </div>
              </TableWrapper>
            ) : (
              <TableWrapper
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </TableWrapper>
            )}
          </DetailsWrapper>
        </Wrapper>
      </Container>
      {openAddFile && (
        <AddFileModal
          open={openAddFile}
          setOpen={setOpenAddFile}
          contactID={props.id}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default observer(FilesTab);
