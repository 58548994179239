import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { editProjectMemberApi, getTeamApi } from "../../Api";
import { useEffect } from "react";
import { myTheme } from "../../themeUtils";
import { getLocalizedText } from "../../Functions";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 30px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Input = styled.input`
  width: 428px;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const ButtonWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  column-gap: 28px;
  margin-bottom: 20px;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const EditProjectMember = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({
    team: "",
    calling_mode: "",
    role: "member",
    unmask_columns: false,
  });
  const [teamList, setTeamList] = useState([]);
  const roleList = ["lead", "member", "manager"];
  const callingModes = ["Manual", "Progressive"];

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const editUser = async () => {
      let data = {
        user_id: props.data.user_id,
        calling_mode: user.calling_mode,
        role: user.role,
        team_id: user.team,
        unmask_columns: user.unmask_columns,
      };
      try {
        let response = await editProjectMemberApi({
          id: props.pid,
          data: data,
        });
        props.setRefresh(!props.refresh);
        props.setOpen(false);
      } catch (error) {
        console.log(error);
      }
    };

    editUser();
  };
  const handleClose = () => {
    props.setOpen(false);
  };
  useEffect(() => {
    const getTeams = async () => {
      try {
        let response = await getTeamApi(props.pid);
        setTeamList(response);
      } catch (error) {
        console.log(error);
      }
    };
    getTeams();
    setUser({
      team: props.data.team["id"],
      calling_mode: props.data.calling_mode,
      role: props.data.role,
      unmask_columns: props.data.unmask_columns,
    });
  }, []);
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Header>{getLocalizedText("edit_member_details")}</Header>
              <SubHeader>
                {getLocalizedText("fill_in_the_below_details")}
              </SubHeader>
              <Form onSubmit={handleSubmit}>
                <InputContainer>
                  <Row>
                    <SelectColumn>
                      <Label>{getLocalizedText("select_role")}</Label>
                      <FormControl
                        sx={{ minWidth: 200 }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          value={user.role}
                          name="role"
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          required
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          style={{
                            width: "200px",
                            height: "30px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "0px",
                          }}
                        >
                          {roleList.map((e) => {
                            return (
                              <MenuItem id={e} value={e}>
                                {e}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </SelectColumn>
                    <InputWrapper style={{ width: "200px" }}>
                      <Label
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        {getLocalizedText("unmask_columns")}
                      </Label>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio size="small" />}
                          label={
                            <Label style={{ fontSize: "12px" }}>Yes</Label>
                          }
                          checked={user.unmask_columns}
                          onChange={() => {
                            setUser({ ...user, unmask_columns: true });
                          }}
                        />
                        <FormControlLabel
                          value={false}
                          checked={!user.unmask_columns}
                          onChange={() => {
                            setUser({ ...user, unmask_columns: false });
                          }}
                          control={<Radio size="small" />}
                          label={<Label style={{ fontSize: "12px" }}>No</Label>}
                        />
                      </RadioGroup>
                    </InputWrapper>
                  </Row>
                  <Row>
                    <SelectColumn>
                      <Label>{getLocalizedText("calling_mode")}</Label>
                      <FormControl
                        sx={{ minWidth: 200 }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          s
                          name="calling_mode"
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          required
                          value={user.calling_mode}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          style={{
                            width: "200px",
                            height: "30px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "0px",
                          }}
                        >
                          {callingModes.map((e) => {
                            return (
                              <MenuItem id={e} value={e}>
                                {e}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </SelectColumn>
                    <SelectColumn>
                      <Label>{getLocalizedText("select_team")}</Label>
                      <FormControl
                        sx={{ minWidth: 200 }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          value={user.team}
                          name="team"
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          required
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          style={{
                            width: "200px",
                            height: "30px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "0px",
                          }}
                        >
                          {teamList.map((e) => {
                            return (
                              <MenuItem id={e.id} value={e.id}>
                                {e.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </SelectColumn>
                  </Row>
                </InputContainer>
                <ButtonWrapper>
                  <Button
                    onClick={handleClose}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      marginRight: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {getLocalizedText("submit_details")}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(EditProjectMember);
