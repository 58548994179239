import {
  Airplay,
  Dvr,
  FormatListBulleted,
  FormatListNumberedRtl,
  LocalOffer,
  People,
  Settings,
  SettingsInputAntenna,
  ViewColumn,
  Work,
} from "@material-ui/icons";
import { Diversity1, MoveDown, ViewCozy } from "@mui/icons-material";

const pageSize = 25;
const sizes = [25, 50, 100];
let features = "unknown";
let app_id = process.env.REACT_APP_ID;
let app_version = "2.0.3630.20240130";
let device_os = "web";
const refetch_time = 15;
const domainName = ".flexagn.com";
const columnWidth = {
  small: 100,
  medium: 150,
  large: 250,
};
const StackedBarConfig = {
  options: {
    plugins: {
      title: {
        display: false,
        text: "Chart.js Bar Chart - Stacked",
      },
      legend: {
        position: "right",
        labels: {
          // Use the legendCallback function to customize the legend labels
          generateLabels: (chart) => {
            const getLegendData = (label) => {
              let total = 0;
              let index = chart.data.datasets.findIndex(
                (dataset) => dataset.label === label
              );
              if (index !== -1) {
                let dataset = chart.data.datasets[index];
                dataset.data.forEach((value) => (total += value));
              }

              return total;
            };

            return chart.data.datasets.map((data, index) => {
              const meta = chart.getDatasetMeta(index);
              return {
                text: `${data.label}: ${getLegendData(data.label)}`,
                fillStyle: data.backgroundColor[0],
                hidden: !meta.visible,
                index: index,
                datasetIndex: index,
              };
            });
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  },
};

const AdminProjectMenuList = [
  {
    name: "Settings",
    path: "/project-administration/settings",
    icon: <Settings />,
  },
  {
    name: "Members",
    path: "/project-administration/members",
    icon: <People />,
  },
  {
    name: "Teams",
    path: "/project-administration/teams",
    icon: <Diversity1 />,
  },
  {
    name: "Sources",
    path: "/project-administration/sources",
    icon: <SettingsInputAntenna />,
  },
  {
    name: "Stages",
    path: "/project-administration/stages",
    icon: <FormatListNumberedRtl />,
  },
  {
    name: "Statuses",
    path: "/project-administration/statuses",
    icon: <FormatListBulleted />,
  },
  {
    name: "Stage-Status Settings",
    path: "/project-administration/statustostage",
    icon: <MoveDown />,
  },
  {
    name: "Columns",
    path: "/project-administration/columns",
    icon: <ViewColumn />,
  },
  // {
  //   name: "Column Groups",
  //   path: "/project-administration/columngroups",
  //   icon: <ViewColumn />,
  // },
  {
    name: "Jobs",
    path: "/project-administration/jobs",
    icon: <Work />,
  },
  {
    name: "Predefined Deals",
    path: "/project-administration/predefined-deals",
    icon: <LocalOffer />,
  },
  {
    name: "Views",
    icon: <ViewCozy />,
    submenu_list: [
      {
        name: "View Tabs",
        path: "/project-administration/view-tabs",
        icon: <Dvr />,
      },
      {
        name: "View Groups",
        path: "/project-administration/view-groups",
        icon: <Airplay />,
      },
    ],
  },
];

const TeamLeadAccessNameList = ["Settings", "Jobs"];

const autoDialerCampaignTypeList = [
  { label: "Agentwise - Equally", value: "agent_wise" },
];

const LeadFilterTypeList = [
  { label: "All Leads", key: "all_leads" },
  { label: "Leads Created Between", key: "leads_created_between" },
  { label: "Leads Updated Between", key: "leads_updated_between" },
];

const formBuilderConfig = {
  settings: {
    disableNavigationArrows: true,
    disableProgressBar: true,
    disableWheelSwiping: true,
    showQuestionsNumbers: false,
  },
  blocks: [
    {
      id: "",
      name: "",
      attributes: {
        label: "",
        description: "",
      },
      innerBlocks: [],
    },
  ],
};

const welcomeScreenConfig = {
  name: "welcome-screen",
  attributes: {
    buttonText: "Let's start!",
    nextBtnLabel: false,
    classnames: "",
    description: "",
    label: "",
    customHTML: "",
    layout: "stack",
    attachmentFocalPoint: {
      x: 0.5,
      y: 0.5,
    },
    attachmentFancyBorderRadius: false,
    attachmentBorderRadius: "0px",
    attachmentMaxWidth: "none",
  },
};

const groupConfig = {
  name: "group",
  attributes: {
    nextBtnLabel: false,
    classnames: "",
    description: "",
    label: "",
    customHTML: "",
    layout: "stack",
    attachmentFocalPoint: {
      x: 0.5,
      y: 0.5,
    },
    attachmentFancyBorderRadius: false,
    attachmentBorderRadius: "0px",
    attachmentMaxWidth: "none",
  },
};

const smartViewRoleList = [
  {
    label: "Manager",
    value: "manager",
  },
  {
    label: "Lead",
    value: "lead",
  },
  {
    label: "Member",
    value: "member",
  },
];

const pageSchemaConfig = {
  top_section: {
    heading: "Personal Loans",
    key_points: [
      "Personal Loans upto 50Lakhs",
      "Cheapest Rate of Interest",
      "Instant Approval",
      "Quick and Easy Process",
    ],
    bg: "https://djr5js929hkg.cloudfront.net/4b2b1578-b5b3-4dbb-ad6f-1a35a844d6f1/static/media/bg1.7fd1c744e6b1d8766b90.jpg",
  },
  about_section: {
    heading: "Key Features",
    card_content: [
      {
        icon_type: "calendar",
        title: "Extend upto 5 years",
      },
      {
        icon_type: "percent",
        title: "1.33% per month",
      },
      {
        icon_type: "rupee",
        title: "5,000 to 5,00,000",
      },
    ],
  },
  bottom_section: {
    heading: "Money View Personal loans:",
    key_points: [
      "Complete Digital Process",
      "Faster Disbursal",
      "Anywhere in the country",
    ],
  },
  footer: "Copyright© 2023 Vibe Fintech | Money View. All Rights Reserved",
  form_id: "b3077d1d-e0b7-442e-bca1-e1421e749b3f",
};

const colorsList = [
  "#ff0000",
  "#00ff00",
  "#0000ff",
  "#ff9900",
  "#ff00ff",
  "#00ffff",
  "#ff3300",
  "#33ff00",
  "#0033ff",
  "#ffcc00",
  "#cc00ff",
  "#00ccff",
  "#ff66ff",
  "#66ff33",
  "#3366ff",
  "#ff3366",
  "#ccff66",
  "#6633ff",
  "#ff6633",
  "#99ff66",
  "#9966ff",
  "#ff9966",
  "#66ff99",
  "#ff3399",
  "#9933ff",
];

export {
  pageSize,
  columnWidth,
  features,
  app_id,
  app_version,
  device_os,
  sizes,
  StackedBarConfig,
  refetch_time,
  AdminProjectMenuList,
  TeamLeadAccessNameList,
  autoDialerCampaignTypeList,
  LeadFilterTypeList,
  formBuilderConfig,
  welcomeScreenConfig,
  groupConfig,
  smartViewRoleList,
  pageSchemaConfig,
  colorsList,
  domainName,
};
