import React, { useEffect } from "react";
import { Box, Dialog } from "@mui/material";
import { Close } from "@material-ui/icons";
import { styled } from "@mui/material/styles";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { IconButton } from "@material-ui/core";

const Container = styled(Box)`
  width: 210mm;
  margin: 0 auto;
  ${`height: calc(100% - 32px);`};
`;

function PreviewPdfDialog(props) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    window.addEventListener("contextmenu", handleContextMenu);
    return () => {
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullScreen
      style={{ width: "fit-content", margin: "auto", overflowY: "hidden" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
          padding: "8px 12px",
          paddingBottom: "0px",
        }}
      >
        <IconButton onClick={handleClose} style={{ padding: "0px" }}>
          <Close />
        </IconButton>
      </Box>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
        <Container>
          <Viewer
            defaultScale={1}
            fileUrl={props.file}
            plugins={[defaultLayoutPluginInstance]}
          />
        </Container>
      </Worker>
    </Dialog>
  );
}

export default PreviewPdfDialog;
