import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { daysList } from "../../Db";
import { Box, Checkbox, Divider, FormControlLabel } from "@material-ui/core";
import { getAdminProjectDataApi } from "../../Api";
import { IsoToLocalTime } from "../../Functions";
import moment from "moment";
import { Edit } from "@material-ui/icons";
import AddProjectModal from "../admin_setting_modals/AddProjectModal";
import EditWorkSchedule from "../admin_setting_modals/EditWorkSchedule";
import rootStore from "../../stores/RootStore";
import analytics from "../../Analytics";

const Container = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 258px);`};

  position: relative;
  /* margin-top: 70px; */
`;
const Wrapper = styled.div`
  display: flex;
`;

const InfoWrapper = styled.div`
  width: 200px;
  height: fit-content;
  margin-bottom: 12px;
`;
const SectionWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  background-color: #f9f9fc;
  padding: 20px;
`;
const Label = styled.div`
  font: normal normal 600 12px Open Sans;
  margin-bottom: 8px;
  color: #979799;
`;
const Detail = styled.div`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const SectionsContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  height: 100%;
  column-gap: 20px;
  row-gap: 20px;
`;
const DetailsHeader = styled.span`
  font: normal normal 600 16px Open Sans;
  color: #979797;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 20px;
`;

const ProjectSettingTab = (props) => {
  const { userStore, authStore } = rootStore;
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editWork, setEditWork] = useState(false);

  useEffect(() => {
    const getProjectData = async () => {
      try {
        let response = await getAdminProjectDataApi(props.id);
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getProjectData();
  }, [refresh]);

  const getLocalTimeFromIso = (isoDateString) => {
    if (isoDateString !== null && isoDateString !== undefined) {
      return moment.utc(isoDateString).local().format("h:mm A");
    } else {
      return "-";
    }
  };
  const canEditDetails = () => {
    let account_role =
      userStore.UserData !== null ? userStore.UserData.role : "";
    let project_role = authStore.getCurrentProjectRole();
    if (account_role === "owner") {
      return true;
    } else {
      if (project_role !== "member" && project_role !== "") {
        return true;
      }
      return false;
    }
  };
  return (
    <>
      <Container>
        <Wrapper>
          <SectionsContainer>
            <SectionWrapper>
              <HeaderWrapper>
                <DetailsHeader>{`BASIC INFORMATION`}</DetailsHeader>
                {canEditDetails() && (
                  <Edit
                    color="primary"
                    style={{
                      cursor: "pointer",
                      height: "20px",
                      width: "20px",
                    }}
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000168,
                        "edit_details_button_tap",
                        "project_setting_page",
                        "edit_details_button",
                        {}
                      );
                      setIsEdit(true);
                      setOpen(true);
                    }}
                  />
                )}
              </HeaderWrapper>
              <Row style={{ flexWrap: "wrap", rowGap: "20px" }}>
                <InfoWrapper>
                  <Label>Name</Label>
                  <Detail>{data !== null ? data["name"] : "-"}</Detail>
                </InfoWrapper>
                <InfoWrapper>
                  <Label>Created At</Label>
                  <Detail>
                    {data !== null ? IsoToLocalTime(data["created_at"]) : "-"}
                  </Detail>
                </InfoWrapper>
                <InfoWrapper>
                  <Label>Date Format</Label>
                  <Detail>{data !== null ? data["date_format"] : "-"}</Detail>
                </InfoWrapper>
                <InfoWrapper>
                  <Label>Dialer</Label>
                  <Detail>
                    {data !== null ? data["external_dialer"] : "-"}
                  </Detail>
                </InfoWrapper>
                <InfoWrapper>
                  <Label>Calling Mode</Label>
                  <Detail>{data !== null ? data["calling_mode"] : "-"}</Detail>
                </InfoWrapper>
              </Row>
            </SectionWrapper>
            <Column>
              <SectionWrapper>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "10px",
                  }}
                >
                  <DetailsHeader style={{ marginRight: "10px" }}>
                    Work Days
                  </DetailsHeader>
                  {canEditDetails() && (
                    <Edit
                      color="primary"
                      style={{
                        cursor: "pointer",
                        height: "20px",
                        width: "20px",
                      }}
                      onClick={() => {
                        analytics.triggerEvent(
                          4625000169,
                          "edit_work_schedule_button_tap",
                          "project_setting_page",
                          "edit_work_schedule_button",
                          {}
                        );
                        setEditWork(true);
                      }}
                    />
                  )}
                </Box>
                {data !== null && (
                  <InfoWrapper style={{ width: "100%", marginBottom: "0px" }}>
                    <Row>
                      {daysList.map((item, i) => {
                        return (
                          <FormControlLabel
                            key={i}
                            checked={data["workday_" + item["value"]] === true}
                            control={<Checkbox size="small" />}
                            label={<Detail>{item["name"]}</Detail>}
                          />
                        );
                      })}
                    </Row>
                  </InfoWrapper>
                )}
              </SectionWrapper>
              <Divider />
              <SectionWrapper>
                <DetailsHeader style={{ marginBottom: "10px" }}>
                  Work Timings
                </DetailsHeader>
                <Row>
                  <InfoWrapper>
                    <Label>Start Time</Label>
                    <Detail>
                      {data !== null
                        ? getLocalTimeFromIso(data["work_start_time"])
                        : "-"}
                    </Detail>
                  </InfoWrapper>
                  <InfoWrapper>
                    <Label>End Time</Label>
                    <Detail>
                      {data !== null
                        ? getLocalTimeFromIso(data["work_end_time"])
                        : "-"}
                    </Detail>
                  </InfoWrapper>
                </Row>
              </SectionWrapper>
            </Column>
          </SectionsContainer>
        </Wrapper>
      </Container>
      {open && (
        <AddProjectModal
          setOpen={setOpen}
          open={open}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          editableData={data}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      {editWork && (
        <EditWorkSchedule
          setOpen={setEditWork}
          open={editWork}
          data={data}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default ProjectSettingTab;
