import React from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { Grid } from "@material-ui/core";
import { IsoToLocalTime, dataRenderer } from "../../Functions";
import { Circle } from "@mui/icons-material";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ViewActivityInfoPanel = ({ data }) => {
  const renderListOfStrings = (str) => {
    let list = str.split("\n");
    if (list.length > 0) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {list.map((item) => {
            return (
              <Box sx={{ display: "flex", columnGap: "8px" }}>
                <Circle
                  style={{
                    width: "8px",
                    height: "8px",
                    marginTop: "6px",
                    color: "gray",
                  }}
                />
                <Detail>{item}</Detail>
              </Box>
            );
          })}
        </Box>
      );
    }
    return "-";
  };
  return (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Activity Details</ModelHeader>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Performed By</Label>
              <Detail>
                {data["user"] !== null
                  ? dataRenderer(data["user"]["name"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Activity Type</Label>
              <Detail>{dataRenderer(data["activity_type"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Time of Activity</Label>
              <Detail>
                {data["activity_performed_at"] !== null
                  ? IsoToLocalTime(data["activity_performed_at"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <Label>Description</Label>
              <Detail>
                {data["activity_desc"] !== null
                  ? renderListOfStrings(data["activity_desc"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <Label>Additional Comments</Label>
              {data["additional_comments"] !== null &&
              data["additional_comments"].length > 0 ? (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {data["additional_comments"].map((item) => {
                    return (
                      <Box sx={{ display: "flex", columnGap: "8px" }}>
                        <Circle
                          style={{
                            width: "8px",
                            height: "8px",
                            marginTop: "6px",
                            color: "gray",
                          }}
                        />
                        <Detail>{item}</Detail>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Detail>-</Detail>
              )}
            </InputWrapper>
          </Grid>
        </Grid>
      </ModelWrapper>
    </Box>
  );
};

export default ViewActivityInfoPanel;
