import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import styled from "styled-components";
import {
  getSummaryBarData,
  getWidgetDataApi,
  removeWidgetApi,
  removeWidgetApiv2,
} from "../../../Api";
import { useEffect } from "react";
import { useState } from "react";
import { StackedBarConfig } from "../../../Config";
import rootStore from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CalendarToday, Fullscreen } from "@material-ui/icons";
import FullScreenModal from "../../full_screen_modal/FullScreenModal";
import { Menu, MenuItem } from "@mui/material";

Chart.register(ArcElement);
const emptyData = {
  labels: ["Season1", "Season2", "Season3"],
  // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
  datasets: [
    {
      label: "color1",
      data: [95, 23, 96],
      // you can set indiviual colors for each bar
      backgroundColor: ["#ffbb11", "#ffbb11", "#ffbb11", "#f3ba2f", "#2a71d0"],
      borderWidth: 1,
    },
    {
      label: "color2",
      data: [75, 63, 96],
      // you can set indiviual colors for each bar
      backgroundColor: ["#ecf0f1", "#ecf0f1", "#ecf0f1", "#f3ba2f", "#2a71d0"],
      borderWidth: 1,
    },
    {
      label: "color3",
      data: [85, 23, 36],
      // you can set indiviual colors for each bar
      backgroundColor: ["#50AF95", "#50AF95", "#50AF95", "#f3ba2f", "#2a71d0"],
      borderWidth: 1,
    },
  ],
};
const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: ${(e) => (e.type === "preview" ? "0px" : "20px")};
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
`;
const ChartTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.p`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Text2 = styled.p`
  font: normal normal 500 12px Open Sans;
  margin: 0px;
  text-align: left;
  color: #bbbab6;
  margin-right: 8px;
`;
const ITEM_HEIGHT = 48;
const StackedBarGraphWidget = (props) => {
  const { userStore } = rootStore;
  const [data, setData] = useState();
  const [title, setTitle] = useState();
  const [open, setOpen] = useState(false);
  const [showTop, setShowTop] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dateRange, setDateRange] = useState("");

  const Open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRemove = async () => {
    try {
      let response = await removeWidgetApiv2({ id: props.data.id });
      setAnchorEl(null);
      props.setRefresh(!props.refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const generateRandomColor = (val) => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    userStore.updateColorsList({ key: val, color: color });
    return color;
  };
  const updateBgList = (val, wrapper) => {
    let resultObj = {};
    if (wrapper.length > 0) {
      wrapper.map((item) => {
        if (val.length > 0) {
          val.map((key) => {
            if (!resultObj.hasOwnProperty(key["name"])) {
              resultObj[key["name"]] = [];
              if (userStore.ColorsList.hasOwnProperty(key["name"])) {
                let colorVal = userStore.ColorsList[key["name"]];
                resultObj[key["name"]].push(colorVal);
              } else {
                let colorVal = generateRandomColor(key["name"]);
                resultObj[key["name"]].push(colorVal);
              }
            } else {
              if (userStore.ColorsList.hasOwnProperty(key["name"])) {
                let colorVal = userStore.ColorsList[key["name"]];
                if (resultObj[key["name"]].includes(colorVal)) {
                  resultObj[key["name"]].push(colorVal);
                }
              } else {
                let colorVal = generateRandomColor(key["name"]);
                if (resultObj[key["name"]].includes(colorVal)) {
                  resultObj[key["name"]].push(colorVal);
                }
              }
            }
          });
        }
      });
    } else {
      resultObj = {};
    }
    return resultObj;
  };
  const updateData = (val) => {
    let newObj = {
      labels: [],
      datasets: [],
    };

    let tempList = [];
    let labelList = [];
    let checkData = val["data"];
    // console.log("checkData :", typeof Object.keys(checkData)[0]);
    if (Object.keys(checkData).length > 0) {
      let group_first = Object.keys(props.data.xdata)[0];
      let group_second = Object.keys(props.data.xdata)[1];
      // console.log("group first :", group_first, "group second :", group_second);
      let search_value_first = props.data.xdata[group_first];
      let search_value_second = props.data.xdata[group_second];
      let meta_first = val["xdata"][search_value_first];
      let meta_second = val["xdata"][search_value_second];
      let tempColorList = updateBgList(meta_second, meta_first);
      // console.log("meta first :", meta_first, "meta second :", meta_second);
      if (meta_first.length > 0) {
        meta_first.map((item) => {
          newObj.labels.push(item["name"]);
        });
      }
      if (meta_second.length > 0) {
        meta_second.map((item) => {
          labelList.push(item["name"]);
        });
      }
      if (meta_second.length > 0) {
        meta_second.map((item) => {
          let tempObj = {};
          let dataList = [];
          let colorList = [];

          if (meta_first.length > 0) {
            meta_first.map((key) => {
              let arrString = `["${item["id"]}", "${key["id"]}"]`;
              // console.log(checkData.hasOwnProperty(arrString));
              if (checkData.hasOwnProperty(arrString)) {
                dataList.push(checkData[arrString]);
              } else {
                dataList.push(0);
              }
            });
          }

          tempObj["data"] = dataList;
          // tempColorList.push(colorList);
          tempList.push(tempObj);
        });
      }
      // console.log("temp_list :", tempList.length, labelList.length);
      if (labelList.length > 0) {
        labelList.map((item, i) => {
          let new_object = {};
          new_object["label"] = item;
          if (tempList.length > 0) {
            // console.log("temp_list data:", tempList[i]["data"]);
            new_object["data"] = tempList[i]["data"];
          }
          new_object["backgroundColor"] = tempColorList[item];

          new_object["borderWidth"] = 1;
          newObj.datasets.push(new_object);
        });
      }
    } else {
      newObj = {
        labels: ["no data"],
        datasets: [
          {
            label: ["no data"],
            data: [100],
            backgroundColor: [
              "rgba(54, 162, 235, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(54, 162, 235, 0.2)",
            ],
            hoverBackgroundColor: [
              "rgba(54, 162, 235, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(54, 162, 235, 1)",
            ],
          },
        ],
      };
    }

    // console.log("stacked result data is :", newObj);
    return newObj;
  };
  const ChartBottomSection = () => {
    if (props.type === "preview") {
      return (
        <Wrapper>
          <Bar data={emptyData} options={StackedBarConfig.options} />
        </Wrapper>
      );
    } else if (props.type !== "preview" && data) {
      return (
        <Wrapper>
          <Bar data={data} options={StackedBarConfig.options} />
        </Wrapper>
      );
    }
  };
  useEffect(() => {
    const getData = async () => {
      try {
        let response = await getWidgetDataApi({ id: props.data.id });
        let modifiedData = updateData(response.data);
        // setTitle(response.data.title);
        setData(modifiedData);
        if (props.data.rolling_date) {
          setDateRange(props.data.rolling_date_string);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (props.type !== "preview") {
      getData();
    }
    // console.log("bar data", data);
  }, []);
  return (
    <>
      {data ? (
        <Container type={props.type}>
          {props.type === "preview" ? (
            <Text style={{ textAlign: "center" }}>Preview</Text>
          ) : (
            <ChartTop>
              <Text>{props.data.title}</Text>
              <IconWrapper>
                <CalendarToday
                  style={{
                    width: "18px",
                    height: "18px",
                    marginRight: "8px",
                    color: "#BBBAB6",
                  }}
                />
                <Text2>{props.data.rolling_date && dateRange}</Text2>
                <IconButton
                  style={{ padding: "0px" }}
                  onClick={() => {
                    setShowTop(false);
                    setOpen(true);
                  }}
                >
                  <Fullscreen />
                </IconButton>
                <IconButton
                  onClick={handleClick}
                  aria-label="more"
                  id="long-button"
                  aria-controls={Open ? "long-menu" : undefined}
                  aria-expanded={Open ? "true" : undefined}
                  aria-haspopup="true"
                  style={{ padding: "0px", marginRight: "-10px" }}
                >
                  <MoreVertIcon />
                </IconButton>
              </IconWrapper>
            </ChartTop>
          )}
          <ChartBottomSection />
        </Container>
      ) : (
        <Container type={props.type}>
          {props.type === "preview" ? (
            <Text style={{ textAlign: "center" }}>Preview</Text>
          ) : (
            <ChartTop>
              <Text></Text>
              <IconWrapper>
                <IconButton
                  style={{ padding: "0px" }}
                  onClick={() => {
                    setShowTop(false);
                    setOpen(true);
                  }}
                >
                  <Fullscreen />
                </IconButton>
                <IconButton
                  onClick={handleClick}
                  aria-label="more"
                  id="long-button"
                  aria-controls={Open ? "long-menu" : undefined}
                  aria-expanded={Open ? "true" : undefined}
                  aria-haspopup="true"
                  style={{ padding: "0px", marginRight: "-10px" }}
                >
                  <MoreVertIcon />
                </IconButton>
              </IconWrapper>
            </ChartTop>
          )}
          <ChartBottomSection />
        </Container>
      )}
      {open && !showTop && (
        <FullScreenModal
          open={open}
          setOpen={setOpen}
          myComponent={ChartBottomSection}
          setShowTop={setShowTop}
        />
      )}
      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
          },
        }}
      >
        <MenuItem style={{ fontSize: "12px" }} onClick={handleRemove}>
          Remove
        </MenuItem>
      </Menu>
    </>
  );
};

export default observer(StackedBarGraphWidget);
