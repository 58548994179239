import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Tooltip,
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";

import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import QuickCreateContact from "../../components/contact_forms_sidepanl/QuickCreateContact";
import { getContactsListApi } from "../../Api";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { useNavigate } from "react-router-dom";
import ExpandableSearchbar from "../../components/expandable_searchbar/ExpandableSearchbar";
import { FormattedAgGridColumns } from "../../ColumnUtils";
import { dataRenderer } from "../../Functions";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 75px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;

const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 276px);`};

  background-color: white;
  padding: 20px;
  padding-bottom: 0px;
`;

const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;

const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;

const requiredColumns = ["phone", "city", "email", "title"];

const ContactsPage = () => {
  const navigate = useNavigate();
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openCreateQuickContact, setOpenCreateQuickContact] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [columns, setColumns] = useState([]);

  const getContactsList = async ({ query }) => {
    setLoadingData(true);
    let payload = query !== "" ? { query: query } : null;
    let response = await getContactsListApi({
      page: rootStore.contactStore.listingInfo.page_number,
      page_size: rootStore.contactStore.listingInfo.page_size,
      payload: payload,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      rootStore.contactStore.updateCount(response.item_count);
    } else {
      setRow([]);
      rootStore.contactStore.updateCount(0);
    }
    setLoadingData(false);
  };
  const getContactColumns = async () => {
    let filteredColumns = [...rootStore.contactStore.columnsList].filter(
      (column) => requiredColumns.includes(column["column_name"])
    );
    let formattedColumns = FormattedAgGridColumns(filteredColumns);

    setColumns(formattedColumns);
  };

  const init = async () => {
    setLoading(true);
    await getContactsList({
      query: searchQuery,
    });
    await getContactColumns();
    setLoading(false);
  };

  useEffect(() => {
    if (refresh === true) {
      getContactsList({
        query: searchQuery,
      });
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handlePageSize = async (e) => {
    rootStore.contactStore.udpatePageNumber(0);
    rootStore.contactStore.updatePageSize(e.target.value);
    await getContactsList({
      query: searchQuery,
    });
  };
  const handlePagination = async (e, page) => {
    rootStore.contactStore.udpatePageNumber(page - 1);

    await getContactsList({
      query: searchQuery,
    });
  };

  const handleSearchQuery = async (query) => {
    rootStore.contactStore.udpatePageNumber(0);
    rootStore.contactStore.updatePageSize(pageSize);
    await getContactsList({
      query: query,
    });
  };

  const nameColumn = [
    {
      field: "name",
      headerName: "NAME",

      width: 230,
      cellRenderer: (params) => {
        let parts = [params.data.first_name, params.data.last_name].filter(
          Boolean
        );
        let fullName = parts.length ? parts.join(" ") : "-";
        return (
          <>
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Tooltip title={fullName}>
                <TextHighlight>{fullName}</TextHighlight>
              </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];

  const creatorColumn = [
    {
      field: "creator",
      headerName: "CREATED BY",
      flex: 1,
      minWidth: 230,
      cellRenderer: (params) => {
        let value =
          params.data.hasOwnProperty("creator") &&
          params.data["creator"] !== null
            ? dataRenderer(params.data["creator"]["name"])
            : "-";
        return (
          <>
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Tooltip title={value}>
                <Typography>{value}</Typography>
              </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];

  return !loading ? (
    <>
      <ParentContainer>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              marginY: "16px",
            }}
          >
            <TopWrapper>
              <Header>Contacts</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
            </TopWrapper>

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "16px",
              }}
            >
              <ExpandableSearchbar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleSearch={(query) => {
                  handleSearchQuery(query);
                }}
              />

              <Button
                onClick={() => {
                  setOpenCreateQuickContact(true);
                }}
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "160px",
                  height: "40px",
                }}
              >
                Create Contact
              </Button>
            </Box>
          </Box>

          {!loadingData ? (
            <TableWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={nameColumn.concat(columns).concat(creatorColumn)}
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  paginationPageSize={
                    rootStore.contactStore.listingInfo.page_size
                  }
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    navigate(`/contacts/${row.data.id}`);
                  }}
                  overlayNoRowsTemplate={"No Contacts found."}
                />
              </div>
            </TableWrapper>
          ) : (
            <TableWrapper
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </TableWrapper>
          )}
          <PaginationWrapper>
            <PaginationComponent
              page_no={rootStore.contactStore.listingInfo.page_number}
              row={row}
              page_size={rootStore.contactStore.listingInfo.page_size}
              size={rootStore.contactStore.listingInfo.page_size}
              count={rootStore.contactStore.listingInfo.count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </PaginationWrapper>
        </Container>
      </ParentContainer>
      <Footer />
      {openCreateQuickContact && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreateQuickContact}
          onClose={() => {
            setOpenCreateQuickContact(false);
          }}
        >
          <QuickCreateContact
            setRefresh={setRefresh}
            setOpen={setOpenCreateQuickContact}
            open={openCreateQuickContact}
          />
        </Drawer>
      )}
    </>
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(ContactsPage);
