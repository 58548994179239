import React, { useEffect, useState } from "react";
import styled from "styled-components";
import spinner from "../../assets/images/gifs/spinner.gif";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import rootStore from "../../stores/RootStore";
import LeadAssignModal from "../../components/lead_assign_modal/LeadAssignModal";
import ColumnSelectionModal from "../../components/column_selection_modal/ColumnSelectionModal";
import { observer } from "mobx-react-lite";
// import Pagination from "../pagination/Pagination";
import { pageSize } from "../../Config";
import {
  getFilterColsApi,
  getFilteredLeadsApi,
  getNotificationsDetailApi,
} from "../../Api";
// import PageSizeDropdown from "../page_size_dropdown/PageSizeDropdown";
import { Pagination, PaginationItem } from "@mui/material";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import Footer from "../../components/landing_page_components/footer/Footer";
import { Assignment, ChevronRight } from "@material-ui/icons";
import { PersonAddAlt } from "@mui/icons-material";
import { checkFeaturePermission } from "../../Functions";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const ParentContainer = styled.div`
  ${`min-height: calc(100vh - 148px);`};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9fc;
  justify-content: center;
  margin-top: 70px;
  width: 100%;
`;
const ParentWrapper = styled.div`
  display: flex;
  justify-content: ${(e) => (e.params ? "flex-end" : "space-between")};
  width: 95%;
  min-height: 40px;
`;
const Container = styled.div`
  height: fit-content;
  background-color: white;
  width: 95%;
  margin: auto;
  /* padding: 12px; */
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 400px;
  flex-direction: column;
  margin: auto;
`;

const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const get_leads_url = process.env.REACT_APP_API_BASE_URL;

const NotificationSection = (props) => {
  const [row, setRow] = useState();
  const [assignState, setAssignState] = useState(0);
  const { type, title } = useParams();
  const [openDrawer, setOpenDrawer] = useState(false);

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState();
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);

  let navigate = useNavigate();

  const [refresh, setRefresh] = useState(false);
  let uid = localStorage.getItem("uid");
  const [leads, setLeads] = useState([]);
  const { authStore, userStore } = rootStore;
  let pid = authStore.projectId;
  let columns = userStore.FilterColumnHeaders;
  const [size, setSize] = useState(pageSize);
  useEffect(() => {
    window.scrollTo(0, 0);
    setSize(pageSize);
    setRow();
    setLoading(true);
    setPage(0);
    const getNotificationDetails = async () => {
      try {
        let date = new Date();
        date.setHours(10);
        date.setMinutes(0);
        date = date.toUTCString();
        let data = { notification_type: type, start_time: date };
        let response = await getNotificationsDetailApi({
          page_size: size,
          page_no: 0,
          data: data,
        });
        setRow(response.data.items);
        setCount(response.data.item_count);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getNotificationDetails();
  }, [refresh]);
  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setRow();
    setLoading(true);
    setPage(0);
    let date = new Date();
    date.setHours(10);
    date.setMinutes(0);
    date = date.toUTCString();
    let data = { notification_type: type, start_time: date };
    try {
      let response = await getNotificationsDetailApi({
        page_size: e.target.value,
        page_no: page,
        data: data,
      });
      setRow(response.data.items);
      setCount(response.data.item_count);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);
    setRow();
    setLoading(true);
    let date = new Date();
    date.setHours(10);
    date.setMinutes(0);
    date = date.toUTCString();
    let data = { notification_type: type, start_time: date };

    try {
      let response = await getNotificationsDetailApi({
        page_size: size,
        page_no: page - 1,
        data: data,
      });
      setRow(response.data.items);
      setCount(response.data.item_count);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleBreadCrump = () => {
    navigate(-1);
  };

  useEffect(() => {
    authStore.updateLeadDetailStatus();
  }, []);

  return (
    <>
      <ParentContainer>
        <ParentWrapper>
          <TopWrapper>
            <Header>Notifications</Header>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Header>{title}</Header>
          </TopWrapper>
        </ParentWrapper>
        <ParentWrapper
          style={{ backgroundColor: "white", justifyContent: "flex-end" }}
        >
          <MenuWrapper>
            {checkFeaturePermission("notification_assign_lead") && (
              <IconButton
                disabled={assignState < 1}
                onClick={() => {
                  setOpen(true);
                }}
              >
                <Assignment style={{ cursor: "pointer" }} />
              </IconButton>
            )}
          </MenuWrapper>
        </ParentWrapper>
        <Container>
          <Wrapper>
            {row && columns.length > 0 ? (
              <>
                <TableWrapper>
                  <DataGrid
                    autoHeight={true}
                    rows={row}
                    columns={columns}
                    pageSize={size}
                    style={{ cursor: "pointer" }}
                    hideFooter={true}
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Array(ids);
                      setAssignState(selectedIDs[0].length);
                      setLeads(selectedIDs);
                    }}
                    loading={loading}
                    disableColumnMenu={true}
                    onRowClick={(e) => {
                      navigate(`/users/${uid}/leads/${e.id}`);
                    }}
                    checkboxSelection={true}
                  />
                </TableWrapper>
                <PaginationComponent
                  page_no={page}
                  row={row}
                  page_size={size}
                  size={size}
                  count={count}
                  handlePageSize={handlePageSize}
                  handlePage={handlePagination}
                />
              </>
            ) : (
              <SpinnerWrapper>
                <Spinner src={spinner} alt="loading..." />
              </SpinnerWrapper>
            )}
            {open && (
              <LeadAssignModal
                open={open}
                setOpen={setOpen}
                leads={leads}
                pid={pid}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            )}
          </Wrapper>
        </Container>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default observer(NotificationSection);
