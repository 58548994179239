import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getListApi } from "../../Api";
import styled from "styled-components";
import { FormControl, Select, makeStyles } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { getSlug } from "../../Functions";
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled.span`
  font: normal normal normal 10px Open Sans;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const SingleSelect = (props) => {
  const classes = useStyles();
  const [list, setList] = useState([]);
  const [listType, setListType] = useState(null);

  const init = async (slug) => {
    let response = await getListApi({ slug: slug });
    if (response !== null) {
      if (Array.isArray(response)) {
        setListType("list");
        setList(response);
      } else if (typeof response === "object") {
        if (Object.keys(response).length > 0) {
          setListType(response["type"]);
          setList(response["values"]);
        }
      }
    } else {
      setListType(null);
      setList([]);
    }
  };

  useEffect(() => {
    let slug = getSlug(props.item.filter_input);
    init(slug);
  }, [props.item.id]);
  return (
    <>
      <FormControl
        sx={{ width: "100%" }}
        classes={{
          root: classes.quantityRoot,
        }}
      >
        <Select
          classes={{
            icon: classes.icon,
          }}
          displayEmpty
          name={props.field}
          variant="outlined"
          onChange={(e) => props.handleChange(e)}
          style={{
            width: "100%",
            fontSize: "12px",
            height: "30px",
          }}
          value={
            props.selectedVal.hasOwnProperty(props.field)
              ? props.selectedVal[props.field]["value"]
              : ""
          }
          required={props.required}
        >
          {list.map((item, index) => {
            if (listType !== null) {
              if (listType === "list") {
                return (
                  <MenuItem id={index} key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              } else if (listType === "list_objects") {
                return (
                  <MenuItem id={index} key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                );
              }
            }
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default SingleSelect;
