import { styled } from "@mui/material/styles";
import { useMemo, useState } from "react";
import { useEffect } from "react";
import {
  getAdminStagesApi,
  getProjectAdministrationReOrederedStagesApi,
} from "../../Api";
import { AddBoxOutlined, Edit } from "@material-ui/icons";
import AddStageModal from "../admin_setting_modals/AddStageModal";
import analytics from "../../Analytics";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AdminStageColumns } from "../../Db";
import { Chip } from "@mui/material";

const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 262px);`};
  position: relative;
`;

const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 5px;
  top: 5px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
`;

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          style={{ fontSize: "10px" }}
          variant="caption"
          component="div"
          color="text.secondary"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const StagesTab = (props) => {
  const [row, setRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [loading, setLoading] = useState(true);
  const getStagesList = async () => {
    setRow([]);
    setLoading(true);
    try {
      let response = await getAdminStagesApi(props.id);
      setRow(response.data);
      setLoading(false);
    } catch (error) {
      setRow([]);
      setLoading(false);
      console.log(error);
    }
  };
  const init = async () => {
    await getStagesList();
  };
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    if (refresh) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  const getReOrderedStageList = async ({ payload }) => {
    setRow([]);
    setLoading(true);
    let response = await getProjectAdministrationReOrederedStagesApi({
      projectId: props.id,
      payload: payload,
    });
    setRow(response);
    setLoading(false);
  };

  const onRowDragEnd = async (event) => {
    const gridApi = event.api;
    let draggedItem = event.node.data;
    let newOrderList = [];
    gridApi.forEachNode((node, i) => {
      let tempObj = { id: node.data.id, rank: i + 1 };
      newOrderList.push(tempObj);
    });

    let prevIndex = row.findIndex((data) => data["id"] === draggedItem["id"]);
    let newIndex = newOrderList.findIndex(
      (data) => data["id"] === draggedItem["id"]
    );

    if (prevIndex !== newIndex) {
      await getReOrderedStageList({
        payload: {
          stage_ranks: newOrderList,
        },
      });
    }
  };
  const actionColumn = [
    {
      field: "probability",
      headerName: "PROBABILITY",
      width: 130,
      cellRenderer: (params) => {
        if (
          params.data.probability !== null &&
          params.data.probability !== ""
        ) {
          return <CircularProgressWithLabel value={params.data.probability} />;
        } else {
          return "-";
        }
      },
    },
    {
      field: "mark_as_won",
      headerName: "MARK AS WON",
      width: 130,
      cellRenderer: (params) => {
        if (
          params.data.mark_as_won !== undefined &&
          params.data.mark_as_won !== null &&
          params.data.mark_as_won !== ""
        ) {
          if (params.data.mark_as_won === true) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "mark_as_lost",
      headerName: "MARK AS LOST",
      width: 130,
      cellRenderer: (params) => {
        if (
          params.data.mark_as_lost !== undefined &&
          params.data.mark_as_lost !== null &&
          params.data.mark_as_lost !== ""
        ) {
          if (params.data.mark_as_lost === true) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "mark_as_closed",
      headerName: "MARK AS CLOSED",
      width: 130,
      cellRenderer: (params) => {
        if (
          params.data.mark_as_closed !== undefined &&
          params.data.mark_as_closed !== null &&
          params.data.mark_as_closed !== ""
        ) {
          if (params.data.mark_as_closed === true) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "action",
      headerName: "ACTIONS",
      minWidth: 70,
      flex: 1,
      resizable: false,
      cellRenderer: (params) => {
        if (params.data.default) {
          return (
            <Edit
              style={{
                marginRight: "20px",
                width: "18px",
                height: "18px",
              }}
              color="disabled"
            />
          );
        } else {
          return (
            <Edit
              onClick={() => {
                analytics.triggerEvent(
                  4625000203,
                  "edit_button_tap",
                  "project_stages_page",
                  "edit_button",
                  {}
                );
                setEditableData(params.data);
                setIsEdit(true);
                setOpen(true);
              }}
              style={{
                marginRight: "20px",
                width: "18px",
                height: "18px",
                cursor: "pointer",
              }}
              color="primary"
            />
          );
        }
      },
    },
  ];

  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));

  return (
    <>
      {!loading ? (
        <TableWrapper>
          <AddBtnWrapper>
            <AddBoxOutlined
              onClick={() => {
                analytics.triggerEvent(
                  4625000199,
                  "add_button_tap",
                  "project_stages_page",
                  "add_button",
                  {}
                );
                setOpen(true);
              }}
              style={{
                width: "40px",
                height: "40px",
              }}
            />
          </AddBtnWrapper>
          <div className="ag-theme-alpine" style={{ width: "100%" }}>
            <AgGridReact
              onRowDragEnd={onRowDragEnd}
              rowDragManaged={true}
              domLayout="autoHeight"
              suppressCellFocus
              gridOptions={gridOptions}
              loadingOverlayComponent={<CircularProgress />}
              rowData={row}
              defaultColDef={defaultColDef}
              columnDefs={AdminStageColumns.concat(actionColumn)}
              animateRows={true}
            />
          </div>
        </TableWrapper>
      ) : (
        <TableWrapper
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </TableWrapper>
      )}
      {open && (
        <AddStageModal
          id={props.id}
          setOpen={setOpen}
          open={open}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          editableData={editableData}
          refresh={refresh}
          setRefresh={setRefresh}
          stageList={row}
        />
      )}
    </>
  );
};
export default StagesTab;
