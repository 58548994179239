import React, { useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { Box, Divider, Modal, TextField } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import { Button, Grid } from "@mui/material";
import { useEffect } from "react";
import { DatePicker } from "antd";
import "./style.css";
import spinner from "../../assets/images/gifs/spinner.gif";
import {
  getAllColsApi,
  getFilterColsApi,
  getFilteredLeadsApi,
} from "../../Api";
import MultiselectDropdown from "../multiselect_dropdown/MultiselectDropdown";
import NewMultiselectDropdown from "../multiselect_dropdown/NewMultiselectDropdown";
import { useNavigate } from "react-router-dom";
import { pageSize } from "../../Config";
import ChildMultiSelectDropdown from "../multiselect_dropdown/ChildMultiSelectDropdown";
import { sortAndAssignWidthColumnSetup } from "../../Functions";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const Title = styled.span`
  font: normal normal 600 24px Open Sans;
  text-align: center;
`;
const Label = styled.span`
  font: normal normal normal 12px Open Sans;
  color: black;
`;
const Input = styled.input`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  color: #4d4e4f;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 12px Open Sans;
    color: #b5b5b5;
    text-transform: lowercase;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const Row = styled.div`
  display: flex;
  /* width: 100%;
  margin-bottom: 10px; */
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: 320px;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  overflow: hidden;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

// this component is being used as global filter as well as to create target segement
const ReportFilterComponent = (props) => {
  const { authStore, userStore } = rootStore;
  const { RangePicker } = DatePicker;

  const [allCols, setAllCols] = useState([]);
  const columnsList = userStore.AllColumnsList;

  let pid = authStore.projectId;
  let navigate = useNavigate();

  const handleChange = ({ e, dataType }) => {
    let value =
      dataType === "integer" ? parseInt(e.target.value) : e.target.value;
    props.setFilters({
      ...props.filters,
      [e.target.name]: { value: value, type: dataType },
    });
  };

  useEffect(() => {
    let column_meta = [...columnsList];
    if (column_meta.length > 0) {
      column_meta = column_meta.filter(
        (e) => e.field !== "lead_created_at" && e.field !== "created_at"
      );
    }
    let modified_list = sortAndAssignWidthColumnSetup(column_meta);
    setAllCols(modified_list);
  }, []);

  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }

  return (
    <>
      <Container>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Grid container spacing={2} style={{ width: "340px" }}>
            {allCols.length > 0 ? (
              allCols.map((item) => {
                if (item.filterable) {
                  if (
                    item.filter_type === "normal" ||
                    item.filter_type === ""
                  ) {
                    if (
                      (item.data_type === "date" ||
                        item.data_type === "datetime") &&
                      props.filters.hasOwnProperty(item.field)
                    ) {
                      let value = props.filters[item.field]["value"];
                      return (
                        <Grid item xs={item["width"] === 1 ? 6 : 12}>
                          <InputWrapper>
                            <Label>
                              {item["width"] === 1
                                ? truncate(item.headerName, 20)
                                : item.headerName}
                            </Label>
                            <Input
                              type={
                                item.data_type === "email"
                                  ? "email"
                                  : item.data_type === "datetime" ||
                                    item.data_type === "date"
                                  ? "date"
                                  : item.data_type === "integer"
                                  ? "number"
                                  : "text"
                              }
                              name={item.field}
                              value={value}
                              placeholder={`enter ${item.headerName}`}
                              //   style={{ width: "170px" }}
                              onChange={(e) => {
                                let dataType = item.data_type;
                                handleChange({ e, dataType });
                              }}
                            />
                          </InputWrapper>
                        </Grid>
                      );
                    }
                    return (
                      <Grid item xs={item["width"] === 1 ? 6 : 12}>
                        <InputWrapper>
                          <Label>
                            {item["width"] === 1
                              ? truncate(item.headerName, 20)
                              : item.headerName}
                          </Label>
                          <Input
                            type={
                              item.data_type === "email"
                                ? "email"
                                : item.data_type === "datetime" ||
                                  item.data_type === "date"
                                ? "date"
                                : item.data_type === "integer"
                                ? "number"
                                : "text"
                            }
                            name={item.field}
                            value={
                              props.filters.hasOwnProperty(item.field)
                                ? props.filters[item.field]["value"]
                                : ""
                            }
                            placeholder={`enter ${item.headerName}`}
                            //   style={{ width: "170px" }}
                            onChange={(e) => {
                              let dataType = item.data_type;
                              handleChange({ e, dataType });
                            }}
                          />
                        </InputWrapper>
                      </Grid>
                    );
                  } else if (item.filter_type === "range") {
                    if (
                      item.data_type === "datetime" ||
                      item.data_type === "date"
                    ) {
                      if (props.type === "edit") {
                        if (props.dateRange.hasOwnProperty(item.field)) {
                          return (
                            <Grid item xs={item["width"] === 1 ? 6 : 12}>
                              <InputWrapper style={{ width: "100%" }}>
                                <Label>
                                  {item["width"] === 1
                                    ? truncate(item.headerName, 20)
                                    : item.headerName}
                                  *
                                </Label>
                                <RangePicker
                                  placeholder={["From", "To"]}
                                  getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                  }}
                                  defaultValue={[
                                    moment(
                                      props.dateRange[item["field"]]["start"],
                                      "YYYY-MM-DD"
                                    ),
                                    moment(
                                      props.dateRange[item["field"]]["end"],
                                      "YYYY-MM-DD"
                                    ),
                                  ]}
                                  style={{ height: "30px", width: "100%" }}
                                  onChange={(value) => {
                                    let d1 = moment(value[0]).format(
                                      "YYYY-MM-DD"
                                    );
                                    let d2 = moment(value[1]).format(
                                      "YYYY-MM-DD"
                                    );
                                    props.setDateRange({
                                      ...props.dateRange,
                                      [item.field]: { start: d1, end: d2 },
                                    });
                                  }}
                                />
                              </InputWrapper>
                            </Grid>
                          );
                        } else {
                          return (
                            <Grid item xs={item["width"] === 1 ? 6 : 12}>
                              <InputWrapper style={{ width: "100%" }}>
                                <Label>
                                  {item["width"] === 1
                                    ? truncate(item.headerName, 20)
                                    : item.headerName}
                                </Label>
                                <RangePicker
                                  placeholder={["From", "To"]}
                                  getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                  }}
                                  defaultValue={
                                    props.dateRange.hasOwnProperty(
                                      item.field
                                    ) && [
                                      moment(
                                        props.dateRange[item["field"]]["start"],
                                        "YYYY-MM-DD"
                                      ),
                                      moment(
                                        props.dateRange[item["field"]]["end"],
                                        "YYYY-MM-DD"
                                      ),
                                    ]
                                  }
                                  style={{ height: "30px", width: "100%" }}
                                  onChange={(value) => {
                                    let d1 = moment(value[0]).format(
                                      "YYYY-MM-DD"
                                    );
                                    let d2 = moment(value[1]).format(
                                      "YYYY-MM-DD"
                                    );
                                    props.setDateRange({
                                      ...props.dateRange,
                                      [item.field]: { start: d1, end: d2 },
                                    });
                                  }}
                                />
                              </InputWrapper>
                            </Grid>
                          );
                        }
                      } else {
                        return (
                          <Grid item xs={item["width"] === 1 ? 6 : 12}>
                            <InputWrapper style={{ width: "100%" }}>
                              <Label>
                                {item["width"] === 1
                                  ? truncate(item.headerName, 20)
                                  : item.headerName}
                              </Label>
                              <RangePicker
                                placeholder={["From", "To"]}
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode;
                                }}
                                defaultValue={
                                  props.dateRange.hasOwnProperty(
                                    item.field
                                  ) && [
                                    moment(
                                      props.dateRange[item["field"]]["start"],
                                      "YYYY-MM-DD"
                                    ),
                                    moment(
                                      props.dateRange[item["field"]]["end"],
                                      "YYYY-MM-DD"
                                    ),
                                  ]
                                }
                                style={{ height: "30px", width: "100%" }}
                                onChange={(value) => {
                                  let d1 = moment(value[0]).format(
                                    "YYYY-MM-DD"
                                  );
                                  let d2 = moment(value[1]).format(
                                    "YYYY-MM-DD"
                                  );
                                  props.setDateRange({
                                    ...props.dateRange,
                                    [item.field]: { start: d1, end: d2 },
                                  });
                                }}
                              />
                            </InputWrapper>
                          </Grid>
                        );
                      }
                    } else if (item.data_type === "integer") {
                      return (
                        <Grid item xs={item["width"] === 1 ? 6 : 12}>
                          <InputWrapper>
                            <Label>
                              {item["width"] === 1
                                ? truncate(item.headerName, 20)
                                : item.headerName}
                            </Label>
                            <Row>
                              <Input
                                type="number"
                                name="start"
                                placeholder="min"
                                onChange={(e) => {
                                  let start = parseInt(e.target.value);
                                  props.setNumRange({
                                    ...props.numRange,
                                    [item.field]: { start: start },
                                  });
                                }}
                                style={{
                                  width: "70px",
                                  marginRight: "10px",
                                }}
                              />
                              <Input
                                type="number"
                                name="end"
                                placeholder="max"
                                onChange={(e) => {
                                  let start = parseInt(
                                    props.numRange[item.field]["start"]
                                  );
                                  let end = parseInt(e.target.value);
                                  props.setNumRange({
                                    ...props.numRange,
                                    [item.field]: {
                                      start: start,
                                      end: end,
                                    },
                                  });
                                }}
                                style={{ width: "70px" }}
                              />
                            </Row>
                          </InputWrapper>
                        </Grid>
                      );
                    }
                  } else if (item.filter_type === "list") {
                    if (
                      item.filter_input.charAt(0) === "/" ||
                      item.filter_input.startsWith("ep:")
                    ) {
                      return (
                        <MultiselectDropdown
                          type="report"
                          headerName={item.headerName}
                          setSelectedVal={props.setSelectedVal}
                          selectedVal={props.selectedVal}
                          field={item.field}
                          item={item}
                        />
                      );
                    } else if (item.filter_input.charAt(0) !== "/") {
                      return (
                        <Grid item xs={item["width"] === 1 ? 6 : 12}>
                          <NewMultiselectDropdown
                            type="report"
                            list={item.filter_input}
                            field={item.field}
                            headerName={item.headerName}
                            setSelectedVal={props.setSelectedVal}
                            selectedVal={props.selectedVal}
                            item={item}
                          />
                        </Grid>
                      );
                    }
                  }
                }
              })
            ) : (
              <SpinnerWrapper>
                <Spinner src={spinner} alt="loading..." />
              </SpinnerWrapper>
            )}
          </Grid>
        </Form>
      </Container>
    </>
  );
};

export default observer(ReportFilterComponent);
