import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import styled from "styled-components";
import "./style.css";
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
  position: relative;
`;
const NestedChildMultiSelectFilter = (props) => {
  const [list, setList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelect = (selected) => {
    setSelectedOptions(selected);
    let newList = [];
    selected.map((val) => {
      newList.push(val.value);
    });
    props.setSelectedVal({
      ...props.selectedVal,
      [props.field]: newList,
    });
    props.setFilterList({
      ...props.filterList,
      [props.field]: {
        value: newList,
        type: props.filterType,
        dataType: props.dataType,
      },
    });
  };

  const generateGroupedList = (list) => {
    let newList = [];
    Object.keys(list).forEach((key) => {
      let tempObj = { label: key, options: [] };

      list[key].forEach((item) => {
        let optionObj = { label: item, value: item };
        tempObj.options.push(optionObj);
      });
      newList.push(tempObj);
    });
    return newList;
  };

  useEffect(() => {
    const getList = async () => {
      let generatedList = generateGroupedList(props.list);
      setList(generatedList);
    };
    getList();
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      maxHeight: "150px",
      overflowY: "auto",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
    }),
  };

  return (
    <>
      {list.length > 0 && (
        <InputWrapper type={props.type}>
          <Select
            options={list}
            value={selectedOptions}
            onChange={handleSelect}
            isMulti
            closeMenuOnSelect={false}
            styles={customStyles}
          />
        </InputWrapper>
      )}
    </>
  );
};

export default NestedChildMultiSelectFilter;
