import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import styled from "styled-components";
import { getWidgetDataApiv2, removeWidgetApiv2 } from "../../../Api";
import { useEffect } from "react";
import { useState } from "react";
import rootStore from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { CircularProgress, IconButton, Typography } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Fullscreen } from "@material-ui/icons";
import FullScreenModal from "../../full_screen_modal/FullScreenModal";
import { Menu, MenuItem } from "@mui/material";
import { getStageColorCode, getStatusColorCode } from "../../../Functions";
Chart.register(ArcElement);

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: ${(e) => (e.type === "preview" ? "0px" : "20px")};
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
`;
const ChartTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.p`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ITEM_HEIGHT = 48;
const OneGroupBarGraphWidget = (props) => {
  const { userStore } = rootStore;
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [showTop, setShowTop] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(true);

  const Open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRemove = async () => {
    try {
      let response = await removeWidgetApiv2({ id: props.data.id });
      setAnchorEl(null);
      props.setRefresh(!props.refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const generateRandomColor = (val) => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    userStore.updateColorsList({ key: val, color: color });
    return color;
  };

  const updateData = (response, v2Response) => {
    let aggregateBy = v2Response["xdata"].hasOwnProperty(
      "aggregate_by_col_names"
    )
      ? v2Response["xdata"]["aggregate_by_col_names"]
      : "count"; //here if xdata has the aggregate_by_col_names then it will use that else by default it will use "count"
    let newObj = {
      labels: [],
      datasets: [{ label: aggregateBy, data: [], backgroundColor: [] }],
    };
    if (response.length > 0) {
      response.map((item) => {
        let keyname = v2Response["xdata"]["group_by"][0];
        newObj.labels.push(item[keyname]);
        newObj.datasets[0].data.push(item[aggregateBy]);
        if (keyname === "lead_stage") {
          let color = getStageColorCode(item[keyname]);
          newObj.datasets[0].backgroundColor.push(color);
        } else if (keyname === "lead_status") {
          let color = getStatusColorCode(item[keyname]);
          newObj.datasets[0].backgroundColor.push(color);
        } else {
          if (userStore.ColorsList.hasOwnProperty(item[keyname])) {
            let colorVal = userStore.ColorsList[item[keyname]];
            newObj.datasets[0].backgroundColor.push(colorVal);
          } else {
            let colorVal = generateRandomColor(item[keyname]);
            newObj.datasets[0].backgroundColor.push(colorVal);
          }
        }
      });
    } else {
      newObj = {};
    }
    return newObj;
  };

  const ChartBottomSection = () => {
    if (Object.keys(data).length === 0) {
      return (
        <Wrapper>
          <Typography>No data found!</Typography>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <Bar
            data={data}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </Wrapper>
      );
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await getWidgetDataApiv2({ id: props.data.id });
        let modifiedData = updateData(response.data, props.data);

        setData(modifiedData);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (props.type !== "preview") {
      getData();
    }
  }, [props.data]);
  return (
    <>
      {!loading ? (
        Object.keys(data).length > 0 ? (
          <Container type={props.type}>
            {props.type === "preview" ? (
              <Text style={{ textAlign: "center" }}>Preview</Text>
            ) : (
              <ChartTop>
                <Text>{props.data.title}</Text>
                <IconWrapper>
                  <IconButton
                    style={{ padding: "0px" }}
                    onClick={() => {
                      setShowTop(false);
                      setOpen(true);
                    }}
                  >
                    <Fullscreen />
                  </IconButton>
                  <IconButton
                    onClick={handleClick}
                    aria-label="more"
                    id="long-button"
                    aria-controls={Open ? "long-menu" : undefined}
                    aria-expanded={Open ? "true" : undefined}
                    aria-haspopup="true"
                    style={{ padding: "0px", marginRight: "-10px" }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </IconWrapper>
              </ChartTop>
            )}
            <ChartBottomSection />
          </Container>
        ) : (
          <Container type={props.type}>
            {props.type === "preview" ? (
              <Text style={{ textAlign: "center" }}>Preview</Text>
            ) : (
              <ChartTop>
                <Text>{props.data.title}</Text>
                <IconWrapper>
                  <IconButton
                    style={{ padding: "0px" }}
                    onClick={() => {
                      setShowTop(false);
                      setOpen(true);
                    }}
                  >
                    <Fullscreen />
                  </IconButton>
                  <IconButton
                    onClick={handleClick}
                    aria-label="more"
                    id="long-button"
                    aria-controls={Open ? "long-menu" : undefined}
                    aria-expanded={Open ? "true" : undefined}
                    aria-haspopup="true"
                    style={{ padding: "0px", marginRight: "-10px" }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </IconWrapper>
              </ChartTop>
            )}
            <ChartBottomSection />
          </Container>
        )
      ) : (
        <Container style={{ alignItems: "center" }}>
          <CircularProgress />
        </Container>
      )}
      {open && !showTop && (
        <FullScreenModal
          open={open}
          setOpen={setOpen}
          myComponent={ChartBottomSection}
          setShowTop={setShowTop}
          title={props.data.title}
        />
      )}
      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
          },
        }}
      >
        <MenuItem
          style={{ fontSize: "12px" }}
          onClick={handleRemove}
          disabled={!props.isDashboardEditable()}
        >
          Remove
        </MenuItem>
      </Menu>
    </>
  );
};

export default observer(OneGroupBarGraphWidget);
