import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { styled } from "@mui/material/styles";
import {
  ChevronRight,
  Equalizer,
  Publish,
  ShareSharp,
  Visibility,
} from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import { WebFormsColumnHeaders } from "../../Db";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Button, CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { getWebFormsListApi } from "../../Api";
import rootStore from "../../stores/RootStore";
import { pageSize } from "../../Config";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useMemo } from "react";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};

  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: fit-content;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 236px);`};
  background-color: white;
  position: relative;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;

const WebFormsListingPage = () => {
  const navigate = useNavigate();
  const [row, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const getWebFormsList = async ({ page_size, page }) => {
    setRow([]);
    setLoading(true);
    let response = await getWebFormsListApi({
      page_size: page_size,
      page: page,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setCount(0);
    }
    setLoading(false);
  };
  const init = async () => {
    await getWebFormsList({ page_size: pageSize, page: 0 });
  };
  useEffect(() => {
    if (refresh === true) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setPage(0);
    await getWebFormsList({ page_size: e.target.value, page: 0 });
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);
    await getWebFormsList({ page_size: size, page: page - 1 });
  };

  useEffect(() => {
    rootStore.authStore.updateLeadDetailStatus();
    init();
  }, []);

  const actionColumn = [
    {
      field: "actions",
      headerName: "Actions",
      resizable: false,
      minWidth: 70,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <>
            {/* <Visibility
              onClick={(e) => {
                navigate(`/web-forms/form?formid=${params.data.id}`);
              }}
              style={{
                marginRight: "20px",
                width: "18px",
                height: "18px",
                cursor: "pointer",
              }}
              color="primary"
            /> */}
            <Tooltip title={"Publish"}>
              <IconButton disabled color="primary">
                <Publish
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Share"}>
              <IconButton disabled color="primary">
                <ShareSharp
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Stats"}>
              <IconButton
                color="primary"
                onClick={() => {
                  navigate(`/web-forms/stats/${params.data.id}`);
                }}
              >
                <Equalizer
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));

  const gridOptions = {
    suppressRowClickSelection: true,
  };

  return (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Header>Web Forms</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
            </TopWrapper>
            <Button
              onClick={() => {
                navigate("/web-forms/create");
              }}
              variant="contained"
              style={{
                backgroundColor: "#185DD2",
                color: "white",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              Create Form
            </Button>
          </Row>
          {!loading ? (
            <TableWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  suppressCellFocus
                  columnDefs={WebFormsColumnHeaders.concat(actionColumn)}
                  animateRows={true}
                />
              </div>
            </TableWrapper>
          ) : (
            <TableWrapper
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </TableWrapper>
          )}
          <PaginationWrapper>
            <PaginationComponent
              page_no={page}
              row={row}
              page_size={size}
              size={size}
              count={count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </PaginationWrapper>
        </Container>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default WebFormsListingPage;
