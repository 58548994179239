import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { getCampaignLeadsListApi, getColumnsForMapping } from "../../../Api";
import { Divider, Select } from "@material-ui/core";
import {
  IsoToLocalTime,
  capitalizeWord,
  getLocalizedText,
} from "../../../Functions";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  margin-bottom: 30px;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-height: 400px;
  overflow-y: auto;
  width: 400px;
  padding: 10px 0px;
`;
const RowWrapper = styled(Box)`
  display: flex;
  column-gap: 20px;
  width: 100%;
`;
const CheckBoxTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 190px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const SubHeader = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #5a5a5a;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const ColumnWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;
const ColumnHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  width: 50%;
`;
const MapCampaignLeadColumnToProject = (props) => {
  const [campaignLeadColumns, setCampaignLeadColumns] = useState([]);
  const [projectColumns, setProjectColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const getProjectColumnsForMapping = async () => {
    let response = await getColumnsForMapping(props.projectId);
    return response;
  };
  const initCampaignToProjectList = (campaignColumnList, projectColumnList) => {
    let initList = {};
    campaignColumnList.forEach((column) => {
      let tempObj = {
        campaign_lead: {
          field: column["field"],
          display_name: column["display_name"],
        },
        project_lead: {},
        checked: false,
      };

      initList[column["field"]] = tempObj;
    });
    return initList;
  };
  const setup = async () => {
    let campaignColumns = await setupColumns();
    setCampaignLeadColumns(campaignColumns);
    let projectColumns = await getProjectColumnsForMapping();
    let new_init_list = initCampaignToProjectList(
      campaignColumns,
      projectColumns
    );
    props.setProjectColumnList(projectColumns);
    props.setCampaignToProjectList(new_init_list);
    setProjectColumns(projectColumns);
    setLoading(false);
  };

  const getCampaignFieldHeaderName = (field) => {
    let index = campaignLeadColumns.findIndex(
      (column) => column["field"] === field
    );
    if (index !== -1) {
      return campaignLeadColumns[index]["display_name"];
    } else {
      return "";
    }
  };

  useEffect(() => {
    setup();
  }, []);

  const setupColumns = async () => {
    let data = [];
    let page_index = 0; //initialize with 0 as we are taking the base index as 0
    while (data.length < props.count) {
      //above count is the state variable which gets set on tab change or first time page load with the first tab
      let response = await getCampaignLeadsListApi({
        campaign_id: props.id,
        page_size: 1000,
        page_no: page_index,
        scope: "submitted",
      });
      if (Object.keys(response).length > 0) {
        data = data.concat(response.items);
        page_index++;
      } else {
        break;
      }
    }

    let column = [
      {
        display_name: props.campaignType,
        field: "target",
      },
      { display_name: "Submitted On", field: "submitted_on" },
    ];
    let keys = [];
    data.forEach((item) => {
      let columnItem = {
        ...item,
      };
      if (columnItem.submitted_on !== null) {
        columnItem["submitted_on"] = IsoToLocalTime(columnItem["submitted_on"]);
      }
      delete columnItem["submit_data"];

      if (
        item["submit_data"] !== null &&
        item["submit_data"] !== "" &&
        item["submit_data"] !== undefined
      ) {
        Object.keys(item["submit_data"]).forEach((key) => {
          if (key.toLowerCase() !== "custom_params") {
            columnItem[key] = item["submit_data"][key];
            if (keys.includes(key) === false) {
              keys.push(key);
            }
          }
        });
      }
    });
    keys.forEach((key) => {
      column.push({
        display_name: capitalizeWord(key),
        field: key,
      });
    });
    return column;
  };

  const isAlreadySelectedProjectField = (field) => {
    let flag = false;
    Object.keys(props.campaignToProjectList).forEach((item) => {
      if (
        props.campaignToProjectList[item]["project_lead"].hasOwnProperty(
          "field"
        ) &&
        props.campaignToProjectList[item]["project_lead"]["field"] === field
      ) {
        flag = true;
      }
    });
    return flag;
  };

  return !loading ? (
    <Container>
      <Header>{getLocalizedText("move_to")}</Header>
      <SubHeader>{getLocalizedText("please_fill_the_below_details")}</SubHeader>
      <Divider style={{ margin: "10px 0px" }} />
      <ColumnWrapper>
        <ColumnHeader>Campaign Lead Field</ColumnHeader>
        <ColumnHeader>{getLocalizedText("project_lead_field")}</ColumnHeader>
      </ColumnWrapper>
      <Wrapper>
        {Object.keys(props.campaignToProjectList).map((item) => {
          return (
            <RowWrapper>
              <CheckBoxTextWrapper>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        props.setShowAlert(false);
                        props.setShowRequiredColumnAlert(false);
                        props.setCampaignToProjectList({
                          ...props.campaignToProjectList,
                          [item]: {
                            ...props.campaignToProjectList[item],
                            checked: e.target.checked,
                          },
                        });
                      }}
                      size="small"
                      checked={props.campaignToProjectList[item]["checked"]}
                    />
                  }
                  label={
                    <Label>
                      {getCampaignFieldHeaderName(
                        props.campaignToProjectList[item]["campaign_lead"][
                          "field"
                        ]
                      )}
                    </Label>
                  }
                />
              </CheckBoxTextWrapper>

              <Select
                value={
                  props.campaignToProjectList[item][
                    "project_lead"
                  ].hasOwnProperty("field")
                    ? props.campaignToProjectList[item]["project_lead"]["field"]
                    : ""
                }
                required={props.campaignToProjectList[item]["checked"]}
                label="Select Member"
                onChange={(e) => {
                  let index = projectColumns.findIndex(
                    (column) => column["field"] === e.target.value
                  );

                  props.setCampaignToProjectList({
                    ...props.campaignToProjectList,
                    [item]: {
                      ...props.campaignToProjectList[item],
                      project_lead: {
                        id: projectColumns[index]["id"],
                        field: e.target.value,
                        display_name: projectColumns[index]["headerName"],
                        data_type: projectColumns[index]["data_type"],
                        is_custom_param:
                          projectColumns[index]["is_custom_param"],
                      },
                    },
                  });
                }}
                style={{
                  width: "190px",
                  height: "30px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "0px",
                }}
              >
                {projectColumns.map((e, i) => {
                  return (
                    <MenuItem
                      disabled={isAlreadySelectedProjectField(e["field"])}
                      key={i}
                      style={{ fontSize: "12px" }}
                      id={e.id}
                      value={e.field}
                    >
                      <span style={{ color: "red" }}> {e.required && "*"}</span>{" "}
                      {e.headerName}
                    </MenuItem>
                  );
                })}
              </Select>
            </RowWrapper>
          );
        })}
      </Wrapper>
    </Container>
  ) : (
    <Container
      style={{
        height: "420px",
        width: "400px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Container>
  );
};

export default MapCampaignLeadColumnToProject;
