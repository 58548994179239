import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { dateTimeHandler, IsoToLocalTime } from "../../../Functions";
import rootStore from "../../../stores/RootStore";

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 28px;
  column-gap: 20px;
  width: 428px;
  margin-bottom: 20px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;
const DetailWrapperLg = styled.div`
  display: flex;
  flex-direction: column;
  width: 428px;
  max-height: 40px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #eeeeee;
  }
`;
const Label = styled.span`
  font: normal normal 500 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled.span`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const FirstScreen = (props) => {
  const { authStore } = rootStore;
  const pid = authStore.projectId;
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const getHeaderName = (field) => {
    let key = "custom_params_" + field;
    let headerName = "";
    let dataType = "";
    let flag = false;
    if (columnsList.length > 0) {
      columnsList.map((item) => {
        if (item.field === key) {
          flag = true;
          headerName = item.headerName;
          dataType = item.data_type;
        }
      });
    }
    return { value: headerName, flag: flag, data_type: dataType };
  };

  return (
    <>
      <InputContainer>
        <InputWrapper style={{ width: "428px" }}>
          <Label>Purpose</Label>
          <DetailWrapperLg>
            <Detail>
              {props.data.purpose !== null ? props.data.purpose : "-"}
            </Detail>
          </DetailWrapperLg>
        </InputWrapper>
        <InputWrapper>
          <Label>Place</Label>
          <Detail>{props.data.place !== null ? props.data.place : "-"}</Detail>
        </InputWrapper>

        <InputWrapper>
          <Label>Check In Time</Label>
          <Detail>
            {props.data.checked_in !== null
              ? IsoToLocalTime(props.data.checked_in)
              : "-"}
          </Detail>
        </InputWrapper>
        <InputWrapper>
          <Label>Check Out Time</Label>
          <Detail>
            {props.data.checked_out !== null
              ? IsoToLocalTime(props.data.checked_out)
              : "-"}
          </Detail>
        </InputWrapper>

        <InputWrapper>
          <Label>Status</Label>
          <Detail>
            {props.data.lead_status !== null ? props.data.lead_status : "-"}
          </Detail>
        </InputWrapper>
        <InputWrapper>
          <Label>Sub-Status</Label>
          <Detail>
            {props.data.lead_sub_status !== null
              ? props.data.lead_sub_status
              : "-"}
          </Detail>
        </InputWrapper>
        <InputWrapper>
          <Label>Next Follow-up On</Label>
          <Detail>
            {props.data.next_followup_on !== null
              ? IsoToLocalTime(props.data.next_followup_on)
              : "-"}
          </Detail>
        </InputWrapper>
        <InputWrapper style={{ width: "428px" }}>
          <Label>Note</Label>
          <DetailWrapperLg>
            <Detail>
              {props.data.notes !== null ? props.data.notes : "-"}
            </Detail>
          </DetailWrapperLg>
        </InputWrapper>
        {Object.keys(props.data.status_params).length > 0 &&
          Object.keys(props.data.status_params).map((key) => {
            let item = getHeaderName(key);
            if (item.flag) {
              if (item.data_type === "date" || item.data_type === "datetime") {
                let localDateTime =
                  props.data.status_params[key] !== ""
                    ? IsoToLocalTime(props.data.status_params[key])
                    : "";
                return (
                  <InputWrapper>
                    <Label>{item.value}</Label>
                    <Detail>
                      {localDateTime !== null && localDateTime !== ""
                        ? localDateTime
                        : "-"}
                    </Detail>
                  </InputWrapper>
                );
              } else {
                return (
                  <InputWrapper>
                    <Label>{item.value}</Label>
                    <Detail>
                      {props.data.status_params[key] !== null &&
                      props.data.status_params[key] !== ""
                        ? props.data.status_params[key]
                        : "-"}
                    </Detail>
                  </InputWrapper>
                );
              }
            }
          })}
        {/* <ImageScroller /> */}
      </InputContainer>
    </>
  );
};

export default FirstScreen;
